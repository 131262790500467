import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineMobileAndroidIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m12.71 16.29-.15-.12a.7618.7618 0 0 0-.18-.09L12.2 16a1.0005 1.0005 0 0 0-.91.27 1.1495 1.1495 0 0 0-.21.33.9999.9999 0 0 0 1.3 1.31 1.461 1.461 0 0 0 .33-.22.9995.9995 0 0 0 .21-1.09 1.0011 1.0011 0 0 0-.21-.31ZM16 2H8a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Zm1 17a1.0001 1.0001 0 0 1-1 1H8a1.0002 1.0002 0 0 1-1-1V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v14Z"/>
  </SvgIcon>
}

export default LineMobileAndroidIcon
