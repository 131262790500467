import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const dealsTravelDefaultPalette: App.Palette = {
  ...whiteLabelBaseDefaultPalette,
}

export const dealsTravelShadows: App.ThemeShadows = { ...baseThemeShadows }
export const dealsTravelBorderRadii: App.ThemeBorderRadii = { ...whiteLabelBaseBorderRadii }

const dealsTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default dealsTravelTheme
