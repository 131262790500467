import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createReducer } from 'lib/redux/reducerUtils'

import uiReducer from './ui'
import giftCardReducer from './giftCard'
import offerReducer from './offer'
import orderReducer from './order'
import ordersReducer from './orders'
import authReducer from './auth'
import geoReducer from './geo'
import routeHistoryReducer from './routeHistory'
import analyticsReducer from './analytics'
import contentReducer from './content'
import flightsReducer from './flights'
import insuranceReducer from './insurance'
import addonReducer from './addon'
import calendarReducer from './calendar'
import systemReducer from './system'
import optimizelyReducer from './optimizely'
import destinationReducer from './destination'
import destinationProductPageReducer from './destinationProductPage'
import checkoutReducer from './checkout'
import experiencesReducer from './experiences'
import cruisesReducer from './cruises'
import tourReducer from './tours'
import tripPlannerReducer from 'tripPlanner/reducers'
import referReducer from './refer'
import marketingReducer from './marketing'
import reviewReducer from './review'
import utmReducer from './utm'
import recommendationsReducer from './recommendations'
import wlSubscriptionsReducer from './WLSubscriptions'
import leSubscriptionsReducer from './LESubscriptions'
import userTravelPreferencesReducer from './userTravelPreferences'
import carHireReducer from './carHire'
import businessTravellerReducer from 'reducers/businessTravellerActionReducer'
import stickPromptCardReducer from './stickyPromptCard'
import luxPlusReducer from './luxPlus'
import bookingProtectionReducer from './bookingProtection'
import agentHubReducer from './agentHub'
import sherlogReducer from './sherLog'
import supportAssistantReducer from './supportAssistantActionReducer'
import securePaymentReducer from './securePayment'
import paymentScheduleReducer from './paymentSchedule'
import luxLoyaltyReducer from './luxLoyalty'

export default (history) => combineReducers<App.State>({
  addon: addonReducer,
  agentHub: agentHubReducer,
  analytics: analyticsReducer,
  auth: authReducer,
  bookingProtection: bookingProtectionReducer,
  businessTraveller: businessTravellerReducer,
  calendar: calendarReducer,
  carHire: carHireReducer,
  checkout: checkoutReducer,
  config: systemReducer,
  content: contentReducer,
  cruise: cruisesReducer,
  destination: destinationReducer,
  destinationProductPage: destinationProductPageReducer,
  experience: experiencesReducer,
  flights: flightsReducer,
  geo: geoReducer,
  giftCard: giftCardReducer,
  insurance: insuranceReducer,
  interactionStudio: createReducer<App.InteractionStudioState>({} as App.InteractionStudioState, {}),
  leSubscriptions: leSubscriptionsReducer,
  luxPlus: luxPlusReducer,
  luxLoyalty: luxLoyaltyReducer,
  marketing: marketingReducer,
  offer: offerReducer,
  optimizely: optimizelyReducer,
  order: orderReducer,
  orders: ordersReducer,
  paymentSchedule: paymentScheduleReducer,
  recommendations: recommendationsReducer,
  refer: referReducer,
  review: reviewReducer,
  routeHistory: routeHistoryReducer,
  router: connectRouter(history),
  sherLog: sherlogReducer,
  stickyPromptCard: stickPromptCardReducer,
  supportAssistant: supportAssistantReducer,
  tour: tourReducer,
  tripPlanner: tripPlannerReducer,
  ui: uiReducer,
  utm: utmReducer,
  wlSubscriptions: wlSubscriptionsReducer,
  securePayment: securePaymentReducer,
  userTravelPreferences: userTravelPreferencesReducer,
})
