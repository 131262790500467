import moment from 'moment'
import { fillArray } from 'lib/array/arrayUtils'
import { dateIsAfter, dateIsBefore, endOfMonth, isSameDay, isSameMonth } from 'lib/datetime/dateUtils'

export function calcGridPositionsForCalendarMonth(firstDateInMonth: Date, startDate?: Date, endDate?: Date) {
  const month = firstDateInMonth.getMonth()
  const year = firstDateInMonth.getFullYear()
  const monthEnd = endOfMonth(firstDateInMonth)

  // second part accounts for when the first date in the month isn't the the 1st of the month
  const monthOffset = firstDateInMonth.getDay() - (firstDateInMonth.getDate() - 1)
  let startDayIndex, startColumn, startRangeColumn
  if (startDate) {
    if (startDate.getMonth() === month && startDate.getFullYear() == year) {
      // starts in *this month*, find where it starts
      startColumn = startDate.getDay() + 1
      startRangeColumn = startColumn === 7 ? 1 : startColumn + 1
      startDayIndex = startDate.getDate() + monthOffset
    } else if (endDate && dateIsBefore(startDate, firstDateInMonth) && !isSameDay(endDate, firstDateInMonth)) {
      // starts *before* this month, so first day must be in range
      startRangeColumn = firstDateInMonth.getDay() + 1
      startDayIndex = monthOffset
    }
  }

  let endDayIndex, endColumn, endRangeColumn
  if (endDate) {
    if (endDate.getMonth() === month && endDate.getFullYear() === year) {
      // ends in *this month*
      endColumn = endDate.getDay() + 1
      endRangeColumn = endColumn === 1 ? -1 : endColumn
      endDayIndex = endDate.getDate() + monthOffset
    } else if (startDate) {
      if (dateIsAfter(endDate, monthEnd) && !isSameDay(startDate, monthEnd)) {
        // doesn't end in this month
        endRangeColumn = monthEnd.getDay() + 2
        endDayIndex = monthEnd.getDate() + monthOffset + 1
      }
    }
  }

  const startRangeRow = Math.ceil((startDayIndex + 1) / 7)
  const endRangeRow = Math.ceil((endDayIndex - 1) / 7)
  let rangeWeeks = 0
  if ((startDayIndex != null && endDayIndex != null) && endDayIndex !== startDayIndex + 1 && (startDate && endDate) && startDate < endDate) {
    rangeWeeks = endRangeRow - startRangeRow + 1
  }

  return {
    startRow: Math.ceil(startDayIndex / 7),
    endRow: Math.ceil(endDayIndex / 7),
    endColumn,
    startColumn,
    ranges: fillArray<number>(rangeWeeks).map((index) => {
      const row = startRangeRow + index
      return {
        key: index,
        columnStart: row === startRangeRow ? startRangeColumn : 1,
        columnEnd: row === endRangeRow ? endRangeColumn : -1,
        row,
      }
    }),
  }
}

export type CellRanges = {
  start: number
  end: number
}

export function calcGridPositionsForVerticalMonth(firstDateInMonth: Date, startDate?: Date, endDate?: Date): {
  startRow?: number
  endRow?: number
  range?: CellRanges
} {
  const monthEnd = endOfMonth(firstDateInMonth)
  const monthStartOffset = firstDateInMonth.getDate()

  let startRow: number | undefined, endRow: number | undefined, range: CellRanges | undefined
  if (startDate) {
    if (isSameMonth(startDate, firstDateInMonth)) {
      // starts in this month
      startRow = startDate.getDate() - monthStartOffset + 1
    }
  }

  if (endDate) {
    if (isSameMonth(endDate, firstDateInMonth)) {
      // ends in this month
      endRow = endDate.getDate() - monthStartOffset + 2
    }
  }

  if (startDate && endDate) {
    // calcuate our range positions
    if (dateIsBefore(startDate, firstDateInMonth) && dateIsAfter(endDate, monthEnd)) {
      // spans the whole month
      range = {
        start: 1,
        end: monthEnd.getDate() - monthStartOffset,
      }
    } else {
      let start, end
      if (isSameMonth(startDate, firstDateInMonth)) {
        // starts in this month, find the row it starts at
        start = startDate.getDate() - monthStartOffset + 2
      }

      if (dateIsBefore(startDate, firstDateInMonth) && !isSameDay(endDate, firstDateInMonth)) {
        // starts in a previous month, so our range starts on the first day (unless that's also the end)
        start = 1
      }

      if (dateIsAfter(endDate, monthEnd)) {
        // ends in a later away month, so our range is all the way to the end
        end = monthEnd.getDate() - monthStartOffset + 2
      }

      if (isSameMonth(endDate, firstDateInMonth)) {
        // ends in this month, find the row it ends at
        end = endDate.getDate() - monthStartOffset + 1
      }

      if (start && end) {
        range = { start, end }
      }
    }
  }

  return {
    startRow,
    endRow,
    range,
  }
}

/**
 * Create Date obj in user local timezone
 *
 * @param {string} dateString - 2022-01-30
 * @return {Date}
 */
export function getLocalDate(dateString: String) {
  return new Date(`${dateString}T00:00`)
}

export function getCalendarDay(dateString: string | moment.Moment, calendar?: App.Calendar) {
  const date = moment.utc(dateString)
  const monthKey = `${date.month() + 1}-${date.year()}`
  const month = calendar?.months.find(m => m.key === monthKey)
  return month?.days.find(d => d.day === date.date())
}

/**
 * Get coordinates (month, day)
 */
export function getCalendarDateCoordinates(dateString: string, calendar: App.Calendar) {
  const date = moment.utc(dateString)
  const monthKey = `${date.month() + 1}-${date.year()}`

  const monthIdx = calendar.months.findIndex(month => month.key === monthKey)

  const month = calendar.months[monthIdx]
  const dayIdx = month.days.findIndex(day => day.checkIn === dateString)

  return { monthIdx, dayIdx }
}

export const BEDBANK_CALENDAR_FUTURE_DAYS = 500

/**
 * Retrieves the empty cells that should be shown at the start of a calendar month.
 * our month may not start on the first day of the month, because we can't check in
 * on those days (they may be in the past). So we fill in the earliest weeks cells
 */
export function getCalendarMonthEmptyCells(firstDayOfMonth: App.CalendarDay | App.BedbankCalendarDay) {
  if (firstDayOfMonth.day !== 1) {
    const checkIn = moment(firstDayOfMonth.checkIn)
    const firstDay = moment.max(checkIn.clone().startOf('week'), checkIn.clone().startOf('month'))
    return fillArray(checkIn.date() - firstDay.date(), (index) => ({
      day: firstDay.date() + index,
      column: firstDay.weekday() + index + 1,
    }))
  }
  return []
}
