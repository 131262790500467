import { OptimizelyExperiments } from 'constants/optimizely'
import { useBedbankRoomRatePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import { useAppSelector } from 'hooks/reduxHooks'
import useBedbankOfferSell from 'hooks/useBedbankOfferSell'
import getBedbankPromoExtraValue, { DisplayTotals, getDisplayTotalPrice, getDisplayTotalPricePerNight } from 'lib/hotels/bedbankUtils'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { calculateDiscount } from 'lib/payment/calculateDiscount'
import { checkCanViewLuxPlusBenefits, isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import moment from 'moment'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary
  rate?: App.BedbankRate,
  duration?: number,
  filters?: App.OfferListFilters;
  bundleRate?: App.BedbankRate;
  suggestedDates?: App.OfferSuggestedDates;
  directSearchPrices?: App.OfferListMetadataPricing;
  flightPrice?: number
  promoteAsBundle?: boolean
  isFlexibleSearch?: string
  rooms?: Array<App.Occupants>
  promotions: Array<App.BedbankPromotion>;
}

interface Return {
  offerPrice: number
  offerMemberPrice: number
  totalPrices: DisplayTotals
  value: number,
  bundleTaxesAndFees: number,
  bundlePropertyFees: number,
  propertyFees: number,
  taxesAndFees: number,
  bundlePrice: number,
  hotelPrice: number,
  bundleMemberPrice: number,
  hotelMemberPrice: number,
  hotelNights?: number,
  discountPercentage: number
  nightsToDisplay: number
}

export const useSearchBedbankOfferPrices = ({ rate, offer, filters, bundleRate, suggestedDates, promotions, directSearchPrices, flightPrice, promoteAsBundle, rooms, isFlexibleSearch }: Props): Return => {
  const canViewLuxPlusBenefits = useAppSelector((state) => checkCanViewLuxPlusBenefits(state))
  const luxPlusLPPEnabled = useAppSelector((state) => isLuxPlusLPPEnabled(state))
  const isPerNightPricingTestEnabled = useAppSelector((state) => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))
  const useMemberPrice = luxPlusLPPEnabled && canViewLuxPlusBenefits
  const calculateHotelMemberPrice = suggestedDates?.memberPrice ?? (rate ? rate?.totals.memberInclusive : 0) ?? 0

  let duration = rate?.nights
  if (promotions.length && !filters?.checkIn && !filters?.checkOut) {
    duration = Math.min(...promotions.map(x => x.los))
  }

  const sell = useBedbankOfferSell(offer, duration, rooms)

  let hotelValue: undefined | number
  let bundleValue: undefined | number
  let hotelDiscount: number
  let hotelMemberDiscount: number = 0
  let taxesAndFees = rate?.totals.taxesAndFees ?? sell?.taxesAndFees ?? 0
  let propertyFees = rate?.totals.propertyFees ?? sell?.propertyFees ?? 0
  let hotelPrice = (suggestedDates?.price ?? (rate ? rate.totals.inclusive : sell?.price ?? 0)) + propertyFees
  let hotelNights = rate?.nights ?? sell?.los
  let hotelMemberPrice = calculateHotelMemberPrice > 0 ? calculateHotelMemberPrice + propertyFees : 0

  const bundleTaxesAndFees = bundleRate?.totals.taxesAndFees || taxesAndFees
  const bundlePropertyFees = bundleRate?.totals.propertyFees || propertyFees
  const bundlePrice = (bundleRate?.totals.inclusive || hotelPrice) + bundlePropertyFees

  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch
  const showOneNightPrice = !isPerNightPricingTestEnabled && isAnytimeSearch && !offer.promotions.length
  const calculateBundleMemberMemberPrice = bundleRate?.totals.memberInclusive || hotelMemberPrice
  const bundleMemberPrice = calculateBundleMemberMemberPrice > 0 ? calculateBundleMemberMemberPrice + bundlePropertyFees : 0

  const promo = useBedbankRoomRatePromotions(
    offer.promotions,
    filters?.checkIn ? moment(filters.checkIn) : undefined,
    filters?.checkOut ? moment(filters.checkOut) : undefined,
  )

  if (rate && 'packages' in offer) {
    const pkg = rate.room
    const isPromoEnabledRate = !!promo[rate.id]

    if (isPromoEnabledRate) {
      const inclusionsAmount = pkg?.sell ? getBedbankPromoExtraValue({
        price: pkg.sell.price,
        value: pkg.sell.value,
        minLos: pkg.sell.los,
        duration,
      }) : 0
      hotelValue = inclusionsAmount + (rate.totals.inclusive)
      bundleValue = inclusionsAmount + (bundleRate?.totals.inclusive || hotelValue)
      hotelDiscount = Number((inclusionsAmount / hotelValue).toFixed(2))
    } else {
      hotelValue = rate.value
      bundleValue = bundleRate?.value || hotelValue
      hotelDiscount = rate.discount
      hotelMemberDiscount = rate.memberDiscount
    }
  } else if (suggestedDates?.value) {
    hotelValue = suggestedDates.value
    hotelDiscount = sell?.discount ?? 0
  } else {
    hotelValue = sell?.value ?? 0
    bundleValue = sell?.value
    hotelDiscount = sell?.discount ?? 0
  }

  // override with search prices if passed down to this component
  if (directSearchPrices) {
    hotelPrice = directSearchPrices.lowestPrice ?? hotelPrice
    hotelMemberPrice = directSearchPrices.lowestMemberPrice ?? hotelMemberPrice
    hotelValue = directSearchPrices.lowestPriceValue ?? hotelValue
    hotelDiscount = calculateDiscount((useMemberPrice && hotelMemberPrice > 0) ? hotelMemberPrice : hotelPrice, hotelValue)
    taxesAndFees = directSearchPrices.lowestPriceTaxes ?? taxesAndFees
    propertyFees = directSearchPrices.lowestPricePropertyFees ?? propertyFees
    hotelNights = directSearchPrices.duration ?? hotelNights
  }

  const totalPrices = getDisplayTotalPrice({
    hotelPrice,
    hotelValue,
    bundlePrice,
    hotelMemberPrice,
    bundleMemberPrice,
    bundleValue,
    hotelDiscount,
    hotelMemberDiscount,
    checkIn: filters?.checkIn,
    flightPrice,
    rooms,
    displayPricingAsPerPerson: offer.displayPricingAsPerPerson,
  })

  const nightsToDisplay = showOneNightPrice ? 1 : (hotelNights || 0)

  let price = promoteAsBundle ? totalPrices.totals.flightBundlePrice : totalPrices.totals.price
  let memberPrice = promoteAsBundle ? totalPrices.totals.flightBundleMemberPrice : totalPrices.totals.memberPrice
  let value = promoteAsBundle ? totalPrices.totals.flightBundleValue : totalPrices.totals.value

  price = (price / hotelNights!) * nightsToDisplay
  memberPrice = memberPrice > 0 ? (memberPrice / hotelNights!) * nightsToDisplay : 0
  value = (value / hotelNights!) * nightsToDisplay

  const calcDiscount = useMemberPrice && memberPrice > 0 ? memberPrice : price
  const discountPercentage = calculateDiscount(calcDiscount, value)

  const totalPricesPerNight = getDisplayTotalPricePerNight(totalPrices, hotelNights || 1)
  const perNightOfferPrice = promoteAsBundle ? totalPricesPerNight.totals.flightBundlePrice : totalPricesPerNight.totals.price
  const perNightOfferMemberPrice = promoteAsBundle ? totalPricesPerNight.totals.flightBundleMemberPrice : totalPricesPerNight.totals.memberPrice

  let offerPrice = promoteAsBundle ? totalPrices.totals.flightBundlePrice : totalPrices.totals.price
  let offerMemberPrice = promoteAsBundle ? totalPrices.totals.flightBundleMemberPrice : totalPrices.totals.memberPrice

  if (showOneNightPrice && offerMemberPrice > 0) {
    offerMemberPrice /= hotelNights!
  }

  offerPrice = showOneNightPrice ? (offerPrice / hotelNights!) : offerPrice
  offerPrice = isPerNightPricingTestEnabled ? perNightOfferPrice : offerPrice
  offerMemberPrice = isPerNightPricingTestEnabled ? perNightOfferMemberPrice : offerMemberPrice

  return {
    offerPrice,
    offerMemberPrice,
    totalPrices,
    bundleTaxesAndFees,
    bundlePropertyFees,
    propertyFees,
    taxesAndFees,
    bundlePrice,
    hotelPrice,
    bundleMemberPrice,
    hotelMemberPrice,
    discountPercentage,
    hotelNights,
    value,
    nightsToDisplay,
  }
}
