/**
 * "Search" style tile. Not that it isn't just used on the search results page,
 * but also search-ish places such as destination product pages.
 */

import classnames from 'clsx'
import React, { forwardRef, useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'

import MainOfferTile from '../MainOfferTile'
import SearchBedbankOffer from './SearchBedbankOffer'
import SearchBundleOffer, { BundleOfferMetaData } from './SearchBundleOfferTile'
import SearchFlashOffer from './SearchFlashOffer'
import SearchOfferTile from './SearchOfferTile'

import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
} from 'constants/offer'
import noop from 'lib/function/noop'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'

import * as Analytics from 'analytics/analytics'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import config from 'constants/config'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import DepositTagOffersContext from 'contexts/depositTagOffersContext'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import useOfferUrl from 'hooks/Offers/useOfferUrl'
import { isBundleOffer, isCruiseOffer, isCruiseV1Offer, isTourV2Offer } from 'lib/offer/offerTypes'
import { TopLevelTileProps } from '../OfferTileTypes'
import TourV2SearchTile from '../TourV2SearchTile'
import SearchVillaTile from './SearchVillaTile/SearchVillaTile'
import CruiseTile from 'components/Cruises/CruiseTiles/CruiseTile'
import { useAppSelector } from 'hooks/reduxHooks'
import { getSearchVerticalFromPathname } from 'lib/search/searchUtils'
import { SEARCH_VERTICALS } from 'constants/search'
import { getItemContextForOffer, SnowplowClientEventParams } from 'analytics/snowplow/events'
import getOfferListKey from 'lib/offer/offerListKey'
import SearchTileEventsContext, { SearchTileEvents, SearchTileEventsHandler } from './SearchTileEventsContext'

const offerSoldOutClickEvent: SnowplowClientEventParams = {
  subject: 'search-sold-out-offer',
  action: 'clicked',
  category: 'logging',
  type: 'operational',
  optimizelyEventId: '29823620233',
  optimizelyEventKey: 'search-click-sold-out-offer',
}

const offerTypeClickEvents: Record<string, SnowplowClientEventParams> = {
  [OFFER_TYPE_ALWAYS_ON]: {
    subject: 'search-lpc-offer',
    action: 'clicked',
    category: 'logging',
    type: 'operational',
    optimizelyEventId: '25348110190',
    optimizelyEventKey: 'search-click-lpc-offer',
  },
  [OFFER_TYPE_HOTEL]: {
    subject: 'search-flash-offer',
    action: 'clicked',
    category: 'logging',
    type: 'operational',
    optimizelyEventId: '27798670454',
    optimizelyEventKey: 'search-click-flash-offer',
  },
  [OFFER_TYPE_BED_BANK]: {
    subject: 'search-lpp-offer',
    action: 'clicked',
    category: 'logging',
    type: 'operational',
    optimizelyEventId: '29828090090',
    optimizelyEventKey: 'search-click-lpp-offer',
  },
}

const OfferLink = styled(ClickableLink)`
  position: relative;
  display: block;
  user-select: none;

  &,
  &:hover,
  &:focus {
    color: ${props => props.theme.palette.neutral.default.one};
    text-decoration: none;
  }
`

function getTestId(offer: App.AnyOffer) {
  let flightsEnabled
  let onlinePurchaseSchedule

  if (offer.type !== OFFER_TYPE_BED_BANK) {
    const internalOffer = offer as App.Offer

    flightsEnabled = internalOffer.offerFlightsEnabled
    onlinePurchaseSchedule = internalOffer.onlinePurchaseSchedule
  }

  return classnames(
    `offer-${offer.type}`, {
      'offer-with-flights': flightsEnabled,
      [`Purchasable-${offer.type}`]: scheduleIsCurrent(onlinePurchaseSchedule),
    })
}

interface Props extends TopLevelTileProps {
  className?: string;
  position?: number;
}

const SearchTile = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    offer,
    filters,
    productClick,
    eagerLoadFirstImage,
    offerLinkIncludesFilters,
    className,
  } = props
  const { clearDepositTagOffers = noop } = useContext(DepositTagOffersContext) ?? {}
  const pathname = useAppSelector(state => state.router.location.pathname)
  const searchVertical = getSearchVerticalFromPathname(pathname)

  const offerListKey = getOfferListKey(filters ?? {})
  const posAdjusted = useAppSelector(state => state.offer.offerListAdjustedOffers[offerListKey]?.[offer.id])

  useEffect(() => {
    return () => clearDepositTagOffers()
  }, [clearDepositTagOffers])
  const offerMetaData = useOfferMetaData(offer.id, filters)
  const offerUrl = useOfferUrl(offer, {
    filters,
    bundledOfferId: offerMetaData?.bundledOfferId,
    offerLinkIncludesFilters,
  })

  const handleClick = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'search-offer',
      action: 'clicked',
      category: 'logging',
      type: 'operational',
      optimizelyEventId: '25273391378',
      optimizelyEventKey: 'search-click-offer',
    })

    const offerTypeClickEvent = offerTypeClickEvents[offer.type]
    if (offerTypeClickEvent) {
      Analytics.trackClientEvent(offerTypeClickEvent)
    }

    if (posAdjusted === 'sold-out') {
      Analytics.trackClientEvent(offerSoldOutClickEvent)
    }

    productClick?.(offer)
  }, [offer, posAdjusted, productClick])

  const onSearchEventHandler = useCallback<SearchTileEventsHandler>((dispatchAction) => {
    const itemContext = getItemContextForOffer(offer)
    switch (dispatchAction.type) {
      case SearchTileEvents.clickAlternativeDateViewOffer:
        Analytics.trackClientEvent({
          subject: 'search-alternative-dates',
          action: 'clicked',
          category: 'logging',
          type: 'operational',
          context: itemContext,
        })
        break
      case SearchTileEvents.viewAlternativeDatesBanner:
        Analytics.trackClientEvent({
          subject: 'search-alternative-dates',
          action: 'impression',
          category: 'logging',
          type: 'operational',
          context: itemContext,
        })
        break
    }
  }, [offer])

  const handleImageChange = useCallback(
    (idx: number, _?: App.Image) => {
      Analytics.trackClientEvent({
        subject: 'search_img_carousel_swipe',
        action: `swiped - ${offer.id} - ${idx}`,
        category: 'search-page',
        type: 'interaction',
      })
    }, [offer.id],
  )

  const linkParams = {
    onClick: handleClick,
    to: offerUrl,
    'data-testid': getTestId(offer),
    ...(config.OPEN_NEW_TAB_OFFER_CLICK ? { target: '_blank' } : {}),
  }

  if (isBundleOffer(offer) && !config.ENABLE_BUNDLE_AND_SAVE) {
    return null
  }
  const showCruiseV1Offer = isCruiseV1Offer(offer) && searchVertical !== SEARCH_VERTICALS.HOTELS

  return (
    <SearchTileEventsContext.Provider value={onSearchEventHandler}>
      <OfferLink {...linkParams} className={className} ref={ref} >
        {!showCruiseV1Offer && offer.type === OFFER_TYPE_HOTEL &&
          <ProductPaletteProvider product={offer}>
            <SearchFlashOffer
              offer={offer as App.Offer | App.OfferSummary}
              filters={filters}
              offerMetaData={offerMetaData}
              eagerLoadFirstImage={eagerLoadFirstImage}
              offerUrl={offerUrl}
              offerLinkIncludesFilters={offerLinkIncludesFilters}
              onImageChange={handleImageChange}
            />
          </ProductPaletteProvider>}
        {isBundleOffer(offer) && offerMetaData?.bundledOfferId &&
          <SearchBundleOffer
            offer={offer}
            filters={filters}
            offerMetaData={offerMetaData as BundleOfferMetaData}
            eagerLoadFirstImage={eagerLoadFirstImage}
            offerUrl={offerUrl}
            onImageChange={handleImageChange}
          />}
        {showCruiseV1Offer &&
          <CruiseTile
            tileType="search"
            offer={offer as App.Offer | App.OfferSummary}
            onImageChange={handleImageChange}
          />}
        {!showCruiseV1Offer && offer.type === OFFER_TYPE_TOUR &&
          <MainOfferTile
            offer={offer as App.Offer | App.OfferSummary}
            eagerLoadFirstImage={eagerLoadFirstImage}
            offerUrl={offerUrl}
            onImageChange={handleImageChange}
          />}
        {isTourV2Offer(offer) && <TourV2SearchTile
          offer={offer}
          filters={filters}
          onImageChange={handleImageChange}
        />}
        {(offer.type === OFFER_TYPE_LAST_MINUTE || offer.type === OFFER_TYPE_ALWAYS_ON) &&
          <ProductPaletteProvider product={offer}>
            <SearchOfferTile
              offer={offer as App.Offer | App.OfferSummary}
              filters={filters}
              offerMetaData={offerMetaData}
              eagerLoadFirstImage={eagerLoadFirstImage}
              offerUrl={offerUrl}
              offerLinkIncludesFilters={offerLinkIncludesFilters}
              onImageChange={handleImageChange}
            />
          </ProductPaletteProvider>}
        {offer.type === OFFER_TYPE_BED_BANK && offerMetaData &&
          <SearchBedbankOffer
            offer={offer as App.BedbankOffer | App.BedbankOfferSummary}
            filters={filters}
            eagerLoadFirstImage={eagerLoadFirstImage}
            offerUrl={offerUrl}
            offerMetaData={offerMetaData}
            onImageChange={handleImageChange}
          />
        }
        {isCruiseOffer(offer) &&
          <CruiseTile
            tileType="search"
            offer={offer}
            onImageChange={handleImageChange}
          />
        }
        {offer.type === 'rental' &&
          <SearchVillaTile
            offer={offer as App.VillaOffer}
            filters={filters}
            offerUrl={offerUrl}
            onImageChange={handleImageChange}
          />
        }
      </OfferLink>
    </SearchTileEventsContext.Provider>
  )
})

SearchTile.defaultProps = {
  productClick: noop,
  offerLinkIncludesFilters: true,
}

export default SearchTile
