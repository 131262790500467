import { definitions } from '@luxuryescapes/contract-trip'
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  UseInfiniteQueryResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query'
import moment, { Moment } from 'moment'
import { useCallback, useContext, useMemo } from 'react'

import * as CruiseKeys from './reactQueryKeys/cruises'
import * as CuratedTripKeys from './reactQueryKeys/curatedTrips'
import * as ExperienceKeys from './reactQueryKeys/experiences'
import * as OfferKeys from './reactQueryKeys/offers'
import * as OrderKeys from './reactQueryKeys/orders'
import * as RecommendationKeys from './reactQueryKeys/recommendations'
import * as SearchKeys from './reactQueryKeys/search'
import { placeById } from './reactQueryKeys/search'
import * as TripMetadataKeys from './reactQueryKeys/tripMetadata'
import * as TripKeys from './reactQueryKeys/trips'
import * as UserSettingsKeys from './reactQueryKeys/userSettings'
import { applyPatchFields } from './utils'

import * as Analytics from 'analytics/analytics'
import { getCruiseOffer } from 'api/cruises'
import { getExperienceById } from 'api/experiences'
import { getAirportsSearch, getAirlines, getFlightPrice } from 'api/flights'
import { getOffersById } from 'api/offer'
import {
  getBrandOrders,
  getOrders,
  getOrderItemFlightDetails,
  getOrderWithPaymentsAndReservations,
  getReservationDetailsForItems,
} from 'api/order'
import * as RecommendationService from 'api/recommendations'
import { getPlaceById, getPlaceByLatLong } from 'api/search'
import { reqGetTourOrderTravellerDetails } from 'api/traveller'
import { Airline } from 'components/Flights/types'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import GeoContext from 'contexts/geoContext'
import { useAppSelector } from 'hooks/reduxHooks'
import dedupeConcat from 'lib/array/arrayConcatDedupe'
import {
  arrayToObject,
  nonNullable,
  uniqueBy,
  updateWhere,
} from 'lib/array/arrayUtils'
import { EmptyObject } from 'lib/object/objectUtils'
import { isRejected } from 'lib/promise/promiseUtils'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { isIOSSel } from 'selectors/configSelectors'
import { clearRecentlySavedTripId } from 'storage/recentSavedTrip'
import {
  createTrip,
  deleteTrip,
  getTrip,
  getTripMetadata,
  getTrips,
  updateTrip,
  refreshTripOrders,
  getTripCollaborators,
  sendTripInvite,
  acceptTripInvite,
  removeCollaborator,
  deleteTripInvite,
  getCuratedTrip,
  getCuratedTripSummary,
  publishTripTemplate,
  unpublishTripTemplate,
  fetchUser,
  submitTripForApproval,
  getCuratedTripMetadata,
  copyTripTemplate,
  fetchCurator,
  updateReviewData,
  editTripInvite,
  editCollaborator,
  recordTripView,
  getLowStockItemsForTrip,
  validateTemplate,
  uploadTripImage,
} from 'tripPlanner/api'
import {
  CuratedTripFilters,
  getCuratedTrips,
  getCuratedTripsWithPagination,
} from 'tripPlanner/api/templates'
import {
  createTripItem,
  createTripItemBatch,
  deleteTripItem,
  deleteTripItemsByOrderId,
  getTripItems,
  updateTripItem,
} from 'tripPlanner/api/tripItem'
import {
  DeleteTripItemError,
  DeleteTripItemsByOrderIdError,
  UpdateTripItemError,
} from 'tripPlanner/api/tripItem/types'
import {
  GetTripCollaboratorsResponse,
  AcceptInviteError,
  CreateTripError,
  DeleteTripError,
  GetTripCollaboratorError,
  GetTripError,
  GetTripMetadataError,
  RefreshTripError,
  RemoveCollaboratorError,
  RevokeInviteError,
  SendInviteError,
  UpdateTripError,
  UploadTripImageError,
  PublishTripError,
  UnpublishTripError,
  SubmitForApprovalError,
  CopyTemplateError,
  GetTripCuratorError,
  EditInviteError,
  EditCollaboratorError,
  GetCuratedTripsResponse,
  RecordTripViewError,
} from 'tripPlanner/api/types'
import {
  FLIGHTS_SEARCH_DAY_MAX_ADVANCE,
  FLIGHTS_SEARCH_DAY_MIN_ADVANCE,
} from 'tripPlanner/config/flights'
import {
  mobileBatchSuccess,
  triggerMobileError,
  mobileSuccess,
  mobileDeleteSuccess,
} from 'tripPlanner/hooks/mobileApi'
import { useWebViewModal } from 'tripPlanner/hooks/useWebViewModal'
import * as Mapper from 'tripPlanner/mappers'
import {
  BasicTrip,
  Collaborator,
  CreateItemsBatchResponse,
  Curator,
  FullTrip,
  GuestRole,
  Invitation,
  RefreshedTrip,
  TripLocation,
  TripMetadata,
  TripSummary,
  TravellerRoom,
} from 'tripPlanner/types/common'
import { TripItem } from 'tripPlanner/types/tripItem'
import {
  getTripViewedTimes,
  sortTripsByMostRecentlyViewedOrUpdated,
} from 'tripPlanner/utils/recentTrips'

export type OfferTypeForTripItemTypes =
  | App.Offer
  | App.OfferSummary
  | Tours.TourV2Offer
  | Tours.TourV2OfferSummary
  | App.BedbankOffer
  | App.BedbankOfferSummary
  | App.CruiseOffer

export type OrderAndOffers = {
  order: App.Order
  offers: Array<OfferTypeForTripItemTypes>
}

export type FlightDetails = {
  journeyFares: {
    tripType: string
    fares: Array<App.JourneyV2>
  }
  travellers?: Array<App.OrderTraveller>
  providerBookingReference?: any
  pnrId?: string
}

// To make multi-query results a bit nicer to work with. Add new fields as needed.
type MultiQueryResults<T> = {
  data: Array<T>
  isLoading: boolean
  isSuccess: boolean
  results: Array<UseQueryResult<T>>
}

const MINUTES = 60 * 1000
const NON_TRIP_STALE_TIME = 4 * MINUTES
const TRIP_STALE_TIME = 4 * MINUTES

export const getBasicTrip = (
  tripId: string,
  queryClient: QueryClient,
): BasicTrip | undefined => {
  const trip = queryClient
    .getQueryData<Array<definitions['basicTrip']>>(TripKeys.lists)
    ?.find((t) => t.id === tripId)

  return trip ? Mapper.basicTrip(trip) : undefined
}

/**
 * Invalidate a query and also cancel any in-progress fetch for that query.
 * If we invalidate a query while a fetch is in progress, the query cache
 * will be written with potentially outdated data after the invalidation
 * has already been done.
 */
export function cancelAndInvalidate(
  queryClient: QueryClient,
  queryKey: QueryKey,
) {
  queryClient.cancelQueries(queryKey)
  queryClient.invalidateQueries(queryKey)
}

export function invalidateTrip(queryClient: QueryClient, tripId: string) {
  cancelAndInvalidate(queryClient, TripKeys.detail(tripId))
  cancelAndInvalidate(queryClient, TripKeys.summary(tripId))
  cancelAndInvalidate(queryClient, TripMetadataKeys.metadata(tripId))
  cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
  cancelAndInvalidate(queryClient, TripKeys.lists)
  cancelAndInvalidate(queryClient, TripKeys.tripItemsList)
}

export function removeTripQueries(queryClient: QueryClient, tripId: string) {
  queryClient.removeQueries(TripKeys.detail(tripId))
  queryClient.removeQueries(TripKeys.summary(tripId))
  queryClient.removeQueries(TripMetadataKeys.metadata(tripId))
  queryClient.removeQueries(TripKeys.collaborator(tripId))
  queryClient.removeQueries(TripKeys.tripItemsList)
  queryClient.invalidateQueries(TripKeys.lists)
}

export function removeAuthedQueries(queryClient: QueryClient) {
  queryClient.removeQueries(TripKeys.all)
  queryClient.removeQueries(OrderKeys.all)
  queryClient.removeQueries(UserSettingsKeys.all)
}

export const TRIP_API_DEFAULT_TRIPS: Array<definitions['basicTrip']> = []
export const TRIP_API_DEFAULT_CURATED_TRIPS: Array<definitions['basicTrip']> =
  []
export const TRIP_API_DEFAULT_TRIP_ITEMS: Array<definitions['tripItem']> = []

export const useTrips = (
  options?: UseQueryOptions<
    Array<definitions['basicTrip']>,
    unknown,
    Array<BasicTrip>
  >,
) => {
  const loggedIn = useAppSelector(selectLoggedIn)

  return useQuery({
    queryKey: loggedIn ? TripKeys.lists : TripKeys.listsLoggedOut,
    queryFn: loggedIn ? () => getTrips() : () => TRIP_API_DEFAULT_TRIPS,
    select: Mapper.basicTrips,
    initialData: loggedIn ? undefined : TRIP_API_DEFAULT_TRIPS,
    placeholderData: TRIP_API_DEFAULT_TRIPS,
    enabled: loggedIn,
    staleTime: TRIP_STALE_TIME,
    ...options,
  })
}

export const useTripItems = (
  options?: UseQueryOptions<
    Array<definitions['tripItem']>,
    unknown,
    Array<TripItem>
  >,
) => {
  const loggedIn = useAppSelector(selectLoggedIn)

  return useQuery({
    queryKey: loggedIn ?
      TripKeys.tripItemsList :
      TripKeys.tripItemsListLoggedOut,
    queryFn: loggedIn ?
        () => getTripItems() :
        () => TRIP_API_DEFAULT_TRIP_ITEMS,
    select: Mapper.tripItems,
    initialData: loggedIn ? undefined : TRIP_API_DEFAULT_TRIP_ITEMS,
    placeholderData: TRIP_API_DEFAULT_TRIP_ITEMS,
    enabled: loggedIn,
    staleTime: TRIP_STALE_TIME,
    ...options,
  })
}

interface UseCuratedTripsOptions
  extends UseQueryOptions<
    Array<definitions['basicTrip']>,
    unknown,
    Array<BasicTrip>
  > {
  filters?: CuratedTripFilters
  limit?: number
}

export const useCuratedTrips = (
  options: UseCuratedTripsOptions = EmptyObject,
) => {
  const { filters = EmptyObject, limit, ...queryOptions } = options
  const regionCode = useAppSelector((state) => state.geo.currentRegionCode)
  const timezoneOffset = new Date().getTimezoneOffset() / 60
  return useQuery({
    queryKey: CuratedTripKeys.lists(regionCode, timezoneOffset, filters, limit),
    queryFn: () => getCuratedTrips(regionCode, timezoneOffset, filters, limit),
    select: Mapper.basicTrips,
    initialData: undefined,
    placeholderData: TRIP_API_DEFAULT_CURATED_TRIPS,
    staleTime: TRIP_STALE_TIME,
    ...queryOptions,
  })
}

export const usePaginatedCuratedTrips = () => {
  const regionCode = useAppSelector((state) => state.geo.currentRegionCode)
  const timezoneOffset = new Date().getTimezoneOffset() / 60
  const fetchCuratedTrips = async({ pageParam = 0 }) =>
    await getCuratedTripsWithPagination(pageParam, regionCode, timezoneOffset)

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isSuccess,
    ...otherOptions
  }: UseInfiniteQueryResult<GetCuratedTripsResponse, null> = useInfiniteQuery({
    queryKey: CuratedTripKeys.paginatedTemplates,
    queryFn: fetchCuratedTrips,
    initialData: undefined,
    staleTime: TRIP_STALE_TIME,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasMore) {
        return lastPage?.offset
      }
      return undefined
    },
  })

  const trips = useMemo(() => {
    if (isSuccess) {
      const pages = data?.pages ?? []
      return pages.flatMap((page) => {
        const results = page?.result
        return results?.map(Mapper.basicTrip)
      })
    }
    return []
  }, [isSuccess, data])

  return {
    data: trips,
    hasMore: data?.pages[data.pages?.length - 1].hasMore,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...otherOptions,
  }
}

const filterEditableTrips = (data: Array<definitions['basicTrip']>) => {
  const trips = Mapper.basicTrips(data)
  return trips.filter((trip) => trip.role !== 'VIEWER')
}

const filterOwnedTrips = (data: Array<definitions['basicTrip']>) => {
  const trips = Mapper.basicTrips(data)
  return trips.filter((trip) => trip.role === 'OWNER')
}

export const useEditableTrips = (
  options?: UseQueryOptions<
    Array<definitions['basicTrip']>,
    unknown,
    Array<BasicTrip>
  >,
) => {
  return useTrips({
    select: filterEditableTrips,
    ...options,
  })
}

export const useOwnedTrips = (
  options?: UseQueryOptions<
    Array<definitions['basicTrip']>,
    unknown,
    Array<BasicTrip>
  >,
) => {
  return useTrips({
    select: filterOwnedTrips,
    ...options,
  })
}

export const useRecentTrips = (
  options?: Parameters<typeof useTrips>[0],
): UseQueryResult<Array<BasicTrip>, unknown> => {
  const lastViewedTimes = getTripViewedTimes()
  return useTrips({
    select: useCallback(
      (data: Array<definitions['basicTrip']>) => {
        const trips = Mapper.basicTrips(data)
        return sortTripsByMostRecentlyViewedOrUpdated(trips, lastViewedTimes)
      },
      [lastViewedTimes],
    ),
    ...options,
  })
}

export const useTrip = ({
  tripId,
  withPlaceholder = true,
  ...options
}: {
  tripId?: string
  withPlaceholder?: boolean
} & UseQueryOptions<
  definitions['fullTrip'],
  GetTripError,
  FullTrip,
  ReturnType<typeof TripKeys.detail>
>): UseQueryResult<FullTrip, GetTripError> => {
  const queryClient = useQueryClient()
  return useQuery(TripKeys.detail(tripId!), () => getTrip(tripId!), {
    select: Mapper.fullTrip,
    enabled: !!tripId,
    staleTime: TRIP_STALE_TIME,
    placeholderData: withPlaceholder ?
        () => {
          const basicTrip = queryClient
            .getQueryData<Array<definitions['basicTrip']>>(TripKeys.lists)
            ?.find((d) => d.id === tripId)

          if (!basicTrip) {
            return undefined
          }
          const { bookmarkIdSets, ...rest } = basicTrip
          return {
            items: [],
            users: [],
            destinations: [],
            isAgentAttributed: false,
            ...rest,
          }
        } :
      undefined,
    ...options,
  })
}

export const useCuratedTrip = ({
  tripId,
  ...options
}: {
  tripId: string
} & UseQueryOptions<
  definitions['fullTrip'],
  GetTripError,
  FullTrip,
  ReturnType<typeof CuratedTripKeys.detail>
>): UseQueryResult<FullTrip, GetTripError> => {
  return useQuery({
    queryKey: CuratedTripKeys.detail(tripId),
    queryFn: () => getCuratedTrip(tripId),
    select: Mapper.fullTrip,
    ...options,
  })
}

export const useTripMetadataPrefetch = ({ tripId }: { tripId: string }) => {
  const queryClient = useQueryClient()

  queryClient.prefetchQuery(TripMetadataKeys.metadata(tripId), () =>
    getTripMetadata(tripId),
  )
}

export const useTripMetadata = ({
  tripId,
  onSuccess,
  onError,
}: {
  tripId: string
  onSuccess?: () => void
  onError?: (err: GetTripMetadataError) => void
}): UseQueryResult<TripMetadata, GetTripMetadataError> => {
  return useQuery(
    TripMetadataKeys.metadata(tripId),
    () => getTripMetadata(tripId),
    {
      select: useCallback((data) => Mapper.tripMetadata(data), []),
      onSuccess,
      onError,
      enabled: !!tripId,
    },
  )
}

export const useCuratedTripSummary = ({
  tripId,
}: {
  tripId: string
}): UseQueryResult<TripSummary, GetTripError> => {
  return useQuery(
    CuratedTripKeys.summary(tripId),
    () => getCuratedTripSummary(tripId),
    {
      select: Mapper.tripSummary,
    },
  )
}

export const useCuratedTripMetadata = ({
  tripId,
}: {
  tripId: string
}): UseQueryResult<TripMetadata, GetTripError> => {
  return useQuery(
    CuratedTripKeys.metadata(tripId),
    () => getCuratedTripMetadata(tripId),
    {
      select: Mapper.tripMetadata,
    },
  )
}

export const useTripAirports = ({
  tripId,
}: {
  tripId: string
}): Array<App.AirportLocation> => {
  const { data: tripMetadata } = useTripMetadata({ tripId })

  return uniqueBy(
    (tripMetadata?.locations || []).flatMap((location) => {
      return location.airports.map(
        (airport): App.AirportLocation => ({
          ...airport,
          cityAirportName: airport.cityAirportName || '',
        }),
      )
    }),
    (airport) => airport.airportCode,
  )
}

const DEFAULT_LOCATIONS: Array<TripLocation> = []

export const useTripDestinations = ({
  tripId,
  includesTripDestinations = true,
  includesTripLocations = true,
  prioritise = 'destinations',
}: {
  tripId: string
  includesTripDestinations?: boolean
  includesTripLocations?: boolean
  prioritise?: 'destinations' | 'locations'
}): Array<App.SearchDestination> => {
  const { data: tripMetadata } = useTripMetadata({ tripId })
  const { data: trip } = useTrip({ tripId })

  const listOfPlaceIdsFromTripDestinations = includesTripDestinations ?
    trip?.destinationsGeo?.map((dest) => dest.lePlaceId) || [] :
      []

  const listOfPlaceIdsFromTripLocations = includesTripLocations ?
      (tripMetadata?.locations || DEFAULT_LOCATIONS)
        ?.filter((location) => !location.isOrigin)
        .map((dest) => dest.placeGroup.lePlaceId) :
      []

  let listOfPlaceIds: Array<string> = []

  if (prioritise === 'destinations') {
    listOfPlaceIds = dedupeConcat(
      listOfPlaceIdsFromTripDestinations,
      listOfPlaceIdsFromTripLocations,
    )
  } else if (prioritise === 'locations') {
    listOfPlaceIds = dedupeConcat(
      listOfPlaceIdsFromTripLocations,
      listOfPlaceIdsFromTripDestinations,
    )
  }

  const results = useQueries({
    queries: listOfPlaceIds.map((placeId) => ({
      queryKey: placeById(placeId),
      queryFn: () =>
        getPlaceById(placeId).then<App.SearchDestination>((place) => ({
          searchType: 'destination',
          destinationType: place.type ?? '',
          value: place.id,
          format: {
            mainText: place.name,
            secondaryText: place.canonicalName,
          },
        })),
      staleTime: Infinity,
    })),
  })

  if (results.find((result) => result.isLoading)) {
    return []
  }

  return results.reduce<Array<App.SearchDestination>>((acc, result) => {
    if (result.data) {
      acc.push(result.data)
    }
    return acc
  }, [])
}

export const useRefreshTripOrders = ({
  onSuccess,
  onError,
}: {
  onSuccess: (updateCount: number) => void
  onError: (err: RefreshTripError) => void
}): UseMutationResult<
  RefreshedTrip,
  RefreshTripError,
  Parameters<typeof refreshTripOrders>[0]
> => {
  const queryClient = useQueryClient()
  return useMutation(
    (params) => refreshTripOrders(params).then(Mapper.refreshedTrip),
    {
      retry: false,
      onSuccess: (data, tripId) => {
        const { changeCount, ...trip } = data
        if (changeCount > 0) {
          queryClient.setQueryData<FullTrip>(TripKeys.detail(tripId), trip)
        }
        onSuccess(changeCount)
      },
      onError,
    },
  )
}

export const useRecordTripView = (): UseMutationResult<
  null,
  RecordTripViewError,
  Parameters<typeof recordTripView>[0]
> => {
  const queryClient = useQueryClient()
  return useMutation((tripId) => recordTripView(tripId), {
    retry: false,
    onSuccess: (_data, tripId) => {
      // Update `interactedAt` for local copies of trip
      queryClient.setQueryData<Array<BasicTrip>>(
        TripKeys.lists,
        updateWhere<BasicTrip>(
          (trip) => trip.id === tripId,
          (trip) => ({
            ...trip,
            interactedAt: moment(),
          }),
        ),
      )
      queryClient.setQueryData<FullTrip>(
        TripKeys.detail(tripId),
        (trip) =>
          trip && {
            ...trip,
            interactedAt: moment(),
          },
      )
    },
  })
}

interface UseExperienceParams {
  experienceId: string | undefined
  currentCurrency: string
  currentRegionCode: string
}

export const useExperience = ({
  experienceId,
  currentCurrency,
  currentRegionCode,
}: UseExperienceParams): UseQueryResult<App.ExperienceOffer> => {
  return useQuery(
    ExperienceKeys.detail(currentCurrency, experienceId),
    async() =>
      experienceId ?
        getExperienceById(experienceId, {
          currentCurrency,
          currentRegionCode,
        }) :
        Promise.resolve(undefined),
    {
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useOrdersByMemberId = (
  regionCode: string,
  memberId?: string,
  enabled: boolean = true,
  pageSize: number = 50,
): UseQueryResult<Array<App.Order>> => {
  return useQuery(
    OrderKeys.detailsByMemberId(regionCode, memberId),
    async() => {
      if (!memberId) {
        return []
      }

      // The following code has been adopted from src/client/actions/OrderActions.ts:fetchOrdersForCurrentUser.
      // The getDepositDetails has been left out as it doesn't have a use-case in TripPlanner yet, once we do
      // we can add that too.
      const ordersPerMemberId: Array<App.Order> = []
      let end = false
      let page = 1
      while (!end) {
        const results = await getOrders(memberId, regionCode, {
          page,
          pageSize,
        })
        const ordersWithReservationDetails = await Promise.all(
          results.orders.map((order) =>
            getReservationDetailsForItems(
              order.items.filter((item) => item.reservationMade),
              order.id,
            ).then((reservations) => {
              // now we...*shudder* mutate the order with our reservation details
              const reservationsById = arrayToObject(
                reservations,
                (res) => res.itemId,
              )
              order.items = order.items.map((item) => {
                if (reservationsById[item.id]) {
                  return {
                    ...item,
                    reservation: reservationsById[item.id],
                  }
                }
                return item
              })
              return order
            }),
          ),
        )

        ordersPerMemberId.push(...ordersWithReservationDetails)

        end = page * pageSize >= results.total
        page++
      }

      const brandOrders = await getBrandOrders(memberId)
      if (brandOrders.length > 0) {
        ordersPerMemberId.push(...brandOrders)
      }

      return ordersPerMemberId
    },
    {
      enabled: !!memberId && enabled,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useFlightDetailsForItem = ({
  orderId,
  itemId,
  enabled = true,
}: {
  orderId: string
  itemId: string
  enabled?: boolean
}): UseQueryResult<FlightDetails> => {
  return useQuery(
    OrderKeys.flightDetailsOrderAndItem(orderId, itemId),
    () => getOrderItemFlightDetails({ orderId, itemId }),
    {
      enabled,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useFlightDetailsForItems = ({
  itemIds,
  orderId,
  enabled = true,
}: {
  itemIds: Array<string>
  orderId: string
  enabled?: boolean
}): UseQueryResult<Array<FlightDetails>> => {
  const queryClient = useQueryClient()

  return useQuery(
    OrderKeys.flightDetailsOrderAndItems(orderId, itemIds),
    async() => {
      if (itemIds.length === 0) {
        return []
      }

      return await Promise.all(
        itemIds.map((itemId) =>
          getOrderItemFlightDetails({
            itemId,
            orderId,
          }).then((resp) => {
            queryClient.setQueryData(
              OrderKeys.flightDetailsOrderAndItem(orderId, itemId),
              resp,
            )
            return resp
          }),
        ),
      )
    },
    {
      enabled,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

interface UseExperiencesParams {
  currentCurrency: string
  experienceIds: Array<string>
  currentRegionCode: string
}

export const useExperiences = ({
  currentCurrency,
  experienceIds,
  currentRegionCode,
}: UseExperiencesParams): MultiQueryResults<App.ExperienceOffer> => {
  const results = useQueries({
    queries: experienceIds.map((experienceId) => ({
      queryKey: ExperienceKeys.detail(currentCurrency, experienceId),
      queryFn: () =>
        getExperienceById(experienceId, {
          currentCurrency,
          currentRegionCode,
        }),
      staleTime: NON_TRIP_STALE_TIME,
    })),
  })

  return {
    data: nonNullable(results.map((result) => result.data)),
    isLoading: results.some((result) => result.isLoading),
    isSuccess: results.every((result) => result.isSuccess),
    results,
  }
}

export const useLPPRecommendations = (
  offerId: string | undefined,
  currentRegionCode: string,
  isLuxPlusMember: boolean,
  checkIn: Moment | undefined,
  checkOut: Moment | undefined,
): UseQueryResult<Array<App.Recommendation>> => {
  return useQuery(
    RecommendationKeys.detail(
      offerId,
      checkIn?.format(ISO_DATE_FORMAT) || '',
      checkOut?.format(ISO_DATE_FORMAT) || '',
    ),
    async() => {
      if (!checkIn) {
        const response = await RecommendationService.getRecommendations(
          offerId!,
          currentRegionCode,
          isLuxPlusMember,
        )
        return response.recommendations
      } else {
        const response = await RecommendationService.getCalendarRecommendations(
          offerId!,
          currentRegionCode,
          isLuxPlusMember,
          {
            checkIn: checkIn.format(ISO_DATE_FORMAT),
            duration: checkOut ? checkOut.diff(checkIn, 'day') : undefined,
          },
        )
        return response.recommendations
      }
    },
    {
      placeholderData: [],
      enabled: !!offerId,
    },
  )
}

export const useOffersWithCollectionAPIBecauseToursV2AreNotReturnedFromTheOtherOne =
  (
    offerIds: Array<string>,
    region: string,
  ): UseQueryResult<Array<App.Offer | Tours.TourV2Offer | App.BedbankOffer>> => {
    return useQuery(
      OfferKeys.collection(region, offerIds),
      async() => {
        if (offerIds.length === 0) {
          return []
        }

        return await getOffersById(offerIds, region)
      },
      {
        staleTime: NON_TRIP_STALE_TIME,
        placeholderData: [],
      },
    )
  }

export const useOrderTravellers = (
  orderId: string | undefined,
): UseQueryResult<Array<App.OrderTraveller>> =>
  useQuery(
    ['orderTravellers', orderId],
    () =>
      orderId ?
        reqGetTourOrderTravellerDetails(orderId).then(
          (resp) => resp.travellers,
        ) :
        Promise.resolve(undefined),
    {
      enabled: !!orderId,
      staleTime: NON_TRIP_STALE_TIME,
    },
  )

export const useOrder = (
  orderId: string | undefined,
): UseQueryResult<App.Order | undefined, unknown> =>
  useQuery(
    OrderKeys.detail(orderId),
    () =>
      orderId ?
        getOrderWithPaymentsAndReservations(orderId) :
        Promise.resolve(undefined),
    {
      staleTime: NON_TRIP_STALE_TIME,
    },
  )

export const useCruise = (
  cruiseId: string | undefined,
): UseQueryResult<App.CruiseOffer> => {
  const { currentRegionCode } = useContext(GeoContext)
  return useQuery(
    CruiseKeys.detail(cruiseId),
    () =>
      cruiseId ?
        getCruiseOffer(cruiseId, currentRegionCode) :
        Promise.resolve(undefined),
    {
      staleTime: NON_TRIP_STALE_TIME,
    },
  )
}

export const useCruises = (
  cruiseIds: Array<string>,
): MultiQueryResults<App.CruiseOffer> => {
  const { currentRegionCode } = useContext(GeoContext)
  const results = useQueries({
    queries: cruiseIds.map((cruiseId) => ({
      queryKey: CruiseKeys.detail(cruiseId),
      queryFn: () => getCruiseOffer(cruiseId, currentRegionCode),
    })),
  })

  return {
    data: nonNullable(results.map((result) => result.data)),
    isLoading: results.some((result) => result.isLoading),
    isSuccess: results.every((result) => result.isSuccess),
    results,
  }
}

export const useCreateTrip = (
  options?: UseMutationOptions<
    FullTrip,
    CreateTripError,
    Parameters<typeof createTrip>[0]
  >,
): UseMutationResult<
  FullTrip,
  CreateTripError,
  Parameters<typeof createTrip>[0],
  { previousTrips: Array<BasicTrip> | undefined }
> => {
  const queryClient = useQueryClient()

  return useMutation((params) => createTrip(params).then(Mapper.fullTrip), {
    retry: false,
    ...options,
    // When mutate is called:
    onMutate: async(createTripArgs) => {
      // Clear the recently saved trip ID in case the user wants to save to this trip next
      clearRecentlySavedTripId()

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(TripKeys.lists)

      // Snapshot the previous value
      const previousTrips = queryClient.getQueryData<Array<BasicTrip>>(
        TripKeys.lists,
      )

      // Optimistically update to the new value
      queryClient.setQueryData<Array<BasicTrip>>(TripKeys.lists, (old) => {
        const { startDate, endDate, ...restArgs } = createTripArgs
        const newTrip: BasicTrip = {
          id: '',
          createdAt: moment(),
          updatedAt: moment(),
          interactedAt: moment(),
          role: 'OWNER',
          bookmarkIdSets: [],
          plannedIdSets: [],
          startDate,
          endDate,
          itemCount: 0,
          isActivelyPlanned: false,
          isConciergeTrip: false,
          ...restArgs,
        }
        if (!old) return [newTrip]
        return [...old, newTrip]
      })

      await options?.onMutate?.(createTripArgs)

      // Return a context object with the snapshotted value
      return { previousTrips }
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error, variables, context) => {
      queryClient.setQueryData(TripKeys.lists, context?.previousTrips)
      options?.onError?.(error, variables, context)
    },
    onSuccess: (trip, variables, context) => {
      queryClient.prefetchQuery(TripKeys.detail(trip.id), () =>
        getTrip(trip.id),
      )
      options?.onSuccess?.(trip, variables, context)
    },
    onSettled: (trip, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.lists)
      options?.onSettled?.(trip, error, variables, context)
    },
  })
}

export const useDeleteTrip = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (err: DeleteTripError) => void
}): UseMutationResult<
  null,
  DeleteTripError,
  string,
  { previousTrips: Array<BasicTrip> | undefined }
> => {
  const queryClient = useQueryClient()

  return useMutation(deleteTrip, {
    onMutate: async(tripId) => {
      // Clear the recently saved trip ID to avoid trying to auto-save to deleted trip
      clearRecentlySavedTripId()

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(TripKeys.lists)
      await queryClient.cancelQueries(TripKeys.detail(tripId))

      // Snapshot the previous value
      const previousTrips = queryClient.getQueryData<Array<BasicTrip>>(
        TripKeys.lists,
      )

      // Optimistically update to the new value
      queryClient.setQueryData<Array<BasicTrip>>(TripKeys.lists, (old) => {
        if (!old) return []
        return [...old.filter((trip) => trip.id !== tripId)]
      })

      // Return a context object with the snapshotted value
      return { previousTrips }
    },
    onError: (err, _newTrip, context) => {
      queryClient.setQueryData(TripKeys.lists, context?.previousTrips)
      onError(err)
    },
    onSuccess: (_data, tripId) => {
      queryClient.removeQueries(TripKeys.detail(tripId))
      onSuccess()
    },
    onSettled: () => {
      cancelAndInvalidate(queryClient, TripKeys.lists)
    },
  })
}

export const useCreateTripItemBatch = (
  options?: UseMutationOptions<
    CreateItemsBatchResponse,
    Error,
    Parameters<typeof createTripItemBatch>[0]
  >,
): UseMutationResult<
  definitions['createItemsBatchResponse'],
  Error,
  Parameters<typeof createTripItemBatch>[0]
> => {
  const queryClient = useQueryClient()
  const isWebViewModal = useWebViewModal()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation((params) => createTripItemBatch(params), {
    retry: false,
    ...options,
    onSuccess: (response, variables, context) => {
      queryClient.setQueryData(TripKeys.detail(variables.tripId), response)
      isIOS && mobileBatchSuccess(isWebViewModal, variables.tripId, response)
      options?.onSuccess?.(
        Mapper.createItemsBatchResponse(response),
        variables,
        context,
      )
    },
    onError: (error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.detail(variables.tripId))
      isIOS && triggerMobileError(error.message)
      options?.onError?.(error, variables, context)
    },
    onSettled: (response, error, variables, context) => {
      if (response) {
        invalidateTrip(queryClient, response.id)
      }

      options?.onSettled?.(
        response && Mapper.createItemsBatchResponse(response),
        error,
        variables,
        context,
      )
    },
  })
}

export const useCreateTripItem = (
  options?: UseMutationOptions<
    TripItem,
    Error,
    Parameters<typeof createTripItem>[0],
    definitions['basicTrip']
  >,
): UseMutationResult<
  TripItem,
  Error,
  Parameters<typeof createTripItem>[0],
  definitions['basicTrip']
> => {
  const queryClient = useQueryClient()
  const isWebViewModal = useWebViewModal()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation(
    (params) =>
      createTripItem(params).then((tripItem) =>
        Mapper.tripItem(tripItem, params.tripId),
      ),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
      onSuccess: (res, args) => {
        // If an item can be created, then the trip already exists in our basic trip list cache
        const trip = queryClient
          .getQueryData<Array<definitions['basicTrip']>>(TripKeys.lists)
          ?.find((t) => t.id === args.tripId)
        isIOS && mobileSuccess(isWebViewModal, res?.tripId, res?.id)
        options?.onSuccess?.(res, args, trip)
        if (
          args.tripItem.sourceType === 'NA' ||
          args.tripItem.sourceType === 'Explore'
        ) {
          Analytics.trackClientEvent({
            subject: 'add-custom-trip-item',
            action: 'clicked',
            category: 'logging',
            type: 'operational',
            optimizelyEventId: '28542940091',
            optimizelyEventKey: 'add-custom-trip-item',
          })
        }
      },
      onError: (error, args, trip) => {
        isIOS && triggerMobileError(error.message)
        options?.onError?.(error, args, trip)
      },
    },
  )
}

export const useUpdateTripItem = (
  options?: UseMutationOptions<
    TripItem,
    UpdateTripItemError,
    Parameters<typeof updateTripItem>[0]
  >,
): UseMutationResult<
  TripItem,
  UpdateTripItemError,
  Parameters<typeof updateTripItem>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      updateTripItem(params).then((tripItem) =>
        Mapper.tripItem(tripItem, params.tripId),
      ),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
    },
  )
}

export const useDeleteTripItem = (
  options?: UseMutationOptions<
    null,
    DeleteTripItemError,
    Parameters<typeof deleteTripItem>[0],
    BasicTrip
  >,
): UseMutationResult<
  null,
  DeleteTripItemError,
  Parameters<typeof deleteTripItem>[0],
  BasicTrip
> => {
  const queryClient = useQueryClient()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation(deleteTripItem, {
    retry: false,
    ...options,
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
    onSuccess: (res, args) => {
      isIOS && mobileDeleteSuccess(args.tripId, args.tripItemId)
      options?.onSuccess?.(res, args, getBasicTrip(args.tripId, queryClient))
    },
    onError: (res, args) => {
      isIOS && triggerMobileError(res.error)
      options?.onError?.(res, args, getBasicTrip(args.tripId, queryClient))
    },
  })
}

interface MultiItemDeleteArgs {
  tripId: string
  itemIds: Array<string>
}

export const useDeleteTripItems = (
  options?: UseMutationOptions<
    null,
    DeleteTripItemError,
    MultiItemDeleteArgs,
    BasicTrip
  >,
): UseMutationResult<
  null,
  DeleteTripItemError,
  MultiItemDeleteArgs,
  BasicTrip
> => {
  const queryClient = useQueryClient()

  // Reminder to make this a transaction on BE for bookmark api
  return useMutation(
    ({ tripId, itemIds }) =>
      Promise.allSettled(
        itemIds.map((tripItemId) => deleteTripItem({ tripId, tripItemId })),
      ).then((results) => {
        const rejected = results.find(isRejected)
        if (rejected) {
          throw rejected.reason
        }
        return null
      }),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
      onSuccess: (data, vars) => {
        // If the trip can be saved to, or removed from, then it already exists in our basic trip list cache
        const trip = getBasicTrip(vars.tripId, queryClient)
        options?.onSuccess?.(data, vars, trip)
      },
    },
  )
}

export const useDeleteTripItemsBasedOnOrderId = (
  options?: UseMutationOptions<
    null,
    DeleteTripItemsByOrderIdError,
    Parameters<typeof deleteTripItemsByOrderId>[0]
  >,
): UseMutationResult<
  null,
  DeleteTripItemsByOrderIdError,
  Parameters<typeof deleteTripItemsByOrderId>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(deleteTripItemsByOrderId, {
    retry: false,
    ...options,
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

interface UpdateParams {
  tripId: string
  name?: string
  startDate?: Moment | undefined | null
  endDate?: Moment | undefined | null
  imageId?: string
  originPlaceId?: string | null
  destinationPlaceIds?: Array<string>
  travellerRooms?: Array<TravellerRoom>
}

interface UpdateContext {
  previousTrips: Array<BasicTrip> | undefined
  previousTrip: FullTrip | undefined
}

export const useUpdateTrip = (
  options?: UseMutationOptions<
    definitions['fullTrip'],
    UpdateTripError,
    UpdateParams
  >,
): UseMutationResult<
  definitions['fullTrip'],
  UpdateTripError,
  UpdateParams,
  UpdateContext
> => {
  const queryClient = useQueryClient()

  return useMutation<
    definitions['fullTrip'],
    UpdateTripError,
    UpdateParams,
    UpdateContext
  >((args) => updateTrip(args), {
    retry: false,
    ...options,
    onMutate: async({ tripId, ...props }) => {
      const args = props

      await queryClient.cancelQueries(TripKeys.detail(tripId))
      await queryClient.cancelQueries(TripKeys.lists)

      const previousTrips = queryClient.getQueryData<Array<BasicTrip>>(
        TripKeys.lists,
      )
      if (previousTrips) {
        const currentTrips: Array<BasicTrip> = []
        previousTrips.forEach((val) =>
          currentTrips.push(Object.assign({}, val)),
        )

        const trip = currentTrips.find((trip) => trip.id === tripId)
        if (trip) {
          const modifiedTrip = applyPatchFields(trip, args)
          const tripIndex = currentTrips.indexOf(trip)
          currentTrips[tripIndex] = modifiedTrip
          queryClient.setQueryData(TripKeys.lists, currentTrips)
        }
      }

      const previousTrip = queryClient.getQueryData<FullTrip>(['trip', tripId])
      if (previousTrip) {
        queryClient.setQueryData(
          TripKeys.detail(tripId),
          applyPatchFields(previousTrip, args),
        )
      }

      return { previousTrips, previousTrip }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(TripKeys.lists, context?.previousTrips)
      queryClient.setQueryData(
        TripKeys.detail(variables.tripId),
        context?.previousTrip,
      )
      options?.onError?.(error, variables, context)
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(TripKeys.detail(variables.tripId), data)
      options?.onSuccess?.(data, variables, context)
    },
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

type ImageUploadParams = Parameters<typeof uploadTripImage>[0]

export const useUploadTripImage = (
  options?: UseMutationOptions<
    FullTrip,
    UploadTripImageError,
    ImageUploadParams
  >,
): UseMutationResult<FullTrip, UploadTripImageError, ImageUploadParams> => {
  const queryClient = useQueryClient()

  return useMutation((args) => uploadTripImage(args).then(Mapper.fullTrip), {
    retry: false,
    ...options,
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

export const useAirport = ({
  code,
  ...options
}: Omit<
  UseQueryOptions<
    Array<App.AirportLocation> | undefined,
    unknown,
    App.AirportLocation | undefined
  > & {
    code: string
  },
  'select'
>) => {
  return useQuery<
    Array<App.AirportLocation> | undefined,
    unknown,
    App.AirportLocation | undefined
  >(['airport', code], () => getAirportsSearch(code), {
    select: (data) =>
      data ? data.find((d) => d.airportCode === code) : undefined,
    staleTime: NON_TRIP_STALE_TIME,
    ...options,
  })
}

export const useAirports = ({ codes }: { codes: Array<string> }) => {
  return useQueries<Array<App.AirportLocation>>({
    queries: codes.map((code) => ({
      queryKey: ['airport', code],
      queryFn: () => getAirportsSearch(code),
      select: (data: any) =>
        data ? data.find((d) => d.airportCode === code) : undefined,
      staleTime: NON_TRIP_STALE_TIME,
    })),
    // TODO: Figure out how to use the type correctly so we don't need to use `as`.
    // I tried, but couldn't figure it out.
  }) as Array<UseQueryResult<App.AirportLocation | undefined, unknown>>
}

export const useAirlineByName = ({
  airlineName,
  enabled,
  onError,
  onSuccess,
}: {
  airlineName: string | undefined
  enabled?: boolean
  onSuccess?: (airline: Airline | undefined) => void
  onError?: () => void
}): UseQueryResult<Airline | undefined, unknown> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['airline', airlineName],
    async() => {
      if (!airlineName) {
        return undefined
      }
      const airlines = await getAirlines(airlineName)

      airlines.forEach((airline) => {
        queryClient.setQueryData(['airline', airline.airlineCode], airline)
        queryClient.setQueryData(['airline', airline.airlineName], airline)
      })

      return airlines.find((airline) => airline.airlineName === airlineName)
    },
    { staleTime: NON_TRIP_STALE_TIME, onSuccess, onError, enabled },
  )
}

export const useAirlineByCode = ({
  code,
  onError,
  onSuccess,
}: {
  code: string | undefined
  onSuccess?: (airline: Airline | undefined) => void
  onError?: () => void
}): UseQueryResult<Airline | undefined, unknown> => {
  const queryClient = useQueryClient()

  return useQuery(
    ['airline', code],
    async() => {
      if (!code) {
        return undefined
      }
      const airlines = await getAirlines(code)

      airlines.forEach((airline) => {
        queryClient.setQueryData(['airline', airline.airlineCode], airline)
        queryClient.setQueryData(['airline', airline.airlineName], airline)
      })

      return airlines.find((airline) => airline.airlineCode === code)
    },
    { staleTime: NON_TRIP_STALE_TIME, onSuccess, onError },
  )
}

export const useLEPlaceByCoordinates = (
  lat: number,
  lng: number,
): UseQueryResult<App.Place, unknown> => {
  return useQuery(
    SearchKeys.placeByCoordinates(lat, lng),
    () => getPlaceByLatLong(lat, lng),
    {
      staleTime: Infinity,
    },
  )
}

export type TripCollaboratorReturn = {
  collaborators: Array<Collaborator>
  invitations: Array<Invitation>
}

export const useTripCollaborators = ({
  tripId,
  options,
}: {
  tripId: string
  options?: Omit<
    UseQueryOptions<
      GetTripCollaboratorsResponse['result'],
      GetTripCollaboratorError,
      TripCollaboratorReturn
    >,
    'queryFn'
  >
}): UseQueryResult<TripCollaboratorReturn, GetTripCollaboratorError> => {
  return useQuery({
    queryKey: TripKeys.collaborator(tripId),
    queryFn: () => getTripCollaborators(tripId),
    staleTime: TRIP_STALE_TIME,
    enabled: !!tripId,
    ...options,
  })
}

type SendInviteArgs = {
  tripId: string
  email: string
  role: GuestRole
}

export const useSendInvite = (
  tripId: string,
  options?: UseMutationOptions<null, SendInviteError, SendInviteArgs>,
): UseMutationResult<null, SendInviteError, SendInviteArgs> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ email, role }) => sendTripInvite({ tripId, email, role }),
    ...options,
    onSettled: (data, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

export const useAcceptInvite = (
  tripId: string,
  inviteId: string,
  options?: UseMutationOptions<FullTrip, AcceptInviteError, void>,
): UseMutationResult<FullTrip, AcceptInviteError, void> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () =>
      acceptTripInvite({ tripId, inviteId }).then(Mapper.fullTrip),
    ...options,
    onSettled: (data, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.all)
      cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

type RemoveCollaboratorArgs = {
  userId: string
}

export const useRemoveCollaborator = (
  tripId: string,
  options?: UseMutationOptions<
    null,
    RemoveCollaboratorError,
    RemoveCollaboratorArgs
  >,
): UseMutationResult<null, RemoveCollaboratorError, RemoveCollaboratorArgs> => {
  const queryClient = useQueryClient()

  const userId = useAppSelector(
    (state: App.State) => state.auth.account.memberId,
  )

  return useMutation({
    mutationFn: ({ userId }) => removeCollaborator({ userId, tripId }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (variables.userId === userId) {
        // Removed self - user no longer has access to trip
        removeTripQueries(queryClient, tripId)
      }
      options?.onSuccess?.(data, variables, context)
    },
    onSettled: (data, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

type RevokeInviteArgs = {
  invitationId: string
}

export const useRevokeInvite = (
  tripId: string,
  options?: UseMutationOptions<null, RevokeInviteError, RevokeInviteArgs>,
): UseMutationResult<null, RevokeInviteError, RevokeInviteArgs> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ invitationId }) =>
      deleteTripInvite({ invitationId, tripId }),
    ...options,
    onSettled: (data, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

type EditInviteArgs = {
  invitationId: string
  role: GuestRole
}

export const useEditInvite = (
  tripId: string,
  options?: UseMutationOptions<null, EditInviteError, EditInviteArgs>,
): UseMutationResult<null, EditInviteError, EditInviteArgs> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ invitationId, role }) =>
      editTripInvite({ invitationId, tripId, role }),
    ...options,
    onSettled: (data, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

type EditCollaboratorArgs = {
  userId: string
  role: GuestRole
}

export const useEditCollaborator = (
  tripId: string,
  options?: UseMutationOptions<
    null,
    EditCollaboratorError,
    EditCollaboratorArgs
  >,
): UseMutationResult<null, EditCollaboratorError, EditCollaboratorArgs> => {
  const queryClient = useQueryClient()

  const userId = useAppSelector((state) => state.auth.account.memberId)

  return useMutation({
    mutationFn: ({ userId, role }) =>
      editCollaborator({ userId, tripId, role }),
    ...options,
    onSettled: (data, error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
      if (variables.userId === userId) {
        // If user has edited themselves, invalidate the trip so we get their current role
        cancelAndInvalidate(queryClient, TripKeys.all)
        cancelAndInvalidate(queryClient, TripKeys.detail(tripId))
      }
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

type ReviewArgs = {
  userId: string
  name: string
  position?: string
  imageId?: string
  description?: string
}

type UpdateReviewArgs = {
  name?: string
  position?: string
  imageId?: string
  description?: string
}

export const usePublish = ({
  tripId,
  options,
}: {
  tripId: string
  options?: UseMutationOptions<null, PublishTripError>
}) => {
  const queryClient = useQueryClient()
  const onSuccess = () => {
    invalidateTrip(queryClient, tripId)
  }

  return useMutation({
    mutationFn: () => publishTripTemplate({ tripId }),
    onSuccess,
    ...options,
  })
}

export const useUnpublish = ({
  tripId,
  options,
}: {
  tripId: string
  options?: UseMutationOptions<null, UnpublishTripError>
}) => {
  const queryClient = useQueryClient()
  const onSuccess = () => {
    invalidateTrip(queryClient, tripId)
  }

  return useMutation({
    mutationFn: () => unpublishTripTemplate({ tripId }),
    onSuccess,
    ...options,
  })
}

export const useUserId = ({
  email,
  options,
}: {
  email: string
  options?: UseQueryOptions
}) => {
  return useQuery({
    queryKey: TripKeys.user(email),
    queryFn: () => fetchUser({ email }),
    select: (data: any) => (data ? data.id_member : undefined),
    ...options,
  })
}

export const useSubmitForApproval = ({
  tripId,
  options,
}: {
  tripId: string
  options?: UseMutationOptions<null, SubmitForApprovalError, ReviewArgs>
}) => {
  const queryClient = useQueryClient()
  const onSuccess = (data, vars, context) => {
    cancelAndInvalidate(queryClient, TripKeys.collaborator(tripId))
    cancelAndInvalidate(queryClient, TripKeys.detail(tripId))
    options?.onSuccess?.(data, vars, context)
  }

  return useMutation({
    mutationFn: ({ userId, ...formData }) =>
      submitTripForApproval({
        tripId,
        userId,
        ...formData,
      }),
    ...options,
    onSuccess,
  })
}

export const useUpdateReview = ({
  tripId,
  options,
}: {
  tripId: string
  options?: UseMutationOptions<null, SubmitForApprovalError, UpdateReviewArgs>
}) => {
  const queryClient = useQueryClient()
  const onSuccess = (data, vars, context) => {
    cancelAndInvalidate(queryClient, TripKeys.detail(tripId))
    options?.onSuccess?.(data, vars, context)
  }

  return useMutation({
    mutationFn: (formData) => updateReviewData({ tripId, ...formData }),
    ...options,
    onSuccess,
  })
}

export const useUpdateAndPublish = ({
  tripId,
  options,
}: {
  tripId: string
  options?: UseMutationOptions<null, PublishTripError>
}) => {
  const { mutate: publishTripMutate } = usePublish({ tripId, options })

  const onSuccess = useCallback(() => {
    publishTripMutate()
  }, [publishTripMutate])

  return useUpdateReview({ tripId, options: { onSuccess } })
}

export const useCopyTripTemplate = (
  options?: UseMutationOptions<
    FullTrip,
    CopyTemplateError,
    Parameters<typeof copyTripTemplate>[0]
  >,
): UseMutationResult<
  FullTrip,
  CopyTemplateError,
  Parameters<typeof copyTripTemplate>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(
    (params) => copyTripTemplate(params).then(Mapper.fullTrip),
    {
      retry: false,
      ...options,
      onSettled: (trip, error, variables, context) => {
        cancelAndInvalidate(queryClient, TripKeys.lists)
        options?.onSettled?.(trip, error, variables, context)
      },
    },
  )
}

export const useCurator = ({
  tripId,
}: {
  tripId: string
  onSuccess?: (data: Curator) => void
  onError?: (err: GetTripCuratorError) => void
}): UseQueryResult<Curator, GetTripCuratorError> => {
  return useQuery({
    queryKey: CuratedTripKeys.curator(tripId),
    queryFn: () => fetchCurator({ tripId }),
  })
}

export const useLowestFlightPrice = ({
  originAirportCode,
  destinationAirportCode,
  currencyCode,
  regionCode,
  shouldQuery,
  nights,
  numberOfAdults,
  checkIn,
  checkOut,
}: {
  originAirportCode: string
  destinationAirportCode: string
  currencyCode: string
  regionCode: string
  shouldQuery: boolean
  nights?: number
  numberOfAdults?: number
  checkIn?: string
  checkOut?: string
}): UseQueryResult<number | undefined> => {
  return useQuery(
    [
      'lowestFlightPrice',
      originAirportCode,
      destinationAirportCode,
      currencyCode,
      regionCode,
      checkIn,
      checkOut,
      nights,
      numberOfAdults,
    ],
    () => {
      if (!shouldQuery) return Promise.resolve(undefined)

      const startDate = moment().add(FLIGHTS_SEARCH_DAY_MIN_ADVANCE, 'day')
      const endDate = moment().add(FLIGHTS_SEARCH_DAY_MAX_ADVANCE, 'day')

      return getFlightPrice({
        startDate: checkIn ?? startDate.format(ISO_DATE_FORMAT),
        endDate: checkOut ?? endDate.format(ISO_DATE_FORMAT),
        numberOfAdults: numberOfAdults ?? 1,
        numberOfChildren: 0,
        numberOfInfants: 0,
        nights: nights ?? 1,
        origin: originAirportCode,
        destination: destinationAirportCode,
        currency: currencyCode,
        region: regionCode,
      })
    },
    { staleTime: NON_TRIP_STALE_TIME },
  )
}

const selectFirstTripItem = (
  lowStockItems: Array<definitions['lowStockItem']>,
): TripItem | undefined =>
  lowStockItems && lowStockItems.length > 0 ?
    Mapper.tripItem(
      lowStockItems[0].tripItem,
      lowStockItems[0].tripItem.tripId,
    ) :
    undefined

export const useTripLowStockItems = ({
  tripId,
  ...options
}: {
  tripId: string
  options?: UseQueryOptions
}): UseQueryResult<TripItem | undefined> => {
  const loggedIn = useAppSelector(selectLoggedIn)

  return useQuery({
    queryKey: TripKeys.lowStockItems(tripId),
    queryFn: loggedIn ? () => getLowStockItemsForTrip({ tripId }) : () => [],
    select: selectFirstTripItem,
    ...options,
  })
}

const selectMessages = (
  items: Array<definitions['validationError']>,
): Array<string> => {
  return items.map((item) => item.message)
}

export const useTemplateValidation = ({ tripId }) => {
  const { data = [] } = useQuery({
    queryKey: CuratedTripKeys.validation(tripId),
    queryFn: () => validateTemplate({ tripId }),
    select: selectMessages,
  })
  return { data, hasValidationErrors: data.length > 0 }
}
