import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

/**
 * @deprecated Legacy icon.
 * */
function BinocularsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 58.88 44.27">
      <path d="M56.76 24.17v-.05L45.83 6.91a7.06 7.06 0 00-3.17-2.69l-1.19-2a4.62 4.62 0 00-8.59 2.37v8.17H26V4.62a4.62 4.62 0 00-8.59-2.37l-1.19 2a7.06 7.06 0 00-3.17 2.69L2.14 24.12v.05A13 13 0 1026 31.27v-8.93h6.88v8.93a13 13 0 1023.88-7.1zM35.13 4.62a2.38 2.38 0 014.41-1.22l.16.26a7 7 0 00-4.57 1.88zM35.58 15A8.39 8.39 0 0033 20.09h-7A8.35 8.35 0 0023.29 15zM19.34 3.4a2.38 2.38 0 014.41 1.22v.92a7 7 0 00-4.57-1.88zM13 42a10.75 10.75 0 1110.75-10.73A10.77 10.77 0 0113 42zm0-23.75a12.93 12.93 0 00-5.17 1.07L15 8.12a4.77 4.77 0 018.8 2.55v2.12h-6.23a1.13 1.13 0 100 2.25 6.19 6.19 0 016.18 6.18V24A13 13 0 0013 18.27zm22.13 2.95a6.18 6.18 0 016.18-6.2 1.13 1.13 0 100-2.25h-6.18v-2.08a4.77 4.77 0 018.8-2.55L51 19.34A13 13 0 0035.13 24zM45.88 42a10.75 10.75 0 1110.75-10.73A10.77 10.77 0 0145.88 42z"/>
      <path d="M13 21.92a9.35 9.35 0 109.34 9.35A9.36 9.36 0 0013 21.92zm0 16.44a7.1 7.1 0 117.09-7.09A7.11 7.11 0 0113 38.36zm32.88-16.44a9.35 9.35 0 109.35 9.35 9.35 9.35 0 00-9.35-9.35zm0 16.44A7.1 7.1 0 1153 31.27a7.1 7.1 0 01-7.12 7.09z"/>
    </SvgIcon>
  )
}
export default BinocularsIcon
