import cn from 'clsx'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

export type SvgIconColour =
  'neutral-one' |
  'neutral-two' |
  'neutral-three' |
  'neutral-four' |
  'neutral-six' |
  'neutral-eight' |
  'primary' |
  'primary-dark' |
  'secondary-normal' |
  'success' |
  'urgency' |
  'critical' |
  'critical-light' |
  'fast' |
  'warning' |
  'favourite' |
  'highlight-primary-normal' |
  'highlight-secondary-normal' |
  'exclusive' |
  'highlight-secondary' |
  'highlight-tertiary' |
  'lux-plus' |
  'urgency'

export type SvgIconSize = 'XS' | 'S' | 'M' | 'L' | 'XL'

export interface SvgIconProps extends Omit<React.SVGAttributes<SVGSVGElement>, 'color' | 'height' | 'width'> {
  /**
    Make sure to omit the 'type' property when using Icons with TextButton's, the inverse colouring will automatically be applied.
  **/
  colour?: SvgIconColour;
  /**
   * Icon size symbol to pixel maps:
   *
   * - `XS` = 16px
   * - `S` = 20px
   * - `M` = 24px
   * - `L` = 32px
   * - `XL` = 40px
   *
   * @default Either inherit from the parent icon size or fall back to M
   */
  size?: SvgIconSize | number;
  /**
 * @deprecated use `size` instead
 * */
  height?: string | number
  /**
 * @deprecated use `size` instead
 * */
  width?: string | number
}

export type SvgIconComponent = React.ComponentType<SvgIconProps>

export const SVG_ICON_SIZES: Record<SvgIconSize, string> = {
  XS: rem(16),
  S: rem(20),
  M: rem(24),
  L: rem(32),
  XL: rem(40),
}

export const SVG_ICON_SIZE_CSS_VAR = '--svg-icon-size'

export const StyledSvgIcon = styled.svg<SvgIconProps>`
  flex-shrink: 0;
  height: var(${SVG_ICON_SIZE_CSS_VAR}, ${SVG_ICON_SIZES.M});
  width: var(${SVG_ICON_SIZE_CSS_VAR}, ${SVG_ICON_SIZES.M});

  &.colour-neutral-one { color: ${props => props.theme.palette.neutral.default.one} }
  &.colour-neutral-two { color: ${props => props.theme.palette.neutral.default.two}; }
  &.colour-neutral-three { color: ${props => props.theme.palette.neutral.default.three}; }
  &.colour-neutral-four { color: ${props => props.theme.palette.neutral.default.four}; }
  &.colour-neutral-six { color: ${props => props.theme.palette.neutral.default.six}; }
  &.colour-neutral-eight { color: ${props => props.theme.palette.neutral.default.eight} }
  &.colour-primary { color: ${props => props.theme.palette.brand.primary.normal}; }
  &.colour-primary-dark { color: ${props => props.theme.palette.brand.primary.dark}; }
  &.colour-secondary-normal { color: ${props => props.theme.palette.highlight.secondary.normalForeground}; }
  &.colour-success { color: ${props => props.theme.palette.messaging.success.normalForeground}; }
  &.colour-urgency { color: ${props => props.theme.palette.messaging.urgency.normalForeground}; }
  &.colour-critical { color: ${props => props.theme.palette.messaging.critical.normalForeground}; }
  &.colour-critical-light { color: ${props => props.theme.palette.messaging.critical.lightForeground}; }
  &.colour-fast { color: ${props => props.theme.palette.highlight.tertiary.normalForeground}; }
  &.colour-warning { color: ${props => props.theme.palette.messaging.warning.normalForeground}; }
  &.colour-favourite { color: ${props => props.theme.palette.messaging.favourite.normalForeground}; }
  &.colour-highlight-primary-normal { color: ${props => props.theme.palette.highlight.primary.normalForeground}; }
  &.colour-highlight-secondary-normal { color: ${props => props.theme.palette.highlight.secondary.normalForeground}; }
  &.colour-exclusive { color: ${props => props.theme.palette.product.exclusive.foreground}; }
  &.colour-highlight-secondary { color: ${props => props.theme.palette.highlight.secondary.normalForeground}; }
  &.colour-highlight-tertiary { color: ${props => props.theme.palette.highlight.tertiary.normalForeground};}
  &.colour-lux-plus { color: ${props => props.theme.palette.product.luxPlus.foreground};}
  &.colour-urgency { color: ${props => props.theme.palette.messaging.urgency.normalForeground} ;}
`

function SvgIcon(props: SvgIconProps) {
  const {
    colour,
    size,
    height,
    width,
    fill = 'currentColor',
    className,
    style,
    ...rest
  } = props

  const mappedSize = size !== undefined ? SVG_ICON_SIZES[size] || rem(size) : undefined

  return <StyledSvgIcon
    {...rest}
    className={cn(
      className,
      colour !== undefined ? `colour-${colour}` : undefined,
    )}
    fill={fill}
    height={height}
    width={width}
    style={{
      ...style,
      [SVG_ICON_SIZE_CSS_VAR as string]: mappedSize,
    }}
  />
}

export default SvgIcon
