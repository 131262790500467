export function isAndroid(osName: string) {
  return osName.includes('Android')
}

export function isApple(osName: string) {
  return osName === 'iOS' || osName === 'Mac OS'
}

export function isIOS(osName: string) {
  return osName === 'iOS'
}

export function isMobileDevice(deviceInfo: App.DeviceInfo): boolean {
  return deviceInfo.deviceType === 'mobile'
}

/**
 * this selector will also respect in-app navigation via webviews
 */
export function isIOSAppUserAgent(userAgentBrowserName: string) {
  return userAgentBrowserName.includes('mobileapp-ios')
}

/**
 * This selector will also respect in-app navigation via webviews
 */
export function isAndroidAppUserAgent(userAgentBrowserName: string) {
  return userAgentBrowserName.includes('mobileapp-android')
}
