import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import Heading from 'components/Luxkit/Typography/Heading'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import Group from 'components/utils/Group'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import React from 'react'
import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'
import LuxPlusInclusions from 'luxPlus/components/LuxPlusInclusions'
import { INCLUSIONS_TITLE_BONUS } from 'constants/inclusions'

interface Props {
  inclusionList: Array<App.OfferInclusion> | Array<App.PackageInclusion>;
  heading?: string;
  offerURL?: string;
  title: string;
  luxPlusInclusions?: Array<App.PackageInclusion>
}

const VIEW_OFFER_COPY = 'View offer'

function OfferTileInclusionsModal(props : Props) {
  const {
    inclusionList,
    offerURL,
    title,
    luxPlusInclusions,
    heading = 'Your handpicked inclusions:',
  } = props
  const modalElementContext = useModalElementContext<boolean>()

  const bonusInclusions = inclusionList.filter(inclusion => inclusion.isBonus)
  const regularInclusions = inclusionList.filter(inclusion => !inclusion.isBonus)

  const closeModal = (e : React.MouseEvent<HTMLElement>) => {
    const isViewOffer = e.currentTarget.textContent === VIEW_OFFER_COPY
    modalElementContext.resolve(isViewOffer)
  }

  return (<ModalBase size="S" onClose={closeModal}>
    <ModalHeader title={title} onCloseButtonClick={closeModal} />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={8}>
          {!!luxPlusInclusions?.length && (
            <LuxPlusInclusions inclusions={luxPlusInclusions}/>
          )}
          {!!bonusInclusions.length && (
            <VerticalSpacer gap={4}>
              <PromotionLabel variant="default" format="propercase">{INCLUSIONS_TITLE_BONUS}:</PromotionLabel>
              <div>
                {bonusInclusions.map((inclusion) => <OfferInclusionItem key={inclusion.id} inclusion={inclusion} />)}
              </div>
            </VerticalSpacer>
          )}
          {!!regularInclusions.length && (
            <VerticalSpacer gap={4}>
              <Heading variant="heading6">{heading}</Heading>
              <div>
                {regularInclusions.map((inclusion) => <OfferInclusionItem key={inclusion.id} inclusion={inclusion} />)}
              </div>
            </VerticalSpacer>
          )}
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    {!!offerURL &&
      <ModalFooter>
        <CSSBreakpoint min="tablet">
          <Group direction="horizontal-reverse">
            <TextButton kind="primary" fit="auto" to={offerURL} onClick={closeModal} >{VIEW_OFFER_COPY}</TextButton>
          </Group>
        </CSSBreakpoint>
        <CSSBreakpoint max="mobile">
          <TextButton kind="primary" fit="full-width" to={offerURL} onClick={closeModal}>{VIEW_OFFER_COPY}</TextButton>
        </CSSBreakpoint>
      </ModalFooter>
        }
  </ModalBase>)
}

export default OfferTileInclusionsModal
