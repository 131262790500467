/**
 * Composes an array of functions into a single callable function that
 * gets the same arguments across all calls
 *
 * TODO: Make this type safe
 *
 * @param {Array<functions>} funcs The functions to compose
 */
export default (...funcs: Array<(...args: Array<any>) => any>) => {
  // a 'compose' is actually a 'flow right'.
  // To get a flow right, we swap the order given
  const flowRightFuncs = [...funcs].reverse()
  return (...args) => flowRightFuncs.reduce((result, func) => {
    if (result) {
      return func(result)
    } else {
      return func(...args)
    }
  }, undefined)
}
