import 'isomorphic-fetch'
import config from 'constants/config'
import noop from 'lib/function/noop'

// we json serialise body, so allow anything
export type ObjectOptions = Omit<RequestInit, 'body'> & { body?: any }
export type ObjectOptionsWithMethod = ObjectOptions & { method: string }
export interface ProcessorProps {
  endpoint: string;
  path: string,
  options: ObjectOptionsWithMethod
}
export type PreProcessor = (props: ProcessorProps) => ProcessorProps
export type Interceptor = (promise: Promise<any>, response: Response) => Promise<Response>

const preProcessors: Array<PreProcessor> = []
const interceptors: Array<Interceptor> = []

export function registerRequestPreprocessor(preProcessor: PreProcessor) {
  preProcessors.push(preProcessor)
}

export function registerRequestInterceptor(interceptor: Interceptor) {
  interceptors.push(interceptor)
}

export function unregisterRequestPreprocessor(preProcessor: PreProcessor) {
  const index = preProcessors.indexOf(preProcessor)
  if (index > 0) {
    preProcessors.splice(index, 1)
  }
}

export function unregisterRequestInterceptor(interceptor: Interceptor) {
  const index = interceptors.indexOf(interceptor)
  if (index > 0) {
    interceptors.splice(index, 1)
  }
}

export function resetRequestMiddlewares() {
  preProcessors.splice(0, preProcessors.length)
  interceptors.splice(0, interceptors.length)
}

function sendFetchRequest(
  endpoint: string,
  options: ObjectOptions,
  responseResolver: (value: Response | PromiseLike<Response>) => void,
): Promise<any> {
  return fetch(endpoint, options)
    .then((response) => {
      responseResolver(response)
      const status = response.status
      if (status >= 400) {
        throw response
      }

      const contentType = response.headers?.get('Content-Type')
      if (status === 204 || !contentType) {
        return Promise.resolve()
      }

      if (contentType.includes('application/json')) {
        return response.json()
      }

      if (contentType.includes('application/pdf')) {
        return response.blob()
      }

      return response.text()
    })
    .catch((response) => {
      responseResolver(response)
      throw response
    })
}

function sendXMLHttpRequest(
  endpoint: string,
  options: ObjectOptionsWithMethod,
  responseResolver: (value: unknown) => void,
  onProgress?: (percentage: number) => void,
): Promise<any> {
  const xmlRequest = new XMLHttpRequest()

  // upload progress event
  xmlRequest.upload.addEventListener('progress', function(e) {
    // upload progress as percentage
    const percent_completed = (e.loaded / e.total) * 100
    onProgress?.(percent_completed)
  })

  let resolveResult
  let rejectResult
  const responseResult = new Promise((resolve, reject) => {
    resolveResult = resolve
    rejectResult = reject
  })

  // request successfully finished event
  xmlRequest.addEventListener('load', function() {
    resolveResult()
  })
  // request failed event
  xmlRequest.addEventListener('error', function() {
    rejectResult()
  })
  // request aborted event
  xmlRequest.addEventListener('abort', function() {
    rejectResult('The operation was aborted!')
  })
  // request timed out event
  xmlRequest.addEventListener('timeout', function() {
    rejectResult('The operation timed out!')
  })

  xmlRequest.open(options.method, endpoint)
  if (options.headers) {
    Object.entries(options.headers).forEach(([key, value]) => {
      xmlRequest.setRequestHeader(key, value)
    })
  }

  xmlRequest.send(options.body)

  return responseResult
    .then(() => {
      responseResolver(xmlRequest)

      const status = xmlRequest.status
      if (status >= 400) {
        throw xmlRequest.response
      }

      const contentType = xmlRequest.getAllResponseHeaders()
      if (status === 204 || !contentType) {
        return Promise.resolve()
      }

      // TODO improve for different response type
      // when needed
      if (contentType.includes('application/json')) {
        return JSON.parse(xmlRequest.response)
      }

      return xmlRequest.response
    })
    .catch((response) => {
      responseResolver(response)

      let error = response
      if (typeof response === 'string') {
        try {
          error = JSON.parse(response)
        } catch (error) {
          noop(error)
        }
      }
      throw error
    })
}

const getDefaultEndpoint = () => {
  if (!IS_SSR) {
    // If it's not server side rendering, then return the public API endpoint with config.SCHEME
    return `${config.SCHEME}://${config.API_HOST}`
  }

  const serverSideAPIHost = config.SERVER_SIDE_API_HOST ?? config.API_HOST

  // If server side API host starting with http(s), then return it without adding schema.
  if (serverSideAPIHost.startsWith('http')) {
    return serverSideAPIHost
  }

  // If server side API host is not starting with http, then return it with config.SCHEME
  return `${config.SCHEME}://${serverSideAPIHost}`
}

const defaultEndpoint = getDefaultEndpoint()
/**
 * Does the fetch
 *
 * Lifecycle is:
 * -- Preprocessors
 * Stringify body to JSON (if required)
 * Fetch request sent
 * Parse Result
 * Parse Error (if required)
 * -- Interceptors
 */
async function request<T>(
  path: string,
  options: ObjectOptionsWithMethod,
  endpointOverride?: string,
  onProgress?: (percentage: number) => void,
): Promise<T> {
  const endpoint = endpointOverride ?? defaultEndpoint
  const processedParams = preProcessors.reduce((acc, processor) => processor(acc), {
    path,
    options,
    endpoint,
  })

  let isFormData = false
  if (typeof processedParams.options?.body === 'object') {
    isFormData = processedParams.options.body instanceof FormData
    if (!isFormData) {
      processedParams.options.headers = {
        ...processedParams.options.headers,
        'Content-Type': 'application/json',
      }
      processedParams.options.body = JSON.stringify(processedParams.options.body)
    }
  }

  // because we mutate interceptors as we add/merge them, there's a chance the set of interceptors
  // is different from before the request to after the request - that's not really what we want
  // so 'snapshot' the set of current interceptors just before we made the request
  const snapshotInterceptors = [...interceptors]
  let onResponseResolve
  const pendingResponse = new Promise<Response>((resolve) => { onResponseResolve = resolve })

  const requestProvider = isFormData ?
    sendXMLHttpRequest(`${endpoint}${processedParams.path}`, processedParams.options, onResponseResolve, onProgress) :
    sendFetchRequest(`${endpoint}${processedParams.path}`, processedParams.options, onResponseResolve)

  const fetchPromise = requestProvider
    .catch(async(response) => {
      if (response instanceof Response) {
        let error
        if (response.headers?.get('Content-Type')?.includes('application/json')) {
          error = await response.json()
        } else {
          error = {
            message: await response.text() ?? 'Something went wrong',
            status: response.status ?? 500,
          }
        }
        throw error
      }

      // non-response error, no need to parse - pass it along
      throw response
    })
  const rawResponse = await pendingResponse
  return snapshotInterceptors.reduce((promise, interceptor) => interceptor(promise, rawResponse), fetchPromise)
}

function getRequest<T>(
  path: string,
  options: ObjectOptions = {},
  endpointOverride?: string,
) {
  return request<T>(path, {
    ...options,
    method: 'GET',
  }, endpointOverride)
}

function postRequest<Result, RequestData>(
  path: string,
  data: RequestData,
  options: ObjectOptions = {},
  endpointOverride?: string,
) {
  return request<Result>(path, {
    ...options,
    method: 'POST',
    body: data,
  }, endpointOverride)
}

function patchRequest<T, D>(
  path: string,
  data: D,
  options: ObjectOptions = {},
  endpointOverride?: string,
) {
  return request<T>(path, {
    ...options,
    method: 'PATCH',
    body: data,
  }, endpointOverride)
}

function putRequest<T, D>(
  path: string,
  data: D,
  options: ObjectOptions = {},
  endpointOverride?: string,
) {
  return request<T>(path, {
    ...options,
    method: 'PUT',
    body: data,
  }, endpointOverride)
}

function deleteRequest<T>(
  path: string,
  options: ObjectOptions = {},
  endpointOverride?: string,
) {
  return request<T>(path, {
    ...options,
    method: 'DELETE',
  }, endpointOverride)
}

function optionsRequest<T>(
  path: string,
  options: ObjectOptions = {},
  endpointOverride?: string,
) {
  return request<T>(path, {
    ...options,
    method: 'OPTIONS',
  }, endpointOverride)
}

interface UploadProps {
  path: string;
  data: FormData;
  options: Omit<RequestInit, 'body'>;
  endpointOverride?: string;
}
/**
 * Uploads a FormData returning the progress
 */
function uploadRequest<T>(
  props: UploadProps,
  onProgress?: (percentage: number) => void,
) {
  return request<T>(
    props.path,
    {
      method: 'POST',
      ...(props.options ?? {}),
      body: props.data,
    },
    props.endpointOverride,
    onProgress,
  )
}

export default {
  get: getRequest,
  post: postRequest,
  patch: patchRequest,
  put: putRequest,
  delete: deleteRequest,
  options: optionsRequest,
  upload: uploadRequest,
}

export function authOptions(accessToken?: string): ObjectOptions {
  if (accessToken) {
    return { headers: { Authorization: `Bearer ${accessToken}` } }
  } else {
    return { credentials: 'include' }
  }
}

const bodyMethods = new Set(['POST', 'PUT', 'PATCH'])
interface ParamToAppend {
  key: string;
  value: string;
}

export function appendParams(paramToAppend: ParamToAppend, params: ProcessorProps) {
  const { options } = params
  if (bodyMethods.has(options.method)) {
    const isFormData = options.body instanceof FormData
    if (isFormData) {
      options.body.append(paramToAppend.key, paramToAppend.value)
    } else {
      options.body = {
        ...options.body,
        [paramToAppend.key]: paramToAppend.value,
      }
    }
  } else {
    if (params.path.endsWith('?')) {
      params.path += `${paramToAppend.key}=${paramToAppend.value}`
    } else if (params.path.includes('?')) {
      params.path += `&${paramToAppend.key}=${paramToAppend.value}`
    } else {
      params.path += `?${paramToAppend.key}=${paramToAppend.value}`
    }
  }
}
