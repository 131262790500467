import { PriceDetails } from 'api/flights'
import { sum } from 'lib/array/arrayUtils'

export function getOtherFees(
  details: Array<PriceDetails>,
  targetItem: App.Checkout.FlightItem,
) {
  return details.reduce((fares, item) => {
    switch (item.passengerTypeQuantity.code) {
      case 'atol_fee':
        fares.flightAtolFee = parseFloat(item.totalFare)
        break
      case 'service_fee':
        fares.flightServiceFee = parseFloat(item.totalFare)
        break
      case 'flights_credit_fee':
        fares.flightCreditFee = targetItem.isFlightCredit ? parseFloat(item.totalFare) : 0
        break
      case 'booking_fee':
        fares.flightBookingFee = !targetItem.isFlightCredit ? parseFloat(item.totalFare) : 0
        break
    }
    return fares
  }, {
    flightBookingFee: 0,
    flightServiceFee: 0,
    flightCreditFee: 0,
    flightAtolFee: 0,
  } as App.Checkout.FlightFees)
}

export function computeFeesTotal(fees: Partial<App.Checkout.FlightFees>): number {
  return fees ? sum([
    fees?.flightCreditFee ?? 0,
    fees?.flightBookingFee ?? 0,
    fees?.flightAtolFee ?? 0,
    fees?.flightServiceFee ?? 0,
  ]) : 0
}
