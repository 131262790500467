import { getMaxGuestCount } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'

export function getCapacityTitle(offer: App.VillaOffer | App.VillaOfferSummary) {
  if (!offer.lowestPricePackage) return ''

  const roomRate = offer.lowestPricePackage.roomRate
  if (!roomRate) {
    return ''
  }

  const roomType = offer.lowestPricePackage.roomType
  if (!roomType) {
    return ''
  }

  const capacity = getMaxGuestCount(roomRate.capacities)
  if (!capacity) return ''

  const numberOfBedrooms = roomType.numberOfBedrooms ? ' . ' + pluralizeToString('Bedroom', roomType.numberOfBedrooms) : ''
  const numberOfBathrooms = roomType.numberOfBathrooms ? ' . ' + pluralizeToString('Bathroom', roomType.numberOfBathrooms) : ''

  return `Up to ${capacity} guests${numberOfBedrooms}${numberOfBathrooms}`
}
