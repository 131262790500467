import { OFFER_TYPE_HOTEL } from 'constants/offer'
import { getSumExtraGuestsSurcharge } from 'lib/checkout/cartReservationUtils'
import createSelector from 'lib/web/createSelector'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { isHotLead } from 'lib/offer/highIntentOffers'
import { isBedbank, isLEHotel } from 'lib/offer/offerTypes'
import { buildSearchParamsKey } from 'lib/search/searchUtils'
import { getHighIntentOffers } from 'selectors/recomendations/highIntentSelectors'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

const emptyHotLead: App.HotLeadOffer = {
  offerId: '',
  offerType: OFFER_TYPE_HOTEL,
  occupancy: [],
  isDismissed: false,
  creationTime: 0,
  category: 'sticky_hot_leads',
}

export const getFirstHotLead = createSelector(
  getHighIntentOffers,
  (offers: Array<App.HighIntentOffer>) => offers.find(isHotLead) ?? emptyHotLead,
)

export const getAllHotLeadOffers = createSelector(
  getHighIntentOffers,
  (offers: Array<App.HighIntentOffer>) => offers.filter(isHotLead),

)

const getBestPrices = (state: App.State, args: {offer: App.AnyOffer}) => state.offer.offerBestPrices[args.offer?.id]
const getPricesErrors = (state: App.State, args: {offer: App.AnyOffer}) => state.offer.offerPricesErrors[args.offer?.id]

interface leHotelArgs {
  offer?: App.AnyOffer,
  validHotLead: boolean,
  hotLead: App.HotLeadOffer,
}

export interface HotLeadPriceData {
  totalPrice: number;
  isMemberPrice: boolean;
  discountPercent: number;
  duration: number;
  isSoldOut: boolean;
}

const emptyPriceData: HotLeadPriceData = {
  totalPrice: 0,
  isMemberPrice: false,
  discountPercent: 0,
  duration: 0,
  isSoldOut: false,
}

const soldOutPriceData: HotLeadPriceData = {
  totalPrice: 0,
  isMemberPrice: false,
  discountPercent: 0,
  duration: 0,
  isSoldOut: true,
}

export const getLeHotelPriceData = createSelector(
  (_: App.State, args: leHotelArgs) => args,
  getBestPrices,
  getPricesErrors,
  checkCanViewLuxPlusBenefits,
  ({
    offer,
    validHotLead,
    hotLead: {
      checkIn,
      checkOut,
      occupancy,
    },
  },
  bestPrices, pricesErrors, canViewLuxPlusBenefits,
  ) => {
    if (!validHotLead || !offer || !isLEHotel(offer)) {
      return emptyPriceData
    }

    const hasDates = !!checkIn && !!checkOut
    const searchKey = buildSearchParamsKey(checkIn, checkOut, occupancy)
    const bestPrice = bestPrices?.[searchKey]
    const bestPriceError = pricesErrors?.[searchKey]
    const bestPriceAvailable = bestPrice?.available
    const bestRate = bestPriceAvailable ? bestPrice.rate : undefined
    const fetchingPrice = hasDates && !bestPrice && !bestPriceError
    const soldOut = (hasDates && bestPrice && !bestPriceAvailable) || offer.isSoldOut
    if (fetchingPrice) {
      return emptyPriceData
    }
    if (soldOut) {
      return soldOutPriceData
    }
    let bestPricePackage: App.Package | undefined
    if (!hasDates || bestPriceError) {
      bestPricePackage = offer.lowestPricePackage
    }
    if (!!bestRate && !!offer.packages) {
      bestPricePackage = offer.packages?.find(pkg => pkg.uniqueKey === bestRate.packageUniqueKey)
    }
    if (!bestPricePackage) {
      return emptyPriceData
    }

    // bestRate already include no. of rooms, but lowestPricePackage is only for one room
    const hasMemberBestRatePrice = (bestRate?.memberPrice ?? 0) > 0
    const bestRatePrice = canViewLuxPlusBenefits && hasMemberBestRatePrice ? bestRate?.memberPrice : bestRate?.price
    const hasMemberLowestPricePackage = (offer.lowestPricePackage?.memberPrice ?? 0) > 0
    const lowestPricePackagePrice = canViewLuxPlusBenefits && hasMemberLowestPricePackage ? offer.lowestPricePackage?.memberPrice : offer.lowestPricePackage?.price
    const isMemberPrice = hasMemberBestRatePrice || hasMemberLowestPricePackage
    const basePrice = bestRatePrice ?? lowestPricePackagePrice ?? 0
    const priceMultiplier = bestRate ? 1 : (occupancy?.length || 1)
    const extraGuestSurcharges = getSumExtraGuestsSurcharge(occupancy, offer, bestPricePackage)
    const propertyFees = (bestRate?.propertyFees ?? bestPricePackage?.propertyFees ?? 0) * priceMultiplier || 0

    const hotelPrice = basePrice * priceMultiplier + extraGuestSurcharges
    const totalPrice = Math.ceil(hotelPrice + propertyFees)
    const bestRateValue = bestRate ? bestRate.hotelValue + bestRate.surcharge : undefined
    const totalValue = Math.ceil(
      (bestRateValue ?? ((bestPricePackage?.value ?? 1) * (occupancy?.length || 1))) + extraGuestSurcharges,
    )
    const discountPercent = getValueOffPercent(totalValue, hotelPrice)

    const duration = bestRate?.duration ?? bestPricePackage.duration

    return {
      totalPrice,
      isMemberPrice,
      discountPercent,
      duration,
      isSoldOut: false,
    } as HotLeadPriceData
  },
)

interface bedbankArgs {
  offer?: App.AnyOffer,
  validHotLead: boolean,
  fetchingBedbankRates: boolean,
  bedbankRates: Array<App.BedbankRate>,
}

export const getBedbankPriceData = createSelector(
  (_: App.State, args: bedbankArgs) => args,
  ({
    offer,
    validHotLead,
    fetchingBedbankRates,
    bedbankRates,
  },
  ) => {
    const isBedbankOffer = isBedbank(offer)

    if (!validHotLead || !offer || !isBedbankOffer || fetchingBedbankRates) {
      return emptyPriceData
    }

    const isSoldOut = isBedbankOffer && !fetchingBedbankRates && !(bedbankRates.length > 0 || offer.sell)
    if (isSoldOut) {
      return soldOutPriceData
    }

    const bedbankRate = bedbankRates?.[0]
    const bedbankPrice = (bedbankRate ? bedbankRate.totals.inclusive : offer.sell?.price) ?? 0
    const bedbankPropertyFees = bedbankRate?.totals?.propertyFees ?? 0
    const bedbankDiscount = bedbankRate?.discount ?? offer.sell?.discount
    const bedbankDiscountPercent = bedbankDiscount ? Math.round(bedbankDiscount * 100) : 0
    const bedbankDuration = bedbankRate?.nights ?? offer?.sell?.los
    const bedbankTotalPrice = bedbankPrice + bedbankPropertyFees

    return {
      totalPrice: bedbankTotalPrice,
      discountPercent: bedbankDiscountPercent,
      duration: bedbankDuration,
      isSoldOut: false,
    } as HotLeadPriceData
  },
)
