import React, { ComponentPropsWithRef } from 'react'

import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import LineUnlockAltIcon from 'components/Luxkit/Icons/line/LineUnlockAltIcon'
import SvgIcon from 'components/Luxkit/SvgIcon'
import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LineShieldCheckIcon from 'components/Luxkit/Icons/line/LineShieldCheckIcon'
import LineLockAltIcon from 'components/Luxkit/Icons/line/LineLockAltIcon'

export const SUPPORTED_BENEFITS_ICONS: Record<App.LuxPlusBenefitType, typeof SvgIcon> = {
  hotel_inclusions: LineStarIcon,
  discounted_flash: LineTagAltIcon,
  exclusive_offers: LineLockAltIcon,
  priority_support: LinePhoneIcon,
  discounted_tours: LineTagAltIcon,
  early_access: LineUnlockAltIcon,
  discounted_insurance: LineShieldCheckIcon,
}

interface Props extends ComponentPropsWithRef<typeof SvgIcon> {
  benefitType: App.LuxPlusBenefitType
}

export default function LuxPlusBenefitIcon({ benefitType, ...rest }: Props) {
  const Icon = SUPPORTED_BENEFITS_ICONS[benefitType]
  return <Icon {...rest} />
}
