import { createListenerMiddleware } from '@reduxjs/toolkit'
import { waitForValue } from 'lib/promise/waitForValue'
import { isCheckoutReady } from 'checkout/selectors/view/generic'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { checkoutUrlPattern } from 'constants/url'
// Create instance
export const analyticsListenerMiddleware = createListenerMiddleware()

const waitForCheckoutReady = waitForValue<boolean>()
export const checkoutStateIsReady = () => waitForCheckoutReady.awaitForValue()
analyticsListenerMiddleware.startListening({
  // Run on any change as checkout can be affected by many items in state
  predicate: (_, currentState: App.State) => {
    const currentPath = currentState.routeHistory.currentPath
    const previousPath = currentState.routeHistory.prevPath
    const currentIsCheckoutPath = checkoutUrlPattern.test(currentPath)
    const previousIsCheckoutPath = checkoutUrlPattern.test(previousPath)
    return (currentIsCheckoutPath !== previousIsCheckoutPath) || currentIsCheckoutPath
  },
  effect: async(_, listenerApi) => {
    const state = listenerApi.getState() as App.State
    if (isCheckoutReady(state) || !selectLoggedIn(state)) {
      waitForCheckoutReady.resolveWithValue(true)
    } else {
      waitForCheckoutReady.resetAwaitedValue()
    }
  },
})
