import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { groupBy } from 'lib/array/arrayUtils'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Heading from 'components/Luxkit/Typography/Heading'
import VillasPageAmenity from './VillasPageAmenity'

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 280px));
  grid-gap: ${rem(16)};
`
interface Props {
  amenities: Array<App.PackageRoomAmenity>;
}

function VillasPageAmenitiesModalContent(props: Props) {
  const { amenities } = props
  const amenitiesByGroup = groupBy(amenities, amenity => amenity.group)

  return (
    <VerticalSpacer gap={40}>
      {[...amenitiesByGroup.entries()].map(([groupName, amenities]) => (
        <VerticalSpacer gap={16} key={groupName}>
          <Heading variant="heading5">{groupName}</Heading>
          <AmenitiesGrid>
            {amenities.map((amenity) => (<VillasPageAmenity key={amenity.name} amenity={amenity} />))}
          </AmenitiesGrid>
        </VerticalSpacer>
      ))}
    </VerticalSpacer>
  )
}

export default VillasPageAmenitiesModalContent
