import {
  LE_CONTRACTED_HOTEL_TYPES,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_VILLA,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
} from 'constants/offer'
import { SORT_OPTION_NEW_HOME_PAGE, SORT_OPTION_HOME_PAGE } from 'constants/offerListFilters'
import { MAX_CAROUSEL_ITEM_COUNT } from './components/OfferCarousels/common/constants'
import config from 'constants/config'

export enum SupportedDestinations {
  BALI = 'bali',
  GREECE = 'greece',
  MALDIVES ='maldives',
  SPAIN = 'spain'
}

export const SUPPORTED_DESTINATION_IDS = {
  [SupportedDestinations.BALI]: 'le_64930b7151b6bc3e289f37d5da62ac9f',
  [SupportedDestinations.GREECE]: 'le_735b90b4568125ed6c3f678819b6e058',
  [SupportedDestinations.MALDIVES]: 'le_2723d092b63885e0d7c260cc007e8b9d',
  [SupportedDestinations.SPAIN]: 'le_7e7757b1e12abcb736ab9a754ffb617a',
}

export const DEFAULT_IN_VIEW_PARAMS = {
  triggerOnce: true,
  threshold: 0.5,
  rootMargin: '300px',
}

export const CAROUSEL_IN_VIEW_PARAMS = {
  triggerOnce: true,
  threshold: 0,
  rootMargin: '500px',
}

export const HOME_PAGE_OFFER_TYPES: Array<App.OfferListOfferType> = ([] as Array<App.OfferListOfferType>)
  .concat(LE_CONTRACTED_HOTEL_TYPES)
  .concat([
    OFFER_TYPE_BED_BANK,
    OFFER_TYPE_TOUR,
    OFFER_TYPE_TOUR_V2,
  ]).concat(config.ENABLE_VILLA_RENTALS ? [OFFER_TYPE_VILLA] : [])
  .concat(config.ENABLE_BUNDLE_AND_SAVE ? [OFFER_TYPE_BUNDLE_AND_SAVE] : [])

export const NEW_HOME_PAGE_OFFER_TYPES: Array<App.OfferListOfferType> = [
  ...HOME_PAGE_OFFER_TYPES,
  OFFER_TYPE_CRUISE,
]

export const NEW_DEFAULT_OFFER_LIST: App.OfferListFilters = {
  offerTypes: NEW_HOME_PAGE_OFFER_TYPES,
  limit: MAX_CAROUSEL_ITEM_COUNT,
  sortBy: SORT_OPTION_NEW_HOME_PAGE.value,
}

export const DEFAULT_OFFER_LIST: App.OfferListFilters = {
  offerTypes: HOME_PAGE_OFFER_TYPES,
  limit: MAX_CAROUSEL_ITEM_COUNT,
  sortBy: SORT_OPTION_HOME_PAGE.value,
  frontPageVisibleOnly: true,
  excludeConnectionTours: true,
}
