import { capitalise } from 'lib/string/stringUtils'

const knownPlurals = {
  child: 'children',
}

function endsWithConsonantAndY(str: string) {
  const consonantYRegex = /[bcdfghjklmnpqrstvwxz]y$/i
  return !!str.match(consonantYRegex)
}

export function getPlural(singular: string, count?: number): string {
  if (count === 1) {
    return singular
  }

  const knownPlural = knownPlurals[singular.toLowerCase()]
  if (knownPlural) {
    const startsWithCapital = singular.length > 0 && singular[0] === singular[0].toUpperCase()
    return startsWithCapital ? capitalise(knownPlural) : knownPlural
  } else if (endsWithConsonantAndY(singular)) {
    return singular.replace(/y$/i, 'ies')
  } else {
    return `${singular}s`
  }
}

export default function pluralize(values: { singular: string, plural?: string, count: number}) {
  let { singular, plural, count } = values

  plural = getPlural(singular, count)

  const text = count === 1 ? singular : plural

  return { count, text }
}

export function pluralizeToString(singular: string, count: number) {
  let text = singular
  if (count !== 1) {
    text = getPlural(singular, count)
  }
  return `${count} ${text}`
}
