import AppLayoutContext, { AppObservedFooter, AppObservedHeader, ElementPosition } from 'contexts/AppLayoutContext'
import { MutableRefObject, RefObject, useCallback, useContext, useEffect } from 'react'
import { ElementBoundingClientRectObserver, useElementBoundingClientRectObserver } from './useElementSize'

export function useAppHeaderObserver<T extends HTMLElement>(
  observedHeader: AppObservedHeader,
  observedRef: RefObject<T> | MutableRefObject<T>,
  /** @default static */
  position: ElementPosition = 'static',
) {
  const appLayoutHandler = useContext(AppLayoutContext)

  const rectObserver = useCallback<ElementBoundingClientRectObserver>((rect) => {
    appLayoutHandler('header', observedHeader, rect, position)
  }, [appLayoutHandler, position, observedHeader])
  useElementBoundingClientRectObserver(observedRef, rectObserver)

  useEffect(() => {
    appLayoutHandler('header', observedHeader, observedRef.current?.getBoundingClientRect(), position)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, observedHeader, observedRef])

  useEffect(() => {
    return () => {
      appLayoutHandler('header', observedHeader, undefined, 'hidden')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function useAppFooterObserver<T extends HTMLElement>(
  observedHeader: AppObservedFooter,
  observedRef: RefObject<T> | MutableRefObject<T>,
  /** @default static */
  position: ElementPosition = 'static',
) {
  const appLayoutHandler = useContext(AppLayoutContext)

  const rectObserver = useCallback<ElementBoundingClientRectObserver>((rect) => {
    appLayoutHandler('footer', observedHeader, rect, position)
  }, [appLayoutHandler, position, observedHeader])
  useElementBoundingClientRectObserver(observedRef, rectObserver)

  useEffect(() => {
    appLayoutHandler('footer', observedHeader, observedRef.current?.getBoundingClientRect(), position)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, observedHeader, observedRef])

  useEffect(() => {
    return () => {
      appLayoutHandler('footer', observedHeader, undefined, 'hidden')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
