import React, { useCallback, useState } from 'react'

import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import DateRangePicker from 'tripPlanner/components/TripModal/Common/DateRangePicker'

interface Props {
  triggerRef: React.RefObject<HTMLElement>
  isOpen: boolean
  startDate?: moment.Moment
  endDate?: moment.Moment
  onSelect: (startDate: moment.Moment, endDate: moment.Moment) => void
  onClear?: () => void
  onClose: () => void
}

function TripDatesDropdown({
  triggerRef,
  isOpen,
  startDate,
  endDate,
  onSelect,
  onClear,
  onClose,
}: Props) {
  const [selectedStartDate, setSelectedStartDate] = useState<
    moment.Moment | undefined
  >()

  const onSelectEndDate = useCallback(
    (selectedEndDate?: moment.Moment) => {
      if (selectedStartDate && selectedEndDate) {
        setSelectedStartDate(undefined)
        onSelect(selectedStartDate, selectedEndDate)
      }
    },
    [onSelect, selectedStartDate],
  )

  const onRequestClose = useCallback(() => {
    setSelectedStartDate(undefined)
    onClose()
  }, [onClose])

  const onClickClear = useCallback(() => {
    setSelectedStartDate(undefined)
    onClear?.()
  }, [onClear])

  return (
    <DropdownSheet
      size="M"
      triggerRef={triggerRef}
      anchorRef={triggerRef}
      open={isOpen}
      onClose={onRequestClose}
      placement="bottom-start"
      isSecondaryActionHidden={!onClear}
      secondaryActionProps={{
        kind: 'tertiary',
        children: 'Clear dates',
        onClick: onClickClear,
      }}
    >
      <DateRangePicker
        onChangeStartDate={setSelectedStartDate}
        onChangeEndDate={onSelectEndDate}
        startDate={selectedStartDate ?? startDate}
        endDate={selectedStartDate ? undefined : endDate}
        allowSameDay
      />
    </DropdownSheet>
  )
}

export default TripDatesDropdown
