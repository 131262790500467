import React, { useContext } from 'react'
import { FlightDropdownOption as SelectOption } from 'components/Flights/types'

import Modal from 'components/Luxkit/Modal/Modal'
import RadioGroup from 'components/Luxkit/Radio/RadioGroup'
import FlightDropdownOption from 'components/Flights/FlightSorting/FlightDropdownToggle/FlightDropdownOption'
import ModalContext from 'contexts/ModalContext'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'

interface Props {
  options: Array<SelectOption>;
  selectedOption: SelectOption;
  className?: string;
  title: string;
  onOptionChange: (option: SelectOption) => void;
  name: string;
}

function FareDropdownMobile(props: Props) {
  const { options, selectedOption, className, onOptionChange, title, name } = props
  const showModal = useContext(ModalContext)

  const onShowFareTypes = () => {
    showModal(
      <Modal title={title}>
        <RadioGroup>
          {options.map((option) => (
            <FlightDropdownOption
              key={option.value}
              defaultChecked={option === selectedOption}
              onChange={onOptionChange}
              option={option}
              name={name}
            />
          ))}
        </RadioGroup>
      </Modal>,
    )
  }

  return (
    <TextButton
      className={className}
      kind="tertiary"
      endIcon={<DropdownChevron />}
      onClick={onShowFareTypes}
      data-testid={props['data-testid']}
    >
      {selectedOption.label}
    </TextButton>
  )
}

export default FareDropdownMobile
