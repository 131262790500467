import { definitions } from '@luxuryescapes/contract-svc-agent'
import request from 'api/requestUtils'

type ExpressionOfInterestRequest = definitions['eoiCreateRequest']

export interface ExpressionOfInterestData {
  agencyName: string;
  affiliation: string;
  name: string;
  phone: string;
  email: string;
  regionCode: App.AgentHubEnabledRegions;
}

async function requestPostExpressionOfInterest(agencyData: ExpressionOfInterestData) {
  const requestBody: ExpressionOfInterestRequest = {
    agencyName: agencyData.agencyName,
    affiliation: agencyData.affiliation,
    name: agencyData.name,
    phone: agencyData.phone,
    email: agencyData.email,
    regionCode: agencyData.regionCode,
  }

  return request.post(
    '/api/agent/eoi',
    requestBody,
  )
}

export default requestPostExpressionOfInterest
