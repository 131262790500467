import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import React from 'react'
import { Route, Switch } from 'react-router'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import ContactOptionsBanner from '../Header/HeaderBanners/ContactOptionsBanner'
import HeaderTopBanners from '../Header/HeaderTopBanners'
import { useAppSelector } from 'hooks/reduxHooks'
import { isCustomerAffectedByBaliVolcano } from 'selectors/orderSelectors'
import BaliWarningNotificationBanner from '../Header/HeaderBanners/BaliWarningNotificationBanner'

const BaliBannerRoutes = [
  '/:regionCode/',
  '/:regionCode/support',
]

const NO_BANNER_EXACT_ROUTES: Array<string> = [
  '/:regionCode/support',
]

const NO_BANNER_INEXACT_ROUTES: Array<string> = [
  '/:regionCode/trip-planner/trip/:tripId',
  '/:regionCode/trip-planner/public-trip/:tripId',
  '/:regionCode/trip-planner/curated/:tripId',
]

function BannerRoutes() {
  const showBaliWarningModal = useAppSelector(isCustomerAffectedByBaliVolcano)
  return <>
    {config.SHOW_CONTACT_BANNER && <TripGuard>
      <CSSBreakpoint max="mobile">
        <ContactOptionsBanner />
      </CSSBreakpoint>
    </TripGuard>}
    {showBaliWarningModal && <Route path={BaliBannerRoutes} exact component={BaliWarningNotificationBanner} />}
    <Switch>
      <Route exact path={NO_BANNER_EXACT_ROUTES} />
      <Route path={NO_BANNER_INEXACT_ROUTES} />
      <Route component={HeaderTopBanners} />
    </Switch>
  </>
}

export default BannerRoutes
