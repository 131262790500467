import { useMemo } from 'react'
import { isLPCTacticalEnabled } from 'lib/offer/offerUtils'

export function useTacticalUpsellBanner(
  offer: App.Offer | App.OfferSummary,
  bestPricePackage: App.Package | undefined,
  filters: App.OfferListFilters | undefined,
): boolean {
  return useMemo(() => {
    if (!isLPCTacticalEnabled()) {
      return false
    }
    if (offer?.hasTactical &&
      filters?.checkIn && filters?.checkOut && filters?.rooms &&
      bestPricePackage?.roomRate
    ) {
      return !bestPricePackage?.hasTactical
    }
    return false
  }, [offer, bestPricePackage, filters])
}
