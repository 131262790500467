import React, { useCallback } from 'react'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import TextLink from 'components/Luxkit/TextLink'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { themeClassName } from 'lib/theme/themeUtils'
import Group from 'components/utils/Group'
import LuxPlusLogo from 'luxPlus/components/Logos/LuxPlusLogo'
import { connect } from 'react-redux'
import { dismissFreePreviewBanner } from 'actions/SystemActions'
import { useAppDispatch } from 'hooks/reduxHooks'

interface MappedProps {
  pathName: string;
}

interface Props extends MappedProps {}

function LuxPlusFreePreviewBanner(props: Props) {
  const { pathName } = props
  const dispatch = useAppDispatch()

  const dismiss = useCallback(() => {
    dispatch(dismissFreePreviewBanner())
  }, [dispatch])

  return <PageBanner
    kind="lux-plus"
    onDismiss={dismiss}
    className={themeClassName('inverse')}
    description={<Group direction="vertical" tabletDirection="horizontal" horizontalAlign="start" verticalAlign="center" gap={8}>
      <LuxPlusLogo height={14} />
      <span>
        Start your {LUXURY_PLUS.FREE_PREVIEW_DURATION}-day Free Preview of our brand-new VIP Travel Club. {!pathName.includes(LUXURY_PLUS_PATHS.DEFAULT_LANDING_PAGE) && <TextLink weight="bold" to={`/${LUXURY_PLUS_PATHS.LANDING_PAGE}`}>Learn more</TextLink>}
      </span>
    </Group>}
  />
}

const mapStateToProps = (state: App.State): MappedProps => ({
  pathName: state.router.location.pathname,
})

export default connect(mapStateToProps)(LuxPlusFreePreviewBanner)
