import moment, { Moment } from 'moment'
import { VILLAS_DISCOUNT_MAXIMUM_TRAVEL_PERIOD_RANGE, VillasDiscountSource, VillasDiscountType } from 'constants/villas'
import { EmptyArray } from 'lib/array/arrayUtils'
import { diffInDays, isDateBetweenOrEqual } from 'lib/datetime/dateUtils'

export function isAnyDayInMonthInDiscountPeriod(discounts: Array<App.VillasDiscount>, month: Moment, duration) {
  const daysInMonth = month.daysInMonth()
  return Array.from({ length: daysInMonth }, (_, i) => i + 1)
    .some(day => isDateWithinAnyDiscountPeriod(discounts, month.clone().date(day), duration))
}

export function isDateWithinAnyDiscountPeriod(discounts: Array<App.VillasDiscount>, checkIn: Moment, duration: number) {
  const daysToArrival = diffInDays(checkIn.toDate(), new Date())

  for (const discount of discounts) {
    const isWithinAnySchedule = discount.schedules.some(schedule => {
      const isWithinActivePeriod = isDateBetweenOrEqual(schedule.activePeriod.from, schedule.activePeriod.to)

      let isWithinTravelPeriod: boolean
      switch (discount.source) {
        case VillasDiscountSource.INTERNAL:
          // internal discounts travel period is inclusive-exclusive (last day is check-out day)
          isWithinTravelPeriod = checkIn.startOf('days') >= moment(schedule.travelPeriod.from) && checkIn.startOf('days') < moment(schedule.travelPeriod.to)
          break
        case VillasDiscountSource.EXTERNAL:
          isWithinTravelPeriod = isDateBetweenOrEqual(schedule.travelPeriod.from, schedule.travelPeriod.to, checkIn.toISOString())
          break
        default: // shouldn't be possible
          isWithinTravelPeriod = false
          break
      }

      return isWithinActivePeriod && isWithinTravelPeriod
    })

    const isWithinArrivalPeriod =
    (discount.minDaysToArrival === null || daysToArrival >= discount.minDaysToArrival) &&
    (discount.maxDaysToArrival === null || daysToArrival <= discount.maxDaysToArrival)

    const isWithinLengthOfStay =
      (discount.minLos === null || duration >= discount.minLos) &&
      (discount.maxLos === null || duration <= discount.maxLos)

    if (
      isWithinAnySchedule &&
      isWithinArrivalPeriod &&
      isWithinLengthOfStay
    ) {
      return true
    }
  }

  return false
}

// Discount travel periods can be further than our allowable booking window.
// We limit discount label to x months from today.

function isTravelPeriodWithinTimeFrame(schedules: Array<App.VillasSchedule>, months: number) {
  const todayPlusMonths = moment().add(months, 'months')
  // if any schedules travel period on a discount extends beyond the allowable booking window, return false
  return !schedules.some(schedule => moment(schedule.travelPeriod.to) >= todayPlusMonths)
}

export function getBestVillasDiscounts(discounts: Array<App.VillasDiscount> = EmptyArray, maxDiscounts: number) {
  // We only want to show internal (promotional aka scheduled discounts) if there are any internal ones.
  const internalDiscounts = discounts.filter(discount => discount.source === VillasDiscountSource.INTERNAL)

  return sortVillaDiscounts(internalDiscounts).slice(0, maxDiscounts)
}

const sortVillaDiscounts = (discounts: Array<App.VillasDiscount>) => {
  const topDiscounts = discounts
    .filter(discount => discount.discountType !== VillasDiscountType.LAST_MINUTE && isTravelPeriodWithinTimeFrame(discount.schedules, VILLAS_DISCOUNT_MAXIMUM_TRAVEL_PERIOD_RANGE))
    .sort((a, b) => b.discountPercent - a.discountPercent)

  return topDiscounts
}
