import React, { useCallback, useContext, useMemo } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import cn from 'clsx'
import { buildSuggestedDatesParamsKey, encodeOfferIds } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import { getBedbankFacilitiesLimit } from 'lib/hotels/bedbankUtils'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { Location } from 'history'
import { Main, MainImageSegment, OfferOverlayContainer, StyledCSSBreakpoint } from './SearchTileStyles'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { queryKeySelectedOfferIds } from 'constants/url'
import { SEARCH_VERTICALS } from 'constants/search'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import { isOfferRatingDisplayable } from '../../../../lib/order/reviewUtils'
import { SPLIT_VIEW_BREAKPOINTS } from 'components/Pages/HotelSearchPage/HotelSplitView/constants'
import { sum } from 'lib/array/arrayUtils'
import { useBedbankSearchTilePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import { useOfferSoldOutPushDown } from '../../../../hooks/Offers/useOfferSoldOutPushDown'
import BedbankFacilities from '../Support/BedbankFacilities'
import BedbankMobileOnlyPriceLabel from 'components/Bedbank/BedbankMobileOnlyPriceLabel'
import BedbankOfferTilePriceDetails from '../Support/BedbankOfferTilePriceDetails'
import BedbankOfferTileSnippet from '../Support/BedbankOfferTileSnippet'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import Heading from 'components/Luxkit/Typography/Heading'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import OfferTileLoggedInButtons from '../Support/OfferTileLoggedInButtons'
import OfferUrgencyCountdownLabel from '../../../Common/Labels/OfferUrgencyCountdownLabel'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import RoomList from 'components/OfferPage/Bedbank/BedbankRoomList'
import SoldOutMessage from '../Support/SoldOutMessage'
import TextButton from 'components/Luxkit/Button/TextButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import useImpressionHandler from 'hooks/useImpressionHandler'
import useOfferMetaData from '../../../../hooks/Offers/useOfferMetaData'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import WalledContent from 'components/Common/WalledContent'
import useBedbankDiscount from 'hooks/useBedbankDiscount'
import { HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES } from '../constants'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import Group from 'components/utils/Group'
import BedbankCancellationPolicy from 'components/OfferPage/Bedbank/BedbankCancellationPolicy'
import { BedbankCancellationPolicyWithInfo } from 'lib/search/hotels'
import { useSearchBedbankOfferPrices } from 'hooks/Search/useSearchBedbankOfferPrices'
import { isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import LuxLPPPricingLabel from 'luxPlus/components/LuxLPPPricingLabel'

const Root = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
  padding: ${rem(16)};
  flex: 1;

  &:not(.mapTile) {
    ${mediaQueryUp.tablet} {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-template-rows: min-content 1fr;
      column-gap: ${rem(20)};
    }
  }
`

const PropertyDetails = styled(VerticalSpacer)`
  margin-top: ${rem(4)};
  align-self: flex-start;
  flex: 1;
  width: 100%;
`

const PricingDetails = styled.div`
  margin-top: ${rem(16)};
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};

  &:not(.mapTile) {
    ${mediaQueryUp.tablet} {
      margin-left: ${rem(30)};
      justify-content: flex-end;
      align-items: flex-end;
      justify-self: end;
      align-self: end;

      > * + * {
        margin-top: initial;
      }
    }
  }

  &.slim-offer-tile {
    justify-content: space-between;
  }

  &.mapTile {
    margin-left: 0;
    flex-direction: row;
    justify-content: flex-end;
  }
`

const FooterContentHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;

  &.footer-content-columned {
    flex-direction: row;
    align-items: center;
    & > * > * > * {
      margin-top: 0;
    }
  }

  &:empty {
    display: none;
  }
`

const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${rem(16)};
  &.mapTile {
    @media screen and (min-width: ${SPLIT_VIEW_BREAKPOINTS.medium}px) {
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      > * + * {
        width: 100%;
      }
    }
  }
`

const StyledPricingAndViewOffer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(8)};
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    > * + * {
      width: 100%;
    }

    width: 200px;
    gap: 0;
    flex-direction: column;
  }


  &.mapTile {
    flex-direction: row;
    align-items: end;
    gap: ${rem(8)};
    justify-content: space-between;
    width: 100%;
  }
`

const PromotionsBlock = styled.div`
  width: 100%;
  margin-top: 0;
`

const ViewOfferButton = styled(TextButton)`
  margin-top: ${rem(16)};
  &.mapTile {
    width: fit-content;
    padding: 0 ${rem(20)};
  }
`

const TileHeading = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rem(12)};
  justify-content: space-between;
  grid-column-start: span 2;
`

function getFilterHighlightMessage(offer: App.BedbankOffer | App.BedbankOfferSummary, hasRate: boolean, hasDates: boolean, filters?: App.OfferListFilters) {
  if (filters?.propertyId && !filters?.searchNearby && offer.property?.id === filters.propertyId.split(':')[1]) {
    // This is a property search result

    if (hasDates) {
      if (!hasRate) {
        return HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
      }
      return 'We found a great price for your hotel. Book now to secure this rate!'
    }
    else {
      return 'We found the property you were interested in. Select your dates to book now.'
    }
  }
}

interface MappedStateProps {
  suggestedDates: App.OfferSuggestedDates;
  windowSearch: string;
  location: Location;
  isSpoofed: boolean;
  luxPlusLPPEnabled: boolean;
}

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  onImageChange?: (idx: number, image?: App.Image) => void,
  flightPrice: number | undefined
  flightsEnabled: boolean;
  hotelOnlyRates: Array<App.BedbankRate>;
  hotelBundleRates: Array<App.BedbankRate>;
  fetchingRates: boolean;
  directSearchPrices?: App.OfferListMetadataPricing;
  cancellationPolicy?: BedbankCancellationPolicyWithInfo;
}

function BedbankOfferTileCondensed({
  offer,
  filters,
  eagerLoadFirstImage,
  offerUrl,
  suggestedDates,
  windowSearch,
  location,
  isSpoofed,
  onImageChange,
  flightPrice,
  flightsEnabled,
  hotelOnlyRates,
  hotelBundleRates,
  fetchingRates,
  directSearchPrices,
  cancellationPolicy,
  luxPlusLPPEnabled,
}: Props & MappedStateProps) {
  const checkIn = filters?.checkIn ?? suggestedDates?.checkIn
  const checkOut = filters?.checkOut ?? suggestedDates?.checkOut

  const offerMetadata = useOfferMetaData(offer.id, filters)

  const isSearchAvailable = directSearchPrices && offerMetadata?.available

  const { images, name, tagline, id, property } = offer

  const rate = hotelOnlyRates[0]
  const bundleRate = hotelBundleRates[0] || rate
  const hasDates = !!(checkIn && checkOut)
  const hasStandardOccupancy = useMemo(() => (sum(filters?.rooms || [], room => room.adults + (room.children ?? 0)) === 2), [filters?.rooms])

  const addressArray: Array<string> = []
  const { city, countryName } = property.address
  if (city) addressArray.push(city)
  if (countryName) addressArray.push(countryName)

  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()
  const isMobileScreen = useIsMobileScreen()
  const isMapPage = location.pathname.includes('/search/map')

  const locationTitle = property.locationTitleOverride ? property.locationTitleOverride : addressArray.join(', ')

  const hasInclusions = !!(rate?.refundable || rate?.facilities?.length || offerMetadata?.cancellationPolicyInfo?.refundable)

  const impressionRef = useImpressionHandler(id)

  const { onBedbankSelectDatesPrompt, searchTargetLabel, offerDistanceFromSearchTarget } = useContext(GlobalSearchStateContext)

  const searchTargetDistance = offerDistanceFromSearchTarget?.[offer.id]

  const onSelectDatesPrompt = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (isMobileScreen) {
      toggleSearchVertical(SEARCH_VERTICALS.HOTELS)
    } else {
      onBedbankSelectDatesPrompt?.(offer.id, offer.type, windowSearch)
    }
  }, [isMobileScreen, offer.id, offer.type, onBedbankSelectDatesPrompt, toggleSearchVertical, windowSearch])

  const promotions = useBedbankSearchTilePromotions(
    offer.promotions,
    rate ?? (directSearchPrices?.lowestPriceRoomRateId ? { id: directSearchPrices?.lowestPriceRoomRateId } : undefined),
    checkIn,
    checkOut,
  )

  const hasMobilePromotion = rate?.mobilePromotion ?? directSearchPrices?.lowestPriceMobilePromotion ?? false

  const limitNumberOfFacilities = getBedbankFacilitiesLimit(!!tagline, promotions.length)

  const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut
  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch

  const highlightMessage = getFilterHighlightMessage(offer, !!rate || (isSearchAvailable && hasDates), hasDates, filters)

  const mapSearchUrl = `/search/map?${setSearchParamValue(windowSearch, queryKeySelectedOfferIds, encodeOfferIds([offer]))}`

  const showAmenities = offer.popularFacilities.length > 0

  const isLTSOffer = offerMetadata?.hasPromotions || promotions.length > 0

  const imageParams: ImageParams = {
    mobileAspectRatio: '3:2',
    mobileWidth: '100vw',
  }

  const discount = useBedbankDiscount(rate?.discount, rate?.memberDiscount)

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    discountPercentage={Math.round(discount * 100)}
    align="left"
  />

  const isSoldOut = (!rate && hasDates && !fetchingRates)
  useOfferSoldOutPushDown(offer.id, filters, isSoldOut)
  const hasSellValueAndStandardOccupancy = !!offer.sell && hasStandardOccupancy

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    if (!fetchingRates) {
      dispatchOfferListEvent({
        type: OfferListEvents.offerReady,
        available: !isSoldOut,
      })
    }
  }, [dispatchOfferListEvent, isSoldOut, fetchingRates])

  const promoteAsBundle = flightsEnabled && !!flightPrice && offer.promoteAsBundle

  const {
    offerPrice,
    offerMemberPrice,
  } = useSearchBedbankOfferPrices({
    rate,
    offer,
    filters,
    bundleRate,
    suggestedDates,
    directSearchPrices,
    flightPrice,
    promoteAsBundle,
    isFlexibleSearch,
    rooms: filters?.rooms,
    promotions,
  })

  return (
    <Root ref={impressionRef}>
      {!fetchingRates && highlightMessage && <HighlightMessage message={highlightMessage} />}
      <Main className={cn({ mapTile: isMapPage })}>
        <MainImageSegment>
          <OfferOverlayContainer>
            {isLTSOffer ?
              <ProductTypeLabel kind="default" productType="limited_time_special" /> :
              <ProductTypeLabel kind={isSpoofed ? 'default' : 'hidden'} productType={OFFER_TYPE_BED_BANK} />}
            <TripGuard>
              <StyledCSSBreakpoint min="tablet">
                <FloatingBookmarkContainer className="slim-tile">
                  <BookmarkButton offer={offer} />
                </FloatingBookmarkContainer>
              </StyledCSSBreakpoint>
            </TripGuard>
          </OfferOverlayContainer>

          <TripGuard>
            <StyledCSSBreakpoint max="mobile">
              <FloatingBookmarkContainer className="slim-tile">
                <BookmarkButton offer={offer} />
              </FloatingBookmarkContainer>
            </StyledCSSBreakpoint>
          </TripGuard>

          <ImageCarousel
            images={images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            onImageChange={onImageChange}
            onImageLoad={handleImageLoaded}
          />
        </MainImageSegment>
        <Content className={cn({ 'has-dates': hasDates, 'has-sell': !hasDates && offer.sell, mapTile: isMapPage })}>
          <TileHeading>
            <div>
              <Group direction="vertical" gap={4}>
                <VerticalSpacer gap={8}>
                  {hasMobilePromotion && <BedbankMobileOnlyPriceLabel />}
                  <OfferTileLocationSection
                  location={locationTitle}
                  mapSearchUrl={mapSearchUrl}
                  searchTargetDistance={searchTargetDistance}
                  searchTargetLabel={searchTargetLabel}
                  shouldShowMapLink
                />
                </VerticalSpacer>
                <Heading variant="heading5" as="h4">{name}</Heading>
              </Group>
            </div>
            <div>
              {isOfferRatingDisplayable(property.rating) &&
                <>
                  <CSSBreakpoint min="tablet">
                    <OfferRating
                      variant="small"
                      rating={property.rating}
                      numberPosition="end"
                    />
                  </CSSBreakpoint>
                  <CSSBreakpoint max="mobile">
                    <OfferRating
                      variant="small"
                      rating={property.rating}
                      onlyNumber
                    />
                  </CSSBreakpoint>
                </>
                }
            </div>
          </TileHeading>
          <PropertyDetails gap={4}>
            {!isSoldOut && promotions[0] && (<PromotionsBlock>
              <OfferUrgencyCountdownLabel endDate={promotions[0].travelPeriod.to} daysToBook={30} />
            </PromotionsBlock>)}
            {(!!rate || !!cancellationPolicy) && (
              <BedbankCancellationPolicy
                rate={rate ?? cancellationPolicy}
                offer={offer}
                showNonCancellation={false}
              />
            )}
            {luxPlusLPPEnabled && <LuxLPPPricingLabel offer={offer} hasLuxPlusRate={directSearchPrices?.hasLuxPlusRate} offerMemberPrice={offerMemberPrice} offerPrice={offerPrice} />}
            {!showAmenities && hasInclusions && <RoomList offer={offer} rate={rate} limitNumberOfFacilities={!showAmenities ? limitNumberOfFacilities : 0} showNonCancellation={false} />}
            {!hasDates && !showAmenities && <BedbankOfferTileSnippet offer={offer as App.BedbankOffer} />}
            {showAmenities && 'popularFacilities' in offer && <BedbankFacilities popularFacilities={offer.popularFacilities} />}
          </PropertyDetails>
          <PricingDetails className={cn({ mapTile: isMapPage, 'slim-offer-tile': true })}>
            <FooterContentHolder>
              {(!hasDates && !(hasSellValueAndStandardOccupancy)) && <FooterButtons className={cn({ mapTile: isMapPage })}>
                <TextButton outdent="left" kind="tertiary">View details</TextButton>
                <TextButton kind="primary" onClick={onSelectDatesPrompt}>Select dates</TextButton>
              </FooterButtons>}
            </FooterContentHolder>
            <WalledContent enforced={offer.walledGarden && (hasDates || hasSellValueAndStandardOccupancy)} hidePricing={offer.hidePricing} wall={wall}>
              {(rate || (isAnytimeSearch && hasStandardOccupancy && offer.sell) || isSearchAvailable) && <StyledPricingAndViewOffer className={cn({ mapTile: isMapPage })}>
                <BedbankOfferTilePriceDetails
                  className="slim-offer-tile"
                  rate={rate}
                  bundleRate={bundleRate}
                  rooms={filters?.rooms ?? []}
                  offer={offer}
                  filters={filters}
                  offerUrl={offerUrl}
                  promotions={promotions}
                  flightPrice={flightPrice}
                  flightsEnabled={flightsEnabled}
                  allRates={hotelOnlyRates}
                  directSearchPrices={directSearchPrices}
                />
                <ViewOfferButton className={cn({ mapTile: isMapPage })} kind="primary">View offer</ViewOfferButton>
              </StyledPricingAndViewOffer>}
              {(isSoldOut && !isSearchAvailable) && <div><SoldOutMessage offer={offer} filters={filters} /></div>}
            </WalledContent>
          </PricingDetails>
        </Content>
      </Main>
    </Root>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps): MappedStateProps => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)
  return {
    location: appState.router.location,
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
    windowSearch: appState.router.location.search,
    isSpoofed: appState.auth?.account.isSpoofed,
    luxPlusLPPEnabled: isLuxPlusLPPEnabled(appState),
  }
})(BedbankOfferTileCondensed)
