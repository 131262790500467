import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const scooponTravelDefaultPalette: App.Palette = {
  ...whiteLabelBaseDefaultPalette,
}

export const scooponTravelShadows: App.ThemeShadows = { ...baseThemeShadows }
export const scooponTravelBorderRadii: App.ThemeBorderRadii = { ...whiteLabelBaseBorderRadii }

const scooponTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default scooponTravelTheme
