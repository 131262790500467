import React, { useCallback, useContext, useMemo } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Pane from 'components/Common/Pane'
import { take } from 'lib/array/arrayUtils'
import Image from 'components/Common/Image'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR } from 'constants/offer'
import { INTL_DMY_CASUAL_FORMAT, LONG_MONTH_LONG_YEAR } from 'constants/dateFormats'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import OfferBadge from 'components/Common/OfferBadge'
import OfferTilePricing from './Support/OfferTilePricing'
import FrontPageTileFlightPricing from './Support/FrontPageTileFlightPricing'
import OfferTileLoggedInButtons from './Support/OfferTileLoggedInButtons'
import TextButton from 'components/Luxkit/Button/TextButton'
import FrontPageTileFlightOnlyPricing from './Support/FrontPageTileFlightOnlyPricing'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import Heading from 'components/Luxkit/Typography/Heading'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import GeoContext from 'contexts/geoContext'
import { ImageParams } from 'components/Common/ResponsiveImage'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isFlexibleCancellationPolicy } from 'lib/offer/cancellationPolicyUtils'
import OfferPropertyFlexibleCancellationPolicies from 'components/Common/CancellationPolicies/Flash/OfferPropertyFlexibleCancellationPolicies'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import moment from 'moment'
import { useIntlDateFormatter } from 'lib/datetime/dateUtils'
import WalledContent from 'components/Common/WalledContent'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { useAppSelector } from 'hooks/reduxHooks'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { getInclusionsViewableLuxPlusTier } from 'selectors/luxPlusSelectors'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'

const Main = styled(Pane)`
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-rows: ${rem(230)} auto;

  ${mediaQueryUp.tablet} {
    grid-template-rows: ${rem(360)} auto;
  }

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
  }
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)};
  }
`

const DetailSegment = styled.div`
  position: relative;
  padding: ${rem(20)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  ${mediaQueryUp.desktop} {
    flex: 1;
    text-align: center;
    padding: ${rem(16)};
  }
`

const Logo = styled(Image)`
  width: ${rem(90)};
  height: ${rem(60)};

  ${mediaQueryUp.desktop} {
    width: ${rem(150)};
    height: ${rem(100)};
  }
`

const OfferContent = styled.div`
  > * + * {
    margin-top: ${rem(8)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(12)};
    }
  }
`

const BottomSegmentDetails = styled.div``
const BottomSegmentCTA = styled(Group)``
const BottomSegmentExtra = styled(Group)``
const BottomSegment = styled.div`
  margin-top: ${rem(12)};
`

const BottomSegmentGrid = styled.div`
  display: grid;
  grid-column-gap: ${rem(8)};
  grid-template:
    "details cta"
    "extra extra" auto / 1fr ${rem(120)};

  > ${BottomSegmentDetails} {
    grid-area: details;
  }
  > ${BottomSegmentCTA} {
    grid-area: cta;
    align-self: end;
  }
  > ${BottomSegmentExtra} {
    grid-area: extra;
    margin-top: ${rem(16)};
  }

  ${mediaQueryUp.desktop} {
    grid-template:
      "details"
      "." ${rem(16)}
      "cta"
      "extra" auto / 1fr;
  }
`

const UrgencyLabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / 1;

  ${mediaQueryUp.desktop} {
    justify-content: center;
  }

  > * + * {
    margin-left: ${rem(4)};

    ${mediaQueryUp.desktop} {
      margin-left: ${rem(12)};
    }
  }
`

const MainImageSegment = styled.div`
  display: grid;
  gap: ${rem(4)};
  position: relative;

  ${mediaQueryUp.desktop} {
    &.additional {
      grid-template-rows: 1fr ${rem(120)};
    }
  }
`

const AdditionalImages = styled.div`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
    gap: ${rem(4)};
  }
`

const USDesktopRating = styled(OfferRating)`
  justify-content: center;
`

const OfferBadgeTagWrapper = UrgencyLabelsWrapper

interface Props {
  offer: App.Offer | App.OfferSummary;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  onImageChange?: (idx: number, image?: App.Image) => void
}

const imageParams: ImageParams = {
  aspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
  quality: 'good',
}

const flashOfferTypes = [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR]

function MainOfferTile(props: Props) {
  const {
    offer,
    eagerLoadFirstImage,
    offerUrl,
    onImageChange,
  } = props

  const {
    property,
    name,
    offerFlightsEnabled,
    lowestPricePackage,
    travelToDate,
  } = offer
  const intlDateFormatter = useIntlDateFormatter()
  const tour = lowestPricePackage?.tour

  const { currentRegionCode, currentRegionHasFlights } = useContext(GeoContext)

  const showAdditionalImages = flashOfferTypes.includes(offer.type) && offer.images.length > 3
  const isFlash = flashOfferTypes.includes(offer.type)

  const cancellationPolicy = lowestPricePackage?.roomRate?.cancellationPolicy
  const isFlexibleCancellation = isFlexibleCancellationPolicy(cancellationPolicy)
  const isCruise = offer.holidayTypes?.includes('Cruises')
  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))
  const inclusionsViewableLuxPlusTier = useAppSelector(getInclusionsViewableLuxPlusTier)

  const cruiseLocation = useMemo(() => {
    if (isCruise && offer.startLocation && offer.endLocation) {
      return offer.startLocation === offer.endLocation ?
      `Starts and ends in ${offer.startLocation}` :
      `Starts in ${offer.startLocation}, Ends in ${offer.endLocation}`
    }
    return
  }, [isCruise, offer])

  const location = offer.type === OFFER_TYPE_TOUR ? offer.location : [offer.locationHeading, offer.locationSubheading].filter(t => t).join(', ')
  const pricingSection = <BottomSegmentGrid>
    <BottomSegmentDetails>
      {offer.bundledWithFlightsOnly && <FrontPageTileFlightOnlyPricing offer={offer} />}
      {!offer.bundledWithFlightsOnly && lowestPricePackage &&
        <OfferTilePricing
          offer={offer}
          pkg={lowestPricePackage}
          offerUrl={offerUrl}
          desktopAlign="center"
        />}
    </BottomSegmentDetails>
    <BottomSegmentCTA direction="vertical">
      <TextButton kind="primary">
        <CSSBreakpoint max="tablet">View</CSSBreakpoint>
        <CSSBreakpoint min="desktop">View Offer</CSSBreakpoint>
      </TextButton>
    </BottomSegmentCTA>
    <BottomSegmentExtra direction="vertical" horizontalAlign="start" desktopHorizontalAlign="center" noDisplayWhenEmpty>
      {currentRegionHasFlights && offerFlightsEnabled && !offer.bundledWithFlightsOnly &&
        <FrontPageTileFlightPricing offer={offer}/>}
    </BottomSegmentExtra>
  </BottomSegmentGrid>

  const isUSRegion = currentRegionCode === 'US'

  const memberPrice = lowestPricePackage?.memberPrice ?? 0
  const showMemberPrice = luxPlusEnabled && memberPrice > 0
  const saveAmount = showMemberPrice ? (lowestPricePackage?.price ?? 0) - memberPrice : undefined
  const luxPlusTileInclusions = offer?.luxPlusTileInclusions
  const luxPlusInclusions = lowestPricePackage?.luxPlusInclusionsByTier?.[inclusionsViewableLuxPlusTier]

  const wall = <OfferTileLoggedInButtons
  signUpText="Sign up for free"
  discountPercentage={Math.round(lowestPricePackage?.discountPercent ?? 0)}
  align="none"
  fullWidth />

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return (
    <ProductPaletteProvider product={offer}>
      <Main type="clean">
        <MainImageSegment className={cn({ additional: showAdditionalImages })}>
          <ImageCarousel
            images={offer.images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            topLeft={<LabelGroup>
              <ProductTypeLabel productType={offer.productType} />
              <OfferPartnershipLabel offer={offer} />
            </LabelGroup>}
            topRight={offer.badge && <OfferBadge badge={offer.badge} />}
            onImageChange={onImageChange}
            onImageLoad={handleImageLoaded}
          />
          {showAdditionalImages && <AdditionalImages>
            {take(offer.images, 3, 1).map((image) => (
              <Image
              key={image.id}
              id={image.id}
              alt={name}
              height={120}
              width={245}
              loading="lazy"
              fit="center"
            />
            ))}
          </AdditionalImages>}
        </MainImageSegment>
        <DetailContainer>
          <CSSBreakpoint min="desktop">
            <Group direction="horizontal" horizontalAlign="space-between">
              {!isUSRegion && isOfferRatingDisplayable(offer.property?.rating) && (
                <OfferRating
                  variant="medium"
                  rating={offer.property.rating}
                />
              )}
              <TripGuard>
                <BookmarkButton offer={offer} />
              </TripGuard>
            </Group>
          </CSSBreakpoint>
          <DetailSegment>
            <TripGuard>
              <CSSBreakpoint max="tablet">
                <OffsetBookmarkContainer>
                  <BookmarkButton offer={offer} />
                </OffsetBookmarkContainer>
              </CSSBreakpoint>
            </TripGuard>
            <Group direction="vertical" gap={8}>
              <OfferLabels
                offer={offer}
                desktopHorizontalAlign="center"
                saveAmount={saveAmount}
                showMemberInclusionLabels
                luxPlusTileInclusions={luxPlusTileInclusions}
                luxPlusInclusions={luxPlusInclusions}
              />
              <Group direction="horizontal" desktopDirection="vertical-reverse" horizontalAlign="space-between" desktopHorizontalAlign="center" verticalAlign="center" gap="4 16">
                <Group direction="vertical" horizontalAlign="start" desktopHorizontalAlign="center">
                  <Subtitle variant="subtitle3">{cruiseLocation || location}</Subtitle>
                  <Heading variant="heading6">{property?.name ?? offer.name}</Heading>
                </Group>
                <div>
                  {property?.logoImageId && <Logo id={property.logoImageId} width="300" aspectRatio="3:2" alt={`${property.name || 'Vendor'} logo`}/>}
                  {tour?.logoImageId && <Logo id={tour.logoImageId} width="300" aspectRatio="3:2" alt={`${tour.name || 'Tour'} logo`}/>}
                </div>
              </Group>

              {isOfferRatingDisplayable(offer.property?.rating) && (
                <>
                  <CSSBreakpoint max="tablet">
                    <OfferRating
                      inlineLabel
                      variant="xs"
                      rating={offer.property.rating}
                    />
                  </CSSBreakpoint>
                  {isUSRegion && (
                    <CSSBreakpoint min="desktop">
                      <USDesktopRating
                        inlineLabel
                        variant="xs"
                        rating={offer.property.rating}
                      />
                    </CSSBreakpoint>
                  )}
                </>
              )}

              <OfferContent>
                {property && <BodyText variant="large">{offer.name}</BodyText>}
                {isCruise &&
                  <VerticalSpacer gap={0}>
                    { offer.vendorName &&
                      <BodyText variant="large" weight="normal">
                        Cruise line: {offer.vendorName}
                      </BodyText>
                    }
                    { offer.vendorVehicle &&
                      <BodyText colour="primary" variant="large" weight="bold">
                        Cruise ship: {offer.vendorVehicle}
                      </BodyText>
                    }

                  </VerticalSpacer>
                }

                {isFlash && isFlexibleCancellation && <OfferPropertyFlexibleCancellationPolicies cancellationPolicy={cancellationPolicy} propertyTimezoneOffset={property?.timezoneOffset} />}
                {isFlash && isCruise && <Caption colour="primary" variant="large" weight="bold">Sailing until {moment(travelToDate).format(LONG_MONTH_LONG_YEAR)}</Caption>}
                {isFlash && !isCruise && <Caption colour="primary" variant="large" weight="bold">Travel until {intlDateFormatter(travelToDate, INTL_DMY_CASUAL_FORMAT)}</Caption>}
                {(!!offer.badge || offer.bundledWithFlightsOnly) && <OfferBadgeTagWrapper>
                  {offer.badge && <OfferBadgeTag badge={offer.badge} />}
                </OfferBadgeTagWrapper>}
              </OfferContent>
            </Group>

            <BottomSegment>
              <WalledContent enforced={offer.walledGarden} wall={wall}>
                {pricingSection}
              </WalledContent>
            </BottomSegment>
          </DetailSegment>
        </DetailContainer>
      </Main>
    </ProductPaletteProvider>
  )
}

export default MainOfferTile
