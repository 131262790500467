import { PreProcessor, appendParams } from 'api/requestUtils'

export default function brandLoader(brand: string): PreProcessor {
  return (params) => {
    const { path } = params

    if (path.includes('without_brand=1') || path.includes('/api/trips') || path.includes('/api/search/place/v1/places') || path.includes('business-traveller/employee') || path.includes('business-traveller/request-demo') || path.includes('rate-plans/surcharge-dates/margin')) {
      return params
    }

    appendParams({
      key: 'brand',
      value: brand,
    }, params)
    return params
  }
}
