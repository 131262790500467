import { useAppSelector } from 'hooks/reduxHooks'
import { isBedbank, isLpcHotel } from 'lib/offer/offerTypes'
import { EmptyObject } from 'lib/object/objectUtils'

interface Props {
  offer: App.AnyOffer | undefined;
  filters?: App.OfferListFilters;
}

function useShouldHighlight({ offer, filters = EmptyObject }: Props): boolean {
  // For bedbank offers only, so it doesn't matter if it's not a bedbank offer
  const fetchingRates = useAppSelector(state => (offer && state.offer.offerRatesLoading[offer.id]) ?? true)

  if (!offer || !filters) {
    return false
  }

  if (isLpcHotel(offer)) {
    return !!(
      filters.propertyId &&
      !filters.searchNearby &&
      (offer.property?.id ?? '') === filters.propertyId.split(':')[1]
    )
  }

  if (isBedbank(offer)) {
    return !!(
      !fetchingRates &&
      filters.propertyId &&
      !filters.searchNearby &&
      (offer.property?.id ?? '') === filters.propertyId.split(':')[1]
    )
  }

  return false
}

export default useShouldHighlight
