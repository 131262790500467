import React, { useContext, useEffect } from 'react'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import MasterModalContext from 'contexts/MasterModalContext'
import {
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { matchPath } from 'react-router'
import { checkHasLuxLoyaltyAccount, isLuxLoyaltyEnabled } from 'luxLoyalty/selectors/featureToggles'
import LuxLoyaltyJoinModal from 'luxLoyalty/components/LuxLoyaltyJoinModal'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { fetchLuxLoyaltyAccountEligibility } from 'actions/LuxLoyaltyActions'

const pathsBlacklist = [
  '/:region/checkout',
  '/:region/booking/confirmation',
  `/:region/${LUX_LOYALTY.LANDING_PAGE}`,
  '/:region/offer',
]

function useLuxLoyaltyJoinModal() {
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const luxLoyaltyEnabled = useAppSelector(isLuxLoyaltyEnabled)
  const isDismissed = !!getLocalStorage(LUX_LOYALTY.DISMISS_JOIN_MODAL_STORAGE_KEY)
  const modalState = useContext(MasterModalContext)
  const hasLuxLoyaltyAccount = useAppSelector(checkHasLuxLoyaltyAccount)
  const luxLoyaltyAccount = useAppSelector(state => state.luxLoyalty.account)
  const hasProgramConfigDetails = useAppSelector(state => !!state.luxLoyalty.programConfig?.configDetails)
  const luxLoyaltyEligibility = useAppSelector(state => state.luxLoyalty.eligibility)

  const path = useAppSelector(state => state.router.location.pathname)
  const isModalPreventedPage = !!matchPath(path, {
    path: pathsBlacklist,
    exact: false,
  })

  const shouldFetchEligibility = loggedIn && luxLoyaltyEnabled && !isDismissed && !hasLuxLoyaltyAccount
  const shouldShowModal = loggedIn &&
  luxLoyaltyEnabled &&
  !isDismissed &&
  !modalState.open &&
  !hasLuxLoyaltyAccount &&
  !luxLoyaltyAccount.fetching &&
  !luxLoyaltyAccount.error &&
  !isModalPreventedPage &&
  hasProgramConfigDetails &&
  !luxLoyaltyEligibility.fetching &&
  !luxLoyaltyEligibility.error &&
  !!luxLoyaltyEligibility.tier

  useEffect(() => {
    if (shouldFetchEligibility) {
      dispatch(fetchLuxLoyaltyAccountEligibility())
    }
  }, [shouldFetchEligibility, dispatch])

  useEffect(() => {
    if (shouldShowModal) {
      showModal(<LuxLoyaltyJoinModal />)
    }
  }, [shouldShowModal, showModal])
}

export default useLuxLoyaltyJoinModal
