import { GetQuotePayload } from 'api/insurance'
import { INSURANCE_VALID_COUNTRIES } from 'constants/config/region'
import { isCruiseItem, isTourV1Item } from 'lib/checkout/checkoutUtils'
import { findByName } from 'lib/insurance/insuranceCountries'

export type ValidCountry = GetQuotePayload['destination_countries'][0]
export type ValidRegion = GetQuotePayload['country_code']

export function isValidCountry(country: string | undefined): country is ValidCountry {
  if (!country) return false
  const result = findByName(country)
  return !!result
}

export function isValidRegion(region: string): region is ValidRegion {
  return INSURANCE_VALID_COUNTRIES.includes(region)
}

export function overrideInsuranceParams(
  params: GetQuotePayload,
  overrides: App.Checkout.InsuranceFetchParameters,
): GetQuotePayload {
  return {
    ...params,
    ...((overrides.numberOfSeniors && params.travellers_details?.number_of_adults) && {
      travellers_details: {
        ...params.travellers_details,
        number_of_adults: params.travellers_details.number_of_adults - overrides.numberOfSeniors,
        number_of_seniors: overrides.numberOfSeniors,
      },
    }),
    trip_start_date: overrides.startDate,
    trip_end_date: overrides.endDate,
    destination_countries: overrides.destinationCountries as Array<ValidCountry>,
  }
}

export function isCruise(cartItems: Array<App.Checkout.AnyItem>, offer: Record<string, App.Offer>): boolean {
  let isCruise = !!cartItems.find(isCruiseItem)
  if (!isCruise) {
    const cruiseOfTourV1 = cartItems.find(isTourV1Item)
    isCruise = cruiseOfTourV1 && offer[cruiseOfTourV1.offerId].holidayTypes?.includes('Cruises') || false
  }
  return isCruise
}
