import React, { useMemo } from 'react'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import Caption from 'components/Luxkit/Typography/Caption'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import moment from 'moment/moment'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import { flashOffersWithLuxPlus } from 'lib/cruises/cruiseUtils'

interface Props {
  offerTitle: string;
  departureCity?: string;
  destinationCity?: string;
  promotionDetails: App.CruisePromotionDetails | null;
  cruiseLineImage?: string;
  cruiseLineName?: string;
  isSpecialOffer?: boolean;
  badge?: App.OfferBadge;
  isAgentHubExclusive: boolean;
  flashOffer?: App.OfferSummary
}

function CruiseTileHeader({
  offerTitle,
  departureCity,
  destinationCity,
  promotionDetails,
  cruiseLineImage,
  cruiseLineName,
  isSpecialOffer,
  badge,
  isAgentHubExclusive,
  flashOffer,
}: Props) {
  const promotionEndingDate = useMemo(() => {
    const { isEndingIn } = promotionDetails ?? {}
    return isEndingIn ? moment().add(isEndingIn, 'days').format('YYYY-MM-DD') : undefined
  }, [promotionDetails])

  return <Group gap={4} direction="vertical">
    {/* TODO Remove hardcoded IDs in https://aussiecommerce.atlassian.net/browse/CRUZ-2417 */}
    {!!flashOffer?.id && flashOffersWithLuxPlus.includes(flashOffer.id) && !!flashOffer?.lowestPricePackage?.memberPrice &&
      <LuxPlusLabelWithModal
                    offer={flashOffer}
                    saveAmount={Number(flashOffer?.lowestPricePackage?.price.toFixed()) - Number(flashOffer?.lowestPricePackage?.memberPrice.toFixed())}
                    type="member-price-offer-tour"
                />}
    <LabelGroup>
      {isAgentHubExclusive && <PromotionLabel key="agenthub_exclusive" variant="engagement">Agent Exclusive</PromotionLabel>}
      {promotionDetails?.isNew && (
        <UrgencyLabel variant="positive">NEW</UrgencyLabel>
      )}
      {isSpecialOffer && (
        <PromotionLabel icon={<SolidFireIcon />} variant="premium">
          Special offer
        </PromotionLabel>
      )}
      {!!badge && <OfferBadgeTag badge={badge} />}
      {!!promotionEndingDate && <OfferUrgencyCountdownLabel endDate={promotionEndingDate}/>}
    </LabelGroup>
    <Group gap={4} direction="vertical">
      <Caption variant="large">
        {departureCity === destinationCity && <>Starts and ends in <b>{departureCity}</b></>}
        {departureCity !== destinationCity && <>Starts in <b>{departureCity}</b>. Ends in <b>{destinationCity}</b></>}
      </Caption>
      <Group gap={4} direction="horizontal" horizontalAlign="space-between">
        <Heading variant="heading5" lineClamp={3}>{offerTitle}</Heading>
        {cruiseLineImage && (
          <CSSBreakpoint max="tablet">
            <ResponsiveImage
              id={cruiseLineImage}
              src={cruiseLineImage}
              width={75}
              quality="eco"
              alt={cruiseLineName}
              role="presentation"
            />
          </CSSBreakpoint>
        )}
      </Group>
    </Group>
  </Group>
}

export default CruiseTileHeader
