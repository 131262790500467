import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  SET_CUSTOMER_VIEW_TOGGLE,
} from 'actions/actionConstants'
import {
  FETCH_ACCOUNT_AGENT_HUB,
  FETCH_AGENT_COMMISSION_CONFIG,
  USER_LOGOUT,
  FETCH_AGENT_STATUS,
  ONBOARD_AGENCY,
  FETCH_AGENT_AFFILIATION_OPTIONS,
} from 'actions/apiActionConstants'

export const AGENT_HUB_INITIAL_STATE: App.AgentHubState = {
  agentHubAccount: {
    account: undefined,
    fetching: false,
    error: undefined,
  },
  agentCommissions: {
    baseCommissions: [],
    additionalCommissions: [],
  },
  agentStatus: {},
  customerView: false,
  onboardingAgency: false,
  onboardingAgencyError: undefined,
  affiliationOptions: {
    fetching: false,
    error: undefined,
    options: {},
  },
}

const apiRequests = reducerSwitch<App.AgentHubState>({
  [FETCH_ACCOUNT_AGENT_HUB]: () => {
    return {
      agentHubAccount: {
        account: undefined,
        fetching: true,
        error: undefined,
      },
    }
  },
  [ONBOARD_AGENCY]: () => {
    return {
      onboardingAgency: true,
      onboardingAgencyError: undefined,
    }
  },
  [FETCH_AGENT_AFFILIATION_OPTIONS]: (state, action) => ({
    affiliationOptions: {
      fetching: true,
      error: undefined,
      options: {
        ...state.affiliationOptions.options,
        [action.key]: [],
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.AgentHubState>({
  [FETCH_ACCOUNT_AGENT_HUB]: (state, action) => ({
    agentHubAccount: {
      account: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_AGENT_COMMISSION_CONFIG]: (state, action) => ({
    agentCommissions: action.data,
  }),
  [FETCH_AGENT_STATUS]: (state, action) => ({
    agentStatus: {
      ...state.agentStatus,
      [action.key]: {
        status: action.data.status,
        action: action.data.action,
        agentId: action.data.agentId,
        firstName: action.data.firstName,
        surname: action.data.surname,
        email: action.data.email,
        phone: action.data.phone,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [USER_LOGOUT]: () => AGENT_HUB_INITIAL_STATE,
  [ONBOARD_AGENCY]: (state, action) => {
    return {
      onboardingAgency: false,
      agentHubAccount: {
        fetching: false,
        error: undefined,
        account: action.data,
      },
    }
  },
  [FETCH_AGENT_AFFILIATION_OPTIONS]: (state, action) => {
    return {
      affiliationOptions: {
        fetching: false,
        error: undefined,
        options: {
          ...state.affiliationOptions.options,
          [action.key]: action.data,
        },
      },
    }
  },
})

const apiFailures = reducerSwitch<App.AgentHubState>({
  [FETCH_ACCOUNT_AGENT_HUB]: (state, action) => ({
    agentHubAccount: {
      account: undefined,
      fetching: false,
      error: action.error.message ?? action.error,
    },
  }),
  [FETCH_AGENT_STATUS]: (state, action) => ({
    agentStatus: {
      ...state.agentStatus,
      [action.key]: {
        status: undefined,
        agentId: undefined,
        action: undefined,
        firstName: undefined,
        surname: undefined,
        email: undefined,
        phone: undefined,
        fetching: false,
        error: action.error.message ?? action.error,
      },
    },
  }),
  [ONBOARD_AGENCY]: (state, action) => {
    return {
      onboardingAgency: false,
      onboardingAgencyError: action.error,
    }
  },
  [FETCH_AGENT_AFFILIATION_OPTIONS]: (state, action) => ({
    affiliationOptions: {
      fetching: false,
      error: action.error,
      options: {
        ...state.affiliationOptions.options,
        [action.key]: [],
      },
    },
  }),
})

export default createReducer<App.AgentHubState>(AGENT_HUB_INITIAL_STATE, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [SET_CUSTOMER_VIEW_TOGGLE]: (state, action) => ({
    customerView: action.value,
  }),
})
