import React from 'react'

import { leSpecificHolidayTypes } from 'api/config/search'

import {
  queryNullChildren,
  queryChildrenDelimiter,
  FLEXIBLE_DURATION_RANGE,
  DEFAULT_FLEXIBLE_DURATION_RANGE,
  ANYWHERE_PLACE_ID,
  ANYWHERE_NAME,
  SEARCH_VERTICALS,
} from 'constants/search'
import config from 'constants/config'
import { ISO_DATE_FORMAT, ISO_DATE_SHORT_FORMAT } from 'constants/dateFormats'
import { FLASH_HOTEL_PRODUCT_LABEL, LE_FLASH_HOTEL_PRODUCT_LABEL } from 'constants/productLabel'
import { OfferListSortOption } from 'constants/offerListFilters'
import {
  queryKeyAdults,
  queryKeyChildren,
  queryKeyDestinationName,
  queryKeyDestinationId,
  queryKeyLandmarkName,
  queryKeyLandmarkId,
  queryKeyPropertyName,
  queryKeyPropertyId,
  queryKeyExperienceName,
  queryKeyExperienceId,
  queryKeyBounds,
  queryKeyCheckIn,
  queryKeyCheckOut,
  queryKeyPages,
  queryFlexibleWithDate,
  queryKeyFlexibleNights,
  queryKeyFlexibleMonths,
  queryKeyUserSelectedFlexibleMonths,
  queryKeyFilters,
  queryKeySortBy,
  queryKeyPropertyTypes,
  queryKeyHolidayTypes,
  queryKeyHolidayTypesScoped,
  queryKeyAmenities,
  queryKeyInclusions,
  queryKeyBundledOfferId,
  queryKeyCustomerRatingGte,
  queryKeyMapPinCount,
  queryKeyUrlOfferType,
  queryKeySelectedOfferIds,
} from 'constants/url'

import LineClockIcon from 'components/Luxkit/Icons/line/LineClockIcon'
import LineLayersAltIcon from 'components/Luxkit/Icons/line/LineLayersAltIcon'
import LinePaletteIcon from 'components/Luxkit/Icons/line/LinePaletteIcon'
import LineTreesIcon from 'components/Luxkit/Icons/line/LineTreesIcon'
import LineStarCardIcon from 'components/Luxkit/Icons/line/LineStarCardIcon'
import LineClockDollarIcon from 'components/Luxkit/Icons/line/LineClockDollarIcon'
import LineUltraLuxDiamondIcon from 'components/Luxkit/Icons/line/LineUltraLuxDiamondIcon'
import LineFireIcon from 'components/Luxkit/Icons/line/LineFireIcon'
import LineHourglassIcon from 'components/Luxkit/Icons/line/LineHourglassIcon'
import LinePricetagAltIcon from 'components/Luxkit/Icons/line/LinePricetagAltIcon'
import LineMoneyStackIcon from 'components/Luxkit/Icons/line/LineMoneyStackIcon'
import LineWaterIcon from 'components/Luxkit/Icons/line/LineWaterIcon'
import { MonthOption } from 'components/Search/SearchForm/SearchDateInput/FlexibleDatePicker/MonthPicker'
import Line18PlusIcon from 'components/Luxkit/Icons/line/Line18PlusIcon'
import LineBuildingIcon from 'components/Luxkit/Icons/line/LineBuildingIcon'
import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import LineExhibitionsIcon from 'components/Luxkit/Icons/line/LineExhibitionsIcon'
import LineGolfIcon from 'components/Luxkit/Icons/line/LineGolfIcon'
import LineHeartCardIcon from 'components/Luxkit/Icons/line/LineHeartCardIcon'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import LineHomeAltIcon from 'components/Luxkit/Icons/line/LineHomeAltIcon'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import LineKidIcon from 'components/Luxkit/Icons/line/LineKidIcon'
import LineListUlAltIcon from 'components/Luxkit/Icons/line/LineListUlAltIcon'
import LineRestaurantIcon from 'components/Luxkit/Icons/line/LineRestaurantIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LineSightseeingIcon from 'components/Luxkit/Icons/line/LineSightseeingIcon'
import LineSnowFlakeIcon from 'components/Luxkit/Icons/line/LineSnowFlakeIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LineSunsetIcon from 'components/Luxkit/Icons/line/LineSunsetIcon'
import LineSwimmerIcon from 'components/Luxkit/Icons/line/LineSwimmerIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import LineWellnessIcon from 'components/Luxkit/Icons/line/LineWellnessIcon'
import LineMountainsSunIcon from 'components/Luxkit/Icons/line/LineMountainsSunIcon'
import LineWatersportsIcon from 'components/Luxkit/Icons/line/LineWatersportsIcon'
import LineSchoolBackpackIcon from 'components/Luxkit/Icons/line/LineSchoolBackpackIcon'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import { isTodayBeforeCheckin } from 'components/Search/utils'

import { deleteSearchParams, parseSearchString } from 'lib/url/searchUrlUtils'
import { arrayToObject, sortBy, take } from 'lib/array/arrayUtils'
import { isSameDay } from 'lib/datetime/dateUtils'
import { pluralizeToString } from 'lib/string/pluralize'

import moment, { Moment } from 'moment'
import switchFunc from 'lib/function/switchFunc'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { getOfferId } from 'components/Search/SearchResultsMap/utils'
import { isLEHotel } from 'lib/offer/offerTypes'
import { STREAMING_SUPPORTED_SORT_BY } from './constants'

const searchEngineReferrers = ['https://www.google.com/', 'https://www.bing.com/', 'https://au.search.yahoo.com/', 'https://yandex.com/']

function parseSearchItem(destinationName: string, destinationId: string, landmarkName: string, landmarkId: string, propertyName: string, propertyId: string, bounds: string): App.SearchItem | undefined {
  if (destinationName && destinationId) {
    return {
      searchType: 'destination',
      value: destinationId,
      format: {
        mainText: destinationName,
        secondaryText: '',
      },
    }
  }
  if (landmarkName && landmarkId) {
    return {
      searchType: 'landmark',
      value: landmarkId,
      format: {
        mainText: landmarkName,
        secondaryText: '',
      },
    }
  }
  else if (propertyName && propertyId) {
    return {
      searchType: 'property',
      value: propertyId,
      format: {
        mainText: propertyName,
        secondaryText: '',
      },
    }
  }
  else if (bounds) {
    return {
      searchType: 'bounds',
      value: bounds,
      format: {
        mainText: 'Map area',
        secondaryText: '',
      },
    }
  }

  return undefined
}

export interface SearchFilterParams {
  searchItem?: App.SearchItem;
  rooms: Array<App.Occupants>;
  dates: {
    checkIn?: string;
    checkOut?: string;
  },
  flexibleSearch: {
    flexibleNights?: string,
    flexibleMonths?: string,
    userSelectedFlexibleMonths?: boolean,
  }
  flexibleWithDate?: string,
  bundledOfferId?: string,
  customerRatingGte?: string,
  offerType?: App.OfferType,
}

// decodeSearchParams returns an object containing search parameters
// by parsing the given URL query parameters
export function decodeSearchParams(searchStr: string, maxChildAge?: number, maxInfantAge?: number): SearchFilterParams {
  const params = parseSearchString(searchStr)
  const { destinationName, offerType, destinationId, landmarkName, landmarkId, propertyName, propertyId, bounds, checkIn, checkOut, daysUntilCheckIn, duration, flexibleNights, flexibleMonths, userSelectedFlexibleMonths, customerRatingGte } = params

  const parsedSearchItem = parseSearchItem(destinationName, destinationId, landmarkName, landmarkId, propertyName, propertyId, bounds)
  const parsedOccupancy = parseOccupancy(searchStr, maxChildAge, maxInfantAge)

  const relativeCheckIn = daysUntilCheckIn && moment().add(parseInt(daysUntilCheckIn), 'days').format(ISO_DATE_FORMAT)
  const relativeCheckOut = daysUntilCheckIn && duration && moment().add(parseInt(daysUntilCheckIn) + parseInt(duration), 'days').format(ISO_DATE_FORMAT)

  const parsedDates = {
    checkIn: checkIn || relativeCheckIn || undefined,
    checkOut: checkOut || relativeCheckOut || undefined,
  }
  if (parsedDates.checkIn && !(isTodayBeforeCheckin(parsedDates.checkIn) || isSameDay(new Date(parsedDates.checkIn), new Date()))) {
    parsedDates.checkIn = undefined
    parsedDates.checkOut = undefined
  }

  // if user select travel flexible with date
  const flexibleWithDate = params.flexibleWithDate

  return {
    // @ts-ignore parsing a search item doesn't guarantee a search item will be returned, it's assumed it will here
    searchItem: parsedSearchItem,
    rooms: parsedOccupancy,
    dates: (parsedDates.checkIn && parsedDates.checkOut) ? parsedDates : { checkIn: undefined, checkOut: undefined },
    flexibleSearch: {
      flexibleNights,
      flexibleMonths,
      userSelectedFlexibleMonths: userSelectedFlexibleMonths === 'true' ? true : undefined,
    },
    offerType: offerType as App.OfferType,
    customerRatingGte,
    flexibleWithDate,
    bundledOfferId: params.bundledOfferId,
  }
}

export function getOccupancyFromSearchStrings(
  adults: number,
  children: Array<number> = [],
  maxChildAge?: number,
  maxInfantAge?: number,
): App.Occupants {
  const roomOccupancy = {
    adults,
    children: 0,
    infants: 0,
    childrenAge: [] as Array<number>,
  }

  for (const childAge of children) {
    if (maxChildAge && childAge > maxChildAge) {
      roomOccupancy.adults++
    } else if (childAge > (maxInfantAge ?? 0) && childAge <= (maxChildAge ?? 0)) {
      roomOccupancy.children++
      roomOccupancy.childrenAge.push(childAge)
    } else if (childAge <= (maxInfantAge ?? 0)) {
      roomOccupancy.infants++
      roomOccupancy.childrenAge.push(childAge)
    } else {
      roomOccupancy.children++
      roomOccupancy.childrenAge.push(childAge)
    }
  }

  return roomOccupancy
}

// buildOccupants constructs occupants array from the given
// encoded adults and children search params
export function buildOccupants(
  adults = '',
  children = queryNullChildren,
  maxChildAge?: number,
  maxInfantAge?: number,
): Array<App.Occupants> {
  const allAdults = adults.split(',').map(adult => Number(adult))
  const allChildren = children.split(',')
  const roomCount = Math.max(allAdults.length, allChildren.length)

  const occupants: Array<App.Occupants> = []

  for (let i = 0; i < roomCount; i++) {
    const childrenAgeList = allChildren[i]

    let children: Array<number> = []
    if (childrenAgeList && childrenAgeList !== queryNullChildren) {
      children = childrenAgeList.split(queryChildrenDelimiter)
        .map(age => parseInt(age))
        .filter(age => !isNaN(age))
    }

    occupants.push(getOccupancyFromSearchStrings(allAdults[i], children, maxChildAge, maxInfantAge))
  }

  return occupants
}

// parseOccupancy parses the given adult counts and children age lists and returns an array of
// rooms with adults and children counts
export function parseOccupancy(
  search: string,
  maxChildAge?: number,
  maxInfantAge?: number,
): Array<App.Occupants> {
  const { adults, children } = parseSearchString(search)
  if (Array.isArray(adults) || Array.isArray(children)) {
    // incorrectly formatted query string, we shouldn't get arrays
    return []
  }

  if (!adults && (!children || children === queryNullChildren)) {
    return []
  }

  return buildOccupants(adults, children, maxChildAge, maxInfantAge)
}

// serialiseOccupancy turns the given adults and children states from the
// URL query param into a format suitable for the API.
export function serialiseOccupancy(rooms: Array<App.Occupants>): Array<string> {
  return rooms.map(room => {
    let occupancy = typeof room.adults === 'number' ? room.adults.toString() : '2'

    if (room.childrenAge?.length) {
      occupancy += `-${room.childrenAge.join(',')}`
    }

    return occupancy
  })
}

// occupancyActive checks if the occupancy has been specified, based on a
// given URL query string
export function occupancyActive(searchStr: string) {
  const params = parseSearchString(searchStr)

  return !!params[queryKeyAdults] && !!params[queryKeyChildren]
}

const searchQueryParams = [
  queryKeyAdults,
  queryKeyChildren,
  queryKeyDestinationId,
  queryKeyDestinationName,
  queryKeyLandmarkId,
  queryKeyLandmarkName,
  queryKeyPropertyId,
  queryKeyPropertyName,
  queryKeyBounds,
  queryKeyCheckIn,
  queryKeyCheckOut,
  queryFlexibleWithDate,
  queryKeyCustomerRatingGte,
  queryKeyFlexibleNights,
  queryKeyFlexibleMonths,
  ...queryKeyFilters,
  queryKeySortBy,
  queryKeyBundledOfferId,
  queryKeyMapPinCount,
  queryKeySelectedOfferIds,
]

export function encodeChildrenOccupancy(children?: Array<number>) {
  if (!children || children.length === 0) {
    return queryNullChildren
  }
  const validChildren = children.filter(child => !isNaN(child))

  return validChildren.join(queryChildrenDelimiter)
}

export function convertDatesToString(dateFormat: string, checkinDate?: moment.Moment, checkoutDate?: moment.Moment): App.SearchDates {
  const hasDates = !!(checkinDate && checkoutDate)
  const checkIn = hasDates ? checkinDate.format(dateFormat) : undefined
  const checkOut = hasDates ? checkoutDate.format(dateFormat) : undefined
  return { checkIn, checkOut }
}

export function appendSearchParamValue(searchParams: URLSearchParams, paramName: string, paramValue: string | number) {
  const existingParam = (searchParams.get(paramName) ?? '').split(',').filter(Boolean)
  existingParam.push(paramValue.toString())
  searchParams.set(paramName, existingParam.join(','))
  return searchParams
}

interface EncodeSearchParams {
  urlSearch?: string,
  searchItem?: App.SearchItem,
  dates?: App.SearchDates,
  rooms?: Array<App.Occupants>,
  filters?: App.OfferListFilters,
  isFlexibleWithDate?: boolean,
  flexibleNights?: FLEXIBLE_DURATION_RANGE,
  flexibleMonths?: string,
  userSelectedFlexibleMonths?: boolean,
  sortBy?: OfferListSortOption,
  destinationId?: string;
  destinationName?: string;
  selectedOfferIds?: Array<string>,
}

// encodeSearchParams encodes the given search parameters into a URL query params
export function encodeSearchParams(params: EncodeSearchParams): URLSearchParams {
  const {
    urlSearch = '',
    searchItem,
    dates,
    rooms,
    filters,
    isFlexibleWithDate,
    flexibleNights,
    flexibleMonths,
    userSelectedFlexibleMonths = false,
    destinationId,
    destinationName,
    selectedOfferIds,
  } = params

  // clear all search params and a page param
  let searchParams = new URLSearchParams(urlSearch)
  const paramsToRemove = [...searchQueryParams, queryKeyPages]
  paramsToRemove.forEach(param => {
    searchParams.delete(param)
  })

  // add search params
  if (dates?.checkIn && dates.checkOut) {
    searchParams.set(queryKeyCheckIn, dates.checkIn)
    searchParams.set(queryKeyCheckOut, dates.checkOut)
  }

  if (flexibleNights && flexibleMonths && userSelectedFlexibleMonths !== undefined) {
    searchParams.set(queryKeyFlexibleNights, flexibleNights)
    searchParams.set(queryKeyFlexibleMonths, flexibleMonths)
    searchParams.set(queryKeyUserSelectedFlexibleMonths, userSelectedFlexibleMonths.toString())
  }

  if (destinationId && destinationName) {
    searchParams.set(queryKeyDestinationName, destinationName)
    searchParams.set(queryKeyDestinationId, destinationId)
  }

  if (searchItem?.searchType === 'destination') {
    searchParams.set(queryKeyDestinationName, searchItem.format.mainText)
    searchParams.set(queryKeyDestinationId, searchItem.value)
  }
  if (searchItem?.searchType === 'landmark') {
    searchParams.set(queryKeyLandmarkName, searchItem.format.mainText)
    searchParams.set(queryKeyLandmarkId, searchItem.value)
  }
  if (searchItem?.searchType === 'airport') {
    searchParams.set(queryKeyLandmarkName, searchItem.format.mainText)
    searchParams.set(queryKeyLandmarkId, searchItem.value)
  }
  if (searchItem?.searchType === 'property') {
    searchParams.set(queryKeyPropertyName, searchItem.format.mainText)
    searchParams.set(queryKeyPropertyId, searchItem.value)
  }
  if (searchItem?.searchType === 'experience') {
    searchParams.set(queryKeyExperienceName, searchItem.format.mainText)
    searchParams.set(queryKeyExperienceId, searchItem.value)
  }
  if (searchItem?.searchType === 'bounds') {
    searchParams.set(queryKeyBounds, searchItem.value)
  }
  if (selectedOfferIds && selectedOfferIds.length > 0) {
    searchParams.set(queryKeySelectedOfferIds, selectedOfferIds.join(','))
  }

  if (rooms && rooms.length > 0) {
    for (const room of rooms) {
      searchParams = appendSearchParamValue(searchParams, queryKeyAdults, room.adults)
      searchParams = appendSearchParamValue(searchParams, queryKeyChildren, encodeChildrenOccupancy(room.childrenAge))
    }
  }

  if (filters?.propertyTypes?.length) {
    searchParams.set(queryKeyPropertyTypes, filters.propertyTypes.join(','))
  }

  if (filters?.offerTypes?.length) {
    searchParams.set(queryKeyUrlOfferType, filters.offerTypes.join(','))
  }

  if (filters?.holidayTypesScoped?.length) {
    searchParams.set(queryKeyHolidayTypesScoped, filters?.holidayTypesScoped?.join(','))
  }

  if (filters?.holidayTypes?.length) {
    searchParams.set(queryKeyHolidayTypes, filters.holidayTypes.join(','))
  }

  if (filters?.customerRatingGte) {
    searchParams.set(queryKeyCustomerRatingGte, filters.customerRatingGte.toString())
  }

  if (filters?.amenities?.length) {
    searchParams.set(queryKeyAmenities, filters.amenities.join(','))
  }

  if (filters?.inclusions?.length) {
    searchParams.set(queryKeyInclusions, filters.inclusions.join(','))
  }

  if (isFlexibleWithDate === Boolean(true)) {
    searchParams.set(queryFlexibleWithDate, isFlexibleWithDate.toString())
  }

  if (filters?.sortBy) {
    searchParams.set(queryKeySortBy, filters.sortBy)
  }

  if (filters?.priceLte) {
    searchParams.set('priceLte', filters.priceLte.toString())
  }

  if (filters?.priceGte) {
    searchParams.set('priceGte', filters.priceGte.toString())
  }

  if (filters?.luxPlusFeatures?.length) {
    searchParams.set('luxPlusFeatures', filters.luxPlusFeatures.join(','))
  }

  if (filters?.locations?.length) {
    searchParams.set('locations', filters.locations.join(','))
  }

  return searchParams
}

// Removes the search parameters from the URL
export function removeSearchParamsFromURL(search: string) {
  return deleteSearchParams(search, ...searchQueryParams, queryKeyPages)
}

// Returns true if user didn't select children age
export function isRoomsInvalid(rooms: Array<App.Occupants>) {
  return rooms.some(room =>
    // sometimes childrenAge isn't defined on older occupants objects
    (room.childrenAge ?? []).some(child =>
      child === -1,
    ),
  )
}

export function isSingleRoomInvalid(guestCounts: App.Occupants) {
  return guestCounts.childrenAge?.some(child => child === -1)
}

export function buildSearchParamsKey(
  checkIn: string = '',
  checkOut: string = '',
  rooms: Array<App.Occupants> = [],
  bundledOfferId?: string,
) {
  return `${checkIn}-${checkOut}-${serialiseOccupancy(rooms).join(',')}${bundledOfferId ? `-${bundledOfferId}` : ''}`
}

export function buildSuggestedDatesParamsKey(
  flexibleMonths: string = '',
  flexibleNights: string = '',
  rooms: Array<App.Occupants> = [],
) {
  return `${flexibleMonths}-${flexibleNights}-${serialiseOccupancy(rooms).join(',')}`
}

export function buildDestinationSearchParamsKey(
  destinationId: string,
  checkIn: string = '',
  checkOut: string = '',
  rooms: Array<App.Occupants> = [],
) {
  return `${destinationId}|${checkIn}|${checkOut}|${serialiseOccupancy(rooms).join(',')}`
}

export function buildSearchParamsFromFilters(filters: App.OfferListFilters = {}, suggestedDates?: App.OfferSuggestedDates) {
  const params = new URLSearchParams()

  if (suggestedDates?.checkIn && suggestedDates?.checkOut) {
    params.set(queryKeyCheckIn, suggestedDates.checkIn)
    params.set(queryKeyCheckOut, suggestedDates.checkOut)
  } else {
    if (filters.checkIn) {
      params.set(queryKeyCheckIn, filters.checkIn)
    }

    if (filters.checkOut) {
      params.set(queryKeyCheckOut, filters.checkOut)
    }
  }

  if (filters.rooms) {
    params.set(queryKeyAdults, filters.rooms.map(room => room.adults).join(','))
    params.set(queryKeyChildren, filters.rooms.map(room => encodeChildrenOccupancy(room.childrenAge)).join(','))
  }

  return params
}

export function buildSuggestedDatesString(checkIn: string, checkOut: string): string {
  const formattedCheckIn = moment(checkIn)
  const formattedCheckOut = moment(checkOut)
  const nights = formattedCheckOut.diff(formattedCheckIn, 'days')
  return `${formattedCheckIn.format('DD MMM')} - ${formattedCheckOut.format('DD MMM')} (${pluralizeToString('night', nights)})`
}

export function buildSearchStringFromFilters(filters: App.OfferListFilters = {}) {
  return buildSearchParamsFromFilters(filters).toString()
}

export function isSearchEnabled() {
  return config.SEARCH_ENABLED
}

export function isMobileSearchEnabled() {
  return config.MOBILE_SEARCH_ENABLED
}

export function getSearchTypeFromFilters(filters: App.OfferListFilters): App.SearchItem['searchType'] {
  if (filters.landmarkId) { return 'landmark' }
  else if (filters.propertyId) { return 'property' }
  else if (filters.bounds) { return 'bounds' }
  else { return 'destination' }
}

export const getNextNMonths = (options?: {
  start?: moment.Moment, monthsRequired?: number
}): Array<MonthOption> => {
  const monthsRequired = options?.monthsRequired ?? 12
  const startDate = options?.start ?? moment()
  const monthOptions: Array<MonthOption> = []

  for (let i = 0; i < monthsRequired; i++) {
    const current = startDate.clone().add(i, 'months')
    const monthText = current.format('MMMM')
    const monthNumber = current.format('MM')
    const year = current.format('YYYY')

    monthOptions.push({
      monthText,
      monthNumber,
      year,
    })
  }

  return monthOptions
}

export function addFlexibleMonthsIfNoneSelected(flexibleNights?: FLEXIBLE_DURATION_RANGE, flexibleMonths?: string) {
  if (!flexibleNights || flexibleNights === DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION || flexibleMonths) {
    return flexibleMonths
  }
  const monthOptions = getNextNMonths({ start: moment().add(4, 'months'), monthsRequired: 2 }).map(month => `${month.year}-${month.monthNumber}`)
  return monthOptions.toString()
}

interface DateRange {
  start: Moment,
  end: Moment
}

export function convertFlexibleMonthsToDateRange(flexibleMonths: string): Array<DateRange> {
  return flexibleMonths.split(',').map((month) => {
    const numDays = moment(month, ISO_DATE_SHORT_FORMAT).daysInMonth()
    if (!numDays) {
      return
    }

    const start = moment(`${month}-01`)
    const end = moment(`${month}-${numDays}`)

    return { start, end }
  }).filter((value): value is DateRange => value !== undefined)
}

export function getMinimumDurationFromFlexibleNights(flexibleNights: FLEXIBLE_DURATION_RANGE): number {
  if (flexibleNights === DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION) return 4

  const [lowestNightsInRange] = flexibleNights.split('-')
  return lowestNightsInRange ? Number(lowestNightsInRange) : 1
}

export function getFlexibleNightRange(nightDuration?: FLEXIBLE_DURATION_RANGE): [number | undefined, number | undefined] {
  // any duration doesn't have numbers, even though they're specified in the enum
  if (nightDuration && nightDuration !== DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION) {
    const values = nightDuration.split('-')
    return [parseInt(values[0]), parseInt(values[1])]
  }

  return [undefined, undefined]
}

export function convertFlexibleDatesToTravelDates(flexibleNights: FLEXIBLE_DURATION_RANGE, flexibleMonths: string): {checkIn: string, checkOut: string} {
  const duration = getMinimumDurationFromFlexibleNights(flexibleNights)

  const dateRanges = convertFlexibleMonthsToDateRange(flexibleMonths)
  const now = moment()

  for (const dateRange of dateRanges) {
    const { end } = dateRange
    if (now.clone().add(duration, 'days').isAfter(end)) {
      continue
    }
    const checkIn = end.clone().subtract(duration, 'days').format(ISO_DATE_FORMAT)
    const checkOut = end.format(ISO_DATE_FORMAT)
    return {
      checkIn,
      checkOut,
    }
  }

  const checkIn = now.add(1, 'day').format(ISO_DATE_FORMAT)
  const checkOut = now.add(duration, 'days').format(ISO_DATE_FORMAT)

  return {
    checkIn,
    checkOut,
  }
}

export const HolidayTypeToIconMap = {
  All: <LineLayersAltIcon />,
  'Arts & Culture': <LinePaletteIcon />,
  'Adults-only': <Line18PlusIcon />,
  'All-inclusive': <LineCheckCircleIcon />,
  Boutique: <LineExhibitionsIcon />,
  'Bucket List': <LineListUlAltIcon />,
  'City Break': <LineBuildingIcon />,
  Cruises: <LineShipIcon />,
  Dive: <LineSwimmerIcon />,
  Experiences: <LineHotAirBalloonIcon />,
  'Family Friendly': <LineKidIcon />,
  'Food & Wine': <LineRestaurantIcon />,
  Glamping: <LineMountainsSunIcon />,
  Golf: <LineGolfIcon />,
  Group: <LineUsersAltIcon />,
  'Hill Stations': <LineSightseeingIcon />,
  'Homes & Villas': <LineHomeAltIcon />,
  Honeymoon: <LineHeartIcon />,
  Islands: <LineWatersportsIcon />,
  'Last Minute Escapes': <LineClockDollarIcon />,
  'LE Fave': <LineStarIcon />,
  [LE_FLASH_HOTEL_PRODUCT_LABEL]: <LineStarCardIcon />,
  [FLASH_HOTEL_PRODUCT_LABEL]: <LineStarCardIcon />,
  'Outdoor & Adventure': <LineMountainsSunIcon />,
  'Outdoor Adventure': <LineMountainsSunIcon />,
  Relaxed: <LineBedIcon />,
  Romantic: <LineHeartCardIcon />,
  'School Holiday': <LineSchoolBackpackIcon />,
  'Short Stay': <LineClockIcon />,
  'Ski & Snow': <LineSnowFlakeIcon />,
  'Spa Break': <LineWellnessIcon />,
  'Sun & Beach': <LineSunsetIcon />,
  'Sustainable Travel': <LineTreesIcon />,
  Tours: <LineCompassIcon />,
  'Ultra Lux': <LineUltraLuxDiamondIcon />,
  'Unique Stays': <LineStarIcon />,
  'Wellness & Relaxation': <LineWellnessIcon />,
  Trending: <LineFireIcon />,
  'Ending Soon': <LineHourglassIcon />,
  'Latest/New Offer': <LinePricetagAltIcon />,
  'Best Value': <LineMoneyStackIcon />,
  'Private Pool': <LineSwimmerIcon />,
  'Ocean Views': <LineWaterIcon />,
  Default: <LineCheckIcon />,
}

/**
 * Holiday type -> icon map but the key is normalised as lower case.
 * Makes it easier get the value out.
 */
export const getHolidayTypeIcon = switchFunc(arrayToObject(
  Object.entries(HolidayTypeToIconMap),
  entry => entry[0].toLowerCase(),
  entry => entry[1],
), <LineCheckIcon />)

type GetTopNFilterItem = [string, number, number];

export function getTopNFilterOptions(
  filters: Record<string, number> | undefined,
  orders: Record<string, number> = {},
  topN: number,
  exclude: Array<string> = [],
): Array<GetTopNFilterItem> | undefined {
  if (!filters) {
    return undefined
  }

  const options: Array<GetTopNFilterItem> = Object.entries(filters)
    .filter(([name]) => (!exclude.includes(name.toLowerCase())))
    .map(([name, count]) => ([name, count, orders[name] ?? 0]))

  return take(sortBy(options, (option) => option[2], 'desc'), topN)
}

export function getQueryParams(search: string, optionValue: string, optionText: string, filters?: App.OfferListFilters): string {
  const searchItem: App.SearchItem = {
    searchType: 'destination',
    value: optionValue,
    format: {
      mainText: optionText,
    },
  }
  const queryParams = encodeSearchParams({
    urlSearch: search,
    searchItem,
    rooms: [config.search.defaultOccupants],
    filters,
    isFlexibleWithDate: true,
  }).toString()

  return queryParams
}

// Temporary measure to fix whitelabels. The long-term fix is to update svc-search to consider brand
export function updateLeBrandSpecificFilters(filters: App.OfferListAvailableFilters | App.OfferListFilterOrders | undefined) {
  if (config.BRAND !== 'luxuryescapes' && filters !== undefined) {
    const updatedHolidayTypes = { ...filters.holidayTypes }

    for (const filter of leSpecificHolidayTypes) {
      const holidayTypeCount = updatedHolidayTypes[filter.leName]
      if (holidayTypeCount !== undefined) {
        delete updatedHolidayTypes[filter.leName]
        updatedHolidayTypes[filter.whiteLabelName] = holidayTypeCount
      }
    }

    return {
      ...filters,
      holidayTypes: updatedHolidayTypes,
    }
  }

  return filters // No changes needed for the specified brand.
}

// Unfortunately we now need to reverse the above function to send the correct holiday type to the API
export function reverseUpdateToBrandSpecificFilters(filters: Array<string> | undefined) {
  if (config.BRAND !== 'luxuryescapes' && filters !== undefined) {
    const updatedHolidayTypes = filters

    for (const filter of leSpecificHolidayTypes) {
      if (updatedHolidayTypes.includes(filter.whiteLabelName)) {
        updatedHolidayTypes[updatedHolidayTypes.indexOf(filter.whiteLabelName)] = filter.leName
      }
    }

    return updatedHolidayTypes
  }

  return filters // No changes needed for the specified brand.
}

export function isOrganicSearch(referrer: string): boolean {
  return searchEngineReferrers.includes(referrer) || referrer.startsWith('https://www.baidu.com/')
}

export function encodeOfferIds(offers: Array<App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary | App.OfferListMetaData>) {
  return offers
    .map(offer => {
      const offerId = getOfferId(offer)
      return offer.type === OFFER_TYPE_BED_BANK ? `bedbank:${offerId}` : `le:${offerId}`
    })
    .join(',')
}

/**
 * Constructs a search query params based on the props found in the offer list filter object
 * Note: Will ignore offer types (assumes sending to the correct search page instead)
 *
 * @param filters An offer list filter to generate the query from
 */
export function getSearchQueryFromFilter(filters: App.OfferListFilters) {
  return encodeSearchParams({
    rooms: filters.rooms ?? [config.search.defaultOccupants],
    dates: {
      checkIn: filters.checkIn,
      checkOut: filters.checkOut,
    },
    flexibleMonths: filters.flexibleMonths,
    ...(filters.flexibleNights && { flexibleNights: filters.flexibleNights as FLEXIBLE_DURATION_RANGE }),
    destinationId: filters.destinationId ?? ANYWHERE_PLACE_ID,
    destinationName: filters.destinationName ?? ANYWHERE_NAME,
    filters: {
      ...filters,
      offerTypes: undefined,
    },
  })
}

export function getCenterCoordinates([lat1, lng1, lat2, lng2]: [number, number, number, number]) {
  const centerLat = (lat1 + lat2) / 2
  const centerLng = (lng1 + lng2) / 2
  return [centerLat, centerLng]
}

export function getSearchVerticalFromPathname(pathname: string): SEARCH_VERTICALS | undefined {
  if (pathname.endsWith('/search')) {
    return SEARCH_VERTICALS.HOTELS
  }

  if (pathname.includes('/search/tours')) {
    return SEARCH_VERTICALS.TOURS
  }

  if (pathname.includes('/search/cruises')) {
    return SEARCH_VERTICALS.CRUISES
  }

  if (pathname.includes('/search/experiences')) {
    return SEARCH_VERTICALS.EXPERIENCES
  }

  if (pathname.includes('/search/car-hire')) {
    return SEARCH_VERTICALS.CARHIRE
  }
}

export function mergeOccupants(rooms: Array<App.Occupants>): App.Occupants {
  return rooms.reduce((acc, room) => {
    acc.adults += room.adults
    acc.children! += room.children || 0
    acc.infants! += room.infants || 0
    acc.childrenAge = acc.childrenAge!.concat(room.childrenAge || [])
    return acc
  }, {
    adults: 0,
    children: 0,
    infants: 0,
    childrenAge: [],
  })
}

export function createRecentPropertySearchObject(
  searchItem: App.SearchItem,
  matchingOffer: App.HotelOffer | App.HotelOfferSummary | App.BedbankOffer | App.BedbankOfferSummary,
): App.SearchProperty {
  return {
    searchType: 'property',
    offerType: matchingOffer.type,
    offerId: isLEHotel(matchingOffer) ? matchingOffer.id : undefined,
    value: searchItem.value,
    format: {
      ...searchItem.format,
      imageId: matchingOffer.image.id,
    },
    countryCode: isLEHotel(matchingOffer) ? matchingOffer.property.geoData.countryCode : undefined,
    imageId: matchingOffer.image.id,
  }
}

export function getDestinationImage(destinationId: string): string | undefined {
  const destinationImageMap = new Map([
    ['le_64930b7151b6bc3e289f37d5da62ac9f', 'vg4uvt2y5dgkivujuba6'], // Bali
    ['le_d3d9446802a44259755d38e6d163e820', '6swob1q88xi45bmcm4lgm'], // Australia
    ['le_7eabe3a1649ffa2b3ff8c02ebfd5659f', 'mky1rem3l8kdhe9h78b'], // California
    ['le_d7bce4d8dbac13945471c343c4973ba3', '54h7nft1vhh41svqcid'], // Carribean
    ['le_3416a75f4cea9109507cacd8e2f2aefc', 'x382u2epn5ub73h8l3'], // Cook Islands
    ['le_43baa6762fa81bb43b39c62553b2970d', 'eoo7x60icwbgndyc1wcge'], // Dubai
    ['le_12767faf1bf8cafc7594430586c558c2', 'iuuawzh6wih8s6hu6ss'], // Europe
    ['le_72b32a1f754ba1c09b3695e0cb6cde7f', 'h1t0c6c9brq83bwhazx'], // Fiji
    ['le_eb163727917cbba1eea208541a643e74', 'm4q0xykf28g85c3uv1r'], // Florida
    ['le_093f65e080a295f8076b1c5722a46aa2', 'n32e56two8igfwfrzvq'], // France
    ['le_ba1ceaceb2db0e0462679cfc4a6ae170', 'vaty4co2wg1ca5onn63'], // Goa
    ['le_bac732346917b0a1231354ef55faf00a', 'krvovndggw4zalo1kgo8'], // Gold Coast
    ['le_735b90b4568125ed6c3f678819b6e058', 'la8fpc5ehwxlh9cyd9ne'], // Greece
    ['le_979d472a84804b9f647bc185a877a8b5', 'q26a5y2h5aijx52y8nfm'], // Hawaii
    ['le_f033ab37c30201f73f142449d037028d', '2dkiw9doma2e4oi7wauyh'], // India
    ['le_93db85ed909c13838ff95ccfa94cebd9', 'wbk1yjnf56awezs1lu'], // Italy
    ['le_8f85517967795eeef66c225f7883bdcb', 'lids8h5rs9zj2xvkxr'], // Thailand
    ['le_15fa44474c92da27cf2125373356db4c', 'asgz0fkt6cf311m98rq'], // Melbourne
    ['le_2723d092b63885e0d7c260cc007e8b9d', 'dapbr82l65bcj5iy6v8'], // Maldives
    ['le_a597e50502f5ff68e3e25b9114205d4a', '7pmj8f3m1cz7ip3920k'], // Vietnam
    ['le_3d3621cee6f49e93a34f3f0f654ab41a', '68cb38dgpprmupvorvzg'], // Queensland
    ['le_5781a2637b476d781eb3134581b32044', '0h1arx6nnm0gslh04pba'], // Victoria
    ['le_8337a7365ec44f3d65434a5ea4d73d17', 'jmtsq75cglkjzwfhkn2g'], // New South Wales
    ['le_bd4c9ab730f5513206b999ec0d90d1fb', 'b4u5c9v9eplflpfjohz8'], // Singapore
    ['le_eb160de1de89d9058fcb0b968dbbbd68', 'rasl0402sq9ft6vloi7b'], // Mexico
    ['le_cfecdb276f634854f3ef915e2e980c31', 'bh52x5crz46nt54uxsl'], // United Kingdom
    ['le_757b505cfd34c64c85ca5b5690ee5293', '2agstgb25xcdai5sl2y'], // United States of America
    ['le_9fc3d7152ba9336a670e36d0ed79bc43', '4ezeb1xkmkcuawwm0cf8'], // New Zealand
    ['le_2a79ea27c279e471f4d180b08d62b00a', 'kgp44kj57lap71v2djyt'], // Samoa
    ['le_7e7757b1e12abcb736ab9a754ffb617a', '7mxbm2s01q38r2900zj'], // Spain
    ['le_2b44928ae11fb9384c4cf38708677c48', 'ztyqafpx7lywouc2093'], // Mauritius
    ['le_415e1af7ea95f89f4e375162b21ae38c', 'hotel-destination-nadi'], // Nadi
    ['le_bb5ebfe18035bce236fcddd3717ecbf1', 'hotel-destination-western-division'], // Western Division
    ['le_c81cb23d49ff98e91f8fcf970322d981', 'hotel-destination-coral-coast'], // Coral Coast
    ['le_7499b49343dc7f06c446c9fadbce53b0', 'hotel-destination-denarau-island'], // Denarau Island
    ['le_182438e09eb1ae0567c5773c0a566da2', 'hotel-destination-castaway-island'], // Castaway Island
    ['le_a55472983efa2e239b315de91576d1d3', 'hotel-destination-nusa-dua'], // Nusa Dua
    ['le_a9c53c0f1fe23357e3ba6a078ddb0990', 'hotel-destination-uluwatu'], // Uluwatu
    ['le_7f7ed8ecfca9e17696ff654508efd86a', 'hotel-destination-ubud'], // Ubud
    ['le_425f0cea87a40c5a8860e3db7c918c4e', 'hotel-destination-canggu'], // Canggu
    ['le_790d9f1f52e10ef90ede8efcf7f3c078', 'hotel-destination-seminyak'], // Seminyak
    ['le_678c61ac5b4f91ca86e112fc50e63766', 'hotel-destination-sydney'], // Sydney
    ['le_7af6d6117d13124fd3e00f5cb5f04400', 'hotel-destination-koh-samui'], // Koh Samui
    ['le_b536d75d9b756802546437b33b09b5e8', 'hotel-destination-krabi'], // Krabi
    ['le_f3ad1c493ba61ad63bb43d6947f42d95', 'hotel-destination-khao-lak'], // Khao Lak
    ['le_d47bb339212ddfab0fadd99d492a5fc3', 'hotel-destination-phuket'], // Phuket
    ['le_ff7aa1f9b4062bfee0f99a0453506f1b', 'hotel-destination-bangkok'], // Bangkok
    ['le_cedebb6e872f539bef8c3f919874e9d7', '7nm0fqz96frmfd8v5hz'], // Turkey
    ['le_1608659ea7ec15440fa87763b3f5d700', 'robfxk0yk1ysj4dx03h'], // Tokyo
    ['le_39c4c0a45681f21cead1b7004d1a67ce', 'vyh03sro6upo0l4qjbyp'], // Seattle US
    ['le_bd80d53a4b5d3aef9d8a1fabe2feecfc', 'vgqpaec8fo6xgbzr0z4g'], // San Francisco
    ['le_832d851fd4d8851affa0b77077f5b4d6', 'e19xgagju1exj0903ye'], // Rome Italy
    ['le_6afe49dcb36cc10aeab41ef4ed8ac72d', 'h8scvflh1bsncdws3cpi'], // Punta Cana
    ['le_8d5e957f297893487bd98fa830fa6413', 'mikdytjpmo9cwbcwn4c'], // Portugal
    ['le_cb3f7087c987ceff5b306d4ff89f5db6', '3r1eu1oz4snlmhci35gg'], // Paris
    ['le_1ada7faf229425b786d87f7d9d5d43f2', 'c26khh6d6azl0zedu6n9'], // New York
    ['le_548026b063092cc5c30e6ea04ccef75c', '8z3kse6htms56n9ge6ks'], // Miami
    ['le_66ff0e7c30438d414ff0a941b759f114', 'c3bhy2alpf5ptrkwus'], // London
    ['le_324d1d5faac07d0c5ae13c2213b84ea9', 'kok2z6rcuefwx8rd7mxp'], // Las Vegas
    ['le_68d30a9594728bc39aa24be94b319d21', 'fzyzfwfxpj9wo54xqeb1'], // Ireland
    ['le_ea5d2f1c4608232e07d3aa3d998e5135', 'q5lhedws1yms7nrghv3'], // Germany
  ])

  return destinationImageMap?.get(destinationId) ?? ''
}

export function isDestinationSearchItem(searchItem: App.SearchItem): searchItem is App.SearchDestination {
  return (
    searchItem.searchType === 'destination'
  )
}

export function getDistanceEqFilter(searchType: App.SearchItem['searchType']): number | undefined {
  return searchType === 'destination' ? 0 : undefined
}

export function getDistanceLteFilter(searchType: App.SearchItem['searchType']): number | undefined {
  return searchType === 'landmark' || searchType === 'airport' ? 3000 : undefined
}

export function getsScheduleVisibilitiesFilter(
  isSpoofed: boolean,
): Array<'listVisible' | 'spoofVisible'> {
  return isSpoofed ? ['listVisible', 'spoofVisible'] : ['listVisible']
}

export function showPhoneBookingSection(offerId?: string): boolean {
  // only shown for Sea World Resort LPC for now
  return !!offerId && offerId === '0062y000009CpurAAC'
}

export function isSearchStreamingSupported(filters?: App.OfferListFilters, options?: {allowSortByPrice?: boolean}): boolean {
  if (!filters) return false

  if (!filters.isStream) {
    return false
  }

  if (options?.allowSortByPrice && (filters.sortBy === 'price.asc' || filters.sortBy === 'price.desc')) {
    return true
  }

  if (!STREAMING_SUPPORTED_SORT_BY.includes(filters.sortBy)) {
    return false
  }

  return true
}

export function formatDateRangesForAlternativeDates(checkIn: string, checkOut: string): string {
  const start = moment(checkIn)
  const end = moment(checkOut)
  const formatDates = (start: moment.Moment, end: moment.Moment) => {
    const startDay = start.format('D')
    const startMonth = start.format('MMM')
    const endDay = end.format('D')
    const endMonth = end.format('MMM')

    if (startMonth === endMonth) {
      return `${startDay} - ${endDay} ${startMonth}`
    }

    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`
  }

  return formatDates(start, end)
}
