const placesTree = [
  {
    name: 'Americas',
    canonicalName: 'Americas',
    children: [
      {
        name: 'North America',
        canonicalName: 'North America',
        children: [
          {
            name: 'USA',
            canonicalName: 'United States of America',
            children: [
              { name: 'California', canonicalName: 'California, USA', children: [] },
              { name: 'Alaska', canonicalName: 'Alaska, USA', children: [] },
              { name: 'Hawaii', canonicalName: 'Hawaii, USA', children: [] },
              { name: 'New York', canonicalName: 'New York, USA', children: [] },
            ],
          },
          { name: 'Canada', canonicalName: 'Canada', children: [] },
          { name: 'Mexico', canonicalName: 'Mexico', children: [] },
        ],
      },
      { name: 'Central America', canonicalName: 'Central America', children: [] },
      {
        name: 'South America',
        canonicalName: 'South America',
        children: [
          { name: 'Brazil', canonicalName: 'Brazil', children: [] },
        ],
      },
      { name: 'Caribbean', canonicalName: 'Caribbean', children: [] },
    ],
  },
  {
    name: 'Asia',
    canonicalName: 'Asia',
    children: [
      { name: 'Hong Kong', canonicalName: 'Hong Kong', children: [] },
      { name: 'Sri Lanka', canonicalName: 'Sri Lanka', children: [] },
      {
        name: 'Indonesia',
        canonicalName: 'Indonesia',
        children: [
          { name: 'Lombok', canonicalName: 'Lombok, Indonesia', children: [] },
          { name: 'Bali', canonicalName: 'Bali, Indonesia', children: [] },
        ],
      },
      { name: 'Bhutan', canonicalName: 'Bhutan', children: [] },
      { name: 'Nepal', canonicalName: 'Nepal', children: [] },
      { name: 'Myanmar', canonicalName: 'Myanmar', children: [] },
      { name: 'Korea', canonicalName: 'South Korea', children: [] },
      { name: 'Phillipines', canonicalName: 'Philippines', children: [] },
      { name: 'Taiwan', canonicalName: 'Taiwan', children: [] },
      {
        name: 'Thailand',
        canonicalName: 'Thailand',
        children: [
          { name: 'Bangkok', canonicalName: 'Bangkok, Thailand', children: [] },
          { name: 'Phuket', canonicalName: 'Phuket, Thailand', children: [] },
          { name: 'Hua Hin', canonicalName: 'Hua Hin, Hua Hin District, Prachuap Khiri Khan, Thailand', children: [] },
          { name: 'Khao Lak', canonicalName: 'Khao Lak, Thailand', children: [] },
          { name: 'Koh Samui', canonicalName: 'Koh Samui, Ko Samui District, Surat Thani, Thailand', children: [] },
          { name: 'Krabi', canonicalName: 'Krabi, Thailand', children: [] },
        ],
      },
      { name: 'Philippines', canonicalName: 'Philippines', children: [] },
      { name: 'Japan', canonicalName: 'Japan', children: [] },
      { name: 'China', canonicalName: 'China', children: [] },
      { name: 'Singapore', canonicalName: 'Singapore', children: [] },
      { name: 'Vietnam', canonicalName: 'Vietnam', children: [] },
      { name: 'Fiji', canonicalName: 'Fiji', children: [] },
      { name: 'Maldives', canonicalName: 'Maldives', children: [] },
      { name: 'Malaysia', canonicalName: 'Malaysia', children: [] },
      {
        name: 'India',
        canonicalName: 'India',
        children: [
          { name: 'Agra', canonicalName: 'Agra, Uttar Pradesh, India', children: [] },
          { name: 'Delhi', canonicalName: 'Delhi, India', children: [] },
          { name: 'Rajasthan', canonicalName: 'Rajasthan, India', children: [] },
          { name: 'Goa', canonicalName: 'Goa, India', children: [] },
        ],
      },
      { name: 'Cambodia', canonicalName: 'Cambodia', children: [] },
      { name: 'Laos', canonicalName: 'Laos', children: [] },
    ],
  },
  {
    name: 'Middle East',
    canonicalName: 'Middle East',
    children: [
      {
        name: 'UAE',
        canonicalName: 'UAE',
        children: [
          { name: 'Abu Dhabi', canonicalName: 'Abu Dhabi, United Arab Emirates', children: [] },
          { name: 'Dubai', canonicalName: 'Dubai, United Arab Emirates', children: [] },
        ],
      },
      { name: 'Qatar', canonicalName: 'Qatar', children: [] },
      { name: 'Oman', canonicalName: 'Oman', children: [] },
    ],
  },
  {
    name: 'South Pacific',
    canonicalName: 'South Pacific',
    children: [
      { name: 'Vanuatu', canonicalName: 'Vanuata', children: [] },
      { name: 'Cook Islands', canonicalName: 'Cook Islands', children: [] },
      { name: 'Samoa', canonicalName: 'Samoa', children: [] },
      { name: 'New Caledonia', canonicalName: 'New Caledonia', children: [] },
      { name: 'Tahiti', canonicalName: 'Tahiti', children: [] },
    ],
  },
  {
    name: 'Europe',
    canonicalName: 'Europe',
    children: [
      { name: 'Spain', canonicalName: 'Spain', children: [] },
      { name: 'Switzerland', canonicalName: 'Swizerland', children: [] },
      { name: 'Turkey', canonicalName: 'Turkey', children: [] },
      { name: 'Slovenia', canonicalName: 'Slovenia', children: [] },
      { name: 'Scandinavia', canonicalName: 'Scandinavia', children: [] },
      { name: 'Croatia', canonicalName: 'Croatia', children: [] },
      { name: 'Greece', canonicalName: 'Greece', children: [] },
      { name: 'Netherlands', canonicalName: 'Netherlands', children: [] },
      { name: 'Czech Republic', canonicalName: 'Czeck Republic', children: [] },
      { name: 'Russia', canonicalName: 'Russia', children: [] },
      { name: 'Montenegro', canonicalName: 'Montenegro', children: [] },
      { name: 'France', canonicalName: 'France', children: [] },
      { name: 'Romania', canonicalName: 'Romania', children: [] },
      { name: 'Slovakia', canonicalName: 'Slovakia', children: [] },
      { name: 'Cyprus', canonicalName: 'Cyprus', children: [] },
      { name: 'Italy', canonicalName: 'Italy', children: [] },
      { name: 'Malta', canonicalName: 'Malta', children: [] },
      {
        name: 'United Kingdom',
        canonicalName: 'United Kingdom',
        children: [
          { name: 'Scotland', canonicalName: 'Scotland, United Kingdom', children: [] },
        ],
      },
      { name: 'Ireland', canonicalName: 'Ireland', children: [] },
      { name: 'Poland', canonicalName: 'Poland', children: [] },
      { name: 'Iceland', canonicalName: 'Iceland', children: [] },
      { name: 'Portugal', canonicalName: 'Portugal', children: [] },
      { name: 'Hungary', canonicalName: 'Hungary', children: [] },
      { name: 'Austria', canonicalName: 'Austria', children: [] },
      { name: 'Germany', canonicalName: 'Germany', children: [] },
      { name: 'Belgium', canonicalName: 'Belgium', children: [] },
      { name: 'Georgia', canonicalName: 'Georgia', children: [] },
      { name: 'Armenia', canonicalName: 'Armenia', children: [] },
    ],
  },
  {
    name: 'Africa',
    canonicalName: 'Africa',
    children: [
      { name: 'South Africa', canonicalName: 'South Africa', children: [] },
      { name: 'Tunisia', canonicalName: 'Tunisia', children: [] },
      { name: 'Ethiopia', canonicalName: 'Ethiopia', children: [] },
      { name: 'Mauritius', canonicalName: 'Mauritius', children: [] },
      { name: 'Egypt', canonicalName: 'Egypt', children: [] },
      { name: 'Morocco', canonicalName: 'Morocco', children: [] },
      { name: 'Seychelles', canonicalName: 'Seychelles', children: [] },
    ],
  },
  {
    name: 'Australia',
    canonicalName: 'Australia',
    children: [
      { name: 'Northern Territory', canonicalName: 'Northern Territory, Australia', children: [] },
      {
        name: 'Queensland',
        canonicalName: 'Queensland, Australia',
        children: [
          { name: 'Gold Coast', canonicalName: 'Gold Coast, QLD, Australia', children: [] },
        ],
      },
      { name: 'New South Wales', canonicalName: 'New South Wales, Australia', children: [] },
      { name: 'Victoria', canonicalName: 'Victoria, Australia', children: [] },
      { name: 'Western Australia', canonicalName: 'Western Australia, Australia', children: [] },
      { name: 'ACT', canonicalName: 'ACT, Australia', children: [] },
      { name: 'South Australia', canonicalName: 'South Australia, Australia', children: [] },
      { name: 'Tasmania', canonicalName: 'Tasmania, Australia', children: [] },
    ],
  },
  { name: 'New Zealand', canonicalName: 'New Zealand', children: [] },
]

export default placesTree
