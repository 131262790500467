import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import ResponsiveImage from 'components/Common/ResponsiveImage'
import HeadData from 'components/Common/HeadData'
import config from 'constants/config'
import TextLink from 'components/Luxkit/TextLink'
import Heading from 'components/Luxkit/Typography/Heading'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import BodyText from 'components/Luxkit/Typography/BodyText'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const Root = styled.div`
  position: relative;
  overflow: hidden;
  color: ${props => props.theme.palette.neutral.default.eight};
  text-align: center;

  picture,
  img {
    height: calc(100vh - ${() => rem(config.header.height.lg)});
    object-fit: cover;
  }
`

const LargeHeading = styled(Heading)`
  font-size: 140px !important;
  line-height: 140px !important;
`

const Wrapper = styled(VerticalSpacer)`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

interface Props {
  invalidRegion?: boolean
}

function Error404Page({ invalidRegion = false }:Props) {
  const onBack = (event) => {
    event.preventDefault()
    window.history.back()
  }
  return (
    <Root className="Error404Page T-Error404Page">
      <HeadData
        title="Page Not Found"
        description="404 - page not found"
      />
      <ResponsiveImage
        aria-hidden="true"
        alt="404, This page could not be found"
        brightness={-60}
        mobileAspectRatio="8:9" tabletAspectRatio="4:3" desktopAspectRatio="4:3" largeDesktopAspectRatio="16:9"
        id="404-background"
      />
      <Wrapper data-testid="404-error-page" gap={16}>
        { invalidRegion &&
          <Subtitle variant="subtitle1" colour="neutral-eight">This page is not available in your region</Subtitle>
        }
        { !invalidRegion &&
          <>
            <LargeHeading variant="heading1" colour="neutral-eight">404</LargeHeading>
            <Subtitle variant="subtitle1" colour="neutral-eight">Page not found</Subtitle>
          </>
        }
        <BodyText variant="large">
          Please go <TextLink variant="ghost" onClick={onBack}>Back</TextLink> or head <TextLink variant="ghost" to="/" >Home</TextLink>
        </BodyText>
      </Wrapper>
    </Root>
  )
}

export default React.memo(Error404Page)
