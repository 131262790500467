import { isEmpty, sortBy, truthyMap } from 'lib/array/arrayUtils'
import { serialiseOccupancy } from 'lib/search/searchUtils'

function valueCompare(a: unknown, b: unknown) {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b)
  } else if (typeof a === 'number' && typeof b === 'number') {
    return a - b
  }
  return 0
}

/**
 * Creates a unique key based on the object passed ins keys and values
 * @param object The object to create a key from
 * @returns A key representing the key/values of the object
 */
export default function getObjectKey(object: object) {
  const filterEntries = Object.entries(object)

  // Order is important for the key we need it to generate the same key for the same set of filters
  // even if those filters are defined on the object in a different order
  const sortedEntries = sortBy(filterEntries, entry => entry[0], 'desc')

  const keys = truthyMap(sortedEntries, ([filterType, filterValue]) => {
    // truthy check fails on numbers and we also want actual boolean falses to
    // go through as a 'false'. That means the only things we don't want are
    // undefined or null. Or NaN, please don't send a NaN
    if (!isEmpty(filterValue)) {
      switch (filterType) {
        case 'rooms':
          return `${filterType}=${serialiseOccupancy(filterValue).join(';')}`
        default:
          if (Array.isArray(filterValue) && filterValue.length > 0) {
            return (`${filterType}=` + filterValue.toSorted(valueCompare).join(','))
          }
          if (typeof filterValue !== 'object') {
            return `${filterType}=${filterValue as string}`
          }

          return undefined
      }
    }
  })

  return keys.join(';')
}
