function getValidCreditObjectKeys(creditItem: BusinessTraveller.ToBeCalculatedBusinessCreditItem) {
  return Object.keys(creditItem)
    .filter((key) => creditItem[key] !== null && creditItem[key] !== undefined)
    .sort()
}

export function generateOfferCreditKey(
  toBeCalculatedBusinessCreditItem: BusinessTraveller.ToBeCalculatedBusinessCreditItem,
  offer: App.AnyOffer,
): string {
  const creditItemKey = JSON.stringify(
    toBeCalculatedBusinessCreditItem,
    getValidCreditObjectKeys(toBeCalculatedBusinessCreditItem),
  )

  return `${offer.id}__${creditItemKey}`
}

export function generateFlightCreditKey(
  toBeCalculatedBusinessCreditItem: BusinessTraveller.ToBeCalculatedBusinessCreditItem,
): string {
  const creditItemKey = JSON.stringify(
    toBeCalculatedBusinessCreditItem,
    getValidCreditObjectKeys(toBeCalculatedBusinessCreditItem),
  )

  return creditItemKey
}
