import OfferLabels from 'components/Common/Labels/OfferLabels'
import BodyText from 'components/Luxkit/Typography/BodyText'
import SearchTileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileCancellationPolicy'
import Group from 'components/utils/Group'
import GeoContext from 'contexts/geoContext'
import getUrgencyLabels from 'lib/offer/getUrgencyLabels'
import React, { useCallback, useContext, useMemo } from 'react'
import { connect } from 'react-redux'
import SearchRentalAmenities from './SearchVillaAmenities'
import { take } from 'lib/array/arrayUtils'
import { getCapacityTitle } from 'lib/homesAndVillas/getCapacityTitle'
import TextLink from 'components/Luxkit/TextLink'
import ModalContext from 'contexts/ModalContext'
import VillasPageAmenitiesModalContent from 'components/OfferPage/Villas/VillasPageAmenitiesModalContent'
import Modal from 'components/Luxkit/Modal/Modal'
import SearchTileStaticCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileStaticCancellationPolicy'
import {
  getOrderedAmenitiesFromPackage,
} from 'lib/homesAndVillas/getAmenityPriority'
import { getBestVillasDiscounts } from 'components/OfferPage/Villas/utils/calculateDiscount'
import { VILLAS_DISCOUNT_LABEL_COUNT } from 'constants/villas'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import CSSBreakpoint from '../../../../utils/CSSBreakpoint'

interface MappedStateProps {
  windowSearch: string;
}

interface Props {
  location: string;
  cancellationPolicyType: string;
  offer: App.VillaOffer;
  filters: App.OfferListFilters | undefined;
  offerUrl: string;
}

function SearchVillaPropertyDetails(props: Props & MappedStateProps) {
  const {
    cancellationPolicyType,
    offer,
    filters,
    offerUrl,
  } = props

  const { currentRegionCode } = useContext(GeoContext)

  const urgencyLabels = useMemo(() => getUrgencyLabels(offer, currentRegionCode), [offer, currentRegionCode])

  const { amenities, orderedAmenities } = useMemo(() => getOrderedAmenitiesFromPackage(offer.packages[0]), [offer])

  const showModal = useContext(ModalContext)
  const openAmenitiesModal = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    showModal(
      <Modal title="Amenities" primaryActionTo={offerUrl} primaryActionText="View offer">
        <VillasPageAmenitiesModalContent amenities={amenities} />
      </Modal>,
    )
  }, [showModal, amenities, offerUrl])

  const capacityTitle = useMemo(() => getCapacityTitle(offer), [offer])

  const bestVillasDiscounts = getBestVillasDiscounts(offer.discounts, VILLAS_DISCOUNT_LABEL_COUNT)

  return (
    <Group direction="vertical" gap={8}>
      <Group direction="vertical" fullWidth gap={4}>
        <OfferLabels offer={offer} urgencyLabels={urgencyLabels}/>
        {bestVillasDiscounts.length > 0 && <ProductTypeLabel productType="limited_time_special" kind="plain" />}
        <Group direction="horizontal" verticalAlign="center" gap={8}>
          {capacityTitle.split('.').map((text, index, array) => (
            <>
              <BodyText key={`text-${index}`} variant="medium">{text}</BodyText>
              {index < array.length - 1 && <div>·</div>}
            </>
          ))}
        </Group>
      </Group>
      {!filters?.checkIn && <SearchTileStaticCancellationPolicy cancellationPolicies={offer.packages[0]?.roomRate.cancellationPolicies} offerType={offer.type}/> }
      <SearchTileCancellationPolicy
        cancellationPolicyType={cancellationPolicyType}
        checkInDate={filters?.checkIn}
        timezoneOffset={offer?.property?.timezoneOffset}
        offerType={offer.type}
      />
      {amenities.length > 0 &&
        <Group direction="vertical" gap={16}>
          <CSSBreakpoint max="mobile">
            <SearchRentalAmenities amenities={take(orderedAmenities, 4)}/>
          </CSSBreakpoint>
          <CSSBreakpoint min="tablet">
            <SearchRentalAmenities amenities={take(orderedAmenities, 6)}/>
          </CSSBreakpoint>
          <TextLink weight="bold" underline size="medium" onClick={openAmenitiesModal}>
            View all {amenities.length} amenities
          </TextLink>
        </Group>
      }
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>(
  (state): MappedStateProps => ({
    windowSearch: state.router.location.search,
  }),
)(SearchVillaPropertyDetails)
