import React, { useMemo } from 'react'

import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import offerPageURL from 'lib/offer/offerPageURL'
import { urlHashDatePicker } from 'constants/url'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import styled from 'styled-components'

const Container = styled.div`
  text-align: right;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

interface Props {
  offer: App.Offer | Tours.TourV2Offer | App.OfferSummary | App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  className?: string;
}

function SoldOutMessage({ offer, filters, className }: Props) {
  const offerDatePickerUrl = useMemo(() => {
    const searchParams = buildSearchParamsFromFilters({
      ...(filters ?? {}),
      checkIn: undefined,
      checkOut: undefined,
    })
    return offerPageURL(offer, searchParams) + urlHashDatePicker
  }, [filters, offer])
  const soldOut = 'isSoldOut' in offer && offer.isSoldOut
  return <Container className={className}>
    { soldOut && <BodyText variant="large" weight="semi-bold" align="end">Sold out</BodyText>}
    { !soldOut && <BodyText variant="medium" weight="semi-bold" align="end">Unavailable for your dates</BodyText>}
    <TextLink size="medium" to={offerDatePickerUrl}>
      View available dates
    </TextLink>
  </Container>
}

export default SoldOutMessage
