import { useHistory, useLocation } from 'react-router-dom'

import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import usePrevious from 'hooks/usePrevious'

function determineScrollOptions(locationState: App.LocationState): ScrollOptions {
  const behavior = locationState?.scrollBehaviour || 'instant'
  let top: number
  let left: number

  if (typeof locationState?.scrollTo === 'string') {
    // Why not .scrollIntoView()? For some reason it didn't work and the screen randomly flickers.
    const rect = document.querySelector(locationState.scrollTo).getBoundingClientRect()
    top = rect.top + window.scrollY
    left = rect.left + window.scrollX
  } else {
    top = locationState?.scrollTo?.top || 0
    left = locationState?.scrollTo?.left || 0
  }

  return {
    behavior,
    top,
    left,
  } as ScrollOptions
}

export default function ScrollToTop() {
  const location = useLocation()
  const { action } = useHistory()

  const prevPath = usePrevious(location.pathname)

  useIsomorphicLayoutEffect(() => {
    if (location.pathname !== prevPath && action === 'PUSH') {
      const locationState: App.LocationState = location.state
      window.scroll(determineScrollOptions(locationState))
    }
  }, [location, prevPath, action])

  return null
}
