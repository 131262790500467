import { excludeNullOrUndefined } from 'checkout/utils'
import { NO_BOOKING_PROTECTION_QUOTE_ID } from 'constants/bookingProtection'
import { sum } from 'lib/array/arrayUtils'
import { isBedbankItem, isBookingProtectionItem, isExperienceItem, isFlightItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import createSelector from 'lib/web/createSelector'
import { checkoutWithDiscountedBookingProtection } from './luxPlusSubscription'

const emptyItemView = (itemId: string): App.Checkout.BookingProtectionItemView => {
  return {
    itemId,
    price: 0,
    memberPrice: 0,
    value: 0,
    surcharge: 0,
    taxesAndFees: 0,
    mobileAppOnlyDiscount: 0,
  }
}

export const getBookingProtectionEnabledCheckoutItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InstantBookingLEHotelItem | App.Checkout.BedbankHotelItem | App.Checkout.FlightItem | App.Checkout.ExperienceItem> => {
    return items.filter((item) => {
      return isInstantBookingLEHotelItem(item) ||
         isBedbankItem(item) ||
         isFlightItem(item) ||
         isExperienceItem(item)
    })
  },
)

export const getBookingProtectionItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BookingProtectionItem> => {
    return items.filter(isBookingProtectionItem)
  },
)

export const isBookingProtectionSelected = createSelector(
  getBookingProtectionItems,
  items => items.length > 0 && items[0].quoteId !== NO_BOOKING_PROTECTION_QUOTE_ID,
)

export const getBookingProtectionItemsView = createSelector(
  (state: App.State) => getBookingProtectionItems(state),
  (state: App.State) => state.bookingProtection.quotes,
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (state: App.State) => state.bookingProtection.fetchingQuotes,
  checkoutWithDiscountedBookingProtection,
  (
    bookingProtectionItems,
    quotes,
    existingBookingProtectItems,
    fetchingQuotes,
    checkoutWithDiscountedBookingProtection,
  ): App.WithDataStatus<Array<App.Checkout.BookingProtectionItemView>> => {
    let hasRequiredData = true

    if (!quotes) {
      return {
        hasRequiredData,
        data: [],
      }
    }

    const itemViews = bookingProtectionItems.map(item => {
      if (item.quoteId === NO_BOOKING_PROTECTION_QUOTE_ID) {
        return
      }

      let quote: App.BookingProtectionQuote | undefined
      if (item.amended) {
        const quoteKey = Object.keys(quotes).find((key: string) => key.includes(item.quoteId))
        quote = quoteKey ? quotes[quoteKey] : undefined
      } else {
        quote = Object.values(quotes).find((quote: App.BookingProtectionQuote) => quote.quoteId === item.quoteId)
      }

      if (!quote) {
        hasRequiredData = !Object.values(fetchingQuotes).length
        return emptyItemView(item.itemId)
      }

      let oldPublicQuotePrice = 0
      let oldLuxPlusQuotePrice = 0
      if (item.amended && existingBookingProtectItems) {
        const completedItem = existingBookingProtectItems.find(bookingProtectItem => bookingProtectItem.status === 'completed')
        oldPublicQuotePrice = completedItem?.publicPrice || 0
        oldLuxPlusQuotePrice = completedItem?.memberPrice || 0
      }

      const quotePublicPriceDifference = Math.round(quote.price - oldPublicQuotePrice + Number.EPSILON) * 100 / 100
      const quotePrice = quote.mobileAppOnlyPrice > 0 ? quote.mobileAppOnlyPrice : quote.price
      const quotePriceDifference = Math.round((quotePrice - oldPublicQuotePrice + Number.EPSILON) * 100) / 100
      const quoteLuxPlusPrice = quote.luxPlusPrice ?? 0
      const quoteLuxPlusPriceDifference = Math.round((quoteLuxPlusPrice - oldLuxPlusQuotePrice + Number.EPSILON) * 100) / 100
      // For now, we don't want to refund when negative difference and so we don't want to show the item
      if (quotePriceDifference < 0 ||
          (checkoutWithDiscountedBookingProtection && quoteLuxPlusPriceDifference < 0)
      ) {
        hasRequiredData = true
        return null
      }
      const mobileAppOnlyDiscount = quote.mobileAppOnlyPrice > 0 ? quotePublicPriceDifference - quotePrice : 0

      return {
        ...emptyItemView(item.itemId),
        price: quotePriceDifference,
        memberPrice: quoteLuxPlusPriceDifference,
        value: quotePriceDifference,
        mobileAppOnlyDiscount,
      }
    }).filter(excludeNullOrUndefined)

    return {
      hasRequiredData,
      data: itemViews,
    }
  },
)

function getBookingProtectionBreakdownItem(item: App.Checkout.BookingProtectionItemView): App.Checkout.BookingProtectionItemBreakdownView {
  return {
    title: 'Cancellation Protection',
    price: item.price,
    memberPrice: item.memberPrice,
    itemType: 'bookingProtection',
    additionalInfoText: [],
    additionalElements: [],
    taxesAndFees: item.taxesAndFees,
    mobileAppOnlyDiscount: item.mobileAppOnlyDiscount,
  }
}

export const getBookingProtectionBreakdownView = createSelector(
  getBookingProtectionItemsView,
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (viewWithStatus, existingItem): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const breakdownItems = viewWithStatus.data.map(getBookingProtectionBreakdownItem)

    const price = sum(breakdownItems, item => item.price || 0)
    const memberPrice = sum(breakdownItems, item => item.memberPrice || 0)
    const mobileAppOnlyDiscount = sum(breakdownItems, item => item.mobileAppOnlyDiscount || 0)

    const breakdownView = {
      title: 'Cancellation Protection',
      price: price + mobileAppOnlyDiscount,
      memberPrice,
      additionalInfoText: [],
      additionalElements: [],
      items: [],
      alwaysShowItemPrice: !!existingItem || !!price,
      breakdownItemType: 'bookingProtection' as const,
      ...(mobileAppOnlyDiscount && {
        itemMobileAppOnlyDiscount: {
          discountTitle: '20% app discount',
          discountValue: mobileAppOnlyDiscount,
        },
      }),
    }

    return {
      hasRequiredData: true,
      data: [breakdownView],
    }
  },
)
