import qs from 'qs'
import request from 'api/requestUtils'
import {
  experienceMap,
  experienceAvailabilityTimesMap,
  experienceAvailabilityDatesMap,
  experiencePickupPointMap,
  experienceCustomerFieldMap,
  experienceVoucherMap,
  experienceCategoryMap,
  experienceTransferTimesMap,
} from './mappers/experienceMap'
import { Experiences as ExperiencesContract } from '@luxuryescapes/contract-svc-experience'
import { arrayToObject, partitionBy, without } from 'lib/array/arrayUtils'
import { ExperienceSortTypes } from 'actions/ExperienceActions'
import { isFulfilled } from 'lib/promise/promiseUtils'
import { AnythingCategory, EXPERIENCE_ANY_DATE } from 'constants/experience'
import { Order as OrderTypes } from '@luxuryescapes/contract-svc-order'
import { getOrderWithPaymentsAndReservations } from './order'
import { experienceTransferItemMap } from './mappers/orderMap'
import { buildAirportTransferPayload } from 'components/Pages/MyEscapesPage/AirportTransfers/utils/airportTransferUtils'
import { UpdateAirportTransferForm, UpdateAirportTransferFormFields } from 'components/Pages/MyEscapesPage/AirportTransfers/MyEscapesAirportTransfersPage'

interface GetExperiencesFilters {
  latitude?: number,
  longitude?: number,
  categoryCodes?: Array<number>;
  distance?: string;
  from?: string;
  to?: string;
  postPurchaseOnly?: boolean;
  hotelOfferId?: string;
  showUnlisted?: boolean;
}

interface getExperiencesParams {
  currentCurrency: string,
  currentRegionCode: string,
  filters: GetExperiencesFilters,
  airports?: Array<App.Airport>,
}

export interface UpdateAirportTransferPayload {
  op: string;
  data: {
    adults: number;
    boosterSeats: number;
    children: number;
    childSeats: number;
    day: string;
    flightNumber: string;
    specialRequests: string;
    time: string;
  }
}

export async function getExperiences({
  currentCurrency,
  filters = {},
  currentRegionCode,
  airports,
}:getExperiencesParams) {
  const { longitude, latitude, hotelOfferId, distance } = filters
  const params: Record<string, string | boolean | undefined> = {
    currency: currentCurrency,
    categoryIn: without(filters.categoryCodes ?? [], AnythingCategory.id).join(',') || undefined,
    availabilityStartDate: filters.from,
    availabilityEndDate: filters.to,
    hotelOfferId: filters.hotelOfferId,
    showPostPurchase: filters.postPurchaseOnly,
    showUnlisted: filters.showUnlisted,
    // don't use default distance for hotelOfferId
    distance: undefined,
    coordinates: undefined,
  }

  if (!hotelOfferId) {
    params.distance = distance ?? '50KM'
    params.coordinates = [latitude, longitude].join(',')
  }

  const query = qs.stringify(params)

  const response = await request.get<App.ApiResponse<Array<ExperiencesContract.Offer>>>(`/api/experiences/offers/new?${query}`)
  return response.result.map(res => {
    return experienceMap({ experience: res, airports, currentRegionCode })
  })
}

interface GetExperiencesByIdParams {
  currentCurrency: string,
  currentRegionCode: string,
  airports?: Array<App.Airport>
}

export async function getExperienceById(id: string, {
  currentCurrency,
  currentRegionCode,
  airports,
}:GetExperiencesByIdParams) {
  const query = qs.stringify({
    currency: currentCurrency,
  })

  const response = await request.get<App.ApiResponse<ExperiencesContract.Offer>>(`/api/experiences/offers/new/${id}?${query}`)

  return experienceMap({ experience: response.result, airports, currentRegionCode })
}

interface DateAvailabilityParams {
  pickupPointId?: string;
  redemptionLocationId?: string;
  tickets?: Array<string>;
  ticketMode?: string;
}

export function getExperienceDateAvailability(experienceId: string, params?: DateAvailabilityParams) {
  const query = qs.stringify({
    pickupId: params?.pickupPointId,
    redemptionLocationId: params?.redemptionLocationId,
    tickets: params?.tickets,
    ticketMode: params?.ticketMode,
  })

  return request
    .get<App.ApiResponse<Array<ExperiencesContract.DateAvailability>>>(`/api/experiences/offers/${experienceId}/availability?${query}`)
    .then((response) => response.result.map(experienceAvailabilityDatesMap))
}

interface TimeAvailabilityParams {
  currencyCode: string;
  pickupPointId?: string;
  redemptionLocationId?: string;
  customerId?: string;
  tickets?: Array<string>
  ticketMode?: string;
}

export function getExperienceTimeAvailability(
  experienceId: string,
  params: TimeAvailabilityParams,
  bookingDate: string = EXPERIENCE_ANY_DATE,
) {
  const query = qs.stringify({
    currency: params.currencyCode,
    pickupId: params.pickupPointId,
    redemptionLocationId: params.redemptionLocationId,
    customerId: params.customerId,
    tickets: params.tickets,
    ticketMode: params.ticketMode,
  })

  return request
    .get<App.ApiResponse<Array<ExperiencesContract.TimeGroups>>>(`/api/experiences/offers/${experienceId}/availability/${bookingDate}?${query}`, { credentials: 'include' })
    .then((response) => experienceAvailabilityTimesMap(response.result, experienceId))
}

interface TransferAvailabilityParams {
  currencyCode: string;
  customerId?: string;
}

export function getExperienceTransferAvailability(
  experienceId: string,
  bookingDate: string,
  params: TransferAvailabilityParams,
) {
  const query = qs.stringify({
    currency: params.currencyCode,
    customerId: params.customerId,
  })

  return request
    .get<App.ApiResponse<Array<ExperiencesContract.TimeGroups>>>(`/api/experiences/offers/${experienceId}/availability/${bookingDate}?${query}`, { credentials: 'include' })
    .then((response) => experienceTransferTimesMap(response.result, experienceId))
}

export function getExperiencePickupPoints(
  experienceId: string,
) {
  return request.get<App.ApiResponse<Array<ExperiencesContract.PickupPoint>>>(`/api/experiences/offers/${experienceId}/pickup-points`)
    .then(response => response.result.map(experiencePickupPointMap))
}

export function getExperienceCustomerFields(
  experienceId: string,
  ticketId: string,
  qantity: number,
  pickupId?: string,
  languageId?: string,
): Promise<Array<App.ExperienceFormField>> {
  const query = qs.stringify({
    pickupId,
    language: languageId,
  })
  return request.get<App.ApiResponse<Array<ExperiencesContract.BookingCustomerInfo>>>(
    `/api/experiences/offers/${experienceId}/customer-info/${ticketId}/${qantity}?${query}`,
  ).then(response => response.result.map(experienceCustomerFieldMap))
}

export type ExperienceByIdResponse = { experiences: Array<App.ExperienceOffer>, errors: Record<string, any> }

interface GetExperiencesByIdsParams {
  currentCurrency: string,
  currentRegionCode: string,
  airports?: Array<App.Airport>,
}

export function getExperiencesById(ids: Array<string>, {
  airports,
  currentCurrency,
  currentRegionCode,
}:GetExperiencesByIdsParams): Promise<ExperienceByIdResponse> {
  // not ideal, add promise pooling here or something
  return Promise.allSettled(ids.map(id =>
    getExperienceById(id, { currentCurrency, airports, currentRegionCode }).catch(error => Promise.reject({ id, error }))),
  ).then((results) => {
    const [fufilled, rejected] = partitionBy(results, isFulfilled)
    return {
      experiences: fufilled.map(result => result.value),
      errors: arrayToObject(rejected, result => result.reason.id, result => result.reason.error),
    }
  })
}

export function getExperienceCategories(): Promise<Array<App.ExperienceItemCategory>> {
  return request.get<App.ApiResponse<Array<ExperiencesContract.Category>>>('/api/experiences/categories/new').then(response => {
    return [
      AnythingCategory,
      ...response.result.map(experienceCategoryMap),
    ]
  })
}

interface SearchParams {
  placeId?: string;
  placeIdToIgnore?: string;
  region: string;
  categoryCodes?: Array<number>;
  sortBy?: ExperienceSortTypes;
  campaigns?: Array<string>;
  priceGte?: number;
  priceLte?: number;
  /**
   * Format is "lat_lo,lng_lo,lat_hi,lng_hi",
   * where "lo" corresponds to the southwest corner of the bounding box
   * and "hi" corresponds to the northeast corner.
   *
   * Calling `toUrlValue()` on a `google.maps.LatLngBounds` object will return a string in this format.
   */
  bounds?: string;
}

export function getExperienceSearchList(params: SearchParams) {
  const queryParams = qs.stringify({
    placeId: params.placeId,
    placeIdToIgnore: params.placeIdToIgnore,
    region: params.region,
    categoryValues: without(params.categoryCodes ?? [], AnythingCategory.id),
    sortBy: params.sortBy,
    campaigns: params.campaigns,
    priceGte: params.priceGte,
    priceLte: params.priceLte,
    bounds: params.bounds,
  }, { arrayFormat: 'comma' })

  return request.get<App.ApiResponse<Array<string>>>(`/api/search/experience/v1/list?${queryParams}`).then(response => response.result)
}

interface FacetResult {
  category: Array<{
    label: string;
    value: string;
    level: number;
    count: number;
  }>;
  price: {
    min: number;
    max: number;
  };
}

export function getExperienceListFacets(
  placeId: string,
  region: string,
): Promise<App.ExperienceFacets> {
  const queryParams = qs.stringify({
    placeId,
    region,
  })

  return request.get<App.ApiResponse<FacetResult>>(`/api/search/experience/v1/facet/list?${queryParams}`).then(response => {
    return {
      fetching: false,
      category: response.result.category.map(cat => ({
        categoryId: parseInt(cat.value),
        count: cat.count,
      })),
      price: response.result.price,
    }
  })
}

export async function getExperienceVoucher(experienceItemId: string): Promise<App.ExperienceVoucher> {
  return request
    .get<App.ApiResponse<ExperiencesContract.Voucher>>(
      `/api/orders/experiences/items/${experienceItemId}/voucher`,
      { credentials: 'include' },
    )
    .then((response) => experienceVoucherMap(response.result))
}

export async function getBookingDetailsByIds(id: Array<string>):Promise<Array<ExperiencesContract.BookingDetails>> {
  const response = await request.get<App.ApiResponse<Array<ExperiencesContract.BookingDetails>>>(`/api/orders/experiences/items/booking-details/${id.join()}`, { credentials: 'include' })
  return response.bookingsDetails
}

export async function getHeroesByRegion(region: string) {
  return request.get<App.ApiResponse<{ experiencesOffersIds: Array<string> }>>(`/api/experiences/region-heroes/${region}`)
    .then(response => response.result.experiencesOffersIds)
}

export async function updateAirportTransfers(
  orderId: string,
  airportTransferData: UpdateAirportTransferForm,
): Promise<App.Order> {
  const updatedAirportTransferPromises: Array<Promise<App.OrderTransferItem>> = []

  const airportToHotelItem = airportTransferData.toHotel
  const hotelToAirportItem = airportTransferData.toAirport

  if (airportToHotelItem) {
    updatedAirportTransferPromises.push(putAirportTransfers(airportToHotelItem))
  }

  if (hotelToAirportItem) {
    updatedAirportTransferPromises.push(putAirportTransfers(hotelToAirportItem))
  }

  await Promise.all(updatedAirportTransferPromises)
  return await getOrderWithPaymentsAndReservations(orderId)
}

export async function putAirportTransfers(
  airportTransferData: UpdateAirportTransferFormFields,
): Promise<App.OrderTransferItem> {
  const payload = buildAirportTransferPayload(airportTransferData)
  return request
    .put<App.ApiResponse<Partial<OrderTypes.ExperienceItem>>, UpdateAirportTransferPayload >(
      `/api/orders/experiences/items/${airportTransferData.id}`,
      payload,
      { credentials: 'include' },
    )
    .then((response) => experienceTransferItemMap(response.body.result))
}
