import React, { useEffect, useState } from 'react'
import AccountAccessModalContent from './AccountAccessModalContent'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useMobileNativeAppAuth } from 'selectors/configSelectors'
import { loginNativeApp } from 'actions/AuthActions'
import { selectLoggedIn } from 'selectors/accountSelectors'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { replace } from 'connected-react-router'

export interface AccountAccessModalResult {
  loggedIn?: boolean;
}

interface Props {
  dismissable?: boolean;
  initialMode?: App.UiAccountModalMode;
  resetPasswordToken?: string;
  hotelOfferDetails?: App.AccountModalOfferDetails;
  defaultEmail?: string;
  callbackPath?: string;
}

function AccountAccessModal(props: Props) {
  const {
    initialMode = 'login',
    resetPasswordToken,
    hotelOfferDetails,
    dismissable,
    defaultEmail,
    callbackPath,
  } = props

  const [mode, setMode] = useState<App.UiAccountModalMode>(initialMode)
  const loading = useAppSelector(state => state.auth.processing)

  const { resolve, afterClose } = useModalElementContext<AccountAccessModalResult>()
  const useNativeAppAuth = useAppSelector(useMobileNativeAppAuth)
  const loggedIn = useAppSelector(selectLoggedIn)
  const loggedInError = useAppSelector(state => state.auth.error)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (useNativeAppAuth) {
      dispatch(loginNativeApp())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useNativeAppAuth])

  useEffect(() => {
    if (useNativeAppAuth) {
      if (loggedIn) {
        resolve({ loggedIn: true })
        // afterClose **must** be called after resolve has finished resolving (it sets up state in after resolve)
        // 'resolve' resolves a promise, causing its result to be evalulated on next tick
        // To make afterClose happen after resolve, we bump it to the next tick as well
        window.setTimeout(() => afterClose(), 0)
      } else if (loggedInError) {
        resolve({ loggedIn: false })
        window.setTimeout(() => afterClose(), 0)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useNativeAppAuth, loggedIn, loggedInError])

  if (useNativeAppAuth) {
    return null
  }

  const afterCloseHandler = () => {
    if (callbackPath) {
      dispatch(replace(callbackPath))
    }
  }

  return (<ModalBase
    size="S"
    dismissible={dismissable}
    modalId="accountAccess"
    onAfterClose={afterCloseHandler}
  >
    <LoadingIndicator visible={loading} floating />
    <AccountAccessModalContent
      mode={mode}
      defaultEmail={defaultEmail}
      onModeChange={setMode}
      resetPasswordToken={resetPasswordToken}
      hotelOfferDetails={hotelOfferDetails}
      callbackPath={callbackPath}
      dismissable={dismissable}
    />
  </ModalBase>
  )
}

export default AccountAccessModal
