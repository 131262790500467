import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import React from 'react'
import LuxPlusBenefitIcon from './LuxPlusBenefitIcon'
import useEnabledLuxPlusBenefitsBenefits from 'luxPlus/hooks/useEnabledLuxPlusBenefitsBenefits'

interface Props {
  /**
   * Whether to use the longer or shorter version of the benefit title.
   */
  kind: 'long' | 'short'
  benefits: Array<App.OfferSubscriptionBenefitItem>
}

function LuxPlusBenefitsList(props: Props) {
  const { kind = 'long', benefits } = props
  const enabledBenefits = useEnabledLuxPlusBenefitsBenefits(benefits)

  return (
    <>
      {enabledBenefits.map(benefit => <BodyTextBlock
        key={benefit.type}
        variant="medium"
        startIcon={<LuxPlusBenefitIcon colour="lux-plus" benefitType={benefit.type} />}
      >
        {kind === 'long' && benefit.title}
        {kind === 'short' && benefit.shortTitle}
      </BodyTextBlock>)}
    </>
  )
}

export default LuxPlusBenefitsList
