import qs from 'qs'
import request from 'api/requestUtils'
import { definitions } from '@luxuryescapes/contract-svc-agent'
import { agentHubCheckEmailStatusMapper } from './mappers/agentHubMap'

type GetAgentStatusResponse = definitions['userStatusResponse']

async function requestGetAgentStatus({ agentEmail, agentId }: {agentEmail: string; agentId?: string}): Promise<App.AgentHubAgentStatus> {
  const query = qs.stringify({
    ...(agentEmail ? { email: agentEmail } : {}),
    ...(agentId ? { agentId } : {}),
  })

  const response = await request.get<App.ApiResponse<GetAgentStatusResponse>>(`/api/agent/user/status?${query}`)

  return agentHubCheckEmailStatusMapper(response.result)
}

export default requestGetAgentStatus
