import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'
import React, { useMemo } from 'react'

interface Props {
  amenity: App.PackageRoomAmenity;
}

function VillasPageAmenity({ amenity }: Props) {
  const inclusion = useMemo<App.OfferInclusion>(() => ({
    id: amenity.id,
    description: amenity.name,
    symbol: amenity.symbol,
  }), [amenity])

  return <OfferInclusionItem inclusion={inclusion} format="titlecase" />
}

export default React.memo(VillasPageAmenity)
