import { RequireAtLeastOne, ValueOf } from 'type-fest'
import React, { PropsWithChildren, useCallback } from 'react'
import noop from 'lib/function/noop'
import useStableObjectReference from 'hooks/useStableObjectReference'
import * as Analytics from 'analytics/analytics'
import { trackOfferListClick } from 'api/interactionStudio'
import { impressionEventWithContext, productClickEventWithContext } from 'analytics/snowplow/events'
import { isAnyOffer } from 'lib/offer/offerTypes'

export type TrackableProduct = App.AnyOffer | App.ExperienceOffer | App.FlightDeal | App.JourneyV2 | App.CarHireOffer
export enum OfferListEvents {
  productClick,
  productImpression,
  listLoad,
  clientAction,
  productHover,
  offerReady,
  pricingCalculated
}

export type OfferListEventsDispatchAction = ValueOf<Utils.FullActionMap<{
  [OfferListEvents.productClick]: {
    offer: TrackableProduct,
    position: number,
    leadPrice?: number,
    duration?: number,
    /**
     * @deprecated DO NOT USE. THIS IS FOR HOMEPAGE ONLY!
     */
    key?: string
  }
  [OfferListEvents.productImpression]: {
    offer: TrackableProduct,
    position: number,
    availability?: boolean,
    leadPrice?: number,
    duration?: number,
    /**
     * @deprecated DO NOT USE. THIS IS FOR HOMEPAGE ONLY!
     */
    key?: string
  }
  [OfferListEvents.offerReady]: {
    available?: boolean,
  }
  [OfferListEvents.listLoad]: {
    key: string,
    list: App.OfferList,
  }
  [OfferListEvents.productHover]: {
    offer: TrackableProduct,
  }
  [OfferListEvents.clientAction]: {
    action: string,
    subject: string,
    /**
     * @deprecated DO NOT USE. THIS IS FOR HOMEPAGE ONLY!
     */
    key?: string
  }
  [OfferListEvents.pricingCalculated]: {
    leadPrice: number,
    duration: number,
  }
}>>

export type AdditionalData = Record<string | number | symbol, any>;
export type OfferListEventHandler<Data extends AdditionalData = AdditionalData> = (
  dispatchAction: OfferListEventsDispatchAction,
  additionalData?: Data,
) => void
const OfferListEventsContext = React.createContext<OfferListEventHandler>(noop)

type Props = RequireAtLeastOne<{
  /**
   * Event that will be fired whenever an action occurs in the offer list
   */
  onListEvent?: OfferListEventHandler;
  /**
   * Use this to augment any data to the `onListEvent` function
   */
  additionalData?: AdditionalData;
  /**
   * Pass a tracking config object to enable default tracking.
   * This currently will fire analytics for:
   * - Product clicks
   * - Impressions
   *
   * Use this to automatically track the 'common' metrics
   * For any extra metrics, please hook into the `onListEvent` prop
   */
  tracking?: App.OfferListTrackingConfig;
}, 'onListEvent' | 'tracking'>

export function OfferListEventsProvider(props: PropsWithChildren<Props>) {
  const { onListEvent = noop, additionalData, tracking } = props
  const stableAdditionalData = useStableObjectReference(additionalData)

  const value = useCallback<OfferListEventHandler>((dispatchAction) => {
    onListEvent(dispatchAction, stableAdditionalData)

    if (tracking) {
      switch (dispatchAction.type) {
        case (OfferListEvents.productClick): {
          const {
            offer,
            position,
            leadPrice,
            duration,
          } = dispatchAction
          Analytics.trackClientEvent({
            subject: 'click-any-offer',
            action: 'clicked',
            category: 'logging',
            type: 'operational',
            optimizelyEventId: '28377200726',
            optimizelyEventKey: 'click-any-offer',
          })
          let finalTracking = tracking

          // If the individual offer has different sources, will map the source here
          if (tracking.offerSourceMapping) {
            const offerSource = tracking.offerSourceMapping[offer.id]
            finalTracking = {
              ...tracking,
              listSource: offerSource ?? tracking.listSource,
            }
          }
          if (isAnyOffer(offer)) {
            if (finalTracking.ias) {
              trackOfferListClick({
                index: position,
                list: finalTracking.ias.list,
                offerId: offer.id,
                listId: finalTracking.ias.listId,
              })
            }
          }
          if (tracking?.snowplow?.enabled) {
            Analytics.trackEvent(
              productClickEventWithContext(
                position + 1,
                finalTracking.listName,
                tracking.listId,
                offer,
                { leadPrice, duration },
              ),
            )
          }

          break
        }
        case (OfferListEvents.productImpression): {
          const { offer, position, leadPrice, duration } = dispatchAction
          // If the individual offer has different sources, will map the source here
          let finalTracking = tracking
          if (tracking.offerSourceMapping) {
            const offerSource = tracking.offerSourceMapping[offer.id]
            finalTracking = { ...tracking, listSource: offerSource ?? tracking.listSource }
          }
          if (tracking.snowplow?.enabled) {
            Analytics.trackEvent(
              impressionEventWithContext(
                position + 1,
                finalTracking.listName,
                tracking.listId,
                offer,
                { leadPrice, duration },
              ),
            )
          }
          break
        }
      }
    }
  }, [onListEvent, stableAdditionalData, tracking])

  return <OfferListEventsContext.Provider value={value}>
    {props.children}
  </OfferListEventsContext.Provider>
}

export default OfferListEventsContext
