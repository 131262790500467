import moment from 'moment'
import {
  CURRENCY_MIN_DEPOSIT_AMOUNT_MAP,
  DEPOSIT_ENABLED_CURRENCIES,
  MIN_DEPOSITS_AUD_PAYMENT,
  DEPOSIT_PENDING_BALANCE_STATUSES,
  DEPOSIT_PAID_BALANCE_STATUSES,
} from 'constants/payment'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import switchFunc from 'lib/function/switchFunc'
import { TOUR_V2_DEPOSIT_MINIMUM_VALUE_PER_CURRENCY_CODE } from 'constants/checkout'
import {
  getAlwaysOnCancellationPolicyDays,
  getAlwaysOnDynamicCancellationPolicyDate,
  isAlwaysOnCancellationPolicyRefundable,
} from 'lib/payment/alwaysOnUtils'
import config from 'constants/config'
import { isTourV2OfferType } from 'lib/offer/offerTypes'
import { ConnectionCancellationPolicies } from '@luxuryescapes/lib-refunds'
import { TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD } from 'constants/tours'

export function isDepositsEnabled(currency: string): boolean {
  return config.DEPOSITS_ENABLED && !!(DEPOSIT_ENABLED_CURRENCIES.includes(currency))
}

export const isStripeWalletDepositsEnabled = config.STRIPE_WALLET_DEPOSITS_ENABLED

export function isDepositsEnabledForBNBL(): boolean {
  return config.DEPOSITS_ENABLED && config.BNBL_DEPOSITS_ENABLED
}

export const isWithinDepositsAvailabilityRange = (amount: number, currency: string): boolean => {
  if (currency in CURRENCY_MIN_DEPOSIT_AMOUNT_MAP && amount >= CURRENCY_MIN_DEPOSIT_AMOUNT_MAP[currency]) {
    return true
  } else {
    return false
  }
}

export const getMinDepositAmount = (currency: string): number => {
  return CURRENCY_MIN_DEPOSIT_AMOUNT_MAP[currency] || MIN_DEPOSITS_AUD_PAYMENT
}

export function getDepositDueDaysForOrderItem(item?: App.OrderItem): number {
  return (item?.offer?.type === OFFER_TYPE_TOUR) ? 105 : 60
}

export const getAlwaysOnCancellationDays = (startDate: string, offerPackage: App.Package | undefined, cancellationPolicies?: ConnectionCancellationPolicies): number | null => {
  const cancellationPolicyType = offerPackage?.roomRate?.cancellationPolicy?.type

  if (cancellationPolicyType == 'dynamic') {
    const freeCancellationDate = getAlwaysOnDynamicCancellationPolicyDate(cancellationPolicies)

    if (!freeCancellationDate) return null

    if (moment().isAfter(freeCancellationDate)) return null

    const startDateInIsoFormat = new Date(startDate)
    const difference = startDateInIsoFormat.getTime() - freeCancellationDate.getTime()
    const diffDays = Math.ceil(difference / (1000 * 3600 * 24))

    if (diffDays <= 0) return 0

    return diffDays
  }

  return getAlwaysOnCancellationPolicyDays(cancellationPolicyType)
}

export const isOfferWithInStartDateRange = (startDate: string | undefined, offer: App.Offer | App.OfferSummary | Tours.TourV2Offer, offerPackage: App.Package | undefined, cancellationPolicies?: ConnectionCancellationPolicies): boolean | null => {
  let offerEligibilityRange = 60 * 24 * 60 * 60 * 1000 // 60 days

  if (!startDate) return true

  if (offer &&
    'depositThresholds' in offer &&
    offer.depositThresholds?.numberOfDays) {
    const numberOfDays = offer.depositThresholds.numberOfDays

    offerEligibilityRange = numberOfDays * 24 * 60 * 60 * 1000
  } else {
    if (offer && offer.type === OFFER_TYPE_TOUR) {
      offerEligibilityRange = 105 * 24 * 60 * 60 * 1000 // 105 days
    }

    if (offer && offer.type === OFFER_TYPE_ALWAYS_ON) {
      const cancellationPolicyDays = getAlwaysOnCancellationDays(startDate, offerPackage, cancellationPolicies)
      if (cancellationPolicyDays == null) return null
      offerEligibilityRange = ((cancellationPolicyDays + 16) * 24 * 60 * 60 * 1000)
    }
  }

  const currentDate = Date.now()
  const startDateInIsoFormat = new Date(startDate).toISOString()
  const startDateInMilliseconds = Date.parse(startDateInIsoFormat)

  return currentDate + offerEligibilityRange < startDateInMilliseconds
}

export function isDepositAvailableOnAlwaysOnOffer(offerType: App.OfferType, offerPackage?: App.Package): boolean {
  if (!config.DEPOSITS_ENABLED) return false
  if (offerType !== OFFER_TYPE_ALWAYS_ON) return true
  const cancellationPolicyType = offerPackage?.roomRate?.cancellationPolicy.type
  return !offerPackage?.shouldInstantPurchase &&
  isAlwaysOnCancellationPolicyRefundable(cancellationPolicyType)
}

function isTourV2DepositAvailable(offer: App.Offer | App.OfferSummary | Tours.TourV2Offer): boolean {
  if (!config.DEPOSITS_ENABLED) return false
  if ('depositType' in offer) {
    return offer.depositType !== 'noDeposit'
  }
  return true
}

export function isDepositAvailableOnOffer(
  offer: App.Offer | App.OfferSummary | Tours.TourV2Offer,
  currency: string,
  checkInDate: moment.Moment | undefined,
  totalPrice: number,
  offerPackage?: App.Package,
  pkgAvailableRate?: App.OfferAvailableRate,
) {
  const disableDeposit = offer.parentType === OFFER_TYPE_TOUR_V2 ? false : offer.disableDeposit
  const depositAvailable = !disableDeposit &&
    isDepositsEnabled(currency) &&
    isDepositAvailableOnAlwaysOnOffer(offer.type, offerPackage) &&
    isWithinDepositsAvailabilityRange(totalPrice, currency) &&
    isOfferWithInStartDateRange(checkInDate?.format(), offer, offerPackage, pkgAvailableRate?.cancellationPolicies) &&
    isTourV2DepositAvailable(offer)
  return !!depositAvailable
}

export function isBalancePending(status: string): boolean {
  return DEPOSIT_PENDING_BALANCE_STATUSES.has(status)
}

export function isBalancePaid(status: string): boolean {
  return DEPOSIT_PAID_BALANCE_STATUSES.has(status)
}

export const getTourV2DepositMinimumValueForCurrencyCode = switchFunc(TOUR_V2_DEPOSIT_MINIMUM_VALUE_PER_CURRENCY_CODE, Infinity)
export const getDepositMinimumValueForCurrencyCode = switchFunc(CURRENCY_MIN_DEPOSIT_AMOUNT_MAP, Infinity)

export function isDepositEnabledForOfferType(offerType: App.LEContractedOfferType): boolean {
  return config.DEPOSITS_ENABLED && (offerType === OFFER_TYPE_HOTEL || offerType === OFFER_TYPE_TOUR || offerType === OFFER_TYPE_ALWAYS_ON)
}

export function getPaymentDueDate(depositThresholds: App.DepositThreshold, offerType: App.OfferType, cancellationPolicyType?: string): number {
  if (depositThresholds?.numberOfDays) {
    return depositThresholds.numberOfDays
  }

  if (offerType === OFFER_TYPE_ALWAYS_ON) {
    return getAlwaysOnCancellationPolicyDays(cancellationPolicyType) + 15
  }

  return ((offerType === OFFER_TYPE_TOUR) || isTourV2OfferType(offerType)) ? TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD : 60
}

export function getDepositAmountPercentage(offer: App.Offer | App.OfferSummary | Tours.TourV2Offer | undefined, defaultDepositAmountPercentage: number): number {
  return offer && 'depositThresholds' in offer && offer?.depositThresholds?.percentage ?
    offer.depositThresholds.percentage :
    offer?.depositAmount ?? defaultDepositAmountPercentage
}

export function isDefaultDepositAmountPercentage(offer: App.Offer | App.OfferSummary | Tours.TourV2Offer | undefined) {
  return offer && 'depositThresholds' in offer && offer?.depositThresholds?.percentage ?
    false : !offer?.depositAmount
}

export function isOfferDefaultDepositAmountPercentage(depositThresholds: App.DepositThreshold | undefined, depositAmount: number | undefined) {
  return depositThresholds?.percentage ? false : !depositAmount
}
