import { FilterPanelCheckItem } from 'components/Common/FilterPanel/FilterPanelCheckboxGroup'
import { pluralizeToString } from 'lib/string/pluralize'
import { arrayToObject, groupBy, last, sortBy, sum, unique, uniqueBy } from 'lib/array/arrayUtils'
import { convertTimeObjectToMinutes, convertMinutesToHoursAndMinutes, convertTimeStringToMinutes, getDisplayInfoV2, getFareClass } from 'lib/flights/flightUtils'
import { FlightFilters } from 'checkout/Components/InFlowFlightSearch/CheckoutFlightsFilterDrawer'
import { CHECKOUT_FLIGHT_FILTERS, FlightFilterType, STANDALONE_FLIGHT_FILTERS, flightFiltersLabelMap } from 'constants/flight'
import { FlightPriceFilterRecommendedType, FlightPriceFilterType } from 'contexts/Flights/StandaloneFlights/standaloneFlightsStateReducer'

export function getAirlineFilterItems(
  journeys: Array<App.JourneyV2>,
  options: {
    hidePrice?: boolean
  } = {},
): Array<FilterPanelCheckItem> {
  const items = journeys.map(journey => ({
    carrier: journey.carrier,
    name: journey.carrierName,
  }))

  const airlines = sortBy(
    uniqueBy(items, item => item.carrier),
    item => item.name,
    'asc',
  )

  const costs = getAirlinesCheapeastNumbers(journeys)

  return [
    {
      value: 'all',
      label: 'All airlines',
    },
    ...airlines.map(airline => ({
      label: airline.name,
      value: airline.carrier,
      price: !options.hidePrice ? costs[airline.carrier] : undefined,
    })),
  ]
}

function getAirlinesCheapeastNumbers(journeys: Array<App.JourneyV2> = []) {
  return journeys.reduce((costs: Record<string, number>, journey) => {
    const cost = getJourneyAdultCost(journey)

    const carrier = journey.carrier
    const costExists = cost !== undefined

    if (costExists && (cost < costs[carrier] || costs[carrier] === undefined)) {
      costs[carrier] = cost
    }

    return costs
  }, {})
}

export function getCabinTypeFilterItems(
  journeys: Array<App.JourneyV2>,
): Array<FilterPanelCheckItem> {
  const fareClasses = journeys.map(journey => getFareClass(journey.flightGroup.flights[0].fareClass))
  const cabinTypes = unique(fareClasses)

  return [
    {
      value: 'all',
      label: 'All cabins',
    },
    ...cabinTypes.map(cabinType => ({
      label: cabinType,
      value: cabinType,
    })),
  ]
}

export function getStopoverDurationFilterItems(
  journeys: Array<App.JourneyV2>,
): Array<FilterPanelCheckItem> {
  const allStopoverTimes = journeys.map(
    journey => {
      const timeSum = sum(journey.flightGroup.flights.map(flight => convertTimeObjectToMinutes(flight.stopoverTime)))
      return convertMinutesToHoursAndMinutes(timeSum)
    },
  )

  const uniqueStopOvers = uniqueBy(allStopoverTimes, m => `${m.hours}${m.minutes}`)
  const categoriedTimes = groupBy(uniqueStopOvers, time => time)

  const stopDurations = sortBy(
    categoriedTimes.keys(),
    val => convertTimeObjectToMinutes(val),
    'asc',
  )

  return [
    ...stopDurations.map((duration) => {
      return {
        value: convertTimeObjectToMinutes(duration),
        label: `${duration.hours}h ${duration.minutes}m`,
      }
    }),
  ]
}

export function getStopsFilterItems(
  journeys: Array<App.JourneyV2>,
  options:{ hidePrice?: boolean } = {},
): Array<FilterPanelCheckItem> {
  const allStops = journeys.flatMap(journey => journey.flightGroup.flights.length - 1)
  const stops = unique(allStops).sort()
  const costs = getStopsCheapeastNumbers(journeys)

  return [
    {
      value: 'all',
      label: 'All stops',
    },
    ...stops.map(stop => ({
      label: stop === 0 ? 'Nonstop' : pluralizeToString('stopover', stop),
      value: stop.toString(),
      price: !options.hidePrice ? costs[stop] : undefined,
    })),
  ]
}

export function getStopoverCitiesFilterItems(
  journeys: Array<App.JourneyV2>,
  options:{ hidePrice?: boolean } = {},
): Array<FilterPanelCheckItem> {
  const cities: Array<string> = []
  const existingCity: Record<string, boolean> = {}

  journeys.forEach(journey => {
    if (journey.flightGroup.flights.length >= 2) {
      const stopOversFlights = journey.flightGroup.flights.slice(0, journey.flightGroup.flights.length - 1)
      stopOversFlights.forEach(flight => {
        if (!existingCity[flight.arrivalCity]) {
          cities.push(flight.arrivalCity)
          existingCity[flight.arrivalCity] = true
        }
      })
    }
  })

  const stopOverCosts = getStopOverNumbers(journeys)

  return [
    {
      value: 'all',
      label: 'All stopover cities',
    },
    ...cities.map((city) => {
      return {
        value: city.toLowerCase(),
        label: city,
        price: !options.hidePrice ? stopOverCosts[city] : undefined,
      }
    }),
  ]
}

function getStopsCheapeastNumbers(journeys: Array<App.JourneyV2> = []) {
  return journeys.reduce((costs: Record<string, number>, journey) => {
    const cost = getJourneyAdultCost(journey)

    const stopsCount = journey.flightGroup.flights.length - 1
    const costExists = cost !== undefined

    if (costExists && (cost < costs[stopsCount] || costs[stopsCount] === undefined)) {
      costs[stopsCount] = cost
    }

    return costs
  }, {})
}

function getStopOverNumbers(journeys: Array<App.JourneyV2> = []) {
  return journeys.reduce((costs: Record<string, number>, journey) => {
    const cost = getJourneyAdultCost(journey)

    if (journey.flightGroup.flights.length >= 2) {
      const stopOversFlights = journey.flightGroup.flights.slice(0, journey.flightGroup.flights.length - 1)
      const stopCities = stopOversFlights.map(f => f.arrivalCity)
      const costExists = cost !== undefined

      stopCities.forEach(city => {
        if (costExists && (cost < costs[city] || costs[city] === undefined)) {
          costs[city] = cost
        }
      })
    }

    return costs
  }, {})
}

export function getAllianceFilterItems(
  journeys: Array<App.JourneyV2>,
  options:{ hidePrice?: boolean } = {},
): Array<FilterPanelCheckItem> {
  const alliances: Array<string> = []
  const existingAlliance: Record<string, boolean> = {}

  journeys.forEach(journey => {
    const firstFlight = journey.flightGroup.flights[0]

    if (firstFlight?.alliance && !existingAlliance[firstFlight.alliance]) {
      alliances.push(firstFlight.alliance)
      existingAlliance[firstFlight.alliance] = true
    }
  })

  const alliancesCost = getAlliancesNumbers(journeys)

  return [
    {
      value: 'all',
      label: 'All stopover cities',
    },
    ...alliances.map((alliance) => {
      return {
        value: alliance.toLowerCase(),
        label: alliance,
        price: !options.hidePrice ? alliancesCost[alliance] : undefined,
      }
    }),
  ]
}

function getAlliancesNumbers(journeys: Array<App.JourneyV2> = []) {
  return journeys.reduce((costs: Record<string, number>, journey) => {
    const cost = getJourneyAdultCost(journey)
    const alliance = journey.flightGroup.flights[0]?.alliance

    const costExists = cost !== undefined

    if (costExists && alliance && (cost < costs[alliance] || costs[alliance] === undefined)) {
      costs[alliance] = cost
    }

    return costs
  }, {})
}

export function getDurationFilterItems(
  journeys: Array<App.JourneyV2>,
): Array<FilterPanelCheckItem> {
  const allTimes = journeys.map(journey => journey.flightGroup.totalFlightDuration)
  const categoriedTimes = groupBy(allTimes, time => time)

  const stopDurations = sortBy(
    categoriedTimes.keys(),
    val => convertTimeObjectToMinutes(val),
    'asc',
  )

  return [
    ...stopDurations.map((duration) => {
      return {
        value: convertTimeObjectToMinutes(duration),
        label: `${duration?.hours}h ${duration?.minutes}m`,
      }
    }),
  ]
}

export function getPricesFilterItems(
  priceType: FlightPriceFilterType,
  journeys: Array<App.JourneyV2>,
): Array<FilterPanelCheckItem> {
  const allPrices = journeys.map(
    journey => priceType === FlightPriceFilterType.ADULT ? journey.price.adult.totalFare : journey.price.all.totalFare,
  )

  const categoriedPrices = unique(allPrices).filter(Boolean) as Array<number>

  const prices = sortBy(
    categoriedPrices,
    val => val,
    'asc',
  )

  return prices.map((price) => ({
    value: price,
    label: `${price}`,
  }),
  )
}

export function getDepartureTimesFilterItems(
  journeys: Array<App.JourneyV2>,
): Array<FilterPanelCheckItem> {
  const allTimes = journeys.map(journey => journey.flightGroup.flights[0].departingTime)
  const categoriedTimes = groupBy(unique(allTimes), time => time)

  const times = sortBy(
    categoriedTimes.keys(),
    val => val,
    'asc',
  )

  return [
    ...times.map((duration) => {
      return {
        value: convertTimeStringToMinutes(duration),
        label: duration,
      }
    }),
  ]
}

export function getArrivalTimesFilterItems(
  journeys: Array<App.JourneyV2>,
): Array<FilterPanelCheckItem> {
  const allTimes = journeys.map(journey => last(journey.flightGroup.flights).arrivalTime)
  const categoriedTimes = groupBy(unique(allTimes), time => time)

  const times = sortBy(
    categoriedTimes.keys(),
    val => val,
    'asc',
  )

  return [
    ...times.map((duration) => {
      return {
        value: convertTimeStringToMinutes(duration),
        label: duration,
      }
    }),
  ]
}

export function getRecommendedFilterItems(journeys: Array<App.JourneyV2>, options: { hidePrice?: boolean } = {}): Array<FilterPanelCheckItem> {
  const costs = getRecommendedCheapeastNumbers(journeys)

  return [
    {
      value: FlightPriceFilterRecommendedType.DIRECT_FLIGHTS,
      label: 'Direct flights',
      price: !options.hidePrice ? costs[FlightPriceFilterRecommendedType.DIRECT_FLIGHTS] : undefined,
    },
    {
      value: FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG,
      label: 'Includes checked bag',
      price: !options.hidePrice ? costs[FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG] : undefined,
    },
  ]
}

export enum BAG_TYPE {
  CARRY_ON = 'carryOn',
  CHECKED = 'checked',
}

export function getBagsFilterItems(journeys: Array<App.JourneyV2>, options: { hidePrice?: boolean } = {}): Array<FilterPanelCheckItem> {
  const costs = getRecommendedCheapeastNumbers(journeys)

  return [
    {
      value: BAG_TYPE.CARRY_ON,
      label: 'Includes carry-on bag',
      price: !options.hidePrice ? costs[BAG_TYPE.CARRY_ON] : undefined,

    },
    {
      value: BAG_TYPE.CHECKED,
      label: 'Includes checked bag',
      price: !options.hidePrice ? costs[BAG_TYPE.CHECKED] : undefined,
    },
  ]
}

function getRecommendedCheapeastNumbers(journeys: Array<App.JourneyV2> = []) {
  return journeys.reduce((costs: Record<string, number>, journey) => {
    const cost = getJourneyAdultCost(journey)
    const existingDirectCost = costs[FlightPriceFilterRecommendedType.DIRECT_FLIGHTS]
    const existingWithIncludedBaggageCost = costs[FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG]
    const existingWithCheckedCost = costs[BAG_TYPE.CHECKED]
    const existingWithCarryOnCost = costs[BAG_TYPE.CARRY_ON]

    const isDirect = journey.flightGroup.flights.length === 1
    const baggageIncluded = getDisplayInfoV2(journey.flightGroup.flights).baggageIncluded
    const costExists = cost !== undefined

    if (costExists) {
      if (isDirect && (existingDirectCost === undefined || cost < existingDirectCost)) {
        costs[FlightPriceFilterRecommendedType.DIRECT_FLIGHTS] = cost
      }

      if (baggageIncluded && (existingWithIncludedBaggageCost === undefined || cost < existingWithIncludedBaggageCost)) {
        costs[FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG] = cost
      }

      const checkedBaggage = journey.flightGroup.flights[0]?.includedCheckedBaggage
      if (checkedBaggage && (existingWithCheckedCost === undefined || cost < existingWithCheckedCost)) {
        costs[BAG_TYPE.CHECKED] = cost
      }

      const carryOnBaggage = journey.flightGroup.flights[0]?.includedCarryOnBaggage
      if (carryOnBaggage && (existingWithCarryOnCost === undefined || cost < existingWithCarryOnCost)) {
        costs[BAG_TYPE.CARRY_ON] = cost
      }
    }

    return costs
  }, {})
}

function getJourneyAdultCost(journey: App.JourneyV2): number | undefined {
  return journey.price.adult.totalFare
}

export function isJourneyVisible(
  journey: App.JourneyV2,
  filters: FlightFilters,
): boolean {
  let show = true

  const group = journey.flightGroup
  const flights = group.flights

  if (filters.stops?.length) {
    show = show && filters.stops.includes((flights.length - 1).toString())
  }

  if (filters.stopDurations?.length) {
    const totalLayover = sum(flights.map(f => convertTimeObjectToMinutes(f.stopoverTime)))
    show = show && totalLayover <= parseInt(filters.stopDurations[0])
  }

  if (filters.airlines?.length) {
    show = show && filters.airlines.includes(journey.carrier)
  }

  if (filters.cabinTypes?.length) {
    show = show && flights.some(flight => filters.cabinTypes!.includes(getFareClass(flight.fareClass)))
  }

  if (filters.durations?.length) {
    show = show && convertTimeObjectToMinutes(group.totalFlightDuration) <= parseInt(filters.durations[0])
  }

  if (filters.minDepartureTime?.length) {
    show = show && convertTimeStringToMinutes(flights[0].departingTime) >= parseInt(filters.minDepartureTime[0])
  }

  if (filters.maxDepartureTime?.length) {
    show = show && convertTimeStringToMinutes(flights[0].departingTime) <= parseInt(filters.maxDepartureTime[0])
  }

  if (filters.minArrivalTime?.length) {
    show = show && convertTimeStringToMinutes(last(flights).arrivalTime) >= parseInt(filters.minArrivalTime[0])
  }

  if (filters.maxArrivalTime?.length) {
    show = show && convertTimeStringToMinutes(last(flights).arrivalTime) <= parseInt(filters.maxArrivalTime[0])
  }

  if (filters.recommended?.length) {
    const filterDirectFlights = filters.recommended?.includes(FlightPriceFilterRecommendedType.DIRECT_FLIGHTS)
    const filterCheckedBag = filters.recommended?.includes(FlightPriceFilterRecommendedType.INCLUDES_CHECKED_BAG)

    if (filterDirectFlights) {
      show = show && flights.length === 1
    }

    if (filterCheckedBag) {
      const info = getDisplayInfoV2(flights)
      show = show && info.baggageIncluded
    }
  }

  if (filters.bags?.length) {
    const carryOnBag = filters.bags?.includes(BAG_TYPE.CARRY_ON)
    const checkedBag = filters.bags?.includes(BAG_TYPE.CHECKED)

    if (carryOnBag) {
      show = show && Boolean(flights[0]?.includedCarryOnBaggage)
    }

    if (checkedBag) {
      show = show && Boolean(flights[0]?.includedCheckedBaggage)
    }
  }

  if (filters.stopOverCities?.length) {
    const flightStopOverCities = flights.slice(0, flights.length - 1).map(flight => flight.arrivalCity.toLowerCase())
    const selectedCities = arrayToObject(filters.stopOverCities, val => val, val => val)
    show = show && flightStopOverCities.some(city => selectedCities[city])
  }

  if (filters.alliances?.length) {
    const flightAlliance = flights[0]?.alliance?.toLowerCase()
    show = show && filters.alliances.some(alliance => alliance === flightAlliance)
  }

  return show
}

export function removeUnusedFilters(activeFilters: string | Array<string>, segmentFilters: Array<string>) {
  const filtersSet = new Set(segmentFilters)
  return typeof activeFilters === 'object' ? activeFilters.filter(filter => filtersSet.has(filter)) : filtersSet.has(activeFilters)
}

export function getStandaloneMobileFilterPills(filters: Record<string, Array<number | string> | undefined>, activeFilters: Record<string, Set<string> | undefined>) {
  const pills = STANDALONE_FLIGHT_FILTERS.filter(filter => {
    if ([FlightFilterType.TIMES, FlightFilterType.BAGS].includes(filter)) {
      return true
    }

    const activeFilter = filters[filter] || []
    const isSlider = [FlightFilterType.PRICES, FlightFilterType.DURATIONS, FlightFilterType.STOP_DURATIONS].includes(filter)
    return isSlider ? activeFilter.length > 1 : activeFilter.length > 2
  })

  return pills.map(filterKey => {
    const quantity = getFilterQuantity(activeFilters, filterKey)

    return {
      label: flightFiltersLabelMap[filterKey],
      value: filterKey,
      quantity,
    }
  }).sort((a, b) => b.quantity - a.quantity)
}

export function getStandaloneAllActiveFilterQuantity(activeFilters: Record<string, Set<string> | undefined>) {
  return STANDALONE_FLIGHT_FILTERS.reduce((acc, filterKey) => {
    const quantity = getFilterQuantity(activeFilters, filterKey)
    if (quantity > 0) return acc + 1
    return acc
  }, 0)
}

function getFilterQuantity(activeFilters: Record<string, Set<string> | undefined>, filterType: FlightFilterType) {
  let quantity = 0

  if (filterType === FlightFilterType.PRICES) {
    quantity = getPricesFilterQuantity(activeFilters)
  } else if (filterType === FlightFilterType.TIMES) {
    quantity = getTimeFilterQuantity(activeFilters)
  } else {
    const currentFilter = activeFilters[filterType] || new Set([])
    quantity = [...currentFilter].filter(f => f !== 'all').length
  }

  return quantity
}

function getTimeFilterQuantity(filters: Record<string, Set<string> | undefined>) {
  const minDepartureTime = filters[FlightFilterType.MIN_DEPARTURE_TIME] || new Set([])
  const maxDepartureTime = filters[FlightFilterType.MAX_DEPARTURE_TIME] || new Set([])
  const minArrivalTime = filters[FlightFilterType.MIN_ARRIVAL_TIME] || new Set([])
  const maxArrivalTime = filters[FlightFilterType.MAX_ARRIVAL_TIME] || new Set([])

  return Math.max(minDepartureTime.size, maxDepartureTime.size) + Math.max(minArrivalTime.size, maxArrivalTime.size)
}

function getPricesFilterQuantity(filters: Record<string, Set<string> | undefined>) {
  const minPrices = filters[FlightFilterType.MIN_PRICES] || new Set([])
  const maxPrices = filters[FlightFilterType.MAX_PRICES] || new Set([])

  return Math.max(minPrices.size, maxPrices.size)
}

export function getCheckoutFilterPills(filters: Record<string, Array<number | string> | undefined>, activeFilters: Record<string, Set<string> | undefined>) {
  const pills = CHECKOUT_FLIGHT_FILTERS.filter(filter => {
    if ([FlightFilterType.TIMES, FlightFilterType.BAGS].includes(filter)) {
      return true
    }

    const activeFilter = filters[filter] || []
    const isSlider = [FlightFilterType.PRICES, FlightFilterType.DURATIONS, FlightFilterType.STOP_DURATIONS].includes(filter)
    return isSlider ? activeFilter.length > 1 : activeFilter.length > 2
  })

  return pills.map(filterKey => {
    const quantity = getFilterQuantity(activeFilters, filterKey)

    return {
      label: flightFiltersLabelMap[filterKey],
      value: filterKey,
      quantity,
    }
  }).sort((a, b) => b.quantity - a.quantity)
}

export function getCheckoutAllActiveFilterQuantity(activeFilters: Record<string, Set<string> | undefined>) {
  return CHECKOUT_FLIGHT_FILTERS.reduce((acc, filterKey) => {
    const quantity = getFilterQuantity(activeFilters, filterKey)
    if (quantity > 0) return acc + 1
    return acc
  }, 0)
}
