import GeoContext from 'contexts/geoContext'
import { DecimalFormatOptions, formatDecimal, formatPercent, formatUnit, NumberFormatterOptions, PercentFormatOptions, UnitFormatOptions } from 'lib/format/formatNumber'
import { useCallback, useContext } from 'react'
import useStableObjectReference from './useStableObjectReference'

type DecimalFormatter = (
  value: number
) => string
/**
 * Formats a given number into readable string. **(eg. 10000 => 10,000)**
 *
 * _Note: The utility function version is `formatDecimal` as `lib/format/formatNumber`._
 */
export function useDecimalFormatter(options: Omit<DecimalFormatOptions, 'regionCode'> = {}): DecimalFormatter {
  const { currentRegionCode } = useContext(GeoContext)
  const stableOptions = useStableObjectReference(options)
  const formatter = useCallback<DecimalFormatter>((value) => {
    return formatDecimal(value, { ...stableOptions, regionCode: currentRegionCode })
  }, [currentRegionCode, stableOptions])

  return formatter
}

type PercentFormatter = (
  /**
   * @example 0.5 => 50%
   */
  value: number,
) => string
/**
 * Formats a given number into readable percentage. **(eg. 0.5 => 50%)**
 *
 * _Note: The utility function version is `formatPercent` as `lib/format/formatNumber`._
 */
export function usePercentFormatter(options: Omit<PercentFormatOptions, 'regionCode'> = {}): PercentFormatter {
  const { currentRegionCode } = useContext(GeoContext)
  const stableOptions = useStableObjectReference(options)
  const formatter = useCallback<PercentFormatter>((value) => {
    return formatPercent(value, { ...stableOptions, regionCode: currentRegionCode })
  }, [currentRegionCode, stableOptions])

  return formatter
}

type UnitFormatter = (
  value: number,
  unit: NonNullable<NumberFormatterOptions['unit']>
) => string
/**
 * Formats a given number and unit into readable string. **(eg. 10 kilometer => 10 km)**
 *
 * _Note: The utility function version is `formatNumber` at `lib/format/formatNumber`._
 */
export function useUnitFormatter(options: Omit<UnitFormatOptions, 'regionCode'> = {}): UnitFormatter {
  const { currentRegionCode } = useContext(GeoContext)
  const stableOptions = useStableObjectReference(options)
  const formatter = useCallback<UnitFormatter>((value, unit) => {
    return formatUnit(value, unit, { ...stableOptions, regionCode: currentRegionCode })
  }, [currentRegionCode, stableOptions])

  return formatter
}
