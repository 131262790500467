export const decode = (encodedString: string) => {
  if (typeof atob === 'function') {
    try {
      return atob(encodedString)
    } catch {
      return null
    }
  }
  if (IS_SSR) {
    // buffer only exists in node, which means the server
    // without the check above telling webpack to remove this
    // section of code, it includes the buffer import into the bundle
    // which isn't needed for the FE because it has atob
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (Buffer && Buffer.from) {
      return Buffer.from(encodedString, 'base64').toString()
    }
  }
  return null
}

export const encode = (string: string) => {
  if (typeof btoa === 'function') {
    try {
      return btoa(string)
    } catch {
      return null
    }
  }
  if (IS_SSR) {
    if (Buffer) {
      return Buffer.from(string).toString('base64')
    }
  }
  return null
}
