enum Refundable {
  Fully = 'Free cancellation',
  Partial = 'Partially refundable',
  Non = 'Non refundable'
}

const getRefundDescriptions = (offerType?: string): { [key in Refundable]: string } => {
  if (offerType === 'rental') {
    return {
      [Refundable.Fully]: 'Flexible cancellation',
      [Refundable.Partial]: 'Partially refundable',
      [Refundable.Non]: 'Non refundable',
    }
  }
  return {
    [Refundable.Fully]: 'Free cancellation',
    [Refundable.Partial]: 'Partially refundable',
    [Refundable.Non]: 'Non refundable',
  }
}
interface SimplifiedPolicy {
  policy: {
    unit: 'percentage' | 'amount'
    leadDaysMin: number
    value: number
  },
  nonRefundable: boolean | undefined
}
// If nonRefundable does not exist then its implied that any cancellations greater than the max leadDaysMin are refundable
// using the leadDaysMax as the cutoff
const generateRefundablePolicy = (cancellationPolicies: Array<App.RoomRateCancellationPolicy>): SimplifiedPolicy | null => {
  if (!cancellationPolicies || cancellationPolicies.length === 0) return null

  const policyWithMaxLeadDaysMin = cancellationPolicies.reduce((maxPolicy, currentPolicy) =>
    currentPolicy.policy.leadDaysMin > maxPolicy.policy.leadDaysMin ? currentPolicy : maxPolicy, cancellationPolicies[0])
  if (!policyWithMaxLeadDaysMin || policyWithMaxLeadDaysMin?.nonRefundable) return null
  return {
    policy: {
      unit: 'percentage',
      leadDaysMin: policyWithMaxLeadDaysMin.policy.leadDaysMax ?? 365, // we use leadDaysMax as this new refundable policy needs to start at the end of the last policy
      value: 0,
    },
    nonRefundable: false,
  }
}

const getRefundStatus = (cancellationPolicies: Array<App.RoomRateCancellationPolicy>) => {
  // Non policy displayed
  if (!cancellationPolicies || cancellationPolicies.length === 0) return null
  // If all policies are non refundable, display non refundable
  if (cancellationPolicies.every(policy => policy.nonRefundable)) return Refundable.Non

  if (generateRefundablePolicy(cancellationPolicies)) {
    return Refundable.Fully
  }

  if (cancellationPolicies.some(policy =>
    (policy.policy.unit === 'percentage' && policy.policy.value === 0) ||
    (policy.policy.unit === 'amount' && policy.policy.value === 0))) {
    return Refundable.Fully
  } else if (cancellationPolicies.some(policy =>
    (policy.policy.unit === 'percentage' && policy.policy.value > 0 && policy.policy.value < 100) ||
    (policy.policy.unit === 'amount' && policy.policy.value > 0))) {
    return Refundable.Partial
  } else {
    return Refundable.Non
  }
}

export {
  getRefundStatus,
  Refundable,
  getRefundDescriptions,
  generateRefundablePolicy,
}
