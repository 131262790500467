import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import BodyText from 'components/Luxkit/Typography/BodyText'
import BinocularsIcon from 'components/Luxkit/Icons/custom/BinocularsIcon'
import cn from 'clsx'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import HighlightBorder from 'components/Common/HighlightBorder'

const StyledHighlightBorder = styled(HighlightBorder)`
  box-shadow: inset 0 0 0 2px;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: unset;
`

const HighlightMessageBox = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(12)} ${rem(12)};
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  
  &.tertiary {
    color: ${props => props.theme.palette.neutral.default.two};
  }

  &.superSlim {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
  
  > * + * {
    margin-left: ${rem(12)};
  }
`

interface Props {
  message: string;
  hideIcon?: boolean
  info?: boolean
  isSuperSlim?: boolean
}

function HighlightMessage({ message, hideIcon, info, isSuperSlim }: Props) {
  const bodyTextVariant = 'medium'
  const bodyTextWeight = isSuperSlim ? 'normal' : 'bold'
  const showBorder = !isSuperSlim

  return <>
    <StyledHighlightBorder className={cn({ 'highlight-tertiary': info })} visible={showBorder && true }/>
    <HighlightMessageBox className={cn({
      tertiary: info,
      superSlim: isSuperSlim,
    })}>
      {info && <SolidInfoCircleIcon />}
      {!hideIcon && !info && <BinocularsIcon/>}
      <BodyText variant={bodyTextVariant} weight={bodyTextWeight}>{message}</BodyText>
    </HighlightMessageBox>
  </>
}

export default HighlightMessage
