import { useEffect, useCallback, useRef, useContext, useState } from 'react'
import config from 'constants/config'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import useQueryParams from 'hooks/useQueryParams'
import { jwtDecode } from 'jwt-decode'
import { GSILoadComplete, loginGoogle } from 'actions/AuthActions'
import { fireInteractionEvent } from 'api/googleTagManager'
import noop from 'lib/function/noop'
import useResponsiveSelector from 'hooks/useResponsiveSelector'
import useWhiteLabelAppConfig from 'hooks/useWhiteLabelAppConfig'
import MasterModalContext from 'contexts/MasterModalContext'

const delayValue = 20000

interface Props {
  isLoggedIn: boolean;
  onPromptOpen?: () => void;
  onPromptClose?: () => void;
  disableTapLogin: boolean;
}

interface GoogleToken {
  email: string;
  family_name: string;
  given_name: string;
  sub: string;
}

interface GoogleInitializeData {
  client_id: string;
  auto_select?: boolean;
  callback?: (response: unknown) => void;
  ux_mode?: string;
  login_uri?: string;
}

/** https://developers.google.com/identity/gsi/web/reference/js-reference#PromptMomentNotification */
interface PromptMomentNotification {
  isDisplayMoment: () => boolean;
  isDisplayed: () => boolean;
  isNotDisplayed: () => boolean;
  getNotDisplayedReason: () => 'browser_not_supported' | 'invalid_client' | 'missing_client_id' | 'opt_out_or_no_session' | 'secure_http_required' | 'suppressed_by_user' | 'unregistered_origin' | 'unknown_reason';
  isSkippedMoment: () => boolean;
  getSkippedReason: () => 'auto_cancel' | 'user_cancel' | 'tap_outside' | 'issuing_failed';
  isDismissedMoment: () => boolean;
  getDismissedReason: () => 'credential_returned' | 'cancel_called' | 'flow_restarted';
  getMomentType: () => 'display' | 'skipped' | 'dismissed';
}

declare global {
  interface Window {
      onGoogleLibraryLoad: () => void;
      google: {
        accounts: {
          id: {
            cancel: () => unknown;
            disableAutoSelect: () => unknown;
            initialize: (data: GoogleInitializeData) => unknown;
            renderButton: (document: HTMLElement, props?: any) => unknown;
            prompt: (listener?: (notification: PromptMomentNotification) => void) => unknown;
          }
        }
      };
  }
}

function GoogleAuth(props: Props) {
  const {
    isLoggedIn,
    onPromptOpen = noop,
    onPromptClose = noop,
    disableTapLogin,
  } = props

  const urlSearchParams = useQueryParams()
  const { open: anyModalOpen } = useContext(MasterModalContext)

  // Use these callbacks via refs so they won't trigger the `useEffect` to re-run if they change
  const onOpenRef = useRef(onPromptOpen)
  onOpenRef.current = onPromptOpen
  const onCloseRef = useRef(onPromptClose)
  onCloseRef.current = onPromptClose

  const timeoutState = useRef<number | undefined>()
  const [hasDismissed, setHasDismissed] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const mountTime = useRef<number>(new Date().getTime())
  const isMobileSize = useResponsiveSelector({
    mobile: true,
    tablet: false,
    desktop: false,
    largeDesktop: false,
  })

  const whiteLabelAppConfig = useWhiteLabelAppConfig()

  const onGoogleSignedIn = useCallback((response) => {
    if (!response?.credential) {
      window.google.accounts.id.disableAutoSelect()
      throw new Error('Google Login error')
    }

    const googleData: GoogleToken = jwtDecode(response.credential)

    const data = {
      id_google: googleData.sub,
      email: googleData.email,
      token_id: response.credential,
      givenName: googleData.given_name,
      surname: googleData.family_name,
    }

    fireInteractionEvent({
      category: response.select_by === 'auto' ? 'google-one-tap-login' : 'google-login',
      action: 'clicked',
      label: (new Date().getTime() - mountTime.current).toString(),
    })

    dispatch(loginGoogle(data))
  }, [dispatch])

  const isLoggedInRef = useRef(isLoggedIn)
  isLoggedInRef.current = isLoggedIn

  useEffect(() => {
    if (!window.google?.accounts?.id) return
    if (disableTapLogin || anyModalOpen) {
      if (timeoutState.current) clearTimeout(timeoutState.current)
      window.google.accounts.id.cancel()
    }
  }, [anyModalOpen, disableTapLogin])

  useEffect(() => {
    const onGoogleLoad = (whiteLabelAppGoogleLoginURI = '') => {
      dispatch(GSILoadComplete())

      const params = {
        client_id: config.GOOGLE_APP_ID,
        auto_select: true,
        ...(whiteLabelAppGoogleLoginURI ? { ux_mode: 'redirect', login_uri: whiteLabelAppGoogleLoginURI } : { callback: onGoogleSignedIn }),
      }
      window.google.accounts.id.initialize(params)

      if (config.GOOGLE_ONE_TAP_LOGIN && !disableTapLogin && !anyModalOpen && !hasDismissed) {
        // Make sure OneTap does not pop up at same time as LE log in on mobile
        timeoutState.current = window.setTimeout(() => {
          if (!isLoggedInRef.current) {
            window.google.accounts.id.prompt((notification) => {
              if (notification.isDisplayed()) {
                onOpenRef.current()
              }
              if (notification.isNotDisplayed() || notification.isDismissedMoment() || notification.isSkippedMoment()) {
                onCloseRef.current()
              }
              if (notification.isSkippedMoment()) setHasDismissed(true)
            })
          }
        // delay for mobile initially and for both if it's to be shown again
        }, isMobileSize || timeoutState.current ? delayValue : 0)
      }
    }

    if (window.google?.accounts) {
      // must already be loaded by the time we've mounted, don't need to hook into load function
      onGoogleLoad(whiteLabelAppConfig.googleLoginURI)

      if (whiteLabelAppConfig.googleLoginURI) {
        const credential = urlSearchParams.get('credential') as string
        if (credential) onGoogleSignedIn({ credential })
      }
    } else {
      // not loaded yet, hook into the callback
      window.onGoogleLibraryLoad = onGoogleLoad
    }
    return () => clearTimeout(timeoutState.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onGoogleSignedIn, disableTapLogin, isMobileSize, urlSearchParams, whiteLabelAppConfig.googleLoginURI, anyModalOpen])

  return null
}

const mapState = (state: App.State) => ({
  disableTapLogin: state.utm.medium === 'cpc',
})

export default connect(mapState)(GoogleAuth)
