import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import moment from 'moment'

import { connect } from 'react-redux'
import { encodeOfferIds } from 'lib/search/searchUtils'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { Location } from 'history'
import { queryKeySelectedOfferIds } from 'constants/url'
import { setSearchParamValue } from 'lib/url/searchUrlUtils'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import OfferTilePropertyHeading from './TileSections/OfferTilePropertyHeading'
import { mediaQueryDown, mediaQueryUp } from 'components/utils/breakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import OfferTilePricing from '../Support/OfferTilePricing'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TextButton from 'components/Luxkit/Button/TextButton'
import OfferTileLoggedInButtons from '../Support/OfferTileLoggedInButtons'
import WalledContent from 'components/Common/WalledContent'
import { EmptyObject } from 'lib/object/objectUtils'

const Container = styled.div`
  display: grid;
  grid-template: "image content" auto / ${rem(80)} minmax(0, 1fr);
  border: 2px solid ${props => props.theme.palette.highlight.secondary.normalForeground};

  ${mediaQueryUp.tablet} {
    grid-template: "image content" auto / ${rem(180)} minmax(0, 1fr);
  }
`

const ContentArea = styled.div`
  grid-area: content;
  padding: ${rem(12)};
  display: grid;
  gap: ${rem(8)};
  width: 100%;
  ${mediaQueryUp.desktop} {
    padding: ${rem(20)};
    grid-template:
      "description pricing" auto
      / minmax(0px, 1fr) ${rem(200)}
  }
  ${mediaQueryDown.tablet} {
    padding: ${rem(12)};
    grid-template:
      "description" auto
      "pricing" auto
      / minmax(0px, 1fr)
  }



`

const ImageArea = styled.div`
  grid-area: image;
  min-height: ${rem(140)};
  /* min-width: ${rem(180)};*/

  ${mediaQueryDown.desktop} {
      min-height: ${rem(80)};
  /*    min-width: ${rem(99)}; */
  }
`

const DescriptionArea = styled.div`
  grid-area: description;

`

const PricingArea = styled.div`
  grid-area: pricing;
`

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

interface MappedStateProps {
  location: Location;
  windowSearch: string;
  isSpoofed: boolean;
}

interface Props {
  offer: App.Offer | App.OfferSummary;
  passedWalledGarden: boolean;
  productClick?: (offer: App.Offer | App.OfferSummary) => void;
  offerMetaData?: App.OfferListMetaData;
  filters?: App.OfferListFilters;
  soldOut: boolean;
  bestPricePackage: App.Package;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  onImageChange?: (idx: number, image?: App.Image) => void;
  onImageLoad?: () => void;
  showInclusionUpsell: boolean;
}

function SearchOfferTileSuperSlim(props: Props & MappedStateProps) {
  const {
    offer,
    passedWalledGarden = true,
    filters = EmptyObject,
    bestPricePackage,
    eagerLoadFirstImage,
    offerUrl,
    location,
    windowSearch,
    onImageChange,
    onImageLoad,
  } = props

  const isMapPage = location.pathname.includes('/map')

  const { isPartnerProperty } = offer

  const hasDates = !!(filters?.checkIn && filters?.checkOut && filters?.rooms?.length)

  // This needs a memo because new moment objects aren't stable refs.
  const checkInDate = useMemo(() => {
    return filters?.checkIn ? moment(filters.checkIn) : undefined
  }, [filters?.checkIn])

  const locationName = [offer.locationHeading, offer.locationSubheading]
    .filter((t) => t)
    .join(', ')

  const mapSearchUrl = `/search/map?${setSearchParamValue(
    windowSearch,
    queryKeySelectedOfferIds,
    encodeOfferIds([offer]),
  )}`

  const { property } = offer

  const wall = <OfferTileLoggedInButtons
  signUpText="Sign up for free"
  align="left"
/>
  return (
    <Container className={cn({ 'is-partner-property': isPartnerProperty, mapTile: isMapPage })}>
      <ImageArea>
        <ImageCarousel
          images={offer.images}
          imageParams={imageParams}
          eagerLoadFirstImage={eagerLoadFirstImage}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
      </ImageArea>
      <ContentArea>
        <DescriptionArea>
          <Group direction="vertical" tabletGap={12}>
            <Group direction="vertical" tabletGap={8}>
              <CSSBreakpoint min="desktop">
                <OfferTileLocationSection
                location={locationName}
                mapSearchUrl={mapSearchUrl}
                shouldShowMapLink
              />
              </CSSBreakpoint>
              {property && <OfferTilePropertyHeading name={property.name} />}
            </Group>

            <CSSBreakpoint min="desktop">
              <BodyText variant="medium">{offer.name}</BodyText>
            </CSSBreakpoint>

          </Group>
        </DescriptionArea>
        <PricingArea>
          <WalledContent enforced={offer.walledGarden && !passedWalledGarden} wall={wall}>
            <Group direction="vertical" gap={8}>
              <OfferTilePricing
                offer={offer}
                hasDates={hasDates}
                checkInDate={checkInDate}
                pkg={bestPricePackage}
                offerUrl={offerUrl}
                align="start"
                filters={filters}
              />
              <CSSBreakpoint min="desktop">
                <TextButton
              kind="primary"
              size="medium"
              fit="full-width"
              nonInteractable>View Offer
                </TextButton>
              </CSSBreakpoint>
            </Group>
          </WalledContent>
        </PricingArea>

      </ContentArea>
    </Container>

  )
}

SearchOfferTileSuperSlim.defaultProps = {
  filters: EmptyObject,
}

const mapStateToProps = (state: App.State): MappedStateProps => ({
  location: state.router.location,
  windowSearch: state.router.location.search,
  isSpoofed: state.auth?.account.isSpoofed,
})

export default connect(mapStateToProps)(SearchOfferTileSuperSlim)
