import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'
import config from 'constants/config'
import createSelector from 'lib/web/createSelector'

export const selectSelectedTravellerEmployees = createSelector(
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  (state: App.State) => state.businessTraveller.selectedTravellerEmployeeIds,
  (state: App.State) => state.businessTraveller.employees,
  (
    businessAccount,
    employeeIds,
    employees,
  ): Array<BusinessTraveller.EmployeeFromMeEndpoint> => {
    if (!getBusinessTravellerSelectEnabled(businessAccount) || employees?.status !== 'success') {
      return []
    }

    // get the employee records for the employeeIds of the selected travellers
    // employees: {all: { [employeeId: string]: BusinessTraveller.Employee }}
    if (employeeIds && employeeIds.length > 0) {
      const employeeRecords = employeeIds.map(id => employees.all[id])
      if (employeeRecords.every(ele => ele === undefined)) {
        return []
      }
      return employeeRecords
    }

    return []
  },
)

export const selectIsBusinessTravellerCheckingOutOnBehalf = createSelector(
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  selectSelectedTravellerEmployees,
  (businessAccount, travellerEmployees) => {
    return getBusinessTravellerSelectEnabled(businessAccount) &&
      travellerEmployees.length > 0 &&
      travellerEmployees[0].customerId !== businessAccount?.employee?.customerId
  },
)

export const selectShowBusinessTravellerBookingCustomerLabel = createSelector(
  (state: App.State) => state.businessTraveller.currentBusinessAccount,
  (businessAccount) => {
    return config.businessTraveller.currentAccountMode === 'business' &&
    getBusinessTravellerSelectEnabled(businessAccount)
  },
)
