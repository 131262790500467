import clsx from 'clsx'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import useSearchTileInclusionUpsellBestPrice from 'hooks/useSearchTileInclusionUpsellPackage'
import offerPageURL from 'lib/offer/offerPageURL'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import moment from 'moment'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { INCLUSIONS_TITLE_BONUS } from 'constants/inclusions'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  width: 100%;
  position: relative;
  padding: ${rem(16)};
  background-color: ${props => props.theme.palette.highlight.primary.lightBackground};

  ${mediaQueryUp.tablet} {
    &.new-tile {
      flex-direction: row;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(4)};
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: ${rem(4)};

  ${mediaQueryUp.tablet} {
    flex-direction: column;
    align-items: end;
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  filters?: App.OfferListFilters;
  newTile?: boolean
}

export default function SearchOfferTileInclusionsUpsellBanner({ offer, filters, newTile = false }: Props) {
  const { upsellPackage } = useSearchTileInclusionUpsellBestPrice(offer, filters)

  const offerUrl = useMemo(() => {
    const params = buildSearchParamsFromFilters(filters, filters && upsellPackage ? {
      checkIn: moment(filters.checkIn).format(ISO_DATE_FORMAT),
      checkOut: moment(filters.checkIn).add(upsellPackage.duration, 'days').format(ISO_DATE_FORMAT),
      packageId: upsellPackage.id,
    } : undefined)

    return `${offerPageURL(offer)}?${params}`
  }, [offer, filters, upsellPackage])

  if (!upsellPackage) {
    return null
  }

  return (
    <Container className={clsx({ 'new-tile': newTile })}>
      <Content>
        <BodyText weight="semi-bold" variant="medium" format="propercase">
          {INCLUSIONS_TITLE_BONUS}
        </BodyText>
        <BodyText variant="medium">
          Special discount & inclusions available when you stay for longer.
        </BodyText>
      </Content>
      <Buttons className={clsx({ 'new-tile': newTile })}>
        <TextButton to={offerUrl} kind="secondary">
          View special offer
        </TextButton>
      </Buttons>
    </Container>
  )
}
