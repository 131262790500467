import LineMobileAndroidIcon from 'components/Luxkit/Icons/line/LineMobileAndroidIcon'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import React from 'react'

function BedbankMobileOnlyPriceLabel() {
  return <PromotionLabel variant="premium" icon={<LineMobileAndroidIcon />}>
    Mobile only price
  </PromotionLabel>
}

export default BedbankMobileOnlyPriceLabel
