import React from 'react'
import { rem } from 'polished'
import styled, { keyframes } from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { bezierCurve } from 'styles/tools/constants'
import cn from 'clsx'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import GoogleLongLogo from 'components/Common/TrustIndicators/GoogleLongLogo'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TrustPilotLogoCombined from 'components/Account/AccountAccess/TrustPilotLogoCombined'
import Group from 'components/utils/Group'

const ItemTransition = keyframes`
  0% {
    transform: translateX(0);
  }

  28% {
    transform: translateX(0);
  }

  /* Transition to slide 2 */

  33% {
    transform: translateX(-100%);
  }

  61% {
    transform: translateX(-100%);
  }
  /* Transition to slide 3 */

  66% {
    transform: translateX(-200%);
  }

  94% {
    transform: translateX(-200%);
  }

  /* Transition to slide 1 */

  100% {
    transform: translateX(0);
  }
`

const USPs = styled.div`
  display: grid;
  overflow: visible;
  animation: ${ItemTransition} 9s ${bezierCurve} infinite;
  grid-template-columns: repeat(3, 100%);
  width: 100%;

  ${mediaQueryUp.tablet} {
    animation: none;
    grid-template-columns: ${rem(180)} ${rem(400)};
    gap: ${rem(8)} ${rem(0)};

    grid-template-areas:
      "atol atol"
      "google trustpilot";
    grid-template-columns: unset;
    gap: ${rem(8)};

    .atol-logo {
      grid-area: atol;
      display: flex;
      justify-content: center;
    }
    .trustpilot-logo {
      grid-area: trustpilot;
    }
    .google-logo {
      grid-area: google;
    }

    &.is-sign-up-modal {
      grid-template-columns: repeat(3, auto);
      grid-template-areas:
      "atol google trustpilot";
      grid-gap: ${rem(16)};
    }
  }
`

const BubbleTransition = keyframes`
  0% {
    width: ${rem(16)};
  }

  28% {
    width: ${rem(16)};
  }

  33% {
    width: ${rem(8)};
  }

  94% {
    width: ${rem(8)};
  }

  100% {
    width: ${rem(16)};
  }
`

const BubbleProgressTransition = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }

  /* Slide across the bubble */

  28% {
    transform: translateX(0%);
    opacity: 1;
  }

  /* Fade out as we change slides */

  33% {
    transform: translateX(0%);
    opacity: 0;
  }

  /* Wait for our turn again */

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`

const CarouselBubbles = styled.div`
  display: flex;
  justify-content: center;
  gap: ${rem(4)};

  ${mediaQueryUp.tablet} {
    display: none;
  }

  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const CarouselBubble = styled.div`
  position: relative;
  height: ${rem(8)};
  width: ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  animation: ${BubbleTransition} 9s infinite;
  border: 1px solid ${props => props.theme.palette.neutral.default.one};
  overflow: hidden;
  transform: translateZ(0);

  &:nth-child(2) {
    animation-delay: 3s;

    &::after {
      animation-delay: 3s;
    }
  }

  &:nth-child(3) {
    animation-delay: 6s;

    &::after {
      animation-delay: 6s;
    }
  }


  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-color: ${props => props.theme.palette.neutral.default.one};
    animation: ${BubbleProgressTransition} 9s infinite;
  }
`

const AnimationContainer = styled.div`
  overflow: hidden;
`

export function TrustIndicatorUK() {
  return (
    <AnimationContainer>
      <USPs className={cn('is-sign-up-modal')}>
        <Group className="atol-logo" direction="horizontal" gap={4} verticalAlign="center" horizontalAlign="center">
          <img src={getSVGImageUrl('984lj4elpskyg828z90qb')} width={20} height={20} alt=""/>
          <BodyTextBlock colour="neutral-two" weight="bold" variant="medium" wrap="no-wrap">ATOL protected</BodyTextBlock>
        </Group>
        <Group className="google-logo" direction="horizontal" gap={4} verticalAlign="center" horizontalAlign="center">
          <GoogleLongLogo width="54" height="18"/>
          <BodyTextBlock colour="neutral-two" variant="medium" weight="bold">4.7/5</BodyTextBlock>
        </Group>
        <Group className="trustpilot-logo" direction="horizontal" gap={4} verticalAlign="center" horizontalAlign="center">
          <TrustPilotLogoCombined />
          <BodyTextBlock colour="neutral-two" weight="bold" variant="medium" wrap="no-wrap">4.8/5</BodyTextBlock>
        </Group>
      </USPs>
      <CarouselBubbles>
        <CarouselBubble />
        <CarouselBubble />
        <CarouselBubble />
      </CarouselBubbles>
    </AnimationContainer>
  )
}
