import { OFFER_TYPE_TOUR, OFFER_TYPE_HOTEL } from 'constants/offer'
import { isBedbank, isCruiseOffer, isTourV2Offer } from 'lib/offer/offerTypes'

interface LabelElement {
  mainLabel: string;
  subLabel?: string;
}

export function getConfidenceLabels(offer: App.Offer): Array<string> {
  const labels: Array<string> = []
  if (!offer) return labels

  if (offer.type === OFFER_TYPE_HOTEL && !!offer.daysBeforeCheckInChangesDisallowed) {
    labels.push('Free date changes')
  }

  labels.push('Curated by travel experts')
  return labels
}

export function getLabels(
  offer: App.Offer | App.BedbankOffer | Tours.TourV2Offer | App.CruiseOffer,
  variationId?: string,
): LabelElement {
  if (!offer) return { mainLabel: '' }

  if (isBedbank(offer) || offer.type === OFFER_TYPE_TOUR) {
    return { mainLabel: offer.name }
  }

  if (isTourV2Offer(offer)) {
    return { mainLabel: variationId && offer.variations[variationId] ? offer.variations[variationId].name : offer.name }
  }

  if (isCruiseOffer(offer)) {
    return { mainLabel: offer.name }
  }

  else {
    return { mainLabel: offer.property?.name ?? '', subLabel: offer.name }
  }
}
