export const SET_CURRENT_TRIP_ID = 'tripPlanner/setCurrentTripId'
export const CLEAR_CURRENT_TRIP_ID = 'tripPlanner/clearCurrentTripId'
export const SET_CURRENT_SECTION_ID = 'tripPlanner/setCurrentSectionId'
export const CLEAR_CURRENT_SECTION_ID = 'tripPlanner/clearCurrentSectionId'
export const SET_TRIP_ITEM_HAS_JUST_BEEN_ADDED =
  'tripPlanner/setTripItemHasJustBeenAdded'
export const SET_FIRST_TRIP_ITEM_HAS_JUST_BEEN_ADDED =
  'tripPlanner/setFirstTripItemHasJustBeenAdded'
export const CLEAR_FIRST_TRIP_ITEM_HAS_JUST_BEEN_ADDED =
  'tripPlanner/clearFirstTripItemHasJustBeenAdded'
export const TOGGLE_SUB_FILTER = 'tripPlanner/toggleSubFilter'
export const CLEAR_SUB_FILTERS = 'tripPlanner/clearSubFilters'
export const SET_SUB_FILTERS = 'tripPlanner/addSubFilters'
