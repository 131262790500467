/** This is a generic search tile which are used on
 *  Last Minute and Tactical Always On as the designs are same
 *  This tile will first check whether (1) the search performed are property search and (2) the result has been soldout
 *  Then it will render either the (1) Expanded or (2) Condensed search results.
 * */

import React, { useMemo, useEffect, useContext, useCallback, useState } from 'react'
import SearchOfferTileCondensed from './SearchOfferTileCondensed'
import styled from 'styled-components'
import { sortBy } from 'lib/array/arrayUtils'
import { OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import Pane from 'components/Common/Pane'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'
import cn from 'clsx'

import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { fetchBestPriceForOffer } from 'actions/OfferActions'
import useImpressionHandler from 'hooks/useImpressionHandler'

import { buildSearchParamsKey } from 'lib/search/searchUtils'
import { hasPassedWalledGarden } from 'selectors/accountSelectors'
import SearchOfferTileLoadingSkeleton from './SearchOfferTileLoadingSkeleton'
import SearchOfferTileExpanded from './SearchOfferTileExpanded'
import { isSpoofed } from 'selectors/featuresSelectors'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import useSearchTileInclusionUpsellPackage from 'hooks/useSearchTileInclusionUpsellPackage'
import { logNewRelic } from 'services/newRelic'
import useOffer from 'hooks/Offers/useOffer'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import SearchOfferTileSuperSlim from './SearchOfferTileSuperSlim'
import { rem } from 'polished'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import useShouldHighlight from 'hooks/OfferPage/useShouldHighlightTile'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES } from '../constants'

const Root = styled(Pane)`
  position: relative;
  &.show-high-light {
    margin-left: ${rem(12)};
    margin-right: ${rem(12)};
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  offerMetaData?: App.OfferListMetaData;
  bestPrices?: {
    [key: string]: App.OfferMaybeAvailableRate;
  };
  pricesErrors?: { [key: string]: any };
  passedWalledGarden: boolean;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  isSpoofed?: boolean;
  currentRegion?: string;
  onImageChange?: (idx: number, image?: App.Image) => void
}

const bookablePropertyResultMessage = {
  [OFFER_TYPE_LAST_MINUTE]: 'We found a last minute deal for your hotel. Book now before it ends!',
  [OFFER_TYPE_ALWAYS_ON]: 'We found a great deal for your hotel. Book now to secure your room!',
}

const bookablePropertyResultChooseDatesMessage = {
  [OFFER_TYPE_LAST_MINUTE]: 'We found a last minute deal for your hotel. Choose dates and book now before it ends!',
  [OFFER_TYPE_ALWAYS_ON]: 'We found a great deal for your hotel. Choose dates and book now to secure your room!',
}

function SearchOfferTile(props: Props) {
  const {
    pricesErrors,
    bestPrices,
    filters,
    passedWalledGarden,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    isSpoofed,
    currentRegion,
    onImageChange,
  } = props

  const offerId = props.offer.id
  const dispatch = useAppDispatch()

  const metaData = useOfferMetaData(offerId, filters)
  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? {}, offerId })
  const isSearchAvailable = directSearchPrices && metaData?.available
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const hasDates = !!(checkIn && checkOut)
  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])
  const impressionRef = useImpressionHandler(offerId)
  const bestPrice = bestPrices?.[searchKey]
  const bestPriceError = pricesErrors?.[searchKey]
  const useBestPrice = (passedWalledGarden || !props.offer.walledGarden) && !!(checkIn && checkOut && filters?.rooms)
  const available = bestPrice?.available
  const bestPriceRate = available ? bestPrice.rate : undefined
  const fetchingPrice = useBestPrice && !directSearchPrices && (!bestPrice && !bestPriceError)
  const soldOut = useBestPrice && !!bestPrice && !available

  const [offer = props.offer, fetchingOffer] = useOffer<App.Offer>(offerId, {
    requireSummaryOnly: !useBestPrice,
  })
  const [imageLoaded, setImageLoaded] = useState(false)

  const [showSpoofHighlight, spoofMessage] = useMemo(() => {
    let show = false
    if (offer.visibilitySchedules && isSpoofed) {
      Object.entries(offer.visibilitySchedules).forEach(([schedule, value]) => {
        if (!scheduleIsCurrent(value)) {
          if (schedule === 'AU' && currentRegion === 'AU') {
            show = true
          } else if (schedule !== 'AU' && currentRegion !== 'AU') {
            show = true
          }
        }
      })
    }
    return [show, 'This hotel is only visible to staff and available for purchase.']
  }, [currentRegion, isSpoofed, offer.visibilitySchedules])

  const { upsellPackage } =
    useSearchTileInclusionUpsellPackage(offer, filters)

  const showHighlight = useShouldHighlight({ offer, filters })
  let highlightMessage = ''
  let tileType: 'loading' | 'expanded' | 'condensed' | 'superSlim'

  if (useBestPrice) {
    if (isSearchAvailable) {
      // search pricing is available, and the offer is available
      tileType = 'expanded'
      highlightMessage = bookablePropertyResultMessage[offer.type]
    } else if (directSearchPrices && !metaData?.available) {
      // search has found the offer to be unavailable
      tileType = 'condensed'
      highlightMessage = HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
    } else if (fetchingPrice) {
      tileType = 'loading'
    } else if (available && !bestPriceError) {
      tileType = 'expanded'
      highlightMessage = bookablePropertyResultMessage[offer.type]
    } else {
      tileType = 'condensed'
      highlightMessage = HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
    }
  } else {
    tileType = 'expanded'
    highlightMessage = passedWalledGarden ?
      bookablePropertyResultChooseDatesMessage[offer.type] :
      bookablePropertyResultMessage[offer.type]
  }

  if (showSpoofHighlight) {
    highlightMessage = spoofMessage
    tileType = 'expanded'
  }

  const bestPricePackage = useMemo(() => {
    if (directSearchPrices) {
      // use search pricing package id by default
      const pkgId = directSearchPrices.lowestPricePackageId ?? offer.lowestPricePackage?.id
      const duration = directSearchPrices.duration ?? offer.lowestPricePackage?.duration
      if (!pkgId || !duration || !bestPriceRate?.roomRateId) {
        return offer.lowestPricePackage
      }

      const pkg = offer.packages.find(pkg => pkg.uniqueKey === getPackageUniqueKey(pkgId, duration, bestPriceRate.roomRateId))
      return pkg ?? offer.lowestPricePackage
    }

    if (!useBestPrice || bestPriceError) {
      let bpPackage

      if (offer.hasTactical) {
        const allPackagesFilteredByLowestPrice = (offer.packages ?? []).filter(pkg => pkg.duration === offer.lowestPricePackage?.duration)
        const packagedViews = allPackagesFilteredByLowestPrice.filter(pkg => pkg.roomRate?.isPackaged)

        for (const view of packagedViews) {
          if (view.price > 0) {
            const indexPackageViewByDuration = allPackagesFilteredByLowestPrice.findIndex(x => x.roomType?.id === view.roomType?.id && x.roomRate?.packagedRatePlanId === view.roomRate?.ratePlanId)
            if (indexPackageViewByDuration >= 0) {
              allPackagesFilteredByLowestPrice[indexPackageViewByDuration] = view
            } else {
              allPackagesFilteredByLowestPrice.push(view)
            }
          }
        }

        bpPackage = sortBy(allPackagesFilteredByLowestPrice.filter(pkg => pkg.hasTactical && pkg.price > 0), p => p.price, 'asc')[0]
      }

      return bpPackage || offer.lowestPricePackage
    }

    if (bestPriceRate && !fetchingOffer) {
      const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestPriceRate.packageUniqueKey)
      if (!bestPricePkg) {
        logNewRelic('Missing best price package from offer', {
          offerId: offer.id,
          packages: offer.packages.map(pkg => pkg.uniqueKey),
          bestPriceRate,
        })
      }
      return bestPricePkg ?? offer.lowestPricePackage
    }
  }, [offer, bestPriceRate, useBestPrice, bestPriceError, fetchingOffer, directSearchPrices])

  useEffect(() => {
    const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))
    if (useBestPrice && !occupanciesInvalid && !isSearchAvailable) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: filters.rooms ?? [],
      }))
    }
  }, [checkIn, checkOut, dispatch, filters, offer, useBestPrice, isSearchAvailable])

  useOfferSoldOutPushDown(offer.id, filters, soldOut)

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingPrice))) {
      dispatchOfferListEvent({
        type: OfferListEvents.offerReady,
        available: !(offer.isSoldOut || tileType == 'condensed'),
      })
    }
  }, [fetchingPrice, hasDates, imageLoaded, offer.isSoldOut, dispatchOfferListEvent, tileType])

  // GHA Super Slim Offer Tile A/B Test: https://aussiecommerce.atlassian.net/browse/GR-3177
  // always show the super slim tile if the offer is highlighted
  const isSuperSlimOfferTileAbTestEnabled = !!useOptimizelyExperiment(OptimizelyExperiments.superSlimTilesForOfferListPage, showHighlight)
  const isMobileScreen = useIsMobileScreen()
  if (isSuperSlimOfferTileAbTestEnabled && tileType === 'expanded' && showHighlight) {
    tileType = 'superSlim'
  }

  if (tileType === 'loading') {
    return <SearchOfferTileLoadingSkeleton />
  }

  if (offer.isSoldOut && showHighlight) {
    tileType = 'condensed'
    highlightMessage = HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
  }

  return <Root
  type={showHighlight ? undefined : 'clean'}
  ref={impressionRef}
  className={cn({ 'show-high-light': isSuperSlimOfferTileAbTestEnabled && isMobileScreen })}
  >
    {showHighlight && tileType != 'superSlim' && <HighlightMessage info={showSpoofHighlight}
      message={highlightMessage}
      hideIcon={!!isSuperSlimOfferTileAbTestEnabled}
      isSuperSlim={isSuperSlimOfferTileAbTestEnabled}
    />}
    {tileType === 'superSlim' &&
      <SearchOfferTileSuperSlim
        offer={offer}
        offerMetaData={offerMetaData}
        filters={filters}
        soldOut={soldOut}
        bestPricePackage={bestPricePackage}
        passedWalledGarden={passedWalledGarden}
        offerUrl={offerUrl}
        offerLinkIncludesFilters={offerLinkIncludesFilters}
        showInclusionUpsell={!!upsellPackage}
        onImageLoad={handleImageLoaded}
      />
    }
    {tileType === 'expanded' && <SearchOfferTileExpanded
      offer={offer}
      offerMetaData={offerMetaData}
      filters={filters}
      bestPrice={bestPriceRate!}
      soldOut={soldOut}
      bestPricePackage={bestPricePackage}
      passedWalledGarden={passedWalledGarden}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
      offerLinkIncludesFilters={offerLinkIncludesFilters}
      onImageChange={onImageChange}
      showInclusionUpsell={!!upsellPackage}
      onImageLoad={handleImageLoaded}
    />}
    {tileType === 'condensed' && <SearchOfferTileCondensed
      offer={offer}
      filters={filters}
      eagerLoadFirstImage={eagerLoadFirstImage}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
  </Root>
}

SearchOfferTile.defaultProps = {
  filters: {},
  bestPrices: {},
  pricesErrors: {},
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  const bestPrices = ownProps?.offer?.id ? state.offer.offerBestPrices[ownProps.offer.id] : undefined
  const pricesErrors = ownProps?.offer?.id ? state.offer.offerPricesErrors[ownProps.offer.id] : undefined

  return {
    bestPrices,
    pricesErrors,
    passedWalledGarden: hasPassedWalledGarden(state),
    isSpoofed: isSpoofed(state),
    currentRegion: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(SearchOfferTile)
