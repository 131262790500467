export const UPDATE_INSURANCE = 'cart/UPDATE_INSURANCE'

export const ORDER_STATUS_CANCELLED = 'cancelled'

export const ITEM_TYPE_OFFER = 'offer'
export const ITEM_TYPE_GIFT_CARD = 'gift_card'
export const ITEM_TYPE_BEDBANK = 'bedbank'
export const ITEM_TYPE_ADDON = 'addon'
export const ITEM_TYPE_INSURANCE = 'insurance'
export const ITEM_TYPE_EXPERIENCE = 'experience'
export const ITEM_TYPE_BNBL_EXPERIENCE = 'experience_reservation'
export const ITEM_TYPE_BOOKING_PROTECTION = 'booking_protection'

export const ORDER_STATUS_PENDING = 'pending'
export const ORDER_STATUS_COMPLETED = 'completed'
export const ORDER_STATUS_AWAITING_PAYMENT = 'awaiting_payment'
export const ORDER_STATUS_PAYMENTS_CREATED = 'payments_created'
export const ORDER_STATUS_PROCESSING = 'processing'

export const ITEM_STATUS_CREATED = 'created'
export const ITEM_STATUS_COMPLETED = 'completed'
export const ITEM_STATUS_CANCELLED = 'cancelled'
export const ITEM_STATUS_PENDING = 'pending'
export const ITEM_STATUS_AWAITING_DATES = 'awaiting_dates'
export const ITEM_STATUS_FAILED = 'failed'
export const PAYMENT_PLAN_COMPONENT_STATUS_PENDING = 'pending'
export const PAYMENT_PLAN_COMPONENT_STATUS_COMPLETED = 'completed'
