const videoData = data => ({
  videoDealId: data.offerId,
  currentTime: data.currentTime,
  duration: data.duration,
  immersive: data.immersive,
  fullScreen: data.fullScreen,
  muted: data.muted,
  videoId: data.videoId,
  playSource: data.playSource,
})

export const dealVideoPlayed = action => {
  if (action.data.offerId) {
    return {
      hitType: 'deal-video-played',
      videoDetails: videoData(action.data),
    }
  }
}

export const dealVideoPaused = action => {
  if (action.data.offerId) {
    return {
      hitType: 'deal-video-paused',
      videoDetails: videoData(action.data),
    }
  }
}
