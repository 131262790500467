import React, { useCallback, useContext, useMemo } from 'react'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LineBathIcon from 'components/Luxkit/Icons/line/LineBathIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import TextLink from 'components/Luxkit/TextLink'
import VillasPageAmenitiesModalContent from 'components/OfferPage/Villas/VillasPageAmenitiesModalContent'
import ModalContext from 'contexts/ModalContext'
import getOfferFeatureIcon from 'lib/offer/getOfferFeatureIcon'
import { getMaxGuestCount } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { rem } from 'polished'
import styled from 'styled-components'
import { take } from 'lib/array/arrayUtils'
import {
  getOrderedAmenitiesFromPackage,
} from 'lib/homesAndVillas/getAmenityPriority'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: ${rem(12)};
  padding: ${rem(8)} 0;
`

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary;
  offerUrl: string;
  totalInclusions?: number;
}

function VillaCardInclusions(props: Props) {
  const {
    offer,
    offerUrl,
    totalInclusions = 4,
  } = props

  const { amenities, orderedAmenities } = useMemo(() => getOrderedAmenitiesFromPackage(offer.lowestPricePackage), [offer])
  const numberOfBedrooms = offer.property.bedrooms
  const numberOfBathrooms = offer.property.bathrooms
  const capacity = useMemo((): number => getMaxGuestCount(offer.lowestPricePackage?.roomRate?.capacities), [offer.lowestPricePackage?.roomRate?.capacities])

  const showModal = useContext(ModalContext)
  const openAmenitiesModal = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    showModal(
      <Modal title="Amenities" primaryActionTo={offerUrl} primaryActionText="View offer">
        <VillasPageAmenitiesModalContent amenities={amenities} />
      </Modal>,
    )
  }, [showModal, offerUrl, amenities])

  // numberOfBedrooms, numberOfBathrooms and capacity are already shown, so we want the other amenities
  // NOTE: We're taking only 3 amenities because we show at most 6 on hero tile
  const otherAmenities = useMemo(() => take(orderedAmenities, totalInclusions - 3).map((amenity => ({ Icon: getOfferFeatureIcon(amenity.symbol), id: amenity.id, name: amenity.name }))), [totalInclusions, orderedAmenities])

  return (<VerticalSpacer gap={8}>
    <GridContainer>
      {!!capacity && <BodyTextBlock variant="medium" colour="neutral-two" startIcon={<LineUsersAltIcon/>}>
        {pluralizeToString('guest', capacity)}
      </BodyTextBlock>}
      {!!numberOfBedrooms && <BodyTextBlock variant="medium" colour="neutral-two" startIcon={<LineBedIcon/>}>
        {pluralizeToString('bedroom', numberOfBedrooms)}
      </BodyTextBlock>}
      {!!numberOfBathrooms && <BodyTextBlock variant="medium" colour="neutral-two" startIcon={<LineBathIcon/>}>
        {pluralizeToString('bathroom', numberOfBathrooms)}
      </BodyTextBlock>}
      {otherAmenities.map((amenity) => <BodyTextBlock
        key={amenity.id}
        variant="medium"
        colour="neutral-two"
        startIcon={<amenity.Icon />}
      >
        {amenity.name}
      </BodyTextBlock>)}
    </GridContainer>
    {amenities.length > 1 && <TextLink size="medium" onClick={openAmenitiesModal}>
      Show all {amenities.length} amenities
    </TextLink>}
  </VerticalSpacer>)
}

export default VillaCardInclusions
