import { arrayToObject } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'

const getUsersPartnershipForGeo = createSelector(
  (state: App.State) => state.geo.partnership,
  (state: App.State) => state.auth.account.partnerships,
  (partnership, accountPartnerships) => {
    if (partnership) {
      return (accountPartnerships?.[partnership.prefix])
    }
  },
)

export const getPartnershipFields = createSelector(
  (state: App.State) => getUsersPartnershipForGeo(state),
  (state: App.State) => state.geo.partnership?.userFields,
  (accountPartnerships, partnershipAccountFields) => {
    if (accountPartnerships) {
      return arrayToObject<App.UserField, any>(partnershipAccountFields,
        field => field.name,
        field => accountPartnerships[field.name] || '',
      )
    }
    return {}
  },
)

export const getPartnershipPrefixUpperCase = createSelector(
  (state: App.State) => state.geo.partnership,
  (partnershipConfig) => {
    if (partnershipConfig?.prefix) {
      return partnershipConfig.prefix.toUpperCase()
    }
    return ''
  },
)

export const alreadyLinked = createSelector(
  (state: App.State) => getUsersPartnershipForGeo(state),
  (accountPartnerships) => !!(accountPartnerships?.account_id),
)
