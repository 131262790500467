import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import List from 'components/Common/List'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BedbankCancellationPolicy from './BedbankCancellationPolicy'
import BedbankOfferInclusions from './BedbankOfferInclusions'

const StyledList = styled(List)`
  margin-bottom: 0;
  margin-top: 0;

  /** Fix for extra line on certain resolutions */

  li {
    text-indent: ${rem(-4)}
  }
`

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  rate: App.BedbankRate | App.OrderBedbankRate;
  promotion?: App.BedbankPromotion;
  showNonCancellation: boolean;
  isConfirmation?: boolean;
  className?: string;
  limitNumberOfFacilities?: number;
}

function BedbankRoomList(props: Props) {
  const { rate, className, isConfirmation, showNonCancellation, offer, limitNumberOfFacilities, promotion } = props

  const mergedFacilities = rate.facilities.slice(0, limitNumberOfFacilities ?? 30).concat(rate.promotions)

  if (promotion) {
    return <BedbankOfferInclusions
      content={promotion.rateInclusionsLong}
      inclusions={promotion.inclusions}
      maxHeight={180}
    />
  }

  return <>
    {((rate.refundable && !isConfirmation) || (!rate.refundable && showNonCancellation)) &&
      <BedbankCancellationPolicy
      rate={rate}
      offer={offer}
      isConfirmation={isConfirmation}
      showNonCancellation={showNonCancellation}
      />
    }
    <StyledList className={className}>
      {mergedFacilities.map((facility) =>
        <BodyText variant="medium" key={facility} as="li">{facility}</BodyText>)
      }
    </StyledList>
  </>
}

export default BedbankRoomList
