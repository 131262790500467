import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS, RESET_INSURANCE,
  UPDATE_INSURANCE_PAYMENT_ITEM,
} from 'actions/actionConstants'
import {
  FETCH_CHECKOUT_INSURANCE_QUOTES,
  FETCH_INSURANCE_PRODUCTS,
  FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID,
  FETCH_INSURANCE_QUOTES,
  FETCH_INSURANCE_UPGRADE_QUOTES,
  UPGRADE_INSURANCE,
} from 'actions/apiActionConstants'
import uuidV4 from 'lib/string/uuidV4Utils'
import { ApiActionFailure, ApiActionRequest, ApiActionSuccess } from 'middlewares/apiMiddleware'

function extractError(error: any) {
  if (typeof error === 'string') {
    return error
  }

  const { errors, message } = error
  if (Array.isArray(errors) && errors.length > 0 && errors[0].message) {
    return errors[0].message
  } else {
    return message
  }
}

const initialState: App.InsuranceState = {
  fetchingQuotes: false,
  fetchingProducts: false,
  productsInitialised: false,
  fetchProductsError: undefined,
  fetchQuotesError: undefined,
  triedFetchingQuotes: false,
  products: [],
  quotes: [],
  upgrade: undefined,
  payment: undefined,
}

const apiRequests = reducerSwitch<App.InsuranceState>({
  [FETCH_CHECKOUT_INSURANCE_QUOTES]: (state, action: ApiActionRequest<{ productIds: Array<string>}>) => ({
    fetchingQuotes: true,
    fetchQuotesError: undefined,
    quotes: state.quotes.filter(quote => !action.productIds.includes(quote.productId)),
    triedFetchingQuotes: true,
  }),
  [FETCH_INSURANCE_QUOTES]: () => ({
    fetchingQuotes: true,
    fetchQuotesError: undefined,
    quotes: [],
  }),
  [FETCH_INSURANCE_PRODUCTS]: () => ({
    fetchingProducts: true,
    fetchProductsError: undefined,
    products: [],
  }),
  [FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID]: () => ({
    fetchingProducts: true,
    fetchProductsError: undefined,
  }),
  [FETCH_INSURANCE_UPGRADE_QUOTES]: () => ({
    upgrade: {
      fetchingQuote: true,
      quoteReady: false,
      upgrading: false,
      error: undefined,
    },
  }),
  [UPGRADE_INSURANCE]: (state) => ({
    upgrade: {
      ...state.upgrade,
      upgrading: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.InsuranceState>({
  [FETCH_CHECKOUT_INSURANCE_QUOTES]: (state, action: ApiActionSuccess<Array<App.InsuranceQuote>, { productIds: Array<string> }>) => {
    return {
      fetchingQuotes: false,
      quotes: [
        ...state.quotes,
        ...action.data,
      ],
    }
  },
  [FETCH_INSURANCE_QUOTES]: (state, action) => ({
    fetchingQuotes: false,
    quotes: action.data,
  }),
  [FETCH_INSURANCE_PRODUCTS]: (state, action) => ({
    fetchingProducts: false,
    productsInitialised: true,
    products: action.data,
  }),
  [FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID]: (state, action) => ({
    fetchingProducts: false,
    productsInitialised: true,
    products: action.data,
  }),
  [FETCH_INSURANCE_UPGRADE_QUOTES]: (state, action) => {
    const { updateId, totalPriceDiff, total } = action.data
    const { travellerDetails, coverAmount, startDate, endDate } = action.updates
    return {
      upgrade: {
        fetchingQuote: false,
        quoteReady: true,
      },
      payment: {
        item: {
          ...state.payment.item,
          transactionKey: uuidV4(),
          updateId,
          startDate,
          endDate,
          numberOfTravellers: travellerDetails.length,
          amount: totalPriceDiff,
          coverAmount,
          total,
        },
      },
    }
  },
  [UPGRADE_INSURANCE]: (state) => ({
    upgrade: {
      ...state.upgrade,
      upgrading: false,
    },
  }),
})

const apiFailures = reducerSwitch<App.InsuranceState>({
  [FETCH_CHECKOUT_INSURANCE_QUOTES]: (state, action: ApiActionFailure<any, { productIds: Array<string> }>) => ({
    fetchingQuotes: false,
    fetchQuotesError: extractError(action.error),
  }),
  [FETCH_INSURANCE_QUOTES]: (state, action) => ({
    fetchingQuotes: false,
    fetchQuotesError: extractError(action.error),
  }),
  [FETCH_INSURANCE_PRODUCTS]: (state, action) => ({
    fetchingProducts: false,
    fetchProductsError: extractError(action.error),
  }),
  [FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID]: (state, action) => ({
    fetchingProducts: false,
    fetchProductsError: extractError(action.error),
  }),
  [FETCH_INSURANCE_UPGRADE_QUOTES]: (state, action) => ({
    upgrade: {
      fetchingQuote: false,
      quoteReady: false,
      error: extractError(action.error),
    },
  }),
  [UPGRADE_INSURANCE]: (state, action) => ({
    upgrade: {
      ...state.upgrade,
      upgrading: false,
      error: extractError(action.error),
    },
  }),
})

const insuranceReducer = createReducer<App.InsuranceState>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [RESET_INSURANCE]: () => initialState,
  [UPDATE_INSURANCE_PAYMENT_ITEM]: (state, action) => ({
    ...state,
    payment: {
      item: action.data,
    },
  }),
})

export default insuranceReducer
