import requestUtils, { authOptions } from './requestUtils'
import { luxLoyaltyAccountMapper, luxLoyaltyProgramConfigMapper } from './mappers/luxLoyaltyMap'
import { paths } from '@luxuryescapes/contract-svc-lux-loyalty'

type EnrolLuxLoyaltyAccountPayload = paths['/api/lux-loyalty/account']['post']['parameters']['body']['payload']
type EnrolLuxLoyaltyAccountResponse = paths['/api/lux-loyalty/account']['post']['responses']['201']['content']['application/json']
type GetLuxLoyaltyAccountResponse = paths['/api/lux-loyalty/account/me']['get']['responses']['200']['content']['application/json']
type GetLuxLoyaltyConfigurationResponse = paths['/api/lux-loyalty/program-configuration']['get']['responses']['200']['content']['application/json']

type GetLuxLoyaltyAccountEligibilityResponse = paths['/api/lux-loyalty/customer/{customerId}/enrolment-tier']['get']['responses']['200']['content']['application/json']

const BASE_PATH = '/api/lux-loyalty'

export async function enrolLuxLoyaltyAccountRequest(payload: EnrolLuxLoyaltyAccountPayload, token?: string) {
  const response = await requestUtils.post<EnrolLuxLoyaltyAccountResponse, EnrolLuxLoyaltyAccountPayload>(`${BASE_PATH}/account?without_brand=1`,
    payload,
    authOptions(token))

  return luxLoyaltyAccountMapper(response.result.accountSummary)
}

export async function fetchLuxLoyaltyAccountRequest(token?: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountResponse>(`${BASE_PATH}/account/me`, authOptions(token))

  return luxLoyaltyAccountMapper(response.result.accountSummary)
}

/**
 * Endpoint to determine which tier a customer is eligible to start with for when enrolling in the program
 */
export async function fetchLuxLoyaltyAccountEligibilityRequest(customerId: string) {
  const response = await requestUtils.get<GetLuxLoyaltyAccountEligibilityResponse>(`${BASE_PATH}/customer/${customerId}/enrolment-tier`, {
    credentials: 'include',
  })

  return response.result.enrolmentTier
}

export async function fetchLuxLoyaltyProgramConfigRequest() {
  const response = await requestUtils.get<GetLuxLoyaltyConfigurationResponse>(`${BASE_PATH}/program-configuration`)
  return luxLoyaltyProgramConfigMapper(response.result)
}
