import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'

// box-shadow doesn't sit ontop of borders (and we often have borders)
// so to get around this, place a transparent element on top of the whole
// container that has the highlighted box-shadow and set it's z-index higher
// than the elements with borders
const Border = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  /*
    box-shadow takes on the 'color' if not specified
    this allows users of this component to specifiy the highlight colour!
  */
  color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  box-shadow: inset 0 0 0 3px;
  transition: box-shadow 0.2s;
  pointer-events: none;
  z-index: 1;
  border-radius: inherit;

  &.variant-small {
    box-shadow: inset 0 0 0 1px;
  }

  &:not(.visible) {
    box-shadow: inset 0 0 0 0;
  }

  &.highlight-tertiary {
    color: ${props => props.theme.palette.highlight.tertiary.normalForeground};
  }
`

interface Props {
  className?: string;
  visible?: boolean;
  variant?: 'small';
}

function HighlightBorder(props: Props) {
  const { visible, className, variant } = props

  return <Border className={cn(className, `variant-${variant}`, { visible })}/>
}

export default React.memo(HighlightBorder)
