import React, { useEffect, useState } from 'react'
import { Route, RouteComponentProps, Switch, useRouteMatch } from 'react-router'
import { connect } from 'react-redux'

import loadable from 'components/utils/lazyImport'
import { selectLoggedIn } from 'selectors/accountSelectors'

import { buildPath } from 'lib/url/routeUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { getUpcomingOrders } from 'selectors/orderSelectors'

const SupportAssistantChatWidget = loadable(() => import(/* webpackChunkName: 'SupportAssistantChatWidget' */ 'supportAssistant/components/ChatWidget/ChatWidget'), <div />)

const supportAssistantChatWidgetRoute = [
  '/',
  '/account',
  '/account/my-escapes',
]

interface Props extends RouteComponentProps<any> {
  isLoggedIn: boolean;
  orders: Array<App.Order>
}

function SupportAssistantChatWidgetRoutes(props: Props) {
  const { isLoggedIn, orders } = props
  const [showChatWidget, setShowChatWidget] = useState<boolean>(false)

  const match = useRouteMatch<any>()

  // initialize the experiment
  const supportAssistantWidgetEnabled = !!useOptimizelyExperiment(OptimizelyExperiments.supportAssistantWidgetEnabled)

  useEffect(() => {
    if (showChatWidget || !supportAssistantWidgetEnabled || !isLoggedIn) {
      return
    }

    const hotelOrBedbankOrders = orders.filter((order) => order.items.length > 0 || order.bedbankItems.length > 0)
    setShowChatWidget(hotelOrBedbankOrders.length > 0)
  }, [isLoggedIn, orders, showChatWidget, supportAssistantWidgetEnabled])

  return (<Switch>
    {showChatWidget && supportAssistantChatWidgetRoute.map((path) =>
      <Route key={path} exact path={buildPath(match, path)}>
        <SupportAssistantChatWidget />
      </Route>,
    )}
  </Switch>
  )
}

function mapStateToProps(state: App.State) {
  return {
    isLoggedIn: selectLoggedIn(state),
    orders: getUpcomingOrders(state),
  }
}

export default connect(mapStateToProps)(SupportAssistantChatWidgetRoutes)
