import React from 'react'
import FormatCurrency from 'components/Common/FormatCurrency'
import HelpPane from 'components/Common/HelpPane'
import Hr from 'components/Common/Hr'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { LUXURY_PLUS } from 'luxPlus/constants/base'

interface Props {
  offer?: App.SubscriptionOffer;
  type?: 'base' | 'comprehensive';
}

function LuxPlusSubscriptionCancellationModal(props: Props) {
  const { offer, type = 'base' } = props

  return <Modal title={`${LUXURY_PLUS.PROGRAM_NAME} cancellation policy`}>
    {type === 'base' && <>
      <BodyText variant="medium">
        If {LUXURY_PLUS.PROGRAM_NAME} doesn't meet your expectations you can cancel your auto-renewal  at any time via your account.{' '}
        All member benefits will still be accessible up until the end of your membership anniversary date.
      </BodyText>
      {!!offer?.price && <>
        <br />
        <BodyText variant="medium">
          The <FormatCurrency format="rounded" value={offer.price} /> annual fee is non-refundable.
        </BodyText>
      </>}
    </>}
    {type === 'comprehensive' &&
      <VerticalSpacer gap={20} >
        <BodyText variant="medium">
          Enjoy peace of mind knowing that the following cancellation options are available to you. You can manage your {LUXURY_PLUS.PROGRAM_NAME} membership via your account.
        </BodyText>
        <Group direction="horizontal" verticalAlign="center" gap={8} horizontalAlign="stretch">
          <BodyText variant="medium" weight="bold" wrap="no-wrap">
            Within {LUXURY_PLUS.REFUND_ELIGIBILITY_PERIOD_IN_DAYS} days of joining
          </BodyText>
          <BodyText variant="medium">
            100% refund provided you haven’t used your membership.
          </BodyText>
        </Group>
        <Hr size="md"/>
        <Group direction="horizontal" verticalAlign="center" gap={8} horizontalAlign="stretch">
          <BodyText variant="medium" weight="bold" wrap="no-wrap">
            {LUXURY_PLUS.REFUND_ELIGIBILITY_PERIOD_IN_DAYS}+ days after joining
          </BodyText>
          <BodyText variant="medium">
            Non-refundable. You can cancel your annual renewal at anytime, you will have access to all your member benefits until the membership renewal date.
          </BodyText>
        </Group>
      </VerticalSpacer>}

    <HelpPane />
  </Modal>
}

export default LuxPlusSubscriptionCancellationModal
