import ClickableLink from 'components/Common/Clickable/ClickableLink'
import Image from 'components/Common/Image/Image'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import PaletteSheet from 'components/Luxkit/PaletteSheet'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import { isString } from 'lib/string/stringUtils'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

export const CAROUSEL_CARD_WIDTH = 320

const CarouselCardContainer = styled(ClickableLink)`
  display: grid;
  grid-template-rows: ${rem(200)} 1fr;
  gap: ${rem(16)};
  height: 100%;
  position: relative;
  min-height: ${rem(520)};

  ${mediaQueryUp.desktop} {
    grid-template-rows: ${rem(280)} 1fr;
  }
`

const BookmarkContainer = styled.div`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(12)};
`

const LabelContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(8)};
  left: ${rem(12)};
`

const ImageContainer = styled(PaletteSheet)`
  position: relative;
`

interface Props {
  image: App.Image;
  location: string | JSX.Element;
  title: string;
  providerName?: string;
  to: string;
  className?: string;
  priceDetails?: JSX.Element;
  bookmarkButton?: JSX.Element;
  urgencyLabels?: JSX.Element;
  description?: string | JSX.Element;
  rating?: App.OfferRating
  'data-testid'?: string;
  onClick?: () => void;
  timeRemainingLabel?: App.OfferUrgencyLabel;
  countdownLabelVariant?: string;
}

function CarouselCardMedium(props: Props) {
  const {
    bookmarkButton,
    image,
    location,
    providerName,
    title: heading,
    priceDetails: offerPriceDetails,
    to: offerUrl,
    onClick,
    rating,
    urgencyLabels,
    className,
    description,
    'data-testid': dataTestId,
    timeRemainingLabel,
    countdownLabelVariant,
  } = props

  const isMobileDevice = useIsMobileDevice()

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return (
    <CarouselCardContainer
      data-testid={dataTestId}
      onClick={onClick}
      target={config.OPEN_NEW_TAB_OFFER_CLICK ? '_blank' : undefined}
      to={offerUrl}
      className={className}
    >
      <ImageContainer paletteType="default">
        <Image
          aspectRatio="3:2"
          image={image}
          fit="center"
          dpr={2}
          height="280"
          width="512"
          onLoad={handleImageLoaded}
        />
        <LabelContainer>
          {timeRemainingLabel && <OfferUrgencyCountdownLabel
            endDate={timeRemainingLabel.end!}
            daysToBook={countdownLabelVariant === 'variant_1' ? 7 : undefined}
            showEndsSoonLabel={countdownLabelVariant === 'variant_2'}
          />}
        </LabelContainer>
        <TripGuard>
          <BookmarkContainer>{bookmarkButton}</BookmarkContainer>
        </TripGuard>
      </ImageContainer>
      <Group direction="vertical" verticalAlign="space-between" gap={12}>
        <VerticalSpacer gap={8}>
          <Group direction="horizontal" gap={8} horizontalAlign="space-between">
            <Group direction="vertical" desktopDirection="vertical">
              {isString(location) ? <BodyText colour="neutral-one" variant="medium">{location}</BodyText> : location}
              {providerName && <BodyText colour="neutral-one" variant="medium" weight="bold">{providerName}</BodyText>}
            </Group>
            {rating && <OfferRating
              variant="xs"
              rating={rating}
              onlyNumber={!!isMobileDevice}
              inlineLabel
              hideLink
            />}
          </Group>
          <BodyText
            colour="neutral-one"
            variant="large"
            weight="bold"
            lineClamp={3}
            desktopLineClamp={2}
          >
            {heading}
          </BodyText>
          {description && isString(description) && <BodyText colour="neutral-two" variant="medium">{description}</BodyText>}
          {description && !isString(description) && description}
          {urgencyLabels}
        </VerticalSpacer>
        <div>
          {offerPriceDetails}
        </div>
      </Group>
    </CarouselCardContainer>
  )
}

export default CarouselCardMedium
