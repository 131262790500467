import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import config from 'constants/config'
import { connect } from 'react-redux'
import TextButton from 'components/Luxkit/Button/TextButton'
import { buildSuggestedDatesParamsKey, buildSuggestedDatesString } from 'lib/search/searchUtils'
import { getSuggestedDates } from 'selectors/offerSelectors'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import { pluralizeToString } from 'lib/string/pluralize'
import BedbankTwinShare from 'components/OfferList/OfferListTiles/Support/BedbankTwinShare'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import { mediaQueryUp } from '../../../utils/breakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import BedbankMarginText from 'components/OfferPage/Bedbank/BedbankMargin'
import { max } from 'lib/array/arrayUtils'
import { isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { useSearchBedbankOfferPrices } from 'hooks/Search/useSearchBedbankOfferPrices'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import LuxPlusPriceGuard from 'luxPlus/components/LuxPlusPriceGuard'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${mediaQueryUp.tablet} {
    align-self: flex-start;
  }
`

const UpsellCTAButton = styled(TextButton)`
  align-self: flex-end;
`

interface MappedStateProps {
  suggestedDates?: App.OfferSuggestedDates;
  isPerNightPricingTestEnabled: boolean;
  luxPlusLPPEnabled: boolean;
  region: string;
  currency: string;
}

interface Props {
  rate?: App.BedbankRate;
  bundleRate?: App.BedbankRate;
  rooms: Array<App.Occupants>;
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  offerUrl: string;
  promotions: Array<App.BedbankPromotion>;
  isUpsell?: boolean;
  flightPrice: number | undefined
  flightsEnabled: boolean;
  className?: string;
  allRates?: Array<App.BedbankRate>
  priceSize?: 'L' | 'M' | 'S'
  directSearchPrices?: App.OfferListMetadataPricing;
}

function BedbankOfferTilePriceDetails(props: Props & MappedStateProps) {
  const {
    rate,
    bundleRate,
    rooms,
    offer,
    filters,
    offerUrl,
    suggestedDates,
    isUpsell,
    flightPrice,
    flightsEnabled,
    promotions,
    isPerNightPricingTestEnabled,
    className,
    allRates,
    luxPlusLPPEnabled,
    directSearchPrices,
    priceSize = 'L',
  } = props

  const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut

  // Best margin %, not value (aud)
  const rateWithBestMargin = allRates && max(allRates, (rate) => rate.margin ?? Number.NEGATIVE_INFINITY)
  const margin = rateWithBestMargin?.margin
  const marginAud = rateWithBestMargin?.marginAud
  const supplier = rateWithBestMargin?.supplier
  const isSpoofed = useAppSelector(state => state.auth.account.isSpoofed)
  const showMarginInSpoofMode = useAppSelector(state => state.config.showMarginInSpoofMode)
  const displayMargin = Boolean(margin && marginAud && supplier && isSpoofed && showMarginInSpoofMode)

  const promoteAsBundle = flightsEnabled && !!flightPrice && offer.promoteAsBundle

  const baseSaleUnit = isPerNightPricingTestEnabled ? 'night' : 'total'
  const saleUnit = offer.displayPricingAsPerPerson ? 'person' : baseSaleUnit

  const {
    offerPrice,
    offerMemberPrice,
    totalPrices,
    bundleTaxesAndFees,
    bundlePropertyFees,
    propertyFees,
    taxesAndFees,
    bundlePrice,
    hotelPrice,
    bundleMemberPrice,
    hotelMemberPrice,
    discountPercentage,
    hotelNights,
    value,
    nightsToDisplay,
  } = useSearchBedbankOfferPrices({
    rate,
    offer,
    filters,
    bundleRate,
    suggestedDates,
    directSearchPrices,
    flightPrice,
    promoteAsBundle,
    isFlexibleSearch,
    rooms,
    promotions,
  })

  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  useEffect(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.pricingCalculated,
      leadPrice: offerPrice,
      duration: hotelNights ?? 0,
    })
  }, [dispatchOfferListEvent, offerPrice, hotelNights])
  return <Root className={className}>
    <PriceRowPriceCaption>
      {isFlexibleSearch && buildSuggestedDatesString(suggestedDates.checkIn, suggestedDates.checkOut)}
      {!isFlexibleSearch && `${pluralizeToString('night', nightsToDisplay)}`}
      {promoteAsBundle && ' + flights'}
      {' '}from
    </PriceRowPriceCaption>
    <LuxPlusPriceGuard
      showMemberPrice={(offerMemberPrice ?? 0) > 0 && luxPlusLPPEnabled}
      fallbackPrice={<PriceRowPrice
        size={priceSize}
        price={offerPrice}
        saleUnit={saleUnit}
      />}
    >
      <LuxPlusPriceStack
        size={priceSize}
        price={offerPrice}
        memberPrice={offerMemberPrice}
        saleUnit={saleUnit}
      />
    </LuxPlusPriceGuard>

    {offer.displayPricingAsPerPerson && <BedbankTwinShare rooms={rooms} />}
    {discountPercentage >= 5 && <PriceRowValueDiscountWithCaption
      size="M"
      originalValue={value}
      discountPercentage={discountPercentage}
    />}

    {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
      type="estimate"
      offer={offer}
      totalCost={promoteAsBundle ? totalPrices.original.flightBundlePrice : totalPrices.original.price}
      numberOfNights={hotelNights}
    />}

    <PriceRowAgentHubCommission
      size="L"
      productType={offer.productType}
      offerId={offer.id}
      offerLocations={offerLocations}
    />

    {displayMargin && <BedbankMarginText
      margin={margin!}
      marginAud={marginAud!}
      supplier={supplier!}
      type="bedbank_estimate"
    />}

    {isUpsell && <UpsellCTAButton kind="primary" fit="full-width" to={offerUrl}>
      View Offer
    </UpsellCTAButton>}

    {directSearchPrices && <OfferPriceDetailsRow
      trigger="price-row"
      triggerSize="M"
      offer={offer}
      rooms={rooms}
      propertyFees={promoteAsBundle ? bundlePropertyFees : propertyFees}
      taxesAndFees={promoteAsBundle ? bundleTaxesAndFees : taxesAndFees}
      hotelPrice={promoteAsBundle ? bundlePrice : hotelPrice}
      hotelMemberPrice={promoteAsBundle ? bundleMemberPrice : hotelMemberPrice}
      showMemberPrice={luxPlusLPPEnabled && hotelMemberPrice > 0}
      flightPrice={promoteAsBundle ? flightPrice : undefined}
      duration={hotelNights ?? 1}
      cta={
        <TextButton kind="primary" fit="flex" to={offerUrl}>
          View Offer
        </TextButton>
      }
      context="search"
    />}

    {rate && !isUpsell && hotelNights && (<OfferPriceDetailsRow
      trigger="price-row"
      triggerSize="M"
      offer={offer}
      rooms={rooms}
      propertyFees={promoteAsBundle ? bundlePropertyFees : propertyFees}
      taxesAndFees={promoteAsBundle ? bundleTaxesAndFees : taxesAndFees}
      hotelPrice={promoteAsBundle ? bundlePrice : hotelPrice}
      flightPrice={promoteAsBundle ? flightPrice : undefined}
      hotelMemberPrice={promoteAsBundle ? bundleMemberPrice : hotelMemberPrice}
      showMemberPrice={luxPlusLPPEnabled && hotelMemberPrice > 0}
      duration={hotelNights}
      cta={
        <TextButton kind="primary" fit="flex" to={offerUrl}>
          View Offer
        </TextButton>
      }
      context="search"
      hasMobilePromotion={rate.mobilePromotion}
    />)}
  </Root>
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)
  return {
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
    isPerNightPricingTestEnabled: !!getOptimizelyExperimentVariation(appState, OptimizelyExperiments.pricePerNight),
    luxPlusLPPEnabled: isLuxPlusLPPEnabled(appState),
    region: appState.geo.currentRegionCode,
    currency: appState.geo.currentCurrency,
  }
})(BedbankOfferTilePriceDetails)
