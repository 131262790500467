import { CHECKOUT_ITEM_TYPE_CRUISE } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'

export const titleGenderMap = {
  Mr: 'Male',
  Ms: 'Female',
  Mrs: 'Female',
  Miss: 'Female',
  Mstr: 'Male',
}

export function itemsAreCruise(
  items: Array<App.Checkout.AccommodationItem>,
): items is Array<App.Checkout.CruiseItem> {
  return items[0].itemType === CHECKOUT_ITEM_TYPE_CRUISE
}

export function generateCruiseCheckoutItem(
  version: 'v1' | 'v2',
  offerId: string,
  departureId: string,
  dates: { startDate: string, endDate: string },
  occupancy: App.Occupants,
  bookingId?: string,
  sessionId?: string,
  cabinCode?: string,
  componentId?: string,
): App.Checkout.CruiseItem {
  return {
    itemId: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_CRUISE,
    transactionKey: uuidV4(),
    version,
    offerId,
    bookingId,
    sessionId,
    cabinCode,
    departureId,
    occupancy,
    componentId,
    startDate: dates.startDate,
    endDate: dates.endDate,
  }
}

export function hasCruiseItemPendingSelections(
  item: App.Checkout.CruiseItem,
): boolean {
  return (
    !item.occupancy?.adults ||
    !item.bookingId ||
    !item.cabinType ||
    !item.cabinCodes?.length ||
    !item.cabinCode ||
    !item.cabinNumber ||
    !item.componentId
  )
}
