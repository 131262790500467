import FilterChip from 'components/Luxkit/Chips/FilterChip'
import Heading from 'components/Luxkit/Typography/Heading'
import { rem } from 'polished'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { getTrendingDestinationFilters } from './constants'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import GeoContext from 'contexts/geoContext'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TrendingDestinationsCarousel from './TrendingDestinationsCarousel'
import { useHotelTrendingDestinations } from 'home/hooks/useHotelTrendingDestinations'
import TextButton from 'components/Luxkit/Button/TextButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import { ANYWHERE_NAME, ANYWHERE_PLACE_ID } from 'constants/search'

const SectionContainer = styled(VerticalSpacer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

export const DEFAULT_FILTER: App.DestinationSelectedFilters = 'hotel'

interface Props {
  title?: React.ReactNode;
}

function TrendingDestinations(props: Props) {
  const { title } = props
  const [selectedFilter, setSelectedFilter] = useState<App.DestinationSelectedFilters>(DEFAULT_FILTER)
  const { currentRegionCode } = useContext(GeoContext)
  const filters = getTrendingDestinationFilters(currentRegionCode)
  const [displayTrendingDestinations, fetching] = useHotelTrendingDestinations(selectedFilter, currentRegionCode)
  const searchMapLink = `/search/map?adults=2&children=none&destinationId=${ANYWHERE_PLACE_ID}&destinationName=${ANYWHERE_NAME}`

  return (
    <SectionContainer gap={32}>
      <LayoutContainer>
        <VerticalSpacer gap={12}>
          <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="start" gap={16}>
            <Heading variant="heading2">
              {title ?? <>Immerse yourself in trending <i>destinations</i></>}
            </Heading>
            <CSSBreakpoint min="desktop">
              <TextButton kind="tertiary" size="medium" startIcon={<LineMapIcon />} to={searchMapLink} target="_blank">
                View map
              </TextButton>
            </CSSBreakpoint>
          </Group>
          <HorizontalContainerWithFadedOverflow>
            <Group direction="horizontal" gap={4}>
              {filters.map((filter) => <FilterChip
                selected={filter.id === selectedFilter}
                onClick={() => setSelectedFilter(filter.id)}
                key={filter.id}
                size="medium"
              >
                {filter.title}
              </FilterChip>)}
            </Group>
          </HorizontalContainerWithFadedOverflow>
        </VerticalSpacer>
      </LayoutContainer>
      <TrendingDestinationsCarousel fetchingDestinations={fetching} selectedFilter={selectedFilter} trendingDestinations={displayTrendingDestinations}/>
    </SectionContainer>
  )
}

export default TrendingDestinations
