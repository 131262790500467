import Image from 'components/Common/Image'
import LineBellIcon from 'components/Luxkit/Icons/line/LineBellIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import ListItemThumbnail from 'components/Luxkit/List/ListItemThumbnail'
import Group from 'components/utils/Group'
import { dateDifference } from 'lib/datetime/dateUtils'
import moment from 'moment'
import { rem } from 'polished'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

const NewNotificationContainer = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
`

const NewDot = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.highlight.secondary.normalForeground};
`

interface Props {
  notification: App.BrazeNotification;
}

function HeaderNotificationItem({ notification }: Props) {
  const subtitle = useMemo(() => {
    if (!notification.created) return
    const difference = dateDifference(new Date(), notification.created)

    if (difference.days > 1) return moment(notification.created).format('DD MMM YYYY')
    if (difference.days === 1) return 'Yesterday'
    if (difference.hours === 1) return `${difference.hours} hour ago`
    if (difference.hours > 1) return `${difference.hours} hours ago`
    if (difference.minutes === 1) return `${difference.minutes} minute ago`
    if (difference.minutes > 1) return `${difference.minutes} minutes ago`
    return 'Less than a minute ago'
  }, [notification])
  const onClick = useCallback(() => {
    import(/* webpackChunkName: "braze-sdk" */ '@braze/web-sdk').then(({ logCardClick }) => {
      logCardClick(notification, true)
    })
  }, [notification])

  useEffect(() => {
    const logImpression = () => {
      import(/* webpackChunkName: "braze-sdk" */ '@braze/web-sdk').then(({ logCardImpressions }) => {
        logCardImpressions([notification], true)
      })
    }

    logImpression()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ListItem
      size="medium"
      onClick={onClick}
      title={<><strong>{notification.title}</strong> {notification.description}</>}
      titleLineClamp={0}
      subtitle={subtitle}
      href={notification.url ?? undefined}
      target="_blank"
      startIcon={
        <Group direction="horizontal" gap={8} verticalAlign="center">
          <NewNotificationContainer>{!notification.viewed && <NewDot />}</NewNotificationContainer>
          {notification.imageUrl && <Image width={48} height={48} image={{ url: notification.imageUrl }} />}
          {!notification.imageUrl && <ListItemThumbnail kind="neutral"><LineBellIcon /></ListItemThumbnail>}
        </Group>
      }
    />
  )
}

export default HeaderNotificationItem
