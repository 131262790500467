import cn from 'clsx'
import useToggle from 'hooks/useToggle'
import { rem } from 'polished'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import noop from 'lib/function/noop'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import TextLink from 'components/Luxkit/TextLink'
import { useElementHeight } from 'hooks/useElementSize'

const LargeContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  transition: max-height 0.2s;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${rem(32)};
    background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }

  &.show-mask::after {
    opacity: 1;
  }
`

const SwitchButton = styled(TextLink)`
  margin-top: ${rem(8)};

  ${mediaQueryUp.tablet} {
    justify-self: left;
  }
`

interface Props {
  children: React.ReactNode;
  maxHeight: number;
  collapsedHeight?: number;
  showMoreText?: string;
  showLessText?: string;
  showChevron?: boolean;
  hideText?: boolean;
  open?: boolean;
  defaultOpen?: boolean;
  onToggle?: () => void;
  minimumContentHeight?: number;
}

function LargeContentToggle(props: Props) {
  const {
    defaultOpen,
    children,
    maxHeight,
    collapsedHeight = maxHeight,
    showMoreText,
    showLessText,
    hideText = false,
    showChevron = true,
    open,
    onToggle = noop,
    minimumContentHeight = 0,
  } = props
  const [showAll, toggleAll] = useToggle(defaultOpen)
  const contentElement = useRef<HTMLDivElement>(null)
  const isControlled = open !== undefined

  const onToggleClick = useCallback(() => {
    onToggle()
    if (!isControlled) {
      toggleAll()
    }
  }, [toggleAll, isControlled, onToggle])

  const contentHeight = useElementHeight(contentElement) ?? maxHeight

  const adjustedContentHeight = Math.max(minimumContentHeight, contentHeight)
  const isTaller = adjustedContentHeight > maxHeight
  const expanded = !isTaller || (isControlled ? open : showAll)

  return (<>
    <LargeContentWrapper
      className={cn({ 'show-mask': !expanded })}
      style={{ maxHeight: expanded ? adjustedContentHeight : collapsedHeight }}
    >
      <div ref={contentElement}>
        {children}
      </div>
    </LargeContentWrapper>
    {!hideText && isTaller &&
      <SwitchButton
        size="medium"
        aria-expanded={expanded}
        endIcon={showChevron ? <DropdownChevron open={expanded} /> : undefined}
        onClick={onToggleClick}
      >
        {expanded ? showLessText : showMoreText}
      </SwitchButton>
    }
  </>)
}

export default LargeContentToggle
