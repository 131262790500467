import React from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'

import ResponsiveImage from 'components/Common/ResponsiveImage'
import HeadData from 'components/Common/HeadData'
import config from 'constants/config'
import TextLink from 'components/Luxkit/TextLink'
import OhNoRefreshError from 'components/Common/ErrorBoundary/OhNoRefreshError'

const Root = styled.div`
  position: relative;
  overflow: hidden;
  color: ${props => props.theme.palette.neutral.default.eight};
  text-align: center;

  picture,
  img {
    height: calc(100vh - ${() => rem(config.header.height.lg)});
    object-fit: cover;
  }
`

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Main = styled.div`
  font-size: ${rem(140)};
`

const Secondary = styled.div`
  font-size: ${rem(30)};
`

const Links = styled.div`
  margin-top: ${rem(40)};
  font-size: ${rem(20)};
`

interface Props {
  error: any;
  className?: string;
}

function ErrorPage(props: Props) {
  const { error, className } = props
  let description
  if (error.status && error.message) {
    description = `Error ${error.status} - ${error.message}`
  } else if (error.status) {
    description = `Error ${error.status}`
  } else {
    description = 'Error - Something went wrong'
  }

  const handleBack = (event) => {
    event.preventDefault()
    window.history.back()
  }

  return <Root className={cn(`Error${error.status}Page)`, `T-Error${error.status}Page`, className)}>
    <HeadData
      title={description}
      description={description}
    />
    <ResponsiveImage
      aria-hidden="true"
      alt={description}
      brightness={-60}
      mobileAspectRatio="8:9" tabletAspectRatio="4:3" desktopAspectRatio="4:3" largeDesktopAspectRatio="16:9"
      id="404-background"
    />
    <Wrapper>
      {error.status && <>
        <Main>{error.status}</Main>
        <Secondary>{error.message || description}</Secondary>
        <Links>
          Please go <TextLink variant="ghost" onClick={handleBack}>Back</TextLink> or head <TextLink variant="ghost" to="/">Home</TextLink>
        </Links>
      </>}
      {!error.status && <OhNoRefreshError variant="light" />}
    </Wrapper>
  </Root>
}

export default ErrorPage
