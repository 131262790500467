import React, { useState, useCallback, useMemo } from 'react'
import { addMonths, endOfMonth, dateIsAfter, dateIsBefore, startOfMonth } from 'lib/datetime/dateUtils'
import DatePickerHeader from './DatePickerHeader'
import BasicCalendar from './BasicCalendar'
import DatePickerDay from './DatePickerDay'
import useMountedEffect from 'hooks/useMountedEffect'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import Group from 'components/utils/Group'

interface Props {
  value?: Date;
  defaultValue?: Date;
  min?: Date;
  max?: Date;
  className?: string;
  defaultMonth?: number;
  defaultYear?: number;
  dayRender?: (day: Date, params: { disabled: boolean, selected: boolean }) => React.ReactNode;
  onDayClick?: (day: Date) => void;
  onMonthChange?: (direction: 'forwards' | 'backwards', date: Date) => void;
  type?: 'single' | 'dual'
}

const now = new Date()
function DatePicker(props: Props) {
  const {
    value,
    defaultValue,
    min,
    max,
    className,
    defaultMonth = now.getMonth(),
    defaultYear = now.getFullYear(),
    dayRender,
    onDayClick,
    onMonthChange,
    type = 'single',
  } = props
  const [date, setDate] = useState<Date>(value ?? defaultValue ?? new Date(defaultYear, defaultMonth, 1))

  const handleOnMonthChange = useCallback((direction: 'forwards' | 'backwards') => {
    const nextDate = addMonths(date, direction === 'forwards' ? 1 : -1)
    setDate(nextDate)
    onMonthChange?.(direction, nextDate)
  // eslint-disable-next-line react-hooks/exhaustive-deps -- onMonthChange stable
  }, [date])

  useMountedEffect(() => {
    if (value) {
      // sync what month is on display as our value is set so the value is always visible (as it's set)
      setDate(startOfMonth(value))
    }
  }, [value])

  const monthStr = `${date.getMonth() + 1}`.padStart(2, '0')

  const nextMonth = useMemo(() => addMonths(date, 1), [date])

  const defaultDayRender = date => {
    const disabled = dateIsBefore(date, min) || dateIsAfter(date, max)
    const dateTimestamp = date.getTime()
    const selected = value?.getTime() === dateTimestamp

    if (dayRender) {
      return dayRender?.(date, { disabled, selected })
    } else {
      return <DatePickerDay
        key={dateTimestamp}
        selected={selected}
        date={date}
        disabled={disabled}
        onClick={onDayClick}
      />
    }
  }

  return (
    <Group
      direction="horizontal"
      gap={48}
      horizontalAlign="stretch"
      data-testid="date-picker"
      data-yearmonth={`${date.getFullYear()}-${monthStr}`}
      className={className}
    >
      <VerticalSpacer gap={20}>
        <DatePickerHeader
          currentDate={date}
          onMonthChange={handleOnMonthChange}
          disableBackwards={dateIsBefore(endOfMonth(addMonths(date, -1)), min)}
          disableForwards={dateIsAfter(startOfMonth(addMonths(date, 1)), max)}
          hideForwards={type === 'dual'}
        />
        <BasicCalendar
          month={date.getMonth()}
          year={date.getFullYear()}
          dayRender={defaultDayRender}
        />
      </VerticalSpacer>
      {type === 'dual' && <VerticalSpacer gap={20}>
        <DatePickerHeader
          currentDate={nextMonth}
          onMonthChange={handleOnMonthChange}
          disableForwards={dateIsAfter(startOfMonth(addMonths(nextMonth, 1)), max)}
          hideBackwards
        />
        <BasicCalendar
          month={nextMonth.getMonth()}
          year={nextMonth.getFullYear()}
          dayRender={defaultDayRender}
        />
      </VerticalSpacer>}
    </Group>
  )
}

export default React.memo(DatePicker)
