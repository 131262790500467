import {
  API_CALL, BEDBANK_UPDATE_PRICING_DATA,
} from './actionConstants'
import {
  FETCH_BEDBANK_OFFER, FETCH_BEDBANK_OFFER_RATES,
  FETCH_BEDBANK_OFFERS_RATES,
  FETCH_BEDBANK_OFFER_CALENDAR,
  FETCH_BEDBANK_OFFER_RATES_BULK,
} from './apiActionConstants'

import * as OfferService from 'api/offer'
import { isBedbankOfferCached } from 'selectors/bedbankSelectors'
import { AppApiAction } from './ActionTypes'
import NotFound from '../api/errors/NotFound'
import { getAccumulatedBedbankRatesById, getRatesForBedbankOffer, getRatesForBedbankOffers } from 'api/bedbankRates'
import { getCalendar } from 'api/bedbank'
import { serialiseOccupancy } from 'lib/search/searchUtils'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import { getBedbankRatesCheckInRange } from '../lib/hotels/bedbankUtils'

export function fetchBedbankOfferById(
  offerId: string,
  options?: {
    regionCode?: string,
    flightOrigin?: string,
    isSpoofed?: boolean,
  },
): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()

    if (isBedbankOfferCached(state, offerId)) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEDBANK_OFFER,
      request: async() => {
        const offer = await OfferService.getOfferById(offerId, {
          region: options?.regionCode || state.geo.currentRegionCode,
          preview: state.config.previewMode ? state.config.previewHash : undefined,
          flightOrigin: options?.flightOrigin || getDefaultAirportCode(state),
          isSpoofed: options?.isSpoofed,
        })
        if (offer.type === 'bedbank_hotel') {
          return offer
        }

        throw new NotFound(`Bedbank offer ${offerId} is not found`)
      },
      offerId,
    })
  }
}

export function convertBedbankOfferToSummary(offer: App.BedbankOffer): App.BedbankOfferSummary {
  const {
    description,
    metaDescription,
    attractions,
    facilityGroups,
    ...offerSummary
  } = offer
  return offerSummary
}

export function getBedbankRateKey(
  occupants: Array<App.Occupants>,
  checkIn: string,
  checkOut: string,
) {
  return `${checkIn}-${checkOut}-${serialiseOccupancy(occupants ?? []).join(',')}`
}

export function fetchAccumulatedRatesForBedbankOffer(
  id: string,
  rooms: Array<App.Occupants>,
  checkIn: string,
  checkOut: string,
): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()

    const filterKey = getBedbankRateKey(rooms, checkIn, checkOut)
    if (state.offer.bedbankOfferRates[id]?.[filterKey] || state.offer.offerRatesLoading[id]) {
      // already have fetched the rooms for the current filters
      return
    }

    const authState: App.AuthState = state.auth

    const requestData = {
      checkIn,
      checkOut,
      rooms,
      region: state.geo.currentRegionCode,
      id,
      timezone: state.offer.bedbankOffers[id]?.property.timezone,
      preview: state.config.previewMode ? state.config.previewHash : undefined,
      isSpoofed: authState.account.isSpoofed,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEDBANK_OFFER_RATES,
      request: () => getAccumulatedBedbankRatesById(requestData),
      offerId: id,
      filterKey,
    })
  }
}

export function fetchRatesForBedbankOffer(
  offer: App.BedbankOffer | App.BedbankOfferSummary,
  rooms: Array<App.Occupants>,
  checkIn: string,
  checkOut: string,
): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()

    const filterKey = getBedbankRateKey(rooms, checkIn, checkOut)
    if (state.offer.bedbankOfferRates[offer.id]?.[filterKey] || state.offer.offerRatesLoading[offer.id]) {
      // already have fetched the rooms for the current filters
      return
    }

    const authState: App.AuthState = state.auth

    const requestData = {
      checkIn,
      checkOut,
      rooms,
      region: state.geo.currentRegionCode,
      id: offer.id,
      timezone: offer.property.timezone,
      preview: state.config.previewMode ? state.config.previewHash : undefined,
      isSpoofed: authState.account.isSpoofed,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEDBANK_OFFER_RATES,
      request: () => getRatesForBedbankOffer(requestData),
      offerId: offer.id,
      filterKey,
    })
  }
}

export function fetchRatesRangeForBedbankOffer(
  offerId: string,
  rooms: Array<App.Occupants>,
  monthKey: string,
  duration: number,
): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()
    const checkInsRange = getBedbankRatesCheckInRange(monthKey, duration)
    if (state.offer.offerRatesLoading[offerId]) {
      return
    }

    const ratesToFetch = checkInsRange.filter(({ checkIn, checkOut }) => {
      const filterKey = getBedbankRateKey(rooms, checkIn, checkOut)
      if (state.offer.bedbankOfferRates[offerId]?.[filterKey]) {
        // already have fetched the rooms for the current filters
        return false
      }

      return true
    })

    if (!ratesToFetch.length) {
      return
    }

    const authState: App.AuthState = state.auth

    dispatch({
      type: API_CALL,
      api: FETCH_BEDBANK_OFFER_RATES_BULK,
      request: async() => {
        return await Promise.all(ratesToFetch.map(async({ checkIn, checkOut }) => {
          const filterKey = getBedbankRateKey(rooms, checkIn, checkOut)

          const requestData = {
            checkIn,
            checkOut,
            rooms,
            region: state.geo.currentRegionCode,
            preview: state.config.previewMode ? state.config.previewHash : undefined,
            isSpoofed: authState.account.isSpoofed,
          }

          const response = await getRatesForBedbankOffers([offerId], requestData)

          return {
            filterKey,
            rates: response.flatMap(r => r.rates),
          }
        }))
      },
      offerId,
    })
  }
}

export function fetchRatesForBedbankOffersById(
  offerIds: Array<string>,
  rooms: Array<App.Occupants>,
  checkIn: string,
  checkOut: string,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const filterKey = getBedbankRateKey(rooms, checkIn, checkOut)
    const missingOfferIds = offerIds.filter(id => !state.offer.bedbankOfferRates[id]?.[filterKey] && !state.offer.offerRatesLoading[id])
    if (missingOfferIds.length === 0) {
      // already have fetched the rooms for the current filters
      return
    }

    const authState: App.AuthState = state.auth

    const requestData = {
      checkIn,
      checkOut,
      rooms,
      region: state.geo.currentRegionCode,
      preview: state.config.previewMode ? state.config.previewHash : undefined,
      isSpoofed: authState.account.isSpoofed,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEDBANK_OFFERS_RATES,
      request: () => getRatesForBedbankOffers(missingOfferIds, requestData),
      offerIds: missingOfferIds,
      filterKey,
    })
  }
}

type ImplicitRoomPricing = { inclusive?: number, propertyFees?: number }
interface Pricing {
  data: Array<ImplicitRoomPricing>;
}

export function updateBedbankPricingData(
  offerId: string,
  roomId: string,
  roomRateId: string,
  isFlightBundle: boolean,
  checkIn: string,
  checkOut: string,
  rooms: Array<App.Occupants>,
  pricing: Pricing,
) {
  return {
    type: BEDBANK_UPDATE_PRICING_DATA,
    data: {
      offerId,
      roomId,
      roomRateId,
      isFlightBundle,
      bedbankRateKey: getBedbankRateKey(rooms, checkIn, checkOut),
      pricing,
    },
  }
}

export function getBedbankCalendarKey(
  region: string,
  duration: number,
  selectedBedbankRoomId?: string | undefined,
) {
  return `${region}-${duration}-${selectedBedbankRoomId ?? 'all'}`
}

export function fetchCalendarForBedbankOffer(
  id: string,
  duration: number,
  selectedBedbankRoomId?: string,
): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()
    const region = state.geo.currentRegionCode

    const filterKey = getBedbankCalendarKey(region, duration, selectedBedbankRoomId)
    if (state.offer.bedbankOfferCalendar[id]?.[filterKey] || state.offer.bedbankOfferCalendarLoading[id]) {
      // already have fetched the calendar for the current filters
      return
    }

    const requestData = {
      propertyId: id,
      region,
      nights: duration,
      roomId: selectedBedbankRoomId,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEDBANK_OFFER_CALENDAR,
      request: () => getCalendar(requestData),
      offerId: id,
      filterKey,
    })
  }
}
