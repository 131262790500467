import React from 'react'
import {
  DetailSegment,
  Main,
  MainImageSegment,
} from 'components/OfferList/OfferListTiles/SearchOfferTiles/SearchTileStyles'
import cn from 'clsx'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import styled from 'styled-components'
import { rem } from 'polished'

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`

const LeftContent = styled(Content)``

const RightContent = styled(Content)`
  justify-content: flex-end;
`

interface Props {
  className?: string;
}

function ToursSearchTileLoadingSkeleton(props: Props) {
  const { className } = props

  return (<Main type="clean" className={cn(className, { slim: true })}>
    <MainImageSegment>
      <LoadingBox floating />
    </MainImageSegment>
    <DetailSegment className={cn(className, { slim: true })}>
      <LeftContent>
        <div>
          <TextLoadingBox typography="body-medium" width="40%" />
          <TextLoadingBox typography="body-medium" width="40%" />
        </div>
        <div style={{ minWidth: '50%' }}>
          <TextLoadingBox typography="heading5" width="80%" />
          <TextLoadingBox typography="subtitle3" width="80%" />
          <TextLoadingBox typography="subtitle3" width="80%" />
        </div>
        <div>
          <TextLoadingBox typography="body-medium" width="80%"/>
          <TextLoadingBox typography="body-medium" width="80%"/>
          <TextLoadingBox typography="body-medium" width="70%"/>
        </div>
        <div>
          <TextLoadingBox typography="body-medium" width="90%" />
          <TextLoadingBox typography="body-medium" width="90%" />
          <TextLoadingBox typography="body-medium" width="90%"/>
          <TextLoadingBox typography="body-medium" width="90%" />
        </div>
        <TextLoadingBox typography="body-medium" width="50%" />
      </LeftContent>
      <RightContent>
        <div>
          <TextLoadingBox typography="body-medium" width="80%" />
          <TextLoadingBox typography="heading2" width="80%" />
          <TextLoadingBox typography="body-medium" width="80%" />
          <TextLoadingBox typography="body-medium" width="80%" />
        </div>
        <div>
          <TextLoadingBox typography="heading1" width="100%" />
          <TextLoadingBox typography="body-medium" width="70%" />
        </div>
      </RightContent>
    </DetailSegment>
  </Main>)
}

export default React.memo(ToursSearchTileLoadingSkeleton)
