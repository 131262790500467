import { excludeNullOrUndefined } from 'checkout/utils'
import config from 'constants/config'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import getValueOffPercent from 'lib/offer/getValueOffPercent'

interface BundleValueUp {
  shouldShowValue: Array<boolean>;
  showPriceDetails: Array<boolean>;
  price: number;
  valuedUpTo: number;
  purePrice: number;
}

export const getBundleValueUp = (bundleOffer: App.BundleOffer | App.BundleOfferSummary, bestPricePackage: App.BundlePackageWithPrice, isLuxPlusMember?: boolean) => {
  const data: BundleValueUp = {
    shouldShowValue: [],
    showPriceDetails: [],
    price: 0,
    valuedUpTo: 0,
    purePrice: 0,
  }

  for (const pkg of bestPricePackage.packages) {
    const offer = bundleOffer.bundledOffers[pkg.offerId]

    if (offer.type === OFFER_TYPE_ALWAYS_ON) {
      const packagesWithIsBaseRate = bundleOffer.packages.map((option) => {
        if (option.isBaseRate && option.offerId === offer.id) {
          return { duration: option.duration, value: option.value, memberValue: option.memberValue, packageId: option.id }
        }
      }).filter(excludeNullOrUndefined)

      const basePackageDuration = packagesWithIsBaseRate.find(
        (packageItem) => packageItem.duration === pkg.duration && packageItem.packageId === pkg.id,
      )

      const baseRateCost = isLuxPlusMember ? basePackageDuration?.memberValue : basePackageDuration?.value
      const inclusionsAmount = (isLuxPlusMember ? pkg.totalInclusionsAmount : pkg?.inclusionsAmount) ?? 0
      const pkgValueOrMemberValue = isLuxPlusMember ? pkg.memberValue : pkg.value

      let valuedUpTo = 0

      if (baseRateCost && (baseRateCost + inclusionsAmount) > pkgValueOrMemberValue) {
        const displayedHotelValue = baseRateCost + inclusionsAmount + pkg.propertyFees
        const displayedHotelDiscountPercent = getValueOffPercent(displayedHotelValue, pkg.price)

        if (displayedHotelDiscountPercent > pkg.discountPercent) {
          valuedUpTo = displayedHotelValue
        }
      }

      const showPriceDetails = pkg.taxesAndFees > 0 || pkg.propertyFees > 0 || config.agentHub.isEnabled
      const shouldShowValue = pkgValueOrMemberValue > pkg.price && !pkg.roomRate?.inclusionsHideValue

      data.valuedUpTo = data.valuedUpTo + (valuedUpTo || pkgValueOrMemberValue)
      data.price = data.price + pkg.price

      data.showPriceDetails.push(showPriceDetails)
      data.shouldShowValue.push(shouldShowValue)

      if (pkg.bundleDiscountPercent) {
        data.purePrice = data.purePrice + Number(Number((pkg.price * 100) / (100 - pkg.bundleDiscountPercent)).toFixed(2))
      } else {
        data.purePrice = data.purePrice + pkg.price
      }
    } else if (offer.type === OFFER_TYPE_HOTEL) {
      const showPriceDetails = pkg.taxesAndFees > 0 || pkg.propertyFees > 0 || config.agentHub.isEnabled
      const shouldShowValue = pkg.value > pkg.price && offer.shouldDisplayValue

      data.valuedUpTo = data.valuedUpTo + pkg.value
      data.price = data.price + pkg.price

      data.showPriceDetails.push(showPriceDetails)
      data.shouldShowValue.push(shouldShowValue)

      if (pkg.bundleDiscountPercent) {
        data.purePrice = data.purePrice + Number(Number((pkg.price * 100) / (100 - pkg.bundleDiscountPercent)).toFixed(2))
      } else {
        data.purePrice = data.purePrice + pkg.price
      }
    }
  }

  const bundleDiscount = data.purePrice > data.price ? Math.round((data.purePrice - data.price) * 100 / data.purePrice) : 0

  let hotelDiscountPercent = data.valuedUpTo > 0 && data.price > 0 ? Math.round((data.valuedUpTo - data.price) * 100 / data.valuedUpTo) : bestPricePackage.discountPercent

  if (bundleDiscount > 1) {
    hotelDiscountPercent = bundleDiscount
  }

  return {
    shouldShowValue: data.shouldShowValue.every((p) => p),
    showPriceDetails: data.showPriceDetails.some((p) => p),
    valuedUpTo: data.valuedUpTo,
    hotelDiscountPercent,
    shouldShowDiscountPercent: hotelDiscountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE,
  }
}
