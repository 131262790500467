import getOfferListKey from 'lib/offer/offerListKey'

export function getTourMetadata(
  state: App.State,
  offerId: string,
  filters?: App.OfferListFilters,
): App.TourListMetadata | undefined {
  if (!filters) {
    return undefined
  }

  const filtersKey = getOfferListKey(filters)

  return state.offer.tourSearchResultMetadata?.tourMetadata?.[filtersKey]?.[offerId]
}

export function getAvailableTourOptions(
  offer: Tours.TourV2Offer | Tours.TourV2OfferSummary,
  metadata: App.TourListMetadata | undefined,
): Array<Tours.TourV2OfferPurchasableOption> | undefined {
  if (!metadata) {
    return undefined
  }

  if (!metadata.tourOptionIds) {
    return undefined
  }

  const availableOptionIdSet = new Set(metadata.tourOptionIds)
  const availableVariantIds =
    Object.values(offer.variations)
      .filter(variation => availableOptionIdSet.has(variation.fkTourOptionId))
      .map(variation => variation.id)

  const availableVariantIdSet = new Set(availableVariantIds)

  return offer.purchasableOptions.filter(
    option => availableVariantIdSet.has(option.fkVariationId),
  )
}
