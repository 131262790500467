import clsx from 'clsx'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import offerPageURL from 'lib/offer/offerPageURL'
import { pluralizeToString } from 'lib/string/pluralize'
import { buildSearchParamsFromFilters } from 'lib/search/searchUtils'
import moment from 'moment'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useLPCTacticalSearchTilePromotions } from 'hooks/OfferPage/useLPCTacticalPromotions'
import { useTacticalUpsellBanner } from 'hooks/useTacticalUpsellBanner'
import PriceRowPriceWithCaption from 'components/Luxkit/PricePoints/PriceRowPriceWithCaption'
import { useOfferPrice } from 'hooks/Offers/useOfferPrice'
import { INCLUSIONS_TITLE_BONUS } from 'constants/inclusions'

const Container = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(16)};
  margin-top: ${rem(16)};
  grid-column: 1 / 3;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  > * + * {
    width: fit-content;
    margin-top: ${rem(8)};
  }

  ${mediaQueryUp.tablet} {
    &.new-tile {
      flex-direction: row;
    }
  }
`

const Content = styled.div`
  > * + * {
    margin-top: ${rem(8)};
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: ${rem(4)};

  ${mediaQueryUp.tablet} {
    flex-direction: column;
    align-items: end;
  }
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  bestPricePackage?: App.Package;
  filters?: App.OfferListFilters;
  newTile?: boolean
}

export default function SearchOfferTileInclusionsLPCUpsellBanner({ offer, bestPricePackage, filters, newTile = false }: Props) {
  const shouldShowLPCTacticalUpsell = useTacticalUpsellBanner(offer, bestPricePackage, filters)
  const { rate, checkIn, checkOut } = useLPCTacticalSearchTilePromotions(offer, bestPricePackage, filters)
  const offerUrl = useMemo(() => {
    const newFilter = filters ? {
      ...filters,
      checkIn,
      checkOut,
    } : undefined
    const params = buildSearchParamsFromFilters(newFilter, newFilter && rate ? {
      checkIn: moment(newFilter.checkIn).format(ISO_DATE_FORMAT),
      checkOut: moment(newFilter.checkIn).add(rate.duration, 'days').format(ISO_DATE_FORMAT),
      packageId: rate.id,
    } : undefined)

    return `${offerPageURL(offer)}?${params}`
  }, [offer, filters, rate, checkIn, checkOut])

  const {
    saleUnit,
    valueOffPercent: discount,
    total: totalPrice,
  } = useOfferPrice({
    value: rate?.hotelValue,
    saleUnit: 'total',
    duration: rate?.duration,
    total: rate?.hotelPrice,
    propertyFees: rate?.propertyFees ?? 0,
  })

  if (!rate || !shouldShowLPCTacticalUpsell) {
    return null
  }

  return (
    <Container className={clsx({ 'new-tile': newTile })}>
      <Content>
        <ProductTypeLabel productType="limited_time_special" />
        <BodyText variant="medium">
          Discounts and {INCLUSIONS_TITLE_BONUS} available across select dates
        </BodyText>
        <PriceRowPriceWithCaption
          size="S"
          caption={`${pluralizeToString('night', rate.duration)} from`}
          price={totalPrice}
          saleUnit={saleUnit}
          discountPercentage={discount}
        />
      </Content>
      <Buttons className={clsx({ 'new-tile': newTile })}>
        <TextButton to={offerUrl} kind="secondary">
          View special offer
        </TextButton>
      </Buttons>
    </Container>
  )
}
