import React from 'react'
import { ReportError } from 'actions/ErrorActions'
import { connect } from 'react-redux'
import noop from 'lib/function/noop'
import OhNoRefreshError from './OhNoRefreshError'

interface State {
  error?: any;
}

interface Props {
  onError?: (error: Error) => void;
  captureException: (error, extras) => void;
  fallback?: ((error: any) => React.ReactNode) | null;
  pathname: string;
  reportErrors?: boolean;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren<Props>, State> {
  state: State = {}

  static defaultProps = {
    onError: noop,
    reportErrors: true,
    fallback: () => <OhNoRefreshError />,
  }

  componentDidUpdate(prevProps) {
    // if the user navigates somewhere else, clear the error (although might happen again?)
    if (this.state.error && prevProps.pathname !== this.props.pathname) {
      this.setState({ error: undefined })
    }
  }

  componentDidCatch(error, info) {
    this.setState({ error })

    if (this.props.reportErrors) {
      // report all errors that aren't because the bundle was outdated
      this.props.captureException(error, info)
    }
    try {
      this.props.onError?.(error)
    } catch {
      // nothing
    }
  }

  render() {
    if (this.state.error) {
      return this.props.fallback?.(this.state.error) || null
    }
    return this.props.children || null
  }
}

const actions = {
  captureException: ReportError,
}

function mapState(state: App.State) {
  return {
    pathname: state.router.location.pathname,
  }
}

export default connect(mapState, actions)(ErrorBoundary)
