import { useCallback, useContext, useMemo } from 'react'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { DATE_SEARCH_OPTION_IDS } from 'constants/search'
import config from 'constants/config'

interface Props {
  onAnytimeDatesSelected?: () => void;
  isSpecificSearchEnabled?: boolean
}

interface DateToggle {
  id: string;
  label: string;
}

const flexibleDatesToggle: DateToggle = {
  id: DATE_SEARCH_OPTION_IDS.FLEXIBLE,
  label: 'Flexible dates',
}
const specificDatesToggle: DateToggle = {
  id: DATE_SEARCH_OPTION_IDS.SPECIFIC,
  label: config.ENABLE_FLEXIBLE_SEARCH ? 'Specific dates' : 'I’ve got dates',
}
const anytimeDatesToggle: DateToggle = {
  id: DATE_SEARCH_OPTION_IDS.ANYTIME,
  label: config.ENABLE_FLEXIBLE_SEARCH ? 'Anytime' : 'I’m flexible',
}

const useDateSearchProps = (props: Props) => {
  const { onAnytimeDatesSelected, isSpecificSearchEnabled = true } = props
  const {
    checkinDate,
    checkoutDate,
    isFlexibleDateSelected,
    dateSearchOptionId,
    flexibleNights,
    flexibleMonths,
    userSelectedFlexibleMonths,
  } = useContext(GlobalSearchStateContext)

  const hasDates = !!(checkinDate && checkoutDate)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const isFlexibleSearchEnabled = config.ENABLE_FLEXIBLE_SEARCH
  const dateToggleButtons = useMemo(
    () => {
      const dataToggles: Array<DateToggle> = []
      if (isSpecificSearchEnabled) dataToggles.push(specificDatesToggle)
      if (isFlexibleSearchEnabled) dataToggles.push(flexibleDatesToggle)
      dataToggles.push(anytimeDatesToggle)
      return dataToggles
    },
    [isFlexibleSearchEnabled, isSpecificSearchEnabled],
  )

  /**
   * Handler when user select I'm flexible
   * On mobile view, will apply search
   * On desktop view, will not apply search
   * Fire UA event when I'm flexible clicked
   */

  const unsetDates = useCallback(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKIN_DATE })
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
  }, [globalSearchDispatch])

  const unsetFlexibleDates = useCallback(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION })
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE })
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_USER_SELECTED_FLEXIBLE_MONTHS })
  }, [globalSearchDispatch])

  const selectAnytimeDates = useCallback(() => {
    unsetDates()
    unsetFlexibleDates()
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: DATE_SEARCH_OPTION_IDS.ANYTIME })
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED, selected: true })

    onAnytimeDatesSelected?.()
  }, [globalSearchDispatch, onAnytimeDatesSelected, unsetDates, unsetFlexibleDates])

  /**
   * Reset the flexibleDateSelected when user select i've got dates or when user select dates in calendar
   */
  const selectSpecificDates = useCallback(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED, selected: false })
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: DATE_SEARCH_OPTION_IDS.SPECIFIC })
    unsetFlexibleDates()
  }, [globalSearchDispatch, unsetFlexibleDates])

  const selectFlexibleDates = useCallback(() => {
    unsetDates()
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED, selected: false })
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId: DATE_SEARCH_OPTION_IDS.FLEXIBLE })
  }, [unsetDates, globalSearchDispatch])

  const handleDatesChanged = useCallback(
    (dates: { startDate: moment.Moment; endDate: moment.Moment }) => {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: dates.startDate })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: dates.endDate })

      if (dates.startDate || dates.endDate) {
        selectSpecificDates()
      }
    },
    [globalSearchDispatch, selectSpecificDates],
  )

  const onToggleDateSelection = useCallback((item, e: React.MouseEvent) => {
    // prevent the search form submit
    e.preventDefault()

    switch (item.id) {
      case DATE_SEARCH_OPTION_IDS.SPECIFIC:
        selectSpecificDates()
        break
      case DATE_SEARCH_OPTION_IDS.ANYTIME:
        selectAnytimeDates()
        break
      case DATE_SEARCH_OPTION_IDS.FLEXIBLE:
        selectFlexibleDates()
        break
    }
  }, [selectSpecificDates, selectAnytimeDates, selectFlexibleDates])

  const activeItemByOptionId = useMemo(
    () =>
      dateToggleButtons.find((toggle) => {
        return toggle.id === dateSearchOptionId
      }),
    [dateToggleButtons, dateSearchOptionId],
  )

  const activeItemByAnytimeDateSelected = isFlexibleDateSelected ?
    anytimeDatesToggle :
    specificDatesToggle

  const activeItem =
    isFlexibleSearchEnabled && dateSearchOptionId ?
      activeItemByOptionId :
      activeItemByAnytimeDateSelected

  const onBusinessTraveller = config.businessTraveller.currentAccountMode === 'business'
  const isConfirmationButtonDisabled = onBusinessTraveller ? !checkinDate || !checkoutDate : !!checkinDate && !checkoutDate
  return ({
    checkinDate,
    checkoutDate,
    confirmButtonDisabled: isConfirmationButtonDisabled,
    activeDateToggleButton: activeItem,
    flexibleNights,
    flexibleMonths,
    dateToggleButtons,
    onToggleDateSelection,
    handleDatesChanged,
    hasDates,
    userSelectedFlexibleMonths,
    selectFlexibleDates,
    selectAnytimeDates,
  })
}

export default useDateSearchProps
