import React, { FormEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react'
import TextInput from 'components/Common/Form/Input/TextInput'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import TextButton from 'components/Luxkit/Button/TextButton'
import ReCAPTCHA from 'react-google-recaptcha'
import config from 'constants/config'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import LegalText from 'components/Account/AccountAccess/AccountAccessLegalText'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import noop from 'lib/function/noop'
import { checkUserExists } from 'actions/AuthActions'
import { checkAgentHubStatus } from 'actions/AgentHubActions'
import Caption from 'components/Luxkit/Typography/Caption'
import ModalContext from 'contexts/ModalContext'
import AgentHubExpressionOfInterestModal from '../AgentHubExpressionOfInterestModal'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import TextLink from 'components/Luxkit/TextLink'
import { pushWithRegion } from 'actions/NavigationActions'
import { LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP } from 'agentHub/routes/AgentHubRoutes'
import qs from 'qs'

interface LoginFormData {
  email: string
}

interface Props {
  onModeChange?: (mode: App.UiAccountModalMode) => void;
  onSubmit?: (userId: string) => void;
  defaultUser?: App.User;
  dismissable?: boolean;
}

function AgentHubLoginForm(props: Props) {
  const {
    onModeChange = noop,
    onSubmit = noop,
    defaultUser,
    dismissable,
  } = props

  const appDispatch = useAppDispatch()
  const showModal = useContext(ModalContext)

  const [checkingEmail, setCheckingEmail] = useState<string>('')
  const [isError, setIsError] = useState(false)
  const authProcessing = useAppSelector(state => state.auth.processing)
  const user = useAppSelector(state => state.auth.users[checkingEmail])
  const agentStatus = useAppSelector(state => state.agentHub.agentStatus[checkingEmail])
  const authError = useAppSelector(state => state.auth.error)

  const formRef = useRef<HTMLFormElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const reRef = useRef<ReCAPTCHA>(null)

  const modalContext = useModalElementContext()

  const showRegisterModal = useCallback(() => {
    modalContext.resolve()
    showModal(<AgentHubExpressionOfInterestModal />)
  }, [modalContext, showModal])

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const formData = formToObject<LoginFormData>(event.currentTarget)
    setCheckingEmail(formData.email)
    appDispatch(checkUserExists({ email: formData.email }, reRef.current ?? undefined))
    appDispatch(checkAgentHubStatus({ agentEmail: formData.email }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange() {
    setIsError(false)
    emailInputRef.current?.setCustomValidity('')
  }

  useEffect(() => {
    formRef.current?.reset()
    emailInputRef.current?.focus()
  }, [])

  useEffect(() => {
    if (checkingEmail && !authProcessing && !agentStatus?.fetching && user) {
      onSubmit(checkingEmail)
      if (agentStatus?.action === 'onboard') {
        if (user.exists) {
          const query = qs.stringify({ agent_id: agentStatus.agentId, agent_email: checkingEmail })
          modalContext.resolve()
          appDispatch(pushWithRegion(LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP, query))
        } else {
          onModeChange('agentResendActivation')
        }
        return
      }

      if (user.exists && agentStatus?.action === 'login') {
        onModeChange('loginPassword')
        setCheckingEmail('')
        return
      }

      setIsError(true)
      setCheckingEmail('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingEmail, authProcessing, agentStatus, modalContext])

  return <>
    <ModalHeader title="" dismissible={dismissable} />
    <ModalBody>
      <ModalContent>
        <form
          ref={formRef}
          name="agentHubLoginForm"
          data-testid="agent-hub-login-form"
          onSubmit={handleSubmission}
        >
          <Group direction="vertical" gap={12}>
            <Group direction="vertical" gap={24}>
              {authError?.message && <MessageBanner
                kind="critical"
                description={authError.message}
              />}

              <Group direction="horizontal" horizontalAlign="center"><LogoIcon /></Group>

              <Group direction="vertical" gap={4}>
                <Heading variant="heading4" align="center">
                  Earn commission on the world's best travel deals through Agent Hub
                </Heading>
                <BodyText variant="medium" align="center">
                  Savings for your clients, commission in your pocket
                </BodyText>
              </Group>

              <Group direction="vertical" gap={12}>
                <TextInput
                  label="Email address"
                  required
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  requiredErrorMessage="Email is required"
                  ref={emailInputRef}
                  onChange={onChange}
                  defaultValue={defaultUser?.email}
                />
                {isError &&
                  <Caption variant="medium" colour="critical">
                    This Agent Hub account does not exist. Try again or{' '}
                    <TextLink size="caption" onClick={showRegisterModal} weight="regular">
                      Register Now
                    </TextLink>
                  </Caption>
                }
                <TextButton
                  kind="primary"
                  size="large"
                  data-testid="to-login-continue"
                  type="submit"
                  fit="flex"
                >
                  Continue
                </TextButton>
              </Group>
            </Group>
            <LegalText />
          </Group>
          {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={reRef} />}
        </form>
      </ModalContent>
    </ModalBody>
  </>
}

export default AgentHubLoginForm
