export const minimumBalances = {
  ZAR: 220.00,
  VND: 325000.00,
  USD: 20.00,
  TWD: 400.00,
  THB: 450.00,
  SGD: 20.00,
  SAR: 50.00,
  RUB: 1100.00,
  QAR: 50.00,
  PHP: 700.00,
  NZD: 20.00,
  MYR: 60.00,
  MOP: 115.00,
  KRW: 17000.00,
  JPY: 1500.00,
  INR: 1000.00,
  ILS: 50.00,
  IDR: 200000.00,
  HKD: 110.00,
  GBP: 20.00,
  EUR: 20.00,
  CNY: 100.00,
  CAD: 20.00,
  AUD: 20.00,
  AED: 50.00,
}
