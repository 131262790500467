import FormatCurrency from 'components/Common/FormatCurrency'
import LabeledSheet from 'components/Luxkit/LabeledSheet'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { INCLUSIONS_TITLE_LUXPLUS } from 'constants/inclusions'
import { isLEOffer } from 'lib/offer/offerTypes'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { rem } from 'polished'
import React, { ComponentProps } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import LuxPlusInclusionsList from './LuxPlusInclusionsList'
import LuxPlusLabelWithModal from './LuxPlusLabelWithModal'

const ContentWrapper = styled.div`
  padding: ${rem(16)};
`

interface MappedProps {
  subscription: App.ProductSubscription;
  isLuxPlusEnabled: boolean;
}

type InheritedLuxPlusInclusionsListProps = Pick<ComponentProps<typeof LuxPlusInclusionsList>, 'inclusions' | 'showMinStayDescription'>

interface Props extends InheritedLuxPlusInclusionsListProps{
  accommodationOffer?: App.Offer | App.OfferSummary;
  hideUpsellModal?: boolean;
}

function LuxPlusInclusions(props: Props & MappedProps) {
  const {
    inclusions,
    showMinStayDescription,
    isLuxPlusEnabled,
    accommodationOffer,
    subscription,
    hideUpsellModal,
  } = props

  if (!isLuxPlusEnabled || !inclusions.length) return null

  return <LabeledSheet
    data-testid="lux-plus-bonus-inclusions"
    kind="lux-plus"
    label={
      <>
        <CSSBreakpoint not="tablet">
          <LuxPlusLabelWithModal
            type="bonus-inclusions"
            inclusions={inclusions}
            offer={accommodationOffer}
            hideUpsellModal={hideUpsellModal}
          />
        </CSSBreakpoint>
        <CSSBreakpoint only="tablet">
          <LuxPlusLabelWithModal
            type="only-logo"
            offer={accommodationOffer}
            inclusions={inclusions}
            hideUpsellModal={hideUpsellModal}
            modalTitle={`Join ${LUXURY_PLUS.PROGRAM_NAME} to enjoy ${INCLUSIONS_TITLE_LUXPLUS} for this offer and more!`}
            modalSubTitle={<>
              Become a member of our VIP travel club for <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />{' '}
              per year to unlock {INCLUSIONS_TITLE_LUXPLUS} when you book{' '}
              {isLEOffer(accommodationOffer) ? <b>{accommodationOffer?.property?.name}</b> : 'this hotel'}.
            </>}
          />
        </CSSBreakpoint>
      </>
    }
  >
    <ContentWrapper>
      <LuxPlusInclusionsList inclusions={inclusions} showMinStayDescription={showMinStayDescription} />
    </ContentWrapper>
  </LabeledSheet>
}

export default connect<MappedProps, {}, Props, App.State>((state: App.State) => {
  return {
    isLuxPlusEnabled: isLuxPlusEnabled(state),
    subscription: state.luxPlus.products.subscription,
  }
})(LuxPlusInclusions)
