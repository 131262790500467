import { marked, Tokens } from 'marked'
import { markedSmartypants } from 'marked-smartypants'
import insane from 'insane'
import { deepMerge } from 'lib/object/objectUtils'
import React, { useEffect, useState } from 'react'
import { getRegionByCode, getRegionCodes } from '@luxuryescapes/lib-regions'
import config from 'constants/config'

// This library contains a custom link renderer intended for use with marked
// markdown rendering library. It adds the nofollow attribute to all anchors,
// with the exception of the following domains.
//
// Offer content sometimes contains links to external pages. We want these
// links to be only followed by Google when it is beneficial to LE.com.  We
// introduce a “safelist” for domains that are allowed to be followed.  The
// default behaviour should be to introduce a “nofollow” attribute to links
// that are not in this list.

const whitelist = [
  'facebook.com',
  'apple.com',
  'twitter.com',
  'play.google.com',
  'instagram.com',
  'youtube.com',
  'pinterest.com',
  'cudo.com.au',
  'deals.com.au',
  'treatme.co.nz',
  'luxuryescapes.com',
]

function whitelisted(url: string): boolean {
  return whitelist.some(domain => url.startsWith(`https://${domain}`) ||
      url.startsWith(`http://${domain}`) ||
      url.startsWith(`https://www.${domain}`) ||
      url.startsWith(`http://www.${domain}`))
}

export function markdownLinkRenderer(link: Tokens.Link): string {
  const { href, title, text } = link
  let rel = 'noopener noreferrer'
  if (!whitelisted(href)) {
    rel += ' nofollow'
  }
  let out = `<a target="_blank" rel="${rel}" href="${href}"`
  if (title) {
    out += ` title="${title}"`
  }
  out += `>${text}</a>`
  return out
}

marked.use({
  gfm: true,
  breaks: false,
  pedantic: false,
  useNewRenderer: true,
  renderer: {
    link: markdownLinkRenderer,
  },
})

marked.use(markedSmartypants())

const sanitiseOptions = deepMerge(insane.defaults, {
  allowedAttributes: {
    a: ['rel'],
  },
  allowedSchemes: ['tel'],
}, 'array-concat')

export function phoneLinksDecorator(Component) {
  return function WrappedComponent(props) {
    const [processedMessage, setProcessedMessage] = useState(props.message?.text ?? '')

    // Used to escape numbers with a + prefixing them
    function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    useEffect(() => {
      let newText = props.message?.text ?? ''

      /*
        Get all regions for brand then loop through these regions and create set from all numbers.
        Later discuss implementing new function into lib-regions that gets all numbers regardless of region.
      */

      const regionContacts = getRegionCodes(config.BRAND)
        .flatMap(region => getRegionByCode(region, config.BRAND)?.contacts || [])

      const uniqueNumbers = new Set()

      regionContacts?.forEach(item => {
        if (props.message?.text?.includes(item.international.humanReadable || item.international.number)) {
          uniqueNumbers.add(item.international.humanReadable)
        }
        if (props.message?.text?.includes(item.local.humanReadable || item.local.number)) {
          uniqueNumbers.add(item.local.humanReadable)
        }
      })

      uniqueNumbers.forEach(number => {
        const escapedNumber = escapeRegExp(number)
        const regex = new RegExp(`(${escapedNumber})`, 'g')
        newText = newText.replace(regex, `<a href="tel:${number}">${number}</a>`)
      })

      setProcessedMessage(newText)
    }, [props.message])

    return <Component {...props} processedMessage={processedMessage} />
  }
}

export function renderMarkdown(content: string): string {
  const html = marked.parse(content, { async: false }) as string
  const sanitisedHtml = insane(html, sanitiseOptions)
  return sanitisedHtml
}
