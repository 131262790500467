import OfferLabels from 'components/Common/Labels/OfferLabels'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import OfferTileLoggedInButtons from '../Support/OfferTileLoggedInButtons'
import React from 'react'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import VillaLocationCaption from 'components/OfferList/OfferCards/VillaLocation'
import VillaCardInclusions from 'components/OfferList/OfferCards/VillaCardInclusions'
import WalledContent from 'components/Common/WalledContent'
import HeroOfferTilePricing from '../Support/HeroHotelOfferTilePricing'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'

const DetailContainer = styled(Group)`
  height: 100%;
  padding-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)} ${rem(32)} ${rem(32)} ${rem(32)};
  }
`

const HeaderActions = styled.div`
  display: grid;
  grid-template: "review save";
`

const SaveButtonWrapper = styled.div`
  grid-area: save;
  justify-self: end;
`

const ReviewWrapper = styled.div`
  grid-area: review;

  &:empty {
    display: none;
  }
`

const MinHeightGroup = styled(Group)`
  min-height: ${rem(300)};
  flex-grow: 1;
`

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary;
  offerUrl: string;
}

function HeroVilaOfferDetails(props: Props) {
  const { offer, offerUrl } = props
  const {
    property,
    lowestPricePackage,
  } = offer

  const wall = <OfferTileLoggedInButtons
  signUpText="Sign up for free"
  discountPercentage={Math.round(lowestPricePackage?.discountPercent || 0)}
  align="none"
  fullWidth
/>

  return (
    <DetailContainer direction="vertical" gap={16} >
      <CSSBreakpoint min="desktop">
        <HeaderActions>
          {isOfferRatingDisplayable(offer.property?.rating) && <ReviewWrapper>
            <OfferRating variant="medium" rating={offer.property.rating} />
          </ReviewWrapper>}
          <TripGuard>
            <SaveButtonWrapper>
              <BookmarkButton offer={offer} />
            </SaveButtonWrapper>
          </TripGuard>
        </HeaderActions>
      </CSSBreakpoint>
      <MinHeightGroup direction="vertical" gap={16} tabletGap={24} verticalAlign="space-between">
        <Group direction="vertical" gap={4} horizontalAlign="start">
          <LabelGroup>
            <OfferLabels offer={offer} />
            {offer.badge && <OfferBadgeTag badge={offer.badge} />}
          </LabelGroup>
          <Caption variant="large" colour="neutral-one">
            <VillaLocationCaption offer={offer} />
          </Caption>
          <Heading variant="heading6" lineClamp={3}>{property?.name ?? offer.name}</Heading>
          {isOfferRatingDisplayable(offer.property?.rating) && <CSSBreakpoint max="tablet">
            <OfferRating
              inlineLabel
              variant="xs"
              rating={offer.property.rating}
            />
          </CSSBreakpoint>}
          <CSSBreakpoint max="tablet">
            <VillaCardInclusions offer={offer} offerUrl={offerUrl} totalInclusions={4} />
          </CSSBreakpoint>
          <CSSBreakpoint min="desktop">
            <VillaCardInclusions offer={offer} offerUrl={offerUrl} totalInclusions={6} />
          </CSSBreakpoint>
        </Group>
        <Group direction="vertical" gap={8} >
          <WalledContent enforced={offer.walledGarden} wall={wall} >
            {offer.lowestPricePackage && <HeroOfferTilePricing offer={offer} pkg={offer.lowestPricePackage}/>}
          </WalledContent>
        </Group>
      </MinHeightGroup>
    </DetailContainer>
  )
}

export default HeroVilaOfferDetails
