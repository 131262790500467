import createSelector from 'lib/web/createSelector'
import { capitalise } from 'lib/string/stringUtils'
import { pluralizeToString } from 'lib/string/pluralize'

export const getCustomOfferItems = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order) => order ? order.customOfferItems : [],
)

export const getCustomOfferTravellers = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order) => order?.customOfferItems?.length ? (order.customOfferItems[0].custom_offer.items[0].travellers ?? []) : [], // we support only one item at the moment
)

export function getCustomOfferTravellerBreakdown(travellers: Array<App.CustomOfferTraveller>) {
  const travellersInfo = travellers.reduce((acc, traveller) => {
    if (traveller.type === 'adult') {
      acc.adults += 1
    } else if (traveller.type === 'child') {
      acc.children += 1
    } else {
      acc.infants += 1
    }

    return acc
  }, { adults: 0, children: 0, infants: 0 })

  return travellersInfo
}

export const getCustomOfferBreakdownView = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (!order?.customOfferItems?.length) {
      return {
        hasRequiredData: true,
        data: [],
      }
    }

    const orderItem = order.customOfferItems[0]
    const customOffer = orderItem.custom_offer
    const customItem = customOffer.items[0]

    const travellers = getCustomOfferTravellerBreakdown(customItem.travellers)

    const travellersBreakdown = `${pluralizeToString('Adult', travellers.adults)} ${travellers.children > 0 ? `, ${pluralizeToString('Child', travellers.children)}` : ''} ${travellers.children > 0 ? `, ${pluralizeToString('Infant', travellers.infants)}` : ''}`
    const price = customOffer.price / 100
    const memberPrice = customOffer.memberPrice / 100
    const depositAmount = (customOffer?.payment_metadata?.customer_deposit_1_amount ?? 0) / 100
    const cruiseItemBreakdownView: App.Checkout.CruiseItemBreakdownView = {
      title: `${customItem.ship_name} • ${customItem.ship_cabin}`,
      additionalInfoText: [travellersBreakdown],
      additionalElements: [],
      price: price - depositAmount,
      memberPrice: memberPrice - depositAmount,
      itemType: 'cruise',
    }
    const item: App.Checkout.PriceBreakdownView = {
      title: capitalise(customItem.type),
      price: price - depositAmount,
      memberPrice: memberPrice - depositAmount,
      description: customOffer.name,
      additionalInfoText: [],
      items: [
        cruiseItemBreakdownView,
      ],
    }

    return {
      hasRequiredData: true,
      data: [item],
    }
  },
)
