import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_CRUISE, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_TOUR, PRODUCT_TYPE_TOUR_V2, PRODUCT_TYPE_ULTRALUX, PRODUCT_TYPE_ULTRALUX_CRUISE } from 'constants/offer'
import config from 'constants/config'

import request from 'api/requestUtils'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import { EXPERIENCE_PRODUCT_TYPE_CHANNEL_MANAGER, EXPERIENCE_PRODUCT_TYPE_FLASH, EXPERIENCE_PRODUCT_TYPE_ALWAYS_ON } from 'constants/experience'
import qs from 'qs'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { arrayToObject } from 'lib/array/arrayUtils'

function mapContact(contact: any = {}): App.GeoContact {
  return {
    humanReadable: contact.human_readable,
    number: contact.number,
  }
}

function mapContacts(contacts: Array<any> = []) {
  return arrayToObject(
    contacts,
    (contact) => {
      // response from API comes back as 'ultralux'
      if (contact.type === 'ultralux') {
        return 'ultraLux'
      }

      return contact.type
    },
    (contact) => ({
      local: mapContact(contact.local),
      international: mapContact(contact.international),
      defaultContact: mapContact(contact[contact.default]),
    }),
  )
}

function mapGeoData(
  partnership: any,
  region: any,
): Partial<App.GeoState> {
  const geoData: Partial<App.GeoState> = {
    partnership,
    currentRegionHasFlights: region.has_flights && config.FLIGHT_ENABLED,
    currentRegionName: region.name,
    currentRegionCode: region.code,
    currentCurrency: region.currency_code,
    currentContact: {
      local: mapContact(region.phone.local),
      international: mapContact(region.phone.international),
      default: region.phone.default,
      defaultContact: mapContact(region.phone[region.phone.default]),
    },
    insuranceProductName: region.insurance_product_name,
    isStrikethroughPricingRegion: isEnabledForFeature(config.STRIKETHROUGH_PRICING_REGIONS, region.code),
    contacts: {
      ...mapContacts(region.contacts) as App.GeoContactsTypes,
    },
  }

  return geoData
}

function getRegionData(regionCode: string) {
  const query = qs.stringify({ no_detect: true })
  return request.get<App.ApiResponse<{ regions: Array<any>}>>(`/regions?${query}`).then(response => {
    return response.result.regions.find(val => val.code === regionCode)
  })
}

function getPartnerships(region: string) {
  const query = qs.stringify({
    region,
  })

  return request.get<App.ApiResponse<Array<any>>>(`/api/loyalty/partnerships?${query}`).then(response => {
    // Temporarily remove clubvistara from the results until it's fully removed from the partnership service itself
    const partnershipList = response.result.filter(partnership => partnership.prefix !== 'cvp').map(partnership => ({
      accountFields: partnership.accountFields,
      bonusPointCost: partnership.bonusPointCost,
      bonusUnit: partnership.bonusUnit,
      brandName: partnership.brandName,
      code: partnership.code,
      color: partnership.color,
      confirmationText: partnership.confirmationText,
      currencyCodes: partnership.currencyCodes,
      earnOrBurnLabel: partnership.earnOrBurnLabel,
      earnOrBurnLabelExtended: partnership.earnOrBurnLabelExtended,
      hasBurn: partnership.hasBurn,
      hasEarn: partnership.hasEarn,
      icon: {
        id: partnership.icon,
      },
      iconReversed: {
        id: partnership.iconReversed,
      },
      joinUrl: partnership.joinUrl,
      landingPage: partnership.landingPage,
      landingPageLogo: {
        id: partnership.landingPageLogo,
      },
      numberMaxLength: partnership.numberMaxLength,
      prefix: partnership.prefix,
      programLogo: {
        id: partnership.programLogo,
      },
      programName: partnership.programName,
      regionCodes: partnership.regionCodes,
      reverseLogo: {
        id: partnership.reverseLogo,
      },
      rewardConversion: partnership.rewardConversion,
      rewardCurrency: partnership.rewardCurrency,
      rewardEarn: partnership.rewardEarn,
      rewardName: partnership.rewardName,
      rewardPer: partnership.rewardPer,
      rewardProgramName: partnership.rewardProgramName,
      userFields: partnership.accountFields.map((field) => ({
        name: field,
        label: partnership.accountFieldsLabels[field],
      })),
    }))

    const partnerships = config.VIRGIN_VELOCITY_ENABLED ? partnershipList : partnershipList.filter(partnership => partnership.code !== VIRGIN_VELOCITY_PARTNERSHIP_CODE)

    if (partnerships.length === 0) {
      return null
    }

    const velocityPartnership = partnerships.find(partnership => partnership.code === VIRGIN_VELOCITY_PARTNERSHIP_CODE)
    if (velocityPartnership) {
      return {
        ...velocityPartnership,
        availableForProductType: [
          OFFER_TYPE_HOTEL,
          OFFER_TYPE_ALWAYS_ON,
          OFFER_TYPE_LAST_MINUTE,
          OFFER_TYPE_CRUISE,
          EXPERIENCE_PRODUCT_TYPE_ALWAYS_ON,
          EXPERIENCE_PRODUCT_TYPE_FLASH,
          EXPERIENCE_PRODUCT_TYPE_CHANNEL_MANAGER,
          PRODUCT_TYPE_TOUR_V2,
          PRODUCT_TYPE_ULTRALUX,
          OFFER_TYPE_TOUR,
          PRODUCT_TYPE_ULTRALUX_CRUISE,
        ],
      }
    }
    return {
      ...partnerships[0],
      availableForOfferType: [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_ALWAYS_ON],
    }
  })
}

export async function getGeoData(
  regionCode: string,
): Promise<Partial<App.GeoState>> {
  const [partnershipData, regionData] = await Promise.all([
    getPartnerships(regionCode),
    getRegionData(regionCode),
  ])

  return mapGeoData(partnershipData, regionData)
}
