import qs from 'qs'
import request, { authOptions } from 'api/requestUtils'
import { ReferralOfferTypes, itemTypeToReferralOfferType, promotionMap, promotionMapV2 } from 'api/mappers/promoMap'
import { Promotion, definitions } from '@luxuryescapes/contract-svc-promo'
import config from '../constants/config'
import { operations, paths } from '@luxuryescapes/contract-svc-promo/generated'

interface ReferralRequestBody {
  code: string;
  country_code: string;
  brand: string;
  cart_value: number;
  item_types: Array<ReferralOfferTypes>
}

export type DiscountRequestOrderV2 = definitions['Discount Request Order V2']

export function getPromo(data: {
  experienceId?: string;
  offerId?: string;
  bedbankOfferId?: string;
  codeName: string;
  isOrderGift?: boolean
  region:string
}) {
  const queryParams = qs.stringify({
    experience_id: data.experienceId || undefined,
    offer: !data.experienceId ? data.offerId : undefined,
    bedbank_offer: data?.bedbankOfferId,
    is_order_gift: data.isOrderGift || undefined,
    region: data.region,
  })

  return request.get<App.ApiResponse<Promotion.Promotion>>(
    `/api/promo/codes/${data.codeName}?${queryParams}`,
    { credentials: 'include' },
  ).then(({ result }) => promotionMap(result))
}

export type DiscountRequest =
  paths['/api/promo/discount']['post']['parameters']['body']['Discount Request']

export type DiscountResponse =
paths['/api/promo/discount']['post']['responses']['200']['content']['application/json']['result']

export type DiscountError =
  | operations['calculateDiscount']['responses']['400']['content']['application/json']['result']

/**
 * getPromoV2 uses the passed in order and user information to calculate the promo amount (on a per-item basis)
 * @param code_name - The requested code name
 * @param order - The order being discounted
 * @param user - Details of the user making the request
 * @returns
 */

export type AppDiscountError = paths['/api/promo/discount']['post']['responses']['400']['content']['application/json']['result']['errors']

export type RestrictedPromoRequestData = { code: string, brand: string, email: string, category: string }

type PromoResponse = App.Promotion|AppDiscountError

export async function getPromoV2(
  { codeName, order, preCheckoutOrder, userId, requestSource }:definitions['Discount Request'],
):Promise<PromoResponse> {
  return request.post<App.ApiResponse<DiscountResponse|DiscountError>, DiscountRequest>('/api/promo/discount',
    {
      codeName,
      order,
      preCheckoutOrder,
      userId,
      requestSource,
    }, { credentials: 'include' })
    .then((results) => {
      if (results.status !== 200 || Object.hasOwn(results.result, 'errors')) {
        throw new Error(results.message ?? 'Could not apply promo')
      }
      // TODO: can we avoid this cast?
      const promoResult = results.result as unknown as DiscountResponse
      return promotionMapV2(promoResult.promo)
    })
}

export async function getCompPackagePromo(data: {
  offerId: string;
  region: string;
}) {
  const queryParams = qs.stringify({
    region: data.region,
    offer: data.offerId,
  })

  return request.get<App.ApiResponse<Promotion.Promotion>>(
    `/api/promo/comped-package/generate-code?${queryParams}`,
    { credentials: 'include' },
  ).then(({ result }) => promotionMap(result))
}

interface ServerGiftCard {
  id_gift_card: string;
  gift_card_code: string;
  added_by: string;
  gift_card_value: number;
  created_at: string;
  updated_at: string;
  expires_at: string;
  redeemed_at: string;
  redeemed_by: string;
  cancelled_at: string;
  fk_order_id: string;
  currency: string;
  gift_card_status: 'awaiting_payment' | 'created' | 'cancelled' | 'redeemed';
  brand: string;
  email: string;
  fk_item_id: string;
  personalised: {
    method: 'print' | 'email';
    to?: string;
    from?: string;
    message?: string;
    email_of_recipient?: string;
    is_blank_message: boolean;
  }
  product?: {
    name: string;
    imageId: string;
    imageUrl: string;
    items: Array<string>;
    type: string;
  }
}

export function getGiftCard(code: string): Promise<App.GiftCard> {
  return request.get<App.ApiResponse<ServerGiftCard>>(`/api/gift-card/code/${code}`, { credentials: 'include' })
    .then(response => {
      const giftCard = response.result
      return {
        id: giftCard.id_gift_card,
        code: giftCard.gift_card_code,
        added_by: giftCard.added_by,
        value: giftCard.gift_card_value,
        expiresAt: giftCard.expires_at,
        redeemedAt: giftCard.redeemed_at,
        redeemedBy: giftCard.redeemed_by,
        cancelledAt: giftCard.cancelled_at,
        orderId: giftCard.fk_order_id,
        currency: giftCard.currency,
        status: giftCard.gift_card_status,
        brand: giftCard.brand,
        email: giftCard.email,
        itemId: giftCard.fk_item_id,
        personalised: {
          method: giftCard.personalised.method,
          to: giftCard.personalised.to,
          from: giftCard.personalised.from,
          message: giftCard.personalised.message,
          email_of_recipient: giftCard.personalised.email_of_recipient,
          is_blank_message: giftCard.personalised.is_blank_message,
        },
        product: giftCard.product ? {
          image: { id: giftCard.product.imageId, url: giftCard.product.imageUrl, title: giftCard.product.name },
          name: giftCard.product.name,
          items: giftCard.product.items,
          type: giftCard.product.type,
        } : undefined,
      } as App.GiftCard
    })
}

export function redeemGiftCard(code: string, pin?: string, region?: string) {
  const uri = `/api/gift-card/code/${code}/redeem?brand=${config.BRAND}&region=${region}`

  return request.patch<
    operations['/api/gift-card/code/{code}/redeem/patch']['responses']['200']['content']['application/json'],
    operations['/api/gift-card/code/{code}/redeem/patch']['parameters']['body']['payload']
  >(uri, pin ? { pin } : {}, { credentials: 'include' })
}

type ScanGiftCardRequest =
  paths['/api/gift-card/scan-image']['post']['parameters']['body']['payload']

type ScanGiftCardResult =
  paths['/api/gift-card/scan-image']['post']['responses']['200']['content']['application/json']['result']

export function detectGiftCardNumber(imageBase64: string) {
  return request.post<App.ApiResponse<ScanGiftCardResult>, ScanGiftCardRequest>(
    '/api/gift-card/scan-image',
    { imageBase64 },
    { credentials: 'include' },
  ).then(({ result }) => ({
    cardNumber: result.code,
    accessCode: result.pin,
  }))
}

interface GetReferralPromoProps {
  codeName: string;
  regionCode: string;
  brand: string;
  cartValue: number;
  itemTypes: Array<App.Checkout.AnyItemType>;
}

export function getReferralPromo({ codeName, regionCode, brand, cartValue, itemTypes }: GetReferralPromoProps) {
  const uri = '/api/referral/promo'

  const body:ReferralRequestBody = {
    code: codeName,
    country_code: regionCode,
    brand,
    cart_value: cartValue,
    item_types: itemTypes.map((it) => itemTypeToReferralOfferType(it)),
  }

  return request.post<App.ApiResponse<Promotion.Promotion>, ReferralRequestBody>(uri, body, { credentials: 'include' })
    .then(({ result }) => promotionMap(result))
    .catch((err) => err)
}

export function getCodeByUserId(userId: string, accessToken?: string) {
  return request.get<{ details: { referral_code: string }}>(`/api/referral/code/${userId}`, authOptions(accessToken))
}

export function getReferralDetails(referralCode) {
  return request.get(`/api/referral/${referralCode}`, { credentials: 'include' })
}

export function getReferralCodeValidation(referralCode) {
  return request.get(`/api/referral/public/${referralCode}`)
}

export async function registerCorporateBenefits(code:string, corporateEmail:string) {
  return request.post<{code: string, email: string }, { }>('/api/promo/corporate', { code, corporateEmail }, { credentials: 'include' })
}

export async function registerFriendsAndFamily(data: RestrictedPromoRequestData) {
  return request.post('/api/promo/request-restricted-promo', data, { credentials: 'include' })
}

type PromoDiscount = operations['getPromoDiscountsById']['responses']['200']['content']['application/json']['result']['promoDiscounts'][0]

function mapPromoDiscount(serverDiscount: PromoDiscount): App.PromotionDiscount {
  return {
    id: serverDiscount.id_promo_code_discount!,
    code: serverDiscount.id_promo_code,
    minSpend: serverDiscount.min_spend,
    maxDiscount: serverDiscount.max_discount,
    value: serverDiscount.discount_value,
    products: serverDiscount.products as Array<App.PromotionDiscountProduct> ?? [],
  }
}

type PromoDiscountResponse = paths['/api/promo/id/{id}/discount']['get']['responses']['200']['content']['application/json']['result']

export function getPromoDiscountsByName(promoName: string, regionCode: string): Promise<Array<App.PromotionDiscount>> {
  const query = qs.stringify({
    region: regionCode,
  })
  return request.get<App.ApiResponse<PromoDiscountResponse>>(`/api/promo/v2/codes/${promoName}/discounts?${query}`, { credentials: 'include' }).then(response => {
    return response.result.promoDiscounts.map(mapPromoDiscount)
  })
}

type ChannelMarkup = paths['/api/promo/channel-markup']['get']['responses']['200']['content']['application/json']['result']

function mapChannelMarkup(serverChannelMarkup: ChannelMarkup): App.ChannelMarkup {
  return {
    channelMarkupId: serverChannelMarkup.channel_markup_id,
    channelMarkupValue: serverChannelMarkup.markup,
  }
}

export function getChannelMarkupByFilters(filters: App.ChannelMarkupFilters): Promise<App.ChannelMarkup> {
  const query = qs.stringify(filters)
  return request.get<App.ApiResponse<ChannelMarkup>>(
    `/api/promo/channel-markup?${query}`,
  ).then((response) => mapChannelMarkup(response.result))
}

export function getChannelMarkupById(id: string): Promise<App.ChannelMarkup> {
  return request.get<App.ApiResponse<ChannelMarkup>>(
    `/api/promo/channel-markup/${id}`,
  ).then(response => mapChannelMarkup(response.result))
}
