import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import React, { ComponentProps } from 'react'

type InheritedOfferInclusionItemProps = Pick<ComponentProps<typeof OfferInclusionItem>, 'showMinStayDescription'>

interface Props extends InheritedOfferInclusionItemProps{
  inclusions: Array<App.OfferInclusion>
}

function LuxPlusInclusionsList({
  inclusions,
  showMinStayDescription,
}: Props) {
  return (
    <VerticalSpacer gap={4} data-testid="luxury-plus-inclusions">
      {inclusions.map((inclusion) => <OfferInclusionItem
        key={inclusion.id}
        inclusion={inclusion}
        type="lux-plus"
        showMinStayDescription={showMinStayDescription}
      />)}
    </VerticalSpacer>
  )
}

export default LuxPlusInclusionsList
