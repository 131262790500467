import React, { useContext, useEffect, useMemo } from 'react'
import moment from 'moment'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import Group from 'components/utils/Group'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from 'components/OfferList/OfferListTiles/Support/OfferTileLoggedInButtons'
import config from 'constants/config'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { useOfferRatePrice } from 'hooks/Offers/useOfferPriceNew'
import useBedbankRates from 'hooks/useBedbankRates'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { checkCanViewLuxPlusBenefits, isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface MappedProps {
  canViewLuxPlusBenefits: boolean
  luxPlusLPPEnabled: boolean
}
interface Props extends MappedProps {
  offer: App.BedbankOffer | App.BedbankOfferSummary | App.BedbankOfferWithRate;
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center'
  loggedInButtonsAlign?: 'none' | 'center' | 'left' | 'right'
  variant?: 'default' | 'condensed';
  selectedDatesAndRoomsFilter?: App.OfferListFilters
}

function BedbankOfferPriceDetails(props: Props) {
  const {
    offer,
    loggedInButtonsAlign = 'none',
    horizontalAlign = 'start',
    variant = 'default',
    selectedDatesAndRoomsFilter,
    canViewLuxPlusBenefits,
    luxPlusLPPEnabled,
  } = props
  const canUseDateFilter = !!selectedDatesAndRoomsFilter?.rooms && !!selectedDatesAndRoomsFilter?.checkIn && !!selectedDatesAndRoomsFilter?.checkOut
  const selectedDuration = canUseDateFilter ? moment(selectedDatesAndRoomsFilter.checkOut).diff(selectedDatesAndRoomsFilter.checkIn, 'days') : undefined

  const hotelNights = selectedDuration ?? offer.sell?.los ?? 0
  const durationText = `${pluralizeToString('night', hotelNights)} from`

  const [{ hotelOnlyRates }, fetching] = useBedbankRates(
    offer.id,
    selectedDatesAndRoomsFilter?.rooms,
    selectedDatesAndRoomsFilter?.checkIn,
    selectedDatesAndRoomsFilter?.checkOut,
    canUseDateFilter,
  )

  const { default: pricing, member: memberPricing } = useOfferRatePrice(offer, hotelOnlyRates[0])
  const displayDiscountPercent = luxPlusLPPEnabled && canViewLuxPlusBenefits && (memberPricing?.discountPercent ?? 0) > 0 ? memberPricing?.discountPercent : pricing?.discountPercent

  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  useEffect(() => {
    if (!fetching) {
      dispatchOfferListEvent({
        type: OfferListEvents.pricingCalculated,
        leadPrice: pricing?.price ?? 0,
        duration: hotelNights,
      })
    }
  }, [dispatchOfferListEvent, pricing?.price, hotelNights, fetching])

  const wall = (
    <OfferTileLoggedInButtons
      signUpText="Sign up for free"
      align={loggedInButtonsAlign}
      fullWidth={false}
      mdFullWidth={horizontalAlign === 'center'}
    />
  )
  return <Group
    desktopHorizontalAlign={horizontalAlign}
    direction="vertical"
    gap={8}
  >
    {pricing && <WalledContent enforced={offer.walledGarden} hidePricing={offer.hidePricing} wall={wall}>
      <Group desktopHorizontalAlign={horizontalAlign} direction="vertical">
        <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
        <LuxPlusPriceStack
          size="L"
          price={pricing.price}
          memberPrice={memberPricing?.price}
          saleUnit={pricing.saleUnit}
        />
        {(displayDiscountPercent ?? 0) > 0 && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(pricing.value)}
          discountPercentage={displayDiscountPercent! > (config.displayDiscountThreshold.bedbank * 100) ? displayDiscountPercent : undefined}
        />}
        {variant === 'default' && <PriceRowTaxesAndFees />}
      </Group>
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerLocations={offerLocations}
        offerId={offer.id}
      />
    </WalledContent>}
  </Group>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
  luxPlusLPPEnabled: isLuxPlusLPPEnabled(state),
})

export default connect(mapStateToProps)(BedbankOfferPriceDetails)
