import request from 'api/requestUtils'
import { tourOrderTravellerDetailsMap } from './mappers/travellerMapper'
import { definitions as SvcTravellerDefs } from '@luxuryescapes/contract-traveller'
import qs from 'qs'

export interface getOrderTravellersResult {
  travellers: Array<App.OrderTraveller>;
  total_travellers: number;
  total_submitted: boolean;
  flights_submitted: boolean;
}

export function reqGetTourOrderTravellerDetails(tourOrderId: string): Promise<App.TourOrderTravellerDetails> {
  return request.get<App.ApiResponse<getOrderTravellersResult>>(`/api/traveller/tour-details/${tourOrderId}`, { credentials: 'include' })
    .then(response => tourOrderTravellerDetailsMap(response.result))
}

// TODO: add payload type from traveller contract
export interface PostOrderTravellerDetailsPayload {
  travellerFormData: Record<string, any>
  // legacy ones 👇
  customer_id: string
  group_count: number
  order_id: string
  booking_number: string
  traveller_id: string
  travellerNumber: number
  group_details_only_collected: boolean
  created_at: string
  purchased_item: App.OrderTravellerPurchaseItem
}
export interface PostOrderTravellerDetailsParams {
  isDraft?: boolean
}
export function postOrderTravellerDetails(payload: PostOrderTravellerDetailsPayload, params?: PostOrderTravellerDetailsParams): Promise<any> {
  const queryParamsString = params ? qs.stringify(params) : ''

  return request.post<App.ApiResponse<PostOrderTravellerDetailsPayload>, unknown>(
    `/api/traveller/tour-details?${queryParamsString}`,
    payload,
    { credentials: 'include' },
  )
}

interface SendTravellerBookingFormResult {
  total_submitted: boolean;
  traveller_submitted: boolean;
  flights_submitted: boolean;
}

export function sendTravellerBookingForm(traveller) {
  return request.post<App.ApiResponse<SendTravellerBookingFormResult>, unknown>('/api/traveller/tour-details', traveller, { credentials: 'include' })
}

export async function retrieveTravellerDetailsForm(
  payload: unknown,
): Promise<App.ApiResponse<App.Checkout.TravellerFormSchemaData> | App.Checkout.TravellerFormSchemaData> {
  return request.post<App.ApiResponse<App.Checkout.TravellerFormSchemaData>, unknown>(
    '/api/traveller/travellers-schema',
    payload,
    { credentials: 'include' }).then(response => {
    return response.result
  })
}

export async function getCustomerDetails(customerId: string): Promise<App.CustomerDetails | undefined> {
  const response = await request.get<App.ApiResponse<SvcTravellerDefs['customerDetails']>>(
    `/api/traveller/customer-details?customer_id=${customerId}`,
    { credentials: 'include' },
  )
  if (response.result) {
    return {
      customerId: response.result.customer_id,
      // @ts-ignore: field maybe be undefined
      addressDetails: response.result.address_details,
      gender: response.result.gender,
      title: response.result.title,
      middleName: response.result.middleName,
    }
  }
}

export async function updateCustomerDetails(customerDetails: App.CustomerDetails): Promise<App.CustomerDetails> {
  const { customerId, addressDetails, ...rest } = customerDetails
  await request.patch<App.ApiResponse<void>, SvcTravellerDefs['customerDetails']>(
    '/api/traveller/customer-details',
    {
      customer_id: customerId,
      address_details: addressDetails,
      ...rest,
    },
    { credentials: 'include' },
  )
  return customerDetails
}

export async function deleteCustomerDetails(customerId: string): Promise<void> {
  await request.get<App.ApiResponse<void>>(
    '/api/traveller/customer-details',
    {
      body: { customer_id: customerId },
      credentials: 'include',
    },
  )
}
