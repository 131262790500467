import clsx from 'clsx'
import moment, { Moment } from 'moment'
import { rem } from 'polished'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import DatePicker from 'components/Common/Calendar/DatePicker'
import Clickable from 'components/Common/Clickable'
import Tooltip from 'components/Luxkit/Tooltip'
import BodyText from 'components/Luxkit/Typography/BodyText'
import {
  dateIsAfter,
  dateIsBefore,
  isSameDay,
  roundedDateDifference,
} from 'lib/datetime/dateUtils'
import { pluralizeToString } from 'lib/string/pluralize'

export const DayCell = styled(Clickable)`
  min-height: ${rem(50)};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s;

  &:disabled {
    color: ${(props) => props.theme.palette.neutral.default.six};

    background-image: linear-gradient(
      to top right,
      white 50%,
      ${(props) => props.theme.palette.neutral.default.six},
      white 52%
    );
  }

  &:not(:disabled):not(.selected-date) {
    &:hover,
    &:focus {
      background-color: ${(props) =>
        props.theme.palette.highlight.primary.lightForeground};
    }
  }

  &.selected-date {
    background-color: ${(props) =>
      props.theme.palette.highlight.primary.normalForeground};
  }

  &.in-range-date {
    background-color: ${(props) =>
      props.theme.palette.highlight.primary.lightForeground};
  }
`

interface Props {
  startDate?: Moment
  endDate?: Moment
  initDate?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChangeStartDate: (date?: Moment) => void
  onChangeEndDate: (date?: Moment) => void
  allowSameDay: boolean
}

function DateRangePicker({
  startDate: startDateMoment,
  endDate: endDateMoment,
  initDate: initDateMoment,
  onChangeStartDate,
  onChangeEndDate,
  minDate: minDateMoment,
  maxDate: maxDateMoment,
  allowSameDay,
}: Props) {
  const onDayClick = useCallback(
    (_day: Date) => {
      const day = moment(_day)
      if (
        startDateMoment &&
        !endDateMoment &&
        (
          day.isAfter(startDateMoment) ||
          (allowSameDay && day.isSame(startDateMoment))
        )
      ) {
        onChangeEndDate(day)
      } else {
        onChangeStartDate(day)
        onChangeEndDate(undefined)
      }
    },
    [
      startDateMoment,
      endDateMoment,
      onChangeEndDate,
      onChangeStartDate,
      allowSameDay,
    ],
  )

  const startDate = startDateMoment?.toDate()
  const endDate = endDateMoment?.toDate()
  const minDate = minDateMoment?.toDate()
  const maxDate = maxDateMoment?.toDate()
  const initDate = initDateMoment?.toDate()

  const initVisibleDate = initDate ?? startDate ?? minDate

  return (
    <DatePicker
      min={minDate}
      max={maxDate}
      defaultMonth={initVisibleDate?.getMonth()}
      defaultYear={initVisibleDate?.getFullYear()}
      onDayClick={onDayClick}
      dayRender={(day) => {
        let disabled: boolean = false
        if (minDate && maxDate) {
          disabled = dateIsBefore(day, minDate) || dateIsAfter(day, maxDate)
        } else if (minDate || maxDate) {
          disabled = dateIsBefore(day, minDate) || dateIsAfter(day, maxDate)
        }

        const isStartDate = startDate && isSameDay(day, startDate)
        const isEndDate = !isStartDate && endDate && isSameDay(day, endDate)
        const isSelected = isStartDate || isEndDate
        const isInRange =
          !isSelected && startDate && endDate ?
            dateIsAfter(day, startDate) && dateIsBefore(day, endDate) :
            false

        const daysAfterStartDate = startDate ?
          roundedDateDifference(day, startDate, 'day') :
          undefined
        const showTooltipForThisDay =
          !!daysAfterStartDate && daysAfterStartDate > 0 && !endDate

        const cell = (
          <DayCell
            key={`${day.getMonth()}-${day.getDate()}`}
            disabled={disabled}
            className={clsx({
              'selected-date': isSelected,
              'in-range-date': isInRange,
            })}
            data-date={day.valueOf()}
            onClick={() => onDayClick(day)}
          >
            <BodyText variant="medium">{day.getDate()}</BodyText>
          </DayCell>
        )

        if (showTooltipForThisDay) {
          return (
            <Tooltip
              key={`${day.getMonth()}-${day.getDate()}`}
              description={pluralizeToString('night', daysAfterStartDate)}
            >
              {cell}
            </Tooltip>
          )
        } else {
          return cell
        }
      }}
    />
  )
}

export default DateRangePicker
