import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'
import { getInsuranceItemsView } from '../view/insurance'
import { isNoProtectionInsuranceItem } from 'lib/checkout/checkoutUtils'
import { checkoutWithDiscountedInsurance } from 'checkout/selectors/view/luxPlusSubscription'

export const getInsuranceTotals = createSelector(
  getInsuranceItemsView,
  checkoutWithDiscountedInsurance,
  (viewWithStatus, checkoutWithDiscountedInsurance): App.WithDataStatus<App.Checkout.ItemTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: {
        price: sum(definedItems, itemView =>
          checkoutWithDiscountedInsurance ? (itemView.memberPrice ?? 0) : (itemView.price ?? 0),
        ),
        memberPrice: 0,
        value: sum(definedItems, itemView => (itemView.value ?? 0)),
        surcharge: 0,
        taxesAndFees: 0,
      },
    }
  },
)

export const isNonProtectedInsurance = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items) => items.some(isNoProtectionInsuranceItem),
)
