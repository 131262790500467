import { getUnit, kmToMiles } from 'lib/geo/distanceUnits'

export function getSearchDistanceLabel(searchTargetLabel: string, distanceMeters: number, regionCode: string) {
  const km = distanceMeters / 1000
  const unit = getUnit(regionCode)
  const unitDistance = unit === 'km' ? km : kmToMiles(km)
  const precision = km < 0.5 ? 2 : 1
  const displayNumber = unitDistance.toFixed(precision)
  return unit === 'km' ? `${displayNumber} km from ${searchTargetLabel}` : `${displayNumber} mi from ${searchTargetLabel}`
}
