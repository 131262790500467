import {
  isBedbank,
  isCruiseOffer,
  isOrderOffer,
  isTourV2Offer,
} from 'lib/offer/offerTypes'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'

export default function offerPageURL(
  offer?: App.AnyOffer | App.OrderItemOffer,
  queryObj?: URLSearchParams,
): string {
  if (!offer) {
    return '/'
  }
  const queryString = queryObj ? queryObj.toString() : ''
  const URLSuffix = queryString ? `?${queryString}` : ''

  if (isOrderOffer(offer)) {
    return `/offer/${offer.slug}/${offer.id}${URLSuffix}`
  }

  if (isBedbank(offer)) {
    return `/partner/${offer.slug}/${offer.id}${URLSuffix}`
  }

  if (isTourV2Offer(offer)) {
    const cheapestPurchasableOption = findCheapestTourV2PurchasableOption(offer)

    if (!cheapestPurchasableOption) {
      return `/tour/${offer.id}`
    }

    return `/tour/${offer.id}/${cheapestPurchasableOption.fkVariationId}${URLSuffix}`
  }

  if (isCruiseOffer(offer)) {
    return `/cruises/${offer.id}${URLSuffix}`
  }

  return `/offer/${offer.slug}/${offer.id}${URLSuffix}`
}

export function getExperienceOfferPageURL(
  experience: App.ExperienceOffer,
  queryObj?: URLSearchParams,
) {
  const queryString = queryObj ? queryObj.toString() : ''
  const URLSuffix = queryString ? `?${queryString}` : ''

  return `/experience/${experience.slug}/${experience.id}${URLSuffix}`
}

export function getHotelOfferUrl(
  offerId: string,
  offerType: App.OfferType,
  queryObj?: URLSearchParams,
): string {
  const queryString = queryObj ? queryObj.toString() : ''
  const URLSuffix = queryString ? `?${queryString}` : ''
  if (offerType === 'bedbank_hotel') {
    return `/partner/hotel/${offerId}${URLSuffix}`
  }

  return `/offer/hotel/${offerId}${URLSuffix}`
}
