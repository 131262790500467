import request from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-customer-communication'
import { mapUserPreferencesOccupancy } from './mappers/userPreferencesMap'

// user preference endpoints
export function fetchUserPreferenceDestinationDefault(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations/defaults']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/destinations/defaults`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destinations)
}

export function fetchUserPreferenceDestination(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/destinations`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destinations)
}

type destinationRequestType = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['put']['parameters']['body']['payload'];

export function updateUserPreferenceDestination(userId: string, data: destinationRequestType, brand: string) {
  type requestType = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['put']['parameters']['body']['payload'];
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations']['put']['responses']['200']['content']['application/json']['result']
  return request.put<App.ApiResponse<responseType>, requestType>(`/api/customer-communication/v2/users/${userId}/preferences/destinations?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destination)
}

export function deleteUserPreferenceDestination(userId: string, placeId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/destinations/{placeId}']['delete']['responses']['200']['content']['application/json']['result']
  return request.delete<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/destinations/${placeId}`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.destination)
}

export function fetchUserPreferenceHolidayTypesDefault(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types/defaults']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types/defaults`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayTypes)
}

export function fetchUserPreferenceHolidayTypes(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayTypes)
}

type holidayTypeRequestType = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['put']['parameters']['body']['payload'];

export function updateUserPreferenceHolidayTypes(userId: string, data: holidayTypeRequestType, brand: string) {
  type requestType = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['put']['parameters']['body']['payload'];
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types']['put']['responses']['200']['content']['application/json']['result']
  return request.put<App.ApiResponse<responseType>, requestType>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayType)
}

export function deleteUserPreferenceHolidayTypes(userId: string, holidayTypeId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/holiday-types/{holidayTypeId}']['delete']['responses']['200']['content']['application/json']['result']
  return request.delete<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/holiday-types/${holidayTypeId}`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.holidayType)
}

export function fetchUserPreferenceExternalBrandsDefault(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands/defaults']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands/defaults`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrands)
}

export function fetchUserPreferenceExternalBrands(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrands)
}

type externalBrandRequestType = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['put']['parameters']['body']['payload'];

export function updateUserPreferenceExternalBrands(userId: string, data: externalBrandRequestType, brand: string) {
  type requestType = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['put']['parameters']['body']['payload'];
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands']['put']['responses']['200']['content']['application/json']['result']
  return request.put<App.ApiResponse<responseType>, requestType>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrand)
}

export function deleteUserPreferenceExternalBrands(userId: string, externalBrandId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/external-brands/{externalBrandId}']['delete']['responses']['200']['content']['application/json']['result']
  return request.delete<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/external-brands/${externalBrandId}`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.externalBrand)
}

export function fetchUserPreferenceOccupancyDefault(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy/schema']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy/schema`, {
    credentials: 'include',
  },
  ).then((res) => res.result.preferences.occupancy)
}

export function fetchUserPreferenceOccupancy(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy`, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferencesOccupancy(res.result))
}

type occupancyRequestType = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['put']['parameters']['body']['payload'];

export function updateUserPreferenceOccupancy(userId: string, data: occupancyRequestType, brand: string) {
  type requestType = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['put']['parameters']['body']['payload'];
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['put']['responses']['200']['content']['application/json']['result']
  return request.put<App.ApiResponse<responseType>, requestType>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy?brand=${brand}`, data, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferencesOccupancy(res.result))
}

export function deleteUserPreferenceOccupancy(userId: string) {
  type responseType = paths['/api/customer-communication/v2/users/{userId}/preferences/occupancy']['delete']['responses']['200']['content']['application/json']['result']
  return request.delete<App.ApiResponse<responseType>>(`/api/customer-communication/v2/users/${userId}/preferences/occupancy`, {
    credentials: 'include',
  },
  ).then((res) => res.result)
}
