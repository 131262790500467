import { definitions } from '@luxuryescapes/contract-svc-lux-loyalty'
import { arrayToObject } from 'lib/array/arrayUtils'

type ServerLuxLoyaltyAccount = definitions['accountSummary']
type ServerLuxLoyaltyProgramConfiguration = definitions['programConfiguration']

export function luxLoyaltyAccountMapper(response: ServerLuxLoyaltyAccount | null): App.LuxLoyaltyAccount['accountDetails'] {
  if (!response) return undefined

  return {
    points: response.points,
    tier: response.tier as App.LuxLoyaltyTier,
    statusCredits: response.statusCredits,
  }
}

export function luxLoyaltyProgramConfigMapper(response: ServerLuxLoyaltyProgramConfiguration): App.LuxLoyaltyProgramConfig['configDetails'] {
  return {
    programName: response.programName,
    currencyName: response.currencyName,
    statusCreditsName: response.statusCreditsName,
    tierLabel: response.tierLabel,
    tiers: arrayToObject(response.tiers, tier => tier.id),
  }
}
