import {
  API_CALL,
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
} from 'actions/actionConstants'
import { AnyAction, Middleware } from 'redux'

export interface ApiAction<T> extends AnyAction {
  type: 'API_CALL';
  api: string;
  request: () => Promise<T>;
  data?: T;
}

export type ApiActionRequest<F = {}> = {
  type: 'API_CALL_REQUEST',
  api: string;
} & F

export type ApiActionSuccess<T, F = {}> = {
  type: 'API_CALL_SUCCESS';
  api: string;
  data: T;
} & F

export type ApiActionFailure<E, F = {}> = {
  type: 'API_CALL_FAILURE';
  api: string;
  error: E;
} & F

export interface ApiDispatch {
  <T extends ApiAction<any>>(apiAction: T): T
}
export type ApiMiddleware<S> = Middleware<ApiDispatch, S>;

function isApiAction<T = unknown>(action: AnyAction): action is ApiAction<T> {
  return action.type === API_CALL
}

const apiMiddleware: ApiMiddleware<any> = (args) => {
  const { dispatch } = args
  return next => (action: AnyAction) => {
    if (isApiAction(action)) {
      const { request, ...rest } = action
      const apiRequest = request()
      // edge had an issue where apiRequest wasn't an instance of Promise weirdly
      if (['development', 'test'].includes(process.env.NODE_ENV) && !(apiRequest instanceof Promise)) {
        throw new Error(`Actions of type ${API_CALL} - the request property must be a function that returns a Promise.`)
      }
      apiRequest.then((res) => {
        dispatch({
          ...rest,
          data: res,
          type: API_CALL_SUCCESS,
        })
      }, (error) => {
        if (['development', 'test'].includes(process.env.NODE_ENV)) console.error(`Error during api call: ${rest.api}.`, error)
        dispatch({
          ...rest,
          error,
          type: API_CALL_FAILURE,
        })
      })

      return next({
        ...rest,
        type: API_CALL_REQUEST,
      })
    }

    return next(action)
  }
}

export default apiMiddleware
