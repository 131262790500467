import { ADMIN_ROLE, EMPLOYEE_ROLE } from 'constants/auth'
import config from 'constants/config'
import createSelector from 'lib/web/createSelector'
import showGeoRdirect from 'lib/geo/geoRedirect'
import { SvgIconColour } from 'components/Luxkit/SvgIcon'

export const shouldShowGeoRedirect = createSelector(
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.config.headlessMode,
  (accountRegion, currentRegion, headlessMode) => {
    return !headlessMode &&
      !config.agentHub.isEnabled &&
      config.currencyRedirectEnabled &&
      accountRegion &&
      currentRegion !== accountRegion &&
      !!config.regions.find(region => region.code === accountRegion) &&
      showGeoRdirect()
  },
)

// Suspect this is faster without reselect
// We can convert this to reselect if this ever gets more complex
export const getCurrentUserId = (state: App.State) => state.auth.account.memberId

export const selectLoggedIn = (state: App.State) => !!getCurrentUserId(state)

export const getUserPreferences = createSelector(
  (state: App.State) => state.userTravelPreferences.destinations,
  (state: App.State) => state.userTravelPreferences.holidayTypes,
  (state: App.State) => state.userTravelPreferences.externalBrands,
  (state: App.State) => state.userTravelPreferences.occupancy,
  (destinations, holidayTypes, externalBrands, occupancy) => {
    return {
      loading: destinations.initial || destinations.fetching || holidayTypes.initial || holidayTypes.fetching || externalBrands.initial || externalBrands.fetching || occupancy?.initial || occupancy?.fetching,
      data: {
        destinations: destinations.data,
        holidayTypes: holidayTypes.data,
        externalBrands: externalBrands.data,
        occupancy: occupancy?.data,
      },
    }
  },
)

export const hasUserPreferences = createSelector(
  getUserPreferences,
  (preferences) => {
    if (preferences.loading) {
      return false
    }
    for (const key in preferences.data) {
      const preference = preferences.data[key]
      if (preference) {
        if (key === 'occupancy' && !!preference.groupSize) {
          return true
        }
        else if (preference.length > 0) {
          return true
        }
      }
    }
    return false
  },
)

export const hasUserPreferencesNotification = createSelector(
  hasUserPreferences,
  selectLoggedIn,
  (hasPreferences, isLoggedIn) => !hasPreferences && isLoggedIn && config.USER_PREFERENCES_ENABLED,
)

export const hasPassedWalledGarden = createSelector(
  selectLoggedIn,
  (state: App.State) => state.utm.bp,
  (state: App.State) => state.config.previewMode,
  (isLoggedIn, bypassMode, previewMode) => isLoggedIn || previewMode || bypassMode === '2',
)

export const hasPassedNonEuropeanWalledGarden = createSelector(
  selectLoggedIn,
  (state: App.State) => state.config.previewMode,
  (isLoggedIn, previewMode) => isLoggedIn || previewMode,
)

export const hasPassedHidePricing = createSelector(
  selectLoggedIn,
  (state: App.State) => state.config.previewMode,
  (isLoggedIn, previewMode) => isLoggedIn || previewMode,
)

export const getRecommendationUserId = createSelector(
  getCurrentUserId,
  (state: App.State) => state.recommendations.userId,
  (currentUserId, recommendationUserId) => currentUserId || recommendationUserId,
)

export const hasIncompleteInformation = createSelector(
  selectLoggedIn,
  (state: App.State) => !config.ENABLE_EMAIL_OPT_IN || !!state.auth.account.emailVerified,
  (state: App.State) => !!state.auth.account.givenName && !!state.auth.account.surname,
  (isLoggedIn, emailVerified, nameCompleted) => isLoggedIn && !(emailVerified && nameCompleted),
)

export const hasAccountNotification = createSelector(
  hasIncompleteInformation,
  hasUserPreferencesNotification,
  selectLoggedIn,
  (hasIncompleteInformation, hasUserPreferencesNotification, isLoggedIn) => (hasIncompleteInformation || hasUserPreferencesNotification) && isLoggedIn,
)

export const getNotificationType = createSelector(
  hasIncompleteInformation,
  hasUserPreferencesNotification,
  (hasIncompleteInformation, hasUserPreferencesNotification): SvgIconColour => {
    if (hasIncompleteInformation) {
      return 'warning'
    }

    if (hasUserPreferencesNotification) {
      return 'highlight-primary-normal'
    }

    return 'warning'
  },
)

export const getCustomerDetails = createSelector(
  (state: App.State) => state.auth.addressDetails,
  (state: App.State) => state.auth.account.givenName,
  (state: App.State) => state.auth.account.surname,
  (state: App.State) => state.auth.account.email,
  (state: App.State) => state.auth.account.phone,
  (state: App.State) => state.auth.account.phonePrefix,
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.auth.gender || undefined,
  (state: App.State) => state.auth.title || undefined,
  (state: App.State) => state.auth.middleName || undefined,
  (addressDetails = {}, givenName, surname, email, phone, phonePrefix, countryCode, gender, title, middleName) => {
    return {
      addressDetails,
      givenName,
      surname,
      email,
      phone,
      phonePrefix,
      countryCode,
      gender,
      title,
      middleName,
    }
  },
)

export const isLEStaff = (state: App.State) => state.auth.account.roles.includes(EMPLOYEE_ROLE)
export const isLEAdmin = (state: App.State) => state.auth.account.roles.includes(ADMIN_ROLE)
export const isLEStaffOrAdmin = (state) => isLEAdmin(state) || isLEStaff(state)

export const getFirstName = createSelector(
  selectLoggedIn,
  (state: App.State) => state.auth.account.givenName,
  (isLoggedIn, givenName) => isLoggedIn ? givenName : '',
)

export const isMyProfileComplete = createSelector(
  (state: App.State) => state.auth.account.givenName,
  (state: App.State) => state.auth.account.surname,
  (state: App.State) => state.auth.account.email,
  (state: App.State) => state.auth.account.phone,
  (state: App.State) => state.auth.account.postcode,
  (state: App.State) => state.auth.account.countryCode,
  (state: App.State) => state.auth.account.dob,
  (
    givenName,
    surname,
    email,
    phone,
    postcode,
    countryCode,
    dob,
  ) => {
    return !!(givenName && surname && email && phone && postcode && countryCode && dob)
  },
)
