import request from 'api/requestUtils'
import { paths } from '@luxuryescapes/contract-customer-communication'
import { mapUserPreferences, mapUserPreferencesV2 } from './mappers/LESubscriptionsMap'

export function fetchLEUserSubscriptions(userId: string) {
  type responseType = paths['/api/customer-communication/v1/subscriptions/users/{userId}']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v1/subscriptions/users/${userId}`, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferences(res.result))
}

export function fetchLEUserSubscriptionsV2(userId: string) {
  type responseType = paths['/api/customer-communication/v2/subscriptions/users/{userId}']['get']['responses']['200']['content']['application/json']['result']
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v2/subscriptions/users/${userId}`, {
    credentials: 'include',
  },
  ).then((res) => mapUserPreferencesV2(res.result.subscriptions))
}

export function updateLEUserSubscriptions(data: any) {
  type responseType = paths['/api/customer-communication/v1/subscriptions/users']['post']['responses']['200']['content']['application/json']['result'];
  type requestType = paths['/api/customer-communication/v1/subscriptions/users']['post']['parameters']['body']['payload'];
  const response = request.post<responseType, requestType>('/api/customer-communication/v1/subscriptions/users', data, {
    credentials: 'include',
  },
  ).then(() => (data.subscriptions))

  return response
}

export function updateLEUserSubscriptionsV2(data: any) {
  type responseType = paths['/api/customer-communication/v2/subscriptions/users']['patch']['responses']['200']['content']['application/json'];
  type requestType = paths['/api/customer-communication/v2/subscriptions/users']['patch']['parameters']['body']['payload'];
  return request.patch<responseType, requestType>('/api/customer-communication/v2/subscriptions/users', data, {
    credentials: 'include',
  },
  ).then(res => mapUserPreferencesV2(res.result.subscriptions))
}

export function fetchResubscribeModalDismissedDate(userId: string) {
  type responseType = paths['/api/customer-communication/v1/modals/resubscribe/users/{userId}']['get']['responses']['200']['content']['application/json']['result'];
  return request.get<App.ApiResponse<responseType>>(`/api/customer-communication/v1/modals/resubscribe/users/${userId}`, {
    credentials: 'include',
  }).then(res => res.result.resubscribeModal.lastDismissedAt || null)
}

export function updateResubscribeModalDismissedDate(userId: string) {
  type responseType = paths['/api/customer-communication/v1/modals/resubscribe/users/{userId}/actions/{action}']['put']['responses']['200']['content']['application/json'];
  return request.put(`/api/customer-communication/v1/modals/resubscribe/users/${userId}/actions/dismissed`, {}, {
    credentials: 'include',
  }).then((res: responseType) => res.result.resubscribeModal.lastDismissedAt)
}

export function sendEmailResubscribePromoCode(userId: string) {
  return request.post('/api/customer-communication/v2/events', {
    userId,
    eventType: 'resubscribed-via-modal',
  }, { credentials: 'include' })
}

type FetchTravelPreferencesDismissResponseType = paths['/api/customer-communication/v2/users/{userId}/interactions/travel']['get']['responses']['200']['content']['application/json']['result'];
export function fetchTravelPreferencesModalDismissedDate(userId: string) {
  return request.get<App.ApiResponse<FetchTravelPreferencesDismissResponseType>>(`/api/customer-communication/v2/users/${userId}/interactions/travel`, {
    credentials: 'include',
  }).then(res => res.result.isTravelPrefViewed)
}

export function updateTravelPreferencesModalDismissedDate(userId: string, isTravelPrefViewed: boolean) {
  return request.put(`/api/customer-communication/v2/users/${userId}/interactions/travel`, { isTravelPrefViewed }, {
    credentials: 'include',
  })
}
