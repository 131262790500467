import React, { useCallback, useMemo, useState } from 'react'
import switchFunc from 'lib/function/switchFunc'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useScreenSizeUp } from 'hooks/useScreenSize'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { setTravelPreferencesModalViewed } from 'actions/LESubscriptionsActions'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import SolidCheckCircleIcon from 'components/Luxkit/Icons/solid/SolidCheckCircleIcon'
import TravelPreferencesModalMobileInitial from './TravelPreferencesModalSteps/TravelPreferencesModalMobileInitial'
import TravelPreferencesModalHolidayTypes from './TravelPreferencesModalSteps/TravelPreferencesModalHolidayTypes'
import TravelPreferencesModalDestinations from './TravelPreferencesModalSteps/TravelPreferencesModalDestinations'
import TravelPreferencesModalHotelBrands from './TravelPreferencesModalSteps/TravelPreferencesModalHotelBrands'
import TravelPreferencesModalTravellers from './TravelPreferencesModalSteps/TravelPreferencesModalTravellers'
import { ModalSteps } from './defaults'

const MODAL_SUBTITLE = {
  mobileInitial: undefined,
  holidayTypes: 'Step 1 of 4',
  destinations: 'Step 2 of 4',
  hotelBrands: 'Step 3 of 4',
  travellers: 'Step 4 of 4',
}

function TravelPreferencesModal() {
  const isTabletScreenSizeAndHigher = useScreenSizeUp('tablet')

  const [hasSavedPreferences, setHasSavedPreferences] = useState<boolean>(false)
  const [step, setStep] = useState<ModalSteps>(isTabletScreenSizeAndHigher ? ModalSteps.holidayTypes : ModalSteps.mobileInitial)
  const showBackButton = step !== ModalSteps.mobileInitial && step !== ModalSteps.holidayTypes

  const dispatch = useAppDispatch()
  const modalContext = useModalElementContext()

  const title = useMemo(() => switchFunc({
    mobileInitial: undefined,
    holidayTypes: isTabletScreenSizeAndHigher ? 'Want a personalised experience?' : 'Favourite stays',
    destinations: 'Your destinations',
    hotelBrands: 'Hotel brands',
    travellers: 'Your travellers',
  })(step), [isTabletScreenSizeAndHigher, step])

  const onShowSuccessSnackbar = useCallback(() => {
    showSnackbar('To update these preferences, go to My Account', 'success', { heading: 'Travel preferences saved', icon: <SolidCheckCircleIcon /> })
  }, [])

  const onClose = useCallback(() => {
    dispatch(setTravelPreferencesModalViewed())
    modalContext.resolve()

    // Show success snackbar if user has saved any step
    if (hasSavedPreferences) {
      onShowSuccessSnackbar()
    }
  }, [dispatch, modalContext, hasSavedPreferences, onShowSuccessSnackbar])

  const onSaveLastStep = useCallback(() => {
    dispatch(setTravelPreferencesModalViewed())
    modalContext.resolve()
    onShowSuccessSnackbar()
  }, [dispatch, modalContext, onShowSuccessSnackbar])

  const onSetNextStep = useCallback((step: ModalSteps, isSaveAction?: boolean) => {
    setStep(step)

    if (isSaveAction) {
      setHasSavedPreferences(true)
    }
  }, [])

  const onBack = useCallback(() => {
    if (step === ModalSteps.destinations) return onSetNextStep(ModalSteps.holidayTypes)
    if (step === ModalSteps.hotelBrands) return onSetNextStep(ModalSteps.destinations)
    if (step === ModalSteps.travellers) return onSetNextStep(ModalSteps.hotelBrands)
  }, [onSetNextStep, step])

  return (
    <ModalBase size="L" onClose={onClose} height={step === ModalSteps.mobileInitial ? 'auto' : 'full'} tabletHeight="auto">
      <ModalHeader
        title={title}
        subtitle={MODAL_SUBTITLE[step]}
        isOverlay={step === ModalSteps.mobileInitial}
        dismissible={step !== ModalSteps.mobileInitial}
        onBackButtonClick={showBackButton ? onBack : undefined}
        onCloseButtonClick={onClose}
      />
      {step === ModalSteps.mobileInitial && <TravelPreferencesModalMobileInitial onSetNextStep={onSetNextStep} onClose={onClose} />}
      {step === ModalSteps.holidayTypes && <TravelPreferencesModalHolidayTypes onSetNextStep={onSetNextStep} />}
      {step === ModalSteps.destinations && <TravelPreferencesModalDestinations onSetNextStep={onSetNextStep} />}
      {step === ModalSteps.hotelBrands && <TravelPreferencesModalHotelBrands onSetNextStep={onSetNextStep} />}
      {step === ModalSteps.travellers && <TravelPreferencesModalTravellers onSaveLastStep={onSaveLastStep} />}
    </ModalBase>
  )
}

export default TravelPreferencesModal
