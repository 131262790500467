import IconButton from 'components/Luxkit/Button/IconButton'
import cn from 'clsx'
import LineAngleLeftIcon from 'components/Luxkit/Icons/line/LineAngleLeftIcon'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import BodyText from '../Typography/BodyText'
import BaseImageCarousel, { ImageCarouselRefInterface } from './BaseImageCarousel'
import { EmptyArray } from 'lib/array/arrayUtils'
import { themeClassName } from 'lib/theme/themeUtils'

const NavigationArrow = styled(IconButton)`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
  z-index: 1;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 0.2s;

  &.right {
    transform: translateY(-50%);
    left: unset;
    right: 12px;
  }

  ${mediaQueryUp.tablet} {
    display: inline-flex;
  }
`

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  &.arrow-interaction-visible {
    ${NavigationArrow} {
      opacity: 0;
      pointer-events: none;
    }

    &:hover, .input-keyboard & {
      ${NavigationArrow} {
        opacity: 0.6;
        pointer-events: auto;

        &:hover, &:focus {
          opacity: 1;
        }
      }
    }
  }

  &.arrow-hidden {
    ${NavigationArrow} {
      display: none;
    }
  }

`

const CarouselOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: ${rem(16)};
  pointer-events: none;

  display: grid;
  gap: ${rem(8)};
  grid-template-areas:
    "top-left top-right"
    "bottom-left bottom-right";

  > * {
    max-width: 100%;
    overflow: hidden;
  }

  > .top-left {
    justify-self: flex-start;
    align-self: flex-start;
    grid-area: top-left;
  }

  > .top-right {
    justify-self: flex-end;
    align-self: flex-start;
    grid-area: top-right;
  }

  > .bottom-left {
    justify-self: flex-start;
    align-self: flex-end;
    grid-area: bottom-left;
  }

  > .bottom-right {
    justify-self: flex-end;
    align-self: flex-end;
    grid-area: bottom-right;
  }
`

const OverlayWrapper = styled.div`
  padding: ${rem(4)} ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.S};
  background-color: ${props => props.theme.palette.neutral.default.one20};
`

interface Props extends React.ComponentProps<typeof BaseImageCarousel> {
  /**
   * How the arrows that navigate the images (next/previous) should behave.
   * Interaction-visible means they only become visible when a user interactions with the carousel via focus or mouse hover
   **/
  arrowBehaviour?: 'always-visible' | 'interaction-visible' | 'hidden';
  /** Whether or not to add the image titles in the bottom left hand corner along with the counter */
  showImageTitles?: boolean;
  /** 'slot' in the top left corner of carousel to place content, e.g. offer type labels */
  topLeft?: React.ReactNode;
  /** 'slot' in the top right corner of carousel to place content, e.g. offer badges */
  topRight?: React.ReactNode;
  /**
   * 'slot' in the bottom left corner of carousel to place content, e.g. offer type labels
   * If not specified will show an image counter
   * */
  bottomLeft?: React.ReactNode;
  /** 'slot' in the bottom right corner of carousel to place content, e.g. offer type labels */
  bottomRight?: React.ReactNode
}

function ImageCarousel(props: Props) {
  const {
    images,
    className,
    carouselRef,
    onImageChange,
    onImageLoad,
    arrowBehaviour = 'interaction-visible',
    interstitials = EmptyArray,
    bottomLeft,
    bottomRight,
    topLeft,
    topRight,
    showImageTitles,
    defaultIndex = 0,
    ...baseCarouselProps
  } = props

  const [currentImage, setCurrentImage] = useState<{ index: number, image?: App.Image }>({ index: defaultIndex })
  const localCarouselRef = useRef<ImageCarouselRefInterface>(null)

  useImperativeHandle(carouselRef, () => localCarouselRef.current!)

  const onArrowClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    const direction = e.currentTarget.dataset.direction as any
    if (direction === 'left') {
      localCarouselRef.current?.previousImage()
    } else {
      localCarouselRef.current?.nextImage()
    }
  }

  const imageItemCount = images.length + interstitials.length

  const bottomLeftText = useMemo(() => {
    const imageAltText = currentImage.image?.title

    const text: Array<string> = []
    if (imageItemCount > 1) {
      text.push(`${currentImage.index + 1} / ${imageItemCount}`)
    }
    if (showImageTitles && imageAltText) {
      text.push(imageAltText)
    }

    return text.join(' - ')
  }, [currentImage, imageItemCount, showImageTitles])

  const updateCurrentImage = useCallback((index: number, image?: App.Image) => {
    setCurrentImage({ index, image })
    onImageChange?.(index, image)
  }, [onImageChange])

  return (<Container className={cn(themeClassName('default'), className, `arrow-${arrowBehaviour}`)}>
    <CarouselOverlay>
      {!!topLeft && <div className="top-left">{topLeft}</div>}
      {!!topRight && <div className="top-right">{topRight}</div>}
      <div className="bottom-left">
        {bottomLeft}
        {!bottomLeft && !!bottomLeftText && <OverlayWrapper>
          <BodyText variant="small" colour="neutral-eight" wrap="truncate" title={bottomLeftText}>{bottomLeftText}</BodyText>
        </OverlayWrapper>
        }
      </div>
      {!!bottomRight && <div className="bottom-right">{bottomRight}</div>}
    </CarouselOverlay>
    {imageItemCount > 1 &&
      <NavigationArrow
        onClick={onArrowClick}
        kind="primary"
        variant="dark"
        shape="circle"
        data-direction="left"
      >
        <LineAngleLeftIcon />
      </NavigationArrow>
    }
    <BaseImageCarousel
      {...baseCarouselProps}
      images={images}
      carouselRef={localCarouselRef}
      onImageChange={updateCurrentImage}
      onImageLoad={onImageLoad}
      interstitials={interstitials}
      defaultIndex={defaultIndex}
    />
    {imageItemCount > 1 &&
      <NavigationArrow
        onClick={onArrowClick}
        className="right"
        variant="dark"
        kind="primary"
        shape="circle"
        data-direction="right"
      >
        <LineAngleRightIcon />
      </NavigationArrow>
    }
  </Container>)
}

export default React.memo(ImageCarousel)
