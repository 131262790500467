function getValidBusinessBudgetObjectKeys(businessBudget: BusinessTraveller.ToBeCalculatedBusinessBudget) {
  return Object.keys(businessBudget)
    .filter((key) => businessBudget[key] !== null && businessBudget[key] !== undefined)
    .sort()
}

function generateBusinessBudgetKey(
  toBeCalculatedBusinessBudget: BusinessTraveller.ToBeCalculatedBusinessBudget,
): string {
  const budgetKey = JSON.stringify(
    toBeCalculatedBusinessBudget,
    getValidBusinessBudgetObjectKeys(toBeCalculatedBusinessBudget),
  )
  return budgetKey
}

export default generateBusinessBudgetKey
