import { getInsuranceCountries, getInsuranceCountryNames, getInsuranceCountryNameByTwoLetterCode } from '@luxuryescapes/lib-regions'

export const countryNameList = getInsuranceCountryNames()

const countriesByName = getInsuranceCountries().reduce((acc, country) => {
  acc[country.name] = country
  return acc
}, {})

export const findByName = (name: string) => countriesByName[name]

export const findByTwoLetterCode = (code: string) => getInsuranceCountryNameByTwoLetterCode(code)
