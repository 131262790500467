import config from 'constants/config'

type ImageFormat = 'jpeg' | 'jpg' | 'png' | 'webp' | 'svg' | 'avif'

export interface ResponsiveImageOptions {
  width?: number | string;
  height?: number | string;
  aspectRatio?: string;
  gravity?: 'auto' | 'center' | 'east' | 'north' | 'northeast' | 'northwest' | 'south' | 'southeast' | 'southwest' | 'west' // auto is centre
  greyscale?: boolean;
  brightness?: number;
  type?: string;
  dpr?: 1 | 2 | 3;
  highRes?: boolean;
  version?: string;
  format?: ImageFormat;
  quality?: 'best' | 'good' | 'eco' | 'low';
}

export function getSimpleImageUrl(imageId: string, format?: ImageFormat) {
  return `${config.IMAGE_HOST}/${imageId}${format ? `.${format}` : ''}`
}

export function getSVGImageUrl(imageId: string) {
  return getSimpleImageUrl(imageId, 'svg')
}

export function getImageUrl(imageId: string | number, options: ResponsiveImageOptions = {}) {
  const {
    height,
    width,
    aspectRatio,
    gravity,
    greyscale,
    brightness,
    type,
    highRes,
    version,
    format,
    quality,
    dpr,
  } = options

  // Base options
  let optionString = 'fl_progressive,q_auto:eco'
  let extension = ''

  if (type === 'svg') {
    optionString = 'q_auto:best'
  }

  if (highRes) {
    optionString = 'q_80'
  } else if (quality) {
    optionString = `q_auto:${quality}`
  }

  const widthOption = width ? `,w_${width}` : ''
  const heightOption = height ? `,h_${height}` : ''
  // Extra options
  if (aspectRatio) {
    const gravityOption = gravity ? `,g_${gravity}` : ',g_auto'
    optionString += `,c_fill${gravityOption}${widthOption},ar_${aspectRatio}`
  }

  else if (width || height) {
    optionString += `,c_scale${widthOption}${heightOption}`
  }

  if (greyscale) {
    optionString += ',e_grayscale'
  }

  if (brightness) {
    optionString += `,e_brightness_hsb:${brightness}`
  }

  if (dpr) {
    optionString += `,dpr_${dpr}.0`
  }

  if (version) {
    optionString = `${version}/${optionString}`
  }

  if (format) {
    extension = `.${format}`
  }

  const uri = `${optionString}/${imageId}${extension}`

  return `${config.IMAGE_HOST}/${uri}`
}
