import FormatCurrency from 'components/Common/FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import React, { ComponentProps, ReactNode, useMemo } from 'react'
import Typography from '../Typography/Typography'
import PriceRowValueDiscount from './Value/PriceRowValueDiscount'

interface Props {
  size: 'S' | 'M' | 'L'
  /**
   * Leading text
   * @example From $999.99
   */
  caption?: string
  /**
   * Main price
   */
  price: number
  /**
   * @default roundedDollar
   */
  priceFormat?: ComponentProps<typeof FormatCurrency>['format']
  /**
   * @default neutral-one
   */
  priceColour?: 'neutral-one' | 'lux-plus'
  /**
   * @default highlight-secondary
   */
  priceHighlightColour?: 'highlight-secondary'
  /**
   * Node before the main price
   */
  priceLeading?: ReactNode
  /**
   * Node after the sale unit
   */
  priceTrailing?: ReactNode
  /**
   * @example $999.99 /room
   * @example $999.99 total
   *
   * A leading "/" will be prepended for non-"total"
   */
  saleUnit: string
  /**
   * @default neutral-two
   */
  saleUnitColour?: 'neutral-two' | 'lux-plus'
  discountPercentage?: number
  discountType?: ComponentProps<typeof PriceRowValueDiscount>['type']
  signDisplay?: ComponentProps<typeof FormatCurrency>['signDisplay']
  'data-testid'?: string
  className?: string
  /**
   * @default no-wrap
   */
  wrap?: 'word-wrap' | 'no-wrap'
}

const SIZES_HEADING_VARIANTS: Record<Props['size'], ComponentProps<typeof Heading>['variant']> = {
  S: 'heading6',
  M: 'heading5',
  L: 'heading4',
}

export const SIZES_CAPTION_VARIANTS: Record<Props['size'], ComponentProps<typeof Caption>['variant']> = {
  S: 'medium',
  M: 'large',
  L: 'large',
}

/**
 * This component shall not be used directly.
 * Please use `<PriceRowPrice>` or `<PriceRowPriceWithCaption>` instead.
 */
function _PriceRowPrice(props: Props) {
  const {
    size,
    caption,
    price,
    priceFormat = 'roundedDollar',
    priceColour = 'neutral-one',
    priceLeading,
    priceTrailing,
    saleUnit,
    saleUnitColour = 'neutral-two',
    discountPercentage,
    discountType,
    signDisplay,
    wrap,
    'data-testid': TestId,
    className,
  } = props

  const saleUnitLabel = useMemo(() => {
    if (!saleUnit) return undefined
    const sanitisedSaleUnit = saleUnit.toLocaleLowerCase().replace(/^\//, '')
    if (/^(total|with)/.test(sanitisedSaleUnit)) return sanitisedSaleUnit
    return `/${sanitisedSaleUnit}`
  }, [saleUnit])

  return <Typography wrap={wrap} data-testid={TestId} className={className}>
    {!!caption && <>
      <Caption
        as="span"
        variant={SIZES_CAPTION_VARIANTS[size]}
        colour="neutral-two"
        weight="normal"
        format="propercase"
      >
        {caption}
      </Caption>
      {' '}
    </>}
    {priceLeading}
    <Heading
      as="span"
      variant={SIZES_HEADING_VARIANTS[size]}
      colour={priceColour}
      wrap="no-wrap"
    >
      <FormatCurrency value={price} format={priceFormat} signDisplay={signDisplay} />
    </Heading>
    {saleUnitLabel && <>
      {' '}
      <Caption
        as="span"
        variant={SIZES_CAPTION_VARIANTS[size]}
        colour={saleUnitColour}
        weight="normal"
        format="lowercase"
      >
        {saleUnitLabel}
      </Caption>
    </>}
    {priceTrailing}
    {!!discountPercentage && <>
      {' '}
      <PriceRowValueDiscount
        discountPercentage={discountPercentage}
        type={discountType}
      />
    </>}
  </Typography>
}

export default _PriceRowPrice
