import { get, remove, set } from 'lib/storage/versionedLocalStorageUtils'

// Redux state
// Increment version when we change the state structure in an incompatible way.
const REDUX_SELECTED_TRAVELLER_STATE_KEY = 'BusinessTravellerSelectedTraveller'
const REDUX_SELECTED_TRAVELLER_STATE_VERSION = 1

export function saveBusinessTravellerSelectedTravellerState(state: Array<string>) {
  set(REDUX_SELECTED_TRAVELLER_STATE_KEY, REDUX_SELECTED_TRAVELLER_STATE_VERSION, state)
}

export function getBusinessTravellerSelectedTravellerState(): Array<string> {
  return get(REDUX_SELECTED_TRAVELLER_STATE_KEY, REDUX_SELECTED_TRAVELLER_STATE_VERSION)
}

export function clearBusinessTravellerSelectedTraveller() {
  remove(REDUX_SELECTED_TRAVELLER_STATE_KEY)
}
