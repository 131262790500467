export const BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE = 20000
export const BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP = {
  AUD: 20000,
  USD: 15000,
  NZD: 22000,
  GBP: 10500,
  SGD: 17900,
  CAD: 18100,
  CNY: 95500,
  EUR: 12300,
  HKD: 103000,
  IDR: 212200000,
  ILS: 49500,
  JPY: 2000000,
  KRW: 18000000,
  MOP: 106000,
  MYR: 62000,
  PHP: 750000,
  QAR: 48200,
  RUB: 1200000,
  SAR: 49600,
  TWD: 428000,
  THB: 480000,
  ZAR: 244600,
  AED: 48600,
  VND: 330000000,
}

export enum BOOKING_PROTECTION_RISK_TYPE_MAP {
  HOTEL = 'Hotel',
  FLIGHT = 'Flight',
  BUNDLED = 'Bundled',
}

/**
 * This ID represents the "Don't add protection" option for booking protection
 * It is used as the `quoteId`. So any quotes with this quote id should be ignored
 * for purposes of actual purchase
 */
export const NO_BOOKING_PROTECTION_QUOTE_ID = 'no-booking-protection'

/**
 * This is the maximum days in the future booking protection is applicable from the
 * start date of the 'booking'
 */
export const BOOKING_PROTECTION_ELIGABILITY_DAY_RANGE = 60
