import luxuryEscapesTheme, { luxuryEscapesBorderRadii, luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'
import { baseThemeShadows } from './shadows'

export const leBusinessTravellerDefaultPalette: App.Palette = {
  ...luxuryEscapesDefaultPalette,
}

export const leBusinessTravellerInversePalette: App.Palette = {
  ...luxuryEscapesInversePalette,
}

export const leBusinessTravellerShadows: App.ThemeShadows = { ...baseThemeShadows }
export const leBusinessTravellerBorderRadii: App.ThemeBorderRadii = { ...luxuryEscapesBorderRadii }

const leBusinessTravellerTheme: App.Theme = {
  ...luxuryEscapesTheme,
}

export default leBusinessTravellerTheme
