import { useAppSelector } from 'hooks/reduxHooks'
import React, { PropsWithChildren } from 'react'
import ModalProvider from './ModalProvider'

const CurrencyContext = React.createContext<string>('AUD')

interface Props {
  currency?: string;
}

export function CurrencyProvider(props: PropsWithChildren<Props>) {
  const currentCurrency = useAppSelector((state) => state.geo.currentCurrency)

  return <CurrencyContext.Provider value={props.currency ?? currentCurrency}>
    <ModalProvider>
      {props.children}
    </ModalProvider>
  </CurrencyContext.Provider>
}

export default CurrencyContext
