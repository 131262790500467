import { useAppSelector } from 'hooks/reduxHooks'
import { getAvailableInsuranceBenefits } from 'luxPlus/selectors/benefits/insurance'

function useLuxPlusInsuranceAvailableCopy() {
  const luxPlusInsuranceBenefitView = useAppSelector(getAvailableInsuranceBenefits)
  if (luxPlusInsuranceBenefitView.isAvailable && !!luxPlusInsuranceBenefitView.totalMemberMaxSavings) {
    return `Save up to ${luxPlusInsuranceBenefitView.luxPlusDiscountPercentage}% on travel protection at checkout`
  }
}

export default useLuxPlusInsuranceAvailableCopy
