const codes = [
  {
    param: 'utm_source',
    key: 'source',
  },
  {
    param: 'utm_medium',
    key: 'medium',
  },
  {
    param: 'utm_bp',
    key: 'bp',
  },
  {
    param: 'utm_campaign',
    key: 'campaign',
  },
  {
    param: 'utm_content',
    key: 'content',
  },
  {
    param: 'utm_term',
    key: 'term',
  },
  {
    param: 'utm_ad',
    key: 'ad',
  },
  {
    param: 'utm_adgroup',
    key: 'adgroup',
  },
  {
    param: 'utm_fb',
    key: 'fb',
  },
  {
    param: 'utm_google',
    key: 'google',
  },
  {
    param: 'utm_join',
    key: 'join',
  },
  {
    param: 'utm_tl',
    key: 'tl',
  },
  {
    param: 'utm_store_join',
    key: 'store_join',
  },
  {
    param: 'gclid',
    key: 'gclid',
  },
  {
    param: 'fbclid',
    key: 'fbclid',
  },
  {
    param: 'irclickid',
    key: 'irclickid',
  },
]

export function toUtmParams(utm: App.UTMState) {
  return codes.reduce((accumulator, currentValue) => {
    const value = utm[currentValue.key]
    if (value) {
      accumulator[currentValue.param] = value
    }
    return accumulator
  }, {})
}

export default function utmCodes(
  query: { [param: string]: any },
): App.UTMState {
  return codes.reduce((accumulator, currentValue) => {
    const param = query[currentValue.param]
    const processParam = param => currentValue.param === 'utm_tl' ? param : param.toLowerCase()

    if (param) {
      if (param instanceof Array) {
        accumulator[currentValue.key] = processParam(param[0])
      } else {
        accumulator[currentValue.key] = processParam(param)
      }
    }
    return accumulator
  }, {})
}
