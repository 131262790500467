import Group from 'components/utils/Group'
import React from 'react'
import LuxLoyaltyPointsListItem, { BaseLuxLoyaltyPointsListItemProps } from './LuxLoyaltyPointsListItem'

interface Props extends BaseLuxLoyaltyPointsListItemProps {
  items: Array<React.ReactNode>
}

function LuxLoyaltyStackPointsListItems(props: Props) {
  const { items, tier, size } = props

  return <Group
    direction="vertical"
    gap={8}
  >
    {items.map((item, idx) => (
      <LuxLoyaltyPointsListItem
        key={idx}
        tier={tier}
        size={size}
      >
        {item}
      </LuxLoyaltyPointsListItem>
    ))}
  </Group>
}

export default LuxLoyaltyStackPointsListItems
