import { useEffect, useRef } from 'react'

import { setOptimizelyExperiment, setOptimizelyFeatureFlag } from 'actions/OptimizelyActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import config from 'constants/config'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import { isFeatureFlagExperiment, getOptimizelyFeatureFlagVariation, getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'

/**
 * Optimizely Experiments Selectors.  Each experiment can be found in Redux in state.optimizely.optimizelyExperiments.
 * Each has an 'enabled' value that can be used to determine if the user is enrolled in a variant (if the user is
 * part of the control, or the test isn't live in Optimizely, the value will be false).  Each experiment also has
 * a 'variationKey' value that can be used to determine which variant the user is enrolled in.
 *
 * Return the optimizely experiment variant dynamically based on the given trigger boolean condition.
 * If canTriggerExperiment is false the experiment will not be activated.
 * Note: once the trigger becomes true once, it will be enabled for the rest of the session until page refresh.
 *
 * @param canTriggerExperiment a boolean indicating whether the experiment can be triggered
 * @param experimentId experiment id from the Optimizely UI (Rule Key)
 * @returns Experiment variant if can be triggered
 */
function useOptimizelyExperiment(experimentId: OptimizelyExperiments | OptimizelyFeatureFlags, canTriggerExperiment: boolean = true) {
  const dispatch = useAppDispatch()
  const experimentActivated = useRef(false)

  useEffect(() => {
    if (canTriggerExperiment && !experimentActivated.current) {
      if (isFeatureFlagExperiment(experimentId)) {
        dispatch(setOptimizelyFeatureFlag(experimentId))
      } else {
        dispatch(setOptimizelyExperiment(experimentId))
      }
      experimentActivated.current = true
    }
  }, [dispatch, canTriggerExperiment, experimentId])

  const experimentVariation = useAppSelector(state => {
    if (isFeatureFlagExperiment(experimentId)) {
      return getOptimizelyFeatureFlagVariation(state, experimentId)
    } else {
      return getOptimizelyExperimentVariation(state, experimentId)
    }
  })

  if (canTriggerExperiment) {
    // For devs to set a specific variation locally. Set REACT_APP_OPTIMIZELY_[experimentId]=true for variant or false for control in .env,
    if (config[`OPTIMIZELY_${experimentId}`] !== undefined) {
      return config[`OPTIMIZELY_${experimentId}`]
    }

    return experimentVariation
  }
}

export default useOptimizelyExperiment
