import { definitions } from '@luxuryescapes/contract-trip'
import moment, { Moment } from 'moment'
import { match, P } from 'ts-pattern'

import { TripItem } from '../types/tripItem'
import { assertUnreachable, makeDate, makeDateTime } from '../utils/index'

import { DAY_MONTH_NAME, SHORT_TIME_FORMAT_AM_PM } from 'constants/dateFormats'
import {
  BasicTrip,
  EndDateTimeMoment,
  EndDateTimeString,
  FullTrip,
  RefreshedTrip,
  StartDateTimeMoment,
  StartDateTimeString,
  TripMetadata,
  TripLocation,
  TripSummary,
  ExperienceRecommendationData,
  HotelRecommendationData,
  FlightRecommendationData,
  TripSummaryEntry,
  FlightSchedule,
  LayoverRecommendationData,
  CreateItemsBatchResponse,
} from 'tripPlanner/types/common'

export function extractStartDateTime<I extends Partial<StartDateTimeString>>(
  item: I,
): StartDateTimeMoment {
  const startDateTime: StartDateTimeMoment = {
    startTime: undefined,
    startDate: undefined,
    startUtcDateTime: undefined,
  }

  if (item.startDate) {
    startDateTime.startDate = makeDate(item.startDate)

    if (item.startTime) {
      startDateTime.startTime = makeDateTime(item.startDate, item.startTime)
    }

    if (item.startUtcDateTime) {
      startDateTime.startUtcDateTime = makeDate(item.startUtcDateTime)
    }
  }

  // to display start time in templates when startDate is not available
  if (!item.startDate) {
    if (item.startTime) {
      startDateTime.startTime = makeDateTime(
        new Date().toISOString().substring(0, 10),
        item.startTime,
      )
    }
  }

  return startDateTime
}

export function extractEndDateTime<I extends Partial<EndDateTimeString>>(
  item: I,
): EndDateTimeMoment {
  const endDateTime: EndDateTimeMoment = {
    endTime: undefined,
    endDate: undefined,
    endUtcDateTime: undefined,
  }

  if (item.endDate) {
    endDateTime.endDate = makeDate(item.endDate)

    if (item.endTime) {
      endDateTime.endTime = makeDateTime(item.endDate, item.endTime)
    }

    if (item.endUtcDateTime) {
      endDateTime.endUtcDateTime = makeDate(item.endUtcDateTime)
    }
  }

  // to display end time in templates when endDate is not available
  if (!item.endDate) {
    if (item.endTime) {
      endDateTime.endTime = makeDateTime(
        new Date().toISOString().substring(0, 10),
        item.endTime,
      )
    }
  }

  return endDateTime
}

export const tripItems = (
  items: Array<definitions['tripItem']>,
): Array<TripItem> => items.map((i) => tripItem(i, i.tripId))

export const tripItem = (
  item: definitions['tripItem'],
  tripId: string,
): TripItem => {
  switch (item.type) {
    case 'EVENT':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }

    case 'EXPERIENCE':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'ACCOMMODATION':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        savedItemData: item.savedItemData,
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'RESTAURANT_BAR':
    case 'ATTRACTION':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'BUS':
    case 'FERRY':
    case 'OTHER_TRANSPORT':
    case 'TRAIN':
    case 'THING_TO_DO':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'FLIGHT':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        confirmationItemCodes: item.confirmationItemCodes || [],
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'CAR_RENTAL':
    case 'CRUISE':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'TOUR':
      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        savedItemData: item.savedItemData,
        startTimezone: item.startTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        endTimezone: item.endTimezone, // TODO: it shouldn't be optional in the contract. Remove this line when it's fixed
        isBooked: item.isBooked ?? false,
      }
    case 'NOTE':
      let noteContent = item.notes
      if (item.link) {
        noteContent += `\n\n${item.link}`
      }

      return {
        ...item,
        createdAt: makeDate(item.createdAt),
        updatedAt: makeDate(item.updatedAt),
        userUpdatedAt: makeDate(item.userUpdatedAt ?? item.updatedAt),
        tripId,
        ...extractStartDateTime(item),
        ...extractEndDateTime(item),
        title: item.title,
        notes: noteContent,
        isSectionHeader: !!item.isSectionHeader,
      }
    case 'HOTEL_BUNDLE':
      // Note for when it's time to implement this: Don't forget to do it in src\tripPlanner\mappers\publicTrip.ts too!
      throw new Error('HOTEL_BUNDLE is not supported yet')
    default:
      return assertUnreachable(item)
  }
}

export const fullTrip = (trip: definitions['fullTrip']): FullTrip => {
  const items = trip.items.map((i) => tripItem(i, trip.id))

  return {
    ...trip,
    items,
    createdAt: makeDate(trip.createdAt),
    updatedAt: makeDate(trip.updatedAt),
    interactedAt: makeDate(trip.interactedAt),
    startDate: trip.startDate ? makeDate(trip.startDate) : undefined,
    endDate: trip.endDate ? makeDate(trip.endDate) : undefined,
    isTemplate: trip.isTemplate,
    isConciergeTrip: trip.isConciergeTrip,
  }
}

export const createItemsBatchResponse = (
  response: definitions['createItemsBatchResponse'],
): CreateItemsBatchResponse => {
  return {
    ...fullTrip(response),
    updatedItemIds: response.updatedItemIds,
  }
}

export const tripMetadataLocation = (
  location: definitions['tripMetadata']['locations'][0],
): TripLocation => {
  return {
    ...location,
    ...{
      startDate: location.startDate ? makeDate(location.startDate) : undefined,
      endDate: location.endDate ? makeDate(location.endDate) : undefined,
      accommodation: location.accommodation ?
          {
            startDate: makeDate(location.accommodation.startDate),
            endDate: makeDate(location.accommodation.endDate),
          } :
        undefined,
      experiences: location.experiences ?
          {
            startDate: makeDate(location.experiences.startDate),
            endDate: makeDate(location.experiences.endDate),
          } :
        undefined,
      transportTo: location.transportTo ?
          {
            arrivalDate: makeDate(location.transportTo.arrivalDate),
          } :
        undefined,
      transportFrom: location.transportFrom ?
          {
            departureDate: makeDate(location.transportFrom.departureDate),
          } :
        undefined,
    },
  }
}

export const tripMetadata = (
  tripMetadata: definitions['tripMetadata'],
): TripMetadata => {
  const locations = tripMetadata.locations.map(tripMetadataLocation)
  return {
    ...tripMetadata,
    locations,
  }
}

// We're going to want to do further processing on the data eventually
export const experienceRecommendationData = (
  data,
): ExperienceRecommendationData => {
  return data
}

export const flightRecommendationData = (data): FlightRecommendationData => {
  return {
    ...data,
    departDate: makeDate(data.departDate),
    returnDate: makeDate(data.returnDate),
  }
}

export const hotelRecommendationData = (data): HotelRecommendationData => {
  return {
    ...data,
    startDate: makeDate(data.startDate),
    endDate: makeDate(data.endDate),
  }
}

export const formattedLayoverDuration = (
  startDateTime: Moment,
  endDateTime: Moment,
): string => {
  const diff = endDateTime.diff(startDateTime)
  const duration = moment.duration(diff)
  if (duration.asMinutes() < 60) return `${duration.minutes()} m`
  if (duration.asHours() < 24 && duration.asMinutes() % 60 !== 0)
  { return `${duration.hours()}h ${duration.minutes()}m` }
  return duration.humanize(false) // isn't granular enough at low durations
}

export const layoverRecommendationData = (data): LayoverRecommendationData => {
  const startDateTime = makeDateTime(data.startDate, data.startTime)
  const endDateTime = makeDateTime(data.endDate, data.endTime)
  const startUtcDateTime = moment(data.startUtcDateTime)
  const endUtcDateTime = moment(data.endUtcDateTime)
  const layoverDurationText = `${startDateTime.format(
    DAY_MONTH_NAME,
  )}, ${startDateTime.format(SHORT_TIME_FORMAT_AM_PM)} - ${endDateTime.format(
    SHORT_TIME_FORMAT_AM_PM,
  )} • ${formattedLayoverDuration(startUtcDateTime, endUtcDateTime)}`
  return {
    ...data,
    startDate: makeDate(data.startDate),
    endDate: makeDate(data.endDate),
    startUtcDateTime,
    endUtcDateTime,
    layoverDurationText,
  }
}

export const tripSummary = (
  tripSummary: definitions['tripSummary'],
): TripSummary => {
  const entries = tripSummary.entries
    .map((s) => {
      return match(s)
        .with(
          { type: 'ITEM', item: P.select() },
          (item): TripSummaryEntry => ({
            type: 'ITEM',
            item: tripItem(item, tripSummary.id),
          }),
        )
        .with(
          {
            type: 'RECOMMENDATION',
            recommendation: { type: 'EXPERIENCE', data: P.select() },
          },
          (r): TripSummaryEntry => ({
            type: 'RECOMMENDATION',
            recommendation: {
              type: 'EXPERIENCE',
              data: experienceRecommendationData(r),
            },
          }),
        )
        .with(
          {
            type: 'RECOMMENDATION',
            recommendation: { type: 'FLIGHT', data: P.select() },
          },
          (r): TripSummaryEntry => ({
            type: 'RECOMMENDATION',
            recommendation: {
              type: 'FLIGHT',
              data: flightRecommendationData(r),
            },
          }),
        )
        .with(
          {
            type: 'RECOMMENDATION',
            recommendation: { type: 'ACCOMMODATION', data: P.select() },
          },
          (r): TripSummaryEntry => ({
            type: 'RECOMMENDATION',
            recommendation: { type: 'HOTEL', data: hotelRecommendationData(r) },
          }),
        )
        .with(
          {
            type: 'RECOMMENDATION',
            recommendation: { type: 'LAYOVER', data: P.select() },
          },
          (r): TripSummaryEntry => ({
            type: 'RECOMMENDATION',
            recommendation: {
              type: 'LAYOVER',
              data: layoverRecommendationData(r),
            },
          }),
        )
        .otherwise(() => null)
    })
    .filter(Boolean) as Array<TripSummaryEntry>
  return {
    id: tripSummary.id,
    entries,
  }
}

export const refreshedTrip = (
  trip: definitions['refreshedTrip'],
): RefreshedTrip => {
  const mappedTrip = fullTrip(trip)
  return {
    ...mappedTrip,
    changeCount: trip.changeCount,
  }
}

export const basicTrip = (trip: definitions['basicTrip']): BasicTrip => ({
  ...trip,
  createdAt: makeDate(trip.createdAt),
  updatedAt: makeDate(trip.updatedAt),
  interactedAt: makeDate(trip.interactedAt),
  startDate: trip.startDate ? makeDate(trip.startDate) : undefined,
  endDate: trip.endDate ? makeDate(trip.endDate) : undefined,
  plannedIdSets: trip.plannedIdSets,
  isConciergeTrip: trip.isConciergeTrip,
})

export const basicTrips = (
  trips: Array<definitions['basicTrip']>,
): Array<BasicTrip> => trips.map(basicTrip)

function getFlightScheduleKey(flight: definitions['flightSchedule']): string {
  return `${flight.airlineCode}${flight.flightNumber}-${flight.departureAirportCode}`
}

export const flightSchedule = (
  flight: definitions['flightSchedule'],
): FlightSchedule => {
  return {
    key: getFlightScheduleKey(flight),
    ...flight,
    departureDate: makeDate(flight.departureDate),
    departureTime: makeDateTime(flight.departureDate, flight.departureTime),
    arrivalDate: makeDate(flight.arrivalDate),
    arrivalTime: makeDateTime(flight.arrivalDate, flight.arrivalTime),
  }
}
