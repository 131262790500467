import { excludeNullOrUndefined } from 'checkout/utils'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import createSelector from 'lib/web/createSelector'
import moment from 'moment'
import { sum } from 'lib/array/arrayUtils'
import { isInsuranceItem, isInsuranceProtectionItem, isNoProtectionInsuranceItem } from 'lib/checkout/checkoutUtils'
import { capitalise } from 'lib/string/stringUtils'
import { getCalculatedInsuranceViewPricing } from 'checkout/lib/utils/insurance/view'
import { isTravelProtectionAvailableInRegion } from 'selectors/featuresSelectors'
import { findInsuranceUpgradeSourceItem } from 'lib/insurance/insuranceHelpers'
import { getLeHotelItems, getBedbankItems } from 'checkout/selectors/view/hotels'
import { getVillaItems } from 'checkout/selectors/view/villa'
import { getBundleAndSaveItems } from 'checkout/selectors/view/bundleAndSave'

export const getActiveInsuranceType = createSelector(
  isTravelProtectionAvailableInRegion,
  (isTravelProtectionAvailableInRegion) => isTravelProtectionAvailableInRegion ? 'protection' : 'insurance',
)

export const getInsuranceItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InsuranceItem> => items.filter(isInsuranceProtectionItem),

)

// when user select No insurance, we add a no_protection item in the cart
// this selector will exclude that item
export const getCartItemsWithoutNoProtectionItem = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.AnyItem> => items.filter(item => !isNoProtectionInsuranceItem(item)),

)

// It includes the no-protection insurance item
export const getInsuranceTypeItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InsuranceItem> => items.filter(isInsuranceItem),
)

export const getInsuranceItemsView = createSelector(
  (state: App.State) => getInsuranceItems(state),
  (state: App.State) => state.insurance.quotes,
  (state: App.State) => state.insurance.products,
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.insurance.payment?.item,
  (state: App.State) => state.insurance.upgrade?.fetchingQuote,
  (state: App.State) => state.insurance.fetchQuotesError,
  (state: App.State) => state.insurance.fetchingProducts,
  (state: App.State) => state.insurance.fetchingQuotes,
  (state: App.State) => state.insurance.productsInitialised,
  isTravelProtectionAvailableInRegion,
  (
    insuranceItems,
    quotes,
    products,
    existingInsuranceItems,
    postPurchase,
    upgradeQuote,
    fetchingUpgradeQuote,
    fetchQuotesError,
    fetchingProducts,
    fetchingQuotes,
    productsInitialised,
    isTravelProtectionAvailableInRegion,
  ): App.WithDataStatus<Array<App.Checkout.InsuranceItemView>> => {
    let hasRequiredData = true
    const itemViews = insuranceItems.map(item => {
      const isPostPurchase = ['change-dates', 'change-package'].includes(postPurchase ?? '')

      // post purchase might go outside cover period, so not having quote and having error is fine
      // insurance will be automatically refunded/cancelled
      if (isPostPurchase && !fetchingUpgradeQuote && !!fetchQuotesError) {
        hasRequiredData = true
        return null
      }

      if (!productsInitialised || (productsInitialised && !quotes.length)) {
        hasRequiredData = false
        return null
      }

      if (isPostPurchase && fetchingUpgradeQuote) {
        hasRequiredData = false
        return null
      }

      // post purchase might go outside cover period, so not having quote and having error is fine
      if (isPostPurchase && (!upgradeQuote || !!fetchQuotesError)) {
        hasRequiredData = true
        return null
      }
      // quoteId actually changes on every fetch request
      // use productId instead
      const quote = quotes.find(quote => quote.productId === item.productId)
      if (!quote) {
        hasRequiredData = !fetchingQuotes
        return null
      }
      const product = products.find(product => product.id === item.productId)
      if (!product) {
        hasRequiredData = !fetchingProducts
        return null
      }

      let oldQuotePrice = 0
      let newQuotePrice = quote.mobileAppOnlyPrice > 0 ? quote.mobileAppOnlyPrice : quote.total
      let newQuoteLuxPlusPrice = quote.luxPlusTotal
      let startDate
      let endDate
      if (isPostPurchase) {
        oldQuotePrice = existingInsuranceItems?.find(item => item.status !== 'cancelled')?.total || 0
        newQuotePrice = Math.round(((upgradeQuote?.total ?? 0) - oldQuotePrice + Number.EPSILON) * 100) / 100
        // TODO - build a strategy for LuxPlus+ price calculation post purchase
        // see - mapUpdateQuote
        newQuoteLuxPlusPrice = newQuotePrice
        startDate = upgradeQuote?.startDate
        endDate = upgradeQuote?.endDate
      }

      const selectedPolicyIds = new Set(item.policyIds)
      const selectedPolicyNames = product.policyOptions?.filter(option => selectedPolicyIds.has(option.policy))
        .map(option => option.name) || []

      const policyNames = product.policyNames?.length ? product.policyNames : selectedPolicyNames

      const newPrice = newQuotePrice > 0 ? newQuotePrice : 0
      const newLuxPlusPrice = newQuoteLuxPlusPrice > 0 ? newQuoteLuxPlusPrice : 0
      const calculatedInsuranceViewPricing = getCalculatedInsuranceViewPricing(selectedPolicyIds, quote)

      let benefits: Array<string> = []
      const isCustomizedProtectionSelected = selectedPolicyNames && selectedPolicyNames.length > 0
      if (isCustomizedProtectionSelected) {
        selectedPolicyIds.forEach((selectedPolicyId) => {
          const policyBenefits = product.policyOptions?.find((p) => p.policy === selectedPolicyId)?.keyBenefits
          const policyBenefitLabels = policyBenefits?.map((benefit) => benefit.label)
          if (policyBenefitLabels) {
            benefits = [...benefits, ...policyBenefitLabels]
          }
        })
      } else {
        benefits = product.keyBenefits.map((benefit) => benefit.label)
      }

      return {
        itemId: item.itemId,
        quoteId: quote.id,
        productId: item.productId,
        name: product.name,
        description: product.description,
        policyNames,
        startDate: startDate || quote.startDate,
        endDate: endDate || quote.endDate,
        destinationCountries: quote.destinationCountries,
        price: isTravelProtectionAvailableInRegion && !isPostPurchase ? calculatedInsuranceViewPricing.price : newPrice,
        memberPrice: isTravelProtectionAvailableInRegion && !isPostPurchase ? calculatedInsuranceViewPricing.memberPrice : newLuxPlusPrice,
        value: newPrice,
        surcharge: 0,
        propertyFees: 0,
        taxesAndFees: 0,
        benefits,
        mobileAppOnlyDiscount: calculatedInsuranceViewPricing.mobileAppOnlyDiscount ?? 0,
      }
    }).filter(excludeNullOrUndefined)

    return {
      hasRequiredData,
      data: itemViews,
    }
  },
)

function getInsuranceBreakdownItem(item: App.Checkout.InsuranceItemView): App.Checkout.InsuranceItemBreakdownView {
  return {
    title: item.policyNames.length ? '' : item.name,
    price: item.price,
    memberPrice: item.memberPrice,
    itemType: 'insurance',
    additionalInfoText: [
      ...item.policyNames,
      `${moment(item.startDate).format(DMY_CASUAL_FORMAT)} - ${moment(item.endDate).format(DMY_CASUAL_FORMAT)}`,
      `Covered in: ${item.destinationCountries.join(', ')}`,
    ],
    additionalElements: [],
    taxesAndFees: item.taxesAndFees,
    mobileAppOnlyDiscount: item.mobileAppOnlyDiscount,
  }
}

export const getInsuranceBreakdownView = createSelector(
  getInsuranceItemsView,
  getActiveInsuranceType,
  (viewWithStatus, travelProtectionName): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }
    const breakdownItems = viewWithStatus.data.map(getInsuranceBreakdownItem)
    const mobileAppDiscountValue = sum(breakdownItems, item => item.mobileAppOnlyDiscount || 0)
    const breakdownView = {
      title: 'Travel ' + capitalise(travelProtectionName),
      price: sum(breakdownItems, item => (item.price || 0) + item.mobileAppOnlyDiscount),
      memberPrice: sum(breakdownItems, item => item.memberPrice || 0),
      additionalInfoText: [],
      additionalElements: [],
      items: breakdownItems,
      breakdownItemType: 'insurance' as const,
      ...(mobileAppDiscountValue > 0 && {
        itemMobileAppOnlyDiscount: {
          discountTitle: '20% app discount',
          discountValue: mobileAppDiscountValue,
        },
      }),
    }
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: [breakdownView],
    }
  },
)

export const getInsuranceTotalPrice = createSelector(
  getInsuranceBreakdownView,
  (views) => sum(views.data, view => view.price),
)

export const getDisclaimer = createSelector(
  (state: App.State) => state.insurance.products,
  (state: App.State) => state.insurance.quotes,
  (products, quotes) => {
    if (quotes.length) {
      const seniorsApplicable = products.some(product => product.seniorsApplicable)

      if (seniorsApplicable) {
        return quotes.find(quote => quote.seniorsApplicable)?.disclaimer
      }

      return quotes.find(quote => !quote.seniorsApplicable)?.disclaimer
    }
  },
)

// generated based on the full protection quote, as we need the policy quotes
// no matter whether customer selects the policy or not
function getCustomiseOptionQuotes(
  productId: string,
  products: Array<App.InsuranceProduct>,
  quotes: Array<App.InsuranceQuote>,
): App.InsuranceQuote | undefined {
  const fullProtectionProduct = products.find(p => p.isFullProtection)
  const fullQuote = quotes.find(q => q.productId === fullProtectionProduct?.id)
  if (fullQuote) {
    const lowestPricePolicy = fullQuote.policyQuotes.reduce((prev, curr) => {
      return prev.price < curr.price ? prev : curr
    })
    return {
      ...fullQuote,
      productId,
      mobileAppOnlyStartPrice: lowestPricePolicy.mobileAppOnlyPrice,
      mobileAppOnlyStartPricePerPerson: lowestPricePolicy.mobileAppOnlyPricePerPerson,
      startPrice: lowestPricePolicy.price,
      startPricePerPerson: lowestPricePolicy.pricePerPerson,
      luxPlusStartPrice: lowestPricePolicy.luxPlusPrice,
      luxPlusStartPricePerPerson: lowestPricePolicy.luxPlusPricePerPerson,
    }
  }
}

function getCustomiseOptionMutualQuotes(
  productId: string,
  quotes: Array<App.InsuranceQuote>,
): App.InsuranceQuote | undefined {
  /**
   * There are two customized protection quotes in the state.
   *
   * The first quote contains 3 policies and is created before the customer selects their desired options.
   * The second quote includes only the policies selected by the customer.
   *
   * In this selector, we return the first quote with 3 policies because we still want to display the prices
   * of all policies, allowing the customer to choose other options if they wish.
  */
  const quote = quotes
    .filter(quote => quote.productId === 'CUSTOMISE_PROTECTION' || quote.productId === 'CUSTOMISE_PROTECTION_DOMESTIC')
    .reduce((acc, curr) => {
      if (!acc) {
        return curr
      }

      // If the quote with all policies was already found, keep it
      if (acc.policyQuotes.length >= curr.policyQuotes.length) return acc

      return curr
    }, undefined as App.InsuranceQuote | undefined)

  if (quote) {
    const lowestPricePolicy = quote.policyQuotes.reduce((prev, curr) => {
      return prev.price < curr.price ? prev : curr
    })
    return {
      ...quote,
      productId,
      startPrice: lowestPricePolicy.price,
      startPricePerPerson: lowestPricePolicy.pricePerPerson,
      luxPlusStartPrice: lowestPricePolicy.luxPlusPrice,
      luxPlusStartPricePerPerson: lowestPricePolicy.luxPlusPricePerPerson,
    }
  }
}

export const getQuoteForProduct = createSelector(
  (state: App.State) => state.insurance.products,
  (state: App.State) => state.insurance.quotes,
  isTravelProtectionAvailableInRegion,
  (state: App.State, productId: string) => productId,
  (products, quotes, isTravelProtectionAvailableInRegion, productId) => {
    const product = products.find(p => p.id === productId)
    if (product) {
      // if not a customise option, just return the quote
      if (!product.policyOptions?.length) {
        return quotes.find(q => q.productId === productId)
      } else {
        return isTravelProtectionAvailableInRegion ?
          getCustomiseOptionMutualQuotes(productId, quotes) : getCustomiseOptionQuotes(productId, products, quotes)
      }
    }
  },
)

export const isCustomizablePoliciesMaximumReached = createSelector(
  getInsuranceTypeItems,
  (insuranceItems) => {
    const itemsWithMaximumPolicies = insuranceItems.find(item => item.policyIds && item.policyIds.length > 2)
    return !!itemsWithMaximumPolicies
  },
)

export const getUpdatedInsuranceDetails = createSelector(
  (state: App.State) => state.insurance.payment?.item,
  getInsuranceTotalPrice,
  (item, quotePriceDiff) => {
    if (!item) return null

    return {
      updateId: item.updateId || '',
      quotePriceDiff,
      coverAmount: item.coverAmount,
      transactionKey: item.transactionKey || '',
      endDate: item.endDate,
    }
  },
)

export const getInsuranceUpgradeSourceItem = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (existingOrder, insuranceItems) => {
    const activeInsuranceItem = insuranceItems?.find(item => item.status === 'completed')

    if (!activeInsuranceItem || !existingOrder) {
      return null
    }

    const insuranceUpgradeSourceItem = findInsuranceUpgradeSourceItem(activeInsuranceItem, existingOrder)
    return insuranceUpgradeSourceItem
  },
)

export const getShouldResetFormOnAddInsurance = createSelector(
  getLeHotelItems,
  getBedbankItems,
  getVillaItems,
  getBundleAndSaveItems,
  (leHotelItems, bedbankItems, villaItems, bundleAndSaveItems): boolean => {
    return !!leHotelItems.length || !!bedbankItems.length || !!villaItems.length || !!bundleAndSaveItems.length
  },
)
