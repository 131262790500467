import React, { PropsWithChildren } from 'react'
import Label from './Label'

type Variant = 'default' | 'premium' | 'engagement'

const configMap: Record<Variant, React.ComponentProps<typeof Label>> = {
  premium: {
    kind: 'primary',
    variant: 'highlight-secondary',
  },
  engagement: {
    kind: 'tertiary',
    variant: 'fast',
  },
  default: {
    kind: 'tertiary',
    variant: 'highlight',
  },
}

type InheritedLabelProps = Pick<React.ComponentProps<typeof Label>, 'format'>

interface Props extends InheritedLabelProps {
  className?: string;
  icon?: React.ReactNode;
  variant: Variant;
}

function PromotionLabel(props: PropsWithChildren<Props>) {
  const { variant, icon, ...labelProps } = props
  return <Label
    {...labelProps}
    {...configMap[variant]}
    startIcon={icon}
  />
}

export default PromotionLabel
