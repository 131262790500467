import { removeRoomlessSelectedPackages } from 'lib/offer/multipleRoomBookingUtils'
import { createReducer } from 'lib/redux/reducerUtils'
import { OfferPackageOptionView } from 'selectors/offerPage/offerPageSelectors'
import { ValueOf } from 'type-fest'
import { isBundleOffer } from 'lib/offer/offerTypes'
import config from 'constants/config'
import { isBundleAndSaveV2Enabled } from 'lib/offer/offerUtils'
import { EmptyArray } from 'lib/array/arrayUtils'

export enum OfferPageActions {
  checkAvailability = 'checkAvailability',
  closeFilter = 'closeFilter',
  clearFilter = 'clearFilter',
  closeSubFilter = 'closeSubFilter',
  filterContinue = 'filterContinue',
  filterBack = 'filterBack',
  resetDates = 'resetDates',
  setDepartureMonth = 'selectDepartureMonthIndex',
  setDepartureYear = 'selectDepartureYear',
  setTourV2Departure = 'selectTourV2OfferDeparture',
  setTourV2Variation = 'selectTourVOfferVariation',
  setAirport = 'setAirport',
  setPaginatorCurrentOfferId = 'setPaginatorCurrentOfferId',
  setBedBankOffer = 'setBedBankOffer',
  setCheckInDate = 'setCheckInDate',
  setCheckOutDate = 'setCheckOutDate',
  showRecommendationsView = 'showRecommendationsView',
  hideRecommendationsView = 'hideRecommendationsView',
  setDuration = 'setDuration',
  setFilteredBundlePackageId = 'setFilteredBundlePackageId',
  reverseBundles = 'reverseBundles',
  setFlights = 'setFlights',
  setOffer = 'setOffer',
  setRoomType = 'setRoom',
  setRooms = 'setRooms',
  setTourV2Offer = 'setTourV2Offer',
  toggleFilter = 'toggleFilter',
  openSwapModal = 'openSwapModal',
  toggleSubFilter = 'toggleSubFilter',
  setRoomOption = 'setRoomOption',
  selectRoomsPackages = 'selectRoomsPackages',
  selectRoomPackage = 'selectRoomPackage',
  toggleFlightsPrice = 'toggleFlightsPrice',
  setBedbankRoomId = 'setBedbankRoomId',
  setDisplayMemberPrice = 'setDisplayMemberPrice',
  toggleConnectedRooms = 'toggleConnectedRooms',
}

export type OfferPageFilterTypes = 'all' | 'duration' | 'capacity' | 'airport' | 'checkin' | 'package' | 'datepicker' | 'room' | 'roomSelection' | 'packageSelection' | 'flights-toggle' | 'roomDetails'

export interface MultipleRoomPackage {
  roomId: string;
  packageUniqueKey: string;
  isFlightsSelected: boolean
  roomTypeId: string
}

export type OfferPageRoomOption = {
  roomRateId: string;
  roomTypeId: string;
}

export interface BundledOfferState {
  offer?: App.Offer
  checkInDate?: string
  checkOutDate?: string
  duration?: number
  room?: App.PackageRoomType
  activeFilter?: OfferPageFilterTypes
}

export interface PaginatorState {
  currentOfferId?: string
  nextOfferId?: string
  backOfferId?: string
}

export interface TourV2MonthFilter {
  month: number,
  year: number
}

export interface OfferPageState {
  bundleOffer?: App.BundleOffer
  activeFilter?: OfferPageFilterTypes
  activeSubFilter?: OfferPageFilterTypes
  filterHistory: Array<OfferPageFilterTypes>;
  airport?: App.Airport
  bedbankOffer?: App.BedbankOffer
  checkInDate?: string
  checkOutDate?: string
  duration: number
  durationSelected?: boolean;
  filteredBundlePackageId?: string
  bundledOffers: {
    [offerId: string]: BundledOfferState
  }
  bundleSwapModalTicker: number
  paginator?: PaginatorState
  flights?: boolean
  isMultiRoomConnected?: boolean
  multipleRoomPackages: Array<MultipleRoomPackage>
  occupancyPicker?: boolean
  offer?: App.Offer
  package?: App.Package
  recommendationsCheckInDay?: string
  recommendationsMonthIndex?: string
  room?: App.PackageRoomType
  roomOption?: OfferPageRoomOption
  rooms?: Array<App.RoomOccupants>

  /**
   * TODO: Remove this field, views should never be stored, they are transient values
   * Perhaps store the package unique key instead
   * */
  selectedPackageView?: OfferPackageOptionView
  showRecommendationsView?: boolean
  selectedBedbankRoomId?: string
  displayMemberPrice?: boolean

  // Tours
  tourV2Offer?: Tours.TourV2Offer
  tourV2Departure?: Tours.TourV2OfferDeparture
  tourV2Variation?: Tours.TourV2OfferVariation
  departureMonth?: TourV2MonthFilter;
  departureYear?: number;
}

export const INITIAL_OFFER_PAGE_STATE: OfferPageState = {
  filterHistory: [],
  multipleRoomPackages: [],
  bundledOffers: {},
  bundleSwapModalTicker: 0,
  duration: 0,
}

function setFlightsForAllRooms(state: OfferPageState, flights: boolean): Array<MultipleRoomPackage> {
  if (!state.rooms?.length || state.bedbankOffer) return []

  // Get the list of rooms that are not in the multipleRoomPackages yet (if any)
  const alreadyAddedRooms = new Set((state.multipleRoomPackages ?? []).map(mrpkg => mrpkg.roomId))
  const roomsToAdd =
    state.rooms
      .filter(room => !alreadyAddedRooms.has(room.roomId))
      .map(room => ({ roomId: room.roomId, packageUniqueKey: undefined, roomTypeId: undefined }))

  return [...(state.multipleRoomPackages ?? []), ...roomsToAdd].map(mrpkg => ({
    ...mrpkg,
    isFlightsSelected: flights,
  }))
}

export type OfferPageDispatchAction = ValueOf<Utils.FullActionMap<{
  [OfferPageActions.checkAvailability]: {
    flights: OfferPageState['flights'],
    room?: OfferPageState['room'],
    filter: OfferPageState['activeFilter'],
    selectedPackageView?: OfferPageState['selectedPackageView']
  }
  [OfferPageActions.closeFilter]: {
    occupancyPicker?: OfferPageState['occupancyPicker']
  },
  [OfferPageActions.clearFilter]: {},
  [OfferPageActions.closeSubFilter]: {},
  [OfferPageActions.filterContinue] : {},
  [OfferPageActions.filterBack] : {},
  [OfferPageActions.resetDates]: {
    offerId?: string,
  },
  [OfferPageActions.setDepartureMonth]: {
    month: OfferPageState['departureMonth'] | undefined,
  },
  [OfferPageActions.setDepartureYear]: {
    year: OfferPageState['departureYear'],
  },
  [OfferPageActions.setTourV2Departure]: {
    departure: OfferPageState['tourV2Departure'],
  },
  [OfferPageActions.setTourV2Variation]: {
    variation: OfferPageState['tourV2Variation'],
  },
  [OfferPageActions.setAirport]: {
    airport: OfferPageState['airport'],
  },
  [OfferPageActions.setAirport]: {
    airport: OfferPageState['airport'],
  },
  [OfferPageActions.setBedBankOffer]: {
    bedbankOffer: OfferPageState['bedbankOffer'],
  },
  [OfferPageActions.setPaginatorCurrentOfferId]: {
    currentOfferId: string,
  },
  [OfferPageActions.setCheckInDate]: {
    checkIn: OfferPageState['checkInDate'],
    offerId?: string,
  },
  [OfferPageActions.setCheckOutDate]: {
    checkOut: OfferPageState['checkOutDate'],
    offerId?: string,
  },
  [OfferPageActions.setDuration]: {
    duration: OfferPageState['duration'],
    offerId?: string,
  },
  [OfferPageActions.setFilteredBundlePackageId]: {
    filteredBundlePackageId: OfferPageState['filteredBundlePackageId'],
  },
  [OfferPageActions.reverseBundles]: {},
  [OfferPageActions.setFlights]: {
    flights: OfferPageState['flights'],
  }
  [OfferPageActions.setOffer]: {
    offer: OfferPageState['offer'],
    bundledOffers?: OfferPageState['bundledOffers'],
  }
  [OfferPageActions.setRoomType]: {
    room: OfferPageState['room'],
    offerId?: string,
  }
  [OfferPageActions.setRooms]: {
    rooms: OfferPageState['rooms'],
  }
  [OfferPageActions.setTourV2Offer]: {
    variation: OfferPageState['tourV2Variation'],
    offer: OfferPageState['tourV2Offer'],
  }
  [OfferPageActions.setRoomOption]: {
    roomOption: OfferPageState['roomOption'],
  }
  [OfferPageActions.toggleFilter]: {
    filter: OfferPageState['activeFilter'],
    offerId?: string
  }
  [OfferPageActions.openSwapModal]: {}
  [OfferPageActions.toggleSubFilter]: {
    filter: OfferPageState['activeSubFilter']
  }
  [OfferPageActions.showRecommendationsView]: {
    monthIndex?: string;
    checkInDay?: string;
  },
  [OfferPageActions.hideRecommendationsView]: {
  },
  [OfferPageActions.selectRoomsPackages]: {
    roomsPackages: Array<{ room: App.RoomOccupants, packageOption: App.Package }>
  },
  [OfferPageActions.selectRoomPackage]: {
    packageOption: App.Package,
    room: App.RoomOccupants
  },
  [OfferPageActions.toggleFlightsPrice]: {
    isFlightsSelected: boolean,
  },
  [OfferPageActions.setBedbankRoomId] : {
    bedbankRoomId: OfferPageState['selectedBedbankRoomId'],
  }
  [OfferPageActions.setDisplayMemberPrice]: {
    displayMemberPrice: OfferPageState['displayMemberPrice'],
  }
  [OfferPageActions.toggleConnectedRooms]: {},
}>>

function setBundledOfferData(
  offerId: string,
  field: 'checkInDate' | 'checkOutDate' | 'duration' | 'room' | 'activeFilter',
  state: Partial<OfferPageState>,
  value: OfferPageState['checkInDate'] | OfferPageState['checkOutDate'] | OfferPageState['duration'] | OfferPageState['room'] | OfferPageState['activeFilter'],
) {
  return {
    bundledOffers: {
      ...(state.bundledOffers ?? {}),
      [offerId]: {
        ...(state.bundledOffers?.[offerId] ?? {}),
        [field]: value,
      },
    },
  }
}

export function takeOffersStates(state: OfferPageState) {
  if (isBundleOffer(state.offer)) {
    const attachedOffersIds = Object.keys(state.bundledOffers)

    return attachedOffersIds.map((id) => takeOfferState(state, id))
  }

  return [takeOfferState(state)]
}

export function takeOfferState(state: OfferPageState, offerId?: string): OfferPageState {
  if (isBundleOffer(state.offer) && offerId) {
    return {
      ...state,
      ...state.bundledOffers?.[offerId] ?? {},
    }
  }

  return state
}

export function coverByOffersStates<T extends any>(selector: any, ...states: Array<OfferPageState | App.State>): Array<T> {
  const offerPageStateIndex = states.findIndex((state) => 'id' in state.offer)
  const offerPageStates = takeOffersStates(states[offerPageStateIndex] as OfferPageState)
  return offerPageStates.map((state) => {
    const newState = [...states]
    newState[offerPageStateIndex] = state
    return selector(...newState)
  })
}

export function takeCurrentOfferState(state: OfferPageState) {
  return takeOfferState(state, state.paginator?.currentOfferId)
}

function offerPageStateReducer(middleware: Array<any> = EmptyArray, log: boolean = false) {
  return createReducer<OfferPageState, OfferPageDispatchAction>(INITIAL_OFFER_PAGE_STATE, {
    [OfferPageActions.toggleConnectedRooms]: (state) => ({
      isMultiRoomConnected: !state.isMultiRoomConnected,
    }),
    [OfferPageActions.checkAvailability]: (state, action) => ({
      flights: action.flights,
      room: action.room,
      activeFilter: action.filter,
      selectedPackageView: action.selectedPackageView,
      package: action.selectedPackageView?.package,
    }),
    [OfferPageActions.closeFilter]: (state, action) => ({
      activeFilter: undefined,
      ...(!action.occupancyPicker && { selectedPackageView: undefined }),
    }),
    [OfferPageActions.filterContinue]: () => ({
      activeFilter: undefined,
    }),
    [OfferPageActions.clearFilter]: () => ({
      activeFilter: undefined,
    }),
    [OfferPageActions.closeSubFilter]: () => ({
      activeSubFilter: undefined,
    }),
    [OfferPageActions.resetDates]: (state, action) => {
      if (action.offerId) {
        return setBundledOfferData(action.offerId, 'checkOutDate', setBundledOfferData(action.offerId, 'checkInDate', state, undefined), undefined)
      }

      return {
        checkInDate: undefined,
        checkOutDate: undefined,
      }
    },
    [OfferPageActions.setDepartureMonth]: (state, action) => ({
      departureMonth: action.month,
    }),
    [OfferPageActions.setDepartureYear]: (state, action) => {
      if (
        // we're settting a year and we have a month already set
        state.departureMonth && action.year &&
        // the years don't match
        state.departureMonth.year !== action.year
      ) {
        // reset the selected 'month' if it's no longer the same year as the one the user selects
        return {
          departureYear: action.year,
          departureMonth: undefined,
        }
      }
      return {
        departureYear: action.year,
      }
    },
    [OfferPageActions.setTourV2Departure]: (state, action) => ({
      tourV2Departure: action.departure,
    }),
    [OfferPageActions.setTourV2Variation]: (state, action) => ({
      tourV2Variation: action.variation,
    }),
    [OfferPageActions.setAirport]: (state, action) => ({
      airport: action.airport,
    }),
    [OfferPageActions.setBedBankOffer]: (state, action) => ({
      bedbankOffer: action.bedbankOffer,
    }),
    [OfferPageActions.setPaginatorCurrentOfferId]: (state, action) => {
      const allOffersIds = Object.keys(state.bundledOffers ?? {})
      const paginator = {
        currentOfferId: action.currentOfferId,
        nextOfferId: allOffersIds[allOffersIds.indexOf(action.currentOfferId) + 1],
        backOfferId: allOffersIds[allOffersIds.indexOf(action.currentOfferId) - 1],
      }

      if (paginator.nextOfferId && state.bundledOffers?.[paginator.nextOfferId]?.checkInDate && state.bundledOffers?.[paginator.currentOfferId]?.checkInDate) {
        if (isBundleAndSaveV2Enabled()) {
          return {
            paginator,
          }
        }
        return {
          paginator,
          ...setBundledOfferData(paginator.nextOfferId, 'checkOutDate', setBundledOfferData(paginator.nextOfferId, 'checkInDate', state, undefined), undefined),
        }
      }

      return {
        paginator,
      }
    },
    [OfferPageActions.setCheckInDate]: (state, action) => {
      if (action.offerId) {
        return setBundledOfferData(action.offerId, 'checkInDate', state, action.checkIn)
      }
      return {
        checkInDate: action.checkIn,
        durationSelected: true,
      }
    },
    [OfferPageActions.setCheckOutDate]: (state, action) => {
      if (action.offerId) {
        return setBundledOfferData(action.offerId, 'checkOutDate', state, action.checkOut)
      }

      return {
        checkOutDate: action.checkOut,
        durationSelected: true,
      }
    },
    [OfferPageActions.setDuration]: (state, action) => {
      if (action.offerId) {
        return setBundledOfferData(action.offerId, 'duration', state, action.duration)
      }
      return {
        duration: action.duration,
        // duration changed, the selected package view is no longer possible, so clear it
        selectedPackageView: action.duration !== state.duration ? undefined : state.selectedPackageView,
        package: action.duration !== state.duration ? undefined : state.package,
        durationSelected: true,
      }
    },
    [OfferPageActions.setFilteredBundlePackageId]: (state, action) => ({
      filteredBundlePackageId: action.filteredBundlePackageId,
    }),
    [OfferPageActions.reverseBundles]: (state) => {
      const bundledOffersArr = Object.values(state.bundledOffers)
      const newOrderedObject = {}
      newOrderedObject[bundledOffersArr[1].offer.id] = bundledOffersArr[1]
      newOrderedObject[bundledOffersArr[0].offer.id] = bundledOffersArr[0]
      return {
        bundledOffers: newOrderedObject,
      }
    },
    [OfferPageActions.setFlights]: (state, action) => ({
      flights: action.flights,
      multipleRoomPackages: setFlightsForAllRooms(state, action.flights),
    }),
    [OfferPageActions.setOffer]: (state, action) => {
      return {
        bundleOffer: isBundleOffer(action.offer) ? action.offer : undefined,
        offer: action.offer,
        bundledOffers: action.bundledOffers,
      }
    },
    [OfferPageActions.setRoomType]: (state, action) => {
      if (action.offerId) {
        return setBundledOfferData(action.offerId, 'room', state, action.room)
      }

      return {
        room: action.room,
        // room changed, selected view is no longer possible as it was for a previous room
        selectedPackageView: action.room !== state.room ? undefined : state.selectedPackageView,
        package: action.room !== state.room ? undefined : state.package,
      }
    },
    [OfferPageActions.setRooms]: (state, action) => ({
      rooms: action.rooms,
      multipleRoomPackages: action.rooms.length > 1 ? removeRoomlessSelectedPackages(action.rooms, state.multipleRoomPackages) : [],
    }),
    [OfferPageActions.setBedbankRoomId]: (state, action) => ({
      selectedBedbankRoomId: action.bedbankRoomId,
    }),
    [OfferPageActions.setTourV2Offer]: (state, action) => ({
      tourV2Variation: action.variation,
      tourV2Offer: action.offer,
    }),
    [OfferPageActions.setRoomOption]: (state, action) => ({
      roomOption: action.roomOption,
    }),
    [OfferPageActions.toggleFilter]: (state, action) => {
      if (action.offerId) {
        const filterShouldBeClosed = action.filter === state.bundledOffers[action.offerId].activeFilter
        if (filterShouldBeClosed) {
          return setBundledOfferData(action.offerId, 'activeFilter', state, undefined)
        }
        return setBundledOfferData(action.offerId, 'activeFilter', state, action.filter)
      }

      const filterShouldBeClosed = action.filter === state.activeFilter
      if (filterShouldBeClosed) {
        return {
          activeFilter: undefined,
        }
      }

      return {
        activeFilter: action.filter,
      }
    },
    [OfferPageActions.openSwapModal]: (state) => {
      return {
        bundleSwapModalTicker: state.bundleSwapModalTicker + 1,
      }
    },
    [OfferPageActions.toggleSubFilter]: (state, action) => {
      const filterShouldBeClosed = action.filter === state.activeSubFilter
      if (filterShouldBeClosed) {
        return {
          activeSubFilter: undefined,
        }
      }

      return {
        activeSubFilter: action.filter,
      }
    },
    [OfferPageActions.showRecommendationsView]: (state, action) => ({
      showRecommendationsView: true,
      recommendationsMonthIndex: action.monthIndex ?? state.recommendationsMonthIndex,
      recommendationsCheckInDay: action.checkInDay ?? state.recommendationsCheckInDay,
    }),
    [OfferPageActions.hideRecommendationsView]: () => ({
      showRecommendationsView: false,
      recommendationsMonthIndex: undefined,
      recommendationsCheckInDay: undefined,
    }),
    [OfferPageActions.selectRoomsPackages]: (state, action) => {
      const roomIds = new Set(action.roomsPackages.map(rpgk => rpgk.room.roomId))
      const newRoomPackagesList = state.multipleRoomPackages.filter(rpkg => !roomIds.has(rpkg.roomId))

      action.roomsPackages.forEach(rpkg => {
        const targetRoomPackage = state.multipleRoomPackages.find(mrkpg => mrkpg.roomId === rpkg.room.roomId)
        newRoomPackagesList.push(
          targetRoomPackage ? {
            ...targetRoomPackage,
            packageUniqueKey: rpkg.packageOption.uniqueKey,
            roomTypeId: rpkg.packageOption.roomType.id,
          } : {
            roomId: rpkg.room.roomId,
            packageUniqueKey: rpkg.packageOption.uniqueKey,
            isFlightsSelected: false,
            roomTypeId: rpkg.packageOption.roomType.id,
          },
        )
      })

      return ({
        multipleRoomPackages: newRoomPackagesList,
      })
    },
    [OfferPageActions.selectRoomPackage]: (state, action) => {
      const { packageOption, room } = action
      const targetRoomPackage = state.multipleRoomPackages.find(rpkg => rpkg.roomId === room.roomId)
      return ({
        multipleRoomPackages: [
          ...state.multipleRoomPackages.filter(rpkg => rpkg.roomId !== room.roomId),
          targetRoomPackage ? {
            ...targetRoomPackage,
            packageUniqueKey: packageOption.uniqueKey,
            roomTypeId: packageOption.roomType.id,
          } : {
            roomId: room.roomId,
            packageUniqueKey: packageOption.uniqueKey,
            isFlightsSelected: false,
            roomTypeId: packageOption.roomType.id,
          },
        ],
      })
    },
    [OfferPageActions.toggleFlightsPrice]: (state, action) => {
      const multipleRoomPackages = setFlightsForAllRooms(state, action.isFlightsSelected)
      return ({
        multipleRoomPackages,
        flights: action.isFlightsSelected,
      })
    },
    [OfferPageActions.setDisplayMemberPrice]: (state, action) => ({
      displayMemberPrice: action.displayMemberPrice,
    }),
  },
  middleware,
  log && config.LOG_REACT_REDUCERS,
  )
}

export default offerPageStateReducer
