import { fetchToursPopularDestinations } from 'actions/GlobalSearch/GlobalSearchToursActions'
import AssistChip from 'components/Luxkit/Chips/AssistChip'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import qs from 'qs'
import React, { useCallback, useEffect, useMemo } from 'react'
import { getToursPopularDestinations, getIsFetchingToursPopularDestinations } from 'selectors/destinationSelectors'
import GlobalSearchQuickFilterButtonsContainer from '../GlobalSearch/QuickFilters/GlobalSearchQuickFilterButtonsContainer'
import GlobalSearchQuickFilterLoadingButtons from '../GlobalSearch/QuickFilters/GlobalSearchQuickFilterLoadingButtons'
import GlobalSearchQuickFiltersContainer from '../GlobalSearch/QuickFilters/GlobalSearchQuickFiltersContainer'
import GlobalSearchQuickFiltersTitle from '../GlobalSearch/QuickFilters/GlobalSearchQuickFiltersTitle'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { SEARCH_VERTICALS } from 'constants/search'

interface Props {
  titleLabel?: string
  titleWeight: 'bold' | 'normal'
  titleColour: 'neutral-eight' | 'neutral-two'
  onDestinationClick?: () => void
}

function TourV2TrendingDestinationsQuickFilters(props: Props) {
  const {
    titleLabel = 'Trending destinations',
    titleColour,
    titleWeight,
    onDestinationClick,
  } = props

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchToursPopularDestinations())
  }, [dispatch])

  const destinations = useAppSelector(getToursPopularDestinations)
  const isLoading = useAppSelector(getIsFetchingToursPopularDestinations)

  const destinationLinks = useMemo(() => destinations.reduce((links, destination) => {
    const query = qs.stringify({
      destinationId: destination.value,
      destinationName: destination.format.mainText,
    })
    return {
      ...links,
      [destination.value]: `/search/tours?${query}`,
    }
  }, {}), [destinations])

  const handleDestinationClick = useCallback((event: any) => {
    const location = event.target.textContent

    Analytics.trackEvent(searchEventWithContext(
      'common-search',
      'standard', {
        destinationIds: [],
        destinationType: 'location',
        destinations: [location],
        vertical: SEARCH_VERTICALS.TOURS,
      },
    ))
    onDestinationClick?.()
  }, [onDestinationClick])

  if (!isLoading && destinations.length === 0) {
    return null
  }

  return <GlobalSearchQuickFiltersContainer>
    <GlobalSearchQuickFiltersTitle colour={titleColour} weight={titleWeight}>
      {titleLabel}
    </GlobalSearchQuickFiltersTitle>
    <GlobalSearchQuickFilterButtonsContainer>
      {isLoading && <GlobalSearchQuickFilterLoadingButtons />}
      {!isLoading && destinations.map(destination =>
        <AssistChip
          size="medium"
          variant="filled"
          key={destination.value}
          to={destinationLinks[destination.value]}
          onClick={handleDestinationClick}
        >
          {destination.format.mainText}
        </AssistChip>)}
    </GlobalSearchQuickFilterButtonsContainer>
  </GlobalSearchQuickFiltersContainer>
}

export default TourV2TrendingDestinationsQuickFilters
