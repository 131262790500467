import createSelector from 'lib/web/createSelector'
import {
  getTourV2IdForCheckoutItem,
  isTourV2Item,
} from 'lib/checkout/checkoutUtils'
import { subDays } from 'lib/datetime/dateUtils'
import config from 'constants/config'
import { PAYMENT_SCHEDULE_ENABLED_REGIONS } from 'constants/config/region'
import { getTourV2CheckoutItems } from 'checkout/selectors/view/accommodation'
import { getAccommodationPayable } from './accommodation'
import { getMerchantFeeAmount, getPayableTotal } from './checkout'
import { floatify } from 'lib/maths/mathUtils'
import { PAYMENT_OPTIONS } from 'constants/payment'
import moment from 'moment'
import { DMY_CASUAL_SHORT_FORMAT } from 'constants/dateFormats'

export const isPaymentScheduleEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (currentRegionCode) => {
    if (!config.PAYMENT_SCHEDULE_ENABLED) {
      return false
    }

    if (!PAYMENT_SCHEDULE_ENABLED_REGIONS.includes(currentRegionCode)) {
      return false
    }

    return true
  },
)

const canGetItemsPaymentScheduleInfo = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.offer.tourV2Offers,
  (state: App.State) => state.checkout.cart.isGift,
  (items, tourV2Offers, isGift): Array<App.Checkout.PaymentScheduleRequestInfo> => {
    if (isGift || !items.every(isTourV2Item)) {
      return [
        {
          disablePaymentSchedule: true,
        },
      ]
    }

    const tourV2Items = items.filter(isTourV2Item)

    return tourV2Items.map((item) => {
      const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
      const templateId = offer?.paymentScheduleInfo?.templateId
      if (!templateId) {
        return {
          disablePaymentSchedule: true,
        }
      }
      return {
        disablePaymentSchedule: false,
      }
    })
  },
)

const isAnyItemDisabledForPaymentSchedule = createSelector(
  canGetItemsPaymentScheduleInfo,
  (itemsPaymentScheduleInfo): boolean => itemsPaymentScheduleInfo.some(item => item.disablePaymentSchedule),
)

export const isPaymentScheduleAvailable = createSelector(
  isPaymentScheduleEnabled,
  (state: App.State) => state.checkout.cart.postPurchase,
  isAnyItemDisabledForPaymentSchedule,
  (
    isPaymentScheduleEnabled,
    postPurchase,
    isAnyItemDisabledForPaymentSchedule,
  ): boolean => {
    return (
      isPaymentScheduleEnabled &&
      !postPurchase &&
      !isAnyItemDisabledForPaymentSchedule
    )
  },
)

export const getPaymentScheduleTemplateIdFromTourV2Offer = createSelector(
  (state: App.State) => state.offer.tourV2Offers,
  getTourV2CheckoutItems,
  (tourV2Offers, tourV2Items): string | undefined => {
    const item = tourV2Items.find(item => {
      const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
      return offer?.paymentScheduleInfo?.templateId
    })

    if (!item) {
      return undefined
    }

    return tourV2Offers[getTourV2IdForCheckoutItem(item)]?.paymentScheduleInfo?.templateId
  },
)

export const getPaymentScheduleRequestInfo = createSelector(
  isPaymentScheduleAvailable,
  getTourV2CheckoutItems,
  (state: App.State) => state.offer.tourV2Offers,
  (isPaymentScheduleAvailable, tourV2Items, tourV2Offers): App.Checkout.PaymentScheduleRequestInfo | null => {
    if (!isPaymentScheduleAvailable) {
      return null
    }

    const getPaymentScheduleInfoFromOffer = (item: App.Checkout.TourV2Item) => tourV2Offers[getTourV2IdForCheckoutItem(item)]?.paymentScheduleInfo

    const itemWithTemplateId = tourV2Items.find(getPaymentScheduleInfoFromOffer)
    if (!itemWithTemplateId) {
      return null
    }

    const paymentScheduleInfo = getPaymentScheduleInfoFromOffer(itemWithTemplateId)
    if (!paymentScheduleInfo) {
      return null
    }

    if (!paymentScheduleInfo.supplierPaymentDate && !paymentScheduleInfo.supplierPaymentNumberOfDays) {
      return null
    }

    if (!itemWithTemplateId.total) {
      return null
    }

    const dueDate = paymentScheduleInfo.supplierPaymentDate ?
      new Date(paymentScheduleInfo.supplierPaymentDate) :
      subDays(new Date(itemWithTemplateId.startDate), paymentScheduleInfo.supplierPaymentNumberOfDays!)

    return {
      disablePaymentSchedule: false,
      requestInfo: {
        templateId: paymentScheduleInfo.templateId,
        bookingInfo: {
          bookingDate: moment().format(DMY_CASUAL_SHORT_FORMAT),
          checkinDate: itemWithTemplateId.startDate,
          supplierDueDate: moment(dueDate).format(DMY_CASUAL_SHORT_FORMAT),
          totalAmount: itemWithTemplateId.total,
        },
      },
    }
  },
)

export const getServiceFeeForInitialPayment = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    return paymentSchedule.payment_schedules[0].feeAmount || 0
  },
)

export const getPaymentScheduleTotalServiceFee = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    return paymentSchedule.payment_schedules.reduce((acc, schedule) => acc + schedule.feeAmount, 0)
  },
)

export const getServiceFeeForLaterPayments = createSelector(
  getPaymentScheduleTotalServiceFee,
  getServiceFeeForInitialPayment,
  (totalServiceFee, initialServiceFee): number => {
    return totalServiceFee - initialServiceFee
  },
)

export const getPaymentScheduleDueToday = createSelector(
  getPayableTotal,
  getAccommodationPayable,
  (state: App.State) => state.paymentSchedule,
  (state: App.State) => getMerchantFeeAmount(state, PAYMENT_OPTIONS.PAYMENT_SCHEDULE),
  (payableTotal, accommodationPayable, paymentSchedule, merchantFee): number => {
    if (!paymentSchedule.eligible || !paymentSchedule.payment_schedules) {
      return 0
    }
    const depositAmount = paymentSchedule.payment_schedules.find(schedule => schedule.paymentType === 'deposit')?.expectedAmount || 0
    return floatify(payableTotal - accommodationPayable + depositAmount + merchantFee)
  },
)

export const getPaymentScheduleTimelineData = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): Array<App.PaymentScheduleTimelineData> | undefined => {
    return paymentSchedule.payment_schedules?.slice(1).map((schedule) => {
      return {
        dueDate: schedule.dueDate,
        totalAmount: schedule.totalAmount,
        feeAmount: schedule.feeAmount,
      }
    })
  },
)

export const isPaymentScheduleServiceFeePaidUpfront = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): boolean => {
    if (!paymentSchedule.payment_schedules) {
      return false
    }
    return !!paymentSchedule.payment_schedules.find(paymentSchedule => paymentSchedule.paymentType === 'deposit')?.fee
  },
)

export const getServiceFeePaymentScheduleIndex = createSelector(
  (state: App.State) => state.paymentSchedule,
  (paymentSchedule): number => {
    return paymentSchedule.payment_schedules?.findIndex(schedule => schedule.feeAmount > 0) ?? 0
  },
)
