import React from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import noop from 'lib/function/noop'
import { rem } from 'polished'
import InputWrap from './InputWrap'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import InputText, { InputTextBase } from 'components/Luxkit/Typography/InputText'
import { TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from 'components/Luxkit/Typography/Typography'

const IconWrapper = styled.div`
  display: flex;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  pointer-events: none;

  &.left {
    left: ${rem(12)};
  }

  &.right {
    right: ${rem(12)};
  }
`

const SelectInput = styled.select`
  ${InputTextBase}
  line-height: var(${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR});
  overflow-x: hidden; /* This is to avoid zoom on IOS on focus */
  padding: ${rem(12)};
  appearance: none;
  width: 100%;
  border: none;
  background-color: transparent;
  text-overflow: ellipsis;

  &:disabled {
    cursor: not-allowed;
  }

  > option[disabled] {
    display: none;
  }

  &::-ms-expand {
    display: none;
  }

  /*
    Browser things like auto fill and validation pop ups use the
    actual input element as their target. We want this to look like
    the whole element - so we need our input to take up the whole container

    When there's icon, reserve space for them and then they will be
    absolutely positioned.

    Icons for inputs are always 24px sized
  */
  &.icon-left {
    padding-left: ${rem(48)};
  }

  &.icon-right {
    padding-right: ${rem(48)};
  }
`

const Placeholder = styled(InputText)`
  position: absolute;
  pointer-events: none;
  left: ${rem(12)};
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: color 0.2s;

  &.show  {
    opacity: 1;
  }

  &.icon-left {
    left: ${rem(48)};
  }

  &.icon-right {
    right: ${rem(48)};
  }
`

interface State {
  isDirty?: boolean;
  value?: any;
  error?: string;
}

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  onDirtyChange?: (isDirty: boolean) => void;
  getInvalidMessage?: (input: HTMLSelectElement) => string;
  // Override the 'required' error message
  requiredErrorMessage?: string;
  // Override any other validation error
  // if you want more granular error handling, use getInvalidMessage
  invalidErrorMessage?: string;
  label?: React.ReactNode;
  noValidationSpacing?: boolean;
  innerRef?: React.Ref<HTMLSelectElement>;
  noValidationMessage?: boolean;
  preventDropdown?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  helpText?: string;
}

class SelectOnly extends React.Component<React.PropsWithChildren<Props>, State> {
  state: State = {
    value: this.props.value || this.props.defaultValue,
  }

  static defaultProps = {
    defaultValue: '',
    onChange: noop,
    onBlur: noop,
    onInvalid: noop,
    onDirtyChange: noop,
    getInvalidMessage: () => '',
    onMouseDown: noop,
    endIcon: <LineAngleDownIcon />,
  }

  elements = {
    select: React.createRef<HTMLSelectElement>(),
  }

  componentDidUpdate(prevProps) {
    // if it's being used as a controlled component, make sure we re-evalulate
    // error messages if the value changes
    if (prevProps.value !== this.props.value) {
      this.setDirty()
      if (this.elements.select.current) {
        this.updateErrorMessage(this.elements.select.current)
      }
    }
  }

  selectRef = (element: HTMLSelectElement) => {
    // react types claim that ref.current is read only
    // but the entire point of a ref is .current is mutable.
    // Clobber the type because we know better!
    (this.elements.select as any).current = element
    if (this.props.innerRef) {
      (this.props.innerRef as any).current = element
    }
  }

  setDirty = () => {
    if (!this.state.isDirty) {
      this.props.onDirtyChange?.(true)
      this.setState({ isDirty: true })
    }
  }

  onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ value: e.currentTarget.value })
    this.setDirty()
    this.updateErrorMessage(e.currentTarget)
    this.props.onChange?.(e)
  }

  updateErrorMessage(input: HTMLSelectElement) {
    const { getInvalidMessage, requiredErrorMessage, invalidErrorMessage } = this.props
    const validity = input.validity
    const inIncorrectCustomRequiredError = !validity.valueMissing && validity.customError && input.validationMessage === requiredErrorMessage

    if (validity && !validity.valid && !inIncorrectCustomRequiredError) {
      // put error message into state here
      let message = getInvalidMessage?.(input)
      if (!message) {
        if (validity.valueMissing && requiredErrorMessage) {
          message = requiredErrorMessage
          // Custom validity lets us set the text in the browser pop up t o our custom message
          // but we have to manage it - each time it's called the banner will pop up
          // we don't need to show every time it's re-evaluated, only if the message changes
          if (this.state.error !== message) {
            input.setCustomValidity(message)
          }
        } else if (invalidErrorMessage) {
          message = invalidErrorMessage
        } else {
          // reset any custom messages, utilise the default messages again
          input.setCustomValidity('')
          message = input.validationMessage
        }
      }

      if (this.state.error !== message) {
        this.setState({ error: message })
      }
    } else if (this.state.error) {
      // reset the error
      input.setCustomValidity('')
      this.setState({ error: undefined })
    }
  }

  onBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    this.setDirty()
    this.updateErrorMessage(e.currentTarget)
    this.props.onBlur?.(e)
  }

  onInvalid = (e: React.FormEvent<HTMLSelectElement>) => {
    this.setDirty?.()
    this.updateErrorMessage(e.currentTarget)
    this.props.onInvalid?.(e)
  }

  onMouseDown = (e: React.MouseEvent<HTMLSelectElement>) => {
    if (this.props.preventDropdown) {
      e.preventDefault()
      e.currentTarget.focus()
    }
    this.props.onMouseDown?.(e)
  }

  render() {
    const {
      className,
      style,
      children,
      placeholder,
      disabled,
      label,
      noValidationSpacing,
      innerRef,
      noValidationMessage,
      startIcon,
      endIcon,
      onDirtyChange,
      getInvalidMessage,
      invalidErrorMessage,
      defaultValue,
      helpText,
      ...rest
    } = this.props
    const { isDirty, value, error } = this.state

    // if we're an uncontrolled component we use our state controlled value as our source of truth
    // if we're a controlled component, we use rest.value
    const finalValue = rest.value ?? value
    // we use empty string to mean empty. Falsey check is mostly correct
    // But 0 could be legit value, so treat it as a special case
    const hasValue = !!finalValue || finalValue === 0

    return (
      <InputWrap
        error={isDirty ? error : undefined}
        disabled={disabled}
        noValidationSpacing={noValidationSpacing}
        noValidationMessage={noValidationMessage}
        helpText={helpText}
      >
        {startIcon && <IconWrapper className="left">{startIcon}</IconWrapper>}
        <SelectInput
          {...rest}
          className={cn(className,
            { 'icon-left': !!startIcon, 'icon-right': !!endIcon },
          )}
          ref={this.selectRef}
          disabled={disabled}
          onBlur={this.onBlur}
          onInvalid={this.onInvalid}
          onChange={this.onChange}
          onMouseDown={this.onMouseDown}
          value={finalValue}
        >
          <option value="" disabled />
          {children}
        </SelectInput>
        {endIcon && <IconWrapper className="right">{endIcon}</IconWrapper>}
        {placeholder &&
          <Placeholder
            variant="text-regular"
            colour={disabled ? 'neutral-three' : 'neutral-four'}
            className={cn({
              show: !hasValue,
              'icon-left': !!startIcon,
              'icon-right': !!endIcon,
            })}
            wrap="truncate"
          >
            {placeholder}
          </Placeholder>
        }
      </InputWrap>
    )
  }
}

export default SelectOnly
