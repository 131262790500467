import {
  getBookingDetailsByIds,
  getExperienceById,
  getExperienceCategories,
  getExperienceDateAvailability,
  getExperienceListFacets,
  getExperiences,
  getExperiencesById,
  getExperienceSearchList,
  getExperienceTimeAvailability,
  getExperienceTransferAvailability,
  getExperienceVoucher,
} from 'api/experiences'
import { EXPERIENCE_VIEWED, API_CALL, API_CALL_SUCCESS, SET_EXPERIENCE_PLACE, UPDATE_USER_LAT_LONG, SET_CANCELLING_EXPERIENCES } from './actionConstants'
import { getExperienceListKey, getExperienceDatesKey, getExperienceTimesKey } from 'lib/experiences/experienceUtils'
import {
  FETCH_EXPERIENCE,
  FETCH_EXPERIENCE_LIST,
  FETCH_EXPERIENCE_DATES,
  FETCH_EXPERIENCE_TIMESLOTS,
  FETCH_EXPERIENCES,
  FETCH_EXPERIENCE_CATEGORIES,
  FETCH_EXPERIENCE_LIST_FACETS,
  FETCH_EXPERIENCE_VOUCHER,
  FETCH_BOOKING_DETAILS,
  FETCH_TRANSFER_OPTIONS,
  FETCH_REFUND_DETAILS,
} from './apiActionConstants'
import { addRecentlyViewedExperience } from 'cookies/recentlyViewedExperiencesCookie'
import { unique } from 'lib/array/arrayUtils'
import { getPlaceByRegionCode } from 'constants/places'
import { clearExperienceLocationCookie, setExperienceLocationCookie } from 'cookies/experienceLocationCookie'
import getCurrentGeoLocation from 'lib/geo/navigatorUtils'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { getOrderItemsRefundDetails } from 'api/order'

export function fetchExperienceSearchList(
  placeId: string | undefined,
  filters: {
    categoryCodes?: Array<number>;
    priceLte?: number;
    priceGte?: number;
    sortBy?: ExperienceSortTypes;
    campaigns?: Array<string>;
    placeIdToIgnore?: string;
    bounds?: string;
  } = {},
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = getExperienceListKey({ placeId, ...filters })

    if (state.experience.experienceLists[key]) {
      // already have it or are currently fetching it, don't try again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_EXPERIENCE_LIST,
      request: () => getExperienceSearchList({
        placeId,
        region: state.geo.currentRegionCode,
        ...filters,
      }),
      key,
    })
  }
}

export type ExperienceSortTypes = 'type' | 'recommended' | 'price.asc' | 'price.desc'
export interface ExperienceListFilters {
  latitude?: number,
  longitude?: number,
  currencyCode?: string;
  categoryCodes?: Array<number>;
  distance?: string;
  from?: string;
  to?: string;
  postPurchaseOnly?: boolean;
  offerId?: string;
  showUnlisted?: boolean;
}

export function fetchExperienceList(
  filters: ExperienceListFilters = {},
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { airports, currentRegionCode } = state.geo
    const currentCurrency = filters.currencyCode ?? state.geo.currentCurrency
    const key = getExperienceListKey(filters)

    if (state.experience.experienceLists[key]) {
      // already have it or are currently fetching it, don't try again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_EXPERIENCE_LIST,
      request: async() => {
        const experiences = await getExperiences({
          currentCurrency,
          filters: {
            latitude: filters.latitude,
            longitude: filters.longitude,
            categoryCodes: filters.categoryCodes,
            distance: filters.distance,
            from: filters.from,
            to: filters.to,
            postPurchaseOnly: filters.postPurchaseOnly,
            hotelOfferId: filters.offerId,
            showUnlisted: filters.showUnlisted,
          },
          airports,
          currentRegionCode,
        })
        const experienceIds = experiences.map(exp => exp.id)

        dispatch({
          type: API_CALL_SUCCESS,
          api: FETCH_EXPERIENCES,
          data: { experiences, errors: {} },
          ids: experienceIds,
        })

        return experienceIds
      },

      key,
    })
  }
}

export function fetchExperiencesById(...ids: Array<string>) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const missingIds = unique(ids.filter(id => !state.experience.experiences[id] && !state.experience.fetchingExperiences[id] && !state.experience.experienceErrors[id]))

    if (missingIds.length === 0) {
      // already have it or are currently fetching it, don't try again
      return
    }

    const { currentCurrency, airports, currentRegionCode } = state.geo

    dispatch({
      type: API_CALL,
      api: FETCH_EXPERIENCES,
      request: () => getExperiencesById(missingIds, { currentCurrency, currentRegionCode, airports }),
      ids: missingIds,
    })
  }
}

export function fetchExperienceById(id: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.experience.experiences[id] || state.experience.fetchingExperiences[id] || state.experience.experienceErrors[id]) {
      // already have it or are currently fetching it, don't try again
      return
    }

    const { currentCurrency, airports, currentRegionCode } = state.geo

    dispatch({
      type: API_CALL,
      api: FETCH_EXPERIENCE,
      request: () => getExperienceById(id, {
        airports,
        currentRegionCode,
        currentCurrency,
      }),
      id,
    })
  }
}

interface FetchDatesParams {
  pickupPointId?: string;
  redemptionLocationId?: string;
  tickets?: Array<string>;
  ticketModeKey?: string;
}

export function fetchExperienceDates(experienceId: string, params?: FetchDatesParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const key = getExperienceDatesKey(experienceId, {
      pickupPointId: params?.pickupPointId,
      redemptionLocationId: params?.redemptionLocationId,
      tickets: params?.tickets,
      ticketMode: params?.ticketModeKey,
    })

    if (state.experience.experienceDates[experienceId]?.[key]) return
    dispatch({
      key,
      experienceId,
      type: API_CALL,
      api: FETCH_EXPERIENCE_DATES,
      request: () => getExperienceDateAvailability(experienceId, {
        pickupPointId: params?.pickupPointId,
        redemptionLocationId: params?.redemptionLocationId,
        tickets: params?.tickets,
        ticketMode: params?.ticketModeKey,
      }),
    })
  }
}

interface FetchTimesParams {
  currency?: string;
  pickupPointId?: string;
  redemptionLocationId?: string;
  tickets?: Array<string>;
  date?: string;
  isBuyNowBookLater?: boolean;
  isGift?: boolean;
  ignoreCache?: boolean;
  ticketModeKey?: string;
}

export function fetchExperienceTimes(
  experienceId: string,
  params?: FetchTimesParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const currencyCode = params?.currency ?? state.geo.currentCurrency
    const customerId = state.auth.account.memberId
    const key = getExperienceTimesKey(experienceId, params?.date, {
      currency: currencyCode,
      pickupPointId: params?.pickupPointId,
      redemptionLocationId: params?.redemptionLocationId,
      isBuyNowBookLater: params?.isBuyNowBookLater,
      isGift: params?.isGift,
      tickets: params?.tickets,
      ticketMode: params?.ticketModeKey,
    })

    if (!params?.ignoreCache && state.experience.experienceTimes[experienceId]?.[key]) return

    dispatch({
      key,
      experienceId,
      type: API_CALL,
      api: FETCH_EXPERIENCE_TIMESLOTS,
      request: () => getExperienceTimeAvailability(experienceId, {
        currencyCode,
        pickupPointId: params?.pickupPointId,
        redemptionLocationId: params?.redemptionLocationId,
        customerId,
        tickets: params?.tickets,
        ticketMode: params?.ticketModeKey,
      }, params?.date),
    })
  }
}

export function fetchExperienceTransferTimes(experienceId: string, date: string, currency?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const currencyCode = currency ?? state.geo.currentCurrency
    const customerId = state.auth.account.memberId
    const key = getExperienceTimesKey(experienceId, date, {
      currency: currencyCode,
    })

    if (state.experience.experienceTimes[experienceId]?.[key]) return

    dispatch({
      key,
      experienceId,
      type: API_CALL,
      api: FETCH_EXPERIENCE_TIMESLOTS,
      request: () => getExperienceTransferAvailability(experienceId, date, {
        currencyCode,
        customerId,
      }),
    })
  }
}

export function fetchExperienceCategories() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (state.experience.experienceCategories.length > 0 ||
      state.experience.fetchingCategories ||
      state.experience.experienceCategoriesError) {
      // already have the categories, no need to fetch again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_EXPERIENCE_CATEGORIES,
      request: () => getExperienceCategories(),
    })
  }
}

export function fetchExperienceListFacets(placeId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (state.experience.experienceListFacets[placeId]) {
      // already have the categories, no need to fetch again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_EXPERIENCE_LIST_FACETS,
      request: () => getExperienceListFacets(placeId, state.geo.currentRegionCode),
      key: placeId,
    })
  }
}

export function fetchExperienceVoucher(experienceItemId: string) {
  return {
    type: API_CALL,
    experienceItemId,
    api: FETCH_EXPERIENCE_VOUCHER,
    request: () => getExperienceVoucher(experienceItemId),
  }
}

export function fetchBookingDetailsByIds(ids: Array<string>) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const missingIds = ids.filter(id =>
      !state.experience.bookingDetails[id] &&
      !state.experience.fetchingBookingDetails[id] &&
      !state.experience.bookingDetailsErrors[id],
    )
    if (!missingIds.length) return

    dispatch({
      type: API_CALL,
      api: FETCH_BOOKING_DETAILS,
      request: () => getBookingDetailsByIds(ids),
      ids,
    })
  }
}

export function fetchRefundDetailsByIds(ids: Array<string>) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const missingIds = ids.filter(id =>
      !state.experience.refundDetails[id] &&
      !state.experience.fetchingRefundDetails[id] &&
      !state.experience.refundDetailsErrors[id],
    )
    if (!missingIds.length) return

    dispatch({
      type: API_CALL,
      api: FETCH_REFUND_DETAILS,
      request: () => getOrderItemsRefundDetails(ids),
      ids,
    })
  }
}

export function experienceViewed(experience: App.ExperienceOffer) {
  addRecentlyViewedExperience(experience.id)
  return {
    type: EXPERIENCE_VIEWED,
    id: experience.id,
    experience,
  }
}

interface ExperiencePlaceOptions {
  placeId?: string;
  regionCode?: string;
  userSelected?: boolean;
  save?: boolean;
}

export function setExperiencePlace(type: App.ExperiencePlaceType, options: ExperiencePlaceOptions = {}) {
  return async(dispatch, getState) => {
    const state = getState() as App.State
    const geo = state.geo

    let placeId: string | undefined
    switch (type) {
      case 'region':
        placeId = options.placeId ?? getPlaceByRegionCode(options.regionCode ?? geo.currentRegionCode).id
        break
      case 'place':
        placeId = options.placeId
        break
      case 'currentLocation':
        try {
          const coords = await getCurrentGeoLocation()
          dispatch({
            type: UPDATE_USER_LAT_LONG,
            latitude: coords.latitude,
            longitude: coords.longitude,
          })
        } catch (message) {
          showSnackbar(message, 'warning', { heading: 'Couldn\'t find your location!' })
          return
        }
        break
    }

    if (options.save) {
      setExperienceLocationCookie(type, placeId, options.userSelected)
    } else {
      clearExperienceLocationCookie()
    }

    dispatch({
      type: SET_EXPERIENCE_PLACE,
      placeType: type,
      placeId,
      userSelected: options.userSelected,
      save: options.save,
    })
  }
}

export function fetchTransferOptions(experienceId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const existingState = state.experience.experienceTransferOptions[experienceId]
    if (existingState && (existingState.fetching || existingState.error || existingState.transfers)) {
      return
    }
    const customerId = state.auth.account.memberId

    dispatch({
      type: API_CALL,
      api: FETCH_TRANSFER_OPTIONS,
      request: async() => {
        const dates = await getExperienceDateAvailability(experienceId)
        const firstNotSoldOutDate = dates.find(d => !d.soldOut)
        if (!firstNotSoldOutDate) {
          throw new Error('No dates available for transfer')
        }
        return await getExperienceTransferAvailability(experienceId, firstNotSoldOutDate.day, { currencyCode: state.geo.currentCurrency, customerId })
      },
      experienceId,
    })
  }
}

export function setCancellingExperiences(experiences) {
  return {
    type: SET_CANCELLING_EXPERIENCES,
    experiences,
  }
}
