import {
  PRODUCT_CLICK_VIEW_DETAILS, MAP_PROPERTY_PIN_CLICK,
  MAP_OFFER_INFO_CLICK,
  MAP_PIN_OFFER_VIEW,
  DEAL_VIDEO_PLAYED,
  DEAL_VIDEO_PAUSED,
  OFFER_PRODUCT_DETAIL_VIEW,
  BEDBANK_OFFER_PRODUCT_DETAIL_VIEW,
  TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW,
  TOUR_V2_OFFER_VARIATION_VIEW,
  PACKAGE_VIEW_MORE_DETAILS_CLICK,
  INITIALISE_SEARCH_SESSION_ID,
  GENESYS_CUSTOM_EVENT,
  PAGE_VIEW,
} from './actionConstants'
import uuidV4 from 'lib/string/uuidV4Utils'

export interface AnalyticEvent {
  bannerId: string;
  placements?: Array<{
    bannerId: string,
    name?: string,
    creative?: string,
    position?: string,
  }>;
  hitType: string;
}

export function productClickViewDetails(data, position?: number, listId?: string, listName?: string) {
  return {
    type: PRODUCT_CLICK_VIEW_DETAILS,
    data,
    tracking: { position, listId, listName },
  }
}

export function mapPropertyPinClick(data) {
  return {
    type: MAP_PROPERTY_PIN_CLICK,
    data,
  }
}

export function mapOfferInfoClick(data: App.Offer) {
  return {
    type: MAP_OFFER_INFO_CLICK,
    data,
  }
}

export function mapPinOfferView(data) {
  return {
    type: MAP_PIN_OFFER_VIEW,
    data,
  }
}

export function dealVideoPlayed(data) {
  return {
    type: DEAL_VIDEO_PLAYED,
    data,
  }
}

export function dealVideoPaused(data) {
  return {
    type: DEAL_VIDEO_PAUSED,
    data,
  }
}

export function offerProductDetailView(data) {
  return {
    type: OFFER_PRODUCT_DETAIL_VIEW,
    data,
  }
}

export function bedbankOfferProductDetailView(data) {
  return {
    type: BEDBANK_OFFER_PRODUCT_DETAIL_VIEW,
    data,
  }
}

export function tourV2OfferProductDetailView(data) {
  return {
    type: TOUR_V2_OFFER_PRODUCT_DETAIL_VIEW,
    data,
  }
}

export function tourV2OfferVariationView(data) {
  return {
    type: TOUR_V2_OFFER_VARIATION_VIEW,
    data,
  }
}

export function packageViewMoreDetailsClick() {
  return {
    type: PACKAGE_VIEW_MORE_DETAILS_CLICK,
  }
}

export function initialiseSearchSessionId(offerListKey: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.analytics.search.sessions[offerListKey]) {
      return
    }

    dispatch({
      type: INITIALISE_SEARCH_SESSION_ID,
      offerListKey,
      sessionId: uuidV4(),
    })
  }
}

export function fireGenesysCustomEvent({ type, data = {} }) {
  return {
    type: GENESYS_CUSTOM_EVENT,
    data: {
      eventType: type,
      ...data,
    },
  }
}

interface PageViewParams {
  template: string;
  params: object;
  path: string;
  search?: string;
}

export function registerPageView(params: PageViewParams) {
  return {
    type: PAGE_VIEW,
    pathTemplate: params.template,
    pathParams: params.params,
    path: params.path,
    search: params.search,
  }
}
