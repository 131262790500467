import { useMutation } from '@tanstack/react-query'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import Select from 'components/Common/Form/Input/Select'
import TextInput from 'components/Common/Form/Input/TextInput'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import TextButton from 'components/Luxkit/Button/TextButton'
import Modal from 'components/Luxkit/Modal/Modal'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { formToObject } from 'lib/forms/formToObject'
import { rem } from 'polished'
import React, { FormEventHandler, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AGENT_HUB_TERMS_URL, AGENT_HUB_POLICY_URL } from 'agentHub/constants/agentHubUrls'
import requestPostExpressionOfInterest, { ExpressionOfInterestData } from 'agentHub/api/requestPostExpressionOfInterest'
import cn from 'clsx'
import TextLink from 'components/Luxkit/TextLink'
import { connect } from 'react-redux'
import AgentHubAffiliationAutocomplete from 'agentHub/components/AgentHubAffiliationAutocomplete'
import { AGENT_HUB_DEFAULT_PHONE_NUMBER_PREFIX } from 'constants/config/region'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchAgentAffiliationOptions } from 'actions/AgentHubActions'

import { getRegionByCode, getRegionCodes } from '@luxuryescapes/lib-regions'
import { LE_AGENT_HUB } from 'constants/brands'
import LoadingSpinner from 'components/Common/Loading/LoadingSpinner'

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(12)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:"agencyName agencyName"
                        "regionCode regionCode"
                        "affiliation affiliation"
                        "firstName lastName"
                        "phone email";
    &.showSpecifyAffiliation {
      grid-template-areas:"agencyName agencyName"
                        "regionCode regionCode"
                        "affiliation affiliation"
                        "specifyAffiliation specifyAffiliation"
                        "firstName lastName"
                        "phone email";
    }
    .grid-agencyName {
      grid-area: agencyName;
    }
    .grid-regionCode {
      grid-area: regionCode;
    }
    .grid-firstName {
      grid-area: firstName;
    }
    .grid-lastName{
      grid-area: lastName;
    }
    .grid-phone {
      grid-area: phone;
    }
    .grid-email {
      grid-area: email;
    }
    .grid-affiliation {
      grid-area: affiliation;
    }
    .grid-specifyAffiliation {
      grid-area: specifyAffiliation;
    }
  }
`

export interface FormData {
  agencyName: string;
  firstName: string;
  lastName: string;
  email: string;
  affiliation: string;
  phone: { phone: string, prefix: string };
  specifyAffiliation?: string;
  regionCode: App.AgentHubEnabledRegions;
}

function mapFormData(formData: FormData) {
  return {
    agencyName: formData.agencyName,
    name: `${formData.firstName} ${formData.lastName}`,
    email: formData.email,
    affiliation: formData.affiliation === 'Other (please specify)' ? (formData.specifyAffiliation || '') : formData.affiliation,
    phone: `${formData.phone.prefix} ${formData.phone.phone}`,
    regionCode: formData.regionCode,
  }
}

function getPhonePlaceholder(region: string) {
  switch (region) {
    case 'GB':
      return '07000 000 000'
    default:
      return '0400 000 000'
  }
}

const AFFILIATION_AUTO_COMPLETE_REGIONS = ['US', 'GB']

interface Props {
  geoRegion: string
}

function AgentHubExpressionOfInterestModal({
  geoRegion,
}: Props) {
  const dispatch = useAppDispatch()

  const [regionCode, setRegionCode] = useState<App.AgentHubEnabledRegions>(geoRegion as App.AgentHubEnabledRegions)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [finished, setFinished] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [shouldDisplayAffiliationInput, setShouldDisplayAffiliationInput] = useState(false)
  const emailSendMutation = useMutation({
    mutationFn: async(userData: ExpressionOfInterestData) => {
      return requestPostExpressionOfInterest(userData)
    },
    onSuccess: async() => {
      setFinished(true)
      setIsError(false)
    },
    onError: async(error: App.ApiCallFailure) => {
      setErrorMsg(error.message)
      setIsError(true)
      setIsSubmitted(false)
    },
  })
  const fetching = useAppSelector((state) => state.agentHub.affiliationOptions.fetching)
  const affiliationOptions = useAppSelector((state) => state.agentHub.affiliationOptions.options[regionCode])

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault()
    setIsSubmitted(true)
    const formData = formToObject<FormData>(e.currentTarget)
    const mappedFormData = mapFormData(formData)
    emailSendMutation.mutate(mappedFormData)
  }, [emailSendMutation])

  function handleAffiliationSelection(value: string) {
    if (value === 'Other (please specify)') {
      setShouldDisplayAffiliationInput(true)
      return
    }

    setShouldDisplayAffiliationInput(false)
  }

  useEffect(() => {
    dispatch(fetchAgentAffiliationOptions(regionCode))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionCode])

  return (<Modal size={finished ? 'S' : 'L'} title={finished ? 'Thank you!' : 'Register your interest'}>
    {isError && <MessageBanner kind="critical" description={`Something went wrong while setting up your registration. Error message: ${errorMsg}`}/>}
    {finished && <BodyText variant="medium">
      You have officially registered to join Agent Hub. We will keep you notified with launch updates and next steps to verify your account.
    </BodyText>}
    {!finished && (<Group direction="vertical" gap={20}>
      <BodyText variant="medium">
        Join Agent Hub to earn and unlock exclusive travel deals
      </BodyText>

      <form
        onSubmit={handleSubmit}
        name="agentHubExpressionOfInterestForm"
      >
        <InputGrid className={cn({ showSpecifyAffiliation: shouldDisplayAffiliationInput })} >
          <TextInput
            className="grid-agencyName"
            label="Agency name"
            maxLength={100}
            minLength={2}
            name="agencyName"
            placeholder="Agency name"
            required
            requiredErrorMessage="Agency name is required"
          />
          <Select
            className="grid-regionCode"
            label="Region"
            name="regionCode"
            placeholder="Region"
            required
            requiredErrorMessage="Region is required"
            value={regionCode}
            onChange={(e) => setRegionCode(e.target.value as App.AgentHubEnabledRegions)}
          >
            {getRegionCodes(LE_AGENT_HUB).map((item) => <option key={item} value={item}>{getRegionByCode(item)?.name}</option>)}
          </Select>
          {AFFILIATION_AUTO_COMPLETE_REGIONS.includes(regionCode) && <div className="grid-affiliation">
            <AgentHubAffiliationAutocomplete
              affiliationOptions={affiliationOptions}
              loading={fetching}
              onSelect={(value) => handleAffiliationSelection(value)}
            />
          </div>}
          {!AFFILIATION_AUTO_COMPLETE_REGIONS.includes(regionCode) && <Select
            className="grid-affiliation"
            label="Affiliation"
            name="affiliation"
            placeholder="Affiliation"
            required
            disabled={fetching}
            startIcon={fetching && <LoadingSpinner />}
            requiredErrorMessage="Affiliation is required"
            onChange={(e) => handleAffiliationSelection(e.target.value)}
          >
            {affiliationOptions?.map((item) => <option key={item} >{item}</option>)}
          </Select>}

          {shouldDisplayAffiliationInput &&
            <TextInput
              className="grid-specifyAffiliation"
              invalidErrorMessage="Invalid characters. Please use only letters and spaces"
              label="Please specify affiliation"
              maxLength={100}
              minLength={2}
              name="specifyAffiliation"
              pattern="^[A-Za-z ]+$"
              placeholder="Affiliation"
              required={Boolean(shouldDisplayAffiliationInput)}
              requiredErrorMessage="Affiliation is required"
            />
          }

          <TextInput
            className="grid-firstName"
            invalidErrorMessage="Invalid characters. Please use only letters and spaces"
            label="First name"
            maxLength={100}
            minLength={2}
            name="firstName"
            pattern="^[A-Za-z ]+$"
            placeholder="First name"
            required
            requiredErrorMessage="First name is required"
          />

          <TextInput
            className="grid-lastName"
            invalidErrorMessage="Invalid characters. Please use only letters and spaces"
            label="Last name"
            maxLength={100}
            minLength={2}
            name="lastName"
            pattern="^[A-Za-z ]+$"
            placeholder="Last name"
            required
            requiredErrorMessage="Last name is required"
          />

          <PhoneInput
            className="grid-phone"
            prefixCountry={regionCode}
            defaultPhonePrefix={AGENT_HUB_DEFAULT_PHONE_NUMBER_PREFIX[regionCode]}
            label="Phone number"
            name="phone"
            key={regionCode}
            placeholder={getPhonePlaceholder(regionCode)}
            required
            requiredErrorMessage="Phone number is required"
          />

          <TextInput
            className="grid-email"
            invalidErrorMessage="Invalid email address"
            label="Email address"
            maxLength={100}
            name="email"
            placeholder="Enter your agency email address"
            required
            requiredErrorMessage="Email address is required"
            type="email"
          />
        </InputGrid>
        <Group tabletGap="0 32" gap="16 0" tabletDirection="horizontal" direction="vertical" tabletHorizontalAlign="space-evenly" horizontalAlign="stretch">
          <BodyText variant="medium">By continuing, you agree to our <TextLink href={AGENT_HUB_TERMS_URL} rel="noopener noreferrer" target="_blank" weight="regular">Terms & Conditions</TextLink> and <TextLink href={AGENT_HUB_POLICY_URL} rel="noopener noreferrer" target="_blank" weight="regular">Privacy Policy</TextLink>, and to subscribe to marketing communications for offers, alerts and services.</BodyText>
          <TextButton disabled={isSubmitted} fit="mobile-full-width" kind="primary" size="large" type="submit">Submit</TextButton>
        </Group>
      </form>
    </Group>)}
  </Modal>)
}

const mapStateToProps = (state: App.State) => ({
  geoRegion: state.geo.currentRegionCode,
})

export default connect(mapStateToProps)(AgentHubExpressionOfInterestModal)
