import React, { useContext, useEffect } from 'react'
import ModalContext from 'contexts/ModalContext'
import LuxPlusFreePreviewAutoModal from 'luxPlus/components/modals/LuxPlusFreePreviewAutoModal'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { checkCanRedeemLuxPlusBenefits, isEligibleLuxPlusFreePreview, isFreePreviewLuxPlusMember, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { fetchMemberFreePreviewEligibility } from 'actions/LuxPlusActions'
import MasterModalContext from 'contexts/MasterModalContext'
import {
  get as getLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import useQueryParams from 'hooks/useQueryParams'
import { matchPath } from 'react-router'
import config from 'constants/config'

const pathsBlacklist = [
  '/:region/checkout',
  '/:region/booking/confirmation',
]

function useLuxPlusFreePreviewAutoModal() {
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const isDismissed = getLocalStorage(LUXURY_PLUS.DISMISS_FREE_PREVIEW_STORAGE_KEY)
  const freePreviewEligible = useAppSelector(isEligibleLuxPlusFreePreview)
  const modalState = useContext(MasterModalContext)
  const queryParams = useQueryParams()
  const freePreviewMember = useAppSelector(isFreePreviewLuxPlusMember)
  const canRedeemLuxPlusBenefits = useAppSelector(checkCanRedeemLuxPlusBenefits)
  const alreadyEnrolled = freePreviewMember || canRedeemLuxPlusBenefits

  const path = useAppSelector(state => state.router.location.pathname)
  const isModalPreventedPage = !!matchPath(path, {
    path: pathsBlacklist,
    exact: false,
  })

  const shouldFetchEligibility = config.LUXURY_PLUS_FREE_PREVIEW_MODAL && loggedIn && luxPlusEnabled && !isDismissed && !alreadyEnrolled
  const shouldShowModal = luxPlusEnabled && !isDismissed && freePreviewEligible && !modalState.open && !alreadyEnrolled && !queryParams.get(LUXURY_PLUS.FREE_PREVIEW_AUTO_ENROLL_QUERY_PARAM) && !isModalPreventedPage

  useEffect(() => {
    if (shouldFetchEligibility) {
      dispatch(fetchMemberFreePreviewEligibility())
    }
  }, [shouldFetchEligibility, dispatch])

  useEffect(() => {
    if (shouldShowModal) {
      showModal(<LuxPlusFreePreviewAutoModal />)
    }
  }, [shouldShowModal, showModal])
}

export default useLuxPlusFreePreviewAutoModal
