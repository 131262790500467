import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { formToObject } from 'lib/forms/formToObject'
import React, { useEffect, useMemo, useState } from 'react'
import ExperienceDetailsTransferBookingTravellers from './ExperienceTransferBookingTravellers'
import ExperienceTransferBookingOption from './ExperienceTransferBookingOption'
import ExperienceTransferBookingForm from './ExperienceTransferBookingForm'
import { findBestTransfer, SingleTransferForm } from './ExperienceTransferUtils'
import noop from 'lib/function/noop'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import ExperienceTransferBookingOptionError from './ExperienceTransferBookingOptionError'

type TransferType = 'AIRPORT-TO-HOTEL' | 'HOTEL-TO-AIRPORT' | 'ROUND-TRIP'

const TransferTypeResultLabels = new Map<TransferType, string>([
  ['AIRPORT-TO-HOTEL', 'Your hotel drop off transfer'],
  ['HOTEL-TO-AIRPORT', 'Your airport drop off transfer'],
])

interface Props {
  defaultTransfer: App.ExperienceTransferView;
  transferOptions: Array<App.ExperienceTransferOption>;
  experience: App.ExperienceBookingView;
  formId?: string;
  onPriceChange?: (total: number, isFromPrice?: boolean) => void;
  onSubmit?: (transfer: Array<App.ExperienceTransferView>) => void;
}

function ExperienceTransferSingleBooking(props: Props) {
  const {
    defaultTransfer,
    transferOptions,
    experience,
    formId,
    onPriceChange = noop,
    onSubmit = noop,
  } = props

  const availableOptions = useMemo(() => transferOptions.filter(option => option.transferTypes.includes(defaultTransfer.type)), [defaultTransfer.type, transferOptions])
  const [transfer, setTransfer] = useState<App.ExperienceTransferView>(defaultTransfer)

  const hasTicketValues = !!transfer?.hotel.kmFromAirport

  // Getting the best option for the user
  const { bestTransfer, noTransferReason } = useMemo(() => {
    return findBestTransfer(transfer, availableOptions)
  }, [transfer, availableOptions])

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formValues = formToObject<SingleTransferForm>(e.currentTarget)

    onSubmit([{
      ...transfer,
      travellers: {
        ...transfer.travellers,
        // if we have no children, reset the seats. We only kept them around in case the user
        // flicked back and forth between having children and not for convenience
        boosterSeats: transfer.travellers.children ? transfer.travellers.boosterSeats : 0,
        childSeats: transfer.travellers.children ? transfer.travellers.childSeats : 0,
      },
      type: formValues.transfer.type,
      flightNumber: formValues.transfer.flightNumber,
      time: formValues.transfer.time,
      date: formValues.transfer.date,
      option: bestTransfer,
      title: experience.title,
    }])
  }

  const totalPrice = bestTransfer?.price ?? experience.price ?? 0

  useEffect(() => {
    onPriceChange(totalPrice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice])

  return <VerticalSpacer gap={24} as="form" onSubmit={onFormSubmit} id={formId}>
    <VerticalSpacer gap={16}>
      <Heading variant="heading5">
        {transfer.type === 'AIRPORT-TO-HOTEL' ? 'Airport pick up' : 'Airport drop off'}
      </Heading>
      <ExperienceTransferBookingForm
        transfer={transfer}
        name="transfer"
        onChange={setTransfer}
        experience={experience.originalExperience}
      />
      <ExperienceDetailsTransferBookingTravellers
        name="transfer"
        options={availableOptions}
        transfer={transfer}
        onChange={setTransfer}
      />
    </VerticalSpacer>
    {hasTicketValues && <>
      <Divider kind="primary" />
      <VerticalSpacer gap={12} style={{ position: 'relative' }}>
        <BodyText variant="large" weight="bold">{TransferTypeResultLabels.get(transfer.type)}</BodyText>
        {bestTransfer && <ExperienceTransferBookingOption transferTicket={bestTransfer} />}
        {noTransferReason && <ExperienceTransferBookingOptionError reason={noTransferReason} />}
        <HiddenInput
          required={!bestTransfer}
          invalidMessage="Please adjust your search parameters and try again."
        />
      </VerticalSpacer>
    </>}
  </VerticalSpacer>
}

export default ExperienceTransferSingleBooking
