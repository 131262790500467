import {
  ANYWHERE_PLACE_ID,
  DEFAULT_FLEXIBLE_DURATION_RANGE,
  LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL,
  LOCATION_SEARCH_INPUT_TITLE_LABEL,
} from 'constants/search'
import useToggle from 'hooks/useToggle'
import { EmptyArray, take, without } from 'lib/array/arrayUtils'
import noop from 'lib/function/noop'
import React, { ComponentProps, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { encodeSearchParams } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import * as Analytics from 'analytics/analytics'
import { pushWithRegion } from 'actions/NavigationActions'
import { useAppDispatch } from 'hooks/reduxHooks'
import config from 'constants/config'
import setSelectedBusinessTravellerEmployeeIds from 'actions/businessTraveller/setSelectedBusinessTravellerEmployeeIds'
import { saveBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import SearchPlaceSelectDropdown from './SearchPlaceSelectDropdown'
import SearchPlaceSelectInline from './SearchPlaceSelectInline'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapRecentSearchDataToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import HotelSearchPlaceSelectDropdown from '../Hotels/HotelSearchPlaceSelectDropdown'
import TourSearchPlaceSelectDropdown from '../TourV2/TourSearchPlaceSelectDropdown'

interface MappedStateProps {
  pathname: string;
}

interface Props {
  open?: boolean;
  className?: string;
  label?: string;
  value?: App.SearchItem;
  placeholder?: string;
  searchTypes?: Array<App.SearchType>;
  placeTypes?: Array<App.SearchPlaceType>;
  searchVertical?: App.SearchPlaceVertical;
  /** Items to show when a user hasn't entered any search */
  placeholderItems?: Array<App.SearchItem>;
  placeholderTitle?: string;
  /** When the selected item changes */
  onChange?: (item?: App.SearchItem, searchPhase?: string) => void;
  onToggle?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onResultsChange?: (results: Array<App.SearchItem>, searchPhrase: string) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  controlsContainerRef?: React.RefObject<HTMLDivElement>;
  required?: boolean;
  /** A list of recent search items, will be displayed along with placeholder items */
  recentSearchItems?: Array<App.FullRecentSearch>;
  /** Use this to supply your own search results list the input will use */
  overrideSearchItems?: Array<App.SearchItem>;
  variant: 'inline' | 'dropdown';
  dropdownSize?: ComponentProps<typeof SearchPlaceSelectDropdown>['size']
  /**
   * Dismiss the dropdown once an item is selected
   *
   * @default true
   */
  closeOnChange?: boolean;
}

const SELECTABLE_RESULT_TYPES: Array<App.SearchType> = ['destination', 'property', 'landmark', 'airport', 'departurePort']

function SearchPlaceSelect(props: Props & MappedStateProps) {
  const {
    open,
    placeholder = LOCATION_SEARCH_INPUT_PLACEHOLDER_LABEL,
    label = LOCATION_SEARCH_INPUT_TITLE_LABEL,
    value,
    placeholderItems = EmptyArray,
    onChange = noop,
    onResultsChange = noop,
    inputRef,
    recentSearchItems = EmptyArray,
    closeOnChange = true,
    onToggle,
    pathname,
    variant,
    dropdownSize,
    controlsContainerRef,
    searchVertical,
  } = props

  const [localRecentSearches, setLocalRecentSearches] = useState<Array<App.FullRecentSearch>>(recentSearchItems)
  const [searchPhrase, setSearchPhrase] = useState<string>(value?.format.mainText ?? '')
  const [selectedDropdown, setSelectedDropdown] = useState<number | undefined>()
  const localInputRef = useRef<HTMLInputElement>(null)
  const [localOpen, , openDropdown, closeDropdown] = useToggle()
  const [results, setResults] = useState<Array<App.SearchItem>>([])
  const finalOpen = !!(open ?? localOpen)
  const dispatch = useAppDispatch()
  const {
    searchVerticals,
    eventAnalytics,
  } = useContext(GlobalSearchStateContext)

  useEffect(() => {
    setLocalRecentSearches(recentSearchItems)
  }, [recentSearchItems])

  useEffect(() => {
    if (value?.format.mainText) {
      // update search phrase if search item updated
      setSearchPhrase(value.format.mainText)
    }
  }, [value])

  const handleOpen = useCallback((e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    openDropdown()
    if (onToggle) {
      onToggle(e)
    }
  }, [onToggle, openDropdown])

  useImperativeHandle(inputRef, () => localInputRef.current!)
  const onLocationSelect = useCallback((searchItem?: App.SearchItem) => {
    setSearchPhrase(searchItem?.format.mainText ?? '')
    onChange(searchItem, searchPhrase)
    if (closeOnChange) {
      closeDropdown()
    }
  }, [onChange, searchPhrase, closeOnChange, closeDropdown])

  const handleResultsChange = useCallback((results: Array<App.SearchItem>, searchPhrase: string) => {
    setResults(results)
    onResultsChange(results, searchPhrase)
  }, [onResultsChange])

  const showPlaceholder = (searchPhrase.length === 0 && placeholderItems.length > 0) || !!(value && value.format.mainText === searchPhrase)
  const hasRecentSearchItems = !!localRecentSearches.length

  const [recentSearches, placeholdersSearches] = useMemo(() => {
    const filteredPlaceholderItems = placeholderItems.filter(item => item.value !== ANYWHERE_PLACE_ID)
    const uniqueSearchItems = take(localRecentSearches, 3)

    let maxItems = 7

    if (searchVertical === 'hotel') {
      maxItems = 12
    }

    return [
      uniqueSearchItems,
      take(filteredPlaceholderItems, maxItems - uniqueSearchItems.length),
    ]
  }, [localRecentSearches, placeholderItems, searchVertical])

  const onRecentSearchDelete = useCallback((item: App.FullRecentSearch) => {
    setLocalRecentSearches(without(localRecentSearches, item))
  }, [localRecentSearches])

  const onSearchPhraseChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.currentTarget.value)
    // clear the previous selected item during typing
    onChange(undefined)
  }, [onChange])

  const onClearClick = useCallback(() => {
    if (localInputRef.current) {
      localInputRef.current.value = ''
    }
    // clear the search phrase, selected item and suggested items
    setSearchPhrase('')
    onChange(undefined)
    onResultsChange([])
  }, [onChange, onResultsChange])

  const onRecentSearchClick = useCallback((recentSearch: App.FullRecentSearch) => {
    const { dates, rooms } = recentSearch

    if (config.businessTraveller.currentAccountMode === 'business' && recentSearch.travellers) {
      const selected = recentSearch.travellers.map(traveller => traveller.id)
      dispatch(setSelectedBusinessTravellerEmployeeIds(selected))
      // save to localstorage so persist on page refresh
      saveBusinessTravellerSelectedTravellerState(selected)
    }

    if ([...searchVerticals][0] === 'hotels' && (eventAnalytics.contextLocation === 'search-list' || eventAnalytics.contextLocation === 'common-search')) {
      Analytics.trackEvent(searchEventWithContext(eventAnalytics.contextLocation, 'recent', mapRecentSearchDataToSnowplowSearchEvent(recentSearch, searchVerticals)))
    }

    const newSearch = encodeSearchParams({
      flexibleMonths: dates?.flexibleMonths,
      flexibleNights: dates?.flexibleDuration as DEFAULT_FLEXIBLE_DURATION_RANGE,
      dates: {
        checkIn: dates.checkIn,
        checkOut: dates.checkOut,
      },
      urlSearch: '',
      rooms,
      searchItem: recentSearch.searchItem,
      userSelectedFlexibleMonths: !!dates?.flexibleMonths,
    }).toString()
    const path = pathname.includes('/map') ? '/search/map' : '/search'
    closeDropdown()
    dispatch(pushWithRegion(path, newSearch))
  }, [pathname, searchVerticals, eventAnalytics, closeDropdown, dispatch])

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    const selectableResults = results.filter(result => SELECTABLE_RESULT_TYPES.includes(result.searchType))
    if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (selectedDropdown === 0) {
        return
      }
      setSelectedDropdown(prev => prev ? prev - 1 : undefined)
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (selectedDropdown === selectableResults.length - 1) {
        return
      }
      setSelectedDropdown(prev => prev === undefined ? 0 : prev + 1)
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (selectedDropdown === undefined) {
        if (!showPlaceholder && selectableResults.length > 0) {
          onLocationSelect(selectableResults[0])
        }
        return
      } else {
        if (showPlaceholder && selectedDropdown < recentSearches.length) {
          onRecentSearchClick(recentSearches[selectedDropdown])
        } else if (showPlaceholder && selectedDropdown > recentSearches.length - 1) {
          onLocationSelect(placeholdersSearches[selectedDropdown - recentSearches.length])
        } else if (!showPlaceholder) {
          if (selectedDropdown < selectableResults.length) {
            onLocationSelect(selectableResults[selectedDropdown])
          }
        }
      }
      setSelectedDropdown(undefined)
    } else {
      if (!finalOpen) {
        // if typing happens when the dropdown closed, open it
        handleOpen()
      }
    }
  }, [onLocationSelect, onRecentSearchClick, placeholdersSearches, recentSearches, results, selectedDropdown, showPlaceholder, finalOpen, handleOpen])

  const SearchPlaceSelectProps = {
    ...props,
    onKeyDown,
    localInputRef,
    controlsContainerRef,
    searchPhrase,
    onSearchPhraseChange,
    handleOpen,
    onClearClick,
    finalOpen,
    closeDropdown,
    hasRecentSearchItems,
    recentSearches,
    selectedDropdown,
    onRecentSearchDelete,
    onRecentSearchClick,
    placeholdersSearches,
    onLocationSelect,
    showPlaceholder,
    handleResultsChange,
    label,
    placeholder,
    placeholderItems,
    setSearchPhrase,
  }

  const components = {
    // desktop
    dropdown: {
      default: SearchPlaceSelectDropdown,
      hotel: HotelSearchPlaceSelectDropdown,
      tour: TourSearchPlaceSelectDropdown,
    },
    // mobile
    inline: {
      default: SearchPlaceSelectInline,
    },
  }

  const Component = components[variant][searchVertical ?? 'default'] ?? components[variant].default

  return <Component {...SearchPlaceSelectProps} {...(variant === 'dropdown' && { size: dropdownSize })} />
}

function mapStateToProps(state: App.State): MappedStateProps {
  return {
    pathname: state.router.location.pathname,
  }
}

export default connect<MappedStateProps, undefined, Props, App.State>(mapStateToProps)(SearchPlaceSelect)
