import { useContext, useEffect, useMemo } from 'react'
import useGlobalSearchContext from 'hooks/GlobalSearch/useGlobalSearchContext'
import GlobalSearchState, { GlobalSearchStateActions, GLOBAL_SEARCH_INITIAL_STATE } from 'contexts/GlobalSearch/GlobalSearchState'
import { SEARCH_VERTICALS } from 'constants/search'
import { useAppSelector } from 'hooks/reduxHooks'
import { getDefaultUserDriverCategory } from 'selectors/carHireSelectors'
import { getCarHireSearchStateFromParams } from 'components/CarHire/carHireUtils'
import GeoContext from 'contexts/geoContext'

const AUSTRALIA_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_7f1eee11378becef394af4059cb8b04a',
    format: {
      mainText: 'Sydney, NSW (SYD-Kingsford Smith Intl.)',
      secondaryText: 'Sydney, NSW, Australia (SYD-Kingsford Smith Intl.)',
    },
    airportCode: 'SYD',
  },
  {
    searchType: 'airport',
    value: 'le_32b4b9f262dcc3ce114bc2e533594339',
    format: {
      mainText: 'Melbourne, VIC (MEL-Tullamarine)',
      secondaryText: 'Melbourne, VIC, Australia (MEL-Tullamarine)',
    },
    airportCode: 'MEL',
  },
  {
    searchType: 'airport',
    value: 'le_cf6d83e635ba166e4c3e55238f66d92a',
    format: {
      mainText: 'London (LHR-Heathrow)',
      secondaryText: 'London, United Kingdom (LHR-Heathrow)',
    },
    airportCode: 'LHR',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_55dddb74a8c2e96db33e4363b0a73b98',
    format: {
      mainText: 'Cairns',
      secondaryText: 'Cairns, Queensland, Australia',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_b573c52d599a979ba8c16e9789bb8014',
    format: {
      mainText: 'Hobart',
      secondaryText: 'Tasmania, Australia',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_464adf7b911508701f4c4e70eab19a6e',
    format: {
      mainText: 'Auckland',
      secondaryText: 'Auckland Region, New Zealand',
    },
  },
]

const USA_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_1f504b84e3ad334dbe907d0531562ce4',
    format: {
      mainText: 'Panama City (PTY-Tocumen Intl.)',
      secondaryText: 'Panama City, Panama (PTY-Tocumen Intl.)',
    },
    airportCode: 'PTY',
  },
  {
    searchType: 'airport',
    value: 'le_4a5664bdb40e7c297f234840373a463c',
    format: {
      mainText: 'Rome (FCO-Fiumicino - Leonardo da Vinci Intl.)',
      secondaryText: 'Rome, Italy (FCO-Fiumicino - Leonardo da Vinci Intl.)',
    },
    airportCode: 'FCO',
  },
  {
    searchType: 'airport',
    value: 'le_cf6d83e635ba166e4c3e55238f66d92a',
    format: {
      mainText: 'London (LHR-Heathrow)',
      secondaryText: 'London, United Kingdom (LHR-Heathrow)',
    },
    airportCode: 'LHR',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_d4cd3a4f4ae9ae32b496706cb99b0a0e',
    format: {
      mainText: 'Tel Aviv',
      secondaryText: 'Tel Aviv District, Israel',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_54ff9e9e3a2ec0300d4ce11261f5169f',
    format: {
      mainText: 'Lisbon',
      secondaryText: 'Lisbon District, Portugal',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_3b479d2992711d569ed1edc1c9eda1ad',
    format: {
      mainText: 'Munich',
      secondaryText: 'Bavaria, Germany',
    },
  },
]

const UK_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_1b715016be3afe2d4903a8edf6a83901',
    format: {
      mainText: 'Cape Town (CPT-Cape Town Intl.)',
      secondaryText: 'Cape Town, South Africa (CPT-Cape Town Intl.)',
    },
    airportCode: 'CPT',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_4b92a752784b1761b28370121cfcd525',
    format: {
      mainText: 'Paphos',
      secondaryText: 'Cyprus',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_54ff9e9e3a2ec0300d4ce11261f5169f',
    format: {
      mainText: 'Lisbon',
      secondaryText: 'Lisbon District, Portugal',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_bd80d53a4b5d3aef9d8a1fabe2feecfc',
    format: {
      mainText: 'San Francisco',
      secondaryText: 'California, United States of America',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_079230e29195cac1ce62e737740797a0',
    format: {
      mainText: 'Los Angeles',
      secondaryText: 'California, United States of America',
    },
  },
]

const FRANCE_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_832d851fd4d8851affa0b77077f5b4d6',
    format: {
      mainText: 'Rome',
      secondaryText: 'Lazio, Italy',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_342fdc93f26b8bb8fe3548a9188ea5d2',
    format: {
      mainText: 'Athens',
      secondaryText: 'Attica, Greece',
    },
  },
  {
    searchType: 'airport',
    value: 'le_f0c77599e690b0fc0ca20fb961ac9f7b',
    format: {
      mainText: 'Split (SPU)',
      secondaryText: 'Split, Croatia (SPU)',
    },
    airportCode: 'SPU',
  },
  {
    searchType: 'airport',
    value: 'le_8d732fc6a8c499198d0474273c3e8c46',
    format: {
      mainText: 'Marrakech (RAK-Menara)',
      secondaryText: 'Marrakech, Morocco (RAK-Menara)',
    },
    airportCode: 'RAK',
  },
  {
    searchType: 'airport',
    value: 'le_d0e7de26b52527ca29f4824eeef0e3de',
    format: {
      mainText: 'Catania (CTA-Fontanarossa)',
      secondaryText: 'Catania, Italy (CTA-Fontanarossa)',
    },
    airportCode: 'CTA',
  },
]

const GERMANY_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_040259596be66c3870f21222986d66dd',
    format: {
      mainText: 'Athens (ATH-Eleftherios Venizelos)',
      secondaryText: 'Athens, Greece (ATH-Eleftherios Venizelos)',
    },
    airportCode: 'ATH',
  },
  {
    searchType: 'airport',
    value: 'le_56cd07a539ee11783f66be26b70291ec',
    format: {
      mainText: 'Valencia (VLC)',
      secondaryText: 'Valencia, Spain (VLC)',
    },
    airportCode: 'VLC',
  },
  {
    searchType: 'airport',
    value: 'le_40381aeff0d6c4c8232541055627fa98',
    format: {
      mainText: 'Tenerife (TFS-Tenerife South)',
      secondaryText: 'Tenerife, Spain (TFS-Tenerife South)',
    },
    airportCode: 'TFS',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_959a557f5f6beb411fd954f3f34b21c3',
    format: {
      mainText: 'Cagliari',
      secondaryText: 'Sardinia, Italy',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_14ecb3e647f034724f0e2d004c51d6f0',
    format: {
      mainText: 'Porto',
      secondaryText: 'Porto District, Portugal',
    },
  },
]

const SPAIN_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_4fa4ee1298896f184495015bae67404b',
    format: {
      mainText: 'Las Palmas (LPA-Gran Canaria)',
      secondaryText: 'Las Palmas, Spain (LPA-Gran Canaria)',
    },
    airportCode: 'LPA',
  },
  {
    searchType: 'airport',
    value: 'le_40381aeff0d6c4c8232541055627fa98',
    format: {
      mainText: 'Tenerife (TFS-Tenerife South)',
      secondaryText: 'Tenerife, Spain (TFS-Tenerife South)',
    },
    airportCode: 'TFS',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_cd18715c3998f5e305b948ffc72cf765',
    format: {
      mainText: 'Madrid',
      secondaryText: 'Community of Madrid, Spain',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_950a4152c2b4aa3ad78bdd6b366cc179',
    format: {
      mainText: 'Málaga',
      secondaryText: 'Andalusia, Spain',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_54ff9e9e3a2ec0300d4ce11261f5169f',
    format: {
      mainText: 'Lisbon',
      secondaryText: 'Lisbon District, Portugal',
    },
  },

]

const CANADA_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_d76776c4015676786316c8948f95a07b',
    format: {
      mainText: 'Kahului, HI (OGG)',
      secondaryText: 'Kahului, HI, United States of America (OGG)',
    },
    airportCode: 'OGG',
  },
  {
    searchType: 'airport',
    value: 'le_f6c0dc01b1b43ffe7d614fbb2323880c',
    format: {
      mainText: 'Palm Springs, CA (PSP-Palm Springs Intl.)',
      secondaryText: 'Palm Springs, CA, United States of America (PSP-Palm Springs Intl.)',
    },
    airportCode: 'PSP',
  },
  {
    searchType: 'airport',
    value: 'le_f6ffcdc5f396ba547fcb5843d6ba8e22',
    format: {
      mainText: 'Orlando, FL (MCO-Orlando Intl.)',
      secondaryText: 'Orlando, FL, United States of America (MCO-Orlando Intl.)',
    },
    airportCode: 'MCO',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_f16e5f06232a362daffcb3429e42c19d',
    format: {
      mainText: 'Halifax',
      secondaryText: 'Nova Scotia, Canada',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_6781be9b5eb16b142d24c60a04c821e2',
    format: {
      mainText: 'Phoenix',
      secondaryText: 'Arizona, United States of America',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_96da601313d3127d3038e0dfc095a852',
    format: {
      mainText: 'Fort Lauderdale',
      secondaryText: 'Florida, United States of America',
    },
  },
]

const ITALY_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_4445ee26916d5209290799c145c3d66c',
    format: {
      mainText: 'Malaga (AGP)',
      secondaryText: 'Malaga, Spain (AGP)',
    },
    airportCode: 'AGP',
  },
  {
    searchType: 'airport',
    value: 'le_a8088e2fd85e4567961e2753cf0a85d3',
    format: {
      mainText: 'Lisbon (LIS-Humberto Delgado)',
      secondaryText: 'Lisbon, Portugal (LIS-Humberto Delgado)',
    },
    airportCode: 'LIS',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_959a557f5f6beb411fd954f3f34b21c3',
    format: {
      mainText: 'Cagliari',
      secondaryText: 'Sardinia, Italy',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_4ea6a546c19499318091a9df40a13181',
    format: {
      mainText: 'Palermo',
      secondaryText: 'Sicily, Italy',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_af15ce7cbd279b1a769d85d1540a5073',
    format: {
      mainText: 'Barcelona',
      secondaryText: 'Catalonia, Spain',
    },
  },
]

const OTHER_REGIONS_POPULAR_DESTINATIONS: Array<any> = [
  {
    searchType: 'airport',
    value: 'le_7f1eee11378becef394af4059cb8b04a',
    format: {
      mainText: 'Sydney, NSW (SYD-Kingsford Smith Intl.)',
      secondaryText: 'Sydney, NSW, Australia (SYD-Kingsford Smith Intl.)',
    },
    airportCode: 'SYD',
  },
  {
    searchType: 'airport',
    value: 'le_32b4b9f262dcc3ce114bc2e533594339',
    format: {
      mainText: 'Melbourne, VIC (MEL-Tullamarine)',
      secondaryText: 'Melbourne, VIC, Australia (MEL-Tullamarine)',
    },
    airportCode: 'MEL',
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_bd80d53a4b5d3aef9d8a1fabe2feecfc',
    format: {
      mainText: 'San Francisco',
      secondaryText: 'California, United States of America',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_54ff9e9e3a2ec0300d4ce11261f5169f',
    format: {
      mainText: 'Lisbon',
      secondaryText: 'Lisbon District, Portugal',
    },
  },
  {
    searchType: 'destination',
    destinationType: 'city',
    value: 'le_af15ce7cbd279b1a769d85d1540a5073',
    format: {
      mainText: 'Barcelona',
      secondaryText: 'Catalonia, Spain',
    },
  },
]

const popularDestinations = {
  AU: AUSTRALIA_POPULAR_DESTINATIONS,
  US: USA_POPULAR_DESTINATIONS,
  GB: UK_POPULAR_DESTINATIONS,
  FR: FRANCE_POPULAR_DESTINATIONS,
  DE: GERMANY_POPULAR_DESTINATIONS,
  ES: SPAIN_POPULAR_DESTINATIONS,
  CA: CANADA_POPULAR_DESTINATIONS,
  IT: ITALY_POPULAR_DESTINATIONS,
  OTHER: OTHER_REGIONS_POPULAR_DESTINATIONS,
}

export function useGlobalCarHireSearchContext(
  initialState: GlobalSearchState = GLOBAL_SEARCH_INITIAL_STATE,
  syncWithURLSearchParams?: URLSearchParams,
) {
  const [defaultAgeCategry, defaultAge] = useAppSelector(getDefaultUserDriverCategory)
  const urlState = useMemo(() => {
    if (syncWithURLSearchParams) {
      return getCarHireSearchStateFromParams(syncWithURLSearchParams)
    }
    return undefined
  }, [syncWithURLSearchParams])

  const geo = useContext(GeoContext)

  const { globalSearchState, globalSearchDispatch } = useGlobalSearchContext({
    ...initialState,
    eventAnalytics: {
      contextLocation: 'common-search',
    },
    driversAgeCategory: initialState.driversAgeCategory ?? defaultAgeCategry,
    driversAge: initialState.driversAge ?? defaultAge,
    searchVerticals: new Set([SEARCH_VERTICALS.CARHIRE]),
    pickUpTime: initialState.pickUpTime || '10:00',
    dropOffTime: initialState.dropOffTime || '10:00',
    popularDestinations: popularDestinations[geo.currentRegionCode] || popularDestinations.OTHER,
    isLoadingPopularDestinations: false,
  })

  useEffect(() => {
    if (urlState) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem: urlState.searchItem })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_SECONDARY_SEARCH_ITEM, searchItem: urlState.secondarySearchItem })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: urlState.checkinDate })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: urlState.checkoutDate })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_DRIVERS_AGE_CATEGORY, driversAgeCategory: urlState.driversAgeCategory ?? defaultAgeCategry })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_DRIVERS_AGE, driversAge: urlState.driversAge ?? defaultAge })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_PICKUP_TIME, pickupTime: urlState.pickUpTime || '10:00' })
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_RETURN_TIME, dropOffTime: urlState.dropOffTime || '10:00' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlState])

  return {
    globalCarHireSearchDispatch: globalSearchDispatch,
    globalCarHireSearchState: globalSearchState,
  }
}
