import React, { useCallback, useContext, useMemo, useRef } from 'react'
import {
  fareTypes,
  fareCabins,
  FlightsClassTypes,
  FlightsFareTypes,
} from 'constants/flight'
import { FlightDropdownOption } from 'components/Flights/types'
import { FlightSearchWidgetActions, FlightSearchWidgetFlightItem, createEmptyFlightSearchWidgetFlightItem } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FareDropdown from './FareDropdown/FareDropdown'
import Group from 'components/utils/Group'
import FlightSearchTravellerSelect from './FlightTravellerSelect/FlightSearchTravellerSelect'
import {
  flightsEconomyFareClassSelect,
  flightsPremiumFareClassSelect,
  flightsBusinessFareClassSelect,
  flightsFirstFareClassSelect,
  flightsOneWayFareTypeSelect,
  flightsReturnFareTypeSelect,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import FareRadio from './FareDropdown/FareRadio'
import FlightSearchWidgetDesktopForm from './FlightSearchWidgetDesktopForm'
import FlightSearchWidgetDesktopMultiCityForm from './FlightSearchWidgetDesktopMultiCityForm'
import config from 'constants/config'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import BusinessTravellerSelectFlightsDesktop from 'businessTraveller/components/select-traveller/BusinessTravellerSelectFlightsDesktop'

interface Props {
  onSearchAirport: () => void;
  /**
   * Sets the airports to a 'read only' mode
   * Used when defaulting airport selections such as on flight deal pages
   */
  readOnlyAirports?: boolean;
  showMultiCity?: boolean;
}

function FlightSearchWidgetDesktop(props: Props) {
  const { onSearchAirport, readOnlyAirports, showMultiCity } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)
  const isBusinessTravellerSelectEnabled = config.businessTraveller.currentAccountMode === 'business'

  const { fareType, fareCabin } = state

  const changeFareType = useCallback(
    (fareType: FlightDropdownOption) => {
      dispatch({ type: FlightSearchWidgetActions.SET_FARE_TYPE, fareType })

      if (fareType.value === FlightsFareTypes.ONE_WAY) {
        fireInteractionEvent(flightsOneWayFareTypeSelect())
      } else if (fareType.value === FlightsFareTypes.RETURN) {
        fireInteractionEvent(flightsReturnFareTypeSelect())
      }

      // If it is multi-city or one way clear the checkout date
      if ([FlightsFareTypes.MULTI_CITY, FlightsFareTypes.ONE_WAY].includes(fareType.value as FlightsFareTypes)) {
        dispatch({ type: FlightSearchWidgetActions.SET_CHECKOUT_DATE, id: state.flights[0].id, date: null })
      }

      // If it is one-way or return we only need one flight
      if ([FlightsFareTypes.ONE_WAY, FlightsFareTypes.RETURN].includes(fareType.value as FlightsFareTypes)) {
        for (let i = 1; i < state.flights.length; i++) {
          const flight = state.flights[i]
          dispatch({ type: FlightSearchWidgetActions.REMOVE_FLIGHT, payload: flight.id })
        }
      }

      // If it is multi-city we show two flights
      if (fareType.value === FlightsFareTypes.MULTI_CITY && state.flights.length === 1) {
        const item: FlightSearchWidgetFlightItem = createEmptyFlightSearchWidgetFlightItem()
        dispatch({ type: FlightSearchWidgetActions.ADD_FLIGHT, payload: item })
      }
    },
    [dispatch, state.flights],
  )

  const changeFareCabin = useCallback(
    (fareCabin: FlightDropdownOption) => {
      dispatch({ type: FlightSearchWidgetActions.SET_FARE_CABIN, fareCabin })
      switch (fareCabin.value) {
        case FlightsClassTypes.ECONOMY:
          fireInteractionEvent(flightsEconomyFareClassSelect())
          break
        case FlightsClassTypes.PREMIUM:
          fireInteractionEvent(flightsPremiumFareClassSelect())
          break
        case FlightsClassTypes.BUSINESS:
          fireInteractionEvent(flightsBusinessFareClassSelect())
          break
        case FlightsClassTypes.FIRST:
          fireInteractionEvent(flightsFirstFareClassSelect())
          break
        default:
          break
      }
    },
    [dispatch],
  )

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSearchAirport()
  }

  const isMultiCity = useMemo(() => {
    return fareType?.value === FlightsFareTypes.MULTI_CITY
  }, [fareType])

  const fareOptions = useMemo(() => {
    if (!showMultiCity) {
      return fareTypes.filter((fare) => fare.value !== FlightsFareTypes.MULTI_CITY)
    }

    return fareTypes
  }, [showMultiCity])

  const boxRef = useRef<HTMLDivElement>(null)

  return (
    <Group direction="vertical" gap={16}>
      <Group direction="horizontal" gap={32}>
        <FareRadio
          options={fareOptions}
          selectedOption={fareType}
          onOptionChange={changeFareType}
          data-testid="fare-type-dropdown"
        />

        <Group direction="horizontal" ref={boxRef} gap={12}>
          {!isBusinessTravellerSelectEnabled && (
            <FlightSearchTravellerSelect anchorRef={boxRef} />
          )}

          {isBusinessTravellerSelectEnabled && (
            <BusinessTravellerAccountGuard
              accountMode="business"
              employeeRole="BUSINESS_ADMIN"
              loading={<BusinessTravellerSelectLoadingSkeleton />}
              fallback={
                <FlightSearchTravellerSelect
                  className="traveller-input-area"
                  anchorRef={boxRef}
                  disabled
                />
              }
            >
              <BusinessTravellerSelectFlightsDesktop
                className="traveller-input-area"
                dropdownBoundaryRef={boxRef}
              />
            </BusinessTravellerAccountGuard>
          )}

          <FareDropdown
            options={fareCabins}
            selectedOption={fareCabin}
            onOptionChange={changeFareCabin}
            data-testid="fare-class-dropdown"
          />
        </Group>
      </Group>

      {!isMultiCity && (
        <FlightSearchWidgetDesktopForm
          onSubmit={onSubmit}
          readOnlyAirports={readOnlyAirports}
        />
      )}

      {isMultiCity && (
        <FlightSearchWidgetDesktopMultiCityForm
          onSubmit={onSubmit}
          readOnlyAirports={readOnlyAirports}
        />
      )}
    </Group>
  )
}

export default FlightSearchWidgetDesktop
