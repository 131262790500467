import React, { useCallback, useEffect, useMemo } from 'react'
import Clickable from 'components/Common/Clickable'
import { TrendingDestination } from './constants'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { useInView } from 'react-intersection-observer'
import Image from 'components/Common/Image/Image'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { calculateTrackingInView } from 'components/utils/OfferInView'
import * as Analytics from 'analytics/analytics'
import { destinationClickWithContext, destinationImpressionWithContext, optimizelyEventContext } from 'analytics/snowplow/events'
import uuidV4 from 'lib/string/uuidV4Utils'

const Section = styled(Clickable)`
  display: grid;
  gap: ${rem(16)};
  grid-template-columns: ${rem(85)} 1fr;
  width: ${rem(280)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: ${rem(180)} 1fr;
    flex-direction: column;
    grid-gap: ${rem(12)};
    width: ${rem(180)};
  }
`

interface Props {
  index: number;
  trendingDestination: TrendingDestination;
  selectedFilter?: App.DestinationSelectedFilters;
}

function TrendingDestinationsTile(props: Props) {
  const { index, selectedFilter, trendingDestination } = props
  const [inViewRef, _, entry] = useInView({ triggerOnce: true, threshold: 0.5 })
  const trackingInView = useMemo(() => calculateTrackingInView(entry), [entry])

  const getDestinationEvent = useCallback((eventType: 'click' | 'impression') => {
    if (!trendingDestination.destinationId || !selectedFilter) return undefined

    const commonProps = {
      destinationId: trendingDestination.destinationId,
      destinationName: trendingDestination.name,
      tileType: 'tile',
      destinationImageId: trendingDestination.imageId,
      listInstance: uuidV4(),
      listName: `homepage-trending-destinations-${selectedFilter}`,
      itemPosition: index + 1,
    }

    const eventMap = {
      click: destinationClickWithContext,
      impression: destinationImpressionWithContext,
    }

    return eventMap[eventType]?.(commonProps)
  }, [trendingDestination, selectedFilter, index])

  const trackEventWithOptimizelyContext = useCallback((eventType: 'click' | 'impression', optimizelyEventId: string, optimizelyEventKey: string) => {
    const event = getDestinationEvent(eventType)
    if (event) {
      const context = [
        ...(event.context || []),
        optimizelyEventContext(optimizelyEventId, optimizelyEventKey),
      ]

      Analytics.trackEvent({
        ...event,
        context,
      })
    }
  }, [getDestinationEvent])

  const onClick = useCallback(() => {
    trackEventWithOptimizelyContext('click', '5184425579773952', 'click-homepage-trending-destination-tile')
  }, [trackEventWithOptimizelyContext])

  useEffect(() => {
    if (trackingInView) {
      trackEventWithOptimizelyContext('impression', '6289788899164160', 'impression-homepage-trending-destination-tile')
    }
  }, [getDestinationEvent, trackingInView, trackEventWithOptimizelyContext])

  return (<Section to={trendingDestination.url} target="_blank" onClick={onClick} ref={inViewRef}>
    <Image
      fit="center"
      width={180}
      height={180}
      dpr={2}
      aspectRatio="1:1"
      id={trendingDestination.imageId}
    />
    <Group direction="vertical" gap={4} horizontalAlign="start" verticalAlign="center" tabletVerticalAlign="start">
      <BodyText variant="large" weight="bold">{trendingDestination.name}</BodyText>
    </Group>
  </Section>
  )
}

export default TrendingDestinationsTile
