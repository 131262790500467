import StickyPrompt from 'components/Common/StickyPromptCard/StickyPrompt'
import { rem } from 'polished'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import AccountAccessPromptIcon from './AccountAccessPromptIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalContext from 'contexts/ModalContext'
import AccountAccessModal from '../AccountAccess/AccountAccessModal'
import Group from 'components/utils/Group'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineGlassMartiniAltIcon from 'components/Luxkit/Icons/line/LineGlassMartiniAltIcon'
import LineGlobeAltIcon from 'components/Luxkit/Icons/line/LineGlobeAltIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import useClickOutside from 'hooks/useClickOutside'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { matchPath } from 'react-router'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import config from 'constants/config'

const AccessWrapper = styled.div`
  position: relative;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  right: ${rem(20)};
  top: ${rem(20)};
`

const AccessPrompt = styled(StickyPrompt)`
  padding: ${rem(24)};

  ${mediaQueryUp.tablet} {
    width: ${rem(400)};
  }
`

const AccountAccessPromptRoutes = [
  '/:regionCode/hotels',
  '/:regionCode/tours',
  '/:regionCode/cruises',
  '/:regionCode/car-hire',
  '/:regionCode/experiences',
  '/:regionCode/flights',
  '/:regionCode/homes-and-villas',
  '/:regionCode/offer',
  '/:regionCode/tour',
  '/:regionCode/cruises',
  '/:regionCode/search',
  '/:regionCode/lux-plus',
  '/:regionCode/ultra-lux',
]

/**
 * We only want to show the prompt once per 'session' for the user
 * We could save this in redux, but that feels way overkill. Lets just have some local state
 */
let hasSeenPrompt = false

function AccountAccessPrompt() {
  const promptRef = useRef<HTMLDivElement>(null)
  const [show, setShow] = useState<boolean>(false)
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isMobileDevice = useIsMobileDevice()

  const signUpModalTitleEnabled = useOptimizelyExperiment(OptimizelyExperiments.signUpModalTitleEnabled)
  const titleExperimentVariant = signUpModalTitleEnabled ? <>Exclusive prices and <i>more</i> with every booking</> : <>Huge savings and <i>more</i> with every booking</>

  const canShow = useMemo(() => {
    // homepage needs an exact match (otherwise it matches every path!)
    return config.ENABLE_ACCOUNT_ACCESS_PROMPT &&
      (
        matchPath(currentPath, { path: '/:regionCode', exact: true }) ||
        // all other pages work with unexact
        matchPath(currentPath, { path: AccountAccessPromptRoutes, exact: false })
      )
  }, [currentPath])

  useEffect(() => {
    if (!loggedIn && !hasSeenPrompt) {
      setShow(true)
      hasSeenPrompt = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loggedIn || !canShow) {
      setShow(false)
    }
  }, [loggedIn, canShow])

  const dismissPrompt = useCallback(() => {
    setShow(false)
  }, [])

  const onLogin = useCallback(() => {
    dismissPrompt()
    showModal(<AccountAccessModal />)
  }, [dismissPrompt, showModal])

  useClickOutside(promptRef, dismissPrompt, show)

  if (isMobileDevice) {
    return null
  }

  return <AccessWrapper>
    <AccessPrompt show={show} sticky={false} ref={promptRef}>
      <CloseButton
        kind="tertiary"
        shape="square"
        onClick={dismissPrompt}
      >
        <LineTimesIcon />
      </CloseButton>
      <Group direction="vertical" horizontalAlign="center" gap={16}>
        <AccountAccessPromptIcon />
        <VerticalSpacer gap={24}>
          <VerticalSpacer gap={12}>
            <Heading variant="heading3">
              {titleExperimentVariant}
            </Heading>
            <VerticalSpacer gap={4}>
              <BodyTextBlock variant="medium" startIcon={<LineGlassMartiniAltIcon colour="highlight-secondary-normal" />}>
                <b>More inclusions:</b> Drinks, dining, transfers & more
              </BodyTextBlock>
              <BodyTextBlock variant="medium" startIcon={<LineGlobeAltIcon colour="highlight-secondary-normal" />}>
                <b>More options:</b> 1,000s of hotels, tours & flights
              </BodyTextBlock>
              <BodyTextBlock variant="medium" startIcon={<LinePhoneIcon colour="highlight-secondary-normal" />}>
                <b>More expertise:</b> 24/7 customer service, worldwide
              </BodyTextBlock>
            </VerticalSpacer>
          </VerticalSpacer>
          <TextButton size="large" kind="primary" fit="full-width" onClick={onLogin}>
            Log in or sign up
          </TextButton>
        </VerticalSpacer>
      </Group>
    </AccessPrompt>
  </AccessWrapper>
}

export default React.memo(AccountAccessPrompt)
