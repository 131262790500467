import { getExperienceItemView } from 'checkout/lib/utils/experiences/view'
import { excludeNullOrUndefined } from 'checkout/utils'
import { isExperienceItem, isTransferItem } from 'lib/checkout/checkoutUtils'
import { DAY_MONTH_NAME_SHORT, SHORT_TIME_FORMAT_AM_PM } from 'constants/dateFormats'
import { min, sum } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'
import moment from 'moment'
import { pluralizeToString } from 'lib/string/pluralize'

import { EXPERIENCE_ANY_DATE, EXPERIENCE_GIFT_SCHEDULE_DATE_LIMIT_BEFORE_EXPIRATION_DATE } from 'constants/experience'
import { getExperienceDatesKey, getExperienceTimesKey } from 'lib/experiences/experienceUtils'
import { getCommissionData } from './agentHub'
import { convertTZ } from 'lib/datetime/dateUtils'

export const getExperienceItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.ExperienceItem> => items.filter(isExperienceItem),
)

export const getExperienceItemsView = createSelector(
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.checkout.cart.currencyCode,
  (
    experienceItems,
    experiences,
    experienceTimes,
    currencyCode,
  ): App.WithDataStatus<Array<App.Checkout.ExperienceItemView>> => {
    const itemViews = experienceItems.map(item => {
      return getExperienceItemView(item, experiences, experienceTimes, currencyCode)
    })

    return {
      hasRequiredData: itemViews.every(Boolean),
      data: itemViews.filter(excludeNullOrUndefined),
    }
  },
)

export const getTransferItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.TransferItem> => items.filter(isTransferItem),
)

export const getTransferItemsView = createSelector(
  (state: App.State) => getTransferItems(state),
  (state: App.State) => state.experience.experiences,
  (
    transferItems,
    experiences,
  ): App.WithDataStatus<Array<App.Checkout.TransferItem>> => {
    return {
      // make sure full experience data has been fetched for every transfer
      hasRequiredData: transferItems.every(transfer => !!experiences[transfer.experienceId]),
      data: transferItems,
    }
  },
)

export const getExperienceBreakdownView = createSelector(
  getExperienceItemsView,
  getCommissionData,
  (viewWithStatus, commissionData): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const price = sum(viewWithStatus.data.map(i => i?.price ?? 0))
    const totalDiscount = !commissionData ? 0 : sum(viewWithStatus.data.map(expView => commissionData.itemsCommission[expView.id]?.itemDiscount ?? 0))

    const experienceBreakdownView = {
      title: 'Experiences',
      price,
      memberPrice: sum(viewWithStatus.data.map(i => i?.memberPrice ?? 0)),
      additionalInfoText: [],
      ...(commissionData && {
        commission: {
          commissionPercentage: Number((totalDiscount / price * 100).toFixed(2)),
          itemDiscount: totalDiscount,
        },
      }),
      items: viewWithStatus.data.filter(excludeNullOrUndefined).map<App.Checkout.ExperienceItemBreakdownView>(expView => {
        const additionalInfoText = expView.ticketViews
          .filter(ticketView => !ticketView.unavailable)
          .map(ticketView => `${ticketView.count}x ${ticketView.name}`)

        if (expView.pickupPoint) { additionalInfoText.push(`Pick up: ${expView.pickupPoint.name}`) }

        const dateTimeLanguage = [
          expView.bookingDate && expView.bookingDate !== EXPERIENCE_ANY_DATE && moment.utc(expView.bookingDate).format(DAY_MONTH_NAME_SHORT),
          expView.bookingTime && expView.hasTimeslots && !expView.hideTimeSlots && moment.utc(`${expView.bookingDate}T${expView.bookingTime}`).format(SHORT_TIME_FORMAT_AM_PM),
          expView.language?.name,
        ].filter(excludeNullOrUndefined)

        if (dateTimeLanguage.length > 0) { additionalInfoText.push(dateTimeLanguage.join(' • ')) }

        return {
          title: expView.title,
          price: expView.price,
          additionalInfoText,
          itemType: 'experience',
          offerId: expView.experienceId,
          additionalElements: [],
          taxesAndFees: expView.taxesAndFees,
        }
      }),
    }

    return {
      hasRequiredData: true,
      data: [experienceBreakdownView],
    }
  },
)

export const getExperienceTotalPrice = createSelector(
  getExperienceBreakdownView,
  (views) => sum(views.data, view => view.price),
)

export const getTransfersBreakdownView = createSelector(
  getTransferItemsView,
  (viewWithStatus): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) { return { hasRequiredData: viewWithStatus.hasRequiredData, data: [] } }

    const transferBreakdownView:App.Checkout.PriceBreakdownView = {
      title: 'Transfers',
      price: sum(viewWithStatus.data.map(item => item.transfer.option!.price ?? 0)),
      memberPrice: sum(viewWithStatus.data.map(item => item.transfer.option!.memberPrice ?? 0)),
      additionalInfoText: [],
      items: viewWithStatus.data.filter(excludeNullOrUndefined).map<App.Checkout.TransferItemBreakdownView>(item => {
        return {
          title: item.transfer.title ?? '',
          price: item.transfer.option!.price,
          memberPrice: item.transfer.option!.memberPrice,
          itemType: item.itemType,
          offerId: item.transfer.option!.id,
          additionalInfoText: [
            item.transfer.option!.name,
            pluralizeToString('passenger', item.transfer.option!.maxPassengers),
            `Maximum ${pluralizeToString('bag', item.transfer.option!.maxCheckedBags)}`,
          ],
          additionalElements: [],
        }
      }),
    }

    return {
      hasRequiredData: true,
      data: [transferBreakdownView],
    }
  },
)

export const getCheckoutFormFieldCustomProps = createSelector(
  (state: App.State) => state.checkout.cart.isGift,
  getExperienceItemsView,
  (isGift, experienceItemsView): App.Checkout.FormFieldCustomProps | undefined => {
    // the user can buy an experience gift only in standalone and is always one experience
    const experienceItem = experienceItemsView.data[0]
    const maxScheduleDate = (isGift && experienceItem?.isGift) ? (moment(experienceItem.bookByDate ?? experienceItem.expirationDate).subtract(EXPERIENCE_GIFT_SCHEDULE_DATE_LIMIT_BEFORE_EXPIRATION_DATE, 'days').toDate()) : undefined
    const customProps = {
      gift: {
        maxScheduleDate,
      },
    }

    return customProps
  },
)

export const isSoldOut = createSelector(
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.experience.experienceDates,
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.geo.currentCurrency,
  (experienceItems, timeslot, experienceDates, experiences, currency): boolean => {
    const isGift = experienceItems.some(item => item.date === 'any')
    if (isGift) return false

    const isSoldOut = experienceItems.some(checkoutItem => {
      let isAvailable = true
      const { experienceId, date, time, bookingType, pickupPointId, redemptionLocationId } = checkoutItem

      if (bookingType === 'CALENDAR-TIMESLOTS' && time) {
        const key = getExperienceTimesKey(experienceId, date, { currency, redemptionLocationId, pickupPointId })
        const availableTimeslots = timeslot[experienceId]?.[key]

        if (availableTimeslots?.slots?.length && availableTimeslots.fetching === false) {
          isAvailable = availableTimeslots.slots.some((slot) => slot.time === time)
        }
      } else if (bookingType === 'CALENDAR-NO-TIMESLOTS') {
        const key = getExperienceDatesKey(experienceId, { pickupPointId, redemptionLocationId })
        const availableDates = experienceDates[experienceId]?.[key]

        if (availableDates?.dates?.length && availableDates.fetching === false) {
          const currentDate = availableDates.dates.find(availableDate => availableDate.day === date)
          isAvailable = currentDate?.soldOut === false
        }
      } else if (bookingType === 'NO-CALENDAR-FIXED-END') {
        const experience = experiences[experienceId]

        if (experience) {
          const minTicket = min(checkoutItem.tickets.filter(ticket => ticket.bookByDate), (ticket) => new Date(ticket.bookByDate!))
          const bookByDate = minTicket?.bookByDate ?? experience.bookByDate ?? experience.expirationDate

          if (!bookByDate) {
            isAvailable = experience.status === 'ONLINE'
          } else {
            const localizedBookByDate = moment(convertTZ(new Date(bookByDate), experience.location.timezone))
            const localizedNow = moment(convertTZ(new Date(), experience.location.timezone))
            isAvailable = localizedNow.isBefore(localizedBookByDate, 'minutes')
          }
        }
      }
      return !isAvailable
    })

    return isSoldOut
  },
)
