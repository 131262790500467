import {
  isAccommodationItem,
  isCruiseItem,
  isExperienceItem,
  isFlightItem,
  isLEHotelItem,
  isTourV1Item,
  isTourV2Item,
} from 'lib/checkout/checkoutUtils'
import createSelector from 'lib/web/createSelector'
import { getExperienceTotals, getTransferTotals } from 'checkout/selectors/payment/experience'
import { getAccommodationTotals } from 'checkout/selectors/payment/accommodation'
import { getVirginFlightTotals } from 'checkout/selectors/payment/flights'
import { computeFeesTotal } from 'checkout/lib/utils/flights/fees'
import { VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP } from 'constants/partnerships'
import { getFlightItems, getFlightItemsView } from '../view/flights'
import { excludeNullOrUndefined } from 'checkout/utils'
import { VIRGIN_AUSTRALIA } from 'constants/flight'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
} from 'constants/checkout'
import { OFFER_TYPE_LAST_MINUTE, PRODUCT_TYPE_ULTRALUX } from 'constants/offer'
import { PAYMENT_OPTIONS } from 'constants/payment'
import { checkoutTotalsView, isUsingCredit } from './checkout'

const isUltraLuxItem = (offer: App.Offer): boolean => {
  return offer && offer.productType === PRODUCT_TYPE_ULTRALUX
}

const isLastMinuteHotel = (offer: App.Offer): boolean => {
  return offer && offer.type === OFFER_TYPE_LAST_MINUTE
}

export const isVelocityCardValid = createSelector(
  (state: App.State) => state.checkout.payment.sso.velocity.isAuthenticated,
  (state: App.State) => state.checkout.payment.partnerships?.velocity?.burn,
  (state: App.State) => state.checkout.payment.paymentSelected,
  (isAuthenticated, velocityBurnState, paymentSelection): boolean => {
    return isAuthenticated &&
      !!velocityBurnState &&
      !!velocityBurnState.pointsBurned &&
      velocityBurnState.pointsBurned > 0 &&
      paymentSelection === PAYMENT_OPTIONS.FULL
  },
)

export const getVelocityBurnableAmount = createSelector(
  (state: App.State) => state.checkout?.promo?.discountTotal ?? 0,
  (state: App.State) => isUsingCredit(state) ? checkoutTotalsView(state).data.amountPayableByCredit : 0,
  getAccommodationTotals,
  getExperienceTotals,
  getTransferTotals,
  getVirginFlightTotals,
  (promoDiscountTotal, creditTotal, ...totalViews): App.WithDataStatus<number> => {
    return totalViews.reduce<App.WithDataStatus<number>>((acc, view) => {
      const otherFees = view.data.otherFees ?
        typeof view.data.otherFees === 'number' ? view.data.otherFees : computeFeesTotal(view.data.otherFees) :
        0
      return {
        hasRequiredData: acc.hasRequiredData && view.hasRequiredData,
        data: acc.data +
          (view.data?.price ?? 0) +
          (view.data?.surcharge ?? 0) +
          otherFees,
      }
    }, { hasRequiredData: true, data: -promoDiscountTotal - creditTotal })
  },
)

const getVirginFlightItems = createSelector(
  getFlightItems,
  getFlightItemsView,
  (flightItems, flightItemsView) => {
    const virginFlightItemsView = flightItemsView.data
      .filter(excludeNullOrUndefined)
      .filter(view => view.validatingCarrierName === VIRGIN_AUSTRALIA)
    return flightItems.filter((item) => virginFlightItemsView.some(view => view.journeyId === item.searchId))
  },
)

export const getVelocityEligibleItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  getVirginFlightItems,
  (items, virginFlights): Array<App.Checkout.AnyItem> => {
    const eligibleItems = items.filter((item) => {
      return ![
        CHECKOUT_ITEM_TYPE_INSURANCE,
        CHECKOUT_ITEM_TYPE_BEDBANK,
        CHECKOUT_ITEM_TYPE_CAR_HIRE,
        CHECKOUT_ITEM_TYPE_FLIGHT,
        CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
      ].includes(item.itemType)
    })
    return eligibleItems.concat(virginFlights)
  },
)

export const mapOrderItemTypesToVelocityCatalogueRef = createSelector(
  getVelocityEligibleItems,
  (state: App.State) => state.offer.offers,
  (items, offers): VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP | null => {
    if (items.length > 1) {
      // multi-room booking should still be hotel
      if (items.every(isLEHotelItem)) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.HOTEL
      }
      return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.PACKAGE
    } else if (items.length === 1) {
      const item = items[0]
      if (isFlightItem(item)) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.FLIGHT
      }
      if (isExperienceItem(item)) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.EXPERIENCE
      }
      if (isAccommodationItem(item) && offers && isUltraLuxItem(offers[item.offerId])) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.ULTRA_LUX
      }
      if (isTourV1Item(item) || isTourV2Item(item)) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.TOUR
      }
      if (isCruiseItem(item)) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.CRUISE
      }
      if (isLEHotelItem(item) && offers && isLastMinuteHotel(offers[item.offerId])) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.LAST_MINUTE_HOTEL
      }
      if (isLEHotelItem(item)) {
        return VIRGIN_VELOCITY_CATALOGUE_REFERENCE_MAP.HOTEL
      }
    }
    return null
  },
)
