import React, { useMemo } from 'react'
import { Refundable, getRefundDescriptions, getRefundStatus } from '../../Common/CancellationPolicies/utils/getCancellationPolicyStatus'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props {
  cancellationPolicies: Array<App.RoomRateCancellationPolicy>;
  offerType: string;
}

function SearchTileStaticCancellationPolicy(props: Props) {
  const {
    cancellationPolicies,
    offerType,
  } = props
  const refundStatus = cancellationPolicies && getRefundStatus(cancellationPolicies)
  const refundDescriptions = useMemo(() => getRefundDescriptions(offerType), [offerType])
  return (
    (refundStatus === Refundable.Fully || refundStatus === Refundable.Partial) ? (
      <Group direction="vertical">
        <BodyText variant="medium">
          <BodyText
            variant="medium"
            colour="highlight-secondary"
            weight="bold"
            as="span"
          >
            {refundDescriptions[refundStatus]}&nbsp;
          </BodyText>
        </BodyText>
      </Group>
    ) : null
  )
}

export default SearchTileStaticCancellationPolicy
