import React, { useRef, useMemo, useCallback, useContext, MouseEventHandler } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'
import pluralize from 'lib/string/pluralize'
import { isNonEmptyArray, sortBy, sum } from 'lib/array/arrayUtils'
import OfferTileInclusionsModal from './OfferTileInclusionsModal'
import ModalContext from 'contexts/ModalContext'
import TextLink from 'components/Luxkit/TextLink'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import OfferInclusionItem from 'components/Common/Inclusions/OfferInclusionItem'

const Root = styled.div`
  position: relative;
  overflow: hidden;
`

interface Props {
  className?: string;
  tileInclusionsList?: Array<App.OfferInclusion> | Array<App.PackageInclusion>;
  heading: string;
  abTestOfferTile?: boolean;
  maxInclusionsCount?: number;
  maxLineCount?: number;
  offerURL?: string
  offerTitle: string
  filters?: App.OfferListFilters
  inclusionsModalHeading?: string
  viewMoreText?: 'inclusion' | 'amenity'
}

function filtersRank(inclusion: string, filters: App.OfferListFilters, children: boolean) {
  if (children) {
    if (inclusion.includes('child')) {
      return 2
    }
    if (inclusion.includes('family')) {
      return 2
    }
    if (inclusion.includes('kid')) {
      return 2
    }
  }
  for (const filter of filters.inclusions || []) {
    const lowercaseFilter = filter.toLowerCase()
    if (inclusion.includes(lowercaseFilter)) {
      return 1
    }
    if ((inclusion.includes('massage') || inclusion.includes('spa')) && lowercaseFilter.includes('spa')) {
      return 1
    }
    if (inclusion.includes('late') && lowercaseFilter.includes('late')) {
      return 1
    }
    if (inclusion.includes('early') && lowercaseFilter.includes('early')) {
      return 1
    }
    if (inclusion.includes('drink') && lowercaseFilter.includes('drink')) {
      return 1
    }
  }
  return 0
}

function sortByFilters(inclusions: Array<App.OfferInclusion>, filters?: App.OfferListFilters): Array<App.OfferInclusion> {
  if (!filters || inclusions.length === 0) return inclusions
  const children = !!filters.rooms && sum(filters.rooms, (room) => room.children ?? 0) > 0

  return sortBy(inclusions, (inclusion) => {
    return filtersRank(inclusion.description.toLowerCase(), filters, children)
  }, 'desc')
}

// Since we don't know the total lines until we render the inclusions, we estimate the number of lines by multiples of lettersPerLine
function limitNumberOfInclusionsByLineCount(inclusions: Array<App.OfferInclusion | App.PackageInclusion>, maxLineCount: number, lettersPerLine = 55) {
  let totalLines = 0
  const filteredInclusions: Array<App.OfferInclusion | App.PackageInclusion> = []

  for (const inclusion of inclusions) {
    const inclusionText = inclusion.description
    if (!inclusionText) continue
    const lines = Math.ceil(inclusionText.length / lettersPerLine)

    if (totalLines + lines <= maxLineCount) {
      filteredInclusions.push(inclusion)
      totalLines += lines
    } else {
      // Break the loop if adding the current inclusion exceeds the limit
      break
    }
  }

  // We want to leave space for the "More inclusions" text if it's going to be necessary
  if (filteredInclusions.length < inclusions.length && totalLines === maxLineCount) {
    return filteredInclusions.slice(0, -1)
  }

  return filteredInclusions
}

const defaultInclusionList: Array<App.OfferInclusion> = []

function OfferTileInclusions({
  className,
  tileInclusionsList = defaultInclusionList,
  heading = 'Your handpicked inclusions',
  abTestOfferTile,
  maxInclusionsCount = 5,
  offerURL,
  offerTitle,
  maxLineCount,
  filters,
  inclusionsModalHeading,
  viewMoreText = 'inclusion',
}: Props) {
  const showReducedInclusions = abTestOfferTile || !!maxInclusionsCount || !!maxLineCount

  const isMobile = useIsMobileScreen()
  const sortedInclusions = useMemo(() => sortByFilters(tileInclusionsList, filters), [tileInclusionsList, filters])

  const inclusionsEl = useRef(null)
  const shownInclusions = (maxLineCount ?
    limitNumberOfInclusionsByLineCount(sortedInclusions, maxLineCount, isMobile ? 40 : 55) :
    sortedInclusions.slice(0, maxInclusionsCount))
  const numInclusionsLeft = sortedInclusions.length - shownInclusions.length

  const modalElement = useModalElementContext()
  const showModal = useContext(ModalContext)
  const showMoreInclusionsModal = useCallback<MouseEventHandler>(async(e) => {
    e.stopPropagation()
    e.preventDefault()
    if (!sortedInclusions) return
    const isViewOfferDismissal = await showModal<boolean>(
      <OfferTileInclusionsModal
        offerURL={offerURL}
        title={offerTitle}
        inclusionList={sortedInclusions}
        heading={inclusionsModalHeading}
      />,
    )
    if (isViewOfferDismissal) {
      modalElement?.resolve()
    }
  }, [sortedInclusions, showModal, offerURL, offerTitle, inclusionsModalHeading, modalElement])

  return (
    <Root className={cn(className, { 'landing-page-test': abTestOfferTile })}>
      {!!heading && <BodyText variant="medium" weight="bold">{heading}</BodyText>}
      <div ref={inclusionsEl}>
        {isNonEmptyArray(sortedInclusions) && (showReducedInclusions ? shownInclusions : sortedInclusions).map((inclusion) => <OfferInclusionItem
          key={inclusion.id}
          inclusion={inclusion}
          showMinStayDescription
        />)}
      </div>
      {isNonEmptyArray(sortedInclusions) && showReducedInclusions && numInclusionsLeft > 0 &&
        <TextLink onClick={showMoreInclusionsModal} weight="bold" size="medium">
          {`+ ${numInclusionsLeft} more ${pluralize({ singular: viewMoreText, count: numInclusionsLeft }).text}`}
        </TextLink>
      }
    </Root>
  )
}

export default OfferTileInclusions
