import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import { ENROL_LUX_LOYALTY_ACCOUNT, FETCH_LUX_LOYALTY_ACCOUNT, FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY, FETCH_LUX_LOYALTY_PROGRAM_CONFIG, USER_LOGOUT } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialLuxLoyaltyState: App.LuxLoyaltyState = {
  account: {
    accountDetails: undefined,
    fetching: false,
    error: undefined,
  },
  eligibility: {
    tier: undefined,
    fetching: false,
    error: undefined,
  },
  programConfig: {
    configDetails: undefined,
    fetching: false,
    error: undefined,
  },
}

const apiRequests = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: () => ({
    account: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: () => ({
    account: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: () => ({
    eligibility: {
      fetching: true,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_PROGRAM_CONFIG]: () => ({
    programConfig: {
      fetching: true,
      error: undefined,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      accountDetails: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      accountDetails: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [USER_LOGOUT]: () => initialLuxLoyaltyState,
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: (_state, action) => ({
    eligibility: {
      tier: action.data,
      fetching: false,
      error: undefined,
    },
  }),
  [FETCH_LUX_LOYALTY_PROGRAM_CONFIG]: (_state, action) => ({
    programConfig: {
      configDetails: action.data,
      fetching: false,
      error: undefined,
    },
  }),
})

const apiFailures = reducerSwitch<App.LuxLoyaltyState>({
  [ENROL_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT]: (_state, action) => ({
    account: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY]: (_state, action) => ({
    eligibility: {
      fetching: false,
      error: action.error,
    },
  }),
  [FETCH_LUX_LOYALTY_PROGRAM_CONFIG]: (_state, action) => ({
    programConfig: {
      fetching: false,
      error: action.error,
    },
  }),
})

export default createReducer<App.LuxLoyaltyState>(initialLuxLoyaltyState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
})
