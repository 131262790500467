import React, { ComponentProps, createContext, useContext } from 'react'
import Group from 'components/utils/Group'

/**
 * This context provides a way for other label groups to know if they are within an existing label group
 * This will allow us to nest label groups without causing layout errors
 */
const LabelGroupContext = createContext<boolean>(false)

type Props = Omit<ComponentProps<typeof Group>, 'direction' | 'gap' | 'noDisplayWhenEmpty' | 'verticalAlign'>

function LabelGroup(props: Props) {
  const { className, wrap, ...rest } = props

  const isInLabelGroup = useContext(LabelGroupContext)

  if (isInLabelGroup) {
    // don't need to nest it, return only children
    return <>{rest.children}</>
  }

  // Why the double <Group>?
  // Because we don't want to let the label group to stretch.
  return <LabelGroupContext.Provider value>
    <Group
      className={className}
      direction="horizontal"
      verticalAlign="start"
      horizontalAlign="stretch"
    >
      <Group
        {...rest}
        direction="horizontal"
        verticalAlign="center"
        gap={4}
        wrap={wrap ?? 'wrap'}
        noDisplayWhenEmpty
      />
    </Group>
  </LabelGroupContext.Provider>
}

export default LabelGroup
