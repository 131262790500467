import React, { useEffect, useCallback, useContext, useMemo, MouseEventHandler, useRef } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchNotificationsForSlug, dismissNotificationBanner } from 'actions/ContentActions'
import { SITEWIDE_NOTIFICATION_SLUG } from 'constants/content'
import Image from 'components/Common/Image'
import GeoContext from 'contexts/geoContext'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import { getNotificationBannerHashFromCookie } from 'cookies/notificationBannerCookie'
import { encode } from 'lib/encoding/isomorphicBase64'
import config from 'constants/config'
import { getClosestAirport } from 'selectors/flightsSelectors'
import TextLink from 'components/Luxkit/TextLink'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'
interface Props {
  notificationBanner: App.NotificationBanner | null;
  slug?: string;
  'data-testid'?: string;
  postcode?: string;
}

const VICStoreBanner: App.NotificationBanner = {
  bannerText: 'Book your next escape in-store at Luxury Escapes, Chadstone Shopping Centre',
  linkText: 'BOOK NOW',
  linkTo: 'https://luxuryescapes.com/au/chadstone.p1',
  openInNewTab: false,
  id: 'defaultNotificationBanner',
  slug: 'defaultNotificationBanner',
  // we will use the existing notification banner setup for our actual show value
  show: true,
}

function NotificationBanner(props: Props) {
  const {
    slug = SITEWIDE_NOTIFICATION_SLUG,
    notificationBanner,
    postcode,
  } = props
  const dispatch = useAppDispatch()

  const geo = useContext(GeoContext)
  const closestAirport = useAppSelector(getClosestAirport)
  const banner = useMemo(() => {
    if (
      (slug === SITEWIDE_NOTIFICATION_SLUG || slug === 'none') &&
      isEnabledForFeature(config.CHADSTONE_STORE_BANNER_REGIONS, geo.currentRegionCode)
    ) {
      // victorian users get a special banner for the new store
      const isVic = geo.geoStateName === 'VIC'
      const isVicPostcode = postcode?.startsWith('3')
      const isVicAirport = closestAirport?.code === 'MEL'

      if (isVic || isVicPostcode || isVicAirport) {
        // we want this notification to show even if there is no site-wide set
        // but we also want to respect if someone has closed it
        // so manually check whether the user has closed *this* banner
        const vicBannerHash = getNotificationBannerHashFromCookie(slug)
        return {
          ...VICStoreBanner,
          show: encode(VICStoreBanner.bannerText) !== vicBannerHash,
        }
      }
    }

    return notificationBanner
  }, [slug, geo.currentRegionCode, geo.geoStateName, notificationBanner, postcode, closestAirport])

  useEffect(() => {
    if (slug) {
      dispatch(fetchNotificationsForSlug(slug))
    }
  }, [dispatch, slug])

  const onDismiss = useCallback(() => {
    if (banner) {
      dispatch(dismissNotificationBanner(slug, banner.bannerText))
    }
  }, [dispatch, slug, banner])

  const containerRef = useRef<HTMLDivElement>(null)
  useAppHeaderObserver('notification-banner', containerRef)

  const handleLinkClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((e) => {
    if (banner?.openInPopup) {
      e.preventDefault()

      window.open(banner.linkTo, 'popup', 'width=640,height=640')
    }
  }, [banner])

  if (!banner?.show) {
    return null
  }

  const { openInNewTab, openInPopup, bannerText, linkText, linkTo, image } = banner

  const linkTarget = openInNewTab ? '_blank' : (openInPopup ? 'popup' : undefined)

  const isExternalLink = linkTo?.startsWith('https://')
  const showLink = linkTo && linkText

  return <PageBanner
    ref={containerRef}
    id="notification-page-banner"
    data-testid="notification-page-banner"
    kind="promotional-primary"
    icon={image ? <div><Image id={image.id} width={image.width} alt={image.alt} enhancedFormats={false} quality="best" /></div> : undefined}
    description={bannerText}
    onDismiss={onDismiss}
    tabletAlign="center"
    trailingContent={showLink && <TextLink
      rel="noopener"
      size="medium"
      target={linkTarget}
      onClick={handleLinkClick}
      // To prevent auto-append current region to external links
      to={!isExternalLink ? linkTo : undefined}
      href={isExternalLink ? linkTo : undefined}
    >
      {linkText}
    </TextLink>}
  />
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  return {
    notificationBanner: state.content.notifications[ownProps.slug || SITEWIDE_NOTIFICATION_SLUG],
    postcode: state.auth.account?.postcode,
  }
}

export default connect(mapStateToProps)(NotificationBanner)
