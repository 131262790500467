/** This is a generic search tile which are used on condensed state of
 *  Flash, Last Minute and Tactical Always On as the designs are same */

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import ImageWithReservedSpace from 'components/Common/ImageWithReservedSpace'
import OfferBadge from 'components/Common/OfferBadge'
import SoldOutMessage from '../Support/SoldOutMessage'
import BodyText from 'components/Luxkit/Typography/BodyText'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import BundleOfferLocation from '../BundleOfferLocation'
import { nonNullable } from 'lib/array/arrayUtils'
import { ImageParams } from 'components/Common/ResponsiveImage'

const Main = styled.div`
  display: flex;
  flex-direction:column;

  ${mediaQueryUp.tablet} {
    flex-direction:row;
  }
`

const Content = styled.div`
  padding: ${rem(16)};
  flex: 1;

  ${mediaQueryUp.tablet} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: ${rem(262)};
    padding: ${rem(24)} ${rem(24)} ${rem(16)}
  }
`

const LeftContent = styled.div`
  align-self: flex-start;
  flex: 1;
`

const RightContent = styled.div`
  align-self: flex-end;
  margin-top: ${rem(16)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${mediaQueryUp.tablet} {
    margin-left: ${rem(8)};
  }
`

const StyledBodyTextBlock = styled(BodyTextBlock)`
  margin: ${rem(16)} 0;

  ${mediaQueryUp.tablet} {
    margin: ${rem(8)} 0;
  }
`

const ProductTypeLabelContainer = styled.div`
  position: absolute;
  top: ${rem(20)};
  left: ${rem(20)};
`

const StyledOfferBadge = styled(OfferBadge)`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(16)};
`

const narrowImageParams: ImageParams = {
  mobileAspectRatio: '3:2',
  mobileWidth: '100vw',
  tabletAspectRatio: '14:9',
  tabletWidth: '313px',
  desktopAspectRatio: '41:15',
  desktopWidth: '491px',
}

interface Props {
  offer: App.BundleOffer;
  productClick?: (offer: App.Offer | App.OfferSummary) => void;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  onImageLoad?: () => void
}

function SearchBundleOfferTileCondensed(props: Props) {
  const {
    offer,
    filters,
    eagerLoadFirstImage,
    onImageLoad,
  } = props

  const {
    name,
  } = offer

  const imageParameters = narrowImageParams

  // Eg. `Melbourne`, `Victoria` & `Kuta`, `Bali`
  const locationCaption = useMemo(() =>
    Object.values(offer.bundledOffers ?? {}).map(offer =>
      nonNullable([
        offer.locationHeading,
        offer.locationSubheading,
      ]).join(', '))
      .join(' & ')
  , [offer.bundledOffers])

  const image = offer.images?.length > 0 ? offer.images[0] : null

  return (
    <Main>
      <ImageWithReservedSpace
        {...imageParameters}
        id={image?.id}
        alt={name}
        loading={eagerLoadFirstImage ? 'eager' : 'lazy'}
        onLoad={onImageLoad}
      >
        <ProductTypeLabelContainer>
          <ProductTypeLabel productType={offer.productType} />
        </ProductTypeLabelContainer>
        {offer.badge && <StyledOfferBadge badge={offer.badge} />}
      </ImageWithReservedSpace>
      <Content>
        <LeftContent>
          <VerticalSpacer gap={4}>
            <StyledBodyTextBlock variant="medium" startIcon={<LineMapMarkerIcon size="XS" />}>
              <OfferTileLocationSection location={locationCaption} mapSearchUrl="" />
            </StyledBodyTextBlock>
            <BundleOfferLocation offer={offer} />
            <BodyText variant="large" as="h4">{name}</BodyText>
          </VerticalSpacer>
        </LeftContent>
        <RightContent>
          <SoldOutMessage offer={offer} filters={filters} />
        </RightContent>
      </Content>
    </Main>
  )
}

SearchBundleOfferTileCondensed.defaultProps = {
  filters: {},
}

export default SearchBundleOfferTileCondensed
