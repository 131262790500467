import cn, { clsx } from 'clsx'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import SearchLocationInput from 'components/Search/SearchForm/SearchLocationInput/SearchLocationInput'
import {
  CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL,
  CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_SEARCH_TYPES,
  CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL,
  CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL,
} from 'constants/carHire'
import CarHireDatePicker from './CarHireDatePickerDropdown'
import CarHireDateTimeField from './CarHireDateTimeField'
import { SearchMenuStates } from 'components/Search/type'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import { pushWithRegion } from 'actions/NavigationActions'
import { ANYWHERE_SEARCH_ITEM, SEARCH_VERTICALS } from 'constants/search'
import { ISO_DATE_FORMAT, SHORT_DAY_NAME_DAY_MONTH, SHORT_DAY_NAME_DAY_MONTH_YEAR } from 'constants/dateFormats'
import qs from 'qs'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import BodyText from 'components/Luxkit/Typography/BodyText'
import moment from 'moment'
import { pluralizeToString } from 'lib/string/pluralize'
import Caption from 'components/Luxkit/Typography/Caption'
import CarHireSearchDriversAgeCategorySelect from './CarHireSearchDriversAgeCategorySelect'
import CarHireDriversAgeInput from './CarHireDriversAgeInput'
import { findCarHireListKey, getCarHireListKey, getCarHireLocationId, getCarHireSearchType } from '../carHireUtils'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import * as Analytics from 'analytics/analytics'
import config from 'constants/config'
import BusinessTravellerSelectCarHireDesktop from 'businessTraveller/components/select-traveller/BusinessTravellerSelectCarHireDesktop'
import BusinessTravellerAccountGuard from 'businessTraveller/components/BusinessTravellerAccountGuard'
import BusinessTravellerSelectLoadingSkeleton from 'businessTraveller/components/select-traveller/BusinessTravellerSelectLoadingSkeleton'
import BusinessTravellerSelectCarHireDisabled from 'businessTraveller/components/select-traveller/BusinessTravellerSelectCarHireDisabled'

const ControlsContainer = styled.div`
  display: grid;
  position: relative;
  gap: ${rem(12)};

  grid-template:
    "location location location"
    "pick-up drop-off actions" auto / 1fr 1fr ${rem(112)};

  &.businessTraveller {
    grid-template:
      "location location location location"
      "pick-up pick-up drop-off drop-off"
      "traveller traveller traveller actions";
  }

  ${mediaQueryUp.desktop} {
    grid-template: "location pick-up drop-off actions" auto / 1fr ${rem(260)} ${rem(260)} ${rem(112)};

    &.businessTraveller {
      grid-template: "location pick-up drop-off traveller actions" auto / 1fr ${rem(260)} ${rem(260)} ${rem(154)} ${rem(112)};
    }
  }

  > .location-input {
    grid-area: location;
  }
  > .pick-up-input {
    grid-area: pick-up;
  }
  > .drop-off-input {
    grid-area: drop-off;
  }
  > .traveller-input {
    grid-area: traveller;
  }
`

// Dodgy override as this input needed to fit within the existing line height
// but there's no luxkit compatable component
const DriversAgeInput = styled(CarHireDriversAgeInput)`
  width: ${rem(58)};
  height: ${rem(32)};

  > input {
    padding: ${rem(6)} ${rem(16)};
  }
`

const DriversAgeContainer = styled(Group)`
  opacity: 1;
  transition: opacity 0.2s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
`

const SearchButton = styled(TextButton)`
  height: 100%;
`

interface Props {
  className?: string;
  /**
   * Will attempt to submit the form and search when a user has finished entering the information
   * without the "search" button being required to be clicked
   */
  optimisticSubmits?: boolean;
  offerLists: Record<string, App.CarHireList>;
}

function CarHireSearchBar(props: Props) {
  const { optimisticSubmits, className, offerLists } = props
  const {
    searchItem,
    secondarySearchItem,
    checkinDate: pickUpDate,
    checkoutDate: dropOffDate,
    pickUpTime,
    dropOffTime,
    driversAgeCategory,
    driversAge,
    eventAnalytics,
  } = useContext(GlobalSearchStateContext)

  const formRef = useRef<HTMLFormElement>(null)
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const dispatch = useAppDispatch()

  const [differentLocations, setDifferentLocations] = useState<boolean>(secondarySearchItem && searchItem?.format.mainText !== secondarySearchItem?.format.mainText)

  const listKey = useMemo(() => {
    const dropOffItem = differentLocations ? secondarySearchItem : searchItem
    const filters = {
      driversAgeCategory,
      dropOffDate: dropOffDate?.format(ISO_DATE_FORMAT),
      dropOffTime,
      pickUpDate: pickUpDate?.format(ISO_DATE_FORMAT),
      pickUpTime,
      pickUpLocationId: searchItem && getCarHireLocationId(searchItem),
      dropOffLocationId: dropOffItem && getCarHireLocationId(dropOffItem),
      ...((driversAgeCategory === 1 || driversAgeCategory === 3) && {
        driversAge,
      }),
    }

    return getCarHireListKey(filters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const duration = useMemo(() => findCarHireListKey(offerLists, listKey)?.vehicles[0]?.duration, [listKey, offerLists])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: SearchMenuStates.Closed })

    const pickUpItem = searchItem ?? ANYWHERE_SEARCH_ITEM
    const dropOffItem = differentLocations ? secondarySearchItem : searchItem

    const query = qs.stringify({
      pickUpName: pickUpItem.format.mainText,
      dropOffName: dropOffItem.format.mainText,
      pickUpId: getCarHireLocationId(pickUpItem),
      dropOffId: getCarHireLocationId(dropOffItem),
      pickUpDate: pickUpDate.format(ISO_DATE_FORMAT),
      pickUpTime,
      dropOffDate: dropOffDate.format(ISO_DATE_FORMAT),
      dropOffTime,
      ageCategory: driversAgeCategory,
      age: (driversAgeCategory === 1 || driversAgeCategory === 3) ? driversAge : undefined,
      isPickUpAtAirport: pickUpItem.searchType === 'airport',
      pickUpSearchType: getCarHireSearchType(pickUpItem),
      dropOffSearchType: getCarHireSearchType(dropOffItem),
    })

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem: pickUpItem,
          secondarySearchItem: dropOffItem,
          searchVerticals: new Set([SEARCH_VERTICALS.CARHIRE]),
          pickUpTime,
          dropOffTime,
          driversAgeCategory,
          checkinDate: pickUpDate,
          checkoutDate: dropOffDate,
        },
        ),
      ))
    }

    dispatch(pushWithRegion('/search/car-hire', query))
  }

  const trySubmit = useCallback(() => {
    if (optimisticSubmits && formRef.current?.checkValidity()) {
      formRef.current.requestSubmit()
    }
  }, [optimisticSubmits])

  const setPickUpTimeDispatch = useCallback((time: string) => {
    if (formRef)
    { searchDispatch({ type: GlobalSearchStateActions.SET_PICKUP_TIME, pickupTime: time }) }
  }, [searchDispatch])

  const setReturnTimeDispatch = useCallback((time: string) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_RETURN_TIME, dropOffTime: time })
  }, [searchDispatch])

  const onSelectDriversAgeCategory = useCallback((nextCategory: App.CarHireDriverAgeCategory) => {
    searchDispatch({ type: GlobalSearchStateActions.SET_DRIVERS_AGE_CATEGORY, driversAgeCategory: nextCategory })
  }, [searchDispatch])

  const needsActualAge = driversAgeCategory === 1 || driversAgeCategory === 3

  const controlsContainerRef = useRef<HTMLDivElement>(null)
  const pickUpFieldRef = useRef<HTMLDivElement>(null)
  const dropOffFieldRef = useRef<HTMLDivElement>(null)

  const dateFieldsRefs = useMemo(() => [pickUpFieldRef, dropOffFieldRef], [])

  const isBusinessTraveller = config.businessTraveller.currentAccountMode === 'business'

  return (
    <VerticalSpacer gap={20} as="form" ref={formRef} onSubmit={onSubmit} className={className}>
      <ControlsContainer ref={controlsContainerRef} className={clsx({ businessTraveller: isBusinessTraveller })}>
        <Group direction="horizontal" gap={12} className="location-input">
          <SearchLocationInput
            label={differentLocations ? CAR_HIRE_PICKUP_LOCATION_SEARCH_TITLE_LABEL : CAR_HIRE_PICKUP_DROPOFF_LOCATION_SEARCH_TITLE_LABEL}
            placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
            searchTypes={CAR_HIRE_SEARCH_TYPES}
            variant="dropdown"
            dropdownSize={differentLocations ? 'M' : 'fill-anchor'}
            searchVertical="car_hire"
            required
          />
          {differentLocations && <SearchLocationInput
            label={CAR_HIRE_DROPOFF_LOCATION_SEARCH_TITLE_LABEL}
            placeholder={CAR_HIRE_PICKUP_LOCATION_SEARCH_PLACEHOLDER_LABEL}
            searchTypes={CAR_HIRE_SEARCH_TYPES}
            isSecondaryLocation
            variant="dropdown"
            dropdownSize="M"
            searchVertical="car_hire"
            required
          />}
        </Group>

        <CarHireDateTimeField
          className="pick-up-input"
          ref={pickUpFieldRef}
          fieldSearchMenu={SearchMenuStates.CarHirePickUpTime}
          date={pickUpDate}
          time={pickUpTime}
          emptyTimeLabel="Start Time"
          inputLabel="Pick-up date"
          dateLabel="Start date"
          setTime={setPickUpTimeDispatch}
        />

        <CarHireDateTimeField
          className="drop-off-input"
          ref={dropOffFieldRef}
          fieldSearchMenu={SearchMenuStates.CarHireReturnTime}
          date={dropOffDate}
          time={dropOffTime}
          emptyTimeLabel="End Time"
          inputLabel="Drop-off date"
          dateLabel="End date"
          setTime={setReturnTimeDispatch}
        />

        {isBusinessTraveller && <BusinessTravellerAccountGuard
          accountMode="business"
          employeeRole="BUSINESS_ADMIN"
          loading={<BusinessTravellerSelectLoadingSkeleton />}
          fallback={<BusinessTravellerSelectCarHireDisabled className="traveller-input" />}
        >
          <BusinessTravellerSelectCarHireDesktop className="traveller-input" />
        </BusinessTravellerAccountGuard>}

        <SearchButton kind="primary" type="submit" size="large">Search</SearchButton>

        <CarHireDatePicker
          anchorRef={controlsContainerRef}
          triggerRef={dateFieldsRefs}
          onApply={trySubmit}
        />
      </ControlsContainer>
      <Group direction="horizontal" horizontalAlign="space-between" gap={16} verticalAlign="center">
        <Group direction="horizontal" verticalAlign="center" gap={24}>
          <CheckboxInput
            defaultChecked={differentLocations}
            onChange={(e) => setDifferentLocations(e.currentTarget.checked)}
          >
            Drop-off to a different location
          </CheckboxInput>
          <CarHireSearchDriversAgeCategorySelect
            value={driversAgeCategory}
            onChange={onSelectDriversAgeCategory}
          />
          <DriversAgeContainer
            className={cn({ hidden: !needsActualAge })}
            forwardedAs="label"
            direction="horizontal"
            verticalAlign="center"
            gap={8}
          >
            <Caption variant="large">
              How old is the driver?
            </Caption>
            <DriversAgeInput />
          </DriversAgeContainer>
        </Group>
        {pickUpDate && dropOffDate && <BodyText variant="medium" colour="neutral-two">
          <b>{moment(pickUpDate).format(SHORT_DAY_NAME_DAY_MONTH)} → {moment(dropOffDate).format(SHORT_DAY_NAME_DAY_MONTH_YEAR)}</b>{' '}
          {duration && `(${pluralizeToString('day', duration)})`}
        </BodyText>}
      </Group>
    </VerticalSpacer>
  )
}

const mapStateToProps = (state: App.State) => ({
  offerLists: state.carHire.offerLists,
})

export default connect(mapStateToProps)(CarHireSearchBar)
