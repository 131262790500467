// eslint-disable-next-line no-restricted-imports
import { themeLayoutCSSVariables } from 'constants/themes/themeLayout'
import React, { createContext, PropsWithChildren } from 'react'

export type ElementPosition = 'static' | 'sticky' | 'fixed' | 'hidden'

interface ObservedHeight {
  relative: number
  floating: number
}

export type AppObservedHeader =
  | 'primary-header'
  | 'tertiary-header'
  | 'contact-options-banner'
  | 'notification-banner'
  | 'banners-stack'
  | 'checkout-header'

const observedHeaderHeights: Record<AppObservedHeader, ObservedHeight> = {
  'primary-header': { relative: 0, floating: 0 },
  'tertiary-header': { relative: 0, floating: 0 },
  'contact-options-banner': { relative: 0, floating: 0 },
  'notification-banner': { relative: 0, floating: 0 },
  'banners-stack': { relative: 0, floating: 0 },
  'checkout-header': { relative: 0, floating: 0 },
}

export type AppObservedFooter =
  | 'primary-footer'

const observedFooterHeights: Record<AppObservedFooter, ObservedHeight> = {
  'primary-footer': { relative: 0, floating: 0 },
}

function syncHeights(
  observedHeights: Record<string, ObservedHeight>,
  observedElement: string,
  rect: DOMRect | undefined,
  position: ElementPosition,
): [relativeOffset: number, floatingOffset: number] {
  if (position === 'hidden' || !rect) {
    observedHeights[observedElement].relative = 0
    observedHeights[observedElement].floating = 0
  } else {
    switch (position) {
      case 'static':
        observedHeights[observedElement].relative = rect.height
        observedHeights[observedElement].floating = 0
        break
      case 'sticky':
        observedHeights[observedElement].relative = rect.height
        observedHeights[observedElement].floating = rect.height
        break
      case 'fixed':
        observedHeights[observedElement].relative = 0
        observedHeights[observedElement].floating = rect.height
    }
  }
  const [relativeOffset, floatingOffset] = Object.values(observedHeights).reduce(([currRelative, currFloating], height) => {
    return [currRelative + height.relative, currFloating + height.floating]
  }, [0, 0])

  return [relativeOffset, floatingOffset]
}

function appLayoutHandler(
  layoutSection: 'header',
  observedElement: AppObservedHeader,
  rect: DOMRect | undefined,
  position: ElementPosition,
): void
function appLayoutHandler(
  layoutSection: 'footer',
  observedElement: AppObservedFooter,
  rect: DOMRect | undefined,
  position: ElementPosition,
): void
function appLayoutHandler(
  layoutSection: 'header' | 'footer',
  observedElement: AppObservedHeader | AppObservedFooter,
  rect: DOMRect | undefined,
  /** @default static */
  position: ElementPosition = 'static',
): void {
  switch (layoutSection) {
    case 'header': {
      const [relativeOffset, floatingOffset] = syncHeights(observedHeaderHeights, observedElement, rect, position)
      document.documentElement.style.setProperty(themeLayoutCSSVariables.headerFloatingOffset, `${floatingOffset}px`)
      document.documentElement.style.setProperty(themeLayoutCSSVariables.headerRelativeOffset, `${relativeOffset}px`)
      break
    }
    case 'footer': {
      const [relativeOffset, floatingOffset] = syncHeights(observedFooterHeights, observedElement, rect, position)
      document.documentElement.style.setProperty(themeLayoutCSSVariables.footerFloatingOffset, `${floatingOffset}px`)
      document.documentElement.style.setProperty(themeLayoutCSSVariables.footerRelativeOffset, `${relativeOffset}px`)
      break
    }
  }
}

const AppLayoutContext = createContext<typeof appLayoutHandler>(appLayoutHandler)

export function AppLayoutContextProvider(props: PropsWithChildren) {
  return <AppLayoutContext.Provider {...props} value={appLayoutHandler} />
}

export default AppLayoutContext
