export function getBoundsFromMarkers(
  markers: Array<App.MapMarker>,
) {
  const bounds = new google.maps.LatLngBounds()

  markers.forEach(p => {
    bounds.extend(new google.maps.LatLng(p.latitude, p.longitude))
  })

  return bounds
}

export function fitMarkersOnMap(
  map: google.maps.Map,
  markers: Array<App.MapMarker>,
  padding?: number,
)
{
  if (markers.length === 0) {
    // whatchu doing passing us no markers to fit!?
    return
  }

  if (markers.length === 1) {
    map.setCenter(new google.maps.LatLng(markers[0].latitude, markers[0].longitude))
    map.setZoom(15)
    return
  }

  const bounds = getBoundsFromMarkers(markers)
  map.fitBounds(bounds, padding)
}

export const calculateCellPositionAndDimensions = (event) => {
  const button = event.currentTarget
  const rect = button.getBoundingClientRect()
  const centerX = rect.left + rect.width / 2
  const centerY = rect.top + rect.height / 2
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const row = Math.floor(centerY / (viewportHeight / 3))
  const column = Math.floor(centerX / (viewportWidth / 3))
  return {
    row,
    column,
    width: rect.width,
    height: rect.height,
  }
}
