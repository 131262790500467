import { countBy } from 'lib/array/arrayUtils'

/**
 * Parses a form and returns an array of key/value pairs
 * from the elements with values in the form
 * The key being the 'name' of the element and value being it's value
 * @param form HTMLFormData The form to parse
 */
function inputElementsToEntries(elements: HTMLFormControlsCollection | Array<HTMLElement>) {
  const formValues: Array<[string, string | Array<string>, HTMLElement]> = []
  for (let i = 0; i < elements.length; i++) {
    const elm = elements[i] as HTMLInputElement
    const isInvalid = !elm.name || elm.disabled || elm.type === 'submit' || elm.type === 'button' ||
      elm.type === 'file' || elm.type === 'fieldset'

    if (isInvalid) {
      continue
    }

    if (elm.type === 'select-one') {
      const select = elm as unknown as HTMLSelectElement
      for (let o = 0; o < select.options.length; o++) {
        const option = select.options[o]
        if (!option.disabled && option.selected) {
          formValues.push([String(elm.name), String(option.value), elm])
        }
      }
      continue
    }

    if (elm.type === 'select-multiple') {
      const select = elm as unknown as HTMLSelectElement
      const selectedValues: Array<string> = []

      for (let o = 0; o < select.options.length; o++) {
        const option = select.options[o]
        if (!option.disabled && option.selected) {
          selectedValues.push(String(option.value))
        }
      }
      formValues.push([String(elm.name), selectedValues, elm])
      continue
    }

    let value
    if (elm.type === 'checkbox' || elm.type === 'radio') {
      if (elm.checked) {
        value = elm.value
      } else {
        continue
      }
    } else if (elm.type === 'textarea') {
      // normalize the line endings between each new line
      value = elm.value.replace(/\r\n/g, '\n').replace(/\n/g, '\r\n')
    } else {
      value = elm.value
    }

    formValues.push([String(elm.name), String(value), elm])
  }

  return formValues
}

function parseValue(value: any, type: string) {
  switch (type) {
    case 'number':
      return parseInt(value, 10)
    case 'float':
      return parseFloat(value)
    case 'boolean': {
      // explicitly checking because I don't want it to return
      // 'false' for anything else, e.g. if it's empty. It's empty, not false.
      if (value === 'true') {
        return true
      } else if (value === 'false') {
        return false
      }
      break
    }
    case 'string':
      return value
  }
  return value
}

function elementEntriesToObject<T>(entries: Array<[string, any, HTMLElement]>): T {
  const obj = {} as T
  const allNames = countBy(entries.map(entry => entry[0].split('::')[0]), val => val)

  for (const entry of entries) {
    const [key, value] = entry

    // supports the form foo.bar.xyz::type
    const [realKey, type = 'string'] = key.split('::')
    const attributes = realKey.split('.')
    attributes.reduce((formData, attribute, index) => {
      const isBottom = index === attributes.length - 1

      if (attribute.endsWith(']')) {
        // it's an array prop!
        const [attributeName, indexStr] = attribute.split('[')
        const arrayIndex = indexStr.slice(0, indexStr.length - 1)
        formData[attributeName] = formData[attributeName] || []
        if (isBottom) {
          // if it's the bottom attribute, then we are assigning the value
          const parsedValue = parseValue(value, type)

          const realKeyValue = allNames.get(realKey)
          if (realKeyValue !== undefined && realKeyValue > 1) {
            // multiple inputs with same name, must want an array
            if (!Array.isArray(formData[attributeName][arrayIndex])) {
              formData[attributeName][arrayIndex] = []
            }
            formData[attributeName][arrayIndex].push(parsedValue)
          } else {
            // only one input with this name, single value
            formData[attributeName][arrayIndex] = parsedValue
          }
        } else {
          formData[attributeName][arrayIndex] = formData[attributeName][arrayIndex] || {}
          return formData[attributeName][arrayIndex]
        }
      } else if (isBottom) {
        // if it's the bottom attribute, then we are assigning the value
        const parsedValue = parseValue(value, type)
        const realKeyValue = allNames.get(realKey)
        if (realKeyValue !== undefined && realKeyValue > 1) {
          // multiple inputs with same name, must want an array
          if (!Array.isArray(formData[attribute])) {
            formData[attribute] = []
          }
          formData[attribute].push(parsedValue)
        } else {
          // only one input with this name, single value
          formData[attribute] = parsedValue
        }
        return formData[attribute]
      } else {
        formData[attribute] = formData[attribute] || {}
        return formData[attribute]
      }
    }, obj)
  }

  return obj
}

/**
* Transforms a form into an object describing the data from the fields in it
* Parses names that have [] as arrays with index specified e.g. foo[1].bar
* Also recognises dot notation as sub-objects (e.g. foo.bar.xyz)
* This also supports forcing parsing of types by adding a ":<type>" to the
* value. e.g. foo.bar.xyz:number
* @param form HTMLFormElement data object
*/
export function formToObject<T>(form: HTMLFormElement): T {
  const elementEntries = inputElementsToEntries(form.elements)
  return elementEntriesToObject<T>(elementEntries)
}

export function elementsToObject<T>(elements: Array<HTMLElement>) {
  const elementEntries = inputElementsToEntries(elements)
  return elementEntriesToObject<T>(elementEntries)
}
