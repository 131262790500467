import { useAppSelector } from 'hooks/reduxHooks'
import { isInsuranceAndRefundProtectionEnabled } from 'luxPlus/selectors/featureToggle'

function useEnabledLuxPlusBenefitsBenefits(benefits?: Array<App.OfferSubscriptionBenefitItem | App.MemberSubscriptionBenefit>) {
  const areInsuranceAndBookingProtectEnabled = useAppSelector(isInsuranceAndRefundProtectionEnabled)
  if (!benefits) return []
  return areInsuranceAndBookingProtectEnabled ? benefits : benefits.filter(benefit => benefit.type !== 'discounted_insurance')
}

export default useEnabledLuxPlusBenefitsBenefits
