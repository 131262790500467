import PillLoadingBox from 'components/Luxkit/PillLoadingBox'
import ModalContext from 'contexts/ModalContext'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import BusinessTravellerHotelCreditsOverBudgetInfoModal from './BusinessTravellerOfferHotelCreditsOverBudgetInfoModal'
import useBusinessTravellerOfferCreditFetcher from 'businessTraveller/hooks/useBusinessTravellerOfferCreditFetcher'
import PriceRowBusinessTravellerCredit from 'components/Luxkit/PricePoints/BusinessTraveller/PriceRowBusinessTravellerCredit'
import PriceRowBusinessTravellerExceededBudget from 'components/Luxkit/PricePoints/BusinessTraveller/PriceRowBusinessTravellerExceededBudget'
import BusinessTravellerOfferCreditsInfoModal from './BusinessTravellerOfferCreditsInfoModal'

type SupportedOffer =
  App.Offer |
  App.OfferSummary |
  App.BedbankOffer |
  App.BedbankOfferSummary

type Props = {
  numberOfNights: number
  offer: SupportedOffer
  totalCost: number
  type: 'exact' | 'estimate'
}

function BusinessTravellerOfferCreditsTextLink(props: Props) {
  const {
    offer,
    totalCost,
    numberOfNights,
    type,
  } = props
  const showModal = useContext(ModalContext)

  const { offerCreditData } = useBusinessTravellerOfferCreditFetcher({ offer, numberOfNights, cost: totalCost })

  const showCreditInfoModal = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()

    showModal(<BusinessTravellerOfferCreditsInfoModal />)
  }, [showModal])

  const showOverBudgetInfoModal = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()

    showModal(<BusinessTravellerHotelCreditsOverBudgetInfoModal numberOfNights={numberOfNights} />)
  }, [numberOfNights, showModal])

  return <>
    {(!offerCreditData || offerCreditData.status === 'pending') && <PillLoadingBox width={160}>Loading</PillLoadingBox>}

    {offerCreditData?.status === 'success' && offerCreditData?.budgetCompliance === 'under_budget' && <PriceRowBusinessTravellerCredit
      size="M"
      type={type}
      creditValue={offerCreditData.creditValue}
      onClick={showCreditInfoModal}
    />}

    {offerCreditData?.status === 'success' && offerCreditData?.budgetCompliance === 'over_budget' && <PriceRowBusinessTravellerExceededBudget
      exceedingPercentage={offerCreditData.budgetComparison}
      onClick={showOverBudgetInfoModal}
    />}
  </>
}

export default BusinessTravellerOfferCreditsTextLink
