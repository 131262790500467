import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import loadable from '@loadable/component'
import config from 'constants/config'
import { ResponsiveLogoProps } from './logos/ResponsiveLuxuryEscapesLogo'

const logos = {
  cudo: loadable(() => import(/* webpackChunkName: "CudoGetawaysLogo" */ './logos/CudoGetawaysLogo')),
  cudotravel: loadable(() => import(/* webpackChunkName: "CudoTravellogo" */ './logos/CudoTravelLogo')),
  ouffer: loadable(() => import(/* webpackChunkName: "Oufferlogo" */ './logos/OufferGetawaysLogo')),
  deals: loadable(() => import(/* webpackChunkName: "Dealslogo" */ './logos/DealsGetawaysLogo')),
  dealstravel: loadable(() => import(/* webpackChunkName: "DealsTravellogo" */ './logos/DealsTravelLogo')),
  livingsocial: loadable(() => import(/* webpackChunkName: "LivingSociallogo" */ './logos/LivingsocialGetawaysLogo')),
  treatme: loadable(() => import(/* webpackChunkName: "TreatMelogo" */ './logos/TreatmeLogo')),
  treatmetravel: loadable(() => import(/* webpackChunkName: "TreatMeTravellogo" */ './logos/TreatmeTravelLogo')),
  luxuryescapes: loadable(() => import(/* webpackChunkName: "ResponsiveLuxuryEscapesLogo" */ './logos/ResponsiveLuxuryEscapesLogo')),
  scoopon: loadable(() => import(/* webpackChunkName: "Scooponlogo" */ './logos/ScooponLogo')),
  lebusinesstraveller: loadable(() => import(/* webpackChunkName: "ResponsiveLeBusinessTravellerLogo" */ './logos/ResponsiveLeBusinessTravellerLogo')),
  leagenthub: loadable(() => import(/* webpackChunkName: "LeAgentHubLogo" */ './logos/LeAgentHubLogo')),
}

const LogoComponent = styled.div<{height: number, width: number}>`
  max-height: ${({ height }) => rem(height)};
  max-width: ${({ width }) => rem(width)};
`

interface Props extends ResponsiveLogoProps {
  className?: string;
  fill?: string;
}

function LogoIcon(props: Props) {
  const {
    className,
    fill,
    mobileVariant,
    tabletVariant,
    desktopVariant,
  } = props

  const Logo = logos[config.logo.icon]

  return (
    <LogoComponent
      className={className}
      as={Logo}
      aria-hidden="true"
      height={config.logo.iconHeight || null}
      width={config.logo.iconWidth || null}
      fill={fill || config.logo.fillColor}
      // Why the conditional spread?
      // Because only ResponsiveLuxuryEscapesLogo understands it.
      {...(config.BRAND === 'luxuryescapes' || config.BRAND === 'lebusinesstraveller' ? { mobileVariant, tabletVariant, desktopVariant } : {})}
    />
  )
}

export default React.memo(LogoIcon)
