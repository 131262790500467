import switchFunc from 'lib/function/switchFunc'

export const EXPERIENCE_PRODUCT_TYPE_ALWAYS_ON = 'always_on'
export const EXPERIENCE_PRODUCT_TYPE_FLASH = 'flash'
export const EXPERIENCE_PRODUCT_TYPE_CHANNEL_MANAGER = 'channel_manager'
export const EXPERIENCE_PRODUCT_TYPE_TRANSFER = 'transfer'
export const EXPERIENCE_LOCATION_SEARCH_TITLE_LABEL = 'Where'
export const EXPERIENCE_LOCATION_SEARCH_PLACEHOLDER_LABEL = 'Search by destination or experience'
export const EXPERIENCE_PRODUCT_TYPES: Array<App.ExperienceProductType> = [EXPERIENCE_PRODUCT_TYPE_ALWAYS_ON, EXPERIENCE_PRODUCT_TYPE_FLASH, EXPERIENCE_PRODUCT_TYPE_CHANNEL_MANAGER]
export const EXPERIENCE_MINIMUM_DISCOUNT_PERCENTAGE_TO_SHOW = 10
export const EXPERIENCE_GIFT_SCHEDULE_DATE_LIMIT_BEFORE_EXPIRATION_DATE = 14
export const EXPERIENCE_ANY_DATE = 'any'
export const EXPERIENCE_TRANSFER_ID = 35

export const EXPERIENCE_WL_DESTINATIONS: Array<App.Place> = [
  { id: 'le_15fa44474c92da27cf2125373356db4c', name: 'Melbourne' },
  { id: 'le_678c61ac5b4f91ca86e112fc50e63766', name: 'Sydney' },
  { id: 'le_b96d145f6c31e35755f4a6841574ba37', name: 'Brisbane' },
  { id: 'le_bac732346917b0a1231354ef55faf00a', name: 'Gold Coast' },
  { id: 'le_f4c2c73d7fe5eb38fd83d32a61604247', name: 'Adelaide' },
  { id: 'le_7c27cf9f8fe09724bb754e474610eb0e', name: 'Perth' },
  { id: 'le_8f85517967795eeef66c225f7883bdcb', name: 'Thailand' },
  { id: 'le_64930b7151b6bc3e289f37d5da62ac9f', name: 'Bali' },
  { id: 'le_d41d8cd98f00b204e9800998ecf8427e', name: 'Anywhere' },
]
export const EXPERIENCE_LE_DESTINATIONS: Array<App.Place> = [
  { id: 'le_15fa44474c92da27cf2125373356db4c', name: 'Melbourne' },
  { id: 'le_9fc3d7152ba9336a670e36d0ed79bc43', name: 'New Zealand' },
  { id: 'le_72b32a1f754ba1c09b3695e0cb6cde7f', name: 'Fiji' },
  { id: 'le_678c61ac5b4f91ca86e112fc50e63766', name: 'Sydney' },
  { id: 'le_64930b7151b6bc3e289f37d5da62ac9f', name: 'Bali' },
  { id: 'le_8f85517967795eeef66c225f7883bdcb', name: 'Thailand' },
  { id: 'le_bac732346917b0a1231354ef55faf00a', name: 'Gold Coast' },
  { id: 'le_d41d8cd98f00b204e9800998ecf8427e', name: 'Anywhere' },
]

export const destinationsMap = switchFunc({
  luxuryescapes: EXPERIENCE_LE_DESTINATIONS,
  treatmetravel: [
    { id: 'le_464adf7b911508701f4c4e70eab19a6e', name: 'Auckland' },
    { id: 'le_1e15efc3fac4ba31daa6f91082c97e97', name: 'Christchurch' },
    { id: 'le_5a4773830c5a9191a6bd6c301d060ad2', name: 'Queenstown' },
    { id: 'le_9fc3d7152ba9336a670e36d0ed79bc43', name: 'New Zealand' },
    { id: 'le_678c61ac5b4f91ca86e112fc50e63766', name: 'Sydney' },
    { id: 'le_15fa44474c92da27cf2125373356db4c', name: 'Melbourne' },
    { id: 'le_b96d145f6c31e35755f4a6841574ba37', name: 'Brisbane' },
    { id: 'le_d41d8cd98f00b204e9800998ecf8427e', name: 'Anywhere' },
  ],
}
, EXPERIENCE_WL_DESTINATIONS)

export const EXPERIENCE_SEARCH_TYPES: Array<App.SearchType> = ['destination', 'experience']

export const EXPERIENCE_TYPEAHEAD_SEARCH_TYPES: Array<App.SearchPlaceType> = [
  'province_state',
  'neighborhood',
  'city',
  'multi_city_vicinity',
  'high_level_region',
  'country',
  'continent',
  'colloquial_area',
  'le_experience',
  'channel_experience',
]

export const AnythingCategory: App.ExperienceItemCategory = {
  id: 999,
  imageId: '',
  level: 'parent',
  name: 'Anything',
}

export enum ExperienceBookingType {
  BUY_NOW_BOOK_LATER = 'buy_now_book_later',
  INSTANT_BOOKING = 'instant_booking'
}

export const EXP_CATEGORY_AIRPORT_TRANSFER_ID = 36
export const EXP_CATEGORY_AIRPORT_LOUNGE_ID = 46

export const EXPERIENCE_IN_FLOW_MAX_SHOWN = 10

export const EmptyExperienceList: Exclude<App.ExperienceListState[string], undefined> = {
  ids: [],
  fetching: true,
}

export const PROVIDERS = {
  led: 'led',
  rez: 'rezdy',
  dby: 'derbysoft',
}
