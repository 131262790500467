import { definitions, Promotion } from '@luxuryescapes/contract-svc-promo'
import config from 'constants/config'

import {
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_CARHIRE,
  CHECKOUT_ITEM_TYPE_GIFT_CARD,
} from 'constants/checkout'
import { isMemberOrHasSubscriptionInTheCart } from 'checkout/selectors/view/luxPlusSubscription'
import { partitionBy, sum } from 'lib/array/arrayUtils'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2, OFFER_TYPE_CRUISE } from 'constants/offer'
import { breakdownView, getAllItemViews } from 'checkout/selectors/view/generic'
import { reportClientError } from 'services/errorReportingService'
import { isAndroidAppUserAgent, isIOSAppUserAgent } from 'lib/web/deviceUtils'
import { getWhiteLabelAppConfig } from 'lib/whitelabels/whitelabels'
import { getExperienceProductIdFromProvider, getExperienceProviderFromOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { getTourV2ItemViewPrice } from 'checkout/lib/utils/tours/priceView'
import { getCarHireSelectedRateOption } from 'checkout/lib/utils/carHire/view'

type ReferralAllowedTypes = definitions['Config']['globalPromoConfig']['referralAllowedTypes'][number];

export type ReferralOfferTypes = ReferralAllowedTypes | 'DISALLOWED_UNMAPPED' | 'DISALLOWED_UNKNOWN';

export type PromoToggleKeys = definitions['PromoToggles']['key']

export function itemTypeToReferralOfferType(itemType: string):ReferralOfferTypes {
  switch (itemType) {
    case CHECKOUT_ITEM_TYPE_LE_HOTEL:
    case CHECKOUT_ITEM_TYPE_EXPERIENCE:
    case CHECKOUT_ITEM_TYPE_FLIGHT:
      return itemType
    case CHECKOUT_ITEM_TYPE_BEDBANK:
      return 'bedbank_hotel'
    case CHECKOUT_ITEM_TYPE_TOUR_V1:
      return 'tour'
    case CHECKOUT_ITEM_TYPE_TOUR_V2:
      return 'tour_v2'
    case CHECKOUT_ITEM_TYPE_CRUISE:
      return 'cruises'
    case CHECKOUT_ITEM_TYPE_TRANSFER:
    case CHECKOUT_ITEM_TYPE_CARHIRE:
    case CHECKOUT_ITEM_TYPE_CAR_HIRE:
    case CHECKOUT_ITEM_TYPE_INSURANCE:
    case CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE:
    case CHECKOUT_ITEM_TYPE_GIFT_CARD:
      return 'DISALLOWED_UNMAPPED'
    default:
      console.error(`(referral) Could not find map: "${itemType}"`)
      return 'DISALLOWED_UNKNOWN'
  }
}

type filterItemDiscountsProps = {
  itemDiscounts: Array<App.ItemDiscount> | undefined,
  iIds: Array<{
    offerId?: string,
    itemId?: string,
  }>
}

export function filterItemDiscounts({ itemDiscounts, iIds }: filterItemDiscountsProps): Array<App.ItemDiscount> {
  if (!itemDiscounts || iIds.length === 0) {
    return []
  }

  return itemDiscounts.filter(id =>
    iIds.some(({ offerId, itemId }) =>
      (offerId && offerId === id.offerId) ||
      (itemId && itemId === id.itemId),
    ),
  )
}

const getPromoDeviceType = (state: App.State):definitions['Discount Request Order']['deviceType'] => {
  const wlApp = getWhiteLabelAppConfig()
  const browserName = state.config.rawUserAgentString

  if (wlApp.isIOS || isIOSAppUserAgent(browserName)) {
    return 'ios'
  }

  if (wlApp.isAndroid || isAndroidAppUserAgent(browserName)) {
    return 'android'
  }

  return 'web'
}

/**
 * We're currently sourcing most of these totals from the PriceBreakdownView, but order item totals from getAllItemViews are the preferred source of total data as it is not inter-related with logic used to display the Summary Breakdown in checkout (i.e. let breakdownView just focus on presenting the totals, allow independent item total calculations for the promo)
 * We are still testing the totals will apply the in the same way as the checkout summary breakdown (via preCheckoutOrder, see marketing/promo-request page in admin (?dev=true mode) for more details)
 */
export function getItemsSpecificDetails(bv: App.Checkout.PriceBreakdownView, state: App.State, reportErrors: boolean): Array<definitions['Discount Request Item']> {
  /** The preferred source of total data: */
  const {
    subscriptionItemView,
    luxPlusSubscriptionItemView,
  } = getAllItemViews(state).data

  return bv.items.map((item):definitions['Discount Request Item'] => {
    const { memberPrice = 0, price = 0, taxesAndFees = 0 } = item
    const itemPrice = price + taxesAndFees
    const canApplyMembership = isMemberOrHasSubscriptionInTheCart(state)
    const itemMemberPrice = (canApplyMembership && memberPrice > 0) ? memberPrice + taxesAndFees : undefined

    switch (item.itemType) {
      case 'accommodation':
      case 'villa':
        return {
          itemId: item.itemId,
          categoryBK: 'hotel',
          discountableTotal: itemPrice,
          luxPlusPrice: itemMemberPrice,
          offerId: item.offerId,
          reservationType: item.reservationType,
          travellers: state.checkout.form.travellerForms.map((traveller) => ({
            firstName: traveller.firstName,
            lastName: traveller.lastName,
          })),
        }
      case 'flight':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'flight',
          discountableTotal: itemPrice,
          travellers: [],
        }
      case 'experience':
      case 'transfer':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'experience',
          discountableTotal: itemPrice,
        }
      // QQQ: could consider addons as something here?
      case 'tour':
      case 'tourV2':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'tour',
          discountableTotal: itemPrice,
        }
      case 'insurance':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'insurance',
          discountableTotal: itemPrice,
        }
        /* NB: Car Hire (unlike others) does not populate breakdownView.items,
       This matching logic remains as it is the preferred method, but (outside of being filtered out), it is currently unused
        */
      case 'car_hire':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'car_hire',
          discountableTotal: itemPrice,
        }
      case 'cruise':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'cruise',
          discountableTotal: itemPrice,
        }
      case 'lux-plus-subscription':

        const subscriptionItemSum = sum(subscriptionItemView.data, (i) => i.price) + sum(luxPlusSubscriptionItemView.data, (i) => i.price)

        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'subscription',
          discountableTotal: subscriptionItemSum,
        }
      default:
        if (reportErrors) {
          reportClientError(new Error(`promoMap:getItemsSpecificDetails (BV) could not map promo item type - ${item.itemType}`))
        }

        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'hotel',
          discountableTotal: itemPrice,
        }
    }
  })
}

interface BreakdownViewItem {
  price?: number
  total?: number
  memberTotal?: number
  memberPrice?: number
  surcharge?: number
  taxesAndFees?: number
  otherFees?: App.Checkout.OtherFees
}

const sumViewItemsTotal = (items:Array<BreakdownViewItem>):number => sum(items, (i) => sumViewItemTotal(i))

const sumViewItemTotal = (item:BreakdownViewItem):number => (item.price ?? 0) + (item.surcharge ?? 0) + (item.otherFees && 'extraGuestSurcharge' in item.otherFees ? item.otherFees?.extraGuestSurcharge ?? 0 : 0)

const sumViewItemsMemberTotal = (items:Array<BreakdownViewItem>):number => sum(items, (bi) => sumViewItemMemberTotal(bi))

const sumViewItemMemberTotal = (item:BreakdownViewItem):number => (item.memberPrice ?? 0) + (item.surcharge ?? 0) + (item.otherFees && 'extraGuestSurcharge' in item.otherFees ? item.otherFees?.extraGuestSurcharge ?? 0 : 0)

const getNumberOfNights = (itemView: App.Checkout.LEAccommodationItemView | App.Checkout.BedbankAccommodationItemView | App.Checkout.TourV2AccommodationItemView | App.Checkout.VillaItemView): number | undefined => {
  if (itemView.kind == 'villa' || itemView.kind == 'bedbank' || itemView.kind == 'tourV2') {
    return itemView.item.duration
  }

  if (itemView.pkg?.duration) {
    return itemView.pkg.duration
  }

  if ('duration' in itemView) {
    return itemView.duration as number
  }
  return undefined
}

type CountryCodeValidationSupportedType = App.Checkout.LEAccommodationOfferView | App.Checkout.TourV2AccommodationOfferView;

const getItemCountryCode = (item: CountryCodeValidationSupportedType): string | undefined => {
  // Some limited support for single country tour_v2 item destination validation
  if (item.offerType === 'tour_v2') {
    const itinerary = item.itemViews?.[0]?.variation?.itinerary
    if (itinerary && itinerary.length > 0 && 'locationsVisitedDetails' in itinerary[0]) {
      return itinerary[0].locationsVisitedDetails?.[0]?.countryCode
    }
    return undefined
  }

  return item.offer?.property?.geoData?.countryCode ?? undefined
}

const getNumberOfOccupants = (item: App.Checkout.AccommodationItemView | App.Checkout.TourV2AccommodationItemView | App.Checkout.BedbankAccommodationItemView | App.Checkout.VillaItemView): {
  numberOfAdults: number | undefined
  numberOfChildren: number | undefined
} => {
  const itemToCheck = item.kind == 'villa' ? item.item : item

  return {
    numberOfAdults: itemToCheck.occupancy?.adults,
    numberOfChildren: (itemToCheck.occupancy?.children ?? 0) + (itemToCheck.occupancy?.infants ?? 0),
  }
}
/**
 *
 * @param prefix a prefix added to the info string
 * @param item the item to build the info string from
 * @returns a string of item identifiers and values
 * Please don't use this directly for business logic, (it only supported on client request and designed to inform changes to the categoryBK/subCategoryBK mapping)
 */
const buildItemInfoString = (prefix: string, item: object, fullDetails = true):string => {
  const includedIds = ['price', 'total', 'memberTotal', 'memberPrice', 'surcharge', 'taxesAndFees', 'offerType', 'designation', 'itemType', 'parentType', 'reservationType', 'price', 'memberTotal', 'total', 'provider', 'kind']
  if (fullDetails) {
    includedIds.concat(['itemId', 'offerId'])
  }
  const includedKeys = Object.keys(item).filter((key) => includedIds.includes(key))
  const log = includedKeys.map((key) => `${key}:${item[key]}`).join(' ')
  return `${prefix} ${log}`
}

const getDefaultTravellers = (state: App.State):definitions['Discount Request Item']['travellers'] => state.checkout.form.travellerForms.map((traveller) => ({
  firstName: traveller?.firstName?.length > 0 ? traveller.firstName : 'Unknown',
  lastName: traveller?.lastName?.length > 0 ? traveller.lastName : 'Unknown',
}))
type DiscountRequestItem = definitions['Discount Request Item']

type PromoCategory = {
  categoryBK: definitions['Discount Request Item']['categoryBK']
  subCategoryBK?: definitions['Discount Request Item']['subCategoryBK']
}

const getPromoItemCategoriesFromItemType = (itemType: string):PromoCategory => {
  switch (itemType) {
    case 'hotel':
      return { categoryBK: 'hotel', subCategoryBK: 'hotel-hotel' }
    case 'villa':
      return { categoryBK: 'hotel', subCategoryBK: 'hotel-rental' }
    case 'tourV1':
      return { categoryBK: 'tour' }
    case 'bedbankHotel':
      return { categoryBK: 'hotel', subCategoryBK: 'hotel-bedbank_hotel' }
    case 'tourV2experience':
    case 'tourV2Experience':
      return { categoryBK: 'experience' }
    case 'tourV2':
      // QQQ: add cruise "HolidayType" check here?
      return { categoryBK: 'tour' }
    case 'cruise':
      return { categoryBK: 'cruise' }
    default:
      reportClientError(new Error(`promoMap:getPromoItemCategoriesFromItemType - Failed to map item via ${itemType}`))
      return { categoryBK: 'hotel' }
  }
}

const getExperienceItemSubCategory = (offerIdWithPrefix: string):definitions['Discount Request Item']['subCategoryBK'] => {
  const provider = getExperienceProviderFromOffer(offerIdWithPrefix)
  const productId = getExperienceProductIdFromProvider(provider)

  switch (productId) {
    case 'led_exp':{
      return 'experience-led_exp'
    }
    case 'mus_exp': {
      return 'experience-mus_exp'
    }
    case 'rez_exp': {
      return 'experience-rez_exp'
    }
  }
}

/**
 * (A simplified replacement for getPromoItems - generates 'Discount Request Item' (as opposed to 'Discount Request Item V2'))
 *
 * Please bump 'clientOrderVersion' when making changes to the logic or structure of the Discount Request Order/Items
 */
export const getPromoItems = (state: App.State, clientOrderVersion: number): Array<definitions['Discount Request Item']> => {
  const resultItems: Array<DiscountRequestItem> = []

  // Before we start using getPromoItems() - these errors are non-critical and can be safely ignored
  try {
    const {
      accommodationItemsView,
      experienceItemsView,
      tourV2ExperienceItemsView,
      carHireItemsView,
      villaItemsView,
      transferItemsView,
      insuranceItemsView,
      subscriptionItemView,
      luxPlusSubscriptionItemView,
      flightItemsView,
    } = getAllItemViews(state).data
    const isMemberOfHasSubItem = isMemberOrHasSubscriptionInTheCart(state)

    const checkoutAccOfferView = checkoutAccommodationOfferView(state)

    const [cruiseItems, accommodationItems] = partitionBy(accommodationItemsView.data || [], (item) => !!(item.offerType == OFFER_TYPE_TOUR && item.offer && 'holidayTypes' in item.offer && item.offer?.holidayTypes?.includes('Cruises')))

    for (const _ of cruiseItems || []) {
      const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'cruise'
      resultItems.push(...cruiseItems.map((item) => ({
        categoryBK,
        discountableTotal: sumViewItemsTotal(item.itemViews),
        luxPlusPrice: isMemberOfHasSubItem ? sumViewItemsMemberTotal(item.itemViews) : 0,
        numberOfNights: item.duration,
        numberOfAdults: item.occupancy.map((occupancy) => occupancy.adults).reduce((a, b) => a + b, 0),
        numberOfChildren: item.occupancy.map((occupancy) => (occupancy.children ?? 0) + (occupancy.infants ?? 0)).reduce((a, b) => a + b, 0),
        reservationType: item.reservationType,
        itemInfoString: buildItemInfoString('cruise-items-v1', item.itemViews),
        offerId: item.offerId,
        travellers: [],
      })))
    }

    for (const item of accommodationItems || []) {
      if (item.offerType == OFFER_TYPE_BED_BANK) {
        const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'hotel'
        const subCategoryBK: definitions['Discount Request Item']['subCategoryBK'] = 'hotel-bedbank_hotel'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          subCategoryBK,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          itemCountryCode: item.locationCountryCode,
          numberOfNights: getNumberOfNights(iv),
          reservationType: item.reservationType,
          offerId: item.offerId,
          itemInfoString: buildItemInfoString('bedbank-items-v1', item),
          travellers: getDefaultTravellers(state),
          ...getNumberOfOccupants(iv),
        })))
      } else if (item.offerType == OFFER_TYPE_HOTEL) {
        const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'hotel'
        const subCategoryBK: definitions['Discount Request Item']['subCategoryBK'] = 'hotel-hotel'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          subCategoryBK,
          itemId: iv.item.itemId,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          numberOfNights: getNumberOfNights(iv),
          itemCountryCode: getItemCountryCode(item),
          reservationType: item.reservationType,
          offerId: item.offerId,
          itemInfoString: buildItemInfoString('hotel-v1', item),
          travellers: getDefaultTravellers(state),
          ...getNumberOfOccupants(iv),
        })))
      } else if (item.offerType == OFFER_TYPE_ALWAYS_ON) {
        const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'hotel'
        const subCategoryBK: definitions['Discount Request Item']['subCategoryBK'] = 'hotel-tactical_ao_hotel'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          subCategoryBK,
          itemId: iv.item.itemId,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          numberOfNights: getNumberOfNights(iv),
          itemCountryCode: getItemCountryCode(item),
          reservationType: item.reservationType,
          offerId: item.offerId,
          itemInfoString: buildItemInfoString('always-on-v1', item),
          travellers: getDefaultTravellers(state),
          ...getNumberOfOccupants(iv),
        })))
      } else if (item.offerType == OFFER_TYPE_TOUR_V2) {
        const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'tour'
        item.itemViews.forEach((iv) => {
          const tourV2Offer = checkoutAccOfferView.data[0].offer as Tours.TourV2Offer
          const { totalPrice, totalMemberPrice } = getTourV2ItemViewPrice(tourV2Offer, iv.item.purchasableOption, iv.occupancy)

          resultItems.push({
            categoryBK,
            discountableTotal: totalPrice,
            luxPlusPrice: isMemberOfHasSubItem ? totalMemberPrice : 0,
            itemCountryCode: getItemCountryCode(item),
            numberOfNights: getNumberOfNights(iv),
            reservationType: item.reservationType,
            itemInfoString: buildItemInfoString('offerType-tourv2-v4', item),
            offerId: item.offerId,
            ...getNumberOfOccupants(iv),
          })
        })
      } else if (item.offerType == OFFER_TYPE_CRUISE) {
        const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'cruise'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          numberOfNights: item.duration,
          reservationType: item.reservationType,
          itemInfoString: buildItemInfoString('itemType-cruise', item),
          offerId: item.offerId,
          ...getNumberOfOccupants(iv),
        })))
      } else if ('itemType' in item && typeof item.itemType == 'string') {
        const category = getPromoItemCategoriesFromItemType(item.itemType)
        switch (item.itemType) {
          case 'hotel': {
            resultItems.push(...item.itemViews.map((iv:App.Checkout.AccommodationItemView) => ({
              ...category,
              discountableTotal: sumViewItemTotal(iv),
              luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
              numberOfNights: item.duration,
              itemCountryCode: getItemCountryCode(item),
              reservationType: item.reservationType,
              itemInfoString: buildItemInfoString('itemType-hotel-v1', item),
              offerId: item.offerId,
              ...getNumberOfOccupants(iv),
            })))
            break
          }
          case 'tourV2':
          case 'addons':
          case 'tourV2Experience': {
            resultItems.push(...item.itemViews.map((iv) => ({
              ...category,
              discountableTotal: sumViewItemTotal(iv),
              luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
              numberOfNights: getNumberOfNights(iv),
              itemCountryCode: getItemCountryCode(item),
              reservationType: item.reservationType,
              itemInfoString: buildItemInfoString('itemType-tourV2-addons-tourv2-experiences-v1', item),
              offerId: item.offerId,
              ...getNumberOfOccupants(iv),
            })))
            break
          }
          case 'cruise':{
            resultItems.push(...item.itemViews.map((iv) => ({
              ...category,
              discountableTotal: sumViewItemTotal(iv),
              luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
              numberOfNights: getNumberOfNights(iv),
              itemCountryCode: getItemCountryCode(item),
              reservationType: item.reservationType,
              itemInfoString: buildItemInfoString('itemType-cruise', item),
              offerId: item.offerId,
              ...getNumberOfOccupants(iv),
            })))
            break
          }
          default:
            reportClientError(new Error(`promoMap:getPromoItems: Could not map itemType or offerType:${item.itemType} ${item.offerType} ${clientOrderVersion} ${buildItemInfoString('fallthrough-1', item, false)}`))
        }
      } else {
        reportClientError(new Error(`promoMap:getPromoItems: Could not map itemType or offerType ${clientOrderVersion} ${item.offerType} ${buildItemInfoString('fallthrough-2', item, false)}`))
      }
    }

    if (villaItemsView.data.length > 0) {
      const category = getPromoItemCategoriesFromItemType('villa')
      resultItems.push(...villaItemsView.data.map((iv) => ({
        ...category,
        discountableTotal: sumViewItemTotal(iv),
        luxPlusPrice: isMemberOfHasSubItem ? sum(villaItemsView.data, (i) => i.memberPrice) : 0,
        numberOfNights: getNumberOfNights(iv),
        itemId: villaItemsView.data[0].item.itemId,
        offerId: villaItemsView.data[0].offer.id,
        travellers: getDefaultTravellers(state),
        itemInfoString: buildItemInfoString('itemArray-villa-items', iv),
        ...getNumberOfOccupants(iv),
      })))
    }

    if (experienceItemsView.data.length > 0) {
      const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'experience'
      resultItems.push(...experienceItemsView.data.map((iv) => {
        const reservationType: definitions['Discount Request Item']['reservationType'] = iv.isBuyNowBookLater ? 'buy_now_book_later' : 'instant_booking'

        return {
          categoryBK,
          subCategoryBK: getExperienceItemSubCategory(iv.experienceId),
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          offerId: iv.experienceId,
          itemId: iv.itemId,
          reservationType,
          itemInfoString: buildItemInfoString('itemArray-experience-items', iv),
        }
      }))
    }

    if (tourV2ExperienceItemsView.data.length > 0) {
      resultItems.push(...tourV2ExperienceItemsView.data.map((iv) => {
        const category = getPromoItemCategoriesFromItemType(iv.item.itemType)
        return {
          ...category,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          offerId: iv.item.offerId,
          itemInfoString: buildItemInfoString('itemArray-tourv2-v1', iv),
          itemId: iv.item.itemId,
        }
      }))
    }

    if (carHireItemsView.data.length > 0) {
      const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'car_hire'
      resultItems.push(...carHireItemsView.data.map((vi) => ({
        categoryBK,
        discountableTotal: getCarHireSelectedRateOption(vi.item).payNowAmount,
        luxPlusPrice: 0,
        offerId: vi.item.offerId,
        itemInfoString: buildItemInfoString('itemArray-car-hire-v1', vi.item),
        itemId: vi.item.itemId,
      })))
    }

    if (subscriptionItemView.data.length > 0) {
      const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'subscription'
      const joiningSubCategoryBK: definitions['Discount Request Item']['subCategoryBK'] = 'subscription-joining_fee'
      resultItems.push(...subscriptionItemView.data.map((siv) => ({
        categoryBK,
        subCategoryBK: joiningSubCategoryBK,
        discountableTotal: sumViewItemTotal(siv),
        luxPlusPrice: 0,
        itemId: siv.itemId,
        itemInfoString: buildItemInfoString('itemArray-subscriptionItemView-v1', siv),
        travellers: getDefaultTravellers(state),
      })))
    }

    if (luxPlusSubscriptionItemView.data.length > 0) {
      const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'subscription'
      const reoccurringFeeSubCategoryBK: definitions['Discount Request Item']['subCategoryBK'] = 'subscription-recurring_fee'
      resultItems.push(...luxPlusSubscriptionItemView.data.map((siv) => ({
        categoryBK,
        subCategoryBK: reoccurringFeeSubCategoryBK,
        discountableTotal: sumViewItemTotal(siv),
        luxPlusPrice: 0,
        itemId: siv.itemId,
        itemInfoString: buildItemInfoString('itemArray-luxPlusSubscriptionItemView-v1', siv),
        travellers: getDefaultTravellers(state),
      })))
    }

    if (flightItemsView.data.length > 0) {
      const categoryBK: definitions['Discount Request Item']['categoryBK'] = 'flight'
      resultItems.push(...flightItemsView.data.map((view) => ({
        categoryBK,
        discountableTotal: view.price,
        luxPlusPrice: isMemberOfHasSubItem ? view.item.memberTotal : 0,
        itemId: view.item.itemId,
        itemInfoString: buildItemInfoString('itemArray-flights-v1', view),
        travellers: getDefaultTravellers(state),
      })))
    }

    if (transferItemsView.data.length > 0) {
      resultItems.push({
        categoryBK: 'transfer',
        discountableTotal: sum(transferItemsView.data, (i) => i.transfer.option?.price ?? 0),
        luxPlusPrice: isMemberOfHasSubItem ? sum(transferItemsView.data, (i) => i.transfer.option?.memberPrice ?? 0) : 0,
        itemId: transferItemsView.data[0].itemId,
        itemInfoString: buildItemInfoString('itemArray-transfer-v1', transferItemsView.data),
        travellers: getDefaultTravellers(state),
      })
    }

    if (insuranceItemsView.data.length > 0) {
      resultItems.push({
        categoryBK: 'insurance',
        discountableTotal: sum(insuranceItemsView.data, (i) => i.price),
        itemId: insuranceItemsView.data[0].itemId,
        itemInfoString: buildItemInfoString('itemArray-insurance-v1', transferItemsView.data),
        travellers: getDefaultTravellers(state),
      })
    }
  } catch (err) {
    reportClientError(err)
  }

  return resultItems
}

/**
 * @deprecated - This is the (very soon) becoming the default method of determining the composition of the cart (i.e. via getAllItemsView)
 * It is just here if we need to fallback to the old priceBreakdownView based method
 */
export const applyPromoWithV2ItemTotals = (state: App.State):boolean => {
  return config.PROMO_CHECKOUT_V2_ITEM_TOTALS_COUNTRY_CODES.includes('ALL') ||
      config.PROMO_CHECKOUT_V2_ITEM_TOTALS_COUNTRY_CODES.includes(state.geo.currentRegionCode) ||
      false
}

export const stateToDiscountOrder = (state: App.State): definitions['Discount Request Order'] => {
  // Please bump the clientOrderVersion when making changes to the structure of the Discount Request Order
  const clientOrderVersion = 16
  return {
    brand: config.BRAND as definitions['Promo Brands'],
    region: state.geo.currentRegionCode as definitions['Promo Regions'],
    deviceType: getPromoDeviceType(state),
    hasBedbankPromotion: false,
    isGiftOrder: state.checkout.cart.isGift ?? false,
    items: getPromoItems(state, clientOrderVersion),
    clientOrderVersion,
  }
}

/**
 * @@deprecated - see getPromoItems()
 * (when PROMO_CHECKOUT_V2_ITEM_TOTALS_COUNTRY_CODES include ALL or the current country, we will attempt the new order (and if no products found fallback to the old method - we'll use stateToDiscountOrder directly after this move see getPromoItems)
 *
 *
 * The goal of checkoutStateToDiscountOrder is to provide item level information
 * for promo calculators for all item types in a way that is consistent with the placed order
 * Next steps: we'd like to move away from the getPromoCodeProductType() mapping
 * towards making this determination, in svc-promo via the offerId (avoiding this mapping)
 *
 *
 * @param state
 * @param breakdownView
 * @returns
 */
export function checkoutStateToDiscountOrder(
  state: App.State,
  reportErrors: boolean,
): definitions['Discount Request Order'] {
  const bv = breakdownView(state).data

  const items = bv.flatMap((bv) => {
    // Prefer the more specific price or ids on the item
    return getItemsSpecificDetails(bv, state, reportErrors)
  }).filter((item) => {
    // if car-hire items are populated in the breakdownView, exclude them as we are patching them in below
    return item.categoryBK !== 'car_hire'
  })

  // CruiseV1 doesn't populate the breakdown view 'items' array, so we patch from the offer view
  const accommodationItems = checkoutAccommodationOfferView(state)
  const cruiseV1Items: Array<definitions['Discount Request Item']> =
  accommodationItems.data?.filter((item) =>
    item.offer?.type === OFFER_TYPE_TOUR &&
    item.offer?.holidayTypes?.includes('Cruises'))
    .map((item) => {
      const cruiseV1Items = item.itemViews.filter((view) => view.item && view.item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1)
      return {
        categoryBK: 'hotel',
        discountableTotal: sum(cruiseV1Items, (i) => i.price),
        offerId: item.offerId,
      }
    })

  // Car hire doesn't populate the breakdown view 'items' array, so we patch from the checkout state items
  // (As a safeguard for if/when they are 'items' are added to the car-hire breakdown view, we filter them out above)
  const carHireItems = state.checkout.cart.items.filter((item) => item.itemType === 'car-hire').map((item:App.Checkout.CarHireItem):definitions['Discount Request Item'] => {
    const selectedRateOption = getCarHireSelectedRateOption(item)
    return {
      itemId: item.itemId,
      categoryBK: 'car_hire',
      discountableTotal: selectedRateOption.payNowAmount,
      offerId: item.offerId,
    }
  })

  const results: definitions['Discount Request Order'] = {
    region: state.geo.currentRegionCode as definitions['Promo Regions'],
    brand: config.BRAND as definitions['Promo Brands'],
    isGiftOrder: state.checkout.cart.isGift ?? false,
    items: items.concat(carHireItems, cruiseV1Items),
    hasBedbankPromotion: false,
    deviceType: getPromoDeviceType(state),
    clientOrderVersion: 1,
  }

  return results
}

/**
 * @@deprecated - see promotionMapV2
 */
export function promotionMap(promo: Promotion.Promotion): App.Promotion {
  return ({
    id: promo.id_promo_code,
    code: promo.code_name,
    discount: promo.promo_value,
    type: promo.promo_type,
    warning: promo.warning || '',
    maxDiscount: promo.max_discount || undefined,
    expiresAt: promo.expires_at,
    items: [],
    currency: promo.currency,
    minSpend: promo.min_spend || undefined,
    allowedPaymentMethods: promo.allowed_payment_methods,
    allowedAirlineCarriers: promo.allowed_airline_carriers,
    isCorporate: promo.is_corporate,
    isFriendsAndFamily: promo.promo_category === 'friends_and_family',
    hasBinNumbers: promo.has_bin_numbers,
    hideDiscountPercentage: false,
    promoToggles: promo.promo_toggles,
    popupMessage: promo.popup_message,
  })
}

export function itemPromotionMap(item: definitions['Discount Response']['promo']['items'][0]):App.ItemDiscount {
  return ({
    offerId: item.offerId,
    itemId: item.itemId,
    categoryBK: item.categoryBK,
    productBK: item.productBK?.toString(),
    discountAmount: item.discountAmount,
    discountType: item.discountType,
    discountableTotal: item.discountableTotal,
    maxDiscount: item.maxDiscount,
    discountValue: item.discountValue,
    hideDiscountPercentage: item.discountHasCombinedWithLuxPlusPricing,
  })
}

export function promotionMapV2(promoV2: definitions['Discount Response']['promo']): App.Promotion {
  const items = promoV2.items.map((i) => itemPromotionMap(i))
  return ({
    id: promoV2.id_promo_code,
    code: promoV2.code_name,
    warning: promoV2.warning,
    discount: promoV2.discount,
    allowedPaymentMethods: promoV2.allowed_payment_methods ?? [],
    currency: promoV2.currency,
    maxDiscount: promoV2.max_discount,
    discountTotal: promoV2.discount_total,
    items,
    expiresAt: promoV2.expires_at,
    minSpend: promoV2.min_spend,
    type: promoV2.type,
    promoToggles: promoV2.promo_toggles,
    corporateName: promoV2.corporate_name,
    isCorporate: promoV2.is_corporate,
    isFriendsAndFamily: promoV2.promo_category === 'friends_and_family',
    hasBinNumbers: promoV2.has_bin_numbers,
    hideDiscountPercentage: items.some((i) => i.hideDiscountPercentage),
    popupMessage: promoV2.popup_message,
  })
}
