import createSelector from 'lib/web/createSelector'
import { EmptyArray } from 'lib/array/arrayUtils'

export const getIsFetchingFeaturedDestinations = (state: App.State) => state.destination.fetchingFeaturedDestinations

export const getIsFetchingTrendingDestinations = (state: App.State) => state.destination.fetchingTrendingDestinations

export const getHotelsTrendingDestinations = createSelector(
  (state: App.State) => state.destination.trendingDestinations,
  (trendingDestinations): Array<App.SearchDestination> => trendingDestinations.map(destination => {
    return {
      searchType: 'destination',
      value: destination.placeId,
      format: { mainText: destination.primaryText },
      imageId: destination.imageId,
    }
  }),
)

export const getToursPopularDestinations = (state: App.State): Array<App.SearchDestination> => state.destination.tours?.popularDestinations ?? EmptyArray
export const getIsFetchingToursPopularDestinations = (state: App.State) => state.destination.tours?.isFetchingPopularDestinations
