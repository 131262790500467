import { baseThemeShadows } from './shadows'
import whiteLabelBaseTheme, { whiteLabelBaseBorderRadii, whiteLabelBaseDefaultPalette } from './whiteLabelBase'

export const cudoTravelDefaultPalette: App.Palette = {
  ...whiteLabelBaseDefaultPalette,
}

export const cudoTravelShadows: App.ThemeShadows = { ...baseThemeShadows }
export const cudoTravelBorderRadii: App.ThemeBorderRadii = { ...whiteLabelBaseBorderRadii }

const cudoTravelTheme: App.Theme = {
  ...whiteLabelBaseTheme,
}

export default cudoTravelTheme
