import { AppAction } from 'actions/ActionTypes'
import { BUSINESS_TRAVELLER_ACTIONS } from 'reducers/businessTravellerActionReducer'

function setSelectedBusinessTravellerEmployeeIds(employeeIds: Array<string> | null): AppAction {
  return (dispatch) => {
    dispatch({
      type: BUSINESS_TRAVELLER_ACTIONS.SET_BUSINESS_TRAVELLER,
      employeeIds: employeeIds ?? [],
    })
  }
}

export default setSelectedBusinessTravellerEmployeeIds
