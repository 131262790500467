import React, { useContext } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Group from '../../utils/Group'
import moment from 'moment'
import { calculateNights } from '../../../../tripPlanner/utils'
import TextButton from '../../Luxkit/Button/TextButton'
import BodyText from '../../Luxkit/Typography/BodyText'
import SearchTileEventsContext, { SearchTileEvents } from '../../OfferList/OfferListTiles/SearchOfferTiles/SearchTileEventsContext'
import { useInView } from 'react-intersection-observer'

const StyledGroup = styled(Group)`
  background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  color: ${props => props.theme.palette.highlight.primary.lightContrast};
  padding: ${rem(12)} ${rem(16)};
  gap: ${rem(8)};
`

interface Props {
  checkIn: string;
  checkOut: string;
  className?: string
}

function SearchAltDatesBanner({ checkIn, checkOut, className }: Props) {
  const searchTileEventsDispatch = useContext(SearchTileEventsContext)
  const nights = calculateNights(moment(checkIn), moment(checkOut))

  const [inViewRef, hasBeenInView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  if (hasBeenInView) {
    searchTileEventsDispatch({
      type: SearchTileEvents.viewAlternativeDatesBanner,
    })
  }

  const handleClick = () => {
    searchTileEventsDispatch({
      type: SearchTileEvents.clickAlternativeDateViewOffer,
    })
  }

  return (
    <StyledGroup ref={inViewRef} verticalAlign="center" className={className} direction="horizontal" horizontalAlign="space-between" onClick={handleClick}>
      <BodyText variant="medium">Stay for at least <strong>{nights} nights</strong> to unlock a limited time deal on this hotel.</BodyText>
      <TextButton kind="secondary">View offer</TextButton>
    </StyledGroup>
  )
}

export default SearchAltDatesBanner
