import { useAppSelector } from 'hooks/reduxHooks'
import { useMemo } from 'react'

interface Props {
  productType: App.ProductType;
  offerId?: string;
  offerLocations?: Array<string>;
  vendorName?: string;
}

interface OfferCommissionParams {
  productType: App.ProductType;
  agent: string;
  agency: string;
  offer: string;
  location: Array<string>;
  vendor: string;
  affiliation: string;
}

function checkIfOfferMatchCommissionConditions(commissionRule: App.AgentHubCommissionRule, offerData: OfferCommissionParams) {
  if (Object.keys(commissionRule.conditions).length === 0) {
    return false
  }

  const matchAllConditions = Object.keys(commissionRule.conditions)
    .every(condition => {
      if (condition === 'location') {
        return offerData.location?.some(location => commissionRule.conditions[condition]?.includes(location))
      }

      return commissionRule.conditions[condition]?.includes(offerData[condition])
    })

  return matchAllConditions
}

function useAgentHubCommission(props: Props): { commissionAmount: number } {
  const { productType, offerId, offerLocations, vendorName } = props
  const baseRules = useAppSelector(state => state.agentHub.agentCommissions.baseCommissions)
  const additionalRules = useAppSelector(state => state.agentHub.agentCommissions.additionalCommissions)
  const agentAccount = useAppSelector(state => state.agentHub.agentHubAccount.account)

  const offerCommissionParams: OfferCommissionParams = useMemo(() => ({
    productType,
    agent: agentAccount?.agentId || '',
    agency: agentAccount?.agencyId || '',
    offer: offerId || '',
    location: offerLocations?.length ? offerLocations : [''],
    vendor: vendorName || '',
    affiliation: agentAccount?.affiliation || '',
  }), [agentAccount, offerId, offerLocations, vendorName, productType])

  const commissionAmount = useMemo(() => {
    let baseCommission = 0
    let additionalCommission = 0

    // Find the highest base commission that matches all conditions
    baseRules.forEach(commissionRule => {
      if (commissionRule.commissionPercentage < baseCommission) {
        return
      }

      const offerMatchAllConditions = checkIfOfferMatchCommissionConditions(commissionRule, offerCommissionParams)

      if (offerMatchAllConditions) {
        baseCommission = commissionRule.commissionPercentage
      }
    })

    // If base commission is 0, we don't need to check for additional commission
    // because the offer is not eligible for any commission
    if (baseCommission === 0) {
      return 0
    }

    // then add any additional commission that matches all conditions
    additionalRules.forEach(commissionRule => {
      const matchAllConditions = checkIfOfferMatchCommissionConditions(commissionRule, offerCommissionParams)

      if (matchAllConditions) {
        additionalCommission += commissionRule.commissionPercentage
      }
    })

    return baseCommission + additionalCommission
  }, [additionalRules, baseRules, offerCommissionParams])

  return { commissionAmount }
}

export default useAgentHubCommission
