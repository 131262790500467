import { min } from 'lib/array/arrayUtils'
import { useMemo } from 'react'
import moment from 'moment'
import { useAppSelector } from './reduxHooks'
import { selectLoggedIn as isLoggedInSelector } from 'selectors/accountSelectors'

export default function useSearchTileInclusionUpsellPackage(offer: App.Offer | App.OfferSummary, filters?: App.OfferListFilters) {
  const isLoggedIn = useAppSelector(isLoggedInSelector)

  const packageWithBonusInclusions = useMemo(() => {
    const packagesWithBonusInclusionsAndPrice = offer.packages.filter(p => (!!p.bonusInclusions.length) && p.price > 0)
    return min(packagesWithBonusInclusionsAndPrice, (p) => p.duration)
  }, [offer])

  const selectedDuration = useMemo(() => {
    return filters?.checkIn && filters?.checkOut ? moment(filters.checkOut).diff(moment(filters.checkIn), 'days') : undefined
  }, [filters])

  const isAuthorised = isLoggedIn || !offer.walledGarden

  return {
    upsellPackage: ((packageWithBonusInclusions?.duration ?? 0) > 0 &&
      (packageWithBonusInclusions && selectedDuration ? selectedDuration < packageWithBonusInclusions.duration : false) &&
      isAuthorised) ?
      packageWithBonusInclusions :
      undefined,
  }
}
