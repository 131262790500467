import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { connect } from 'react-redux'
import * as Analytics from 'analytics/analytics'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import Group from 'components/utils/Group'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import BodyText from 'components/Luxkit/Typography/BodyText'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import { ModalSteps } from '../defaults'
import TravelPreferencesImageItemsGrid from '../TravelPreferencesImageItemsGrid/TravelPreferencesImageItemsGrid'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { UserPreferenceDestination } from '../types'
import { deleteUserPreferenceDestination, updateUserPreferenceDestination } from 'actions/userTravelPreferencesActions'
import GeoContext from 'contexts/geoContext'
import { DEFAULT_FILTER } from 'home/components/TrendingDestinations/TrendingDestinations'
import { useHotelTrendingDestinations } from 'home/hooks/useHotelTrendingDestinations'
import TextInput from 'components/Common/Form/Input/TextInput'
import SearchPlaceToggleList from '../SearchPlaceToggleList'
import { DisplayItemWithCheckBox } from './SearchModalDisplayItemWithCheckBox'

interface Props {
  selectedDestinations: Array<App.UserPreferenceDestination>,
  onSetNextStep: (step: ModalSteps, isSaveAction?: boolean) => void;
}

function TravelPreferencesModalDestinations(props: Props) {
  const { selectedDestinations, onSetNextStep } = props

  const dispatch = useAppDispatch()
  const userId = useAppSelector(getCurrentUserId)
  const [destinationSearch, setDestinationSearch] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<Array<UserPreferenceDestination>>([])
  const selectedPlaceIds = useMemo(() => selectedDestinations?.map(h => h.placeId) || [], [selectedDestinations])
  const { currentRegionCode } = useContext(GeoContext)

  const [trendingDestinations, _] = useHotelTrendingDestinations(DEFAULT_FILTER, currentRegionCode)
  const defaultDestinations: Array<UserPreferenceDestination> = useMemo(() => (
    trendingDestinations
      .filter(destination => !!destination.destinationId)
      .map((d) => ({
        name: d.name,
        placeId: d.destinationId!,
        imageId: d.imageId,
        isAlertSelected: false,
      }))
  ), [trendingDestinations])

  const selectedDestinationItems = useMemo(() => {
    return selectedDestinations?.map(d => ({
      placeId: d.placeId,
      isAlertSelected: d.isAlertSelected || false,
    }))
  }, [selectedDestinations])

  useEffect(() => {
    if (selectedDestinationItems) {
      setSelectedItems(selectedDestinationItems)
    }
  }, [selectedDestinationItems])

  const selectedId = useMemo(() => new Set(selectedItems.map((i) => i.placeId)), [selectedItems])

  const onToggleSelection = useCallback((destination: UserPreferenceDestination) => {
    setDestinationSearch('')
    const selected = selectedId.has(destination.placeId)

    setSelectedItems(prevSelected => {
      if (selected) {
        return prevSelected.filter(item => item.placeId !== destination.placeId)
      }

      return [...prevSelected, destination]
    })
  }, [selectedId])

  const onSkip = useCallback(() => {
    onSetNextStep(ModalSteps.hotelBrands)
  }, [onSetNextStep])

  const onUpdatePreferences = useCallback(() => {
    Analytics.trackClientEvent({
      subject: 'destinations',
      action: 'completion',
      type: 'interaction',
      category: 'travel_preferences',
    })

    for (const item of selectedItems) {
      if (!selectedPlaceIds.includes(item.placeId)) {
        dispatch(updateUserPreferenceDestination(userId!, item.placeId))
      }
    }

    for (const itemId of selectedPlaceIds) {
      if (!selectedItems.some((i) => i.placeId === itemId)) {
        dispatch(deleteUserPreferenceDestination(userId!, itemId))
      }
    }
    onSetNextStep(ModalSteps.hotelBrands, true)
  }, [dispatch, onSetNextStep, selectedPlaceIds, selectedItems, userId])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'destinations',
      action: 'impression',
      type: 'nonInteraction',
      category: 'travel_preferences',
    })
  }, [])

  return (
    <>
      <ModalBody>
        <ModalContent>
          <Group direction="vertical" gap={16}>
            <BodyText variant="medium">Be the first to know about the best offers in these locations.</BodyText>
            <TextInput
              placeholder="Search destinations"
              endIcon={<LineSearchIcon />}
              value={destinationSearch}
              onChange={(e) => setDestinationSearch(e.target.value)}
              autoFocus
            />
            <Group direction="vertical" gap={12}>
              {!!destinationSearch && [...selectedItems].map((destination) => <DisplayItemWithCheckBox
              key={`displayItemWithCheckBox-${destination.placeId}`}
              destination={destination}
              onClick={onToggleSelection}
            />)}
              <SearchPlaceToggleList
              searchPhrase={destinationSearch}
              selectedPlaces={selectedDestinationItems}
              onSelect={onToggleSelection}
            />
            </Group>
            <TravelPreferencesImageItemsGrid
              items={[...selectedDestinationItems, ...defaultDestinations]}
              onSelect={onToggleSelection}
              selectedId={selectedId}
            />
          </Group>
        </ModalContent>
      </ModalBody>
      <ModalFooter primaryActionProps={{ children: 'Save & continue', onClick: onUpdatePreferences }} secondaryActionProps={{ children: 'Skip', onClick: onSkip }} />
    </>
  )
}

const mapStateToProps = (state: App.State) => ({
  selectedDestinations: state.userTravelPreferences.destinations.data,
})

export default connect(mapStateToProps)(TravelPreferencesModalDestinations)
