import { combineReducers } from 'redux'
import { sha256 } from 'lib/utils/createHashUtils'
import {
  API_CALL_SUCCESS,
  INITIALISE_SEARCH_SESSION_ID,
} from 'actions/actionConstants'
import { USER_LOGIN, USER_LOGOUT } from 'actions/apiActionConstants'
import { createReducer } from 'lib/redux/reducerUtils'

export enum BuyerType {
    new = 'new',
    existing = 'existing'
}

const initialUserState: App.AnalyticsUserState = {
  userId: '',
  hashedEmail: '',
  signUpSource: '',
  attribution: {},
  userRoles: [],
  buyerType: BuyerType.new,
}

const initialSearchState: App.AnalyticsSearchState = {
  sessions: {},
}

function getBuyerType(data: any) {
  return data.number_of_purchases > 0 ? BuyerType.existing : BuyerType.new
}

export function userReducer(state = initialUserState, action): App.AnalyticsUserState {
  switch (action.type) {
    case API_CALL_SUCCESS:
      if (action.api === USER_LOGIN) {
        return {
          ...state,
          userId: action.data.memberId,
          hashedEmail: sha256(action.data.email),
          signUpSource: action.data.signupDomain,
          userRoles: action.data.roles,
          // attribution is always empty now, feature no longer used
          attribution: {},
          buyerType: getBuyerType(action.data),
        }
      } else if (action.api === USER_LOGOUT) {
        return {
          ...initialUserState,
        }
      }
      if (action.api === USER_LOGOUT) {
        return {
          ...initialUserState,
        }
      }
      return state
    default:
      return state
  }
}

const searchReducer = createReducer<App.AnalyticsSearchState>(initialSearchState, {
  [INITIALISE_SEARCH_SESSION_ID]: (state, action) => {
    const { offerListKey, sessionId } = action

    return {
      ...state,
      sessions: {
        ...state.sessions,
        [offerListKey]: sessionId,
      },
    }
  },
})

const analyticsReducer = combineReducers({
  user: userReducer,
  search: searchReducer,
})

export default analyticsReducer
