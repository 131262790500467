import luxuryEscapesTheme, { luxuryEscapesBorderRadii, luxuryEscapesDefaultPalette, luxuryEscapesInversePalette } from './luxuryescapes'
import { baseThemeShadows } from './shadows'

export const leAgenthubDefaultPalette: App.Palette = {
  ...luxuryEscapesDefaultPalette,
}

export const leAgenthubInversePalette: App.Palette = {
  ...luxuryEscapesInversePalette,
}

export const leAgentHubShadows: App.ThemeShadows = { ...baseThemeShadows }
export const leAgentHubBorderRadii: App.ThemeBorderRadii = { ...luxuryEscapesBorderRadii }

const leAgenthubTheme: App.Theme = {
  ...luxuryEscapesTheme,
}

export default leAgenthubTheme
