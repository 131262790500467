import React, { useCallback, useContext } from 'react'

import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { GlobalSearchDispatchContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { SearchMenuStates } from 'components/Search/type'

function CapacityExceededMessage() {
  const searchDispatch = useContext(GlobalSearchDispatchContext)
  const onAddAnotherUnitClick = useCallback((event) => {
    event.preventDefault()
    searchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu: SearchMenuStates.Rooms })
  }, [searchDispatch])

  return <>
    <BodyText variant="large" weight="semi-bold" align="end">Max number of guests exceeded</BodyText>
    <TextLink size="medium" onClick={onAddAnotherUnitClick}>
      Add another room
    </TextLink>
  </>
}

export default CapacityExceededMessage
