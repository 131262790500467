import React, { useState, useRef, useEffect, useImperativeHandle, useMemo } from 'react'
import styled, { css } from 'styled-components'
import cn from 'clsx'
import { getImageUrl } from 'lib/image/imageUtils'
import config from 'constants/config'
import LoadingBox from '../Loading/LoadingBox'
import { useInView } from 'react-intersection-observer'

type SupportedFitPositions = 'bottom' | 'center' | 'left' | 'right' | 'top' | 'none'

const POSITIONS: Array<SupportedFitPositions> = ['bottom', 'center', 'left', 'right', 'top']
const PositionsCSS = css`
  ${POSITIONS.map(position => `
  &.fit-${position} {
    &:before {
      background-position: ${position};
    }

    img {
      object-position: ${position};
    }
  }
`)}
`

const PictureElement = styled.picture`
  position: relative;
  z-index: 0;

  &:not(.fit-none) {
    &.fit {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  ${PositionsCSS}

  &:before {
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--placeholder-image);
    opacity: 0;
    transition: opacity 0s 0.2s;
  }

  &.placeholder {
    &:before {
      transition: none;
      opacity: 1;
    }
  }
`

const ImgElement = styled.img`
  opacity: 1;
  transition: opacity 0.2s;
  position: relative;

  &.hide {
    opacity: 0;
  }
`

const PlaceholderBlurrer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(8px);
`

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  image?: App.Image;
  id?: string;
  aspectRatio?: string;
  className?: string;
  type?: string;
  dpr?: 1 | 2 | 3;
  highRes?: boolean;
  format?: 'jpeg' | 'jpg' | 'png' | 'webp' | 'svg' | 'avif';
  enhancedFormats?: boolean;
  width?: number | string;
  height?: number | string;
  gravity?: 'auto' | 'center' | 'east' | 'north' | 'northeast' | 'northwest' | 'south' | 'southeast' | 'southwest' | 'west' // auto is centre
  quality?: 'best' | 'good' | 'eco' | 'low';
  fit?: SupportedFitPositions;
}

export interface ImageProps extends Props {}

const Image = React.forwardRef<HTMLImageElement, Props>((props: Props, ref) => {
  const {
    id: propsId,
    aspectRatio,
    dpr,
    type,
    highRes,
    format,
    enhancedFormats = true,
    quality,
    gravity,
    alt,
    fit,
    className,
    onLoad,
    image,
    loading = 'eager',
    ...imageProps
  } = props

  // determines whether we think we should start showing the image
  // governed by whether it's lazy loaded and in view
  const [inViewRef, showImage] = useInView({
    rootMargin: '200px 0px 200px 0px',
    threshold: 0.01,
    // we don't need an intersection observer if it's managed by parent
    // or we're eagerly loading it
    initialInView: loading === 'eager',
    skip: loading === 'eager',
    triggerOnce: true,
  })
  const [loaded, setLoaded] = useState(false)
  const imgRef = useRef<HTMLImageElement | null>(null)

  useImperativeHandle(ref, () => imgRef.current!)
  useEffect(() => {
    if (imgRef.current?.complete) {
      setLoaded(true)
    }
    // eslint-disable-next-line
  }, [])

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setLoaded(true)
    onLoad?.(e)
  }

  // allow passing of an ID or of an image object
  const id = image?.id ?? propsId
  // always prefer ID over URL
  const externalUrl = id ? undefined : image?.url

  let imageUrl, webpUrl, avifUrl, placeholderUrl
  if (externalUrl) {
    imageUrl = externalUrl
  } else if (id) {
    imageUrl = getImageUrl(id, {
      width: props.width,
      height: props.height,
      quality,
      aspectRatio,
      type,
      highRes,
      format,
      gravity,
      dpr,
    })

    webpUrl = getImageUrl(id, {
      width: props.width,
      height: props.height,
      quality,
      aspectRatio,
      type,
      highRes,
      format: 'webp',
      gravity,
      dpr,
    })

    avifUrl = getImageUrl(id, {
      width: props.width,
      height: props.height,
      quality,
      aspectRatio,
      type,
      highRes,
      format: 'avif',
      gravity,
      dpr,
    })

    placeholderUrl = getImageUrl(id, {
      width: 64,
      aspectRatio,
      type,
      gravity,
      format: 'webp',
    })
  }

  // by default we always show a placeholder image - this is our 'loader'
  const showPlaceholder = !loaded
  // however, placeholders aren't supported by external images, so show a loader there instead
  const showLoader = !!externalUrl && (!showImage || !loaded)

  const placeholderImage = useMemo(() => ({
    '--placeholder-image': `url(${placeholderUrl})`,
  } as React.CSSProperties), [placeholderUrl])

  return (
    <PictureElement
      ref={inViewRef}
      className={cn(className, {
        fit,
        [`fit-${fit}`]: fit,
        placeholder: showPlaceholder,
      })}
      style={showPlaceholder ? placeholderImage : undefined}
    >
      {!externalUrl && <>
        {config.AVIF_ENABLED && showImage && avifUrl && enhancedFormats &&
          <source
            type="image/avif"
            srcSet={avifUrl}
          />
        }
        {showImage && enhancedFormats && webpUrl &&
          <source
            type="image/webp"
            srcSet={webpUrl}
          />
        }
      </>}
      <ImgElement
        {...imageProps}
        onLoad={onImageLoad}
        loading="eager"
        className={cn(className, { hide: !showImage })}
        ref={imgRef}
        alt={alt ?? image?.title}
        src={showImage ? imageUrl : ''}
        />
      {showPlaceholder && <PlaceholderBlurrer />}
      {showLoader && <LoadingBox floating />}
    </PictureElement>
  )
})

Image.displayName = 'Image'

export default React.memo(Image)
