import CurrencyContext from 'contexts/currencyContext'
import GeoContext from 'contexts/geoContext'
import { CurrencyFormatOptions, formatCurrency } from 'lib/format/formatCurrencyIntl'
import React, { useContext } from 'react'

type Props = {
  value: number;
  currency?: string;
} & CurrencyFormatOptions

function FormatCurrency(props: Props) {
  const { currency, value, ...options } = props
  const geo = useContext(GeoContext)
  const contextCurrency = useContext(CurrencyContext)
  const currencyCode = currency ?? contextCurrency ?? geo.currentCurrency
  const formatted = formatCurrency(value, currencyCode, geo.currentRegionCode, options)

  return <>{formatted}</>
}

export default React.memo(FormatCurrency)
