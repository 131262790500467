import { useAppSelector } from 'hooks/reduxHooks'
import getOfferListKey from 'lib/offer/offerListKey'

function useOfferMetaData(offerId: string, filters?: App.OfferListFilters): App.OfferListMetaData | undefined {
  const offerMetaDataKey = getOfferListKey(filters ?? {})

  return useAppSelector(state => state.offer.searchResultMetadata.offerMetaData[offerMetaDataKey]?.[offerId])
}

export default useOfferMetaData
