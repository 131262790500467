import React, { useMemo } from 'react'
import {
  SEVEN_DAYS_AFTER_PURCHASE,
  CANCELLATION_POLICIES,
  CANCELLATION_POLICY_DAYS,
  PRIOR_TO_CHECK_IN_CANCELLATION_POLICIES,
} from 'constants/cancellationPolicies'
import moment from 'moment'
import BodyText from 'components/Luxkit/Typography/BodyText'
import config from 'constants/config'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'

export function getCancellationPolicyText(
  cancellationPolicyType: string,
  offerType: App.OfferType,
  refundableUntil?: moment.Moment,
): { highlightText: string | undefined; secondaryText: string | undefined } {
  if (offerType == OFFER_TYPE_ALWAYS_ON) {
    if (!refundableUntil) {
      return {
        highlightText: 'Free cancellation',
        secondaryText: '',
      }
    }
    return {
      highlightText: 'Free cancellation',
      secondaryText: `until ${refundableUntil.format('DD MMM')}`,
    }
  }
  if (
    cancellationPolicyType === CANCELLATION_POLICIES.POST_PURCHASE_SEVEN_DAYS
  ) {
    return {
      highlightText: 'Free cancellation',
      secondaryText: 'within 7 days of purchase',
    }
  }
  if (PRIOR_TO_CHECK_IN_CANCELLATION_POLICIES.includes(cancellationPolicyType)) {
    if (!refundableUntil) {
      return {
        highlightText: 'Flexible cancellation',
        secondaryText: 'available (hotel only)',
      }
    }
    return {
      highlightText: 'Flexible cancellation',
      secondaryText: `until ${refundableUntil.format('DD MMM')} (hotel only)`,
    }
  }
  return {
    highlightText: undefined,
    secondaryText: undefined,
  }
}

export function getRefundableUntilAndIsRefundableFromCancellationPolicy(
  cancellationPolicyType: string,
  checkInDate: string | moment.Moment,
  propertyTimezoneOffset: number,
) {
  const days = CANCELLATION_POLICY_DAYS[cancellationPolicyType] || 0

  if (days === 0 || cancellationPolicyType === CANCELLATION_POLICIES.NON_REFUNDABLE) {
    return { refundableUntil: null, isRefundable: false }
  }

  let refundableUntil: moment.Moment | undefined

  const checkInMoment = moment(checkInDate)
  if (propertyTimezoneOffset) {
    checkInMoment.utcOffset(propertyTimezoneOffset)
  }

  const startBasedOnCheckIn = checkInDate ? checkInMoment.subtract(days, 'days') : undefined

  const today = moment()
  if (propertyTimezoneOffset) {
    today.utcOffset(propertyTimezoneOffset)
  }

  const startBasedOnToday = today.add(SEVEN_DAYS_AFTER_PURCHASE, 'days')

  if (cancellationPolicyType === CANCELLATION_POLICIES.POST_PURCHASE_SEVEN_DAYS) {
    refundableUntil = !startBasedOnCheckIn || startBasedOnToday.isBefore(checkInDate) ?
      startBasedOnToday :
      startBasedOnCheckIn
  } else {
    refundableUntil = startBasedOnCheckIn
  }

  const isRefundable = !refundableUntil || refundableUntil.isAfter(moment())

  return { refundableUntil, isRefundable }
}

interface Props {
  cancellationPolicyType: string;
  checkInDate?: string | moment.Moment;
  timezoneOffset?: number;
  offerType: App.OfferType;
}

function SearchTileCancellationPolicy(props: Props) {
  const {
    cancellationPolicyType,
    checkInDate,
    timezoneOffset,
    offerType,
  } = props

  const { highlightText, secondaryText, isRefundable } = useMemo(() => {
    const { refundableUntil, isRefundable } =
      getRefundableUntilAndIsRefundableFromCancellationPolicy(
        cancellationPolicyType,
        checkInDate,
        timezoneOffset,
      )
    return {
      ...getCancellationPolicyText(cancellationPolicyType, offerType, refundableUntil),
      isRefundable,
    }
  }, [cancellationPolicyType, checkInDate, timezoneOffset, offerType])

  if (!isRefundable || !config.ENABLE_SEARCH_TILE_CANCELLATION_POLICY) {
    return null
  }

  return <BodyText variant="medium">
    <BodyText
      variant="medium"
      colour="highlight-secondary"
      weight="bold"
      as="span"
    >
      {highlightText}&nbsp;
    </BodyText>
    {secondaryText}
  </BodyText>
}

export default SearchTileCancellationPolicy
