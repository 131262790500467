export const FETCH_INSURANCE_QUOTES = 'api/FETCH_INSURANCE_QUOTES'
export const FETCH_INSURANCE_UPGRADE_QUOTES = 'api/FETCH_INSURANCE_UPDATE_QUOTES'
export const UPGRADE_INSURANCE = 'api/UPGRADE_INSURANCE'
export const FETCH_CHECKOUT_INSURANCE_QUOTES = 'api/FETCH_CHECKOUT_INSURANCE_QUOTES'
export const OPT_OUT_OF_QUOTE = 'api/OPT_OUT_OF_QUOTE'
export const FETCH_INSURANCE_PRODUCTS = 'api/FETCH_INSURANCE_PRODUCTS'
export const FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID = 'api/FETCH_INSURANCE_PRODUCTS_BY_SUBSCRIPTION_ID'
export const FETCH_TRADER_INFORMATION = 'api/FETCH_TRADER_INFORMATION'
export const FETCH_MAGAZINE_ARTICLES = 'api/FETCH_MAGAZINE_ARTICLES'
export const FETCH_LANDING_PAGE = 'api/FETCH_LANDING_PAGE'
export const FETCH_LINK_BOX = 'api/FETCH_LINK_BOX'
export const BOOK_ADDON = 'api/BOOK_ADDON'
export const FETCH_ADDON_BOOKING_AVAILABILITY = 'api/FETCH_ADDON_BOOKING_AVAILABILITY'
export const FETCH_DAY_CALENDAR = 'api/FETCH_DAY_CALENDAR'
export const FETCH_CALENDARS = 'api/FETCH_CALENDARS'
export const FETCH_CALENDARS_BY_OCCUPANCY = 'api/FETCH_CALENDARS_BY_OCCUPANCY'
export const FETCH_TAX_BREAKDOWN = 'api/FETCH_TAX_BREAKDOWN'
export const FETCH_PACKAGE_PRICES = 'api/FETCH_PACKAGE_PRICES'
export const FETCH_FLIGHT_PRICE = 'api/FETCH_FLIGHT_PRICE'
export const FETCH_FLIGHT_CALENDAR = 'api/FETCH_FLIGHT_CALENDAR'
export const FETCH_PAYMENT_CARDS = 'api/FETCH_PAYMENT_CARDS'
export const FETCH_STRIPE_PAYMENT_CARDS = 'api/FETCH_STRIPE_PAYMENT_CARDS'
export const FETCH_AVAILABLE_CREDIT = 'api/FETCH_AVAILABLE_CREDIT'
export const FETCH_FLIGHTS = 'api/FETCH_FLIGHTS'
export const FETCH_FLIGHT_JOURNEY = 'api/FETCH_FLIGHT_JOURNEY'
export const FETCH_FLIGHT_FARE_FAMILIES = 'api/FETCH_FLIGHT_FARE_FAMILIES'
export const FETCH_GIFT_CARD = 'api/FETCH_GIFT_CARD'
export const CHECK_USER_EXISTS = 'api/CHECK_USER_EXISTS'
export const USER_LOGIN = 'api/USER_LOGIN'
export const USER_LOGOUT = 'api/USER_LOGOUT'
export const USER_REGISTRATION = 'api/USER_REGISTRATION'
export const UPDATE_USER_DETAILS = 'api/UPDATE_USER_DETAILS'
export const FETCH_NOTIFICATION_BANNER = 'api/FETCH_NOTIFICATION_BANNER'
export const FETCH_BANNERS = 'api/FETCH_BANNERS'
export const FETCH_LIST_BANNERS = 'api/FETCH_LIST_BANNERS'
export const ADD_USER_POPULAR_DESTINATIONS = 'api/ADD_USER_POPULAR_DESTINATIONS'
export const FETCH_TRENDING_DESTINATIONS = 'api/FETCH_TRENDING_DESTINATIONS'
export const FETCH_DESTINATION_IMAGE = 'api/FETCH_DESTINATION_IMAGE'
export const FETCH_HOTEL_POPULAR_HOLIDAY_TYPES = 'api/FETCH_HOTEL_POPULAR_HOLIDAY_TYPES'
export const FETCH_POPULAR_FILTERS = 'api/FETCH_POPULAR_FILTERS'
export const FETCH_POPULAR_DESTINATIONS = 'api/FETCH_POPULAR_DESTINATIONS'
export const FETCH_TOP_HOTEL_DESTINATIONS = 'api/FETCH_TOP_HOTEL_DESTINATIONS'
export const UPDATE_ACCOUNT_PARTNERSHIP = 'api/UPDATE_ACCOUNT_PARTNERSHIP'
export const FETCH_BEST_PRICE_FOR_OFFER = 'api/FETCH_BEST_PRICE_FOR_OFFER'
export const FETCH_AVAILABILITY_RATES_FOR_OFFER = 'api/FETCH_AVAILABILITY_RATES_FOR_OFFER'
export const FETCH_PLACE = 'api/FETCH_PLACE'
export const FETCH_PLACE_BY_NAME = 'api/FETCH_PLACE_BY_NAME'
export const FETCH_PLACE_BY_COORDS = 'api/FETCH_PLACE_BY_COORDS'
export const FETCH_PLACE_ANCESTORS = 'api/FETCH_PLACE_ANCESTORS'
export const FETCH_SPECIFC_PLACES = 'api/FETCH_SPECIFC_PLACES'
export const USER_RESET_PASSWORD = 'api/USER_RESET_PASSWORD'
export const USER_FORGOT_PASSWORD = 'api/USER_FORGOT_PASSWORD'
export const SEND_VERIFICATION_CODE = 'api/SEND_VERIFICATION_CODE'
export const LINK_PARTNERSHIP_ACCOUNT = 'api/LINK_PARTNERSHIP_ACCOUNT'
export const GET_FINAL_FLIGHT_FARES = 'api/GET_FINAL_FLIGHT_FARES'
export const FETCH_FINAL_FLIGHT_FARES = 'api/FETCH_FINAL_FLIGHT_FARES'
export const FETCH_FLIGHT_BAGGAGE = 'api/FETCH_FLIGHT_BAGGAGE'
export const FETCH_RELATED_TRAVEL_ITEMS = 'api/FETCH_RELATED_TRAVEL_ITEMS'
export const FETCH_OFFER_LOCATION_BREADCRUMBS = 'api/FETCH_LOCATION_BREADCRUMBS'
export const FETCH_FAQ = 'api/FETCH_FAQ'
export const FETCH_RELATED_TRAVEL_DESTINATIONS = 'api/FETCH_RELATED_TRAVEL_DESTINATIONS'
export const FETCH_SEARCH_FLIGHTS = 'api/FETCH_SEARCH_FLIGHTS'
export const FETCH_ALTERNATIVE_OFFERS = 'api/FETCH_ALTERNATIVE_OFFERS'
export const CREATE_DATES_REQUEST = 'api/CREATE_DATES_REQUEST'
export const UPDATE_DATES_REQUEST = 'api/UPDATE_DATES_REQUEST'
export const FETCH_CUSTOMER_DETAILS = 'api/FETCH_CUSTOMER_DETAILS'
export const UPDATE_CUSTOMER_DETAILS = 'api/UPDATE_CUSTOMER_DETAILS'
export const DELETE_CUSTOMER_DETAILS = 'api/DELETE_CUSTOMER_DETAILS'
export const UPDATE_ADD_PHONE_PROMPTED = 'api/UPDATE_ADD_PHONE_PROMPTED'

// LERE Recommendation actions
export const FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS = 'api/FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS'
export const FETCH_OFFER_BASED_LERE_RECOMMENDATIONS = 'api/FETCH_OFFER_BASED_LERE_RECOMMENDATIONS'
export const FETCH_CALENDAR_OFFER_RECOMMENDATIONS = 'api/FETCH_CALENDAR_OFFER_RECOMMENDATIONS'
export const FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME = 'api/FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME'
export const FETCH_LERE_TOP_DEALS = 'api/FETCH_LERE_TOP_DEALS'
export const FETCH_LERE_HERO_PLANNER = 'api/FETCH_LERE_HERO_PLANNER'
export const FETCH_LERE_HIGH_INTENT = 'api/FETCH_LERE_HIGH_INTENT'
export const FETCH_LERE_PERSONALISED_ALTERNATIVES = 'api/FETCH_LERE_PERSONALISED_ALTERNATIVES'
export const FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES = 'api/FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES'
export const FETCH_LERE_PERSONALISED_TOUR_ENQUIRES = 'api/FETCH_LERE_PERSONALISED_TOUR_ENQUIRES'
export const FETCH_LERE_PERSONALISED_PREFERENCES = 'api/FETCH_LERE_PERSONALISED_PREFERENCES'
export const FETCH_LERE_OFFER_STATS = 'api/FETCH_LERE_OFFER_STATS'
export const GET_LOCAL_HIGH_INTENT_OFFERS = 'GET_LOCAL_HIGH_INTENT_OFFERS'
export const FETCH_LERE_NEARBY_OFFERS = 'api/FETCH_LERE_NEARBY_OFFERS'
export const FETCH_LERE_TRENDING_DESTINATIONS = 'api/FETCH_LERE_TRENDING_DESTINATIONS'
export const FETCH_LERE_TOUR_RECOMMENDATIONS = 'api/FETCH_LERE_TOUR_RECOMMENDATIONS'
export const FETCH_LERE_REGION_RECOMMENDATIONS = 'api/FETCH_LERE_REGION_RECOMMENDATIONS'

// Order API Actions
export const FETCH_ORDERS_DATES_REQUESTS = 'api/FETCH_ORDERS_DATES_REQUESTS'
export const FETCH_ORDERS_FOR_CURRENT_USER = 'api/FETCH_ORDERS_FOR_CURRENT_USER'
export const FETCH_ORDER = 'api/FETCH_ORDER'
export const FETCH_ORDER_BY_SUBSCRIPTION = 'api/FETCH_ORDER_BY_SUBSCRIPTION'
export const SUBMIT_ORDER_TRAVELLERS = 'api/SUBMIT_ORDER_TRAVELLERS'
export const SAVE_DRAFT_ORDER_TRAVELLERS = 'api/SAVE_DRAFT_ORDER_TRAVELLERS'
export const FETCH_TOUR_ORDER_TRAVELLER_DETAILS = 'api/FETCH_TOUR_ORDER_TRAVELLER_DETAILS'
export const FETCH_PAYMENT_METHOD_STRIPE = 'api/FETCH_PAYMENT_METHOD_STRIPE'
export const CANCEL_ORDER_GIFTING = 'api/CANCEL_ORDER_GIFTING'
export const FETCH_ORDER_ITEM_FLIGHT_DETAILS = 'api/FETCH_ORDER_ITEM_FLIGHT_DETAILS'
export const UPDATE_ORDER_PARTNERSHIP = 'api/UPDATE_ORDER_PARTNERSHIP'
export const FETCH_FLIGHT_ORDER_PNRS = 'api/FETCH_FLIGHT_ORDER_PNRS'
export const REFUND_ORDER_ITEMS = 'api/REFUND_ORDER_ITEMS'
export const REFUND_ORDER_ITEM = 'api/REFUND_ORDER_ITEM'
export const FETCH_ROOM_REFUND_INFO = 'api/FETCH_ROOM_REFUND_INFO'

// Experience API Actions
export const FETCH_EXPERIENCE_LIST = 'api/FETCH_EXPERIENCE_LIST'
export const FETCH_EXPERIENCES = 'api/FETCH_EXPERIENCES'
export const FETCH_EXPERIENCE_DATES = 'api/FETCH_EXPERIENCE_DATES'
export const FETCH_EXPERIENCE_TIMESLOTS = 'api/FETCH_EXPERIENCE_TIMESLOTS'
export const FETCH_EXPERIENCE = 'api/FETCH_EXPERIENCE'
export const FETCH_EXPERIENCE_CHECKOUT_TRAVELLER_FIELDS = 'api/FETCH_EXPERIENCE_CHECKOUT_TRAVELLER_FIELDS'
export const CHECK_LEGACY_ORDERS_FOR_CURRENT_USER = 'api/FETCH_LEGACY_ORDERS'
export const FETCH_EXPERIENCE_CATEGORIES = 'api/FETCH_EXPERIENCE_CATEGORIES'
export const FETCH_EXPERIENCE_LIST_FACETS = 'api/FETCH_EXPERIENCE_LIST_FACETS'
export const FETCH_EXPERIENCE_VOUCHER = 'api/FETCH_EXPERIENCE_VOUCHER'
export const FETCH_BOOKING_DETAILS = 'api/FETCH_BOOKING_DETAILS'
export const MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED = 'api/MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED'
export const FETCH_TRANSFER_OPTIONS = 'FETCH_TRANSFER_OPTIONS'
export const FETCH_REFUND_DETAILS = 'api/FETCH_REFUND_DETAILS'

// Checkout API Actions
export const FETCH_TRAVELLER_FORM_SCHEMA = 'api/FETCH_TRAVELLER_FORM_SCHEMA'
export const UPDATE_BEDBANK_SESSION = 'api/UPDATE_BEDBANK_SESSION'
export const CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER = 'api/CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER'
export const CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION = 'api/CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION'
export const CHECKOUT_CREATE_CART_QUOTE = 'api/CHECKOUT_CREATE_CART_QUOTE'
export const CHECKOUT_UPDATE_CART_QUOTE = 'api/CHECKOUT_UPDATE_CART_QUOTE'

// Refer API Actions
export const FETCH_REFER_EARN_OPTIONS = 'api/FETCH_REFER_EARN_OPTIONS'
export const FETCH_REFERRAL_LOGS = 'api/FETCH_REFERRAL_LOGS'
export const FETCH_REFERRAL_CODE = 'api/FETCH_REFERRAL_CODE'
export const FETCH_REFERRAL_VALUE = 'api/FETCH_REFERRAL_VALUE'
export const SEND_MULTIPLE_REFERRAL_REQUESTS = 'api/SEND_MULTIPLE_REFERRAL_REQUESTS'
export const FETCH_LETV_QUESTIONS = 'api/FETCH_LETV_QUESTIONS'
export const SEND_LETV_ANSWERS = 'api/SEND_LETV_ANSWERS'
export const FETCH_LETV_PRIZE = 'api/GET_LETV_PRIZE'
export const FETCH_BEST_BACK_PRIZE = 'api/GET_BEST_BACK_PRIZE'
export const FETCH_BEST_BACK_OBJECT = 'api/GET_BEST_BACK_OBJECT'
export const FETCH_BEST_BACK_USER_STATUS = 'api/GET_BEST_BACK_USER_STATUS'

export const FETCH_NBL_COMPETITION_PRIZE = 'api/GET_NBL_COMPETITION_PRIZE'
export const FETCH_NBL_COMPETITION_OBJECT = 'api/GET_NBL_COMPETITION_OBJECT'
export const FETCH_NBL_COMPETITION_USER_STATUS = 'api/GET_NBL_COMPETITION_USER_STATUS'
export const FETCH_UK_COMPETITION_PRIZE = 'api/GET_UK_COMPETITION_PRIZE'
export const FETCH_UK_COMPETITION_OBJECT = 'api/GET_UK_COMPETITION_OBJECT'
export const FETCH_UK_COMPETITION_USER_STATUS = 'api/GET_UK_COMPETITION_USER_STATUS'
export const FETCH_AU_COMPETITION_PRIZE = 'api/GET_AU_COMPETITION_PRIZE'
export const FETCH_AU_COMPETITION_OBJECT = 'api/GET_AU_COMPETITION_OBJECT'
export const FETCH_AU_COMPETITION_USER_STATUS = 'api/GET_AU_COMPETITION_USER_STATUS'
export const FETCH_INTL_COMPETITION_PRIZE = 'api/GET_INTL_COMPETITION_PRIZE'
export const FETCH_INTL_COMPETITION_OBJECT = 'api/GET_INTL_COMPETITION_OBJECT'
export const FETCH_INTL_COMPETITION_USER_STATUS = 'api/GET_INTL_COMPETITION_USER_STATUS'
export const FETCH_LETV_USER_STATUS = 'api/GET_LETV_USER_STATUS'

// Promo actions
export const CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO = 'api/REQUEST_CORPORATE_BENEFITS_PROMO'
export const CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO = 'api/REQUEST_FRIENDS_AND_FAMILY_PROMO'
export const FETCH_COMMMISSION_PROMO = 'api/FETCH_COMMMISSION_PROMO'

// LE Subscription API Actions
export const FETCH_LE_SUBSCRIPTIONS = 'api/FETCH_LE_SUBSCRIPTIONS'
export const FETCH_LE_SUBSCRIPTIONS_V2 = 'api/FETCH_LE_SUBSCRIPTIONS_V2'
export const UPDATE_LE_SUBSCRIPTIONS = 'api/UPDATE_LE_SUBSCRIPTIONS'
export const UPDATE_LE_SUBSCRIPTIONS_V2 = 'api/UPDATE_LE_SUBSCRIPTIONS_V2'
export const FETCH_RESUBSCRIPTION_DISMISSED_DATE = 'api/FETCH_RESUBSCRIPTION_DISMISSED_DATE'
export const UPDATE_RESUBSCRIPTION_DISMISSED_DATE = 'api/UPDATE_RESUBSCRIPTION_DISMISSED_DATE'
export const FIRE_RESUBSCRIBE_PROMO_CODE = 'api/FIRE_RESUBSCRIBE_PROMO_CODE'
export const FETCH_TRAVEL_PREFERENCES_DISMISSED = 'api/FETCH_TRAVEL_PREFERENCES_DISMISSED'
export const UPDATE_TRAVEL_PREFERENCES_DISMISSED = 'api/UPDATE_TRAVEL_PREFERENCES_DISMISSED'

// User Travel Preferences Actions
export const FETCH_USER_PREFERENCES_DESTINATION_DEFAULT = 'api/FETCH_USER_PREFERENCES_DESTINATION_DEFAULT'
export const FETCH_USER_PREFERENCES_DESTINATION = 'api/FETCH_USER_PREFERENCES_DESTINATION'
export const UPDATE_USER_PREFERENCES_DESTINATION = 'api/UPDATE_USER_PREFERENCES_DESTINATION'
export const DELETE_USER_PREFERENCES_DESTINATION = 'api/DELETE_USER_PREFERENCES_DESTINATION'
export const FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT = 'api/FETCH_USER_PREFERENCES_HOLIDAY_TYPES_DEFAULT'
export const FETCH_USER_PREFERENCES_HOLIDAY_TYPES = 'api/FETCH_USER_PREFERENCES_HOLIDAY_TYPES'
export const UPDATE_USER_PREFERENCES_HOLIDAY_TYPES = 'api/UPDATE_USER_PREFERENCES_HOLIDAY_TYPES'
export const DELETE_USER_PREFERENCES_HOLIDAY_TYPES = 'api/DELETE_USER_PREFERENCES_HOLIDAY_TYPES'
export const FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT = 'api/FETCH_USER_PREFERENCES_EXTERNAL_BRANDS_DEFAULT'
export const FETCH_USER_PREFERENCES_EXTERNAL_BRANDS = 'api/FETCH_USER_PREFERENCES_EXTERNAL_BRANDS'
export const UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS = 'api/UPDATE_USER_PREFERENCES_EXTERNAL_BRANDS'
export const DELETE_USER_PREFERENCES_EXTERNAL_BRANDS = 'api/DELETE_USER_PREFERENCES_EXTERNAL_BRANDS'
export const FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT = 'api/FETCH_USER_PREFERENCES_OCCUPANCY_DEFAULT'
export const FETCH_USER_PREFERENCES_OCCUPANCY = 'api/FETCH_USER_PREFERENCES_OCCUPANCY'
export const UPDATE_USER_PREFERENCES_OCCUPANCY = 'api/UPDATE_USER_PREFERENCES_OCCUPANCY'
export const DELETE_USER_PREFERENCES_OCCUPANCY = 'api/DELETE_USER_PREFERENCES_OCCUPANCY'

// WL Subscription API Actions
export const FETCH_WL_SUBSCRIPTIONS = 'api/FETCH_WL_SUBSCRIPTIONS'
export const UPDATE_WL_SUBSCRIPTIONS = 'api/UPDATE_WL_SUBSCRIPTIONS'

// Cruises API Actions
export const FETCH_CRUISE_SEARCH_FACETS = 'api/FETCH_CRUISE_SEARCH_FACETS'
export const FETCH_CRUISE_SEARCH_COUNT = 'api/FETCH_CRUISE_SEARCH_COUNT'
export const FETCH_CRUISES_LIST_RECOMMENDATION = 'api/FETCH_CRUISES_LIST_RECOMMENDATION'
export const FETCH_CRUISE_OFFER = 'api/FETCH_CRUISE_OFFERS'
export const FETCH_CRUISE_SHIP_DECK = 'api/FETCH_CRUISE_SHIP_DECK'
export const FETCH_CRUISE_SHIP = 'api/FETCH_CRUISE_SHIP'
export const FETCH_CRUISE_BOOKING_RATE_LIST = 'api/FETCH_CRUISE_BOOKING_RATE_LIST'
export const FETCH_CRUISE_BOOKING_CABIN_LIST = 'api/FETCH_CRUISE_BOOKING_CABIN_LIST'
export const FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST = 'api/FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST'
export const FETCH_CRUISE_BOOKING_CABIN_SELECTION = 'api/FETCH_CRUISE_BOOKING_CABIN_SELECTION'
export const FETCH_CRUISE_BOOKING_CABIN_PRICING = 'api/FETCH_CRUISE_BOOKING_CABIN_PRICING'
export const FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST = 'api/FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST'
export const FETCH_CRUISE_BOOKING_CABIN_RELEASE = 'api/FETCH_CRUISE_BOOKING_CABIN_RELEASE'
export const FETCH_CRUISE_BOOKING_BY_ID = 'api/FETCH_CRUISE_BOOKING_BY_ID'
export const FETCH_CRUISE_DEPARTURE_BY_ID = 'api/FETCH_CRUISE_DEPARTURE_BY_ID'
export const FETCH_CRUISE_LINES = 'api/FETCH_CRUISE_LINES'
export const FETCH_CRUISE_PROMO_BANNERS = 'api/FETCH_CRUISE_PROMO_BANNERS'
export const FETCH_CRUISE_LOWEST_PRICE_BY_MONTH = 'api/FETCH_CRUISE_LOWEST_PRICE_BY_MONTH'
export const RESET_SESSION_ID = 'api/RESET_SESSION_ID'
export const FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE = 'api/FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE'

// Dream site Actions
export const FETCH_DREAM_SITE_BLOG_POSTS = 'api/FETCH_DREAM_SITE_BLOG_POSTS'

// Review Actions
export const FETCH_REVIEWS = 'api/FETCH_REVIEWS'
export const FETCH_REVIEWS_ORDER = 'api/FETCH_REVIEW_ORDER'
export const UPSERT_REVIEW = 'api/UPSERT_REVIEW'
export const DELETE_REVIEW = 'api/DELETE_REVIEW'
export const UPLOAD_REVIEW_PHOTO = 'api/UPLOAD_REVIEW_PHOTO'
export const DELETE_REVIEW_PHOTO = 'api/DELETE_REVIEW_PHOTO'
export const FETCH_REVIEW_SUMMARY = 'api/FETCH_REVIEW_SUMMARY'

// Offer actions
export const FETCH_OFFER = 'api/FETCH_OFFER'
export const FETCH_OFFER_EXTRA = 'api/FETCH_OFFER_EXTRA'
export const FETCH_OFFERS = 'api/FETCH_OFFERS'
export const FETCH_OFFER_SUMMARIES = 'api/FETCH_OFFER_SUMMARIES'
export const FETCH_OFFER_LIST = 'api/FETCH_OFFER_LIST'
export const FETCH_BEDBANK_OFFER = 'api/FETCH_BEDBANK_OFFER'
export const FETCH_BEDBANK_OFFERS = 'api/FETCH_BEDBANK_OFFERS'
export const FETCH_BEDBANK_OFFER_SUMMARIES = 'api/FETCH_BEDBANK_OFFER_SUMMARIES'
export const FETCH_TOURV2_OFFER = 'api/FETCH_TOURV2_OFFER'
export const FETCH_TOUR_SEARCH_FACETS = 'api/FETCH_TOUR_SEARCH_FACETS'
export const SEND_WAITLIST_REQUEST = 'api/SEND_WAITLIST_REQUEST'
export const FETCH_BEDBANK_OFFER_CALENDAR = 'api/FETCH_BEDBANK_OFFER_CALENDAR'
export const FETCH_BEDBANK_OFFER_RATES = 'api/FETCH_BEDBANK_OFFER_RATES'
export const FETCH_BEDBANK_OFFERS_RATES = 'api/FETCH_BEDBANK_OFFERS_RATES'
export const FETCH_BEDBANK_OFFER_RATES_BULK = 'api/FETCH_BEDBANK_OFFER_RATES_BULK'
export const FETCH_OFFER_PACKAGE_ADDONS = 'api/FETCH_OFFER_PACKAGE_ADDONS'
export const FETCH_OFFER_FLIGHT_PRICE = 'api/FETCH_OFFER_FLIGHT_PRICE'
export const FETCH_OFFER_LIST_FILTERS = 'api/FETCH_OFFER_LIST_FILTERS'
export const FETCH_BEST_OFFER_FOR_PROPERTY = 'api/FETCH_BEST_PROPERTY_FOR_OFFER'
export const FETCH_SURCHARGE_MARGIN = 'api/FETCH_SURCHARGE_MARGIN'

// Geo actions
export const FETCH_GEO_DATA = 'api/FETCH_GEO_DATA'
export const FETCH_GEO_AIRPORTS = 'api/FETCH_GEO_AIRPORTS'

// Car Hire actions
export const FETCH_CAR_HIRE_OFFERS = 'api/FETCH_CAR_HIRE_OFFERS'
export const FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION = 'api/FETCH_CAR_HIRE_OFFER_IMPORTANT_INFORMATION'
export const FETCH_CAR_HIRE_RESERVATION = 'api/FETCH_CAR_HIRE_RESERVATION'
export const FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO = 'api/FETCH_CAR_HIRE_RESERVATION_INSURANCE_INFO'
export const FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE = 'api/FETCH_CAR_HIRE_OFFER_INSURANCE_QUOTE'
export const FETCH_CAR_HIRE_DESTINATION_PRICE_INFO = 'api/FETCH_CAR_HIRE_DESTINATION_PRICE_INFO'
export const FETCH_CAR_HIRE_CHEAPEST_OFFER = 'api/FETCH_CAR_HIRE_CHEAPEST_OFFER'

// New homepage
export const FETCH_MAX_SAVINGS = 'api/FETCH_MAX_SAVINGS'

// Velocity
export const FETCH_VELOCITY_MEMBER_DETAILS = 'api/FETCH_VELOCITY_MEMBER_DETAILS'

// Merchant Fees
export const FETCH_MERCHANT_FEE_CONFIG = 'api/FETCH_MERCHANT_FEE_CONFIG'
export const FETCH_MERCHANT_FEE_DETAILS = 'api/FETCH_MERCHANT_FEE_DETAILS'

// Payment Schedule
export const FETCH_PAYMENT_SCHEDULE = 'api/FETCH_PAYMENT_SCHEDULE'

// PayTo
export const FETCH_PAYTO_SUPPORTED_BANKS = 'api/FETCH_PAYTO_SUPPORTED_BANKS'

// Booking Protection
export const FETCH_BOOKING_PROTECTION_QUOTE = 'api/FETCH_BOOKING_PROTECTION_QUOTE'

// Deposit configuration
export const FETCH_DEPOSIT_CONFIG = 'api/FETCH_DEPOSIT_CONFIG'

// LuxPlus+ actions
export const FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION = 'api/FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION'
export const FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION = 'api/FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION'
export const UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION = 'api/UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION'
export const CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW = 'api/CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW'
export const FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY = 'api/FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY'
export const FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD = 'api/FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD'

// Lux Loyalty actions
export const ENROL_LUX_LOYALTY_ACCOUNT = 'api/ENROL_LUX_LOYALTY_ACCOUNT'
export const FETCH_LUX_LOYALTY_ACCOUNT = 'api/FETCH_LUX_LOYALTY_ACCOUNT'
export const FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY = 'api/FETCH_LUX_LOYALTY_ACCOUNT_ELIGIBILITY'
export const FETCH_LUX_LOYALTY_PROGRAM_CONFIG = 'api/FETCH_LUX_LOYALTY_PROGRAM_CONFIG'

// Agent Hub actions
export const FETCH_ACCOUNT_AGENT_HUB = 'api/FETCH_ACCOUNT_AGENT_HUB'
export const FETCH_AGENT_ACCOUNT_STATUS = 'api/FETCH_AGENT_ACCOUNT_STATUS'
export const FETCH_AGENT_COMMISSION_CONFIG = 'api/FETCH_AGENT_COMMISSION_CONFIG'
export const FETCH_AGENT_STATUS = 'api/FETCH_AGENT_STATUS'
export const FETCH_AGENT_STATUS_BY_AGENT_ID = 'api/FETCH_AGENT_STATUS_BY_AGENT_ID'
export const FETCH_COMMISSION_PROMO_CODE = 'api/FETCH_COMMISSION_PROMO_CODE'
export const FETCH_AGENT_AFFILIATION_OPTIONS = 'api/FETCH_AGENT_AFFILIATION_OPTIONS'
export const ONBOARD_AGENCY = 'api/ONBOARD_AGENCY'

export const LOG_PAYMENT_EVENT = 'api/LOG_PAYMENT_EVENT'

export const CREATE_STRIPE_PAYMENT_METHOD = 'api/CREATE_STRIPE_PAYMENT_METHOD'

export const FETCH_TOUR_DATES = 'api/FETCH_TOUR_DATES'

// Support Assistant Actions (svc-support)
export const CREATE_NEW_CHAT = 'api/CREATE_NEW_CHAT'
export const FETCH_CHATS = 'api/FETCH_CHATS'
export const FETCH_CHAT_MESSAGES = 'api/FETCH_CHAT_MESSAGES'
export const FETCH_CHAT_WELCOME_MESSAGE = 'api/FETCH_CHAT_WELCOME_MESSAGE'
export const POST_CHAT_UPDATE = 'api/POST_CHAT_UPDATE'
export const POST_MESSAGE_FEEDBACK = 'api/POST_MESSAGE_FEEDBACK'

// Support actions (svc-support)
export const FETCH_CANCELLATION_MODAL_SETTINGS = 'api/FETCH_CANCELLATION_MODAL_SETTINGS'

// Tours
export const FETCH_TOURV2_SNAPSHOT_DETAILS = 'api/FETCH_TOURV2_SNAPSHOT_DETAILS'
export const FETCH_TOURS_POPULAR_DESTINATIONS = 'api/FETCH_TOURS_POPULAR_DESTINATIONS'
export const FETCH_TOUR_OPERATORS = 'api/FETCH_TOUR_OPERATORS'
export const FETCH_TAILOR_MADE_TOUR = 'api/FETCH_TAILOR_MADE_TOUR'
export const FETCH_TAILOR_MADE_TOUR_ORDER = 'api/FETCH_TAILOR_MADE_TOUR_ORDER'

// Secure Payment
export const CREATE_STRIPE_PAYMENT_METHOD_SECURE_PAYMENT = 'api/CREATE_STRIPE_PAYMENT_METHOD_SECURE_PAYMENT'
