import { getOfferById } from 'api/offer'
import { fetchAvailableCreditAndOpenSnackbar } from 'actions/AuthActions'
import { getNextUpsellableOrder } from 'selectors/orderSelectors'

import {
  experienceUpsellSnackbarContent,
  getLocation,
  onOpenUpsellSnackbar,
  upsellSnackbarDismissed,
} from './utils'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { replaceExperienceQueryString } from 'lib/experiences/experienceUtils'
import { fetchPlaceByGeoCoords } from 'actions/PlaceActions'

export function fetchUpsellOrAvailableCreditSnackbar() {
  return async(dispatch, getState) => {
    const state = getState() as App.State
    const soonestUpcomingOrder = getNextUpsellableOrder(state)

    // display credit snackbar if upsell snackbar previously dismissed or no upcoming "upsellable" orders
    const { memberId } = state.auth.account
    if (memberId) {
      if (upsellSnackbarDismissed(memberId) || !soonestUpcomingOrder) {
        dispatch(fetchAvailableCreditAndOpenSnackbar())
        return
      }

      // display credit snackbar if soonest upcoming order doesn't meet the upsell requirements
      const isLeHotel = soonestUpcomingOrder.items.length > 0
      const offerId = isLeHotel && soonestUpcomingOrder.items?.[0]?.offerId
      const orderOffer = offerId ? await getOfferById(offerId, {
        region: state.geo.currentRegionCode,
      }) as App.Offer : undefined
      const { hasExperience } = soonestUpcomingOrder || {}
      const displayUpsellSnackbar = !hasExperience
      if (!displayUpsellSnackbar || (isLeHotel && !orderOffer) || !orderOffer?.property) {
        dispatch(fetchAvailableCreditAndOpenSnackbar())
        return
      }

      const location = getLocation(orderOffer)
      const latitude = orderOffer.property.latitude
      const longitude = orderOffer.property.longitude

      dispatch(fetchPlaceByGeoCoords(latitude, longitude, { levels: ['multi_city_vicinity', 'province_state', 'city'] }))
      const placeKey = `${latitude}-${longitude}`
      const latLongPlace = state.destination.coordsToPlace[placeKey]

      if (latLongPlace) {
        const query = replaceExperienceQueryString({ destinationId: latLongPlace.id, destinationName: latLongPlace.name })
        const {
          header,
          message,
          primaryActionLinkLabel,
          primaryActionLinkPath,
        } = experienceUpsellSnackbarContent(location, query)

        // open snackbar
        showSnackbar(
          message,
          'neutral',
          {
            heading: header,
            action: {
              label: primaryActionLinkLabel,
              to: primaryActionLinkPath,
            },
          },
        )
        onOpenUpsellSnackbar(memberId)
      }
    }
  }
}
