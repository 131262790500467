import moment from 'moment'
import { OFFER_TYPE_LAST_MINUTE, OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_TOUR, OFFER_TYPE_BED_BANK, OFFER_TYPE_VILLA } from 'constants/offer'
import { getVisibilityScheduleForRegion } from 'lib/offer/offerUtils'
import { isBedbank, isLEOffer, isTourOfferType, isTourV2Offer, isVillaOffer } from 'lib/offer/offerTypes'
import getUrgencyLabels from 'lib/offer/getUrgencyLabels'
import { pluralizeToString } from '../string/pluralize'

export const ENABLED_OFFER_TYPES_RECENTLY_VIEWED = new Set<App.OfferType>([
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_LAST_MINUTE,
  'direct_tour',
  'partner_tour',
  'connection_tour',
  OFFER_TYPE_TOUR,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_VILLA,
])

export function isOfferValidWithRegion(
  offer: App.Offer | Tours.TourV2Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary,
  region: string,
) {
  if (isTourV2Offer(offer) || isBedbank(offer) || isVillaOffer(offer)) return true

  const current = moment()
  const visibilitySchedule = getVisibilityScheduleForRegion(offer, region)
  const canBook = !offer.bookByDate || current.isBefore(moment(offer.bookByDate, 'YYYY-MM-DD'))
  const isVisible = !!visibilitySchedule && current.isBefore(moment(visibilitySchedule.end, moment.ISO_8601))
  return canBook && isVisible
}

export function getHotLeadDurationText(duration: number, offerType: App.OfferType) {
  const timeUnit = isTourOfferType(offerType) ? 'day' : 'night'
  const durationLabel = pluralizeToString(timeUnit, duration)

  return `${durationLabel} from`
}

export function getTimeLeftUrgencyLabels(offer: App.AnyOffer, currentRegionCode: string | undefined, countdownLabelVariant?: string) {
  if (!currentRegionCode || !isLEOffer(offer)) return []

  const showNewLimitVariant = !!countdownLabelVariant && countdownLabelVariant !== 'control'

  return getUrgencyLabels(offer, currentRegionCode, showNewLimitVariant).filter(item => item.type === 'left')
}

export function isHotLead(offer: App.HighIntentOffer): offer is App.HotLeadOffer {
  return offer.category === 'sticky_hot_leads'
}

export function isRecentlyViewed(offer: App.HighIntentOffer): offer is App.RecentlyViewedOffer {
  return offer.category === 'recently_viewed'
}

export function shouldDisplayAsRecentlyViewed(offer: App.HighIntentOffer) {
  return isTourOfferType(offer.offerType)
}

export function isPriceMissing(offer: App.HighIntentOffer): boolean {
  return !!offer.isPriceMissing
}

export function isAbandonedCart(offer: App.HighIntentOffer): offer is App.AbandonedCartRecommendation {
  return offer.category === 'sticky_abandoned_carts'
}
