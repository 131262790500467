import React, { useMemo } from 'react'
import styled from 'styled-components'
import Pane from 'components/Common/Pane'
import config from 'constants/config'
import BedbankOfferTileExpanded from './BedbankOfferTileExpanded'
import BedbankOfferTileCondensed from './BedbankOfferTileCondensed'
import { useBedbankRoomRatePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import moment from 'moment'
import useBedbankFlightPrice from 'hooks/OfferPage/useBedbankFlightPrice'
import { isBedbankFlightBookingEnabled } from 'selectors/flightsSelectors'
import { connect } from 'react-redux'
import OfferListLoadingOfferTile from 'components/OfferList/OfferListTiles/OfferListLoadingOfferTile'
import useBedbankRates from 'hooks/useBedbankRates'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { buildSuggestedDatesParamsKey, isSearchStreamingSupported } from 'lib/search/searchUtils'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import SearchBedbankOfferTileSuperSlim from './SearchBedbankOfferTileSuperSlim'
import useShouldHighlight from 'hooks/OfferPage/useShouldHighlightTile'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import { buildBedbankCancellationPolicy } from 'lib/search/hotels'

const Root = styled(Pane)`
  position: relative;
`
interface MappedStateProps {
  suggestedDates: App.OfferSuggestedDates;
  isBedbankFlightBookingEnabled: boolean;
}

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerMetaData: App.OfferListMetaData;
  onImageChange?: (idx: number, image?: App.Image) => void;
}

enum PresentMode {
  CONDENSED = 'condensed',
  EXPANDED = 'expanded',
  SUPER_SLIM = 'super_slim',
}

function SearchBedbankOffer(props: Props & MappedStateProps) {
  const {
    offer,
    filters,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    onImageChange,
    isBedbankFlightBookingEnabled,
    suggestedDates,
  } = props

  const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut
  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch
  const promotions = useBedbankRoomRatePromotions(offer.promotions, moment(filters?.checkIn), moment(filters?.checkOut), isAnytimeSearch)
  const isLTSOffer = offerMetaData?.hasPromotions || !!Object.values(promotions).length

  const flightsEnabled = isBedbankFlightBookingEnabled && !!offer.flights?.airportCode && !!offer.flights?.flightsEnabled
  const flightPrice = useBedbankFlightPrice(offer, filters?.rooms || [], flightsEnabled, filters?.checkIn, filters?.checkOut)

  const checkIn = filters?.checkIn ?? suggestedDates?.checkIn
  const checkOut = filters?.checkOut ?? suggestedDates?.checkOut

  const allowSortByPrice = !!useOptimizelyExperiment(OptimizelyFeatureFlags.searchStreamingSortByPrice)
  const searchStreamingEnabled = isSearchStreamingSupported(filters, { allowSortByPrice })
  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? {}, offerId: offer.id })

  const [{
    hotelOnlyRates,
    hotelBundleRates,
  }, fetchingRates] = useBedbankRates(offer.id, filters?.rooms, checkIn, checkOut, !searchStreamingEnabled)

  const showExpandedTile = config.BEDBANK_EXPANDED_SEARCH_OFFER_TILE_ENABLED && isLTSOffer
  const isOfferHighlighted = useShouldHighlight({ offer, filters })
  // always show the super slim tile if the offer is highlighted
  const isSuperSlimOfferTileABTestEnabled = !!useOptimizelyExperiment(OptimizelyExperiments.superSlimTilesForOfferListPage, isOfferHighlighted)
  const presentMode = (isSuperSlimOfferTileABTestEnabled && isOfferHighlighted) ?
    PresentMode.SUPER_SLIM :
      (showExpandedTile ? PresentMode.EXPANDED : PresentMode.CONDENSED)

  const cancellationPolicy = useMemo(() => {
    return buildBedbankCancellationPolicy(
      offerMetaData?.cancellationPolicyInfo,
      filters,
      offer.property.timezone,
    )
  }, [offerMetaData?.cancellationPolicyInfo, filters, offer.property.timezone])

  return <Root type={presentMode === PresentMode.SUPER_SLIM ? undefined : 'clean'}>
    {fetchingRates && <OfferListLoadingOfferTile
      offerType="bedbank_hotel"
      tileStyle="search"
    />}
    {!fetchingRates && <>
      {presentMode === PresentMode.EXPANDED && <BedbankOfferTileExpanded
        offer={offer }
        filters={filters}
        eagerLoadFirstImage={eagerLoadFirstImage}
        offerUrl={offerUrl}
        flightPrice={flightPrice}
        flightsEnabled={flightsEnabled}
        onImageChange={onImageChange}
        hotelOnlyRates={hotelOnlyRates}
        hotelBundleRates={hotelBundleRates}
        directSearchPrices={directSearchPrices}
        cancellationPolicy={cancellationPolicy}
      />}
      {presentMode === PresentMode.CONDENSED && <BedbankOfferTileCondensed
        offer={offer}
        filters={filters}
        eagerLoadFirstImage={eagerLoadFirstImage}
        offerUrl={offerUrl}
        flightPrice={flightPrice}
        flightsEnabled={flightsEnabled}
        onImageChange={onImageChange}
        hotelOnlyRates={hotelOnlyRates}
        hotelBundleRates={hotelBundleRates}
        fetchingRates={fetchingRates}
        directSearchPrices={directSearchPrices}
        cancellationPolicy={cancellationPolicy}
      />}
      {presentMode === PresentMode.SUPER_SLIM &&
        <SearchBedbankOfferTileSuperSlim
        offer={offer }
        filters={filters}
        eagerLoadFirstImage={eagerLoadFirstImage}
        offerUrl={offerUrl}
        flightPrice={flightPrice}
        flightsEnabled={flightsEnabled}
        onImageChange={onImageChange}
        hotelOnlyRates={hotelOnlyRates}
        hotelBundleRates={hotelBundleRates}
        fetchingRates={fetchingRates}
        isOfferHighlighted={isOfferHighlighted}
      />

    }
    </>
    }
  </Root>
}

const mapStateToProps = (appState: App.State, ownProps: Props) => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)

  return {
    isBedbankFlightBookingEnabled: isBedbankFlightBookingEnabled(appState),
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
  }
}

export default connect(mapStateToProps)(SearchBedbankOffer)
