import React, { useMemo, useEffect, useCallback, useContext, useState } from 'react'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import { rem } from 'polished'
import styled from 'styled-components'
import Pane from 'components/Common/Pane'
import HighlightMessage from 'components/Common/HighlightMessage/HighlightMessage'

import { buildSearchParamsKey, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import { dateDifference } from 'lib/datetime/dateUtils'
import { fetchBestPriceForOffer } from 'actions/OfferActions'
import { getPackageUniqueKey } from '../../../../lib/offer/offerUtils'
import { HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES, HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES } from '../constants'
import { isSpoofed } from 'selectors/featuresSelectors'
import { logNewRelic } from 'services/newRelic'
import { OfferUnavailableReason } from 'lib/search/constants'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useOfferSoldOutPushDown } from '../../../../hooks/Offers/useOfferSoldOutPushDown'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import SearchFlashOfferExpanded from './SearchFlashOfferExpanded'
import SearchOfferTileCondensed from './SearchOfferTileCondensed'
import useImpressionHandler from 'hooks/useImpressionHandler'
import useOffer from 'hooks/Offers/useOffer'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'

const Root = styled(Pane)`
  position: relative;
`

const OfferLoadingBox = styled(LoadingBox)`
  height: ${rem(480)};
  width: 100%;
`

interface Props {
  offer: App.Offer | App.OfferSummary;
  offerMetaData?: App.OfferListMetaData;
  bestPrices?: {
    [key: string]: App.OfferMaybeAvailableRate;
  };
  pricesErrors?: { [key: string]: any };
  filters?: App.OfferListFilters;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  isSpoofed?: boolean;
  currentRegion?: string;
  onImageChange?: (idx: number, image?: App.Image) => void
}

function SearchFlashOffer(props: Props) {
  const {
    bestPrices,
    pricesErrors,
    isSpoofed,
    filters,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    currentRegion,
    onImageChange,
  } = props
  const [imageLoaded, setImageLoaded] = useState(false)
  const offerId = props.offer.id
  const metaData = useOfferMetaData(offerId, filters)

  const dispatch = useAppDispatch()
  const impressionRef = useImpressionHandler(offerId)

  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])

  const allowSortByPrice = !!useOptimizelyExperiment(OptimizelyFeatureFlags.searchStreamingSortByPrice)
  const searchStreamingEnabled = isSearchStreamingSupported(filters, { allowSortByPrice })

  const hasDates = !!(checkIn && checkOut && filters?.rooms)
  const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))
  const bestPrice = bestPrices?.[searchKey]
  const bestPriceError = pricesErrors?.[searchKey]
  const available = bestPrice?.available || metaData?.available
  const bestPriceRate = bestPrice?.rate
  const fetchingPrice = hasDates && !bestPrice && !bestPriceError && !searchStreamingEnabled
  const soldOut = hasDates && bestPrice && !available

  const dateUnavailable = metaData?.unavailableReason === OfferUnavailableReason.DATE_UNAVAILABLE
  const capacityExceeded = metaData?.unavailableReason === OfferUnavailableReason.CAPACITY_EXCEEDED

  const [offer = props.offer, fetchingOffer] = useOffer<App.Offer>(offerId, {
    requireSummaryOnly: !hasDates,
  })

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  const [showSpoofHighlight, spoofMessage] = useMemo(() => {
    let show = false
    if (offer.visibilitySchedules && isSpoofed) {
      Object.entries(offer.visibilitySchedules).forEach(([schedule, value]) => {
        if (!scheduleIsCurrent(value)) {
          if (schedule === 'AU' && currentRegion === 'AU') {
            show = true
          } else if (schedule !== 'AU' && currentRegion !== 'AU') {
            show = true
          }
        }
      })
    }
    return [show, 'This hotel is only visible to staff and available for purchase.']
  }, [currentRegion, isSpoofed, offer.visibilitySchedules])

  const showHighlight = (filters?.propertyId && !filters?.searchNearby && offer.property?.id === filters.propertyId.split(':')[1]) || showSpoofHighlight
  let highlightMessage: string
  let tileType: 'loading' | 'expanded' | 'condensed'
  const nights = useMemo(() => {
    if (checkIn && checkOut) {
      return dateDifference(new Date(checkOut), new Date(checkIn)).days
    }
    return null
  }, [checkIn, checkOut])

  if (capacityExceeded) {
    tileType = 'condensed'
    highlightMessage = `The number of guests exceeds the ${offer.saleUnit}'s maximum capacity.`
  } else if (dateUnavailable) {
    tileType = 'condensed'
    highlightMessage = HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
  }
  else if (hasDates) {
    if (searchStreamingEnabled && !metaData?.available) {
      // search has checked dates and found no availability
      tileType = 'condensed'
      highlightMessage = HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
    } else if (searchStreamingEnabled && metaData?.available) {
      // search has checked dates and found availability
      tileType = 'expanded'
      highlightMessage = HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
    } else if (fetchingPrice && !searchStreamingEnabled) {
      tileType = 'loading'
    } else if (available || bestPriceError) {
      tileType = 'expanded'
      highlightMessage = HIGHLIGHT_MESSAGE_AVAILABLE_FOR_DATES()
    }
    else if (!offer.isSoldOut && offer.minDuration && nights && offer.minDuration > nights) {
      tileType = 'condensed'
      highlightMessage = `Stay for at least ${offer.minDuration} nights to unlock a limited time deal on this hotel.`
    } else {
      tileType = 'condensed'
      highlightMessage = HIGHLIGHT_MESSAGE_UNAVAILABLE_FOR_DATES(offer.saleUnit)
    }
  } else {
    tileType = 'expanded'
    highlightMessage = 'We found a limited time deal for your hotel. Choose dates and book now before it ends!'
  }
  if (showSpoofHighlight) {
    highlightMessage = spoofMessage
    tileType = 'expanded'
  }

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingPrice))) {
      dispatchOfferListEvent({
        type: OfferListEvents.offerReady,
        available: tileType === 'expanded',
      })
    }
  }, [fetchingPrice, hasDates, imageLoaded, dispatchOfferListEvent, tileType])

  const bestPricePackage = useMemo(() => {
    if (!searchStreamingEnabled) {
      if (!hasDates || bestPriceError) {
        return offer.lowestPricePackage
      }
      if (bestPriceRate && !fetchingOffer) {
        const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestPriceRate.packageUniqueKey)
        if (!bestPricePkg) {
          logNewRelic('Missing best price package from offer', {
            offerId: offer.id,
            packages: offer.packages.map(pkg => pkg.uniqueKey),
            bestPriceRate,
          })
        }
        return bestPricePkg ?? offer.lowestPricePackage
      }
    } else {
      // use search pricing package id by default
      const pkgId = metaData?.pricing?.lowestPricePackageId ?? offer.lowestPricePackage?.id
      const duration = metaData?.pricing?.duration ?? offer.lowestPricePackage?.duration
      if (!pkgId || !duration || !bestPriceRate?.roomRateId) {
        return offer.lowestPricePackage
      }

      const pkg = offer.packages.find(pkg => pkg.uniqueKey === getPackageUniqueKey(pkgId, duration, bestPriceRate.roomRateId))
      return pkg ?? offer.lowestPricePackage
    }
  }, [offer, bestPriceRate, hasDates, bestPriceError, fetchingOffer, searchStreamingEnabled, metaData?.pricing])

  useOfferSoldOutPushDown(offer.id, filters, !!soldOut)

  // availability/pricing call to svc-calendar
  useEffect(() => {
    if (hasDates && !occupanciesInvalid && !searchStreamingEnabled) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: filters.rooms ?? [],
      }))
    }
  }, [checkIn, checkOut, dispatch, filters, hasDates, occupanciesInvalid, offer, searchStreamingEnabled])

  return <Root type="clean" ref={impressionRef}>
    {showHighlight && <HighlightMessage info={showSpoofHighlight} message={highlightMessage!} />}
    {tileType === 'loading' && <OfferLoadingBox />}
    {tileType === 'expanded' && <SearchFlashOfferExpanded
      offer={offer}
      filters={filters}
      offerMetaData={offerMetaData}
      bestPriceForDates={bestPriceRate!}
      fetchingPriceForDates={fetchingPrice}
      soldOut={!!soldOut}
      bestPricePackage={bestPricePackage}
      eagerLoadFirstImage={eagerLoadFirstImage}
      offerUrl={offerUrl}
      offerLinkIncludesFilters={offerLinkIncludesFilters}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
    {tileType === 'condensed' && <SearchOfferTileCondensed
      offer={offer}
      unavailableReason={metaData?.unavailableReason}
      filters={filters}
      eagerLoadFirstImage={eagerLoadFirstImage}
      onImageChange={onImageChange}
      onImageLoad={handleImageLoaded}
    />}
  </Root>
}

SearchFlashOffer.defaultProps = {
  filters: {},
  bestPrices: {},
  pricesErrors: {},
}

function mapStateToProps(state: App.State, ownProps: Partial<Props>) {
  const offerId = ownProps.offer?.id ?? ''
  return {
    bestPrices: state.offer.offerBestPrices[offerId],
    pricesErrors: state.offer.offerPricesErrors[offerId],
    isSpoofed: isSpoofed(state),
    currentRegion: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(SearchFlashOffer)
