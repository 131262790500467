export function nullifyStartDates() {
  return {
    startDate: undefined,
    startTime: undefined,
    startUtcDateTime: undefined,
  }
}

export function nullifyEndDates() {
  return {
    endDate: undefined,
    endTime: undefined,
    endUtcDateTime: undefined,
  }
}

export function nullifyBookedDetails() {
  return {
    isBooked: false,
    confirmationItemCodes: undefined,
    confirmationCode: undefined,
    confirmationUrl: undefined,
  }
}
