import { createSelector as reselectCreateSelector } from 'reselect'

let createSelector = reselectCreateSelector

if (IS_SSR) {
  createSelector = ((...args: Array<any>) => (...props: Array<any>) => {
    const fnList = typeof args[args.length - 1] === 'function' ? args : args.slice(0, -1)
    return fnList.reduce((params, nextArg, index) => {
      if (index === fnList.length - 1) {
        // last param, this is the result function
        return nextArg(...params)
      }
      // not last param, process the next value
      params.push(nextArg(...props))
      return params
    }, [])
  }) as any
}

export default createSelector
