import React, { useContext } from 'react'
import GeoContext from 'contexts/geoContext'
import KrisFlyerIcon from './PartnershipIcons/KrisFlyerIcon'
import VelocityIcon from './PartnershipIcons/VelocityIcon'

const prefixToIcon = {
  kfp: KrisFlyerIcon,
  vff: VelocityIcon,
}

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

function PartnershipIcon(props: Props) {
  const { partnership } = useContext(GeoContext)
  const Icon = prefixToIcon[partnership?.prefix ?? '']

  if (!Icon) {
    return null
  }

  return <Icon {...props} />
}

export default PartnershipIcon
