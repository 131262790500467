import { CHECKOUT_ITEM_TYPE_INSURANCE } from 'constants/checkout'
import uuidV4 from 'lib/string/uuidV4Utils'
import { PRODUCT_ID_NO_PROTECTION } from 'constants/insurance'

export function generateInsuranceItem(
  productId: string,
  policyIds?: Array<string>,
): App.Checkout.InsuranceItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_INSURANCE,
    productId,
    policyIds,
    insuranceType: 'insurance',
  }
}

export function generateTravelProtectionItem(
  productId: string,
  policyIds?: Array<string>,
): App.Checkout.InsuranceItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_INSURANCE,
    productId,
    policyIds,
    insuranceType: 'travel_protection',

  }
}

export function generateNoProtectionItem(): App.Checkout.InsuranceItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_INSURANCE,
    productId: PRODUCT_ID_NO_PROTECTION,
    insuranceType: 'insurance',
  }
}

export function generateNoTravelProtectionItem(): App.Checkout.InsuranceItem {
  return {
    itemId: uuidV4(),
    transactionKey: uuidV4(),
    itemType: CHECKOUT_ITEM_TYPE_INSURANCE,
    productId: PRODUCT_ID_NO_PROTECTION,
    insuranceType: 'travel_protection',
  }
}
