import {
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  CLEAR_OFFER_CHECK_IN_CHECK_OUT,
  LERE_DISMISS_ALL_STICKY_ABANDONED_CART,
  LERE_REMOVE_ABANDONED_CART,
  LERE_REMOVE_HIGH_INTENT,
  LERE_RESET_PERSONALISED_PREFERENCES,
  LERE_SAVE_ABANDONED_CART,
  LERE_SAVE_DEFAULT_RECOMMENDATION,
  LERE_SAVE_HOT_LEADS,
  OFFER_CALENDAR_RECOMMENDATIONS_CLEAR,
  OFFER_VIEWED,
  SET_LERE_VERSION,
  SET_OFFER_CHECK_IN_CHECK_OUT,
  SET_OFFER_LERE_INLINE_YMAL_IN_VIEW,
} from 'actions/actionConstants'
import {
  FETCH_CALENDAR_OFFER_RECOMMENDATIONS,
  FETCH_LERE_HERO_PLANNER,
  FETCH_LERE_HIGH_INTENT,
  FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS,
  FETCH_LERE_NEARBY_OFFERS,
  FETCH_LERE_OFFER_STATS,
  FETCH_LERE_PERSONALISED_ALTERNATIVES,
  FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES,
  FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME,
  FETCH_LERE_PERSONALISED_PREFERENCES,
  FETCH_LERE_TOP_DEALS,
  FETCH_LERE_TOUR_RECOMMENDATIONS,
  FETCH_OFFER_BASED_LERE_RECOMMENDATIONS,
  FETCH_LERE_TRENDING_DESTINATIONS,
  GET_LOCAL_HIGH_INTENT_OFFERS,
  USER_LOGOUT,
  FETCH_LERE_PERSONALISED_TOUR_ENQUIRES,
  FETCH_LERE_REGION_RECOMMENDATIONS,
} from 'actions/apiActionConstants'
import dedupeConcat from 'lib/array/arrayConcatDedupe'
import { EmptyArray, partitionBy, sortBy, uniqueBy } from 'lib/array/arrayUtils'
import { isAbandonedCart, isRecentlyViewed } from 'lib/offer/highIntentOffers'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialRecommendationState: App.RecommendationState = {
  lereVersion: undefined,
  calendarRecommendations: {
    state: 'loading',
    offerIds: [],
  },
  recommendations: {},
  locationSeededRecommendations: {},
  locationFlexRecommendations: {},
  offerSharedState: {
  },
  personalisedPeopleLikeMe: {
    offers: [],
    state: 'loading',
    error: undefined,
    lereVersion: undefined,
  },
  topDeals: {
    offerIds: [],
    state: 'loading',
    error: undefined,
    lereVersion: undefined,
  },
  heroPlanner: {
    offerIds: [],
    state: 'loading',
    error: undefined,
    lereVersion: undefined,
  },
  highIntent: {
    offers: [],
    state: 'loading',
  },
  personalisedAlternatives: {
    offers: [],
    state: 'loading',
  },
  personalisedAlternativesWithOfferEnquiries: {},
  personalisedTours: {},
  userId: undefined,
  personalisedPreferences: {
    offers: [],
    state: 'loading',
  },
  offerStats: {},
  nearbyOffers: {},
  trendingDestinations: {
    state: 'loading',
    data: [],
  },
  defaultRecommendation: {
    offerIds: [],
    source: '',
  },
  tour: {},
  regionRecommendations: {
    offers: [],
    state: 'loading',
    error: undefined,
    lereVersion: undefined,
  },
}

const apiRequests = reducerSwitch<App.RecommendationState>({
  [FETCH_CALENDAR_OFFER_RECOMMENDATIONS]: () => ({
    calendarRecommendations: {
      state: 'loading',
      offerIds: [],
    },
  }),
  [FETCH_OFFER_BASED_LERE_RECOMMENDATIONS]: (state, action) => {
    if (state.recommendations[action.cacheKey]?.filterKey === action.filterKey) {
      return state
    }
    return {
      ...state,
      recommendations: {
        ...state.recommendations,
        [action.cacheKey]: {
          offerIds: [],
          state: 'loading',
          filterKey: action.filterKey,
        },
      },
    }
  },
  [FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS]: (state, action) => {
    return ({
      ...state,
      locationSeededRecommendations: {
        ...state.locationSeededRecommendations,
        [action.destinationKey]: {
          offerIds: [],
          state: 'loading',
        },
      },
    })
  },
  [FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME]: () => ({
    personalisedPeopleLikeMe: {
      offers: [],
      state: 'loading',
      error: undefined,
      lereVersion: undefined,
    },
  }),
  [FETCH_LERE_TOP_DEALS]: (state) => ({
    ...state,
    topDeals: {
      offerIds: [],
      state: 'loading',
      error: undefined,
      lereVersion: undefined,
    },
  }),
  [FETCH_LERE_HERO_PLANNER]: (state) => ({
    ...state,
    heroPlanner: {
      offerIds: [],
      state: 'loading',
      error: undefined,
      lereVersion: undefined,
    },
  }),
  [FETCH_LERE_HIGH_INTENT]: (state) => ({
    ...state,
    highIntent: {
      ...state.highIntent,
      state: 'loading',
      error: undefined,
    },
  }),
  [FETCH_LERE_PERSONALISED_ALTERNATIVES]: (state) => ({
    personalisedAlternatives: {
      ...state.personalisedAlternatives,
      state: 'loading',
      error: undefined,
    },
  }),
  [FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES]: (state, action) => ({
    personalisedAlternativesWithOfferEnquiries: {
      ...state.personalisedAlternativesWithOfferEnquiries,
      [action.key]: {
        state: 'loading',
        offers: EmptyArray,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_TOUR_ENQUIRES]: (state, action) => ({
    personalisedTours: {
      ...state.personalisedTours,
      [action.key]: {
        state: 'loading',
        offers: EmptyArray,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_PREFERENCES]: (state) => ({
    personalisedPreferences: {
      ...state.personalisedPreferences,
      state: 'loading',
      error: undefined,
    },
  }),
  [FETCH_LERE_OFFER_STATS]: (state, action) => ({
    offerStats: {
      ...state.offerStats,
      [action.offerKey]: {
        stats: undefined,
        state: 'loading',
        error: undefined,
      },
    },
  }),
  [FETCH_LERE_TRENDING_DESTINATIONS]: (state) => ({
    trendingDestinations: {
      ...state.trendingDestinations,
      state: 'loading',
      error: undefined,
    },
  }),
  [FETCH_LERE_NEARBY_OFFERS]: (state, action) => ({
    nearbyOffers: {
      ...state.nearbyOffers,
      [action.key]: {
        offers: [],
        state: 'loading',
        error: undefined,
      },
    },
  }),
  [FETCH_LERE_TOUR_RECOMMENDATIONS]: (state, action) => ({
    tour: {
      ...state.tour,
      [action.key]: {
        offers: [],
        state: 'loading',
        error: undefined,
      },
    },
  }),
  [FETCH_LERE_REGION_RECOMMENDATIONS]: (state, action) => ({ ...state, [action.key]: { offers: [], state: 'loading', error: undefined, lereVersion: undefined } }),
})

const apiSuccesses = reducerSwitch<App.RecommendationState>({
  [FETCH_CALENDAR_OFFER_RECOMMENDATIONS]: (_, action) => {
    return ({
      calendarRecommendations: {
        state: 'done',
        offerIds: action.data.offerIds,
      },
    })
  },
  [FETCH_OFFER_BASED_LERE_RECOMMENDATIONS]: (state, action) => {
    const offerIds = action.data as Array<string>
    const { cacheKey, filterKey } = action
    return ({
      ...state,
      recommendations: {
        ...state.recommendations,
        [cacheKey]: {
          offerIds,
          state: 'done',
          filterKey,
        },
      },
    })
  },
  [FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS]: (state, action) => {
    return ({
      ...state,
      locationSeededRecommendations: {
        ...state.locationSeededRecommendations,
        [action.destinationKey]: {
          offerIds: action.data,
          state: 'done',
        },
      },
    })
  },
  [FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME]: (_, action) => ({
    personalisedPeopleLikeMe: {
      offers: action.data.offers,
      state: 'done',
      error: null,
      lereVersion: action.data.lereVersion,
    },
  }),
  [FETCH_LERE_TOP_DEALS]: (state, action) => ({
    ...state,
    topDeals: {
      offerIds: action.data.offerIds,
      state: 'done',
      error: null,
      lereVersion: action.data.lereVersion,
    },
  }),
  [FETCH_LERE_HERO_PLANNER]: (state, action) => ({
    ...state,
    heroPlanner: {
      offerIds: action.data.offerIds,
      state: 'done',
      error: null,
      lereVersion: action.data.lereVersion,
    },
  }),
  [FETCH_LERE_HIGH_INTENT]: (state, action) => {
    // we want to merge the current offers in state and the results from LERE
    const newOffers = dedupeConcat(action.data.offers, state.highIntent.offers, (offer) => offer.offerId)
    const sortedNewOffers = sortBy(newOffers, (offer) => offer.creationTime, 'desc')
    return ({
      ...state,
      highIntent: {
        ...state.highIntent,
        offers: sortedNewOffers,
        state: 'done',
        error: null,
        lereVersion: action.data.lereVersion,
      },
    })
  },
  [FETCH_LERE_PERSONALISED_ALTERNATIVES]: (_, action) => ({
    personalisedAlternatives: {
      offers: action.data.offers,
      state: 'done',
      error: null,
      lereVersion: action.data.lereVersion,
    },
  }),
  [FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES]: (state, action) => ({
    personalisedAlternativesWithOfferEnquiries: {
      ...state.personalisedAlternativesWithOfferEnquiries,
      [action.key]: {
        offers: action.data.offers,
        state: 'done',
        error: undefined,
        lereVersion: action.data.lereVersion,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_TOUR_ENQUIRES]: (state, action) => ({
    personalisedTours: {
      ...state.personalisedTours,
      [action.key]: {
        offers: action.data.offers,
        state: 'done',
        error: undefined,
        lereVersion: action.data.lereVersion,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_PREFERENCES]: (_, action) => ({
    personalisedPreferences: {
      offers: action.data.offers,
      state: 'done',
      error: null,
      lereVersion: action.data.lereVersion,
    },
  }),
  [FETCH_LERE_OFFER_STATS]: (state, action) => ({
    offerStats: {
      ...state.offerStats,
      [action.offerKey]: {
        stats: action.data.offerStats,
        state: 'done',
        error: undefined,
      },
    },
  }),
  [FETCH_LERE_TRENDING_DESTINATIONS]: (state, action) => ({
    trendingDestinations: {
      state: 'done',
      data: action.data,
      error: undefined,
    },
  }),
  [FETCH_LERE_NEARBY_OFFERS]: (state, action) => ({
    nearbyOffers: {
      ...state.nearbyOffers,
      [action.key]: {
        offers: action.data.offers,
        state: 'done',
        error: undefined,
        lereVersion: action.data.lereVersion,
      },
    },
  }),
  [FETCH_LERE_TOUR_RECOMMENDATIONS]: (state, action) => ({
    tour: {
      ...state.tour,
      [action.key]: {
        offers: action.data.offers,
        state: 'done',
        error: undefined,
        lereVersion: action.data.lereVersion,
      },
    },
  }),
  [FETCH_LERE_REGION_RECOMMENDATIONS]: (state, action) => ({ ...state, [action.key]: { offers: action.data.offers, state: 'done', error: undefined, lereVersion: action.data.lereVersion } }),
})

const apiFailures = reducerSwitch<App.RecommendationState>({
  [FETCH_CALENDAR_OFFER_RECOMMENDATIONS]: (_, action) => {
    return ({
      calendarRecommendations: {
        state: 'error',
        error: action.error,
        offerIds: [],
      },
    })
  },
  [FETCH_OFFER_BASED_LERE_RECOMMENDATIONS]: (state, action) => ({
    ...state,
    recommendations: {
      ...state.recommendations,
      [action.cacheKey]: {
        offerIds: [],
        state: 'error',
        error: action.error,
        filterKey: action.filterKey,
      },
    },
  }),
  [FETCH_LERE_LOCATION_SEEDED_RECOMMENDATIONS]: (state, action) => ({
    ...state,
    locationSeededRecommendations: {
      ...state.locationSeededRecommendations,
      [action.destinationKey]: {
        offerIds: [],
        state: 'error',
        error: action.error,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_PEOPLE_LIKE_ME]: (state, action) => ({
    personalisedPeopleLikeMe: {
      ...state.personalisedPeopleLikeMe,
      state: 'error',
      error: action.error,
      lereVersion: undefined,
    },
  }),
  [FETCH_LERE_TOP_DEALS]: (state, action) => ({
    ...state,
    topDeals: {
      offerIds: [],
      state: 'error',
      error: action.error,
      lereVersion: undefined,
    },
  }),
  [FETCH_LERE_HERO_PLANNER]: (state, action) => ({
    ...state,
    topDeals: {
      offerIds: [],
      state: 'error',
      error: action.error,
      lereVersion: undefined,
    },
  }),
  [FETCH_LERE_HIGH_INTENT]: (state, action) => (
    state.highIntent.state === 'done' ? state : {
      ...state,
      highIntent: {
        ...state.highIntent,
        state: 'error',
        error: action.error,
      },
    }
  ),
  [FETCH_LERE_PERSONALISED_ALTERNATIVES]: (state, action) => (
    state.personalisedAlternatives.state === 'done' ? state : {
      personalisedAlternatives: {
        ...state.personalisedAlternatives,
        state: 'error',
        error: action.error,
      },
    }
  ),
  [FETCH_LERE_PERSONALISED_ALTERNATIVES_WITH_OFFER_ENQUIRES]: (state, action) => ({
    personalisedAlternativesWithOfferEnquiries: {
      ...state.personalisedAlternativesWithOfferEnquiries,
      [action.key]: {
        offers: EmptyArray,
        state: 'error',
        error: action.error,
        lereVersion: undefined,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_TOUR_ENQUIRES]: (state, action) => ({
    personalisedTours: {
      ...state.personalisedTours,
      [action.key]: {
        offers: EmptyArray,
        state: 'error',
        error: action.error,
        lereVersion: undefined,
      },
    },
  }),
  [FETCH_LERE_PERSONALISED_PREFERENCES]: (state, action) => (
    state.personalisedPreferences.state === 'done' ? state : {
      personalisedPreferences: {
        ...state.personalisedPreferences,
        state: 'error',
        error: action.error,
      },
    }
  ),
  [FETCH_LERE_OFFER_STATS]: (state, action) => ({
    offerStats: {
      ...state.offerStats,
      [action.offerKey]: {
        stats: undefined,
        state: 'error',
        error: action.error,
      },
    },
  }),
  [FETCH_LERE_TRENDING_DESTINATIONS]: (state, action) => ({
    trendingDestinations: {
      ...state.trendingDestinations,
      state: 'error',
      error: action.error,
    },
  }),
  [FETCH_LERE_NEARBY_OFFERS]: (state, action) => ({
    nearbyOffers: {
      ...state.nearbyOffers,
      [action.key]: {
        offers: [],
        state: 'error',
        error: action.error,
      },
    },
  }),
  [FETCH_LERE_TOUR_RECOMMENDATIONS]: (state, action) => ({
    tour: {
      ...state.tour,
      [action.key]: {
        offers: [],
        state: 'error',
        error: action.error,
      },
    },
  }),
  [FETCH_LERE_REGION_RECOMMENDATIONS]: (state, action) => ({ ...state, [action.key]: { offers: [], state: 'error', error: action.error, lereVersion: undefined } }),
})

const recommendationReducer = createReducer<App.RecommendationState>(initialRecommendationState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [SET_OFFER_LERE_INLINE_YMAL_IN_VIEW]: (state, action) => {
    const offerId = action.payload.offerId
    return {
      ...state,
      offerSharedState: {
        [offerId]: {
          ymalInlineInView: action.payload.ymalInlineInView,
          checkIn: state.offerSharedState[offerId]?.checkIn,
          checkOut: state.offerSharedState[offerId]?.checkOut,
          duration: state.offerSharedState[offerId]?.duration,
          rooms: state.offerSharedState[offerId]?.rooms,
        },
      },
    }
  },
  [SET_OFFER_CHECK_IN_CHECK_OUT]: (state, action) => {
    const offerId = action.payload.offerId
    return {
      ...state,
      offerSharedState: {
        [offerId]: {
          ymalInlineInView: state.offerSharedState[offerId]?.ymalInlineInView ?? false,
          checkIn: action.payload.checkIn,
          checkOut: action.payload.checkOut,
          duration: action.payload.duration,
          rooms: action.payload.rooms,
        },
      },
    }
  },
  [CLEAR_OFFER_CHECK_IN_CHECK_OUT]: (state, action) => {
    const updatedState: App.RecommendationState = {
      ...state,
      offerSharedState: {
        ...state.offerSharedState,
      },
    }
    delete updatedState[action.payload.offerId]
    return updatedState
  },
  [SET_LERE_VERSION]: (state, action) => {
    return {
      ...state,
      lereVersion: action.lereVersion,
    }
  },
  [OFFER_CALENDAR_RECOMMENDATIONS_CLEAR]: (state) => {
    const newState: App.RecommendationState = { ...state }
    newState.calendarRecommendations = { state: 'loading', offerIds: [] }
    return newState
  },
  // save recently viewed
  [OFFER_VIEWED]: (state, action) => {
    const newView: App.RecentlyViewedOffer = action.view

    // only update RV if there is no other HI types
    // because RV is the lowerest priority
    let highIntentOffers = state.highIntent.offers
    const existingHighIntent = highIntentOffers.find(offer => offer.offerId === newView.offerId)
    if (!existingHighIntent || isRecentlyViewed(existingHighIntent)) {
      // order is maintained, dups are removed from the end
      highIntentOffers = uniqueBy([newView, ...highIntentOffers], offer => offer.offerId)
    }

    return {
      highIntent: {
        ...state.highIntent,
        lastViewed: newView,
        offers: highIntentOffers,
      },
    }
  },
  [LERE_SAVE_HOT_LEADS]: (state, action) => {
    const existingHighIntent = state.highIntent.offers.find(offer => offer.offerId === action.hotLead.offerId)

    // only update HL if no existing AC
    if (!existingHighIntent || !isAbandonedCart(existingHighIntent)) {
      return {
        highIntent: {
          ...state.highIntent,
          offers: uniqueBy([action.hotLead, ...state.highIntent.offers], (offer) => offer.offerId),
        },
      }
    }
    return state
  },
  [LERE_SAVE_ABANDONED_CART]: (state, action) => {
    // only keep one item per main offer id
    const offers: Array<App.HighIntentOffer> = uniqueBy(
      [action.cart, ...state.highIntent.offers],
      (offer: App.HighIntentOffer) => offer.offerId,
    )

    return {
      highIntent: {
        ...state.highIntent,
        offers,
      },
    }
  },
  [USER_LOGOUT]: () => ({ // clear personal recos when user logout
    highIntent: initialRecommendationState.highIntent,
  }),
  [LERE_REMOVE_HIGH_INTENT]: (state, action) => {
    return {
      highIntent: {
        ...state.highIntent,
        offers: state.highIntent.offers.filter(
          offer => !(action.offerIds.includes(offer.offerId) && action.highIntentCategories.includes(offer.category)),
        ),
      },
    }
  },
  [LERE_DISMISS_ALL_STICKY_ABANDONED_CART]: (state) => {
    const [carts, rest] = partitionBy(state.highIntent.offers, isAbandonedCart)

    if (carts.length) {
      const newCarts = carts.map(cart => ({
        ...cart,
        isDismissed: true,
      }))
      return {
        highIntent: {
          ...state.highIntent,
          offers: [...newCarts, ...rest],
        },
      }
    }

    return state
  },
  [LERE_REMOVE_ABANDONED_CART]: (state, action) => {
    const idsToRemove: Array<string> = action.offerIds
    const newOffers = state.highIntent.offers.filter(offer => !idsToRemove.includes(offer.offerId))
    return {
      highIntent: {
        ...state.highIntent,
        offers: newOffers,
      },
    }
  },
  [GET_LOCAL_HIGH_INTENT_OFFERS]: (state, action) => ({
    ...state,
    highIntent: {
      ...state.highIntent,
      offers: uniqueBy([...state.highIntent.offers, ...action.offers], offer => offer.offerId),
      state: 'done',
      error: null,
      lereVersion: 'local',
    },
  }),
  [LERE_SAVE_DEFAULT_RECOMMENDATION]: (state, action) => ({
    defaultRecommendation: {
      ...state.defaultRecommendation,
      ...action.defaultRecommendation,
    },
  }),
  [LERE_RESET_PERSONALISED_PREFERENCES]: () => ({
    personalisedPreferences: {
      offers: [],
      state: 'loading',
    },
  }),
})

export default recommendationReducer
