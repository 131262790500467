import React, { useCallback, useContext, useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import AspectRatio from 'components/utils/AspectRatio'
import { ImageParams } from 'components/Common/ResponsiveImage'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import HeroCruiseOfferDetails from './HeroCruiseOfferDetails'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import HeroOfferTileWrapper from './HeroOfferTileWrapper'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

interface Props {
  offer: App.CruiseOffer | App.Offer | App.OfferSummary;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
}

const imageParams: ImageParams = {
  mobileAspectRatio: '3:2',
  tabletAspectRatio: '16:9',
  desktopAspectRatio: '19:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
  quality: 'good',
}

function HeroCruiseOfferTile(props: Props) {
  const {
    offer,
    eagerLoadFirstImage,
  } = props

  const isMobileDevice = useIsMobileDevice()
  // Swiping through the offer tiles is tough when the image carousel can also swipe. So turn off the image carousel for mobile devices
  const images = useMemo(() => isMobileDevice ? [offer.images[0]] : offer.images, [isMobileDevice, offer.images])
  const isFlash = offer.type === 'tour'

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return (
    <ProductPaletteProvider product={offer}>
      <HeroOfferTileWrapper>
        <AspectRatio ratio="3:2" tabletRatio="16:9" desktopRatio="19:9" fillHeight>
          <ImageCarousel
            images={images}
            imageParams={imageParams}
            eagerLoadFirstImage={eagerLoadFirstImage}
            topLeft={<LabelGroup>
              {isFlash && <ProductTypeLabel productType="cruise-flash" />}
            </LabelGroup>}
            topRight={<TripGuard>
              <CSSBreakpoint max="tablet">
                <BookmarkButton offer={offer as App.Offer} />
              </CSSBreakpoint>
            </TripGuard>}
            onImageLoad={handleImageLoaded}
          />
        </AspectRatio>
        <HeroCruiseOfferDetails offer={offer} />
      </HeroOfferTileWrapper>
    </ProductPaletteProvider>
  )
}

export default HeroCruiseOfferTile
