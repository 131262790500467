import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, PropsWithChildren, ComponentProps, MouseEventHandler, useContext } from 'react'
import ReactModal from 'react-modal'
import ModalSheet from './ModalSheet'
import ModalBackdrop from './ModalBackdrop'
import { MODAL_TRANSITION_DURATION } from './modalConstants'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { MasterModalRegistrationContext } from 'contexts/MasterModalContext'

type Props = Pick<ComponentProps<typeof ModalSheet>, 'mode' | 'size' | 'height' | 'tabletHeight'> & {
  /**
   * @deprecated do not use this directly, please use the `showModal()` syntax instead via ModalContext
   */
  isOpen?: boolean
  className?: string
  /**
   * Controls the following:
   * - Display the close button
   * - Close the modal on backdrop click
   * - Close the modal on Esc key
   * @default true
   */
  dismissible?: boolean
  onAfterClose?: () => void
  onAfterOpen?: () => void
  onClose?: MouseEventHandler<HTMLElement>
  /**
   * Use this to identify your modal when registering with the master modal context
   */
  modalId?: string
}

const ModalBase = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    size = 'S',
    mode = 'default',
    children,
    className,
    height,
    tabletHeight,
    isOpen,
    dismissible = true,
    modalId,
    onAfterClose,
    onAfterOpen,
    onClose,
  } = props

  const { registerModal, unregisterModal } = useContext(MasterModalRegistrationContext)
  const elementContext = useModalElementContext()
  const baseRef = useRef<HTMLDivElement>()
  const [hasOpened, setHasOpened] = useState(false)
  const [id, setId] = useState<string | undefined>(modalId)
  const actuallyOpen = typeof isOpen === 'undefined' ? elementContext?.open : isOpen

  useEffect(() => {
    return () => {
      // always unregister our modal on unmount
      if (id) {
        unregisterModal(id)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => { setHasOpened(false) }, [actuallyOpen])

  const afterOpen = useCallback(() => {
    setHasOpened(true)

    const localId = registerModal(id)
    setId(localId)

    onAfterOpen?.()
  }, [registerModal, id, onAfterOpen])

  useImperativeHandle(ref, () => baseRef.current!)

  const handleClose = useCallback((event) => {
    event.stopPropagation()
    if (onClose && hasOpened) {
      onClose(event)
    } else {
      elementContext?.resolve()
    }
  }, [elementContext, onClose, hasOpened])

  const afterClose = useCallback(() => {
    // always call after close, this is what tells the modal context to clean up the element
    elementContext?.afterClose()
    onAfterClose?.()
    if (id) {
      unregisterModal(id)
    }
  }, [elementContext, id, onAfterClose, unregisterModal])

  return <ReactModal
    contentElement={(props, children) => <ModalSheet
      {...props}
      mode={mode}
      size={size}
      height={height}
      tabletHeight={tabletHeight}
      transition={actuallyOpen ? 'in' : 'out'}
    >
      {children}
    </ModalSheet>}
    shouldCloseOnOverlayClick={dismissible}
    overlayElement={(props, contentElement) => <ModalBackdrop
      {...props}
      transition={actuallyOpen ? 'in' : 'out'}
      mode={mode}
    >
      {contentElement}
    </ModalBackdrop>}
    className={className}
    closeTimeoutMS={MODAL_TRANSITION_DURATION}
    contentRef={(node) => { baseRef.current = node }}
    isOpen={actuallyOpen}
    shouldCloseOnEsc={dismissible}
    shouldReturnFocusAfterClose={false}
    onAfterClose={afterClose}
    onRequestClose={handleClose}
    onAfterOpen={afterOpen}
  >
    {children}
  </ReactModal>
})

ModalBase.displayName = 'ModalBase'

export default ModalBase
