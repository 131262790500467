import { UpdateAirportTransferPayload } from 'api/experiences'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { UpdateAirportTransferFormFields } from '../MyEscapesAirportTransfersPage'

export function formatTransferTravellers(
  travellers: App.OrderTransferItemTraveller,
) {
  let travellerText = formatOccupantsShort({ adults: travellers.adults, children: travellers.children })
  if (travellers.children > 0 && (travellers.childSeats > 0 || travellers.boosterSeats > 0)) {
    const seats = [
      travellers.childSeats > 0 ? pluralizeToString('child seat', travellers.childSeats) : undefined,
      travellers.boosterSeats > 0 ? pluralizeToString('booster seat', travellers.boosterSeats) : undefined,
    ].filter(Boolean)
    travellerText += ` (${seats.join(', ')})`
  }
  return travellerText
}

export function buildAirportTransferPayload(fields: UpdateAirportTransferFormFields) {
  const { flightNumber, date, time, specialRequests, travellers } = fields
  const { adults, children, childSeats, boosterSeats } = travellers

  const payload: UpdateAirportTransferPayload = {
    op: 'update_airport_transfer',
    data: {
      flightNumber,
      day: date,
      time,
      specialRequests,
      adults,
      children,
      childSeats,
      boosterSeats,
    },
  }
  return payload
}

export function airportTransfersUpdateRequired(airportTransfers: Array<App.OrderTransferItem>) {
  return airportTransfers.some(airportTransferUpdateRequired)
}

export function airportTransferUpdateRequired(airportTransfer: App.OrderTransferItem) {
  // because I have seen instances of all of these.
  return (
    airportTransfer.flightNumber == null ||
    airportTransfer.flightNumber === '' ||
    airportTransfer.date == null ||
    airportTransfer.date === '' ||
    airportTransfer.time == null ||
    airportTransfer.time === ''
  )
}

export function isRoundTripAirportTransfer(airportTransfers: Array<App.OrderTransferItem>): boolean {
  // First iteration of airport transfer updates only handles one way or return transfer.
  // I.e. the ones with the form on the experience page.
  if (airportTransfers.length > 2) {
    return false
  }

  const toHotelItems = airportTransfers.filter(item => item.type === 'AIRPORT-TO-HOTEL')
  const toAirportItems = airportTransfers.filter(item => item.type === 'HOTEL-TO-AIRPORT')

  return !(toHotelItems.length > 2 || toAirportItems.length > 2)
}
