import TextInput from 'components/Common/Form/Input/TextInput'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import BodyText from 'components/Luxkit/Typography/BodyText'
import React, { useRef, useState } from 'react'
import noop from 'lib/function/noop'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import Group from 'components/utils/Group'
import styled from 'styled-components'
import { rem } from 'polished'
import LoadingSpinner from 'components/Common/Loading/LoadingSpinner'

interface Props {
  onSelect?: (option: any) => void;
  affiliationOptions?: Array<string>;
  loading?: boolean;
}

const StyledGroup = styled(Group)`
  padding: ${rem(8)};
  padding-top: 0;
`

function AgentHubAffiliationAutocomplete(props: Props) {
  const {
    onSelect = noop,
    affiliationOptions,
    loading,
  } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<Array<string>>(affiliationOptions || [])
  const [isFocused, setIsFocused] = useState(false)

  const noResultsFound = filteredOptions.length < 1

  const onItemSelect = (value: string) => {
    onSelect(value)
    setSearchPhrase(value)
    setIsFocused(false)
  }

  const handleOnFocus = () => {
    setSearchPhrase('')
    setIsFocused(true)
  }

  const handleClickOutside = () => {
    setIsFocused(false)
  }

  const handleChange = (value: string) => {
    setSearchPhrase(value)

    if (value.length < 1) {
      setFilteredOptions(affiliationOptions)
      return
    }

    setFilteredOptions(affiliationOptions?.filter(option => option.toLowerCase().includes(value.toLowerCase())) || [])
  }

  return <>
    <TextInput
      label="Affiliation"
      name="affiliation"
      autoComplete="off"
      onChange={(e) => handleChange(e.currentTarget.value)}
      endIcon={<LineSearchIcon />}
      ref={inputRef}
      disabled={loading}
      startIcon={loading && <LoadingSpinner />}
      placeholder="Affiliation"
      onFocus={handleOnFocus}
      required
      value={searchPhrase}
    />
    {inputRef?.current && (
      <DropdownList
        size="fill-anchor"
        open={isFocused}
        anchorRef={inputRef}
        triggerRef={inputRef}
        onClose={handleClickOutside}
      >
        <div>
          {filteredOptions.map(option => (
            <ListItem
              key={option}
              selected={option === searchPhrase}
              onClick={() => onItemSelect(option)}
              size="medium"
              title={option}
            />
          ))}
        </div>

        {noResultsFound && (
          <StyledGroup direction="vertical" gap={4}>
            <BodyText variant="medium" colour="neutral-two">
              Sorry, we couldn’t find results for{' '}
              <BodyText
                colour="neutral-one"
                as="span"
                variant="medium"
                weight="semi-bold"
              >
                '{searchPhrase}'
              </BodyText>
              .
            </BodyText>
            <BodyText variant="medium" colour="neutral-two">
              Try a different search phrase
            </BodyText>
          </StyledGroup>
        )}

      </DropdownList>
    )}
  </>
}

export default AgentHubAffiliationAutocomplete
