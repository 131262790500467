import { sum } from 'lib/array/arrayUtils'

export function getCalculatedInsuranceViewPricing(selectedPolicyIds: Set<string>, quote: App.InsuranceQuote) {
  if (!selectedPolicyIds.size) {
    return {
      price: quote.mobileAppOnlyPrice > 0 ? quote.mobileAppOnlyPrice : quote.total,
      memberPrice: quote.luxPlusTotal,
      mobileAppOnlyDiscount: quote.mobileAppOnlyPrice > 0 ? quote.total - quote.mobileAppOnlyPrice : 0,
    }
  }
  const quotesOfSelectedPolicies = quote.policyQuotes.filter(policyQuote => selectedPolicyIds.has(policyQuote.policy))
  return {
    price: sum(quotesOfSelectedPolicies, policy => policy.mobileAppOnlyPrice > 0 ? policy.mobileAppOnlyPrice : policy.price),
    memberPrice: sum(quotesOfSelectedPolicies, policy => policy.luxPlusPrice),
    mobileAppOnlyDiscount: sum(quotesOfSelectedPolicies, policy => policy.mobileAppOnlyPrice > 0 ? policy.price - policy.mobileAppOnlyPrice : 0),
  }
}
