import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import LinePhoneIcon from '../../../../Luxkit/Icons/line/LinePhoneIcon'
import TextLink from '../../../../Luxkit/TextLink'
import MessageBanner from '../../../../Luxkit/Banners/MessageBanner'

interface Props {
  localContact: App.GeoContact;
}

const PhoneBookingSection = styled.div`
  display: flex;
  flex-grow: 1;
`

const PhoneBookingBanner = styled(MessageBanner)`
  display:flex;
  flex-grow: 1;
`

function OfferTilePhoneBookingSection({ localContact }: Props) {
  const redirectToContact = () => {
    window.location.href = `tel:${localContact.number}`
  }

  return (
    <PhoneBookingSection onClick={redirectToContact}>
      <PhoneBookingBanner
        kind="promotional-primary"
        icon={<LinePhoneIcon />}
        description={
          <>
            <b>Interested in booking this property?</b> Call us at <TextLink href={`tel:${localContact.number}`}> {localContact.humanReadable} </TextLink>
          </>
        }
      />
    </PhoneBookingSection>
  )
}

const mapStateToProps = (state: App.State) => ({
  localContact: state.geo.currentContact.local,
})

export default connect(mapStateToProps)(OfferTilePhoneBookingSection)
