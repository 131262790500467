import React, { useCallback } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { useDecimalFormatter } from 'hooks/useNumberFormatters'
import { nonNullable, sum } from 'lib/array/arrayUtils'
import { capitalise } from 'lib/string/stringUtils'
import Plural from 'components/utils/Formatters/Plural'
import { getPlural } from 'lib/string/pluralize'

interface Props {
  tier: App.LuxLoyaltyTier;
  mode?: 'logged-out-join' | 'logged-in-join' | 'logged-out-join-offer' | 'logged-out-offer-preview' | 'checkout';
  /**
   * Points earned on purchase
   * @default 0
   */
  orderPointsEarned?: number;
  /**
   * Status credits earned on purchase
   * @default 0
   */
  statusCreditsEarned?: number;
}

interface WelcomeCopy {
  title?: React.ReactNode;
  content?: Array<NonNullable<React.ReactNode>>;
}

function useLuxLoyaltyWelcomeCopy(props: Props): WelcomeCopy {
  const { tier, mode, orderPointsEarned = 0, statusCreditsEarned = 0 } = props
  const programConfigDetails = useAppSelector(state => state.luxLoyalty.programConfig?.configDetails)
  const decimalFormat = useDecimalFormatter()

  const tierConfig = programConfigDetails?.tiers[tier]
  const formattedTier = capitalise(tier)
  const formattedWelcomePointsEarned = decimalFormat(tierConfig?.welcomePoints ?? 0)
  const totalPointsEarned = sum([orderPointsEarned, tierConfig?.welcomePoints ?? 0])

  const getCopy = useCallback(() => {
    if (!programConfigDetails) return {}

    switch (mode) {
      case 'logged-out-join':
        return {
          content: [
            <>Enjoy a <b>{formattedWelcomePointsEarned} {programConfigDetails.currencyName}</b> welcome gift</>,
          ],
        }
      case 'logged-out-join-offer':
        return {
          content: [
            <>Earn <b><Plural singular={programConfigDetails.currencyName} count={orderPointsEarned} withCount /> + <Plural singular={programConfigDetails.statusCreditsName} count={statusCreditsEarned} withCount /></b> for this booking</>,
            <>Enjoy a <b>{formattedWelcomePointsEarned} {programConfigDetails.currencyName}</b> welcome gift</>,
          ],
        }
      case 'logged-out-offer-preview':
        return {
          content: [
            <>Earn <Plural singular={programConfigDetails.currencyName} count={orderPointsEarned} withCount /></>,
          ],
        }
      case 'logged-in-join':
        return {
          title: 'Join our FREE Loyalty Program to:',
          content: [
            <>Earn & redeem {getPlural(programConfigDetails.currencyName)} on every booking</>,
            <>{(tier === 'platinum') ? null : `Unlock ${tier === 'silver' ? 'Gold or ' : ''}`}Platinum status as you spend</>,
            <>Enjoy a <b>{formattedWelcomePointsEarned} {programConfigDetails.currencyName}</b> welcome gift</>,
          ],
        }
      case 'checkout':
        return {
          title: <>Earn <Plural singular={programConfigDetails.currencyName} count={totalPointsEarned} withCount /> on this order as a {formattedTier} Traveller</>,
          content: [
            <>Earn <b><Plural singular={programConfigDetails.currencyName} count={orderPointsEarned} withCount /> + <Plural singular={programConfigDetails.statusCreditsName} count={statusCreditsEarned} withCount /></b> for this booking</>,
            <>Earn & redeem {getPlural(programConfigDetails.currencyName)} on every booking</>,
            <>{(tier === 'platinum') ? null : `Unlock ${tier === 'silver' ? 'Gold or ' : ''}`}Platinum status as you spend</>,
            <>Enjoy a <b>{formattedWelcomePointsEarned} {programConfigDetails.currencyName}</b> welcome gift</>,
          ],
        }
      default:
        return {}
    }
  }, [
    programConfigDetails,
    mode,
    formattedWelcomePointsEarned,
    orderPointsEarned,
    statusCreditsEarned,
    tier,
    totalPointsEarned,
    formattedTier,
  ])

  const copy = getCopy()

  return {
    title: copy.title,
    content: copy.content ? nonNullable(copy.content) : undefined,
  }
}

export default useLuxLoyaltyWelcomeCopy
