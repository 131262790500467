import React, { useCallback, useContext } from 'react'
import PageBanner from 'components/Luxkit/Banners/PageBanner'
import ModalContext from 'contexts/ModalContext'
import BaliWarningModal from '../HeaderModals/BaliWarningModal'
import LineExclamationTriangleIcon from 'components/Luxkit/Icons/line/LineExclamationTriangleIcon'

function BaliWarningNotificationBanner() {
  const showModal = useContext(ModalContext)

  const handleOpenBaliWarningModal = useCallback(() => {
    showModal(<BaliWarningModal/>)
  }, [showModal])

  return <PageBanner
    kind="warning"
    align="center"
    icon={<LineExclamationTriangleIcon />}
    description={<>
      <strong>Important:</strong> Volcanic activity in Indonesia may impact bookings in Bali.{' '}
      Contact us if your travel plans are affected
    </>}
    secondaryActionButtonProps={{
      children: 'More info',
      onClick: handleOpenBaliWarningModal,
    }}
  >
  </PageBanner>
}

export default BaliWarningNotificationBanner
