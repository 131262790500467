import request from 'api/requestUtils'
import { actions } from '@luxuryescapes/contract-svc-agent'
import qs from 'qs'

type GetAffiliationListResponse = actions['affiliationListAvailable']['responses']['200']['content']['application/json']['result']

export async function getAgentAffiliationOptions(regionCode: App.AgentHubEnabledRegions): Promise<Array<string>> {
  const query = qs.stringify({
    region: regionCode,
  })
  const response = await request.get<App.ApiResponse<GetAffiliationListResponse>>(`/api/agent/available-affiliations?${query}`)
  const affiliationOptions = response.result.map(affiliation => affiliation.name)

  return affiliationOptions
}
