import clsx from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { EmptyArray } from 'lib/array/arrayUtils'
import { setAttribute } from 'lib/object/objectUtils'
import { css } from 'styled-components'
import { RequireAtLeastOne } from 'type-fest'

type ResponsivePaletteType = RequireAtLeastOne<{
  base?: App.PaletteType
  tabletAndUp?: App.PaletteType
  desktopAndUp?: App.PaletteType
}, 'base' | 'tabletAndUp' | 'desktopAndUp'>

export function themeClassName(paletteType: App.PaletteType | ResponsivePaletteType): string {
  if (typeof paletteType === 'string') return `app-theme-${paletteType}`
  return clsx(
    paletteType.base ? `app-theme-${paletteType.base}` : undefined,
    paletteType.tabletAndUp ? `app-theme-${paletteType.tabletAndUp}-tablet-and-up` : undefined,
    paletteType.desktopAndUp ? `app-theme-${paletteType.desktopAndUp}-desktop-and-up` : undefined,
  )
}

export function responsiveThemeCSSSelector(
  defaultStyle: ReturnType<typeof css>,
  inverseStyle: ReturnType<typeof css>,
) {
  return css`
    .${themeClassName('default')} & {
      ${defaultStyle}
    }
    .${themeClassName('inverse')} & {
      ${inverseStyle}
    }
    ${mediaQueryUp.tablet} {
      .${themeClassName({ tabletAndUp: 'default' })} & {
        ${defaultStyle}
      }
      .${themeClassName({ tabletAndUp: 'inverse' })} & {
        ${inverseStyle}
      }
    }
    ${mediaQueryUp.desktop} {
      .${themeClassName({ desktopAndUp: 'default' })} & {
        ${defaultStyle}
      }
      .${themeClassName({ desktopAndUp: 'inverse' })} & {
        ${inverseStyle}
      }
    }
  `
}

export function makeCSSVar(...name: Array<string>): App.CSSVar {
  const kebabCasedName = name.map(s => s.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase())
  return `--${kebabCasedName.join('-')}`
}

function isCSSVar(value: string): value is App.CSSVar {
  return value.startsWith('--')
}

export function readCSSVar(
  cssVar: App.CSSVar,
  /**
   * _**NOTE!**_:
   * Only CSSVar type fallbacks will chain recursively.
   * As soon as the first non-CSSVar is detected, the chaining will stop.
   */
  ...fallbacks: Array<App.CSSVar | string>
): App.CSSVarValue {
  if (!fallbacks.length) {
    return `var(${cssVar})`
  }

  const [fallback, ...restOfFallbacks] = fallbacks
  if (isCSSVar(fallback)) {
    return `var(${cssVar}, ${readCSSVar(fallback, ...restOfFallbacks)})`
  }

  return `var(${cssVar}, ${fallback})`
}

const defaultCSSVarValueMapper = (value: unknown) => String(value)

export function generateCSSVarEntries(
  valuesRecord: Record<string, unknown> | object,
  /**
   * Will be kebab-case
   */
  varKeyInitialDepth: string | Array<string> = EmptyArray,
  valueMapper: (value: unknown, keyDepth: Array<string>) => string = defaultCSSVarValueMapper,
): Array<[cssVar: App.CSSVar, value: string]> {
  const entries: Array<[cssVar: App.CSSVar, value: string]> = []

  if (Array.isArray(valuesRecord)) return entries

  for (const [key, value] of Object.entries(valuesRecord)) {
    if (value === undefined || value === null || value === Array.isArray(value)) {
      continue
    }

    const inferredDepth = Array.isArray(varKeyInitialDepth) ? [...varKeyInitialDepth, key] : [varKeyInitialDepth, key]
    if (typeof value === 'string' || typeof value === 'number') {
      entries.push([makeCSSVar(...inferredDepth), valueMapper(value, inferredDepth)])
    }
    if (typeof value === 'object') {
      entries.push(...generateCSSVarEntries(value, inferredDepth, valueMapper))
    }
  }
  return entries
}

export function mapObjectToCSSVarValues<T extends Record<string, unknown> | object>(
  valuesRecord: T,
  /**
   * Will be kebab-case
   */
  varKeyInitialDepth: string | Array<string> = EmptyArray,
): T {
  const mappedObject: T = {} as T

  if (Array.isArray(valuesRecord)) return mappedObject

  for (const [key, value] of Object.entries(valuesRecord)) {
    if (Array.isArray(value)) {
      continue
    }

    const inferredDepth = Array.isArray(varKeyInitialDepth) ? [...varKeyInitialDepth, key] : [varKeyInitialDepth, key]
    if (typeof value === 'object') {
      setAttribute(mappedObject, key, mapObjectToCSSVarValues(value, inferredDepth))
    } else {
      setAttribute(mappedObject, key, readCSSVar(makeCSSVar(...inferredDepth)))
    }
  }

  return mappedObject
}
