import React, { Fragment, useCallback, useContext, useMemo } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'

import { mediaQueryUp } from 'components/utils/breakpoint'
import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Pane from 'components/Common/Pane'
import { take, unique } from 'lib/array/arrayUtils'
import Image from 'components/Common/Image'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR } from 'constants/offer'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import OfferBadge from 'components/Common/OfferBadge'

import BundleOfferTilePricing from './Support/BundleOfferTilePricing'
import BundleOfferLocation from './BundleOfferLocation'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import LinePlusIcon from 'components/Luxkit/Icons/line/LinePlusIcon'
import { ImageParams } from 'components/Common/ResponsiveImage'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Main = styled(Pane)`
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-rows: ${rem(230)} auto;

  ${mediaQueryUp.tablet} {
    grid-template-rows: ${rem(360)} auto;
  }

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
  }
`

// TODO - BnS
const DetailSegment = styled.div`
  position: relative;
  padding: ${rem(24)} ${rem(20)} ${rem(20)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQueryUp.desktop} {
    flex: 1;
    text-align: center;
    padding: ${rem(56)} ${rem(16)} ${rem(16)};
  }
`

// TODO - BnS - Can use Group
const TopDetailSegment = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${rem(12)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(8)};
    }
  }
`

// TODO - BnS - Can use Group
const OfferTitle = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueryUp.desktop} {
     align-items: center;
  }
`

const OfferTitleText = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQueryUp.desktop} {
    margin-top: ${rem(40)};
    align-items: center;
  }
`

const OfferTitleBoxText = styled.div`
  display: flex;
  align-items: center;
  ${mediaQueryUp.desktop} {
    justify-content: center;
  }
`

const LogoWrapper = styled.div`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
    align-items: center;
  }
`

const Logo = styled(Image)`
  width: ${rem(90)};
  height: ${rem(60)};

  ${mediaQueryUp.desktop} {
    width: ${rem(150)};
    height: ${rem(100)};
  }
`

// TODO - BnS - Can use Group
const OfferContent = styled.div`
  > * + * {
    margin-top: ${rem(8)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(12)};
    }
  }
`

const OfferContentLabel = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${mediaQueryUp.desktop} {
    justify-content: center;
  }

  > * + * {
    margin-left: ${rem(4)};

    ${mediaQueryUp.desktop} {
      margin-left: ${rem(12)};
    }
  }
`

const BottomDetailSegment = styled.div`
  display: grid;
  grid-template-columns: 1fr ${rem(120)};
  margin-top: ${rem(12)};

  > * + * {
    margin-top: ${rem(16)};
  }

  ${mediaQueryUp.desktop} {
    grid-template-columns: 1fr;
    margin-top: 0;
  }
`

const MainImageSegment = styled.div`
  display: grid;
  gap: ${rem(4)};
  position: relative;

  ${mediaQueryUp.desktop} {
    &.additional {
      grid-template-rows: 1fr ${rem(120)};
    }
  }
`

const AdditionalImages = styled.div`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
    gap: ${rem(4)};
  }
`

const OfferOverlayContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(12)};
  left: ${rem(12)};
  display: flex;
`

const StyledOfferBadge = styled(OfferBadge)`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};
`

// TODO - BnS - Can use Group as parent
const ViewOfferButton = styled(TextButton)`
  align-self: flex-end;
`

const StyledOfferTilePricing = styled(BundleOfferTilePricing)`
  align-items: flex-start;

  ${mediaQueryUp.desktop} {
    align-items: center;
  }
`

interface Props {
  offer: App.BundleOffer;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
}

const imageParams: ImageParams = {
  aspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
}

const flashOfferTypes = [OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR]

function BundleOfferTile(props: Props) {
  const {
    offer,
    eagerLoadFirstImage,
    offerUrl,
  } = props

  const {
    property,
    name,
    lowestPriceBundlePackage,
  } = offer

  const showAdditionalImages = flashOfferTypes.includes(offer.type) && offer.images.length > 3

  const pricingSection = <>
    {lowestPriceBundlePackage &&
      <StyledOfferTilePricing
        offer={offer}
        pkg={lowestPriceBundlePackage}
        offerUrl={offerUrl}
      />}

    <ViewOfferButton kind="primary" fit="flex">View offer</ViewOfferButton>
  </>

  const bundledOffers = Object.values(offer.bundledOffers)

  const locations = useMemo(
    () => unique(
      bundledOffers.map(bundledOffer =>
        [bundledOffer.locationHeading, bundledOffer.locationSubheading]
          .filter(t => t)
          .join(', ')),
    ),
    [bundledOffers],
  )

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return (
    <Main type="clean">
      <MainImageSegment className={cn({ additional: showAdditionalImages })}>
        <ImageCarousel
          images={offer.images}
          imageParams={imageParams}
          eagerLoadFirstImage={eagerLoadFirstImage}
          onImageLoad={handleImageLoaded}
        />
        {showAdditionalImages && <AdditionalImages>
          {take(offer.images, 3, 1).map((image) => (
            <Image
              key={image.id}
              id={image.id}
              alt={name}
              height={120}
              width={245}
              loading="lazy"
              fit="center"
            />
          ))}
        </AdditionalImages>}
        <OfferOverlayContainer>
          <ProductTypeLabel productType={offer.productType} />
          <OfferPartnershipLabel offer={offer} />
        </OfferOverlayContainer>
        {offer.badge && <StyledOfferBadge badge={offer.badge} />}
      </MainImageSegment>
      <DetailSegment>
        <TopDetailSegment>
          <OfferTitle>
            <LogoWrapper>
              {bundledOffers.map((bundledOffer, index) => <Fragment key={bundledOffer.id}>
                {index > 0 && <>{' '}<LinePlusIcon />{' '}</>}
                <Logo id={bundledOffer.property.logoImageId} width="300" aspectRatio="3:2" alt={`${bundledOffer.property.name || 'Vendor'} logo`}/>
              </Fragment>)}
            </LogoWrapper>
            <OfferTitleText>
              <OfferTitleBoxText>
                {locations.map((location, index) => <Fragment key={location}>
                  {index > 0 && <LinePlusIcon size={12} />}
                  <BodyText weight="normal" variant="medium">{location}</BodyText>
                </Fragment>)}
              </OfferTitleBoxText>
              <OfferTitleBoxText>
                <BundleOfferLocation offer={offer} />
              </OfferTitleBoxText>
            </OfferTitleText>
          </OfferTitle>

          <OfferContent>
            {property && <BodyText variant="large">{offer.name}</BodyText>}
            {(!!offer.badge || offer.bundledWithFlightsOnly) &&
              <OfferContentLabel>
                {offer.badge && <OfferBadgeTag badge={offer.badge} />}
              </OfferContentLabel>
            }
          </OfferContent>
        </TopDetailSegment>

        <BottomDetailSegment>
          {pricingSection}
        </BottomDetailSegment>
      </DetailSegment>
    </Main>
  )
}

export default BundleOfferTile
