import { min } from 'lib/array/arrayUtils'
import { getKilometersDistanceFromLatLong } from 'lib/flights/getDistanceFromLatLong'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'

export interface TransferForm {
  toAirport: TransferFormTransfer;
  toHotel: TransferFormTransfer;
}

export interface SingleTransferForm {
  transfer: TransferFormTransfer;
}

export interface TransferFormTransfer {
  type: App.ExperienceTransferType;
  time: string;
  date: string;
  flightNumber: string;
  travellers: any;
}

export function formatTransferTravellers(
  travellers: App.ExperienceTransferTravellers,
) {
  let travellerText = formatOccupantsShort({ adults: travellers.adults, children: travellers.children })
  if (travellers.children > 0 && (travellers.childSeats > 0 || travellers.boosterSeats > 0)) {
    const seats = [
      travellers.childSeats > 0 ? pluralizeToString('child seat', travellers.childSeats) : undefined,
      travellers.boosterSeats > 0 ? pluralizeToString('booster seat', travellers.boosterSeats) : undefined,
    ].filter(Boolean)
    travellerText += ` (${seats.join(', ')})`
  }
  return travellerText
}

export function getValidTransferOptions(
  view: App.ExperienceTransferView,
  transfers: Array<App.ExperienceTransferOption>,
) {
  const { hotel, travellers } = view
  const childCount = travellers.children ?? 0
  const passengers = travellers.adults + childCount
  const childSeats = childCount > 0 ? travellers.childSeats ?? 0 : 0
  const boosterSeats = childCount > 0 ? travellers.boosterSeats ?? 0 : 0
  const hotelDistance = hotel.kmFromAirport ?? 0
  return transfers.filter(transfer => {
    return transfer.maxPassengers >= passengers &&
    transfer.maxTransferDistance >= hotelDistance &&
    (childSeats === 0 || transfer.hasChildSeats) &&
    (boosterSeats === 0 || transfer.hasBoosterSeats)
  })
}

export function findBestTransfer(
  view: App.ExperienceTransferView,
  transfers: Array<App.ExperienceTransferOption>,
): {
  bestTransfer?: App.ExperienceTransferOption,
  noTransferReason?: 'distance' | 'passengers' | 'childseats'
 } {
  const { hotel, travellers } = view
  if (hotel.kmFromAirport) {
    const passengers = travellers.adults + (travellers.children ?? 0)
    const validTransfers = getValidTransferOptions(view, transfers)

    if (validTransfers.length === 0) {
      // now work out why it's not valid, proritise distance first as it's the most important
      let reason
      if (transfers.some(transfer => transfer.maxTransferDistance >= hotel.kmFromAirport)) {
        // too far away
        reason = 'distance'
      } else if (transfers.some(transfer => transfer.maxPassengers >= passengers)) {
        // must have too many passengers
        reason = 'passengers'
      } else {
        // only reason left
        reason = 'childSeats'
      }

      return {
        bestTransfer: undefined,
        noTransferReason: reason,
      }
    }

    return {
      bestTransfer: min(validTransfers, transfer => transfer.price),
    }
  }

  return {}
}

export function getDistanceBetweenPropertyAndAirport(property?: App.OfferProperty | App.BedbankProperty, airport?: App.Airport) {
  if (property && airport) {
    return getKilometersDistanceFromLatLong(
      property.latitude,
      property.longitude,
      airport.latitude,
      airport.longitude,
    )
  }

  return undefined
}
