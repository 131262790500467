import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import eases from 'styles/tools/eases'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import cn from 'clsx'
import zIndex from 'styles/tools/z-index'
import { without } from 'lib/array/arrayUtils'
import Snackbar, { SnackbarKind } from 'components/Luxkit/Snackbar/Snackbar'
import { useLocation } from 'react-router'
import useMountedEffect from 'hooks/useMountedEffect'

const StickyWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${zIndex.notification};
  pointer-events: none;

  ${mediaQueryUp.tablet} {
    position: sticky;
    top: ${props => props.theme.layout.headerFloatingOffset};
  }
`

const PositionedSnackbar = styled.div`
  transition: transform 0.3s ${eases.power2.in}, opacity 0.3s ${eases.circ.in};
  transform: translateY(300%);
  pointer-events: none;
  opacity: 0;
  width: 100%;

  ${mediaQueryUp.tablet} {
    width: auto;
    max-width: ${rem(500)};
    min-width: ${rem(360)};
    transform: translateX(200%);
  }

  &.open {
    transform: translateX(0%) translateY(0%);
    pointer-events: auto;
    transition: transform 0.3s ${eases.power2.out}, opacity 0.3s ${eases.circ.out};
    opacity: 1;
  }
`

const AppSnackbarWrapper = styled.div`
  display: flex;
  right: 0;
  left: 0;
  bottom: 0;
  padding: ${rem(8)};
  position: absolute;
  overflow: hidden;

  ${mediaQueryUp.tablet} {
    justify-content: flex-end;
    padding: ${rem(20)};
    top: 0;
    bottom: unset;
    /* Extra room for shadow */
    padding-bottom: ${rem(80)};
  }
`

interface SnackbarOptions {
  heading?: string;
  action?: {
    label: string;
    onAction?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    href?: string;
    to?: string;
    testId?: string;
  };
  icon?: React.ReactNode | App.Image;
}

// Internal config type, do not export.
// Show snackbar is only only external way to interact with this element
interface AppSnackbarConfig {
  description: string;
  kind: SnackbarKind;
  heading?: string;
  icon?: React.ReactNode | App.Image;
  action?: {
    label: string;
    onAction?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
    href?: string;
    to?: string;
    testId?: string;
  };
}

export function showSnackbar(description: string, kind: SnackbarKind, options: SnackbarOptions = {}) {
  document.dispatchEvent(new CustomEvent<AppSnackbarConfig>('app-snackbar', {
    detail: {
      description,
      kind,
      ...options,
    },
  }))
}

/**
 * Renders snackbars in the appropriate place for the appropriate time that are queued with a snackbar
 *
 * Features include:
 * Will animate between snackbars as they are queued
 * Consistent timers based on content of snackbar
 * Hovering over snackbar will pause timer
 * Closes snackbar once a user interacts with it (when it has an action to interact with)
 */
function AppSnackbar() {
  // the queue for all snackbars that should be shown
  const [snackbarQueue, updateSnackbarQueue] = useState<Array<AppSnackbarConfig>>([])
  // The snackbar config we're currently showing
  const [visibleConfig, setVisibleConfig] = useState<AppSnackbarConfig>()
  // whether or not the snackbar should be open
  const [open, setOpen] = useState<boolean>(false)
  // data about the current running snackbar, allows us to pause/resume and cancel timers
  const timeoutRef = useRef<{ id?: number, timestamp?: number, remaining?: number}>({})
  const location = useLocation()

  useMountedEffect(() => {
    // close snackbars if the page changes, it's probably no longer relevant
    if (open) {
      setOpen(false)
    }
  }, [location.pathname])

  useEffect(() => {
    const onSnackbarEnqueue = (e: CustomEvent<AppSnackbarConfig>) => {
      // snackbar just came in, close existing one if we had one open
      window.clearTimeout(timeoutRef.current.id)
      setOpen(false)
      updateSnackbarQueue((oldQueue) => [...oldQueue, e.detail])
    }

    document.addEventListener('app-snackbar', onSnackbarEnqueue as any)

    return () => {
      document.removeEventListener('app-snackbar', onSnackbarEnqueue as any)
    }
  }, [])

  useEffect(() => {
    if (!visibleConfig) {
      const nextSnackbar = snackbarQueue[0]

      if (nextSnackbar) {
        setVisibleConfig(nextSnackbar)
        updateSnackbarQueue(without(snackbarQueue, nextSnackbar))
        setOpen(true)
      }
    }
  }, [snackbarQueue, visibleConfig])

  const element = useRef<HTMLDivElement>(null)
  const onTransitionEnd = useCallback((e: React.TransitionEvent<HTMLDivElement>) => {
    if (e.propertyName === 'transform' && e.target === element.current) {
      if (!open) {
        // we're closing it and animation has finished
        // so it must no longer be visible, remove it.
        setVisibleConfig(undefined)
      } else if (visibleConfig) {
        window.clearTimeout(timeoutRef.current.id)
        const duration = visibleConfig.action ? 8000 : 4000
        // just finished opening, start the timeout to close
        timeoutRef.current.id = window.setTimeout(() => setOpen(false), duration)
        timeoutRef.current.timestamp = new Date().getTime()
        timeoutRef.current.remaining = duration
      }
    }
  }, [open, visibleConfig])

  const onSnackbarActionClick = useCallback((e) => {
    // make sure we close the snackbar once a user has interacted with it
    window.clearTimeout(timeoutRef.current.id)
    timeoutRef.current.id = undefined
    setOpen(false)
    visibleConfig?.action?.onAction?.(e)
  }, [visibleConfig?.action])

  const pauseTimer = useCallback(() => {
    window.clearTimeout(timeoutRef.current.id)
    const duration = new Date().getTime() - (timeoutRef.current.timestamp ?? 0)
    // update remaining time with how much time has passed
    timeoutRef.current.remaining = (timeoutRef.current.remaining ?? 0) - duration
  }, [])

  const resumeTimer = useCallback(() => {
    if (timeoutRef.current.id) {
      timeoutRef.current.id = window.setTimeout(() => setOpen(false), timeoutRef.current.remaining)
      timeoutRef.current.timestamp = new Date().getTime()
      // remaining hasn't changed, no need to change it
    }
  }, [])

  return (
    <StickyWrapper>
      <AppSnackbarWrapper>
        <PositionedSnackbar
          ref={element}
          onTransitionEnd={onTransitionEnd}
          className={cn({ open })}
        >
          {visibleConfig && <Snackbar
            heading={visibleConfig.heading}
            kind={visibleConfig.kind}
            description={visibleConfig.description}
            action={visibleConfig.action?.label}
            onAction={onSnackbarActionClick}
            actionHref={visibleConfig.action?.href}
            actionTo={visibleConfig.action?.to}
            actionTestId={visibleConfig.action?.testId}
            icon={visibleConfig.icon}
            onMouseEnter={pauseTimer}
            onMouseLeave={resumeTimer}
          />}
        </PositionedSnackbar>
      </AppSnackbarWrapper>
    </StickyWrapper>
  )
}

export default React.memo(AppSnackbar)
