import { checkCanViewLuxPlusBenefits, isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import { useAppSelector } from 'hooks/reduxHooks'

export default function useBedbankDiscount(discount = 0, memberDiscount = 0): number {
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const luxPlusLPPEnabled = useAppSelector(isLuxPlusLPPEnabled)

  if ((memberDiscount ?? 0) > 0 && canViewLuxPlusBenefits && luxPlusLPPEnabled) {
    return memberDiscount
  }

  return discount
}
