export enum VillasContentSections {
    OVERVIEW = 'overview',
    ROOMS_AND_AMENITIES = 'rooms-and-amenities',
    LOCATION = 'location',
    REVIEWS = 'reviews',
    THINGS_TO_KNOW = 'things-to-know',
  }

export const VILLAS_DISCOUNT_LABEL_COUNT = 1

// Months
export const VILLAS_DISCOUNT_MAXIMUM_TRAVEL_PERIOD_RANGE = 18

export enum VillasDiscountSource {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export enum VillasDiscountType {
  LONG_STAY = 'long_stays',
  LAST_MINUTE = 'last_minute',
}

export const VILLAS_TOP_LOCATIONS = [
  { label: 'Australia', value: 'Australia' },
  { label: 'United States', value: 'USA' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'Bali', value: 'Bali' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Spain', value: 'Spain' },
]
