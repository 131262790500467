import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React from 'react'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import VillaCardInclusions from './VillaCardInclusions'
import LEOfferPriceDetails from './LEOfferPriceDetails'
import VillaLocationCaption from './VillaLocation'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary;
  onClick: () => void
  offerUrl: string;
  className?: string;
  size: 'small' | 'medium';
}

function VillaOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props

  if (offer.isSoldOut) return null

  if (size === 'medium') {
    return <CarouselCardMedium
      className={className}
      bookmarkButton={<BookmarkButton offer={offer} />}
      image={offer.image}
      location={<VillaLocationCaption offer={offer} />}
      title={offer.name}
      providerName={offer.property.name}
      priceDetails={<LEOfferPriceDetails variant="condensed" offer={offer} />}
      to={offerUrl}
      rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
      description={<VillaCardInclusions offer={offer} offerUrl={offerUrl} />}
      urgencyLabels={<LabelGroup>
        <OfferLabels offer={offer} />
        {offer.badge && <OfferBadgeTag badge={offer.badge} />}
      </LabelGroup>}
      onClick={onClick}
    />
  } else if (size === 'small') {
    return <CarouselCardSmall
      className={className}
      bookmarkButton={<BookmarkButton offer={offer} />}
      description={<VillaCardInclusions offer={offer} offerUrl={offerUrl} />}
      image={offer.image}
      location={<VillaLocationCaption offer={offer} />}
      title={offer.property.name}
      priceDetails={<LEOfferPriceDetails offer={offer} />}
      to={offerUrl}
      productType={offer.productType}
      rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
      urgencyLabels={<LabelGroup>
        <OfferLabels offer={offer} />
        {offer.badge && <OfferBadgeTag badge={offer.badge} />}
      </LabelGroup>}
      onClick={onClick}
    />
  }
  return null
}

export default VillaOfferCard
