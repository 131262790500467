import LabeledSheet from 'components/Luxkit/LabeledSheet'
import { checkCanViewLuxPlusBenefits, isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Clickable from 'components/Common/Clickable'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LuxPlusLabel from './LuxPlusLabel'
import ModalContext from 'contexts/ModalContext'
import LuxPlusBenefitsModal from './modals/LuxPlusBenefitsModal'
import LuxPlusLabelContext from 'contexts/LuxPlusLabelContext'

const ContentWrapper = styled.div`
  padding: ${rem(16)};
`

interface MappedProps {
  subscription: App.ProductSubscription;
  luxPlusLPPEnabled: boolean;
  canViewLuxPlusBenefits: boolean;
}

interface Props extends MappedProps {
  offerPrice: number;
  offerMemberPrice: number;
  hasLuxPlusRate?: boolean;
  hideUpsellModal?: boolean;
  offer: App.BedbankOffer | App.BedbankOfferSummary;
}

function LuxLPPPricingLabel(props: Props) {
  const {
    offerPrice,
    offerMemberPrice,
    luxPlusLPPEnabled,
    hasLuxPlusRate,
    canViewLuxPlusBenefits,
    offer,
  } = props
  const saveAmount = offerPrice - offerMemberPrice
  const showModal = useContext(ModalContext)
  const providerPage = useContext(LuxPlusLabelContext)

  const toggleModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    showModal(<LuxPlusBenefitsModal
      type="member-price-offer-lpp-no-save-amount"
      saveAmount={saveAmount}
      offer={offer}
      providerPage={providerPage}
    />)
  }, [offer, providerPage, saveAmount, showModal])

  if (!luxPlusLPPEnabled) return null

  return <div>
    {hasLuxPlusRate && <LabeledSheet
      kind="lux-plus"
      label={<LuxPlusLabel type="only-logo" />}
    >
      <ContentWrapper>
        <BodyTextBlock variant="medium" weight="bold" endIcon={canViewLuxPlusBenefits ? null : <Clickable onClick={toggleModal}><LineInfoCircleIcon /></Clickable>}>Member savings on select packages</BodyTextBlock>
      </ContentWrapper>
    </LabeledSheet>}
  </div>
}

export default connect((state: App.State): MappedProps => {
  return {
    luxPlusLPPEnabled: isLuxPlusLPPEnabled(state),
    subscription: state.luxPlus.products.subscription,
    canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
  }
})(LuxLPPPricingLabel)
