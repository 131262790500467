import Pane from 'components/Common/Pane'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const ErrorPane = styled(Pane)`
  padding: ${rem(12)};
`

interface Props {
  reason: 'passengers' | 'distance' | 'childseats';
}

function ExperienceTransferBookingOptionError(props: Props) {
  const { reason } = props

  return <ErrorPane type="light-grey">
    <VerticalSpacer gap={4}>
      <BodyText variant="medium" weight="bold">
        {reason === 'distance' && 'Sorry, there are no vehicles available for your transfer request.'}
        {reason === 'passengers' && 'Sorry, there are no vehicles available for the travellers selected.'}
        {reason === 'childseats' && 'Sorry, there are no vehicles available for the child seats selected.'}
      </BodyText>
      <BodyText variant="small" colour="neutral-two">
        {reason === 'distance' && 'Please update your transfer details and try again.'}
        {reason === 'passengers' && 'Please update the number of travellers and try again'}
        {reason === 'childseats' && 'Please update the number of child seats and try again'}
      </BodyText>
    </VerticalSpacer>
  </ErrorPane>
}

export default ExperienceTransferBookingOptionError
