import createSelector from 'lib/web/createSelector'
import { isBedbankItem } from '../lib/checkout/checkoutUtils'

import { BEDBANK_CALENDAR_FUTURE_DAYS } from 'lib/datetime/calendarUtils'
import { addDays, pad } from 'lib/datetime/dateUtils'
import { MONTH_NAMES } from 'constants/dates'
import { getBedbankRateKey } from 'checkout/lib/utils/bedbank/cart'
import { getBedbankItemView } from '../../checkout/lib/utils/bedbank/view'
import { sum } from 'lib/array/arrayUtils'
import { getAllOffers } from 'selectors/offerSelectors'
import { getExistingOrderExperienceCalendarDates } from 'lib/experiences/experienceUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { isLuxPlusLPPEnabled } from 'luxPlus/selectors/featureToggle'

interface ChangeDatesBaseCalendar {
  [monthKey: string]: App.BedbankCalendarMonth;
}

export const isBedbankOfferCached = createSelector(
  (state: App.State) => state.offer.offersLoading,
  (state: App.State) => state.offer.bedbankOffers,
  (state: App.State, offerId: string) => offerId,
  (offersLoading, offersLoaded, offerId) => !!(offersLoading[offerId] || offersLoaded[offerId]),
)

export const getPropertyIdIfBestOfferRedirectRequired = createSelector(
  (state: App.State, offerId: string) => state.offer.bedbankOffers[offerId],
  (offer) => offer?.property?.id,
)

export const getPropertyBestAlternateOfferIds = createSelector(
  (state: App.State, offerId: string) => getPropertyIdIfBestOfferRedirectRequired(state, offerId),
  (state: App.State) => state.offer.alternativeOffersForProperty,
  (propertyId, alternativeOffersForProperty) => {
    if (propertyId) {
      return alternativeOffersForProperty[propertyId]
    }
  },
)

export const getPropertyBestAlternateOffer = createSelector(
  (state: App.State, offerId: string) => getPropertyBestAlternateOfferIds(state, offerId),
  (state: App.State) => state.offer.offers,
  (alternatePropertyOffers, offers) => {
    if (alternatePropertyOffers?.le && alternatePropertyOffers.le.length > 0) {
      let alt = alternatePropertyOffers.le.find((offer:string) => offers[offer]?.type === 'hotel')
      if (!alt) {
        alt = alternatePropertyOffers.le[0]
      }
      if (offers[alt]) {
        return offers[alt]
      }
    }
  },
)

export const getPropertyBestAlternateURL = createSelector(
  (state: App.State, offerId: string) => getPropertyBestAlternateOffer(state, offerId),
  (state: App.State) => state.geo.currentRegionCode,
  (offer, regionCode) => {
    if (offer) {
      return `/${regionCode.toLowerCase()}/offer/${offer.slug}/${offer.id}`
    }
  },
)

export const getChangeDatesCalendar = createSelector(
  (state: App.State) => state.checkout.cart.items.filter(isBedbankItem),
  (state: App.State) => state.offer.bedbankOfferRates,
  (state: App.State) => state.businessTraveller.offersCredits,
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => getAllOffers(state),
  // This is temporary until LPP member pricing is fully released
  isLuxPlusLPPEnabled,
  (bedbankCartItems, bedbankRatesByOffer, offersCredits, postPurchase, existingOrder, allOffers, luxPlusLPPEnabled) => {
    const firstItem = bedbankCartItems[0]
    const offerId = firstItem?.offerId
    const duration = firstItem?.duration ?? 1
    const occupancy = bedbankCartItems.map(item => item.occupancy)
    const offer = allOffers[offerId] as App.BedbankOffer
    const experiencesWithDate = getExistingOrderExperienceCalendarDates(existingOrder)

    const output: ChangeDatesBaseCalendar = {}

    for (let day = 0; day <= BEDBANK_CALENDAR_FUTURE_DAYS; day++) {
      const currentDay = addDays(new Date(), day)
      // use local date format to avoid timezone jump issues
      const month = currentDay.getMonth()
      const year = `${currentDay.getFullYear()}`
      const date = currentDay.getDate()
      const monthText = MONTH_NAMES[month]
      const monthKey = `${year}-${pad(month + 1)}`
      const checkIn = moment(currentDay).format(ISO_DATE_FORMAT)
      const checkOut = moment(addDays(currentDay, duration ?? 1)).format(ISO_DATE_FORMAT)
      let bedbankRatesLoaded = false

      // check if rates already loaded in state
      const rateKey = getBedbankRateKey(occupancy, checkIn, checkOut)
      const bedbankOfferRates = bedbankRatesByOffer[offerId]?.[rateKey]
      bedbankRatesLoaded = bedbankRatesByOffer[offerId] && (rateKey in bedbankRatesByOffer[offerId])
      const bedbankOfferRate = bedbankOfferRates?.find(rate => rate.id === firstItem.roomRateId)
      let price

      if (bedbankOfferRate) {
        const itemViews = bedbankCartItems.map(item => {
          return getBedbankItemView(item, luxPlusLPPEnabled, offer, bedbankOfferRate, offersCredits, postPurchase, existingOrder, bedbankRatesLoaded)
        })
        price = sum(itemViews.map(item => item.data.price))
      }
      // create month key object if it doesn't exist
      if (!output[monthKey]) {
        output[monthKey] = {
          key: monthKey,
          month: monthText,
          year,
          days: [] as Array<App.BedbankCalendarDay>,
          hasPromo: false,
        }
      }

      // update with current day
      output[monthKey].days.push({
        day: date,
        month: monthText,
        year: year.toString(),
        checkIn,
        checkOut,
        hasPromo: false,
        price,
        availability: {
          loaded: bedbankRatesLoaded,
          hasAvailability: !!bedbankOfferRate,
        },
        hasExperiences: experiencesWithDate.has(checkIn),
      } as App.BedbankCalendarDay)
    }

    return output
  },
)
