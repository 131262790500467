import React, { useMemo } from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Group from 'components/utils/Group'
import getOfferFeatureIcon from 'lib/offer/getOfferFeatureIcon'
import { take } from 'lib/array/arrayUtils'
import styled from 'styled-components'
import { rem } from 'polished'
import determineOfferFeatureSymbol from 'lib/offer/determineOfferFeatureSymbol'

const AmenitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(var(--col-count, 1), 1fr);
  grid-row-gap: ${rem(8)};
  grid-column-gap: ${rem(16)};
  overflow: hidden;
  white-space: nowrap;
`

const StyledGroup = styled(Group)`
  margin-top: ${rem(12)};
`

interface Props {
  popularFacilities: Array<string>;
  showTitle?: boolean;
  columnCount?: number;
  showMore?: boolean;
}

function BedbankFacilities({ popularFacilities, showTitle = true, columnCount = 2, showMore = true }: Props) {
  const facilities = useMemo(() => {
    return take(popularFacilities, 6).map((facility) => {
      return {
        name: facility,
        Icon: getOfferFeatureIcon(determineOfferFeatureSymbol(facility)),
      }
    })
  }, [popularFacilities])

  return (<>
    {popularFacilities.length > 0 && <StyledGroup direction="vertical" gap={8}>
      {showTitle &&
        <BodyTextBlock variant="medium" weight="bold">
          Enjoy these top amenities:
        </BodyTextBlock>
  }
      <AmenitiesGrid style={{ ['--col-count' as string]: columnCount }}>
        {facilities.map((facility) => <BodyTextBlock
          key={facility.name}
          variant="medium"
          format="titlecase"
          startIcon={<facility.Icon />}
        >
          {facility.name}
        </BodyTextBlock>)}
      </AmenitiesGrid>
      {showMore &&
        <BodyTextBlock variant="medium" >
          + more amenities
        </BodyTextBlock>
 }
    </StyledGroup>
  }
  </>)
}

export default BedbankFacilities
