import React, { useContext } from 'react'

import BedbankBaseBookmarkButton from './BedbankBaseBookmarkButton'
import HotelBaseBookmarkButton from './HotelBaseBookmarkButton'

import { getDatesAndGuestsFromOfferPageState } from 'components/OfferPage/utils'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import OfferPageStateContext from 'contexts/OfferPage/offerPageStateContext'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import { isLEHotel } from 'lib/offer/offerTypes'
import { BookmarkDatesAndOccupancy } from 'tripPlanner/types/tripItem'

export interface Props {
  offer:
    | App.HotelOffer
    | App.HotelOfferSummary
    | App.BedbankOffer
    | App.BedbankOfferSummary
  roomTypeId?: string
  testId?: string
  price?: number | null
  value?: number | null
  iconOnly?: boolean
}

const getDatesAndGuests = (
  offerPageState: OfferPageState | undefined,
  globalSearchState: GlobalSearchState | undefined,
): BookmarkDatesAndOccupancy => {
  if (offerPageState) {
    return getDatesAndGuestsFromOfferPageState(offerPageState)
  }
  if (globalSearchState) {
    return {
      checkInDate: globalSearchState.checkinDate,
      checkOutDate: globalSearchState.checkoutDate,
      occupancies: globalSearchState.occupancies,
      durationInDays:
        globalSearchState.checkoutDate && globalSearchState.checkinDate ?
          globalSearchState.checkoutDate.diff(
            globalSearchState.checkinDate,
            'day',
          ) :
          undefined,
    }
  }

  return {}
}

function HotelBookmarkButton({
  offer,
  roomTypeId,
  testId,
  iconOnly = false,
}: Props) {
  const offerPageState = useContext(OfferPageStateContext)

  const globalSearchState = useContext(GlobalSearchStateContext)
  const datesAndGuests = getDatesAndGuests(offerPageState, globalSearchState)

  if (isLEHotel(offer)) {
    return (
      <HotelBaseBookmarkButton
        offer={offer}
        datesAndGuests={datesAndGuests}
        roomTypeId={roomTypeId}
        testId={testId}
        iconOnly={iconOnly}
      />
    )
  } else {
    return (
      <BedbankBaseBookmarkButton
        offer={offer}
        datesAndGuests={datesAndGuests}
        roomTypeId={roomTypeId}
        testId={testId}
        iconOnly={iconOnly}
      />
    )
  }
}

export default HotelBookmarkButton
