import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import React from 'react'

interface Props {
  name: string;
  descriptiveName?: string;
  showCompact?: boolean;
  withoutDescription?: boolean;
}

function OfferTilePropertyHeading(props: Props) {
  const { name, descriptiveName, showCompact, withoutDescription = false } = props

  return (
    <>
      <Heading variant={showCompact ? 'heading6' : 'heading5'}>{name}</Heading>
      {descriptiveName && !withoutDescription && <BodyText variant="medium">{descriptiveName}</BodyText>}
    </>
  )
}

export default OfferTilePropertyHeading
