import cn from 'clsx'
import React, { useEffect } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'

import { horizontalChildSpacing, verticalChildSpacing } from 'components/utils/spacing'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import ErrorBoundary from 'components/Common/ErrorBoundary'
import Group from 'components/utils/Group'
import Image from 'components/Common/Image'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

import { AccountAccessModalResult } from './AccountAccessModal'
import SocialLoginButton from './SocialLoginButton/SocialLoginButton'

const SocialContainer = styled.div`
  margin-bottom: ${rem(48)};

  ${verticalChildSpacing[12]}

  &.inline {
    display: flex;
    width: 100%;

    > * {
      flex: 1;
    }

    ${verticalChildSpacing[0]};
    ${horizontalChildSpacing[12]};
  }

  ${mediaQueryUp.tablet} {
    margin-bottom: 0
  }
`

const AppLinks = styled(ClickableLink)`
  display: block;
`

const Img = styled(Image)`
  max-width: 100%;
`

interface Props {
  mode: 'login' | 'join';
  isBlocked?: boolean;
  hideText?: boolean;
  inline?: boolean;
  classNameFacebook?: string;
  classNameGoogle?: string;
  renderId: string;
}

/**
 * Determines if we're currently in the facebook apps internal browser.
 *
 * It can sometimes have considerably different behaviour compared regular web browsers.
 * @returns Whether or not we're in the facebook app browser
 */
function isFacebookApp() {
  if (IS_SSR) {
    return false
  }

  const ua = window.navigator?.userAgent || window.navigator?.vendor || window?.opera
  return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)
}

function AccountAccessSocialButtons(props: Props) {
  const {
    isBlocked,
    classNameFacebook,
    classNameGoogle,
    mode,
    hideText,
    inline,
    renderId,
  } = props

  const isFBApp = isFacebookApp()

  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const loggedIn = useAppSelector(selectLoggedIn)

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  return (
    <SocialContainer className={cn({ inline })}>
      {isFBApp && (
        <Group direction="horizontal" horizontalAlign="center" gap={8}>
          <AppLinks href="https://app.luxuryescapes.com/3WHB/1aab092" target="_blank" rel="noopener">
            <Img id="App_Store_suxzv1" alt="Download the iPhone app" width={158}/>
          </AppLinks>
          <AppLinks href="https://app.luxuryescapes.com/3WHB/feb04c69" target="_blank" rel="noopener">
            <Img id="Google_Play_o1xn2t" alt="Download the Android app" width={158}/>
          </AppLinks>
        </Group>
      )}
      {!isFBApp && (
        <ErrorBoundary fallback={() => null} reportErrors>
          <SocialLoginButton
            mode={mode}
            isBlocked={isBlocked}
            className={classNameGoogle}
            source="google"
            hideText={hideText}
            renderId={renderId}
          />
          <SocialLoginButton
            mode={mode}
            isBlocked={isBlocked}
            className={classNameGoogle}
            source="apple"
            hideText={hideText}
          />
          <SocialLoginButton
            mode={mode}
            isBlocked={isBlocked}
            className={classNameFacebook}
            source="facebook"
            hideText={hideText}
          />
        </ErrorBoundary>
      )}
    </SocialContainer>
  )
}

export default AccountAccessSocialButtons
