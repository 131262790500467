import { allCountries, iso2Lookup } from 'country-telephone-data'

const countryData = (country) => ({
  prefixCountry: country.iso2.toUpperCase(),
  phonePrefix: country.dialCode,
})

function ensureNumericPrefix(prefix: string): string {
  if (!prefix || typeof prefix === 'string') {
    return prefix.replace(/^\++/g, '')
  } else {
    console.warn(`getPrefixCountry:ensureNumericPrefix:prefix is invalid: ${prefix}`)
    return ''
  }
}

export const getPrefixCountry = (currentCountryCode: string = '', phonePrefix: string = '') => {
  const numericPrefix = phonePrefix && ensureNumericPrefix(phonePrefix)

  // If user's current region matches prefix, return that
  const userCountry = allCountries[iso2Lookup[currentCountryCode.toLowerCase()]]
  if (userCountry && userCountry.dialCode == numericPrefix) {
    return countryData(userCountry)
  }

  // Otherwise, look up country based on prefix
  const countryForPrefix = allCountries.find(
    country => country.dialCode == numericPrefix && !country.priority)
  if (countryForPrefix) {
    return countryData(countryForPrefix)
  }

  // If prefix is invalid, return data for user's country
  if (userCountry) {
    return countryData(userCountry)
  }

  // If all else fails, return AU
  return countryData(allCountries[iso2Lookup.au])
}
