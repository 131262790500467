import { parseSearchString } from 'lib/url/searchUrlUtils'
import createSelector from 'lib/web/createSelector'

export const getImmersiveTripId = (state: App.State) =>
  state.tripPlanner.currentTripId

export const selectTripPlannerTemplateId = createSelector(
  (state: App.State) => state.router.location.search,
  (search): string => {
    const params = parseSearchString(search)
    return params.templateId
  },
)

export const selectTripPlannerTemplateItemId = createSelector(
  (state: App.State) => state.router.location.search,
  (search): string => {
    const params = parseSearchString(search)
    return params.templateItemId
  },
)
