import config from 'constants/config'
import React from 'react'
import {
  STEP_BOOK_DATES,
  STEP_CAPACITY,
  STEP_CHANGE_DATES,
  STEP_CHANGE_PACKAGE,
  STEP_DAY_CALENDAR,
  STEP_DEPARTING_FLIGHT,
  STEP_EXPERIENCES,
  STEP_MONTH_CALENDAR,
  STEP_PAYMENT,
  STEP_RETURNING_FLIGHT,
  STEP_TOUR_DATES,
} from 'constants/booking'
import { CompactFooter, OriginalFooter } from 'components/App/Footer'
import { Route, RouteComponentProps, Switch } from 'react-router'
import MinimalFooter from '../Footer/MinimalFooter'
import { BUSINESS_TRAVELLER_NO_FOOTER_ROUTES } from 'businessTraveller/routes/businessTravellerRoutes'
import { LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP } from 'agentHub/routes/AgentHubRoutes'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import styled from 'styled-components'
import { rem } from 'polished'

const ReservedFooterSpace = styled.div`
  /*
      Place space at the bottom to simulate some footer spacing
      This prevents overflow issues where by absolutely positioned elements
      at the bottom of the page MAY overflow the page and a cause a second scrollbar
      Now there is no way to overflow, as there is extra padding.
    */
  height: ${rem(40)};
`

const routesWithNoFooter = [
  `/booking/${STEP_CAPACITY}`,
  `/booking/${STEP_BOOK_DATES}`,
  `/booking/${STEP_CHANGE_DATES}`,
  `/booking/${STEP_CHANGE_PACKAGE}`,
  `/booking/${STEP_MONTH_CALENDAR}`,
  `/booking/${STEP_DAY_CALENDAR}`,
  `/booking/${STEP_DEPARTING_FLIGHT}`,
  `/booking/${STEP_RETURNING_FLIGHT}`,
  `/booking/${STEP_TOUR_DATES}`,
  `/booking/${STEP_EXPERIENCES}`,
  `/booking/${STEP_PAYMENT}`,
  `/bb/booking/${STEP_PAYMENT}`,
  '/account/my-escapes/experiences/:experienceItemId/voucher',
  '/map-view',
  '/search/map',
  '/search/homes-and-villas/map',
  '/search/experiences/map',
  '/gift-card/:code',
  '/travel-protection/quote',
  '/travel-protection/checkout',
  '/checkout',
  '/splashpage',
  '/travel-protection/details',
  '/travel-protection/payment',
  LE_AGENT_HUB_COMPLETE_ACCOUNT_SETUP,
  '/agent-hub/account-setup-completed',
  ...BUSINESS_TRAVELLER_NO_FOOTER_ROUTES,
]

// Use the footer type originally for the checkout page
const routesWithMinimalFooter = [
  '/trip-planner/trip',
  '/trip-planner/public-trip',
  '/trip-planner/curated/:templateId/summary',
  '/pay/:paymentLinkId',
]

const routesWithNoFooterMobileTablet = [
  '/support',
]

const pageRoute = (match, path) => `${match.path}${path}`

interface Props extends RouteComponentProps<any> {
}

function FooterRoutes(props: Props) {
  const { match } = props
  const headless = useAppSelector(state => state.config.headlessMode)

  const Footer = config.footer.type === 'original' ? OriginalFooter : CompactFooter
  return (
    <Switch>
      {routesWithNoFooter.map(path => (
        <Route path={pageRoute(match, path)} key={path} />
      ))}
      {routesWithMinimalFooter.map(path => (
        <Route path={pageRoute(match, path)} key={path} component={MinimalFooter} />
      ))}
      {routesWithNoFooterMobileTablet.map(path => (
        <Route path={pageRoute(match, path)} key={path} >
          <CSSBreakpoint min="desktop">
            <Footer />
          </CSSBreakpoint>
        </Route>
      ))}
      <Route exact path={pageRoute(match, '/')} component={Footer} />
      <Route>
        {headless && <ReservedFooterSpace aria-hidden="true" />}
        {!headless && <Footer />}
      </Route>
    </Switch>
  )
}

export default FooterRoutes
