import createSelector from 'lib/web/createSelector'
import {
  hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade,
  hasOptionalExtras,
  isPostPurchaseTourChangeDates,
  isPostPurchaseTourOptionalExperience,
  selectablePurchasableOptionsHasRoomTypeUpgrade,
  shouldShowLuxPlusUpsellForTour,
} from 'checkout/selectors/tourV2Selectors'
import { pushWithRegion } from 'actions/NavigationActions'
import { CheckoutPageId } from 'checkout/constants/pages'
import { getCurrentCheckoutPage } from 'checkout/actions/navigateActions'
import { getCheckoutPathWithCartId } from 'lib/url/pathsUtils'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'

const tourCheckoutSteps: { [key:string]: App.Checkout.CheckoutStep } = {
  package: {
    label: 'Tour package',
    key: CheckoutPageId.TourV2Upgrade,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  luxPlus: {
    label: LUXURY_PLUS.PROGRAM_NAME,
    key: CheckoutPageId.LuxPlus,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  extras: {
    label: 'Optional experiences',
    key: CheckoutPageId.TourV2OptionalExperience,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: true,
  },
  purchase: {
    label: 'Review & payment',
    key: CheckoutPageId.Purchase,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
  departureDates: {
    label: 'Departure Dates',
    key: CheckoutPageId.TourV2Departures,
    isRoute: true,
    subSteps: EmptyArray,
    showFooter: false,
  },
}

export const isStepTour = (step: string) => {
  return Object.values(tourCheckoutSteps).some(
    tourStep => tourStep.key === step && step !== tourCheckoutSteps.purchase.key,
  )
}

export const getTourCheckoutSteps = createSelector(
  (state: App.State) => getTourV2Items(state),
  hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade,
  selectablePurchasableOptionsHasRoomTypeUpgrade,
  hasOptionalExtras,
  shouldShowLuxPlusUpsellForTour,
  isPostPurchaseTourOptionalExperience,
  isPostPurchaseTourChangeDates,
  (
    tourItems,
    hasPackageUpgrade,
    hasRoomUpgrade,
    hasExtras,
    showLuxPlusUpsell,
    isPostPurchaseTourOptionalExperience,
    isPostPurchaseTourChangeDates,
  ): Array<App.Checkout.CheckoutStep> => {
    if (isPostPurchaseTourOptionalExperience) {
      return [tourCheckoutSteps.extras, tourCheckoutSteps.purchase]
    }
    if (isPostPurchaseTourChangeDates) {
      const steps = [tourCheckoutSteps.departureDates]
      if ((!!tourItems.length && tourItems[0].isOriginalItem) || hasPackageUpgrade) {
        steps.push(tourCheckoutSteps.package)
      }
      steps.push(tourCheckoutSteps.purchase)
      return steps
    }
    if (!tourItems.length) {
      return []
    }
    const steps = [] as Array<App.Checkout.CheckoutStep>
    if (hasPackageUpgrade || hasRoomUpgrade) {
      tourCheckoutSteps.package.showFooter = !!hasRoomUpgrade
      steps.push(tourCheckoutSteps.package)
    }
    if (showLuxPlusUpsell) steps.push(tourCheckoutSteps.luxPlus)
    if (hasExtras) steps.push(tourCheckoutSteps.extras)
    steps.push(tourCheckoutSteps.purchase)
    return steps
  },
)

export const navigateToTourCheckoutStep = (selectedStep?: string) => {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const steps = getTourCheckoutSteps(state)
    const firstStep = selectedStep ?? steps?.[0]?.key

    const url = getCheckoutPathWithCartId(selectedStep ?? firstStep, state.checkout.cart.cartId)

    if (firstStep) {
      dispatch(pushWithRegion(url))
    }
  }
}

export const navigateToNextStepTourCheckout = () => {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const steps = getTourCheckoutSteps(state)
    const currentStep = getCurrentCheckoutPage(state)
    const currentIndex = steps.findIndex(step => step.key === currentStep)
    const nextStep = steps?.[currentIndex + 1]
    if (nextStep) {
      const url = getCheckoutPathWithCartId(nextStep.key, state.checkout.cart.cartId)
      dispatch(pushWithRegion(url))
    }
  }
}

export const getCurrentTourCheckoutStep = createSelector(
  getTourCheckoutSteps,
  getCurrentCheckoutPage,
  (tourSteps, currentStep) => {
    const currentIndex = tourSteps.findIndex(step => step.key === currentStep)
    return tourSteps?.[currentIndex]
  },
)

export const getNextTourCheckoutStep = createSelector(
  getTourCheckoutSteps,
  getCurrentCheckoutPage,
  (tourSteps, currentStep) => {
    const currentIndex = tourSteps.findIndex(step => step.key === currentStep)
    return tourSteps?.[currentIndex + 1]
  },
)
