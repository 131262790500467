import config from 'constants/config'
import { sum } from 'lib/array/arrayUtils'
import { isFlightItem, isInsuranceItem, isLEHotelItem, isLuxPlusSubscriptionItem } from 'lib/checkout/checkoutUtils'
import { breakdownView } from 'checkout/selectors/view/generic'
import { checkoutTotalsView } from 'checkout/selectors/payment/checkout'
import { OFFER_TYPE_HOTEL } from 'constants/offer'
import { getHotelTotalPrice } from 'checkout/selectors/view/accommodation'
import { getInsuranceTotalPrice } from 'checkout/selectors/view/insurance'
import { getExperienceTotalPrice } from 'checkout/selectors/view/experience'
import { cartIncludesFlights, getFlightItemsView } from 'checkout/selectors/view/flights'
import { getInsuranceQuoteRequest } from 'checkout/selectors/request/insurance'

function getCookie(key) {
  const regex = new RegExp('(^| )' + key + '=([^;]+)')
  const match = document.cookie.match(regex)
  if (match) {
    return match[2]
  }
}

export function getSFMCCookies(): App.ISASFMCCookies {
  return {
    sfmcJobId: getCookie('sfmcJobID'),
    sfmcBatchId: getCookie('sfmcBatchID'),
    sfmcListId: getCookie('sfmcListID'),
    sfmcMemberId: getCookie('sfmcMemberID'),
    sfmcSubscriberId: getCookie('sfmcSubscriberID'),
    sfmcUrlId: getCookie('sfmcUrlID'),
    sfmcLinkAlias: getCookie('sfmcLinkAlias'),
  }
}

function getReservationsData(state: App.State): App.ISAReservationsData {
  const hotelItems : Array<App.Checkout.LEHotelItem> = state.checkout.cart.items.filter(isLEHotelItem)
  if (hotelItems.length === 0) {
    return {}
  }

  return {
    noOfPackages: hotelItems.length,
    isFamilyTrip: hotelItems.some(item => (!!item.occupancy?.children && item.occupancy?.children > 0) || (!!item.occupancy?.infants && item.occupancy?.infants > 0)),
    noOfAdults: sum(hotelItems.map(item => item.occupancy?.adults ?? 0)),
    noOfChildren: sum(hotelItems.map(item => item.occupancy?.children ?? 0)),
    noOfInfants: sum(hotelItems.map(item => item.occupancy?.infants ?? 0)),
  }
}

function getInsuranceData(state: App.State): App.ISAInsuranceData {
  const insurances = state.checkout.cart.items.filter(isInsuranceItem)
  if (insurances.length == 0) {
    return {
      insuranceAccepted: false,
    }
  }
  const insuranceData = getInsuranceQuoteRequest(state)
  const travellerCount = insuranceData?.travellers_details ? Object.values(insuranceData.travellers_details).reduce((acc, val): number => (acc ?? 0) + (val ?? 0), 0) || 0 : 0
  return {
    insuranceAccepted: true,
    insuranceNoOfTravellers: travellerCount,
    insuranceDestinationCountries: insuranceData?.destination_countries,
    insuranceQuotePrice: insuranceData?.total_price,
    insuranceEndDate: insuranceData?.trip_end_date ?? undefined,
    insuranceStartDate: insuranceData?.trip_start_date ?? undefined,
  }
}

function getFlightsData(flight: App.Checkout.FlightItemView): App.ISAFlightsData {
  const flightData: App.ISAFlightsData = {}

  if (!flight) {
    return flightData
  }

  const departingFlight = flight.flights[0]
  flightData.selectedFlightsPrice = flight.price
  flightData.departingFlightAirport = departingFlight?.journeyFlight.flights[0].departingAirportName
  flightData.departingFlightTime = departingFlight?.journeyFlight.flights[0].departingTime
  flightData.departingFlightDate = departingFlight?.journeyFlight.flights[0].departingDate
  flightData.departingFlightFareClass = departingFlight?.journeyFlight.flights[0].fareClass
  flightData.departingFlightCarrierName = departingFlight?.journeyFlight.flights[0].carrierName

  if (!flight.flights[1]) {
    return flightData
  }

  const returningFlight = flight.flights[1]
  flightData.returningFlightAirport = returningFlight.journeyFlight.flights[0].departingAirportName
  flightData.returningFlightTime = returningFlight.journeyFlight.flights[0].departingTime
  flightData.returningFlightDate = returningFlight.journeyFlight.flights[0].departingDate
  flightData.returningFlightFareClass = returningFlight.journeyFlight.flights[0].fareClass
  flightData.returningFlightCarrierName = returningFlight.journeyFlight.flights[0].carrierName
  flightData.flightsTotal = flight.price + flight.taxesAndFees + (flight.otherFees ? sum(Object.values(flight.otherFees)) : 0)

  return flightData
}

function getPromoData(state: App.State): App.ISAPromoData {
  const promo = state.checkout?.promo
  if (promo) {
    return {
      promoCode: promo?.code,
      promoDiscount: promo?.discountTotal,
      promoId: promo?.id,
    }
  }
  return {
    promoCode: '',
    promoDiscount: 0,
    promoId: '',
  }
}

export function baseData(state: App.State): App.ISABaseData {
  return {
    platform: state.interactionStudio?.platform,
    country_code: state.geo?.currentRegionCode,
    brand: config.BRAND,
  }
}

export function checkoutData(state: App.State): App.ISACheckoutData | undefined {
  const hotelItems : Array<App.Checkout.LEHotelItem> = state.checkout.cart.items.filter(isLEHotelItem)
  const subscriptionItems : Array<App.Checkout.LuxPlusSubscriptionItem> = state.checkout.cart.items.filter(isLuxPlusSubscriptionItem)
  if (hotelItems.length === 0 && subscriptionItems.length === 0) {
    return undefined
  }
  const flightBreakdownView = getFlightItemsView(state)
  return {
    offer_id: hotelItems.length > 0 ? hotelItems[0].offerId : 'subscription_item',
    package_id: hotelItems.length > 0 ? hotelItems[0].packageId : undefined,
    ...baseData(state),
    cartId: state.checkout.cart.cartId,
    currencyCode: state.checkout.cart.currencyCode,
    orderCompleted: false,
    withFlights: state.checkout.cart.items.filter(isFlightItem).length > 0,
    ...getReservationsData(state),
    ...getPromoData(state),
    ...getInsuranceData(state),
    accommodationTotal: getHotelTotalPrice(state),
    insuranceTotal: getInsuranceTotalPrice(state),
    addonsTotal: getExperienceTotalPrice(state),
    ...getSFMCCookies(),
    ...getFlightsData(flightBreakdownView.data[0]),
  }
}

export function getHotelGlobalCheckoutReservationsData(state: App.State): App.ISAReservationsData {
  const items = state.checkout.cart.items.filter(item => item.itemType === OFFER_TYPE_HOTEL)
  const reservationData = items.map(item => {
    const res = {} as App.ISAReservationsData
    if ('reservationType' in item) {
      res.reservationType = item.reservationType
    }
    if ('checkIn' in item && 'checkOut' in item) {
      res.hotelCheckInDate = item.checkIn
      res.hotelCheckOutDate = item.checkOut
    } else if ('startDate' in item && 'endDate' in item) {
      res.hotelCheckInDate = item.startDate
      res.hotelCheckOutDate = item.endDate
    }
    if ('occupancy' in item) {
      if (item.occupancy.children > 0 || item.occupancy.infants > 0) {
        res.isFamilyTrip = true
      }
      res.noOfAdults = item.occupancy.adults
      res.noOfChildren = item.occupancy.children
      res.noOfInfants = item.occupancy.infants
    }
    return res
  })
  const noOfAdults = sum(reservationData, (room) => room.noOfAdults)
  const noOfChildren = sum(reservationData, (room) => room.noOfChildren ?? 0)
  const noOfInfants = sum(reservationData, (room) => room.noOfInfants ?? 0)
  return {
    ...reservationData.find(item => item.hotelCheckInDate),
    noOfAdults,
    noOfChildren,
    noOfInfants,
  }
}

function getGlobalCheckoutInsuranceData(state: App.State): App.ISAInsuranceData {
  const insurance = state.checkout.cart.items.find(isInsuranceItem)
  if (!insurance?.productId) {
    return {
      insuranceAccepted: false,
    }
  }
  const insuranceQuote = state.insurance?.quotes?.find(quote => quote.productId === insurance.productId)
  return {
    insuranceAccepted: true,
    insuranceStartDate: insuranceQuote?.startDate,
    insuranceEndDate: insuranceQuote?.endDate,
    insuranceNoOfTravellers: insuranceQuote?.travellerCount,
    insuranceDestinationCountries: insuranceQuote?.destinationCountries,
    insuranceExcess: insuranceQuote?.excess,
    insuranceQuotePrice: insuranceQuote?.total,
  }
}

function getGlobalCheckoutTotals(state: App.State): { accommodationTotal: number, addonsTotal: number } {
  return {
    accommodationTotal: getGlobalCheckoutViewTotal(state),
    addonsTotal: getGlobalCheckoutViewTotal(state, 'Experiences'),
  }
}

function getGlobalCheckoutViewTotal(state: App.State, title: string = 'Accommodation'): number {
  const breakdownViewData = breakdownView(state)
  const totalView = checkoutTotalsView(state)
  const allViews = breakdownViewData.data.filter(item => item.title === title)
  const priceArray = allViews.map(view => sum(view.items, (item) => item.price))
  return priceArray.reduce((prev, nxt) => prev + nxt, 0) + (title === 'Accommodation' ? totalView.data.taxesAndFees : 0)
}

function getOfferId(state: App.State): string {
  for (const item of state.checkout.cart.items) {
    if ('offerId' in item) {
      return item.offerId
    } else if ('experienceId' in item) {
      return item.experienceId
    }
  }
}

function getPackageId(state: App.State): string {
  for (const item of state.checkout.cart.items) {
    if ('packageId' in item) {
      return item.packageId
    }
  }
}

export function globalCheckoutData(state: App.State): App.ISACheckoutData {
  const insuranceData = getGlobalCheckoutInsuranceData(state)
  return {
    ...baseData(state),
    ...getHotelGlobalCheckoutReservationsData(state),
    ...getPromoData(state),
    ...insuranceData,
    ...getSFMCCookies(),
    ...getGlobalCheckoutTotals(state),
    insuranceTotal: insuranceData?.insuranceQuotePrice ?? 0,
    flightsTotal: 0,
    offer_id: getOfferId(state),
    package_id: getPackageId(state),
    withFlights: cartIncludesFlights(state),
    cartId: state.checkout?.cart?.transactionKey,
    currencyCode: state.checkout?.cart?.currencyCode,
    orderCompleted: false,
  }
}

export function getReservationsDataFromOrder(order: App.Order): App.ISAReservationsData {
  const items = order.items.filter(item => item.reservation)
  const reservationData = items.map(item => {
    const res = {} as App.ISAReservationsData
    const reservation = item.reservation
    if ('reservationType' in item) {
      res.reservationType = item.reservationType
    }
    if ('startDate' in reservation && 'endDate' in reservation) {
      res.hotelCheckInDate = reservation.startDate
      res.hotelCheckOutDate = reservation.endDate
    }
    if (reservation.children > 0 || reservation.infants > 0) {
      res.isFamilyTrip = true
    }
    res.noOfAdults = reservation.adults
    res.noOfChildren = reservation.children
    res.noOfInfants = reservation.infants
    return res
  })
  const noOfAdults = sum(reservationData, (room) => room.noOfAdults)
  const noOfChildren = sum(reservationData, (room) => room.noOfChildren ?? 0)
  const noOfInfants = sum(reservationData, (room) => room.noOfInfants ?? 0)
  return {
    ...reservationData.find(item => item.hotelCheckInDate),
    noOfAdults,
    noOfChildren,
    noOfInfants,
  }
}

export function getGlobalPurchaseData(state: App.State): App.ISAPurchaseData {
  const res = {
    ...baseData(state),
    ...getPromoData(state),
    ...getGlobalCheckoutInsuranceData(state),
    ...getSFMCCookies(),
    offer_id: getOfferId(state),
    package_id: getPackageId(state),
    orderCompleted: true,
    cartId: state.checkout?.cart?.transactionKey,
    deviceId: state.interactionStudio.deviceId,
  }
  return res
}
