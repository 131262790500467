import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import TextLink from 'components/Luxkit/TextLink'
import Modal from 'components/Luxkit/Modal/Modal'
import config from 'constants/config'

function BaliWarningModal() {
  return (
    <Modal
      title="Booking Disruptions"
      primaryActionText="Yes, I understand"
    >
      <VerticalSpacer gap={20}>
        <BodyTextBlock variant="large">
          Due to recent volcanic eruptions in Bali, flights to and from the region
          may experience delays or cancellations. Our team is here to support
          you with guidance as needed.
        </BodyTextBlock>
        <BodyTextBlock variant="large" weight="bold">
          If your flight has been cancelled or changed:
        </BodyTextBlock>
        <BodyTextBlock variant="large">
          Please contact us for assistance. Our team can review available
          options and offer guidance on next steps to help you manage any
          changes. You can reach us on our priority number: <TextLink
              href="tel:61370323334"
              rel="nofollow"
            >+61 3 7032 3334</TextLink>
        </BodyTextBlock>
        <Group direction="vertical" gap={20}>
          <BodyTextBlock variant="large" weight="bold">
            If your flight is currently scheduled to operate as planned:
          </BodyTextBlock>
          <BodyTextBlock variant="large">
            There's no immediate action required at this time. However, we
            encourage you to monitor your flight status regularly, as the situation
            can evolve quickly. Please stay informed by checking updates on your
            airline's website or travel advisory page. If your travel plans are
            impacted, please reach out to us directly, and we'll provide advice and
            explore any options available to you.
          </BodyTextBlock>
        </Group>
        <Group direction="vertical" gap={20}>
          <BodyTextBlock variant="large">
            Thank you for your patience and understanding. Our team is here to
            help ensure your travel experience is as smooth as possible.
          </BodyTextBlock>
          <BodyTextBlock variant="large">
            {config.title} Support Team
          </BodyTextBlock>
        </Group>
      </VerticalSpacer>
    </Modal>
  )
}

export default BaliWarningModal
