import React from 'react'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import SolidKeySkeletonAltIcon from 'components/Luxkit/Icons/solid/SolidKeySkeletonAltIcon'
import LinePriceTagAltIcon from 'components/Luxkit/Icons/line/LinePricetagAltIcon'
import SolidCheckIcon from 'components/Luxkit/Icons/solid/SolidCheckIcon'

interface Props {
  highlight?: boolean
  weight?: 'normal' | 'bold'
  inclusion?: string
  type?: 'promotion' | 'access'
  children?: React.ReactNode
}

const icons = {
  promotion: <LinePriceTagAltIcon size="S" />,
  access: <SolidKeySkeletonAltIcon size="S" />,
  default: <SolidCheckIcon size="S" />,
}

function PromotionInclusionItem({ highlight, inclusion, type, children, weight } : Props) {
  return <BodyTextBlock
    colour={highlight ? 'highlight-secondary' : 'neutral-one'}
    weight={weight ?? 'normal'}
    variant="medium"
    startIcon={icons[type ?? 'default']}
  >
    {inclusion ?? children}
  </BodyTextBlock>
}

export default PromotionInclusionItem
