import { fetchBestPriceForOffer } from 'actions/OfferActions'
import Pane from 'components/Common/Pane'
import { useAppDispatch } from 'hooks/reduxHooks'
import { buildSearchParamsKey } from 'lib/search/searchUtils'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import SearchOfferTileCondensed from '../SearchOfferTileCondensed'
import SearchOfferTileLoadingSkeleton from '../SearchOfferTileLoadingSkeleton'
import SearchVillaTileExpanded from './SearchVillaTileExpanded'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { useOfferSoldOutPushDown } from 'hooks/Offers/useOfferSoldOutPushDown'
import { useDirectSearchPrices } from 'hooks/Search/useSearchPrices'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { logNewRelic } from 'services/newRelic'

interface Props {
  offer: App.VillaOffer
  filters: App.OfferListFilters | undefined
  bestPrices?: {
    [key: string]: App.OfferMaybeAvailableRate;
  };
  pricesErrors?: { [key: string]: any };
  offerUrl: string
  onImageChange?: (idx: number, _?: App.Image) => void,
}

function SearchVillaTile(props: Props) {
  const { offer, filters, bestPrices, pricesErrors, offerUrl, onImageChange } = props
  const dispatch = useAppDispatch()
  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? {}, offerId: offer.id })

  const metaData = useOfferMetaData(offer.id, filters)
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))
    if (!directSearchPrices && checkIn && checkOut && !occupanciesInvalid) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn,
        checkOut,
        occupants: filters?.rooms ?? [],
      }))
    }
  }, [dispatch, filters, offer, bestPrices, checkIn, checkOut, directSearchPrices])

  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])
  const hasDates = !!checkIn && !!checkOut
  const bestPrice = bestPrices ? bestPrices[searchKey] : undefined
  const bestPriceError = pricesErrors ? pricesErrors[searchKey] : undefined
  const available = bestPrice?.available
  const rate = bestPrice?.available ? bestPrice.rate : undefined
  const fetchingPrice = hasDates && !bestPrice && !bestPriceError && !directSearchPrices
  const lowestPrice = offer.lowestPricePackage?.price

  const bestPricePackage = useMemo(() => {
    if (directSearchPrices) {
      if (!directSearchPrices.lowestPricePackageId && !directSearchPrices.lowestPriceRoomRateId) {
        logNewRelic('SearchVillaTile: No direct search prices', {
          offerId: offer.id,
          directSearchPrices,
          filters,
        }, 'error')
        return offer.lowestPricePackage
      }

      const pkg = offer.packages.find(pkg => pkg.uniqueKey === getPackageUniqueKey(directSearchPrices.lowestPricePackageId!, directSearchPrices.duration!, directSearchPrices.lowestPriceRoomRateId!))
      return pkg ?? offer.lowestPricePackage
    }

    if (!hasDates) {
      return offer.lowestPricePackage
    }
    if (available && 'packages' in offer) {
      return offer.packages.find(pkg => pkg.uniqueKey === rate?.packageUniqueKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, available, rate, hasDates, directSearchPrices]) // don't care about filters here, just using it for logging

  const soldOut = !lowestPrice || (!fetchingPrice && !bestPricePackage)
  useOfferSoldOutPushDown(offer.id, filters, soldOut)

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true)
  }, [])

  useEffect(() => {
    if (imageLoaded && (!hasDates || (hasDates && !fetchingPrice))) {
      dispatchOfferListEvent({
        type: OfferListEvents.offerReady,
        available: !soldOut && !!rate,
      })
    }
  }, [imageLoaded, dispatchOfferListEvent, hasDates, fetchingPrice, rate, soldOut])

  return (<Pane type="clean">
    {fetchingPrice && <SearchOfferTileLoadingSkeleton /> }
    {/* Search should never return a sold out offer in search results TODO: investigate if we can remove this */}
    {soldOut && <SearchOfferTileCondensed offer={offer} filters={filters} onImageChange={onImageChange} onImageLoad={handleImageLoaded}/>}
    {!fetchingPrice && !soldOut &&
      <SearchVillaTileExpanded
        offer={offer}
        bestPriceForDates={rate}
        filters={filters}
        offerUrl={offerUrl}
        bestPricePackage={bestPricePackage}
        onImageChange={onImageChange}
        onImageLoad={handleImageLoaded}
      />
    }
  </Pane>)
}

const mapStateToProps = (state: App.State, ownProps: Partial<Props> & {offer: App.Offer}) => ({
  bestPrices: state.offer.offerBestPrices[ownProps.offer.id],
  pricesErrors: state.offer.offerPricesErrors[ownProps.offer.id],
})

export default connect(mapStateToProps)(SearchVillaTile)
