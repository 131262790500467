import createSelector from 'lib/web/createSelector'
import { checkoutAccommodationOfferView } from './view/accommodation'
import { OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR_V2, OFFER_TYPE_VILLA } from 'constants/offer'
import config from 'constants/config'
import { getCustomOfferItems, getCustomOfferTravellers } from './view/customOffer'
import { getVillaViews } from './view/villa'
import { getBundleAndSaveItems } from './view/bundleAndSave'
import { isCruiseOfferView } from 'checkout/lib/utils/cruises/view'
import { getCarHireItems, getCarHireItemsView } from './view/carHire'
import { isTourOfferViewWithPackageUpgrade } from 'checkout/lib/utils/tours/view'
import { getFlightItemsView, isStandaloneFlights } from './view/flights'
import { getExperienceItemsView, getTransferItems } from './view/experience'
import { getGiftCardItems } from './view/giftCard'
import { areInsuranceOccupantsConsistent, getInsuranceFetchParams, isInsuranceSupported } from './request/insurance'
import { getBookingProtectionQuoteRequestKey, getQuoteFromBookingProtectionQuotes, isBookingProtectionEnabled } from './request/bookingProtection'
import { shouldShowArrivalDetailsForm, shouldShowPaymentForm, shouldShowTravellerForm } from './stepsSelectors'
import { isBalancePending } from 'lib/payment/depositsUtils'
import { getCruiseItems, getCruisesV1 } from './view/cruise'
import {
  CHECKOUT_INSURANCE_CONTAINER_ID,
  CHECKOUT_EXPERIENCE_TRANSFER_CONTAINER_ID,
  CHECKOUT_REVIEW_CONTAINER_ID,
  CHECKOUT_TRAVELLER_CONTAINER_ID,
  CHECKOUT_ARRIVAL_DETAILS_CONTAINER_ID,
  CHECKOUT_PAYMENTS_CONTAINER_ID,
  CHECKOUT_HIGH_VALUE_CONTAINER_ID,
} from 'constants/checkout'
import { isLuxPlusEnabled, checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { isStandaloneLuxPlusSubscription } from './view/luxPlusSubscription'
import { getTourV2ExperienceItemsViews } from 'checkout/selectors/view/toursv2'
import { isSpoofed, isTravelProtectionAvailableInRegion } from 'selectors/featuresSelectors'
import { isSimpleStandaloneCheckout, isStandaloneExperience } from 'selectors/checkoutSelectors'
import { INSURANCE_TITLE, TRAVEL_PROTECTION_TITLE } from 'checkout/constants/insurance'
import { getAllOffers } from 'selectors/offerSelectors'
import { getLeAccommodationItems, getBedbankItems } from 'checkout/selectors/view/accommodation'
import { getExperienceTransferBestOptions, getOneClickExperienceTransfer, isOneClickExperienceTransferEnabled } from './experienceSelectors'
import { isNonEmptyArray } from 'lib/array/arrayUtils'
import { checkHasLuxLoyaltyAccount, isLuxLoyaltyEnabled } from 'luxLoyalty/selectors/featureToggles'
import { getCheckoutTotal } from './payment/checkout'

export interface CheckoutNumberedSection<T> {
  id: string;
  title?: string;
  checkoutNumber?: string;
  data: T;
}

export interface CheckoutSections {
  review: CheckoutNumberedSection<Array<CheckoutSectionsReview>>;
  experienceTransfer: CheckoutNumberedSection<Array<CheckoutSectionsExperienceTransfer>>
  insurance: CheckoutNumberedSection<Array<CheckoutSectionsInsurance>>;
  travellerDetails: CheckoutNumberedSection<Array<CheckoutSectionsTravellerDetails>>;
  arrivalDetails: CheckoutNumberedSection<Array<CheckoutSectionsArrivalDetails>>;
  highValue: CheckoutNumberedSection<Array<CheckoutSectionsHighValue>>;
  payment: CheckoutNumberedSection<Array<CheckoutSectionsPayment>>;
}

export type CheckoutSectionsReview = 'customOffer' | 'accommodation' | 'villa' | 'bundleAndSave' | 'cruise' | 'carHire' | 'tour' | 'tourExperience' | 'flight' | 'experience' | 'reviewTransfer' | 'giftCard' | 'customerServicePanel' | 'luxPlusInflow' | 'luxPlusStandalone'
export type CheckoutSectionsExperienceTransfer = 'experienceTransfer'
export type CheckoutSectionsInsurance = 'insurance' | 'bookingProtection' | 'spoofedInsurance'
export type CheckoutSectionsTravellerDetails = 'customOfferTravellers' | 'travellerDetails' | 'carHireArrivalFlightNumber' | 'agentBookingDetails' | 'depositDetailsNotice' | 'businessBookingDetails'
export type CheckoutSectionsArrivalDetails = 'arrivalDetails'
export type CheckoutSectionsHighValue = 'luxLoyalty'
export type CheckoutSectionsPayment = 'instalmentBalancePaymentSchedule' | 'paymentForm'

const activeSections = <T>(sections: Array<[T, boolean]>) => sections.filter(section => Boolean(section[1])).map(section => section[0])

// @TODO: a 'cruise' is not an accommodation item
export const filterCheckoutAccommodationView = (view) => {
  const isTourWithPackageUpgrade = view.offerType === OFFER_TYPE_TOUR_V2 && view.itemViews.some((item) => !!item?.packageOptionInclusion)
  return view.offerType !== OFFER_TYPE_CRUISE && !isTourWithPackageUpgrade && view.offerType !== OFFER_TYPE_VILLA
}

const accommodationViews = createSelector(
  checkoutAccommodationOfferView,
  (offerViews) => offerViews.data.filter(filterCheckoutAccommodationView),
)

export const checkoutShouldShowBookingProtection = createSelector(
  isBookingProtectionEnabled,
  getQuoteFromBookingProtectionQuotes,
  // Exclude post purchase as currently only changes are allowed as part of date/package modifications
  // without removing the item, or adding it, so we don't want to show the options ever for now
  // but only display it in the summary if additional charges are required
  (state:App.State) => state.checkout.cart.postPurchase,
  (state:App.State) => state.bookingProtection.fetchingQuotes[getBookingProtectionQuoteRequestKey(state)],
  (enabled, quotes, postPurchase, fetching) => !postPurchase && enabled && (!!quotes || fetching),
)

const checkoutShouldShowCarHireArrivalInput = createSelector(
  getCarHireItemsView,
  (carHireViews) => !!carHireViews.data.length && (carHireViews.data[0].item.isPickUpAtAirport ||
    carHireViews.data[0]?.pickUpLocation.name.toUpperCase().includes('AIRPORT')),
)

const getReviewSectionTitle = createSelector(
  isSimpleStandaloneCheckout,
  isStandaloneExperience,
  (state:App.State) => state.checkout.cart.isGift,
  getCarHireItems,
  (simpleStandaloneCheckout, standaloneExperience, isGift, carHireItems):string => {
    if (isGift) {
      return 'Let’s review your gift'
    } else if (simpleStandaloneCheckout) {
      return ''
    } else if (standaloneExperience) {
      return 'Review your experience'
    } else {
      if (config.businessTraveller.isEnabled) {
        if (carHireItems.length) {
          return 'Let’s review your booking'
        } else {
          return 'Let’s review your trip'
        }
      } else {
        return 'Let’s review your escape'
      }
    }
  },
)

const getTravellerSectionTitle = createSelector(
  (state:App.State) => state.checkout.schemaData,
  (state:App.State) => state.checkout.cart.isGift,
  getCarHireItems,
  (formSchema, isGift, carHireItems) => {
    if (carHireItems.length) return 'Who\'s the driver?'
    if (isGift) return formSchema?.accommodationFlightSchema?.properties?.recipient?.title
    return 'Who\'s going?'
  },
)

const getInsuranceSectionTitle = createSelector(
  checkoutShouldShowBookingProtection,
  isInsuranceSupported,
  getCruiseItems,
  getCruisesV1,
  isSpoofed,
  isStandaloneFlights,
  isTravelProtectionAvailableInRegion,
  (
    showBookingProtection,
    isInsuranceSupported,
    cruiseItems,
    cruisesV1,
    isSpoofed,
    standaloneFlights,
    isTravelProtection,
  ) => {
    if (isSpoofed) return
    if (showBookingProtection) {
      if (standaloneFlights) {
        return 'Protect yourself if you need to cancel your flights'
      }
      return 'Protect yourself if you need to cancel your booking'
    }
    if (isInsuranceSupported && (cruiseItems.length || cruisesV1.length)) return 'Want to add cruise protection?'
    if (isInsuranceSupported) return isTravelProtection ? TRAVEL_PROTECTION_TITLE : INSURANCE_TITLE
  },
)

const getNumberedSections = ({
  reviewSections,
  experienceTransferSection,
  insuranceSections,
  travellerSections,
  arrivalDetails,
  highValueSections,
  paymentSections,
}):CheckoutSections => {
  const sections = [
    { name: 'review', id: CHECKOUT_REVIEW_CONTAINER_ID, data: reviewSections },
    { name: 'experienceTransfer', id: CHECKOUT_EXPERIENCE_TRANSFER_CONTAINER_ID, data: experienceTransferSection },
    { name: 'insurance', id: CHECKOUT_INSURANCE_CONTAINER_ID, data: insuranceSections },
    { name: 'travellerDetails', id: CHECKOUT_TRAVELLER_CONTAINER_ID, data: travellerSections },
    { name: 'arrivalDetails', id: CHECKOUT_ARRIVAL_DETAILS_CONTAINER_ID, data: arrivalDetails },
    { name: 'highValue', id: CHECKOUT_HIGH_VALUE_CONTAINER_ID, data: highValueSections },
    { name: 'payment', id: CHECKOUT_PAYMENTS_CONTAINER_ID, data: paymentSections },
  ]
  const result = new Map<string, any>()

  let checkoutNumber = 1

  sections.forEach(({ name, data, id }) => {
    if (data.length) {
      result.set(name, { checkoutNumber, data, id })
      checkoutNumber++
    }
  })
  return {
    review: result.get('review'),
    experienceTransfer: result.get('experienceTransfer'),
    insurance: result.get('insurance'),
    travellerDetails: result.get('travellerDetails'),
    arrivalDetails: result.get('arrivalDetails'),
    highValue: result.get('highValue'),
    payment: result.get('payment'),
  }
}

const isLuxPlusSubscriptionInFlowCheckout = createSelector(
  isLuxPlusEnabled,
  isStandaloneLuxPlusSubscription,
  checkCanRedeemLuxPlusBenefits,
  (isLuxPlusEnabled, isStandaloneLuxPlusCheckout, canRedeemLuxPlusBenefits): boolean =>
    isLuxPlusEnabled && !isStandaloneLuxPlusCheckout && !canRedeemLuxPlusBenefits,
)

const isLuxPlusSubscriptionStandaloneCheckout = createSelector(
  isLuxPlusEnabled,
  isStandaloneLuxPlusSubscription,
  checkCanRedeemLuxPlusBenefits,
  (isLuxPlusEnabled, isStandaloneLuxPlusCheckout, canRedeemLuxPlusBenefits): boolean =>
    isLuxPlusEnabled && isStandaloneLuxPlusCheckout && !canRedeemLuxPlusBenefits,
)

const getReviewCheckoutPageSection = createSelector(
  getCustomOfferItems,
  accommodationViews,
  getVillaViews,
  getBundleAndSaveItems,
  checkoutAccommodationOfferView,
  getCarHireItemsView,
  getFlightItemsView,
  getExperienceItemsView,
  getTransferItems,
  getGiftCardItems,
  getTourV2ExperienceItemsViews,
  isSimpleStandaloneCheckout,
  isLuxPlusSubscriptionInFlowCheckout,
  isLuxPlusSubscriptionStandaloneCheckout,
  isOneClickExperienceTransferEnabled,
  (
    customOfferItems,
    accommodationViews,
    villaViews,
    bundleAndSaveItems,
    checkoutAccommodationOfferView,
    carHireItemsView,
    flightItemsView,
    experienceViews,
    transferViews,
    giftCardItems,
    tourV2ExperienceItemsViews,
    isSimpleStandaloneCheckout,
    isLuxPlusSubscriptionInFlowCheckout,
    isLuxPlusSubscriptionStandaloneCheckout,
    oneClickAirportTransfersABTestEnabled,
  ):Array<CheckoutSectionsReview> => {
    // array of tuples as the order matters
    return activeSections([
      ['customOffer', !!customOfferItems.length],
      ['accommodation', !!accommodationViews.length],
      ['villa', !!villaViews.data.length],
      ['bundleAndSave', !!bundleAndSaveItems.length],
      ['cruise', !!checkoutAccommodationOfferView.data.filter(isCruiseOfferView)?.length],
      ['carHire', !!carHireItemsView.data.length],
      ['tour', !!checkoutAccommodationOfferView.data.filter(isTourOfferViewWithPackageUpgrade)?.length],
      ['tourExperience', !!tourV2ExperienceItemsViews.data.length],
      ['flight', !!flightItemsView.data.length],
      ['experience', !!experienceViews.data.length],
      ['reviewTransfer', !oneClickAirportTransfersABTestEnabled && !!transferViews.length],
      ['giftCard', !!giftCardItems.length],
      ['customerServicePanel', (!!accommodationViews.length || isSimpleStandaloneCheckout) && !config.agentHub.isEnabled && !isLuxPlusSubscriptionStandaloneCheckout],
      ['luxPlusInflow', isLuxPlusSubscriptionInFlowCheckout],
      ['luxPlusStandalone', isLuxPlusSubscriptionStandaloneCheckout],
    ])
  },
)

const getExperienceTransferCheckoutPageSection = createSelector(
  getOneClickExperienceTransfer,
  (state: App.State) => state.experience.experienceTransferOptions,
  getTransferItems,
  isOneClickExperienceTransferEnabled,
  getExperienceTransferBestOptions,
  (oneClickTransfer, transferOptions, checkoutTransferItems, oneClickAirportTransfersABTestEnabled, bestTransferOptions): Array<CheckoutSectionsExperienceTransfer> => {
    const { bestToHotelOption, bestToAirportOption } = bestTransferOptions
    const isAvaiableTransfers = !!bestToHotelOption || !!bestToAirportOption

    if (!oneClickAirportTransfersABTestEnabled || (!oneClickTransfer && !checkoutTransferItems.length) || !isAvaiableTransfers) {
      return activeSections([
        ['experienceTransfer', false],
      ])
    }

    const transferId = isNonEmptyArray(checkoutTransferItems) ? checkoutTransferItems[0].experienceId : oneClickTransfer?.id

    // Some transfer options don't include the transfer type, making it unclear whether they are to the hotel or to the airport.
    // We want to display one-click transfers only when the transfer type is known, as this information must be shown to the customer.
    const isOptionWithTransferType = !!transferOptions[transferId!]?.transfers?.some(transferOption => !!transferOption?.transferTypes?.length)

    return activeSections([
      ['experienceTransfer', isOptionWithTransferType],
    ])
  })

const getInsuranceCheckoutPageSection = createSelector(
  isInsuranceSupported,
  getInsuranceFetchParams,
  (state: App.State) => state.insurance.fetchingProducts,
  (state: App.State) => state.insurance.fetchingQuotes,
  (state: App.State) => state.insurance.quotes.length,
  (state: App.State) => state.insurance.products.length,
  areInsuranceOccupantsConsistent,
  checkoutShouldShowBookingProtection,
  isSpoofed,
  (
    insuranceSupported,
    hasInsuranceFetchParams,
    fetchingProducts,
    fetchingQuotes,
    quotesLength,
    productsLength,
    occupantsConsistent,
    shouldShowBookingProtection,
    isSpoofed,
  ):Array<CheckoutSectionsInsurance> => {
    // array of tuples as the order matters
    const insuranceProducts = fetchingProducts || !!productsLength
    const insuranceQuotes = fetchingQuotes || !!quotesLength
    const showInsurance = insuranceSupported && insuranceProducts && insuranceQuotes && !!hasInsuranceFetchParams
    const showOccupancyWarning = insuranceSupported && !occupantsConsistent
    return activeSections([
      ['spoofedInsurance', isSpoofed && (showInsurance || shouldShowBookingProtection)],
      ['insurance', !isSpoofed && (showInsurance || showOccupancyWarning)],
      ['bookingProtection', !isSpoofed && shouldShowBookingProtection],
    ])
  },
)

const getTravellerCheckoutPageSection = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  getCustomOfferTravellers,
  shouldShowTravellerForm,
  checkoutShouldShowCarHireArrivalInput,
  (
    existingOrder,
    customTravellers,
    shouldShowTravellerForm,
    shouldShowCarHireArrivalInput,
  ):Array<CheckoutSectionsTravellerDetails> => {
    // array of tuples as the order matters
    return activeSections([
      ['customOfferTravellers', !!existingOrder && !!customTravellers.length],
      ['travellerDetails', shouldShowTravellerForm],
      ['carHireArrivalFlightNumber', shouldShowCarHireArrivalInput],
      ['agentBookingDetails', config.agentHub.isEnabled],
      ['businessBookingDetails', config.businessTraveller.isEnabled],
      ['depositDetailsNotice', !!existingOrder?.depositDetails && isBalancePending(existingOrder.depositDetails.deposit_status)],
    ])
  },
)

const getArrivalDetailsCheckoutPageSection = createSelector(
  shouldShowArrivalDetailsForm,
  (
    shouldShowArrivalDetailsForm,
  ):Array<CheckoutSectionsArrivalDetails> => {
    return activeSections([
      ['arrivalDetails', shouldShowArrivalDetailsForm],
    ])
  })

const getHighValueCheckoutPageSection = createSelector(
  isLuxLoyaltyEnabled,
  checkHasLuxLoyaltyAccount,
  (
    luxLoyaltyEnabled,
    hasLuxLoyaltyAccount,
  ): Array<CheckoutSectionsHighValue> => {
    return activeSections([
      ['luxLoyalty', luxLoyaltyEnabled && !hasLuxLoyaltyAccount],
    ])
  },
)

const getPaymentCheckoutPageSection = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  shouldShowPaymentForm,
  (state: App.State) => state.checkout.cart.postPurchase,
  (
    existingOrder,
    showPaymentForm,
    postPurchase,
  ): Array<CheckoutSectionsPayment> => {
    // array of tuples as the order matters
    return activeSections([
      ['instalmentBalancePaymentSchedule', (postPurchase === 'instalment' || postPurchase === 'instalment-balance') && !!existingOrder?.instalmentDetails],
      ['paymentForm', showPaymentForm],
    ])
  },
)

const getPaymentSectionTitle = createSelector(
  getCheckoutTotal,
  (total): string => {
    return total > 0 ? 'How would you like to pay?' : ''
  },
)

export const getCheckoutPageSections = createSelector(
  getReviewCheckoutPageSection,
  getExperienceTransferCheckoutPageSection,
  getInsuranceCheckoutPageSection,
  getTravellerCheckoutPageSection,
  getArrivalDetailsCheckoutPageSection,
  getPaymentCheckoutPageSection,
  getPaymentSectionTitle,
  getReviewSectionTitle,
  getTravellerSectionTitle,
  getInsuranceSectionTitle,
  getHighValueCheckoutPageSection,
  (
    reviewSections,
    experienceTransferSection,
    insuranceSections,
    travellerSections,
    arrivalDetails,
    paymentSections,
    paymentSectionTitle,
    reviewSectionTitle,
    travellerSectionTitle,
    insuranceSectionTitle,
    highValueSections,
  ) : CheckoutSections => {
    const numberedSections = getNumberedSections({
      reviewSections,
      experienceTransferSection,
      insuranceSections,
      travellerSections,
      arrivalDetails,
      highValueSections,
      paymentSections,
    })
    return {
      review: { title: reviewSectionTitle, ...numberedSections.review },
      experienceTransfer: { title: 'Need airport transfers?', ...numberedSections.experienceTransfer },
      insurance: { title: insuranceSectionTitle, ...numberedSections.insurance },
      travellerDetails: { title: travellerSectionTitle, ...numberedSections.travellerDetails },
      arrivalDetails: { title: 'When will you be arriving?', ...numberedSections.arrivalDetails },
      highValue: { ...numberedSections.highValue },
      payment: { title: paymentSectionTitle, ...numberedSections.payment },
    }
  },
)

export const hasRentalInCart = createSelector(
  (state: App.State) => state?.checkout?.cart?.items,
  (items) => items?.some(item => item.itemType === 'villa') ?? false,
)

export const hasFijiHotelInCart = createSelector(
  getAllOffers,
  getLeAccommodationItems,
  getBedbankItems,
  (offers, bedbankItems, leHotelItem) => {
    const isFijiBedbank = bedbankItems.some(item => (offers[item.offerId] as App.BedbankOffer)?.property?.timezone === 'Pacific/Fiji') ?? false
    const isFijiHotel = leHotelItem.some(item => (offers[item.offerId] as App.HotelOffer)?.property?.timezone === 'Pacific/Fiji') ?? false

    return isFijiBedbank || isFijiHotel
  },
)
