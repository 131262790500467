import {
  STEP_BEDBANK_CHANGE_DATES,
  STEP_BOOK_DATES,
  STEP_CONFIRMATION,
  STEP_DEPARTING_FLIGHT,
  STEP_RETURNING_FLIGHT,
} from 'constants/booking'
import qs from 'qs'
import { CheckoutPageId } from 'checkout/constants/pages'

// validateBookingPathQueryParams validates the presence of query params for the booking path
// of the given step
function validateBookingPathQueryParams(step: string, q: Record<string, string>) {
  if (step === STEP_BOOK_DATES) {
    return !!(q.offerId && q.packageId && q.orderId && q.orderItemId)
  }

  if (step === STEP_DEPARTING_FLIGHT || step === STEP_RETURNING_FLIGHT) {
    return !!(q.departDate && q.returnDate)
  }
  if (step === STEP_CONFIRMATION) {
    return !!(q.orderId)
  }

  return true
}

export function getCheckoutPath(step: string, queryParams = {}) {
  const ok = validateBookingPathQueryParams(step, queryParams)
  if (!ok) {
    throw new Error(`missing one or more required parameters for booking step ${step}`)
  }

  let path = `/checkout/${step}`

  const queryStr = qs.stringify(queryParams)
  if (queryStr) {
    path += `?${queryStr}`
  }

  return path
}

export function getBedbankChangeDatesPath(orderItemId: string, orderId: string) {
  return getCheckoutPath(STEP_BEDBANK_CHANGE_DATES, { orderItemId, orderId })
}

export function getInflowFlightsCheckoutPath() {
  return '/checkout/departing'
}

export function getCheckoutPathWithCartId(stepId: string, cartId: string) {
  return stepId === 'purchase' ? `/checkout/${cartId}/${stepId}` : `/checkout/${stepId}`
}

export function getCruiseCheckoutPath(stepId: string, cartId: string, cabinId?: string) {
  if (stepId === 'purchase') {
    return `/checkout/${cartId}/${stepId}`
  }

  if (!cabinId || stepId === CheckoutPageId.CruiseTravelers) {
    return `/checkout/${stepId}`
  }

  return `/checkout/${stepId}/${cabinId}`
}
