import React from 'react'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import HelpPane from 'components/Common/HelpPane'
import { getSupportEmailForCurrentRegion } from 'selectors/supportContactSelector'
import { connect } from 'react-redux'
import Modal from 'components/Luxkit/Modal/Modal'

interface Props {
  email: string;
  description?: string;
  currentContact: App.GeoContacts;
}

function AdditionalGuestsModal({
  description,
  currentContact,
  email,
}:Props) {
  return <Modal
      title="Extra Guest Surcharges"
    >
    <MarkdownRender content={description || ''}/>
    <HelpPane email={email} contactNumber={currentContact[currentContact.default as string] as App.GeoContact}/>
  </Modal>
}
function mapStateToProps(state: App.State) {
  return {
    email: getSupportEmailForCurrentRegion(state),
  }
}

export default connect(mapStateToProps)(AdditionalGuestsModal)
