import React, { useContext } from 'react'
import Caption from 'components/Luxkit/Typography/Caption'
import GeoContext from 'contexts/geoContext'
import Tooltip from 'components/Luxkit/Tooltip'
import { getSearchDistanceLabel } from './searchDistanceLabel'

interface Props {
  searchTargetLabel: string;
  distance: number;
}

function SearchTargetDistance(props: Props) {
  const { currentRegionCode } = useContext(GeoContext)
  const label = getSearchDistanceLabel(props.searchTargetLabel, props.distance, currentRegionCode)

  return <Tooltip
    description="This distance is calculated as a straight line on a map. Actual travel distance may vary."
  >
    <Caption variant="medium" colour="neutral-three">
      {label}
    </Caption>
  </Tooltip>
}

export default SearchTargetDistance
