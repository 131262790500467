import React, { useContext } from 'react'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import { pluralizeToString } from 'lib/string/pluralize'

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  hideLabel?: boolean;
}

const FlightSearchTravellerSelectField = React.forwardRef<
  HTMLButtonElement,
  Props
>((props, ref) => {
  const { onClick, disabled, hideLabel = false } = props
  const state = useContext(FlightSearchWidgetStateContext)

  const { occupants } = state

  const numberOfOccupants = occupants.adults + (occupants.children || 0) + (occupants.infants || 0)
  const label = hideLabel ? numberOfOccupants : pluralizeToString('Traveller', numberOfOccupants)

  return (
    <TextButton
      kind="tertiary"
      startIcon={<LineUserIcon />}
      endIcon={<DropdownChevron open={state.activeMenu === 'occupants'} />}
      onClick={disabled ? undefined : onClick}
      ref={ref}
      data-testid={props['data-testid']}
      disabled={!!disabled}
    >
      {label}
    </TextButton>
  )
})

FlightSearchTravellerSelectField.displayName = 'FlightSearchTravellerSelectField'

export default FlightSearchTravellerSelectField
