import { groupBy, split } from 'lib/array/arrayUtils'

export function transformRequestData(packages: Array<App.Package>) {
  const queryGroups = groupBy(packages, pkg => pkg.duration)

  return [...queryGroups.entries()].map(([duration, pkgGroup]) => {
    // get package options
    const pkgOptionsGroups = groupBy(pkgGroup, pkg => pkg.id)

    // get unique package
    const groupedPkg = Array.from(new Map(pkgGroup.map(p => ([p.id, p]))).values())

    // calculate chunkSize
    const packagesRatio = Array.from(pkgOptionsGroups.keys()).map(key => 2 / (pkgOptionsGroups.get(key) ?? [1]).length)
    const minRatio = Math.min(...packagesRatio) || 1
    const chunkSize = Math.ceil(10 * minRatio)

    // generate chunks
    const chunks = split<App.Package>(groupedPkg, chunkSize)
    return { duration, chunks }
  })
}
