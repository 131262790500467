import qs from 'qs'
import request, { authOptions } from 'api/requestUtils'
import { actions, definitions } from '@luxuryescapes/contract-svc-agent'

type AgentHubGetCommissionsResponse = actions['getAvailableCommissions']['responses']['200']['content']['application/json']['result']
type ServerCommission = definitions['availableCommission']

function mapCommission(serverCommission: ServerCommission): App.AgentHubCommissionRule {
  return {
    commissionPercentage: serverCommission.commissionPercentage,
    description: serverCommission.description,
    conditions: {
      ...(serverCommission.rules.productType?.length && {
        productType: serverCommission.rules.productType as Array<App.ProductType> ?? [],
      }),
      ...(serverCommission.rules.agency?.length && { agency: serverCommission.rules.agency }),
      ...(serverCommission.rules.agent?.length && { agent: serverCommission.rules.agent }),
      ...(serverCommission.rules.location?.length && { location: serverCommission.rules.location }),
      ...(serverCommission.rules.offer?.length && { offer: serverCommission.rules.offer }),
      ...(serverCommission.rules.vendor?.length && { vendor: serverCommission.rules.vendor }),
      ...(serverCommission.rules.affiliation?.length && { affiliation: serverCommission.rules.affiliation }),
    },
  }
}

export async function getCommissions(regionCode: string): Promise<App.AgentHubCommissionConfig> {
  const query = qs.stringify({
    region: regionCode,
  })

  const response = await request.get<App.ApiResponse<AgentHubGetCommissionsResponse>>(`/api/agent/available-commissions?${query}`, authOptions())

  const baseCommissionRules = response.result.base.map(mapCommission)
  const additionalCommissionRules = response.result.additional.map(mapCommission)

  const config: App.AgentHubCommissionConfig = {
    baseCommissions: baseCommissionRules,
    additionalCommissions: additionalCommissionRules,
  }

  return config
}
