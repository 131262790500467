import * as DestinationAlertsService from 'api/destinationAlerts'
import { API_CALL } from 'actions/actionConstants'

import {
  FETCH_HOTEL_POPULAR_HOLIDAY_TYPES,
  FETCH_POPULAR_DESTINATIONS,
  FETCH_TRENDING_DESTINATIONS,
} from 'actions/apiActionConstants'
import { getPopularDestinations } from 'api/search'

export function fetchPopularDestinations(destinationsType: 'domestic' | 'international') {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { popularDestinations } = state.destination

    if (!popularDestinations[destinationsType].destinations && !popularDestinations[destinationsType].fetching) {
      dispatch({
        type: API_CALL,
        api: FETCH_POPULAR_DESTINATIONS,
        request: () => getPopularDestinations(destinationsType, state.geo.currentRegionCode),
        destinationsType,
      })
    }
  }
}

export function fetchTrendingDestinations() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.destination.trendingDestinations.length === 0 && !state.destination.fetchingTrendingDestinations) {
      dispatch({
        type: API_CALL,
        api: FETCH_TRENDING_DESTINATIONS,
        request: () => DestinationAlertsService.getTrendingDestinations(state.geo.currentRegionCode),
      })
    }
  }
}

export function fetchHotelPopularHolidayTypes() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.destination.hotelPopularHolidayTypes.length === 0 && !state.destination.fetchingHotelPopularHolidayTypes) {
      dispatch({
        type: API_CALL,
        api: FETCH_HOTEL_POPULAR_HOLIDAY_TYPES,
        request: () => DestinationAlertsService.getHotelPopularHolidayTypes(state.geo.currentRegionCode),
      })
    }
  }
}
