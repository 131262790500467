import { checkoutTotalsView } from './checkout'

export const getGrandTotal = (state: App.State) => checkoutTotalsView(state).data.grandTotal

export const getCreditBalanceForCheckoutCurrency = (state: App.State) =>
  state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode]?.balance ?? 0

export function calculateValue(price: number, discountPercentage: number) {
  const priceFraction = 1 - (discountPercentage / 100)
  if (priceFraction === 0) { return 0 }
  return price / priceFraction
}
