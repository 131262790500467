import { checkoutShouldShowBookingProtection } from 'checkout/selectors/checkoutSectionsSelectors'
import { getQuoteFromBookingProtectionQuotes } from 'checkout/selectors/request/bookingProtection'
import { sum } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'
import { isEnabledLuxPlusDiscountedBookingProtection } from 'luxPlus/selectors/featureToggle'
import { getBookingProtectionItemsView } from 'checkout/selectors/view/bookingProtection'

export const getBookingProtectionMemberSavings = createSelector(
  getBookingProtectionItemsView,
  (bookingProtectionItemsView) => {
    if (!bookingProtectionItemsView.hasRequiredData) {
      return {
        totalPrice: 0,
        totalMemberPrice: 0,
        totalMemberPriceSavings: 0,
      }
    }

    const totalPrice = sum(bookingProtectionItemsView.data, item => item.price)
    const totalMemberPrice = sum(bookingProtectionItemsView.data, item => item.memberPrice)
    const totalMemberPriceSavings = totalPrice - totalMemberPrice

    return {
      totalPrice,
      totalMemberPrice,
      totalMemberPriceSavings,
    }
  },
)

const getBookingProtectData = createSelector(
  getQuoteFromBookingProtectionQuotes,
  (state: App.State) => state.bookingProtection.fetchingQuotes,
  (state: App.State) => state.bookingProtection.triedFetchingQuotes,
  (bookingProtectionQuote, fetching, triedFetchingQuotes) => {
    const luxPlusPrice = bookingProtectionQuote?.luxPlusPrice ?? 0
    const price = bookingProtectionQuote?.price ?? 0
    const maxSavings = luxPlusPrice > 0 ? price - luxPlusPrice : 0
    return {
      luxPlusDiscountPercentage: bookingProtectionQuote?.luxPlusDiscountPercentage ?? 0,
      luxPlusDiscountValueDisplayThreshold: bookingProtectionQuote?.luxPlusDiscountValueDisplayThreshold ?? 0,
      maxSavings,
      fetching: !!(triedFetchingQuotes && Object.values(fetching).some(fetching => fetching)),
    }
  },
)

export const getAvailableBookingProtectionBenefits = createSelector(
  isEnabledLuxPlusDiscountedBookingProtection,
  checkoutShouldShowBookingProtection,
  getBookingProtectionMemberSavings,
  getBookingProtectData,
  (
    isEnabledLuxPlusDiscountedBookingProtection,
    shouldShowBookingProtection,
    bookingProtectionMemberSavings,
    bookingProtectData,
  ): App.Checkout.LuxPlusBookingProtectionBenefits => {
    const totalMemberPriceSavings = bookingProtectionMemberSavings.totalMemberPriceSavings
    const totalPrice = bookingProtectionMemberSavings.totalPrice
    const totalMemberPrice = bookingProtectionMemberSavings.totalMemberPrice

    return {
      isAvailable: isEnabledLuxPlusDiscountedBookingProtection && shouldShowBookingProtection,
      fetching: bookingProtectData.fetching,
      luxPlusDiscountPercentage: bookingProtectData.luxPlusDiscountPercentage,
      luxPlusDiscountValueDisplayThreshold: bookingProtectData.luxPlusDiscountValueDisplayThreshold,
      totalMemberMaxSavings: bookingProtectData.maxSavings,
      totalMemberPriceSavings,
      totalPrice,
      totalMemberPrice,
    }
  },
)
