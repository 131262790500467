import { useEffect, useMemo } from 'react'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { getFlightPriceKey } from 'lib/flights/flightUtils'
import { sum } from 'lib/array/arrayUtils'
import useDefaultAirport from './useDefaultAirport'
import { useAppDispatch, useAppSelector } from './reduxHooks'
import { fetchFlightPrice } from 'actions/FlightActions'

function useFlightPrice(
  destinationAirportCode: string,
  rooms: Array<App.Occupants> = [],
  startDate?: string | moment.Moment,
  endDate?: string | moment.Moment,
  originAirportCode?: string,
  nights?: number,
): [number, boolean] {
  const defaultAirport = useDefaultAirport()
  const origin = originAirportCode ?? defaultAirport?.code
  const dispatch = useAppDispatch()
  const startDateParam = moment.isMoment(startDate) ? startDate.format(ISO_DATE_FORMAT) : startDate
  const endDateParam = moment.isMoment(endDate) ? endDate.format(ISO_DATE_FORMAT) : endDate

  const filterKey = useMemo(() => getFlightPriceKey({
    startDate: startDateParam,
    endDate: endDateParam,
    origin,
    destination: destinationAirportCode,
    numberOfAdults: sum(rooms, (room) => room.adults),
    numberOfChildren: sum(rooms, (room) => room.children ?? 0),
    numberOfInfants: sum(rooms, (room) => room.infants ?? 0),
    nights,
  }), [startDateParam, endDateParam, origin, destinationAirportCode, rooms, nights])

  useEffect(() => {
    if (destinationAirportCode && startDateParam && endDateParam) {
      dispatch(fetchFlightPrice(destinationAirportCode, rooms, startDateParam, endDateParam, origin, nights))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey])

  const flightPrice = useAppSelector(state => state.flights.flightPrices[filterKey])
  const fetching = useAppSelector(state => state.flights.flightPricesLoading[filterKey])

  return [flightPrice, !!fetching]
}

export default useFlightPrice
