import React, { PropsWithChildren, useCallback, useMemo, useRef } from 'react'
import noop from 'lib/function/noop'

interface DepositTagOffersContextData {
  addDepositTagOffer: (offerId: string) => void;
  hasDepositTagOffer: (offerId: string) => boolean;
  clearDepositTagOffers: () => void;
}

const depositTagOffersContextDefaults = {
  addDepositTagOffer: noop,
  hasDepositTagOffer: () => false,
  clearDepositTagOffers: noop,
}

const DepositTagOffersContext = React.createContext<DepositTagOffersContextData>(depositTagOffersContextDefaults)

export function DepositTagOffersProvider(props: PropsWithChildren) {
  const depositTagOffersRef = useRef<Set<string>>(new Set())

  const addDepositTagOffer = useCallback((offerId: string) => {
    depositTagOffersRef.current.add(offerId)
  }, [])

  const hasDepositTagOffer = useCallback((offerId: string) : boolean => {
    return depositTagOffersRef.current.has(offerId)
  }, [])

  const clearDepositTagOffers = useCallback(() => {
    depositTagOffersRef.current.clear()
  }, [])

  const value = useMemo(() => {
    return { addDepositTagOffer, hasDepositTagOffer, clearDepositTagOffers }
  }, [addDepositTagOffer, clearDepositTagOffers, hasDepositTagOffer])

  return <DepositTagOffersContext.Provider value={value}>
    {props.children}
  </DepositTagOffersContext.Provider>
}

export default DepositTagOffersContext
