import ClickableLink from 'components/Common/Clickable/ClickableLink'
import Image from 'components/Common/Image/Image'
import OfferRating from 'components/Common/NumberRating/OfferRating'
import PartnershipLabel from 'components/Luxkit/Label/PartnershipLabel'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import PaletteSheet from 'components/Luxkit/PaletteSheet'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import config from 'constants/config'
import { isString } from 'lib/string/stringUtils'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

export const CAROUSEL_CARD_WIDTH = 320

const CarouselCardContainer = styled(ClickableLink)`
  display: grid;
  grid-template-rows: ${rem(200)} 1fr;
  gap: ${rem(16)};
  height: 100%;
  position: relative;
  min-height: ${rem(520)};
`

const BookmarkContainer = styled.div`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(12)};
`

const Partnership = styled(PartnershipLabel)`
  position: absolute;
  top: ${rem(8)};
  left: ${rem(12)};
`

const ImageContainer = styled(PaletteSheet)`
  position: relative;
`

interface Props {
  className?: string;
  bookmarkButton?: JSX.Element;
  description?: string | JSX.Element;
  descriptionLines?: number;
  image: App.Image;
  location: string | JSX.Element;
  title: string;
  priceDetails?: JSX.Element;
  to: string;
  onClick?: () => void;
  productType?: App.ProductType;
  productTypeKind?: 'default' | 'plain';
  rating?: App.OfferRating
  urgencyLabels?: JSX.Element;
  velocityFeatures?: { canEarn: boolean, canBurn: boolean, variant?: 'default' | 'vendor' | 'icon' };
  'data-testid'?: string;
}

function CarouselCardSmall(props: Props) {
  const {
    bookmarkButton,
    description,
    descriptionLines,
    image,
    location,
    title,
    priceDetails,
    to,
    onClick,
    productType,
    productTypeKind,
    rating,
    urgencyLabels,
    velocityFeatures,
    className,
    'data-testid': dataTestId,
  } = props
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return <CarouselCardContainer
    data-testid={dataTestId}
    onClick={onClick}
    target={config.OPEN_NEW_TAB_OFFER_CLICK ? '_blank' : undefined}
    to={to}
    className={className}
  >
    <ImageContainer paletteType="default">
      <Image
      aspectRatio="3:2"
      image={image}
      fit="center"
      dpr={2}
      height="200"
      width="320"
      onLoad={handleImageLoaded}
    />
      {velocityFeatures && <Partnership canUse={velocityFeatures.canBurn} />}
      <TripGuard>
        <BookmarkContainer>{bookmarkButton}</BookmarkContainer>
      </TripGuard>
    </ImageContainer>
    <Group direction="vertical" verticalAlign="space-between" gap={16}>
      <Group direction="vertical" horizontalAlign="start" gap={8}>
        {urgencyLabels}
        <Group direction="vertical" gap={4}>
          {isString(location) ? <Caption colour="neutral-one" variant="large">{location}</Caption> : location}
          <Heading lineClamp={3} variant="heading6">{title}</Heading>
        </Group>
        {productType && <ProductTypeLabel productType={productType} kind={productTypeKind ?? 'plain'} />}
        {rating && <OfferRating
          variant="xs"
          rating={rating}
          inlineLabel
          hideLink
        />}
        {description && isString(description) && <BodyText colour="neutral-two" lineClamp={descriptionLines || 2} variant="medium">{description}</BodyText>}
        {description && !isString(description) && description}
      </Group>
      <div>
        {priceDetails}
      </div>
    </Group>
  </CarouselCardContainer>
}

export default CarouselCardSmall
