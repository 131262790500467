import { excludeNullOrUndefined } from 'checkout/utils'

export function mergeFees(otherFares: Array<App.Checkout.OtherFees>): App.Checkout.OtherFees {
  return otherFares.filter(excludeNullOrUndefined).reduce((merged, fares) => {
    Object.keys(fares).forEach((key) => {
      if (key in merged) {
        merged[key] += fares[key]
      } else {
        merged[key] = fares[key]
      }
    })
    return merged
  }, {} as App.Checkout.OtherFees)
}
