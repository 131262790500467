import React, { useContext, useCallback, useEffect } from 'react'
import { pushWithRegion } from 'actions/NavigationActions'
import AccountAccessModal, { AccountAccessModalResult } from 'components/Account/AccountAccess/AccountAccessModal'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import offerPageURL from 'lib/offer/offerPageURL'
import { selectLoggedIn } from 'selectors/accountSelectors'
import LuxPlusFreePreviewModal from '../modals/LuxPlusFreePreviewModal'
import LuxPlusHiddenOfferModal from '../modals/LuxPlusHiddenOfferModal'
import LuxPlusHiddenOfferTransitionModal from './LuxPlusHiddenOfferTransitionModal'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { isFlashHotel } from 'lib/offer/offerTypes'
import { fetchMemberFreePreviewEligibility } from 'actions/LuxPlusActions'
import useLuxPlusFreePreviewUtils from 'luxPlus/hooks/useLuxPlusFreePreviewUtils'

export default function useHiddenOfferClickHandler(offer: App.Offer | App.OfferSummary) {
  const showModal = useContext(ModalContext)
  const dispatch = useAppDispatch()
  const isLoggedInUser = useAppSelector(selectLoggedIn)
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const { showFreePreviewSignup } = useLuxPlusFreePreviewUtils()

  useEffect(() => {
    if (isLoggedInUser) {
      dispatch(fetchMemberFreePreviewEligibility())
    }
  }, [dispatch, isLoggedInUser])
  const openLogin = useCallback(() => {
    return showModal<AccountAccessModalResult>(<AccountAccessModal />)
  }, [showModal])
  const onHandleHiddenOffer = useCallback(async() => {
    if (isLoggedInUser) {
      if (!canViewLuxPlusBenefits) {
        if (showFreePreviewSignup) {
          showModal(<LuxPlusFreePreviewModal />)
        } else {
          showModal(<LuxPlusHiddenOfferModal />)
        }
      } else {
        dispatch(pushWithRegion(offerPageURL(offer)))
      }
    } else {
      const result = await openLogin()
      if (result.loggedIn) {
        showModal(<LuxPlusHiddenOfferTransitionModal offer={offer} />)
      }
    }
  }, [dispatch, canViewLuxPlusBenefits, showFreePreviewSignup, isLoggedInUser, offer, openLogin, showModal])

  if (!isFlashHotel(offer)) {
    return
  }

  return onHandleHiddenOffer
}
