import moment from 'moment'
import useFlightPrice from 'hooks/useFlightPrice'
import { getPromotionDefaultPeriod } from 'lib/hotels/bedbankUtils'

function useBedbankFlightPrice(
  offer: App.BedbankOffer | App.BedbankOfferSummary | undefined,
  rooms: Array<App.Occupants> = [],
  flightsEnabled: boolean,
  startDate?: string | moment.Moment,
  endDate?: string | moment.Moment,
  originAirportCode?: string,
): number | undefined {
  const period = getPromotionDefaultPeriod()

  const hasPromotions = (offer?.promotions ?? []).length > 0

  let flightCheckinDate: string | moment.Moment = period.startDate
  let flightCheckoutDate: string | moment.Moment = period.endDate
  let nights = 1

  if (hasPromotions && !startDate && !endDate) {
    nights = Math.min(...(offer?.promotions ?? []).map(x => x.los))
  } else if (startDate && endDate) {
    flightCheckinDate = moment.isMoment(startDate) ? startDate : moment(startDate)
    flightCheckoutDate = moment.isMoment(endDate) ? endDate : moment(endDate)
    nights = flightCheckoutDate.diff(flightCheckinDate, 'days')
  }

  const [flightPrice, fetching] = useFlightPrice(flightsEnabled ? offer?.flights?.airportCode : undefined, rooms, flightCheckinDate, flightCheckoutDate, originAirportCode, nights)

  if (flightsEnabled && fetching) {
    return offer?.flights?.flightPrice
  }

  return flightPrice
}

export default useBedbankFlightPrice
