import React from 'react'
import styled from 'styled-components'
import BodyText from 'components/Luxkit/Typography/BodyText'

const Root = styled(BodyText)`
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: unset;
  line-clamp: unset;
`

interface Props {
  offer: App.BedbankOffer;
}

function BedbankOfferTileSnippetExpanded({ offer }: Props) {
  const description = offer.amenities ?? offer.metaDescription
  return <Root variant="medium">
    {description}
  </Root>
}

export default BedbankOfferTileSnippetExpanded
