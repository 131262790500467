import {
  LE_CONTRACTED_HOTEL_TYPES,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_CRUISE, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
  OFFER_TYPE_VILLA,
} from 'constants/offer'
import { TrackableProduct } from 'components/OfferList/OfferListEventsContext'

function isTourV1OfferType(offerType?: App.OfferType) {
  return offerType === OFFER_TYPE_TOUR
}

export function isBedbank(offer?: App.AnyOffer): offer is App.BedbankOffer | App.BedbankOfferSummary {
  return isBedbankType(offer?.type)
}

export function isBedbankType(offerType?: App.OfferType) {
  return offerType === OFFER_TYPE_BED_BANK
}

export function isBundleOffer(offer?: App.AnyOffer): offer is App.BundleOffer | App.BundleOfferSummary {
  return offer?.type === OFFER_TYPE_BUNDLE_AND_SAVE
}

export function isLEOffer(offer?: App.AnyOffer): offer is App.Offer | App.OfferSummary {
  return !!offer && !isBedbank(offer) && !isCruiseOffer(offer) && !isTourV2Offer(offer)
}

export function isLEHotel(offer?: App.AnyOffer): offer is App.HotelOffer | App.HotelOfferSummary {
  return isLEHotelType(offer?.type)
}

export function isLEHotelType(offerType?: App.OfferType) {
  return !!offerType && LE_CONTRACTED_HOTEL_TYPES.includes(offerType)
}

export function isLpcHotel(offer?: App.AnyOffer): offer is App.HotelOffer | App.HotelOfferSummary {
  return isLpcHotelType(offer?.type)
}

export function isLpcHotelType(offerType?: App.OfferType) {
  return offerType === OFFER_TYPE_ALWAYS_ON
}

export function isFlashHotel(offer?: App.AnyOffer): offer is App.HotelOffer | App.HotelOfferSummary {
  return isFlashHotelType(offer?.type)
}

export function isFlashHotelType(offerType?: App.OfferType) {
  return offerType === OFFER_TYPE_HOTEL
}

export function isHotel(offer?: App.AnyOffer): offer is App.HotelOffer | App.HotelOfferSummary | App.BedbankOffer | App.BedbankOfferSummary {
  return isBedbank(offer) || isLEHotel(offer)
}

export function isHotelType(offerType?: App.OfferType) {
  return isBedbankType(offerType) || isLEHotelType(offerType)
}

export function isTourV2Offer(offer?: App.AnyOffer): offer is Tours.TourV2Offer {
  return offer?.parentType === OFFER_TYPE_TOUR_V2
}

export function isTourV1Offer(offer?: App.AnyOffer): offer is App.TourOffer {
  return isTourV1OfferType(offer?.type)
}

export function isVillaOffer(offer?: App.AnyOffer): offer is App.VillaOffer {
  return offer?.type === OFFER_TYPE_VILLA
}

export function isTourOffer(offer?: App.AnyOffer): offer is App.TourOffer | Tours.TourV2Offer {
  return isTourV2Offer(offer) || isTourV1Offer(offer)
}

export function isTourV2OfferType(offerType?: App.OfferType | Tours.V2ExperienceOfferType | App.OfferListOfferType) {
  return offerType === 'partner_tour' || offerType === 'direct_tour' || offerType === 'connection_tour'
}

export function isTourOfferType(offerType?: App.OfferType) {
  return isTourV2OfferType(offerType) || isTourV1OfferType(offerType)
}

export function isTourV2OfferSummary(offer?: App.AnyOffer): offer is Tours.TourV2OfferSummary {
  return offer?.parentType === OFFER_TYPE_TOUR_V2 && (offer as any).allVariationsCount !== undefined
}

export function isCruiseOffer(offer?: App.AnyOffer): offer is App.CruiseOffer {
  return offer?.type === OFFER_TYPE_CRUISE
}

export const isCruiseV1Offer = (
  offer?: App.AnyOffer,
) => ((offer?.type === OFFER_TYPE_TOUR || offer?.type === OFFER_TYPE_HOTEL) && offer.holidayTypes?.includes('Cruises'))

export const isCruiseV2 = (type: string) => (
  type === OFFER_TYPE_CRUISE
)

export const isCruiseV1 = (type: string, holidayTypes: Array<string>) => (
  type === OFFER_TYPE_TOUR && holidayTypes.includes('Cruises')
)

export function isOrderOffer(offer: any): offer is App.OrderItemOffer {
  return offer?.type !== OFFER_TYPE_BED_BANK && !('id' in offer)
}

export function isTourPackage(pkg: App.Package | App.TourPackage): pkg is App.TourPackage {
  return !!(pkg as any)?.tour
}

export function isHotelPackage(pkg: App.Package | App.TourPackage | App.BedbankPackage): pkg is App.HotelPackage {
  return !!(pkg as any)?.roomType
}

export function isExperienceOffer(offer: App.AnyOffer | App.ExperienceOffer): offer is App.ExperienceOffer {
  return offer.parentType === 'experience'
}

export function isFlightDeal(flight: any): flight is App.FlightDeal {
  return (flight as App.FlightDeal).dealName !== undefined
}

export function isJourneyV2(flight: any): flight is App.JourneyV2 {
  return (flight as App.JourneyV2).itemType === 'journeyV2'
}

export function isCarHireOffer(flight: any): flight is App.CarHireOffer {
  return (flight as App.CarHireOffer).type === 'car_hire'
}

const ANY_OFFER_TYPE_CHECKS = [isLEOffer, isHotel, isTourOffer, isBundleOffer, isTourV2OfferSummary, isCruiseOffer]
export function isAnyOffer(offer: TrackableProduct): offer is App.AnyOffer {
  return ANY_OFFER_TYPE_CHECKS.some(check => check(offer as App.AnyOffer))
}
