import LoadingBox from 'components/Common/Loading/LoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { DetailSegment, Main, MainImageSegment } from './SearchTileStyles'
import cn from 'clsx'
import { useLocation } from 'react-router'

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
`

const LeftContent = styled(Content)``

const RightContent = styled(Content)`
  justify-content: flex-end;
`

interface Props {
  className?: string;
}

function SearchOfferTileLoadingSkeleton(props: Props) {
  const { className } = props

  const isMapPage = useLocation().pathname.includes('map')

  return (<Main type="clean" className={cn({ className, mapTile: isMapPage })}>
    <MainImageSegment>
      <LoadingBox floating />
    </MainImageSegment>
    <DetailSegment className={cn({ mapTile: isMapPage })}>
      <LeftContent>
        <div>
          <TextLoadingBox typography="body-medium" width={100} />
          <TextLoadingBox typography="body-medium" width={100} />
        </div>
        <div style={{ minWidth: '50%' }}>
          <TextLoadingBox typography="heading5" width={300} />
          <TextLoadingBox typography="subtitle3" width={300} />
          <TextLoadingBox typography="subtitle3" width={300} />
        </div>
        <div>
          <TextLoadingBox typography="body-medium" width={250} />
          <TextLoadingBox typography="body-medium" width={250} />
        </div>
        <div>
          <TextLoadingBox typography="body-medium" width={250} />
          <TextLoadingBox typography="body-medium" width={250} />
          <TextLoadingBox typography="body-medium" width={250} />
          <TextLoadingBox typography="body-medium" width={250} />
        </div>
        <TextLoadingBox typography="body-medium" width={100} />
      </LeftContent>
      <RightContent>
        <div>
          <TextLoadingBox typography="body-medium" width={200} />
          <TextLoadingBox typography="heading2" width={200} />
          <TextLoadingBox typography="body-medium" width={200} />
          <TextLoadingBox typography="body-medium" width={200} />
        </div>
        <div>
          <TextLoadingBox typography="heading1" width="100%" />
          <TextLoadingBox typography="body-medium" width={200} />
        </div>
      </RightContent>
    </DetailSegment>
  </Main>)
}

export default React.memo(SearchOfferTileLoadingSkeleton)
