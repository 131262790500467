import { getBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import setSelectedBusinessTravellerEmployeeIds from './setSelectedBusinessTravellerEmployeeIds'

export function restoreSelectedBusinessTravellerEmployeeIds() {
  return (dispatch) => {
    const employeeIds = getBusinessTravellerSelectedTravellerState()
    dispatch(setSelectedBusinessTravellerEmployeeIds(employeeIds))
  }
}

export default restoreSelectedBusinessTravellerEmployeeIds
